import * as React from "react";
import { useState } from "react";
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Theme,
  Typography,
} from "@mui/material";
import { makeStyles, createStyles } from '@mui/styles';
import BlankCheckBoxIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import DeviationIcon from "@mui/icons-material/Warning";
import ChecklistDeviation from "./ChecklistDeviation";
import { fetchSignature } from "../../services/signatureService";

type Props = {
  checklistPoint: any;
  assignedId: string;
  sessionData: any;
  sessionEvents: any;
};

const useStyles = makeStyles(({ spacing }: Theme) =>
  createStyles({
    deviation: {
      marginLeft: spacing(10),
    },
  })
);

const ChecklistOverviewListItem = ({
  checklistPoint,
  assignedId,
  sessionData,
}: Props) => {
  const classes = useStyles();
  const [showDeviation, setShowDeviation] = useState(false);

  const [signature, setSignature] = useState(null);

  const toggleDeviation = () => {
    setShowDeviation(!showDeviation);
  };

  React.useEffect(() => {
    if (checklistPoint.checked && checklistPoint.signatureId) {
      fetchSignature(checklistPoint.signatureId ?? checklistPoint.userId)
        .then(setSignature)
        .catch(err => console.log(`Failed to fetch signature`, err));
    }
  }, [checklistPoint.checked, checklistPoint.signatureId]);

  return (
    <>
      <ListItem alignItems="flex-start">
        <ListItemIcon>
          {checklistPoint.checked ? <CheckBoxIcon /> : <BlankCheckBoxIcon />}
        </ListItemIcon>
        <ListItemText
          primary={checklistPoint.name}
          secondary={
            <>
              {(checklistPoint.measurement && (
                <p>
                  Registrert temperatur:{" "}
                  <b>{checklistPoint.measurement} &deg;C</b>
                </p>
              )) ||
                (checklistPoint.breakage && (
                  <p>
                    Registrert brekkasje:{" "}
                    <b>
                      {checklistPoint.breakage.value} {checklistPoint.breakage.unit}
                    </b>
                  </p>
                ))}
              {(
                checklistPoint.labels && (
                  checklistPoint.labels.map((label, index) => (
                    <Typography key={index} variant="body1">
                      {label.labelType}: {label.name}
                    </Typography>
                  ))
                )
              )}
              {(
                checklistPoint.checked && (
                  <p>{signature?.autoCreatedForUserId ? "Logg in" : "Signert"}: <i>{(signature?.name ?? '')}</i></p>
                )
              )}
            </>
          }
        />
        {checklistPoint.deviations.length > 0 && (
          <ListItemSecondaryAction>
            <IconButton edge="end" onClick={toggleDeviation}>
              <DeviationIcon />
            </IconButton>
          </ListItemSecondaryAction>
        )}
      </ListItem>
      {showDeviation &&
        checklistPoint.deviations.map((item, key) => (
          <ChecklistDeviation
            item={item}
            key={key}
            assignedId={assignedId}
            sessionData={sessionData}
            pointId={checklistPoint.id}
          />
        ))}
    </>
  );
};

export default ChecklistOverviewListItem;
