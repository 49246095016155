import { Autocomplete, Box, Chip, Paper, Popper, TextField } from "@mui/material";
import React, { useState } from "react"
import { useTranslation } from "react-i18next";

type Props = {
    labelOptions: string[]
    existingLabels: string[];
    labelPlaceholder?: string;
    onValuesChanged: (values: string[]) => void;
}

  
export const PointLabelType = ({labelOptions, existingLabels, onValuesChanged, labelPlaceholder} : Props) => {
    const [currentValues, setCurrentValues] = useState(existingLabels || []);
  
    return (
      <Autocomplete
        multiple
        id="labels-component-autocomplete"
        options={labelOptions}
        value={currentValues}
        defaultValue={existingLabels}
        freeSolo
        renderTags={(value: readonly string[], getTagProps) =>
          value.map((option: string, index: number) => (
            <Chip variant="outlined" label={option} {...getTagProps({ index })} />
          ))
        }
        
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label={labelPlaceholder}
            placeholder={labelPlaceholder}
          />
        )}
        onChange={(_, values) => {
          setCurrentValues(values);
          onValuesChanged(values);
        }}
    />
  );
}