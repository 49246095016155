import { Button, Dialog, DialogContent } from "@mui/material";
import React, { useState } from "react";
import DialogTitle from "../../../react-components/DialogTitle";
import { useTranslation } from "react-i18next";
import {
  useTagChecklist,
  useUntagChecklist,
} from "../../services/checklistService";
import SelectOption from "../cron-definition/SelectOption";

type Props = {
  checklist: any;
  tenantId: string;
  open: boolean;
  existingTags: string[];
  onChange?: (deviationHandlingLevel: string) => any;
  onSuccess: (tagEvents: any[]) => void;
  onClose: () => void;
};

export function getDeviationLevelHandlingTag(tags: string[]): string {
  return tags.find((e) => ["red", "yellow", "green"].includes(e));
}

const EditChecklistDeviationLevel = ({
  checklist,
  tenantId,
  open,
  onClose,
  existingTags,
  onSuccess,
  onChange,
}: Props) => {
  const { t } = useTranslation(); //translation

  const initialDeviationTag = getDeviationLevelHandlingTag(existingTags);
  const [deviationTag, setDeviationTag] = useState<string>(initialDeviationTag);

  const handleSubmit = async () => {
    if (deviationTag === initialDeviationTag) return onClose();

    onSuccess(
      await Promise.all([
        useUntagChecklist({
          checklistId: checklist.checklistId,
          tenantId,
          tag: initialDeviationTag,
        }),
        useTagChecklist({
          checklistId: checklist.checklistId,
          tenantId,
          tag: deviationTag,
        }),
      ])
    );
    onChange && onChange(deviationTag);
  };

  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose}>
      <DialogTitle onClose={onClose}>
        {`${t("editChecklist.deviationHandlingLevel")}`}
      </DialogTitle>
      <DialogContent>
        <SelectOption
          value={deviationTag}
          onChange={setDeviationTag}
          items={[
            { label: t("Red"), value: "red" },
            { label: t("Yellow"), value: "yellow" },
            { label: t("Green"), value: "green" },
          ]}
        />
        <br />
        <Button
          style={{ marginTop: "1em" }}
          onClick={handleSubmit}
          variant="contained"
          color="primary"
        >
          {`${t("editChecklist.submit")}`}
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default EditChecklistDeviationLevel;
