import {
  Button,
  Dialog,
  DialogContent,
} from "@mui/material";
import React, { useState } from "react";
import DialogTitle from "../../../react-components/DialogTitle";
import { useTranslation } from "react-i18next";
import FileUpload, { extractExtension, SupportedMediaFormatsType } from "../../../react-components/FileUpload/FileUpload";
import { AttachmentFile } from "../../../react-components/FileUpload/attachment";
import { Guid } from "../../../system/guid";
import { BASE_CHECKLIST_MEDIA_PATH, useImageUpload } from "../../services/checklistService";
import { ProgressBar } from "../../../react-components/ProgressBar";

type Props = {
  open: boolean;
  onSubmit: (mediaId: string, extension: SupportedMediaFormatsType) => void;
  onClose: () => void;
};

const AttachMediaDialog = ({ open, onClose, onSubmit }: Props) => {

  const { t } = useTranslation(); //translation

  const [attachments, setAttachments] = useState<AttachmentFile>();
  const [progress, setProgress] = React.useState<number>();

  const handleSubmit = () => {
    const mediaId = Guid.newGuid().toString();
    const extension = extractExtension(attachments.files[0]?.name);
    useImageUpload(attachments.data(), mediaId, extension, BASE_CHECKLIST_MEDIA_PATH, setProgress)
    .then(() => {
      onSubmit(mediaId, extension);
      onClose();
    })
    .catch(() => {
      // TODO: Alert the user about the unsuccessful file upload
      onClose();
    });
  };

  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose}>
      <DialogTitle onClose={onClose}>
        {`${t("Attach Media")}`}
      </DialogTitle>
      <DialogContent>
        <FileUpload
          attachments={AttachmentFile(attachments)}
          onAttachmentsChange={(value) => setAttachments(value)}
        />
        {progress != null && <ProgressBar value={progress} />}
        <Button style={{marginTop: '1em'}} onClick={handleSubmit} variant="contained" color="primary">{`${t("editChecklist.submit")}`}</Button>
      </DialogContent>
    </Dialog>
  );
};

export default AttachMediaDialog;
