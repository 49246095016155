<div class="_content">
  <div class="content__heading">
    <a class="content__heading__go-back" [routerLink]="['/']">
      <i class="material-icons">home</i>
    </a>
    <h1>Dører</h1>
  </div>

  <div *ngIf="isLoadingEntities">
    <mat-spinner></mat-spinner>
  </div>
  <div *ngIf="!isLoadingEntities && entities.length === 0">
    Ingen dører funnet.
  </div>
  <div *ngIf="!isLoadingEntities">
    <ng-template ngFor let-entity [ngForOf]="entities">
      <mat-card class="entity" [ngClass]="{'time-to-clean': entity.openCloseInfo?.usagePercentage >= 100}">
        <mat-card-content>
          <div class="items horizontal">
            <div class="item">
              <circle-progress
                [percent]="entity.openCloseInfo?.usagePercentage"
                [radius]="50"
                [outerStrokeColor]="entity.openCloseInfo?.usageColorHex"
                [innerStrokeColor]="entity.openCloseInfo?.usageColorHighlightHex"
                [showSubtitle]="false"
              ></circle-progress>
            </div>
            <div class="item">
              <div class="time-to-clean" *ngIf="entity.openCloseInfo?.usagePercentage >= 100">
                På tide med rengjøring
              </div>
              <div class="info">
                <div class="info-wrap" title="Antall besøkende siden siste rengjøring">
                  <div class="info-part"><i class="material-icons">person</i></div>
                  <div class="info-part">{{entity.openCloseInfo?.countSinceLastCleaning}} / {{entity.openCloseLimit}}</div>
                </div>
                
                <ng-template [ngIf]="entity.latestDoorInfo?.isFound">
                  <div class="info-wrap" title="Sist registrerte status">
                    <div class="info-part"><i class="material-icons">sensor_door</i></div>
                    <div class="info-part">{{entity.latestDoorInfo.state}}</div>
                  </div>
                  <div class="info-wrap" title="Tidspunkt for sist registrerte status">
                    <div class="info-part"><i class="material-icons">wc</i></div>
                    <div class="info-part">{{entity.latestDoorInfo.occuredTime}}</div>
                  </div>
                </ng-template>
                <ng-template [ngIf]="!entity.latestDoorInfo?.isFound">
                  <div>
                    Ingen åpne/lukke aktiviteter funnet.
                  </div>
                </ng-template>
                <ng-template [ngIf]="entity.latestCleanInfo?.isFound">
                  <div class="info-wrap" title="Tidspunkt for sist registrerte rengjøring">
                    <div class="info-part"><i class="material-icons">cleaning_services</i></div>
                    <div class="info-part">{{entity.latestCleanInfo.occuredTime}}</div>
                  </div>
                </ng-template>
                
                <button mat-raised-button
                color="primary"
                type="button"
                (click)="entity.registerCleanEvent()">
                  Registrer vask
                </button>
                </div>
            </div>
          </div>
        </mat-card-content>
        <mat-card-footer>
          <div class="entity-info items horizontal">
            <div class="item">
              <i class="material-icons">wc</i>
            </div>
            <div class="item">
              {{entity.name}} 
            </div>
          </div>
        </mat-card-footer>
      </mat-card>
    </ng-template>
  </div>
