
<div class="temperature-register">
  <div class="temperature-register__heading">
    <a class="temperature-register__heading__go-back" routerLink="/report" >
      <i class="material-icons">
keyboard_arrow_left
</i>    </a>

    <h1>Temperaturkontroll</h1>
  </div>

  <mat-card class="temperature-register__temperatures">
    <div class="sk-fading-circle" *ngIf="showLoader">
      <div class="sk-circle1 sk-circle"></div>
      <div class="sk-circle2 sk-circle"></div>
      <div class="sk-circle3 sk-circle"></div>
      <div class="sk-circle4 sk-circle"></div>
      <div class="sk-circle5 sk-circle"></div>
      <div class="sk-circle6 sk-circle"></div>
      <div class="sk-circle7 sk-circle"></div>
      <div class="sk-circle8 sk-circle"></div>
      <div class="sk-circle9 sk-circle"></div>
      <div class="sk-circle10 sk-circle"></div>
      <div class="sk-circle11 sk-circle"></div>
      <div class="sk-circle12 sk-circle"></div>
    </div>

    <form *ngIf="tempreportForm" [formGroup]="tempreportForm" (ngSubmit)="onSubmit(tempreportForm)">
     
      <div formArrayName="measurements">
        <!-- <div *ngFor="let entity of manualMeasurements,let i = index"   -->
        <div  *ngFor="let measurement of measurements.controls;let i = index">
          <!-- TODO: TAHANI FIX form med ArrayFrom eller hva den heter -->
          <div  *ngIf="measurement.value.isManual" [formGroupName]="i">
            <div class="row">
              <span class="temperature-register__temperatures__item__name">
                  {{getEntityName(measurement.value.entityId)}}
              </span>

              <div>
                <mat-form-field>
                    <input matInput placeholder="sett inn temp" formControlName="value"  />
                    <!-- <div [hidden]="value.valid">Required</div> -->
                </mat-form-field>
             </div>
            </div>
          </div>
        </div>
      </div>
      <div class="center">
          <button type="submit" mat-button routerLink="/deviationProcedure"  (click)="onSubmit(tempreportForm)" class="send-button" 
          [disabled]="!tempreportForm.controls.measurements.valid"> Neste </button>
      </div>      
    </form>
  </mat-card>

  <!-- <div class="center">
    <button
      (click)="buildMeasurements(f)"
      mat-button
      class="send-button"
      routerLink="/temperature-confirm"
      type="submit"
    >
      Neste
    </button>
  </div> -->

</div>