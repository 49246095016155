<div class="deviations-modal">
  <deviation-actions-slider 
    [entityReports]="data.entityReports" 
    [entityId]="data.entityId" 
    [entityMin]="data.entityMin"
    [entityMax]="data.entityMax"
  >
  </deviation-actions-slider>
  <button mat-button mat-dialog-close> Lukk </button>
</div>
