import * as React from "react";
import {
  Box,
  CircularProgress,
  Typography,
} from "@mui/material";
import { makeStyles } from '@mui/styles';
import { scaleLinear } from "d3-scale";
import { interpolateRdYlGn } from "d3-scale-chromatic";

interface CleanProgressProps {
  value: number;
}

const useStyles = makeStyles({
  cleanProgress: {
    display: "flex",
    justifyContent: "center",
    marginTop: "1em",
  },
  top: {
    position: "absolute",
    left: 0,
  },
  bottom: {
    color: "lightgray",
  },
  progressContainer: {
    paddingTop: ".4em",
  },
});

function CircularProgressWithLabel(props) {
  const classes = useStyles();
  let color = scaleLinear().domain([100, 0]);
  let c = color(props.value);
  let interpolatedColor = interpolateRdYlGn(c);
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress
        variant="determinate"
        size={140}
        value={100}
        thickness={5}
        className={classes.bottom}
      />
      <CircularProgress
        variant="determinate"
        value={props.value > 100 ? 100 : props.value}
        size={140}
        thickness={5}
        className={classes.top}
        style={{ color: interpolatedColor}}
      />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="h4"
          component="div"
          color="textSecondary"
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

const CleanProgress: React.FC<CleanProgressProps> = ({ value }) => {
  const classes = useStyles();
  return (
    <Box className={classes.progressContainer}>
      <Box className={classes.cleanProgress}>
        <CircularProgressWithLabel value={value} />
      </Box>
    </Box>
  );
};

export default CleanProgress;
