import { Theme } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import * as React from "react";
import { useTranslation } from "react-i18next";
import {
  createEntityType,
  fetchEntityType,
  updateEntityType,
} from "../../entity-types-react/services/entityTypesService";
import PageHeader from "../../react-components/PageHeader";
import { useCurrentSite } from "../../react-components/useCurrentSite";
import { EditEntityTypeContext } from "../EditEntityTypeReactWrapper";
import EditEntityTypeForm from "./EditEntityTypeForm";

const styles = ({ spacing }: Theme) =>
  createStyles({
    root: {
      paddingLeft: spacing(4),
      paddingRight: spacing(4),
    },
  });

const useStyles = makeStyles(styles);

type Props = {};

const EditEntityTypePage: React.FC<Props> = () => {
  const { route, router, user, siteService, userSiteService } = React.useContext(EditEntityTypeContext);
  const classes = useStyles();

  const { t } = useTranslation();

  const entityTypeId = route.snapshot.paramMap.get("entityTypeId");
  const { site } = useCurrentSite(userSiteService, siteService);
  
  const tenantId = site?.tenantId ?? user.adalUser.btennant[0];
  

  const [existingEntityType, setExistingEntityType] = React.useState(null);

  React.useEffect(() => {
    entityTypeId &&
      fetchEntityType(entityTypeId)
        .then((resp) => setExistingEntityType(resp.data))
        .catch((err) => {});
  }, [entityTypeId]);

  return (
    <div className={classes.root}>
      <PageHeader
        title={t("entityTypes.edit.pageTitle")}
        href="/entity-types"
      />
      {(!entityTypeId || !!existingEntityType) && (
        <EditEntityTypeForm
          existingEntityType={existingEntityType}
          handleSubmit={(values) => {
            console.log('Tenant ID of entity type', tenantId);
            console.log(values);
            console.log(existingEntityType);
            const promise = !entityTypeId
              ? createEntityType({ tenantId, ...values })
              : updateEntityType(entityTypeId, {
                  tenantId,
                  ...existingEntityType,
                  ...values,
                });

            promise.then((resp) => router.navigateByUrl("/entity-types"));
          }}
        />
      )}
    </div>
  );
};

export default EditEntityTypePage;
