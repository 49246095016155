import { getDefaultRoute, setDefaultRoute } from "./route-utils";

export function clearStorage() {
  const defaultRoute = getDefaultRoute();
  localStorage.clear();
  setDefaultRoute(defaultRoute);
}

export function deleteAllCookies() {
  const cookies = document.cookie.split(";");

  for (var i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  }
}