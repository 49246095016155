import * as React from "react";
import {
  Paper,
  Theme,
  Typography,
  Box,
} from "@mui/material";
import { TimelineDot, } from "@mui/lab";
import { makeStyles, createStyles } from '@mui/styles';
import Settings from "./Settings";
import { useTranslation } from "react-i18next";
import EntityTypeRulesCard from "./EntityTypeRulesCard";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: "1em",
      marginBottom: "1em",
    },
    title: {
      flexGrow: 1,
    },
  });

const useStyles = makeStyles(styles);

type Props = {
  entityType: any;
};

const EntityTypeCard = ({ entityType }: Props) => {
  
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <Box display="flex" alignItems="center">
        <Typography variant="h2" className={classes.title}>
          {entityType.name}
        </Typography>
        <Settings entityType={entityType} />
      </Box>
      <Typography variant="h6" className={classes.title}>
        {entityType.description}
      </Typography>
      <EntityTypeRulesCard rules={entityType.rules} />
    </Paper>
  );
};

export default EntityTypeCard;
