import { Button, IconButton, Theme, Typography } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import { FieldArray } from "formik";
import * as React from "react";
import { useTranslation } from "react-i18next";
import FormikField from "../../react-components/FormikField";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

const styles = ({ spacing }: Theme) =>
  createStyles({
    root: {},
    causeContainer: {
      display: "flex",
      justifyContent: "space-between",
    },
    fieldContainer: {
      width: "100%",
    },
  });

const useStyles = makeStyles(styles);

type Props = {
  items: any[];
  rootKey: string;
  subKeys: string[];
};

const ItemListForm: React.FC<Props> = ({
  items,
  rootKey,
  subKeys
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <>
      <Typography variant="h6">{t(`entityTypes.edit.${rootKey}.title`)}</Typography>
      <FieldArray
        name={`${rootKey}`}
        render={(arrayHelpers) => (
          <div>
            {items && items.length > 0 ? (
              items.map((_, index) => (
                <div key={index} className={classes.causeContainer}>
                  {subKeys?.map((subKey, i) => (
                    <div key={`${i}${subKey}`} className={classes.fieldContainer}>
                      <FormikField
                        label={t(`entityTypes.edit.${rootKey}.${subKey}`)}
                        name={`${rootKey}.${index}.${subKey}`}
                      />
                    </div>
                  ))}
                  <IconButton onClick={() => arrayHelpers.remove(index)}>
                    <HighlightOffIcon />
                  </IconButton>
                </div>
              ))
            ) : (
              <>
                <br />
                {t(`entityTypes.edit.${rootKey}.noItems`)}
                <br />
              </>
            )}
            <Button
              type="button"
              variant="contained"
              color="primary"
              sx={{ marginBottom: "1.5em", marginTop: "0.5em" }}
              onClick={() => arrayHelpers.push({ label: "", cause: "" })}
            >
              {t(`entityTypes.edit.${rootKey}.add`)}
            </Button>
          </div>
        )}
      />
    </>
  );
};

export default ItemListForm;
