import * as React from "react";
import { MaintenanceHandler } from "@bitmesh/maintenance";
import { environment } from "../../environments/environment";
import { useTranslation } from "react-i18next";
import { MsalService } from "@azure/msal-angular";
import { Theme } from "@mui/material";
import { AuthenticationResult } from "@azure/msal-browser";
import { getToken, isAuthenticated } from "../security/token";

type Props = {
  userId: string;
  msalService: MsalService;
  theme: Theme;
};

export const Maintenance: React.FC<Props> = ({
  userId,
  msalService,
  theme,
}) => {
  const { t } = useTranslation();

  return (
    <>
      {userId && (
        <MaintenanceHandler
          userId={userId}
          fileApiUrl={environment.dependencies.fileupload_api.url}
          colors={{
            primary: theme.palette.primary.main,
            secondary: "#fafafa",
            text: "black",
            buttonText: "white",
            icon: theme.palette.primary.main,
          }}
          appId="bitmesh.tmesh.app"
          dontShowLabel={t("maintenance.dontShowLabel")}
          tokenProvider={(url: string) => {
            return new Promise((resolve, reject) => {
              isAuthenticated() ? resolve(getToken()) : resolve('');
            });
          }}
        />
      )}
    </>
  );
};
