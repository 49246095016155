<div class="temperature-register">
  <div class="temperature-register__heading">
    <a class="content__heading__go-back" routerLink="/temperature-register" routerLinkActive="active">
      <i class="material-icons">
keyboard_arrow_left
</i>    </a>

    <h1>Temperaturkontroll</h1>
  </div>

  <mat-card class="temperature-register__temperatures">
    <div *ngFor="let item of entityValues" class="temperature-register__temperatures__item">
      <div class="row">
        <span class="temperature-register__temperatures__item__name">
          {{ item.entityName }}
        </span>
        <div class="temperature-register__temperatures__item__box">
          <span class="temperature-register__temperatures__item__box__value">
            {{ item.entityValue }}&#176;
          </span>
        </div>
      </div>
    </div>
  </mat-card>

  <div class="center">
    <button
      mat-button
      class="send-button"
      (click)="registerTempReport()"
      router-link="/deviations"
    >
      Bekrefte
    </button>
  </div>
</div>
