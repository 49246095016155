import { Injectable } from '@angular/core';
import { ConfigurableMessageBus, EchoChannel } from '../configurable-message-bus';
// import { HubConnection } from '@aspnet/signalr-client';
import { environment } from '../../../environments/environment';

@Injectable()
export class RemoteEventService   {
  
  // private connection: HubConnection;
  private baseUrl: string;
  private processResponceHandler:Map<string,any> = new Map<string, any>();
  private initialized:boolean = false;
  constructor(private messageBus:ConfigurableMessageBus) {
    

  }
  init(): void {
    this.baseUrl = environment.dependencies.signal_api.url;
    this.messageBus.add(new EchoChannel("REMOTE_EVENTS"));
    this.initialized = true;
    // this.connection = new HubConnection(this.baseUrl + '/update');
    let self = this;

    // TODO FIX SIGNALR
    // this.connection.on('Send', (data) => {
    //   var entity = JSON.parse(data);
    //   if(!this.initialized) return;
    //   this.messageBus.emitTo("REMOTE_EVENTS", entity);
    // });
    // this.connection.start()
    // .then(() => {
    //     console.log('Hub connection started')
    // })
    // .catch(err => {
    //     console.log('Error while establishing connection')
    // });
  }
}
