import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Theme,
  Grid,
} from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import React, { FC, useEffect, useState } from "react";
import ExpandIcon from "@mui/icons-material/ExpandMore";
import WarningIcon from "@mui/icons-material/Warning";
import HandledIcon from "@mui/icons-material/CheckCircle";
import { DateTime } from "luxon";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import MediaRender from "../../../react-components/MediaRender";
import {
  BASE_CHECKLIST_MEDIA_PATH,
  FILEUPLOAD_URL,
} from "../../services/checklistService";
import { fetchSignature } from "../../services/signatureService";
import CachedIcon from "@mui/icons-material/Cached";
import { DeviationHandledComments } from "./DeviationHandledComments";

const useStyles = makeStyles(({ spacing, palette }: Theme) =>
  createStyles({
    deviationCard: {
      padding: spacing(2),
      marginBottom: spacing(2),
    },
    summary: {
      display: "flex",
    },
    time: {
      width: 100,
    },
    details: {
      display: "block",
    },
    alert: {
      background: "rgb(255, 244, 229)",
    },
    icon: {
      marginRight: spacing(2),
    },
    warningIcon: {
      color: palette.warning.main,
    },
    handledIcon: {
      color: palette.success.main,
    },
    autoIcon: {
      marginLeft: spacing(2),
      color: palette.info.main,
    },
    mediaContainer: {
      display: "block",
      padding: "2vh",
    },
    accordionTitleContainer: {
      display: "flex",
      justifyContent: "space-between",
      justifyItems: "space-between",
      width: "100%",
    },
    left: {
      display: "flex",
    },
    deviationValue: {
      color: "red",
      width: 50,
    },
  })
);

type Props = {
  item: any;
  tenantId: string;
  isAutoGenerated: boolean;
  pointNameRegistry: { [key: string]: string };
  comment?: boolean;
  defaultExpanded?: boolean;
};

const DeviationAccordion: FC<Props> = ({
  item,
  tenantId,
  isAutoGenerated,
  pointNameRegistry,
  comment,
  defaultExpanded = false,
}) => {
  const classes = useStyles();

  const { t } = useTranslation();

  const [handled, setHandled] = useState(item.handled);
  const [handledBy, setHandledBy] = useState(item.handledSignatureId);
  const [actionTaken,] = useState(item.actionTaken);
  const [actionTakenSignatureId,] = useState(
    item.actionTakenSignatureId
  );
  const [expanded, setExpanded] = useState(!handled);
  const isComment = !!comment ? comment : false;

  const handleExpanded = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    if (defaultExpanded) {
      setExpanded(true);
    }
  }, [defaultExpanded]);

  const [signature, setSignature] = useState(null);
  React.useEffect(() => {
    if (item.signatureId) {
      fetchSignature(item.signatureId)
        .then(setSignature)
        .catch((err) => console.log(`Failed to fetch signature`, err));
    }
  }, [item.signatureId]);

  const [handledSignature, setHandledSignature] = useState(null);
  React.useEffect(() => {
    if (handledBy) {
      fetchSignature(handledBy)
        .then(setHandledSignature)
        .catch((err) => console.log(`Failed to fetch handledSignature`, err));
    }
  }, [handledBy, handled]);

  const [actionTakenSignature, setActionTakenSignature] = useState(null);
  React.useEffect(() => {
    if (actionTakenSignatureId) {
      fetchSignature(actionTakenSignatureId)
        .then(setActionTakenSignature)
        .catch((err) =>
          console.log(`Failed to fetch action taken signature`, err)
        );
    }
  }, [actionTakenSignatureId]);


  return (
    <Accordion expanded={expanded} className={clsx(classes.deviationCard)} elevation={2}>
      <AccordionSummary expandIcon={<ExpandIcon />} onClick={handleExpanded}>
        <div className={classes.accordionTitleContainer}>
          <div className={classes.left}>
            <Typography className={classes.time}>
              <b>
                {DateTime.fromISO(item.timestamp).toLocaleString(
                  DateTime.TIME_SIMPLE
                )}
              </b>
            </Typography>
            {handled ? (
              <HandledIcon
                className={clsx(classes.handledIcon, classes.icon)}
              />
            ) : (
              <WarningIcon
                className={clsx(classes.warningIcon, classes.icon)}
              />
            )}
            {isAutoGenerated && (
              <>
                <CachedIcon className={classes.autoIcon} />
                <Typography>{t("checklist.deviationCard.auto")}</Typography>
              </>
            )}
          </div>
          {!!item.value && (
            <div>
              <Typography className={classes.deviationValue}>
                <b>{item.value}°C</b>
              </Typography>
            </div>
          )}
        </div>
      </AccordionSummary>
      <AccordionDetails className={classes.details}>
        <Typography gutterBottom>
          {t("checklist.deviationCard.point")}:{" "}
          <b>{pointNameRegistry[item.pointId] ?? ""}</b>
        </Typography>
        {signature && (
          <p>
            {t("checklist.deviationCard.reportedBy")}: <i>{signature?.name}</i>
          </p>
        )}
        <Typography gutterBottom>
          {t("checklist.deviationCard.comment")}:{" "}
          {t(item.comment.replace(/\.$/g, ""))}
        </Typography>
        <Grid container>
          {item.images?.length ? (
            item.images.map((imgPath) => (
              <Grid
                key={imgPath}
                className={classes.mediaContainer}
                item
                xs={6}
              >
                <MediaRender
                  src={`${FILEUPLOAD_URL}/${BASE_CHECKLIST_MEDIA_PATH}/${imgPath}`}
                />
              </Grid>
            ))
          ) : (
            <></>
          )}
        </Grid>
        {actionTakenSignature && (
          <p>
            {t("checklist.deviationCard.actionTakenBy")}:{" "}
            <i>{actionTakenSignature?.name}</i>
          </p>
        )}
        {actionTaken ? (
          <Typography gutterBottom>
            {t("checklist.deviationCard.takenAction")}: {actionTaken}
          </Typography>
        ) : (
          <></>
        )}
        {handledSignature && (
          <p>
            {t("checklist.deviationCard.handledBy")}:{" "}
            <i>{handledSignature?.name}</i>
          </p>
        )}

        <DeviationHandledComments
          tenantId={tenantId}
          deviation={item}
          onAddComment={(handledEvent) => {
            //
          }}
          onHandle={(handledEvent) => {
            setHandled(true);
            setHandledBy(handledEvent.signatureId);
          }}
          comment={isComment}
          variant="point"
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default DeviationAccordion;
