import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { Button, Select, MenuItem, Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import PageHeader from "../../react-components/PageHeader";
import { ReportsContext } from "../ReportsReactWrapper";
import DoorReport from "./DoorReport";
import { take } from "rxjs/operators";
import TempReport from "./TempChart";
import HumidityChart from "./HumidityChart";
import * as moment from "moment";
import { forkJoin } from "rxjs";
import { useTranslation } from "react-i18next";
import EntitySettings from "../../rooms-raect/components/Settings";
import { useSearchParams, useLocation } from "react-router-dom";

const useStyles = makeStyles({
  root: {
    padding: "2em",
  },
  actions: {
    marginBottom: "2em",
  },
  select: {},
});

const ReportsPage = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { route, entityService, signalsService } = useContext(ReportsContext);
  const entityId = route.snapshot.paramMap.get("entityId");
  console.log(entityId);

  const queryParams = new URLSearchParams(window.location.search);
  const dateString = queryParams.get("date");
  console.log(dateString);
  const date = moment(dateString, "YYYY-MM-DDTHH:mm:sZ");
  const now = moment();

  const [timespan, setTimespan] = useState<moment.DurationInputArg2>("days");
  const [endDate, setEndDate] = useState(
    dateString ? now.diff(date, "day", true) + 0.5 : 0
  );
  const [startDate, setStartDate] = useState(endDate + 1);

  const [entity, setEntity] = useState<any>();
  const [door, setDoor] = useState([]);
  const [temp, setTemp] = useState([]);
  const [humidity, setHumidity] = useState([]);
  const [tempSignals, setTempSignals] = useState([]);
  const [humiditySignals, setHumiditySignals] = useState([]);

  console.log(endDate);

  useEffect(() => {
    // if (dateString) {
    //   const date = moment(dateString, "YYYY-MM-DDTHH:mm:sZ");
    //   const now = moment();
    //   console.log(date);
    //   console.log(now);
    //   setEndDate(now.diff(date, "day", true));
    //   // setStartDate(endDate + 1);
    //   console.log(endDate);
    // }
    forkJoin([
      entityService.getEntityWithSignals(entityId),
      signalsService.GetSignalsForEntity(
        entityId,
        moment().subtract(startDate, timespan),
        moment().subtract(endDate, timespan)
      ),
    ])
      .pipe(take(1))
      .subscribe(([entityData, signalsData]) => {
        setEntity(entityData);

        const entitySignals: any = entityData.signal;

        const filterSignalType = (unitType: string) =>
          entitySignals.filter((v) => v.unit === unitType);

        const tempSignal = filterSignalType("TempC");
        const humiditySignal = filterSignalType("Humidity%");

        setDoor(filterSignalType("DoorOC"));
        setTemp(tempSignal);
        setHumidity(humiditySignal);

        const filterSignals = (sourceId: string) =>
          signalsData.filter((v) => v.sourceId === sourceId);

        tempSignal.length > 0 &&
          setTempSignals(filterSignals(tempSignal[0].sourceId));
        humiditySignal.length > 0 &&
          setHumiditySignals(filterSignals(humiditySignal[0].sourceId));
      });
  }, [timespan, startDate, endDate]);

  const handleTimespanChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setTimespan(event.target.value as any);
    setEndDate(0);
    setStartDate(1);
  };

  const handleNext = () => {
    setEndDate(endDate - 1);
    setStartDate(startDate - 1);
  };
  const handlePrev = () => {
    setEndDate(endDate + 1);
    setStartDate(startDate + 1);
  };

  const temperatureLimits = entity?.obj?.termoObjectType?.rules?.GREEN ?? entity?.obj?.rules?.temperature;
  return (
    <div className={classes.root}>
      <PageHeader
        title={entity && entity.name}
        href="/entities"
        actions={
          entity && <EntitySettings entity={entity} context={ReportsContext} />
        }
      />
      <Box className={classes.actions}>
        <Select
          value={timespan}
          onChange={(v) => handleTimespanChange(v as any)}
          className={classes.select}
        >
          <MenuItem value="hours">{t("Hour")}</MenuItem>
          <MenuItem value="days">{t("Day")}</MenuItem>
          <MenuItem value="weeks">{t("Week")}</MenuItem>
          <MenuItem value="months">{t("Month")}</MenuItem>
        </Select>
        <Button onClick={handlePrev}>{t("Previous")}</Button>
        {endDate > 0 && <Button onClick={handleNext}>{t("Next")}</Button>}
      </Box>
      <Typography variant="h3">
        {`${moment()
          .subtract(startDate, timespan)
          .format("HH:mm - DD.MM.YYYY")} ${t("to")} 
        ${moment().subtract(endDate, timespan).format("HH:mm - DD.MM.YYYY")}`}
      </Typography>
      {door.length > 0 && (
        <DoorReport
          entity={entity}
          startDate={startDate}
          endDate={endDate}
          timespan={timespan}
        />
      )}
      {temp.length > 0 && (
        <TempReport
          signals={tempSignals}
          startDate={startDate}
          endDate={endDate}
          timespan={timespan}
          limits={temperatureLimits}
        />
      )}
      {humidity.length > 0 && (
        <HumidityChart
          signals={humiditySignals}
          startDate={startDate}
          endDate={endDate}
          timespan={timespan}
          limits={entity?.obj.rules.humidity}
        />
      )}
      {dateString && (
        <p>{`${t("alarmStatus.reportPageAlarmMessage")}: ${moment(
          dateString,
          "YYYY-MM-DDTHH:mm:sZ"
        ).format("HH:mm - DD.MM.YYYY")}`}</p>
      )}
    </div>
  );
};

export default ReportsPage;
