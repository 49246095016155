import { Chart } from 'chart.js';

// var originalLineDraw = Chart.controllers.line.prototype.draw;
// // Extend the line chart, in order to override the draw function.
// Chart.helpers.extend(Chart.controllers.line.prototype, {
//   draw : function() {
//     console.log("Draw limits");
//     var chart = this.chart;
//     // Get the object that determines the region to highlight.
//     var yHighlightRange = chart.config.data.yHighlightRange;

//     // If the object exists.
//     if (yHighlightRange !== undefined) {
//       var xaxis = chart.scales['x-axis-0'];
//       var yaxis = chart.scales['y-axis-0'];
//       var ctx = chart.chart.ctx;
//       yHighlightRange.forEach(range=>{
//         var yRangeBegin = range.begin;
//         var yRangeEnd = range.end;

//         var yRangeBeginPixel = yaxis.getPixelForValue(yRangeBegin);
//         var yRangeEndPixel = yaxis.getPixelForValue(yRangeEnd);

//         ctx.save();

//         ctx.fillStyle = range.color;
//         ctx.fillRect(xaxis.left, Math.min(yRangeBeginPixel, yRangeEndPixel), xaxis.right - xaxis.left, Math.max(yRangeBeginPixel, yRangeEndPixel) - Math.min(yRangeBeginPixel, yRangeEndPixel));

//         ctx.restore();
//       })
//     }
//     // Apply the original draw function for the line chart.
//     originalLineDraw.apply(this, arguments);
//   }
// });
Chart.defaults.LineWithLine = Chart.defaults.line;
Chart.controllers.LineWithLine = Chart.controllers.line.extend({
    draw: function(ease) {


      // console.log("Draw limits");
      var chart = this.chart;
      // Get the object that determines the region to highlight.
      var yHighlightRange = chart.config.data.yHighlightRange;

      // If the object exists.
      if (yHighlightRange !== undefined) {
        var xaxis = chart.scales['x-axis-0'];
        var yaxis = chart.scales['y-axis-0'];
        var ctx = chart.chart.ctx;
        yHighlightRange.forEach(range=>{
          var yRangeBegin = range.begin;
          var yRangeEnd = range.end;

          var yRangeBeginPixel = yaxis.getPixelForValue(yRangeBegin);
          var yRangeEndPixel = yaxis.getPixelForValue(yRangeEnd);

          ctx.save();

          ctx.fillStyle = range.color;
          ctx.fillRect(xaxis.left, Math.min(yRangeBeginPixel, yRangeEndPixel), xaxis.right - xaxis.left, Math.max(yRangeBeginPixel, yRangeEndPixel) - Math.min(yRangeBeginPixel, yRangeEndPixel));

          ctx.restore();
        })
      }

      Chart.controllers.line.prototype.draw.call(this, ease);
      // console.log("Draw Vert line");
      if (this.chart.tooltip._active && this.chart.tooltip._active.length) {
          var activePoint = this.chart.tooltip._active[0],
              ctx = this.chart.ctx,
              x = activePoint.tooltipPosition().x,
              topY = this.chart.scales['y-axis-0'].top,
              bottomY = this.chart.scales['y-axis-0'].bottom;

          // draw line
          ctx.save();
          ctx.beginPath();
          ctx.moveTo(x, topY);
          ctx.lineTo(x, bottomY);
          ctx.lineWidth = 1;
          ctx.strokeStyle = '#07C';
          ctx.stroke();
          ctx.restore();
      }
    }
});
