import { Component, OnInit, Input, Inject } from "@angular/core";
import { DeviationActionsSliderComponent } from "../deviation-actions-slider/deviation-actions-slider.component";
import { MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-deviations-modal",
  templateUrl: "./deviations-modal.component.html",
  styleUrls: ["./deviations-modal.component.scss"],
})
export class DeviationsModalComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit() {}
}
