export enum TimeType {
  SingleTimeNow,
  SingleTimeExact,
  IntervalTime,
}

export type CronUnit =
  | "second"
  | "min"
  | "hour"
  | "dayInMonth"
  | "month"
  | "daysInWeek"
  | "year";

export type StatementTree = {
  [key in CronUnit]: string;
};

const units: CronUnit[] = [
  "second",
  "min",
  "hour",
  "dayInMonth",
  "month",
  "daysInWeek",
  "year",
];

export class CronBuilder {
  private statementTree: StatementTree = {
    second: '0',
    min: '*',
    hour: '*',
    dayInMonth: '?',
    month: '*',
    daysInWeek: '*',
    year: '*',
  }

  public from(statementTree: StatementTree) {
    this.statementTree = statementTree;
    return this;
  }

  public set(unit: CronUnit, value: string | number): CronBuilder {
    if (!value && value !== 0) return this;
    const tokens = this.statementTree[unit].split("/");
    this.statementTree[unit] =
      tokens.length === 2 ? `${value}/${tokens[1]}` : `${value}`;
    return this;
  }

  public setStart(unit: CronUnit, value: string | number): CronBuilder {
    if (!value && value !== 0) return this;
    const intervalTokens = this.statementTree[unit]?.split("-");
    if (intervalTokens?.length === 2) {
      this.statementTree[unit] = `${value}-${intervalTokens[1]}`;
      return this;
    }
    const tokens = this.statementTree[unit]?.split("/");
    this.statementTree[unit] =
      tokens?.length === 2 ? `${value}/${tokens[1]}` : `${value}`;
    return this;
  }

  public setByTimeType(
    unit: CronUnit,
    timeType: TimeType,
    start: string,
    end?: string
  ): CronBuilder {
    if (timeType === TimeType.SingleTimeNow) {
      this.set(unit, "*");
      return this;
    }
    let value = (timeType === TimeType.IntervalTime && (unit !== "min" || start !== end)) ? `${start}-${end}` : start;
    this.set(
      unit,
      value
    );
    return this;
  }

  public setFrequency(unit: CronUnit, value: string | number): CronBuilder {
    if (!value && value !== 0) return this;
    const tokens = this.statementTree[unit].split("/");
    this.statementTree[unit] =
      tokens.length === 2
        ? `${tokens[0]}/${value}`
        : `${this.statementTree[unit]}/${value}`;
    return this;
  }

  public unsetFrequency(unit: CronUnit): CronBuilder {
    const tokens = this.statementTree[unit].split("/");
    this.statementTree[unit] =
      tokens.length === 2
        ? `${tokens[0]}`
        : `${this.statementTree[unit]}`;
    return this;
  }

  public build(): string {
    const { second, min, hour, dayInMonth, month, daysInWeek, year } =
      this.statementTree;
    return [second, min, hour, dayInMonth, month, daysInWeek, year].join(" ");
  }
}
