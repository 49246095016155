import { makeStyles } from '@mui/styles';
import * as React from "react";
import { useEffect, useState, useContext } from "react";
import { forkJoin, of } from "rxjs";
import { catchError, take } from "rxjs/operators";
import { Site } from "../../entity/site";
import Loading from "../../react-components/Loading";
import {
  SignalTag,
  SignalTagFunction,
  SignalTagType,
} from "../../signals/vevent";
import { Guid } from "../../system/guid";
import EditEntityContext from "../EditEntityContext";
import { IValues } from "../interfaces";
import EntityForm from "./EntityForm";
import * as moment from "moment";
import { Entity } from "../../entity/entity";
import { TermoObject } from "../../entity/termo-object";
import { OpenCloseObject } from "../../entity/open-close-object";
import { ReptileObject } from "../../entity/reptile-object";

const useStyles = makeStyles({
  loading: {
    marginTop: "4em",
  },
});

const mapSignalObj = (signal: SignalTag) => {
  return {
    lastValue: "",
    sourceId: signal.sourceId,
    timestamp: signal.timestamp,
    type: signal.tagType,
    unit: null,
    value: 0,
  };
};

const MoveEntity = () => {
  const classes = useStyles();
  const {
    entityService,
    signalsService,
    route,
    siteService,
    user,
    router,
  } = useContext(EditEntityContext);

  const [entity, setEntity] = useState<any>();
  const [oldTags, setOldTags] = useState<any>({});
  const [tenantId, setTenantId] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);

  const entityId = route.snapshot.paramMap.get("entityId");
  const entityGuid = new Guid(entityId);
  useEffect(() => {
    siteService
      .getSite(user.siteId)
      .pipe(take(1))
      .subscribe((site: Site) => {
        setTenantId(site.tenantId.toString());
      });

    forkJoin([
      entityService.getEntity(entityGuid),
      signalsService.getTags(entityId).pipe(catchError((err) => of(err))),
    ])
      .pipe(take(1))
      .subscribe(
        ([entity, tagsArray]) => {
          setEntity(entity);

          const currentTags = tagsArray.length > 0 ? tagsArray : [];

          currentTags.map((tag) => {
            switch (tag.tagFunction) {
              case SignalTagFunction.Temp:
                setOldTags((prev) => ({
                  ...prev,
                  temperature: mapSignalObj(tag),
                }));
                break;
              case SignalTagFunction.Door:
                setOldTags((prev) => ({ ...prev, door: mapSignalObj(tag) }));
                break;
              case SignalTagFunction.Humidity:
                setOldTags((prev) => ({
                  ...prev,
                  humidity: mapSignalObj(tag),
                }));
                break;
              case SignalTagFunction.Touch:
                setOldTags((prev) => ({
                  ...prev,
                  touch: mapSignalObj(tag),
                }));
                break;
              case SignalTagFunction.PPM:
                setOldTags((prev) => ({
                  ...prev,
                  ppm: mapSignalObj(tag),
                }));
                break;
              case SignalTagFunction.Pressure:
                setOldTags((prev) => ({
                  ...prev,
                  pressure: mapSignalObj(tag),
                }));
                break;
            }
          });
        },
        () => { },
        () => setLoading(false)
      );
  }, []);

  const handleMove = (values: IValues) => {
    let newEntityId = Guid.newGuid().toString();
    let createdOn = moment();

    let newObj = {
      id: Guid.newGuid().toString(),
      entityId: newEntityId,
      tenantId,
      createdOn,
      rules: values.limits,
      make: values.family,
      model: values.species,
    };

    let newEntity = Entity.create({
      id: newEntityId,
      name: values.name,
      siteId: user.siteId,
      tenantId,
      type: values.type,
      createdOn,
      hide: false,
    });

    let objMethod;

    switch (values.type) {
      case TermoObject.typeName: {
        objMethod = entityService.postThermoObject(newObj);
        break;
      }
      case OpenCloseObject.typeName: {
        objMethod = entityService.postOpenCloseObject(newObj);
        break;
      }
      case ReptileObject.typeName: {
        objMethod = entityService.postReptileObject(newObj);
        break;
      }
      default: {
        throw Error;
      }
    }

    let signalTagsObservers = [];

    postSignalTags(
      newEntityId,
      oldTags.temperature?.sourceId,
      values.temperature?.sourceId,
      SignalTagFunction.Temp,
      signalTagsObservers
    );

    postSignalTags(
      newEntityId,
      oldTags.humidity?.sourceId,
      values.humidity?.sourceId,
      SignalTagFunction.Humidity,
      signalTagsObservers
    );

    postSignalTags(
      newEntityId,
      oldTags.door?.sourceId,
      values.door?.sourceId,
      SignalTagFunction.Door,
      signalTagsObservers
    );

    postSignalTags(
      newEntityId,
      oldTags.touch?.sourceId,
      values.touch?.sourceId,
      newEntity.type === OpenCloseObject.typeName
        ? SignalTagFunction.Clean
        : SignalTagFunction.Touch,
      signalTagsObservers
    );

    postSignalTags(
      newEntityId,
      oldTags.ppm?.sourceId,
      values.ppm?.sourceId,
      SignalTagFunction.PPM,
      signalTagsObservers
    );
    postSignalTags(
      newEntityId,
      oldTags.pressure?.sourceId,
      values.pressure?.sourceId,
      SignalTagFunction.Pressure,
      signalTagsObservers
    );

    forkJoin([
      entityService.putEntity(entity.id, {
        ...entity,
        disabled: true,
        hide: true,
      }),
      entityService.postEntity(newEntity),
      objMethod,
      ...signalTagsObservers,
    ]).subscribe(
      () => { },
      () => { },
      () => router.navigateByUrl(`/entity/${newEntityId}`)
    );
  };

  const postSignalTags = (
    newEntityId: string,
    oldSourceId: string | null,
    newSourceId: string | null,
    signalTagFunction: SignalTagFunction,
    observableArr: Array<any>
  ) => {
    if (!oldSourceId) return;
    const oldTag = new SignalTag(
      Guid.newGuid().toString(),
      "SignalTag",
      oldSourceId,
      moment().utc().subtract(1, "second"),
      entityId,
      SignalTagType.Stop,
      signalTagFunction
    );

    const newTag = new SignalTag(
      Guid.newGuid().toString(),
      "SignalTag",
      newSourceId,
      moment().utc(),
      newEntityId,
      SignalTagType.Start,
      signalTagFunction
    );

    oldSourceId && observableArr.push(signalsService.postSignalTag(oldTag));
    newSourceId && observableArr.push(signalsService.postSignalTag(newTag));
  };

  if (loading) {
    return (
      <div className={classes.loading}>
        <Loading />
      </div>
    );
  }

  return (
    <EntityForm
      isMove
      entity={entity}
      tags={oldTags}
      handleSubmit={(values: any) => handleMove(values)}
    />
  );
};

export default MoveEntity;
