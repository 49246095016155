import { useEffect, useMemo, useState } from "react";
import { useAssignedIdsDeviationsFromTo } from "../../services/checklistService";
import { DateTime } from "luxon";

export type Deviation = {
  type:
  | "DeviationReported"
  | "ChecklistDeviationReported"
  | "TemperatureDeviationReported"
  | "ChecklistDeviationDetected"
  | "ChecklistComment";
  pointId: string;
  deviationId: string;
  comment: string;
  images: string[];
  handled: boolean;
  handledComment: string;
  timestamp: DateTime;
  sessionId: string;
  assignedId: string;
  checklistId: string;
  checklistName: string;
  signatureId?: string;
  handledSignatureId?: string;
  actionTaken?: string;
  actionTakenSignatureId?: string;
  value?: string | number;
  handledComments: {
    id: string;
    comment: string;
    signatureId: string;
    timestamp: string;
  }[];
};

const compareDateStrings = (a, b) =>
  DateTime.fromISO(a).toMillis() - DateTime.fromISO(b).toMillis();

export function useChecklistDeviations(props: {
  assignedIds: string[];
  checklistDescribedRegistryForAssignedId: Record<string, any>;
  from?: string;
  to?: string;
  refDateStart: DateTime;
}): {
  allDeviations: {
    date: string;
    timestamp: string;
    deviations: Record<string, Deviation[]>;
  }[];
  deviationData: any[];
  deviationLoading: boolean;
  deviationError: boolean;
} {
  const { assignedIds, from, to, refDateStart, checklistDescribedRegistryForAssignedId } = props;

  const [allDeviations, setAllDeviations] = useState([]);
  const [refDate] = useState(DateTime.local());
  const [deviationData, setDeviationData] = useState([]);
  const [deviationLoading, setDeviationLoading] = useState(false);
  const [deviationError, setDeviationError] = useState(false);

  const deviationEventsOnly = useMemo(() => {
    return deviationData?.filter(
      (item) =>
        (item.type === "DeviationReported" ||
          item.type === "TemperatureDeviationReported" ||
          item.type === "ChecklistDeviationReported" ||
          item.type === "ChecklistDeviationDetected" ||
          item.type === "ChecklistComment")
        && (!from || !to || (item.Timestamp >= from && item.Timestamp <= to))
    ) ?? [];
  }, [deviationData]);

  useEffect(() => {
    setDeviationLoading(true);
    useAssignedIdsDeviationsFromTo(
      assignedIds,
      from || refDateStart.startOf("week").toHTTP(),
    )
      .then((resp) => {
        setDeviationData(resp.data);
        setDeviationLoading(false);
        setDeviationError(false);
      })
      .catch((err) => {
        setDeviationError(true);
      });
  }, [refDateStart, refDate]);

  useEffect(() => {
    const deviations = [];
    deviationEventsOnly.forEach((element) => {
      const date = DateTime.fromISO(element.Timestamp).toLocaleString(
        DateTime.DATE_MED_WITH_WEEKDAY
      );
      if (!deviations.find((item) => item.date === date)) {
        const deviationsEventsForDates = deviationEventsOnly.filter(
          (item) =>
            DateTime.fromISO(item.Timestamp).startOf("day").toMillis() ===
            DateTime.fromISO(element.Timestamp).startOf("day").toMillis()
        );

        let deviationsForDate = [];
        deviationsEventsForDates.forEach((d) => {
          const deviationHandledEvents = deviationData.filter(
            (item) =>
              (item.type === "DeviationHandled" ||
                item.type === "ChecklistDeviationHandled" ||
                item.type === "ChecklistDeviationDetected" ||
                item.type === "ChecklistComment") &&
              item.deviationId === d.id
          );
          // sort deviationHandledEvents by date
          deviationHandledEvents.sort((a, b) => compareDateStrings(a.Timestamp, b.Timestamp));
          const deviationHandledEvent = deviationHandledEvents[0];

          const deviationActionTakenEvent = deviationData.find(
            (item) =>
              item.type === "DeviationActionTaken" &&
              item.pointId === d.pointId &&
              item.sessionId === d.sessionId
          );
          const checklistDescribed =
            checklistDescribedRegistryForAssignedId[d.assignedId];
          const deviation: Deviation = {
            type: d.type,
            pointId: d.pointId,
            comment: d.comment,
            deviationId: d.id,
            images: d.images,
            timestamp: d.Timestamp,
            sessionId: d.sessionId,
            assignedId: d.assignedId,
            signatureId: d.signatureId,
            value: d.value,
            checklistId: checklistDescribed.checklistId,
            checklistName: checklistDescribed.name,
            handled: !!deviationHandledEvent,
            handledComment: deviationHandledEvent?.comment,
            handledSignatureId: deviationHandledEvent?.signatureId,
            actionTaken: deviationActionTakenEvent?.action,
            actionTakenSignatureId: deviationActionTakenEvent?.signatureId,
            handledComments: deviationHandledEvents.map((e) => ({
              id: e.id,
              comment: e.comment,
              signatureId: e.signatureId,
              timestamp: e.Timestamp,
            })),
          };

          deviationsForDate.push(deviation);
        });
        const sessionDeviations = {};
        deviationsForDate
          .sort((a, b) => -compareDateStrings(a.timestamp, b.timestamp))
          .forEach((dev) => {
            if (!sessionDeviations[dev.sessionId]) {
              sessionDeviations[dev.sessionId] = [];
            }
            sessionDeviations[dev.sessionId].push(dev);
          });
        deviations.push({
          date: date,
          timestamp: element.Timestamp,
          deviations: sessionDeviations,
        });
      }
    });
    deviations
      .sort((a, b) => -compareDateStrings(a.timestamp, b.timestamp))
    setAllDeviations([...deviations]);
  }, [deviationData]);

  return {
    allDeviations,
    deviationData,
    deviationLoading,
    deviationError,
  };
}