import { environment } from "../../environments/environment";
import { initAxiosFor } from "../security/axiosUtils";

export type AddUserAccount = {
  externalUserId: string;
  userProfileId: string;
}

export type AddPhoneUserAccount = AddUserAccount & {
  name: string;
  phoneNumber: string;
}

export type PhoneUserAccount = AddUserAccount & AddPhoneUserAccount & {
  id: string;
}

export function initPhoneAccountService() {
  const axiosInstance = initAxiosFor(environment.dependencies.security_api.url);

  const getForProfile = async (userProfileId: string): Promise<PhoneUserAccount[]> => {
    return (await axiosInstance?.get(`/api/useraccount/profile/${userProfileId}`))?.data.filter(x => x.type === 'PhoneUserAccount');
  }

  const addOrUpdate = async (user: AddPhoneUserAccount): Promise<PhoneUserAccount> => {
    (user as any).type = 'PhoneUserAccount';
    return (await axiosInstance?.post(`/api/useraccount`, user))?.data;
  }

  const update = async (user: PhoneUserAccount): Promise<PhoneUserAccount> => {
    (user as any).type = 'PhoneUserAccount';
    return (await axiosInstance?.put(`/api/useraccount/${user.id}`, user))?.data;
  }


  const del = async (userAccountId: string): Promise<PhoneUserAccount> => {
    return (await axiosInstance?.delete(`/api/useraccount/${userAccountId}`))?.data;
  }

  return {
    getForProfile,
    addOrUpdate,
    update,
    del,
  };
}