import { Guid } from "../system/guid";
import * as moment from "moment";

export const UnitTypeTemp = 82;

export const SourceIdManual = "manual";

export class Measurement{
	constructor(
		public id: string,
		public occuredTime: string,
		public entityId: string,
		public sourceId: string,
		public value: number,
		public unitType: number
	){
		this.id = id;
		this.occuredTime = occuredTime;
		this.entityId = entityId;
		this.value = value;
		this.unitType = unitType;
  }

  public get OccuredTime(){
    return moment(this.occuredTime);
  }

	public static deSerialize(o:any): Measurement{
		return new Measurement(o.id,o.occuredTime,o.entityId,o.sourceId,o.value,o.unitType);
	}

}
