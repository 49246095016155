import { Measurement } from '../measurements/measurement';
import { Deviation } from './deviation';
export enum ReportState {
	New = 100,
	Inprogress = 200,
	Incompleet = 300, // Not signed. Compleeted by system.
	Compleeted = 400,
}
export enum ReportType {
	Periodic = 100,
	Manual = 200,
	Automatic = 300,
}
export enum ReportStatus {
	Ok = 100,
	Deviations = 200
}
export class TempReport {

	constructor(
		public readonly id: string,
		private occuredTime: string,
		private tenantId: string,
		public createdOn: string,
		private createdBy: string,
		private measurements: any,
		private deviations: any,
		private referenceId: string,
		private status: number,
		private state: number,
		public reportType: ReportType,
		private modifiedBy: string,
		private modifiedOn: string
	) {
	}

	static deSerialize(values: any): TempReport {
		return new TempReport(
			values.id,
			values.occuredTime,
			values.tenantId,
			values.createdOn,
			values.createdBy,
			Object.keys(values.measurements).reduce((measurements, key) => {
				measurements[key] = Measurement.deSerialize(measurements[key]);
				return measurements;
			}, values.measurements),
			Object.keys(values.deviations).reduce((deviations, key) => {
				deviations[key] = Deviation.create(deviations[key]);
				return deviations;
			}, values.deviations),
			values.referenceId,
			values.status,
			values.state,
			values.reportType,
			values.modifiedBy,
			values.modifiedOn
		);
	}
	static fromForm(o: any): TempReport {
		var values = { ...o };
		values.measurements = o.measurements.reduce((acc, curr) => {
			acc[curr.entityId] = curr;
			return acc;
		}, {});
		values.deviations = o.deviation.reduce((acc, curr) => {
			acc[curr.entityId] = curr;
			return acc;
		}, {});
		var deSerialized = TempReport.deSerialize(values);
		deSerialized.status = Object.keys(deSerialized.deviations).length > 0 ? 200 : 100;
		return deSerialized;
	}
	// public getDeviation():any{
	// 	return this.deviations;
	// }
	public getId(): any {
		return this.id;
	}
	public setDeviation(deviations: any) {
		this.deviations = deviations;
	}
	public setState(state: number) {
		this.state = state;
	}
	public getMeasurement(id: string): Measurement {
		return this.measurements[id.toUpperCase()] || this.measurements[id.toLowerCase()];
	}
	public getDeviation(id: string): Deviation {
		return this.deviations[id.toUpperCase()] || this.deviations[id.toLowerCase()];
	}
	public getOccuredTime() {
		return this.occuredTime;
	}
	public getTenantId() {
		return this.tenantId;
	}
	public getCreatedOn() {
		return this.createdOn;
	}
	public getCreatedBy() {
		return this.createdBy;
	}
	public getReferenceId() {
		return this.referenceId;
	}

	public getStatus() {
		return this.status;
	}
	public setStatus(status: number) {
		this.status = status;
	}
	public getState() {
		return this.state;
	}
	public getModifiedBy() {
		return this.modifiedBy;
	}
	public getModifiedOn() {
		return this.modifiedOn;
	}
	public isCompleeted(): boolean {
		return this.state == ReportState.Compleeted;
	}
	public hasDeviations(): boolean {
		return Object.keys(this.deviations).length > 0;
	}
	public getMeasurements(): Array<Measurement> {
		// SUDO code
		// List l = new List();
		// foreach x in this.measurements.keys
		// 	l.add(this.measurements[x])
		return Object.keys(this.measurements).map(x => this.measurements[x]);
	}
	public addMeasurement(measurement: Measurement) {
		this.measurements[measurement.entityId] = measurement;
	}

	public getDeviations(): Array<Deviation> {
		//return this.deviations;
		return Object.keys(this.deviations).map(x => this.deviations[x]);
	}

	public addDeviation(deviation: Deviation) {
		this.deviations[deviation.entityId] = deviation;
	}

	public isHandled(isInPast = false) {
		return isInPast ? this.state > ReportState.Incompleet : this.state >= ReportState.Incompleet;
	}
}
