import * as React from "react";
import { ReactNode } from "react";
import { Field, ErrorMessage, FieldInputProps } from "formik";
import {
  Theme,
  Select,
  FormControl,
  FormHelperText,
  MenuItem,
  InputLabel,
} from "@mui/material";
import { makeStyles } from '@mui/styles';
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: theme.spacing(1),
  },
}));

export interface FormikSelectItem {
  label: string;
  value: string;
}

interface FormikSelectProps {
  name: string;
  items: FormikSelectItem[];
  label: string;
  required?: boolean;
  className?: string;
  classes?: any;
  onChange?: any;
}

interface MaterialUISelectFieldProps extends FieldInputProps<string> {
  errorString?: string;
  children: ReactNode;
  label: string;
  required: boolean;
  classes?: any;
}

const MaterialUISelectField: React.FC<MaterialUISelectFieldProps> = ({
  errorString,
  label,
  children,
  value,
  name,
  onChange,
  onBlur,
  required,
  classes: externClasses,
}) => {
  const classes = useStyles();
  return (
    <FormControl fullWidth>
      <InputLabel required={required} className={externClasses?.label}>
        {label}
      </InputLabel>
      <Select
        variant="standard"
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        className={externClasses?.select}
        inputProps={{
          classes: {
            icon: externClasses?.icon,
          },
        }}
      >
        {children}
      </Select>
      <FormHelperText>{errorString}</FormHelperText>
    </FormControl>
  );
};

const FormikSelect: React.FC<FormikSelectProps> = ({
  name,
  items,
  label,
  required = false,
  className,
  classes: externClasses,
  onChange,
}) => {
  const classes = useStyles();
  return (
    <div className={clsx(classes.root, className)}>
      {!onChange &&
        <Field
          name={name}
          classes={externClasses}
          as={MaterialUISelectField}
          label={label}
          errorString={<ErrorMessage name={name} />}
          required={required}
          
        >
          {items.map((item) => (
            <MenuItem key={item.value} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </Field>
      }
      {onChange &&
        <Field
          name={name}
          classes={externClasses}
          as={MaterialUISelectField}
          label={label}
          errorString={<ErrorMessage name={name} />}
          required={required}
          onChange={onChange}
        >
          {items.map((item) => (
            <MenuItem key={item.value} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </Field>
      }
    </div>
  );
};

export default FormikSelect;
