import {
  Button,
  Dialog,
  DialogContent,
  Theme,
} from "@mui/material";
import { makeStyles, createStyles } from '@mui/styles';
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Site } from "../../../../entity/site";
import DialogTitle from "../../../../react-components/DialogTitle";
import FormikField from "../../../../react-components/FormikField";
import { useChecklistContext } from "../../../ChecklistReactWrapper";
import { ChecklistPointEvent, ChecklistPointEventType } from "./ChecklistPointEventHandler";

type Props = {
  point: any;
  checklistId: string;
  open: boolean;
  onClose: () => void;
  onSubmit: (event: ChecklistPointEvent) => void;
};

const useStyles = makeStyles(({ spacing, palette }: Theme) =>
  createStyles({
    root: {
      marginLeft: spacing(1),
      marginRight: spacing(1),
    },
    dialog: {
      width: 1500,
    },
    textField: {
      "& > div": {
        background: palette.common.white,
      },
    },
  })
);

const AddBreakageToPointDialog = ({ point, checklistId, open, onClose, onSubmit }: Props) => {

  const { t } = useTranslation(); //translation

  const classes = useStyles();
  const { user, siteService, entityService } = useChecklistContext();

  const [tenantId, setTenantId] = useState<string>("");
  const [units, setUnits] = useState<any>([]);

  useEffect(() => {
    siteService.getSite(user.siteId).subscribe((site: Site) => {
      setTenantId(site.tenantId.toString());
    });
  }, []);

  useEffect(() => {
    //change to smart solution, kan evt ha input tekst, slik at man kan skrive hva man vil som enhet?
    setUnits([
      { value: "kg", label: "Kg" },
      { value: "l", label: "Liter" },
      { value: "stk", label: "Stykk" },
      { value: "dl", label: "Desiliter" },
    ]);
  }, []);

  const handleSubmit = (values, { resetForm }) => {
    onSubmit({
      type: ChecklistPointEventType.PointBreakage,
      data: {
        pointId: point?.id,
        unit: values.unit,
        tenantId,
        checklistId,
      }
    });

    resetForm();
    onClose();
  };

  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose}>
      <DialogTitle onClose={onClose}>
        {`${t("editChecklist.add_breakage")}`}
      </DialogTitle>
      <DialogContent>
        <Formik
          initialValues={{ unit: point?.unit ?? "" }}
          enableReinitialize
          validate={(values) => {
            const errors = {};
          }}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <FormikField
                className={classes.textField}
                label={`${t("editChecklist.breakage_unit")}`}
                name="unit"
                variant="filled"
                required
              />
              <Button type="submit" variant="contained" color="primary">
                {`${t("editChecklist.submit")}`}
              </Button>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default AddBreakageToPointDialog;
