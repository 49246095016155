import { Component, OnInit } from "@angular/core";
import { TempReport } from "../../reports/tempreport";
import { Entity } from "../../entity/entity";
import { TempreportService } from "../../reports/tempreport.service";
import { ServiceBusService } from "../../system/service-bus.service";
import { Chanels } from "../../chanels";
import { EntityService } from "../../entity/entity.service";
import { ActionDialog } from "./action-dialog/action-dialog";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: "app-report-latest",
  templateUrl: "./report-latest.component.html",
  styleUrls: ["./report-latest.component.scss"],
})
export class ReportLatestComponent implements OnInit {
  private tempReport: TempReport;
  private entities: Entity[];

  public measurements: any[];

  constructor(
    private tempreportService: TempreportService,
    private entityService: EntityService,
    private bus: ServiceBusService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.getTempReportAndEntities();
  }

  getTempReportAndEntities() {
    this.bus.subscribe(Chanels.USER_CHANEL, (user) => {
      if (user == null) return;
      this.tempreportService.getDelieveredReports(user.siteId).subscribe(
        (tempReport) => {
          this.tempReport = tempReport;
          this.getEntities(user.siteId);
        },
        (err) => {
          console.log(err);
        }
      );
    });
  }

  getEntities(siteId: any) {
    this.entityService.getSiteEntities(siteId).subscribe((entities) => {
      this.entities = entities.getEntities();
      this.buildMeasurements();
    });
  }

  buildMeasurements() {
    var result = [];
    var i = 0;

    this.tempReport.getMeasurements().forEach((measurement) => {
      var entity = this.entities.find(
        (e) => e.id.toString() == measurement.entityId
      );
      var entityName = entity != null ? entity.name : "<Ukjent>";
      var value = measurement.value;

      var deviation = this.tempReport.getDeviation(entity.id.toString());
      var action = deviation != null && deviation.action;
      const rules =
        entity.obj && entity.obj.rules && entity.obj.rules.temperature;
      var min = rules.min;
      var max = rules.max;

      var isDeviation = entity && rules && (min > value || max < value);
      var isManual = value == null;

      result[i++] = {
        entityName: entityName,
        value: value,
        isDeviation: isDeviation,
        isManual: isManual,
        action: action,
        min: min,
        max: max,
      };
    });

    this.measurements = result.sort((a, b) =>
      a.entityName > b.entityName ? 1 : -1
    );
  }

  showAction(item: any) {
    this.dialog.open(ActionDialog, {
      width: "350px",
      data: {
        title: item.entityName,
        action: item.action,
        value: item.value,
        min: item.min,
        max: item.max,
      },
    });
  }
}
