<div class="deviation-action">
    <div class="deviation-action__heading">
      <a class="deviation-action__heading__go-back" routerLink="/deviations" routerLinkActive="active">
        <i class="material-icons">
keyboard_arrow_left
</i>
      </a>
      <h1>{{entity.name}} </h1>
      <span class="temperature danger">
          {{ selectedMeasurment.value }}&#8451;
        </span>
    </div>
  
    <form [formGroup]="actionForm" (ngSubmit)= "onSubmit(actionForm)">
      <div class="action-form">
        <mat-form-field>
          <textarea
            rows="10"
            cols="80"
            placeholder="*Beskriv hva som var gjort med enheten"
            matInput
            formControlName="mvalue"
          >
          </textarea>
        </mat-form-field>
      </div>
    
      <div class="center">
        <button
          mat-button
          class="send-button"
          type="submit"
          >
          Neste
        </button>
      </div>
    </form>
  </div>
  