import {
  Button,
  CardActions,
  CardContent,
  CardHeader,
  Theme,
} from "@mui/material";
import { Card, Typography } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import { DateTime } from "luxon";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import MediaRender from "../../react-components/MediaRender";
import { useSignatureContext } from "../../signature-react/SignatureContext";
import { useChecklistContext } from "../ChecklistReactWrapper";
import { actions, useSingleChecklistContext } from "../services/reducer";
import PreviewDialog from "./PreviewDialog";

type Props = {
  checklist: any;
  inProgress?: boolean;
  timeLabel?: string;
  timeField?: string;
};

const useStyles = makeStyles(({ spacing, palette }: Theme) =>
  createStyles({
    root: {
      textDecoration: "none",
    },
    checklistCard: {},
    header: {
      paddingTop: "2px !important",
      paddingBottom: "2px !important",
    },
    card: {
      paddingTop: "2px !important",
      paddingBottom: "2px !important",
      minHeight: "124px",
      display: 'flex',
      justifyContent: 'space-between'
    },

    thumbnailContainer: {
      display: 'flex',
      alignItems: "center"
    },
    thumbnail: {
      objectFit: "contain",
      maxWidht: "240px",
      maxHeight: "120px"
    }
  })
);

const ChecklistCard = ({
  checklist,
  inProgress,
  timeLabel,
  timeField,
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  timeLabel = timeLabel ?? t("checklist.checklistCard.deadline");
  timeField = timeField ?? "dueTime";
  const { user, tenantId } = useChecklistContext();
  const { state, dispatch } = useSingleChecklistContext();
  const {
    state: { activeSignature },
  } = useSignatureContext();

  const signatureId = activeSignature?.id?.toString();

  const handleChecklistClick = () => {
    if (!inProgress) {
      dispatch({
        type: actions.START,
        payload: {
          checklistId: checklist.checklistId,
          tenantId,
          siteId: user.siteId,
          assignedId: checklist.assignedId,
          userId: user.adalUser.oid,
          username: user.adalUser.preferred_username,
          sessionId: checklist.sessionId,
          signatureId: signatureId,
        },
      });
    }
  };

  const nextStateTime =
    checklist && checklist[timeField] && DateTime.fromISO(checklist[timeField]);
  const today = DateTime.local().day;
  const nextStateDay = nextStateTime.day;
  const isStateOnAnotherDay = nextStateDay && nextStateTime.day !== today;

  return (
    <Card key={checklist.assignedId} className={classes.checklistCard}>
      <CardHeader
        className={classes.header}
        title={checklist.name ?? "Uten navn"}
      />
      <CardContent className={classes.card}>
        {nextStateTime && (
          <Typography variant="body1" display="block">
            <b>{timeLabel}</b>&nbsp;
            {isStateOnAnotherDay
              ? nextStateTime.toFormat("HH:mm dd.MM.yyyy.")
              : nextStateTime.toFormat("HH:mm")}
          </Typography>
        )}
        <div className={classes.thumbnailContainer}>
          {checklist.image && <MediaRender src={checklist.image} className={classes.thumbnail} />}
        </div>
      </CardContent>
      <CardActions>
        <Button
          sx={{ marginRight: '0.4em' }}
          component={Link}
          color="primary"
          to={`/checklist/${checklist.assignedId}?sessionId=${checklist.sessionId}&backTo=/checklist/dashboard/high`}
          variant="contained"
          onClick={handleChecklistClick}
        >
          {inProgress ? t("Continue") : t("Start")}
        </Button>
        {!inProgress && <PreviewDialog checklist={checklist} />}
      </CardActions>
    </Card>
  );
};

export default ChecklistCard;
