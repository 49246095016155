import * as React from "react";
import { Box, Theme, Typography } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import clsx from "clsx";
import { Icon } from "@iconify/react";
import thermometerIcon from "@iconify/icons-mdi/thermometer";
import humidityIcon from "@iconify/icons-mdi/water-percent";
import { useTranslation } from "react-i18next";
import moleculeCo2Icon  from "@iconify/icons-mdi/molecule-co2";
import pressureIcon from "@iconify/icons-mdi/molecule-co2";
import "moment/locale/nb";
import { environment } from "../../../environments/environment";
//set locale. Find better way for this
if (i18n.language === "no") {
  moment.locale("nb");
} else {
  moment.locale("en");
}
import { FaFlag } from "react-icons/fa";
import { MdError } from "react-icons/md";
import { BiError } from "react-icons/bi";
import { AiFillAlert } from "react-icons/ai";
import { Alarm, AlarmType } from "../../alarm";
import { RiLightbulbFill } from "react-icons/ri";
import { IoIosWarning } from "react-icons/io";

import i18n from "../../react-components/i18n";
import moment from "moment";
import "moment/locale/nb";
//set locale. Find better way for this
if (i18n.language === "no") {
  moment.locale("nb");
} else {
  moment.locale("en");
}

type Props = {
  door?: Array<any>;
  temp?: Array<any>;
  humidity?: Array<any>;
  ppm?: Array<any>;
  pressure?: Array<any>;
  rules?: any;
  entityTypeName?: string;
  warning?: boolean;
  alarm?: boolean;
  alarmEvent?: Alarm;
  warningEvent?: Alarm;
  entity?: any;
};

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    icon: {
      fontSize: 48,
      marginRight: theme.spacing(1),
      color: theme.palette.primary.main,
    },
    box: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      flexBasis: 0,
      flexGrow: 1,
      "&:not(:last-child)": {
        borderRight: `1px solid ${theme.palette.divider}`,
        marginRight: theme.spacing(2),
      },
    },
    timestamp: {
      marginLeft: theme.spacing(4),
    },
    iconWarning: {
      fontSize: 24,
      marginRight: theme.spacing(1),
      color: theme.palette.primary.main,
    },
  });

const useStyles = makeStyles(styles);

const Info = ({ door, temp, humidity, rules,ppm,pressure, entityTypeName, warning, alarm, alarmEvent,
  warningEvent, entity }: Props) => {

  const classes = useStyles();
  const { t } = useTranslation();

  // console.log("HEYOOOO", moment(temp[0].lastValue).fromNow());
  // console.log(i18n.language);

  //set locale. Find better way for this
  // if (i18n.language === "no") {
  //   moment.locale("nb");
  // } else {
  //   moment.locale("en");
  // }

  // moment.locale("en");

  const link = () => (window.location.href = `entity/yoyo`);
  //?date=${warningEvent.createdOn}

  return (
    <Box display="flex" justifyContent="space-between" className={classes.root}>
      {door && door.length > 0 && (
        <Box className={classes.box}>
          <Box display="flex">
            <i className={clsx("material-icons", classes.icon)}>sensor_door</i>
            <Typography variant="h3">
              {door[0].value === 1 ? t("Open") : t("Closed")}
            </Typography>
          </Box>
          <Typography variant="subtitle1" className={classes.timestamp}>
            {moment(door[0].lastValue).fromNow()}
          </Typography>
        </Box>
      )}
      {rules.temperature && (
        <Box className={classes.box}>
          <Box display="flex" alignItems="center">
            <Icon
              icon={thermometerIcon}
              className={classes.icon}
              fr={undefined}
            />
            {temp && temp.length > 0 ? (
              <Typography variant="h3">{temp[0].value} &deg;C</Typography>
            ) : (
              <Typography variant="h3">{t("Entity.Manual")}</Typography>
            )}
            {/* {warning && (
              <RiLightbulbFill color="orange" title={t("alarmStatus.yellow")} />
            )}
            {alarm && (
              <RiLightbulbFill color="red" title={t("alarmStatus.red")} />
            )} */}
          </Box>
          {entityTypeName && <Typography variant="subtitle1">{entityTypeName}</Typography>}
          {temp && temp.length > 0 && (
            <Typography variant="subtitle1" className={classes.timestamp}>
              {moment(temp[0].lastValue).fromNow()}
            </Typography>
          )}
          {warning && (
            <Box display="flex" onClick={link}>
              <RiLightbulbFill
                className={classes.iconWarning}
                color="orange"
                title={t("alarmStatus.yellow")}
              />
              {warningEvent && (
                <Typography>
                  {t("alarmStatus.yellowSince")}
                  <a
                    href={`entity/${entity.id}?date=${warningEvent.createdOn}`}
                  >
                    {moment(warningEvent.createdOn).fromNow()}
                  </a>
                </Typography>
              )}
            </Box>
          )}
          {alarm && (
            <Box display="flex">
              <IoIosWarning
                className={classes.iconWarning}
                color="red"
                title={t("alarmStatus.red")}
              />
              {alarmEvent && (
                <Typography>
                  {t("alarmStatus.redSince")}
                  <a href={`entity/${entity.id}?date=${alarmEvent.createdOn}`}>
                    {moment(alarmEvent.createdOn).fromNow()}
                  </a>
                </Typography>
              )}
            </Box>
          )}
        </Box>
      )}
      {rules.humidity && (
        <Box className={classes.box}>
          <Box display="flex">
            <Icon icon={humidityIcon} className={classes.icon} fr={undefined} />
            <Typography variant="h3">
              {humidity && humidity.length > 0
                ? `${humidity[0].value}%`
                : t("Entity.Manual")}
            </Typography>
          </Box>
          {humidity && humidity.length > 0 && (
            <Typography variant="subtitle1" className={classes.timestamp}>
              {moment(humidity[0].lastValue).fromNow()}
            </Typography>
          )}
        </Box>
      )}
      {environment.featureFlag.co2 && rules.ppm && (
        <Box className={classes.box}>
          <Box display="flex">
            <Icon icon={moleculeCo2Icon} className={classes.icon} fr={undefined} />
            <Typography variant="h3">
              {ppm && ppm.length > 0
                ? `${ppm[0].value} PPM`
                : t("Entity.Manual")}
            </Typography>
          </Box>
          {ppm && ppm.length > 0 && (
            <Typography variant="subtitle1" className={classes.timestamp}>
              {moment(ppm[0].lastValue).fromNow()}
            </Typography>
          )}
        </Box>
      )}
      {environment.featureFlag.co2 && rules.pressure && (
        <Box className={classes.box}>
          <Box display="flex">
            <Icon icon={moleculeCo2Icon} className={classes.icon} fr={undefined} />
            <Typography variant="h3">
              {pressure && pressure.length > 0
                ? `${pressure[0].value} hPa`
                : t("Entity.Manual")}
            </Typography>
          </Box>
          {pressure && pressure.length > 0 && (
            <Typography variant="subtitle1" className={classes.timestamp}>
              {moment(pressure[0].lastValue).fromNow()}
            </Typography>
          )}
        </Box>
      )}
    </Box>
  );
};

export default Info;
