import React, { FC } from 'react';
import { Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: "#fff",
      borderRadius: '15px',
      boxShadow: 'rgba(149, 157, 165, 0.5) 0px 12px 20px',
      marginBottom: '2rem'
    },
    container: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      height: '3rem',
      paddingLeft: '1rem',
      color: theme.palette.primary.main,
      fontSize: 24
    },
    contentContainer: {
      paddingLeft: "1.5rem",
      paddingRight: "1.5rem",
      paddingBottom: "1rem",
      paddingTop: 0,
    },
    text: {
      marginLeft: '1rem',
      color: 'black',
      fontWeight: 500
    },
  })
);

type Props = {
  children: any,
  Icon?: any;
  title?: string;
  className?: string;
}

const SectionContainer: FC<Props> = ({ children, Icon, title, className='' }) => {

  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)}>
      {(!!title || !!Icon) && <div className={classes.container}>
        {!!Icon && Icon}
        {!!title && <p className={classes.text} >{title}</p>}
      </div>}
      <div className={classes.contentContainer}>
        {children}
      </div>
    </div>
  );
}

export default SectionContainer;