import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { RouterModule } from "@angular/router";
import { BrowserModule } from "@angular/platform-browser";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MaterialModule } from "./material.module";
import { InfrastructureModule } from "./infrastructure/infrastructure.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppComponent } from "./app.component";
import { HeaderComponent } from "./common/header/header.component";
import { MenuComponent } from "./common/menu/menu.component";
import { ProfileComponent } from "./common/profile/profile.component";
import { AddUserComponent } from "./user-registering/add-user/add-user.component";
import { MessageCenterComponent } from "./message-center/message-center.component";
import { ComponentLayoutComponent } from "./component-layout/component-layout.component";
import { ErrorComponent } from "./error/error-component.component";
import { LoginComponent } from "./login/LoginReactWrapper";
import { UserListComponent } from "./user-registering/user-list/user-list.component";
import { RegisterDeviceComponent } from "./devices-registering/register-device/register-device.component";
import { DevicesListComponent } from "./devices-registering/devices-list/devices-list.component";
import { InitialScreenComponent } from "./initial-screen/initial-screen.component";
import { DashboardComponent } from "./initial-screen/dashboard/dashboard.component";
import { TempWidgetComponent } from "./initial-screen/dashboard/temp-widget/temp-widget.component";
import { ReportStartScreenComponent } from "./reports/report-start-screen/report-start-screen.component";
import { TemperatureRegisteringComponent } from "./reports/temperature-registering/temperature-registering.component";
import { TemperatureConfirmComponent } from "./reports/temperature-confirm/temperature-confirm.component";
import { TemperatureSnapshotComponent } from "./temp-inspector/temperature-snapshot/temperature-snapshot.component";
import { ReportLatestComponent } from "./temp-inspector/report-latest/report-latest.component";
import { ReportHistoryComponent } from "./report-history/report-history.component";
import { FiltersComponent } from "./report-history/filters/filters.component";
import { DeviceGraphicComponent } from "./report-history/device-graphic/device-graphic.component";
import { DateSelectComponent } from "./report-history/date-select/date-select.component";
import { DeviationActionsSliderComponent } from "./report-history/deviation-actions-slider/deviation-actions-slider.component";
import { TabsComponent } from "./report-history/tabs/tabs.component";
import { DeviationsModalComponent } from "./report-history/deviations-modal/deviations-modal.component";
import { SubmitConfirmComponent } from "./temp-inspector/submit-confirm/submit-confirm.component";
import { SiteSelectComponent } from "./site/site-select/site-select.component";
import { EntitiesStatisticsComponent } from "./statistics/entities-statistics/entities-statistics.component";
import { ManualReportComponent } from "./reports/manual/manual-report.component";

import { APP_ROUTES } from "./app.routes";

import { SignalsModule } from "./signals/signals.module";
import { EntityModule } from "./entity/entity.module";
import { Angular2UsefulSwiperModule } from "angular2-useful-swiper";

import { HttpClientModule } from "@angular/common/http";
import { ReportHistoryService } from "./report-history/report-history.service";

import { AuthenticationGuard } from "./system/AuthenticationGuard";
import { DataService } from "./reports/data.service";
import { TempInspectorModule } from "./temp-inspector/temp-inspector.module";

import { MatNativeDateModule } from "@angular/material/core";
import { DeviationexpansionComponent } from "./report-history/deviationexpansion/deviationexpansion.component";
import { DoorsCleanComponent } from "./doors-clean/doors-clean.component";
import { NgCircleProgressModule } from "ng-circle-progress";
import { ThermoFormComponent } from "./entity/thermo-form/thermo-form-component";
import { OpenCloseFormComponent } from "./entity/open-close-form/open-close-form-component";
import { ValueFormComponent } from "./reports/manual/value-form/value-form.component";
import { MainPipe } from "./main-pipe.module.ts";
import { HumidityFormComponent } from "./entity/humidity-form/humidity-form-component";
import { DoorsCleanReactWrapperComponent } from "./doors-clean-react/DoorsCleanReactWrapper";
import { RoomsReactComponent } from "./rooms-raect/RoomsReactWrapper";
import { ReportsComponent } from "./reports-react/ReportsReactWrapper";
import { I18NextModule } from "angular-i18next";
import { I18N_PROVIDERS } from "./i18n-providers";
import { ChangeLanguageWrapperComponent } from "./change-language-react/ChangeLanguageReactWrapper";
import { SiteSettingsComponent } from "./site-settings-react/SiteSettingsReactWrapper";
import { ChecklistWidgetComponent } from "./dashboard-react/ChecklistComponent/ChecklistWidgetWrapper";
import { UnauthpageComponent } from "./common/unauthpage/unauthpage.component";
import { ServiceWorkerModule } from "@angular/service-worker";
import { environment } from "../environments/environment";

import localeNo from "@angular/common/locales/nb";
import { registerLocaleData } from "@angular/common";
import { NotificationSettingsComponent } from "./notifications-settings-react/NotificationSettingsReactWrapper";
import { MatCardModule } from "@angular/material/card";
import { AlarmsComponent } from "./alarms-react/AlarmsReactWrapper";
import { MaintenanceComponent } from "./maintenance-react/MaintenanceReactWrapper";
import { ChecklistReactModule } from "./checklist-react/checklist-react.module";
import { EditEntityFormReactModule } from "./edit-entity-form-react/edit-entity-form-react.module";

import {
  MsalModule,
  MsalService,
} from "@azure/msal-angular";
import {
  PublicClientApplication,
  BrowserCacheLocation,
} from "@azure/msal-browser";
import { UserProfileComponent } from "./user-profile-react/UserProfileReactWrapper";

registerLocaleData(localeNo, "no");

@NgModule({
  declarations: [
    AppComponent,
    UnauthpageComponent,
    HeaderComponent,
    ProfileComponent,
    MenuComponent,
    AddUserComponent,
    UserListComponent,
    RegisterDeviceComponent,
    DevicesListComponent,
    InitialScreenComponent,
    DashboardComponent,
    TempWidgetComponent,
    ReportStartScreenComponent,
    TemperatureRegisteringComponent,
    TemperatureConfirmComponent,
    TemperatureSnapshotComponent,
    ReportLatestComponent,
    ReportHistoryComponent,
    FiltersComponent,
    DeviceGraphicComponent,
    DateSelectComponent,
    DeviationActionsSliderComponent,
    TabsComponent,
    DeviationsModalComponent,
    DeviationexpansionComponent,
    SubmitConfirmComponent,
    MessageCenterComponent,
    ComponentLayoutComponent,
    ErrorComponent,
    SiteSelectComponent,
    EntitiesStatisticsComponent,
    DoorsCleanComponent,
    ManualReportComponent,
    ValueFormComponent,
    DoorsCleanReactWrapperComponent,
    RoomsReactComponent,
    ReportsComponent,
    ChangeLanguageWrapperComponent,
    SiteSettingsComponent,
    AlarmsComponent,
    NotificationSettingsComponent,
    LoginComponent,
    ChecklistWidgetComponent,
    MaintenanceComponent,
    UserProfileComponent,
  ],
  entryComponents: [
    DateSelectComponent,
    DeviationsModalComponent,
    DeviationexpansionComponent,
    SiteSelectComponent,
    ThermoFormComponent,
    HumidityFormComponent,
    OpenCloseFormComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(APP_ROUTES, { enableTracing: true }),
    EntityModule,
    MaterialModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    InfrastructureModule,
    HttpClientModule,
    SignalsModule,
    Angular2UsefulSwiperModule,
    BrowserAnimationsModule,
    TempInspectorModule,
    MatNativeDateModule,
    MatCardModule,
    NgCircleProgressModule.forRoot({
      // set defaults here
      radius: 100,
      outerStrokeWidth: 16,
      innerStrokeWidth: 8,
      outerStrokeColor: "#78C000",
      innerStrokeColor: "#C7E596",
      animationDuration: 300,
    }),
    MainPipe,
    I18NextModule.forRoot(),
    ServiceWorkerModule.register("/ngsw-worker.js", {
      enabled: environment.production,
    }),
    ChecklistReactModule,
    EditEntityFormReactModule,
    MsalModule.forRoot(
      new PublicClientApplication({
        // MSAL Configuration
        auth: {
          clientId: environment.clientId,
          redirectUri: environment.redirectUrl,
          authority: 'https://login.microsoftonline.com/577c80ff-449e-4fb3-897f-269d41e57e8b',
        },
        cache: {
          cacheLocation: BrowserCacheLocation.LocalStorage,
          storeAuthStateInCookie: true, // set to true for IE 11
        },
        system: {
          loggerOptions: {
            loggerCallback: () => { },
            piiLoggingEnabled: false,
          },
        },
      }),
      null,
      null
    ),
  ],
  exports: [
    DateSelectComponent,
    DeviationsModalComponent,
    DashboardComponent,
    ManualReportComponent,
  ],
  providers: [
    MsalService,
    AuthenticationGuard,
    ReportHistoryService,
    DataService,
    MainPipe,
    I18N_PROVIDERS,
  ],

  bootstrap: [AppComponent],
})
export class AppModule { }
