import { Attachment, Close } from "@mui/icons-material";
import { Theme, Typography } from "@mui/material";
import { makeStyles, createStyles } from '@mui/styles';
import * as React from "react";
import { AttachmentFile } from "./attachment";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useTranslation } from "react-i18next";

const MAX_FILE_SIZE = 52_428_800;

export type SupportedMediaFormatsType = '.jpg'| '.jpeg'| '.gif'| '.png'| '.apng'| '.svg'| '.bmp'| '.ico'| '.mp4'| '.webm'| '.ogg'|'.pdf';

export const extractExtension: (source: string) => SupportedMediaFormatsType = (source: string) => {
  const match = source.match(/\.(jpg|jpeg|gif|png|apng|svg|bmp|ico|mp4|webm|ogg|pdf)$/g);
  return (match && match.length) ? match[0] as SupportedMediaFormatsType : null;
}

export const isValidExtension = (source: string) => !!extractExtension(source);

type Props = {
  multiple?: boolean;
  attachments: AttachmentFile;
  onAttachmentsChange: (att: AttachmentFile) => void;
};

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      listStyle: "none",
    },
    fileList: {
      margin: `${theme.spacing(2)}px 0`,
      padding: 0,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      listStyle: "none",
    },
    fileInput: {
      display: "none",
    },
    fileItem: {
      padding: `${theme.spacing(2)}px 0`,
      display: "flex",
      width: "100%",
      alignItems: "center",
      flexDirection: "row",
      justifyContent: "space-between",
      borderBottom: `1px solid ${theme.palette.grey.A100}`,
    },
    dragZone: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-around",
      alignItems: "center",
      minHeight: "5rem",
      border: `1px dashed ${theme.palette.primary.main}`,
      padding: theme.spacing(2),
      "&:hover": {
        backgroundColor: "rgba(100, 200, 255, 0.08)",
        cursor: "pointer",
      },
    },
    dragZoneIcon: {
      color: theme.palette.primary.main,
    },
  });
});

const FileUpload: React.FC<Props> = ({ multiple=false, attachments, onAttachmentsChange}) => {
  const { t } = useTranslation();

  const [isInvalidExtension, setInvalidExtension] = React.useState<boolean>(false);
  const [isFileTooLarge, setFileTooLarge] = React.useState<boolean>(false);

  const classes = useStyles();
  const fileInput = React.createRef<HTMLInputElement>();
  const addFiles = (fileTarget: any) => {
    if (!fileTarget.files || fileTarget.files.length === 0) {
      return;
    }
    // Remove in order to support uploading multiple media documents.
    if (!multiple && fileTarget.files.length > 1) {
      alert(t("Only one file can be uploaded."));
      return;
    }
    const files = fileTarget.files;
    for (var i = 0; i < files.length; i++) {
      const f = files[i];
      if(!isValidExtension(f.name)) {
        setInvalidExtension(true);
        return;
      }
      console.log(f.size)
      if(f.size >= MAX_FILE_SIZE) {
        setFileTooLarge(true);
        return;
      }
      setFileTooLarge(false);
      setInvalidExtension(false);
      attachments.append(f);
    }
    onAttachmentsChange(AttachmentFile({ ...attachments }));
    var target: any = fileTarget;
    target.value = null;
  };
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    addFiles(e.target);
  };
  const handleRemoveFile = (file: File) => () => {
    const newAttachments = AttachmentFile({
      ...attachments,
      files: attachments.files.filter((x) => x !== file),
    });
    onAttachmentsChange(newAttachments);
  };
  const handleAddFile = () => {
    fileInput?.current?.click();
  };
  const onDragOver = (e: any) => {
    let event = e as Event;
    event.stopPropagation();
    event.preventDefault();
  };
  const onDragEnter = (e: any) => {
    let event = e as Event;
    event.stopPropagation();
    event.preventDefault();
  };
  const onFileDrop = (e: any) => {
    let event = e as Event;
    event.stopPropagation();
    event.preventDefault();
    addFiles(e.dataTransfer);
  };
  return (
    <div className={classes.root}>
      <ul className={classes.fileList}>
        {attachments.files.map((x, i) => (
          <li className={classes.fileItem} key={i}>
            <span>
              <Attachment />
              {x.name}
            </span>
            <Close onClick={handleRemoveFile(x)}></Close>
          </li>
        ))}
      </ul>
      {attachments.files.length < 1 && (
        <>
          {isInvalidExtension && <p style={{textAlign: 'center', color: 'red', marginBottom: '0.5em'}}>{t("The format of the uploaded file is not supported")}.</p>}
          {isFileTooLarge && <p style={{textAlign: 'center', color: 'red', marginBottom: '0.5em'}}>{t("The file is too large, maximum allowed size is 50MB")}.</p>}
          <input
            className={classes.fileInput}
            multiple
            type="file"
            ref={fileInput}
            onChange={handleFileChange}
          />
          ​
          <div
            onDragEnter={onDragEnter}
            onDragOver={onDragOver}
            onDrop={onFileDrop}
            className={classes.dragZone}
            onClick={handleAddFile}
          >
            <CloudUploadIcon className={classes.dragZoneIcon} />
            <Typography variant="h6" gutterBottom>
              {/* {t("Orders:newOrder.fileDropAriaTitle")} */}
              Last opp bilde
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
              {/* {t("Orders:newOrder.fileDropAriaCaption")} */}
              Dra og slipp
            </Typography>
          </div>
        </>
      )}
    </div>
  );
};
export default FileUpload;
