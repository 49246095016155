import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { DateSelectComponent } from "../../report-history/date-select/date-select.component";
import { MatBottomSheet } from "@angular/material/bottom-sheet";
import * as moment from "moment";
import { ChartPeriod } from "../../entity/chartperiod";
import { I18NextService } from "angular-i18next";

@Component({
  selector: "tabs",
  templateUrl: "./tabs.component.html",
  styleUrls: ["./tabs.component.scss"],
})
export class TabsComponent implements OnInit {
  @Output() changedPeriod = new EventEmitter<ChartPeriod>();

  showTab: string;
  startOfWeek: any;
  endOfWeek: any;
  month: any;
  isNavigateNextEnabled: boolean;

  private period: ChartPeriod;

  constructor(
    private bottomSheet: MatBottomSheet,
    public i18next: I18NextService
  ) {
    this.showTab = i18next.t('Week')
  }

  ngOnInit() {
    this.period = ChartPeriod.create(this.showTab);
    this.periodChanged();
  }

  openDateModal() {
    this.bottomSheet.open(DateSelectComponent);
  }

  selectTab(tabValue: any) {
    this.showTab = tabValue.tab.textLabel;

    this.periodChanged();
  }

  private periodChanged() {
    this.period = this.period.setType(this.showTab);

    this.startOfWeek = this.period.startDate.format("DD MMMM");
    this.endOfWeek = this.period.endDate.format("DD MMMM");
    this.month = this.period.startDate.format("MMMM");
    this.isNavigateNextEnabled = this.period.endDate.isBefore(moment());

    this.changedPeriod.next(this.period);
  }

  onClickNavigate_before() {
    this.period = this.period.prevPeriod();
    this.periodChanged();
  }

  onClickNavigate_next() {
    this.period = this.period.nextPeriod();
    this.periodChanged();
  }
}
