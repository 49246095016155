import * as React from "react";
import { List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { Icon } from "@iconify/react";
import thermometerIcon from "@iconify/icons-mdi/thermometer";
import humidityIcon from "@iconify/icons-mdi/water-percent";
import { useTranslation } from "react-i18next";
import * as moment from "moment";
import { RiLightbulbFill } from "react-icons/ri";

type Props = {
  door?: Array<any>;
  temp?: Array<any>;
  humidity?: Array<any>;
  rules?: any;
  entityTypeName?: string;
  alarm?: boolean;
  warning?: boolean;
};

const useStyles = makeStyles({
  icon: {
    fontSize: 32,
  },
});

const MobileInfo = ({ door, temp, humidity, rules, entityTypeName, alarm, warning}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <List dense>
      {door && door.length > 0 && (
        <ListItem>
          <ListItemIcon>
            <i className={clsx("material-icons", classes.icon)}>sensor_door</i>
          </ListItemIcon>
          <ListItemText>
            {door[0].value === 1 ? t("Open") : t("Closed")}
          </ListItemText>
          <ListItemText>{moment(door[0].lastValue).fromNow()}</ListItemText>
        </ListItem>
      )}
      {rules.humidity && (
        <ListItem>
          <ListItemIcon>
            <Icon icon={humidityIcon} className={classes.icon} fr={undefined} />
          </ListItemIcon>
          <ListItemText>
            {humidity && humidity.length > 0
              ? `${humidity[0].value}%`
              : t("Entity.Manual")}
          </ListItemText>
          {humidity && humidity.length > 0 && (
            <ListItemText>
              {moment(humidity[0].lastValue).fromNow()}
            </ListItemText>
          )}
        </ListItem>
      )}
      {rules.temperature && (
        <ListItem>
          <ListItemIcon>
            <Icon
              icon={thermometerIcon}
              className={classes.icon}
              fr={undefined}
            />
          </ListItemIcon>
          <ListItemText>
            {temp && temp.length > 0 ? (
              <>{temp[0].value} &deg;C</>
            ) : (
              t("Entity.Manual")
            )}
            {warning && (
              <RiLightbulbFill color="orange" title={t("alarmStatus.yellow")} />
            )}
            {alarm && (
              <RiLightbulbFill color="red" title={t("alarmStatus.red")} />
            )}
          </ListItemText>
          <ListItemText>
            {entityTypeName}
          </ListItemText>
          {temp && temp.length > 0 && (
            <ListItemText>{moment(temp[0].lastValue).fromNow()}</ListItemText>
          )}
        </ListItem>
      )}
    </List>
  );
};

export default MobileInfo;
