import * as React from "react"
import { ActivatedRoute, Router } from "@angular/router";
import { EntityService } from "../entity/entity.service";
import { SiteService } from "../entity/site.service";
import { SignalsService } from "../signals/signals.service";
import { UserSiteService } from "../site/user-site.service";

type EditEntityContextTypes = {
  user: any;
  entityService: EntityService;
  signalsService: SignalsService;
  siteService: SiteService;
  userSiteService: UserSiteService;
  router: Router;
  route?: ActivatedRoute
};

const EditEntityContext = React.createContext<EditEntityContextTypes>(
  undefined
);

export default EditEntityContext