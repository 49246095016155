import React from "react";
import { Site } from "../entity/site";
import { SiteService } from "../entity/site.service";
import { UserSiteService } from "../site/user-site.service";

export function useCurrentSite(userSiteService: UserSiteService, siteService: SiteService) {
  const [site, setSite] = React.useState<Site>();
  React.useEffect(() => {
    const siteId = userSiteService.getSiteId();
    if(siteId) {
      siteService.getSite(siteId)
        .subscribe({
          next(value) {
            setSite(value);
          },
        })
    }
  }, []);

  return { site };
}