import {
  Typography,
  Tooltip,
  TextField,
  Button,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";

import InfoIcon from "@mui/icons-material/Info";

import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  useDescribeChecklist,
  useSiteDescribeChecklist,
  useDisableMediaToChecklist,
} from "../../services/checklistService";
import DescriptionEditor from "./edit-point/DescriptionEditor";
import MarkdownDisplay from "../../../react-components/MarkdownDisplay";
import MediaRender from "../../../react-components/MediaRender";
import { useChecklistContext } from "../../ChecklistReactWrapper";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

type Props = {
  checklist: any;
  tenantId: string;
  assignedId: string;
  onSuccess: (events: any[]) => void;
  onCancel: () => void;
  isEdit: boolean;
  isSiteEdit: boolean;
};

const EditChecklistDescription = ({
  checklist,
  tenantId,
  assignedId,
  onSuccess,
  onCancel,
  isEdit,
  isSiteEdit,
}: Props) => {
  const { t } = useTranslation();

  const [name, setName] = React.useState<string>(checklist?.name ?? "");
  const [siteName, setSiteName] = React.useState<string>(
    checklist?.siteName ?? ""
  );
  const { user, siteService } = useChecklistContext();
  const [site, setSite] = React.useState<any>(null);

  useEffect(() => {
    siteService.getSite(user.siteId).subscribe((site: any) => {
      setSite(site);
    });
  }, []);

  console.log(checklist);

  const [description, setDescription] = React.useState<string>(
    checklist?.description ?? ""
  );
  const [siteDescription, setSiteDescription] = React.useState<string>(
    checklist?.siteDescription ?? ""
  );
  const [images, setImages] = React.useState<string[]>(checklist?.images ?? []);

  useEffect(() => {
    setImages(checklist.images ?? []);
  }, [checklist?.images]);

  const [markdownImages, setMarkdownImages] = React.useState<string[]>(
    checklist?.markdownImages ?? []
  );

  useEffect(() => {
    setMarkdownImages(checklist.markdownImages ?? []);
  }, [checklist?.markdownImages]);

  const [imageLibrary, setImageLibrary] = React.useState(
    images?.concat(markdownImages)
  );

  const isNameOverriden = !!checklist.siteName;
  const isDescriptionOverriden = !!checklist.siteDescription;

  const handleClickSave = async () => {
    const hasChanged =
      description !== checklist.description || name !== checklist.name;
    const hasChangedSite =
      siteDescription !== checklist.siteDescription ||
      siteName !== checklist.siteName;

    if (isEdit && hasChanged) {
      const event = await useDescribeChecklist({
        checklistId: checklist.checklistId,
        name,
        description,
        tenantId,
      });
      onSuccess([event]);
    }
    if (isSiteEdit && hasChangedSite) {
      const event = await useSiteDescribeChecklist({
        checklistId: checklist.checklistId,
        name: siteName,
        description: siteDescription,
        tenantId,
        assignedId,
      });
      onSuccess([event]);
    }
  };

  const handleClickCancel = () => {
    onCancel();
    setName(checklist.name);
    setSiteName(checklist.siteName);
    setDescription(checklist.description);
    setSiteDescription(checklist.siteDescription);
  };

  const disableImage = async (image: string) => {
    const imagePath = image.split("fileupload/")[1]; //find better way?
    const filteredImages = images.filter((img) => img != image);
    setImages(filteredImages);

    // disable image here
    await useDisableMediaToChecklist({
      checklistId: checklist.checklistId,
      tenantId: site.tenantId.toString(),
      imagePath: imagePath,
    });
  };

  return (
    <>
      {isEdit || isSiteEdit ? (
        <>
          <TextField
            value={isEdit ? name : siteName?siteName:name}
            onChange={(e) => (isEdit ? setName : setSiteName)(e.target.value)}
            label={t("Checklist Name")}
          />
          <div style={{ marginTop: "1em", marginBottom: "1em" }}>
            <DescriptionEditor
              value={isEdit ? description : siteDescription?siteDescription:description}
              onChange={isEdit ? setDescription : setSiteDescription}
              images={images}
              markdownImages={markdownImages}
              checklist={checklist}
            />
          </div>
          <Button
            variant="contained"
            color="primary"
            style={{ marginLeft: "1em", marginBottom: "1em" }}
            onClick={handleClickSave}
          >
            {t("Save")}
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={{ marginLeft: "1em", marginBottom: "1em" }}
            onClick={handleClickCancel}
          >
            {t("Cancel")}
          </Button>
        </>
      ) : (
        <>
          <Grid
            style={{
              marginBottom: "1em",
              marginRight: "1em",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
            container
            component={"div"}
          >
            {isNameOverriden && (
              <Tooltip
                title={`${t("The template name")}: " ${checklist.name} " ${t(
                  "is overridden"
                )}.`}
              >
                <InfoIcon />
              </Tooltip>
            )}
            <Typography variant="h4" style={{ marginRight: "auto" }}>
              {isNameOverriden ? siteName : name}
            </Typography>
          </Grid>
          <Grid style={{ marginBottom: "1em", marginRight: "1em" }} container>
            {isDescriptionOverriden && (
              <Tooltip
                title={`${t("The template description")}: " ${
                  checklist.description
                } " ${t("is overridden")}.`}
              >
                <InfoIcon />
              </Tooltip>
            )}
            <MarkdownDisplay>
              {isDescriptionOverriden ? siteDescription : description}
            </MarkdownDisplay>
          </Grid>
        </>
      )}
      {/* {image && <MediaRender src={image} />} */}
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          {t("editChecklist.images")}
        </AccordionSummary>
        <AccordionDetails>
          {images &&
            images.map((image, key) => {
              return (
                <Grid item xs={12} md={6}>
                  <MediaRender src={image} key={key} />
                  <Button onClick={() => disableImage(image)}>{`${t(
                    "editChecklist.removeImage"
                  )}`}</Button>
                </Grid>
              );
            })}
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          {t("editChecklist.imageLibrary")}
        </AccordionSummary>
        <AccordionDetails>
          {markdownImages &&
            markdownImages.map((image, key) => {
              return (
                <Grid item xs={12} md={6}>
                  <MediaRender src={image} key={key} />
                </Grid>
              );
            })}
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default EditChecklistDescription;
