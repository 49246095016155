import { Typography, Theme, alpha } from "@mui/material";
import { makeStyles } from "@mui/styles";
import * as React from "react";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Route, Navigate, Routes } from "react-router";
import { BrowserRouter } from "react-router-dom";
import {
  SignatureContext,
  useSignatureState,
} from "../../signature-react/SignatureContext";
import SignatureSelect from "../../signature-react/SignatureSelect";
import { Guid } from "../../system/guid";
import { useChecklistContext } from "../ChecklistReactWrapper";
import { useGetSignatures } from "../services/signatureService";
import ChecklistOverview from "./ChecklistOverview";
import ChecklistPage from "./ChecklistPage";
import EditChecklist from "./edit-checklist/EditChecklist";
import EditChecklistList from "./edit-checklist/EditChecklistList";
import ChecklistOverviewPage from "./overview/ChecklistOverviewPage";
import ViewChecklist from "./ViewChecklist";
import { usePostSignatures, usePutSignature } from "../../signature-react/SignatureService";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: "1rem",
  },
  notSignedIn: {
    zIndex: 10,
    backgroundColor: alpha(theme.palette.primary.dark, 0.6),
    position: "fixed",
    width: "100vw",
    height: "100vh",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "stretch",

    color: theme.palette.primary.contrastText,
  },
}));

const useOverlayStyles = makeStyles((theme: Theme) => ({
  signaturOverlay: {
    width: "60%",
    maxWidth: "400px",
    color: theme.palette.primary.contrastText,
    [theme.breakpoints.down("xs")]: {
      width: "90%",
    },
  },
  label: {
    color: theme.palette.primary.contrastText,
  },
  select: {
    "&:before": {
      borderColor: theme.palette.primary.contrastText,
    },
    "&:hover:not(.Mui-disabled):before": {
      borderColor: theme.palette.primary.contrastText,
    },
    "&:after": {
      borderColor: "blue",
    },
    "& label": {
      color: theme.palette.primary.contrastText,
    },
    "& label.Mui-focused": {
      color: theme.palette.primary.contrastText,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: theme.palette.primary.contrastText,
    },
    "& .MuiInput-underline:before": {
      borderBottomColor: theme.palette.primary.contrastText,
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before ": {
      borderBottomColor: theme.palette.primary.contrastText,
    },
    "& .MuiInputBase-input": {
      color: theme.palette.primary.contrastText,
      borderColor: theme.palette.primary.contrastText,
    },
    "& .MuiInputBase-root .": {
      "& fieldset": {
        borderColor: theme.palette.primary.contrastText,
        color: theme.palette.primary.contrastText,
      },
      "&:hover fieldset": {
        borderColor: theme.palette.primary.contrastText,
      },
      "&.Mui-focused fieldset": {
        borderColor: theme.palette.primary.contrastText,
      },
    },
    "& .MuiButtonBase-root": {
      color: theme.palette.primary.contrastText,
    },
  },
  icon: {
    fill: theme.palette.primary.contrastText,
  },
}));

const ChecklistRoutes = () => {
  const classes = useStyles();
  const overlayClasses = useOverlayStyles();

  const { user, tenantId } = useChecklistContext();

  const userId = user?.adalUser?.oid
    ? new Guid(user?.adalUser?.oid)
    : Guid.empty();
  const [{ data: signaturesData, error, loading: signatureLoading }, refetchSignatures] =
    useGetSignatures(userId);
  const data = signatureLoading ? signaturesData : (signaturesData ?? []);

  const currentTenantId = tenantId ? tenantId : Guid.empty();
  const [state, dispatch] = useSignatureState(userId, currentTenantId);
  const { activeSignature, } = state;
  const { t } = useTranslation();

  const automaticallyCreatedSignatureForCurrentUser = data?.find(
    (s) => (s as any).autoCreatedForUserId === userId.toString()
  );

  const regularSignatures = data?.filter(
    (s) => (s as any).autoCreatedForUserId == null
  ) ?? [];

  const mustSelectSignature = !!regularSignatures.length;

  const needsSignature = useMemo(() => {
    if (mustSelectSignature && !activeSignature) {
      return true;
    }
    return false;
  }, [mustSelectSignature, activeSignature]);

  useEffect(() => {
    if (signatureLoading) return;

    if (!activeSignature && !mustSelectSignature) {
      if (automaticallyCreatedSignatureForCurrentUser) {
        dispatch({ type: "selected-signature", activeSignature: automaticallyCreatedSignatureForCurrentUser });
      } else {
        // create a new signature for the user
        const id = user.adalUser.oid;

        const signature = {
          id: id,
          name: user.adalUser.name,
          pin: "",
          tenantId: tenantId.toString(),
          UserIds: [user.adalUser.oid],
          autoCreatedForUserId: user.adalUser.oid,
        };
        if (user.adalUser.name) {
          usePutSignature(signature, id).then(() => {
            refetchSignatures();
          });
        }
      }
    }
  }, [activeSignature, mustSelectSignature, automaticallyCreatedSignatureForCurrentUser, user, data]);

  useEffect(() => {
    console.log("Got data", data, userId);
    dispatch({ type: "signature-list", payload: data || [] });
  }, [data, signatureLoading]);

  if (signatureLoading) return <p>{t("Loading")}</p>;
  if (error && error?.response?.status !== 404) {
    console.log(error)
    return <p>Error!</p>;
  }

  return (
    <div className={classes.root}>
      <SignatureContext.Provider value={{ state, dispatch }}>
        {!needsSignature && !!regularSignatures.length && <SignatureSelect dark />}
        {needsSignature && (
          <div className={classes.notSignedIn}>
            <SignatureSelect
              className={overlayClasses.signaturOverlay}
              classes={overlayClasses}
            />
            <Typography variant="h2">
              {t("checklist.chooseSignature")}
            </Typography>
          </div>
        )}

        <BrowserRouter>
          <Routes>
            <Route
              path="/edit-checklist/:assignedId"
              element={<EditChecklist />}
            />
            <Route path="/edit-checklist" element={<EditChecklistList />} />
            <Route
              path="/checklist/overview/:assignedId"
              element={<ChecklistOverviewPage />}
            />
            <Route
              path="/checklist/overview/details/:checklistId"
              element={<ChecklistOverview />}
            />
            <Route path="/checklist/:checklistId" element={<ViewChecklist />} />
            <Route
              path="/checklist/report/:assignedId"
              element={<ChecklistOverviewPage />}
            />
            <Route
              path={"/checklist"}
              element={<Navigate to="/checklist/dashboard/high" />}
            />
            <Route
              path={"/checklist/dashboard"}
              element={<Navigate to="/checklist/dashboard/high" />}
            />
            <Route path="/checklist/dashboard/*" element={<ChecklistPage />} />
          </Routes>
        </BrowserRouter>
      </SignatureContext.Provider>
    </div>
  );
};

export default ChecklistRoutes;
