import {
  Theme,
  Typography,
} from "@mui/material";
import React, { FC, useEffect, } from "react";
import { useTranslation } from "react-i18next";
import { createStyles, makeStyles } from "@mui/styles";
import HandleChecklistDeviation from "./HandleChecklistDeviation";
import { DateTime } from "luxon";
import { fetchManySignatures, } from "../../services/signatureService";
import { Deviation } from "./useChecklistDeviations";


const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: "1em",
      marginBottom: "1em",
    },
  });

const useStyles = makeStyles(styles);


type Props = {
  tenantId: string;
  deviation: Deviation;
  onAddComment?: (handledEvent: any) => void;
  onHandle?: (handledEvent: any) => void;
  comment?: boolean;
  variant?: "point" | "checklist";
};

export const DeviationHandledComments: FC<Props> = ({ tenantId, deviation, onAddComment, onHandle, comment, variant }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();


  const [comments, setComments] = React.useState<Deviation['handledComments']>([]);

  const [signatureNamesMap, setSignatureNamesMap] = React.useState<Map<string, string>>(new Map<string, string>());

  useEffect(() => {
    fetchManySignatures(comments.map((comment) => comment.signatureId))
      .then((signatures) => {
        const newSignatureNamesMap = new Map<string, string>();
        signatures?.forEach((signature) => {
          newSignatureNamesMap.set(signature.id, signature.name);
        });
        setSignatureNamesMap(newSignatureNamesMap);
      });
  }, [comments]);

  useEffect(() => {
    setComments(deviation.handledComments);
  }, [deviation.handledComments.length]);

  if (!comments.length && !comment) {
    return (
      <HandleChecklistDeviation
        variant={variant}
        {...(variant === "point" ? { pointId: deviation.pointId } : {})}
        tenantId={tenantId}
        assignedId={deviation.assignedId}
        deviation={deviation}
        sessionData={{ sessionId: deviation.sessionId }}
        onHandle={(handledEvent) => {
          onHandle(handledEvent);

          // optimistic update
          setComments([...comments, {
            id: handledEvent.id,
            signatureId: handledEvent.signatureId,
            timestamp: handledEvent.timestamp,
            comment: handledEvent.comment,
          }]);
        }}
      />
    );
  }

  const justComments = comments.slice(1);

  return (
    <>
      <div style={{
        display: "flex",
      }}
      >
        <Typography gutterBottom>
          {DateTime.fromISO(comments[0].timestamp).toFormat('dd.MM.yyyy. HH:mm')}{", "}
          {t("checklist.deviationCard.handledComment")}:{" "}
          {comments[0].comment}
        </Typography>

        <HandleChecklistDeviation
          variant="comment"
          tenantId={tenantId}
          assignedId={deviation.assignedId}
          deviation={deviation}
          sessionData={{ sessionId: deviation.sessionId }}
          onHandle={(handledEvent) => {
            onAddComment(handledEvent);

            // optimistic update
            setComments([...comments, {
              id: handledEvent.id,
              signatureId: handledEvent.signatureId,
              timestamp: handledEvent.timestamp,
              comment: handledEvent.comment,
            }]);
          }}
        />
      </div>

      {!!justComments.length && (<>{t('checklist.deviationCard.commentsHistory')}:
        {justComments.map((comment, index) => (
          <div key={index}>
            <Typography gutterBottom>
              {signatureNamesMap.get(comment.signatureId) && (
                <p>
                  {t("checklist.deviationCard.handledBy")}:{" "}
                  <i>{signatureNamesMap.get(comment.signatureId)}</i>{", "}
                </p>
              )}
              {DateTime.fromISO(comment.timestamp).toFormat('dd.MM.yyyy. HH:mm')}{", "}
              {t("checklist.deviationCard.additionalCommentLabel")}:{" "}
              {comment.comment}
            </Typography>
          </div>
        ))}
      </>)}
    </>
  );
};

