import axios from "axios";
import { ENTITY_API_URL } from "../environments/environment.generated";

export function useTenantService() {
  return {
    getAll: async () => (await axios.get(`${ENTITY_API_URL}/api/tenants`))?.data,
    getOne: async (id: string) => (await axios.get(`${ENTITY_API_URL}/api/tenants/` + id))?.data,
    post: async (value: any, includeTypes: boolean) => (await axios.post(`${ENTITY_API_URL}/api/tenants`, value, { params: { includeTypes } }))?.data,
    put: async (id: string, value: any) => (await axios.put(`${ENTITY_API_URL}/api/tenants/` + id, value))?.data,
  }
}