<div class="deviation-list">
  <div class="deviation-list__heading">
    <a class="deviation-list__heading__go-back" (click)="goBack()">
      <i class="material-icons">keyboard_arrow_left</i>
    </a>
  </div>
  <div *ngIf="deviationList.length >= 0">
    <div *ngFor="let item of deviationList; let deviationIndex = index">
      <mat-card class="deviation-item">
        <div class="items horizontal entity-info" style="padding-left: 2em; padding-right: 2em;">

          <div class="items vertical min-top-height" style="text-align: start;">

            <div
              style="display: flex; flex-wrap: wrap; align-items: center; justify-content: start; gap: 0.5em; margin-bottom: 0.5em;">
              <div class="info-title">{{ "deviationProcedures.deviationAndPerios" | i18next }}:</div>
              <div class="details">
                {{ printDate(item.occuredTime) }}
              </div>
            </div>

            <div class="items horizontal" style="justify-content: start; gap: 1em;">
              <span style="text-align: start;">
                <div style="font-size: 1.5em;">
                  {{ getEntityName(item.entityId) }}
                </div>
              </span>

              <span style="font-size: 1.5em; font-weight: bold; color: #F90606;">
                {{ getValue(item.entityId) }} °C
              </span>
            </div>

            <div class="info-title" style="margin-top: 0.5em; margin-bottom: 0.5em;">{{ "deviationProcedures.limitValue"
              |
              i18next }}:
              {{getEntityMinMax(item.entityId).min}} Min, {{getEntityMinMax(item.entityId).max}} Max </div>
          </div>

          <div class="items horizontal" style="justify-content: end;">
            <span class="min-top-height">

              <div
                style="display: flex; flex-wrap: wrap; align-items: center; justify-content: start; gap: 0.5em; margin-bottom: 0.5em;">
                <div class="info-title">{{ "deviationProcedures.lastMeasuredTemperature" | i18next }}:</div>
                <div class="details">
                  {{ printDate(getLastMeasuredTime(item.entityId)) }}
                </div>
              </div>

              <app-current-temp-react-component [entityId]="item.entityId"></app-current-temp-react-component>

            </span>
          </div>

        </div>

        <div class="info-title" style="padding-left: 2em;">{{ "deviationProcedures.seeMoreInfo" | i18next }}</div>
        <app-temp-chart-react-component [entityId]="item.entityId" [refDate]="item.occuredTime"
          [deviationValue]="getValue(item.entityId)"></app-temp-chart-react-component>

        <div class="info-title" style="padding-left: 2em; margin-bottom: 1em;">{{
          "deviationProcedures.handleDeviationHere" | i18next }}</div>
        <mat-card-actions>
          <div class="items horizontal deviation-actions">
            <button mat-raised-button (click)="updateAction(deviationIndex, deviationMessageType.Empty)">
              Tomt
            </button>
            <button mat-raised-button (click)="updateAction(deviationIndex, deviationMessageType.Clean)">
              Vaske & rydde
            </button>

            <button mat-raised-button (click)="updateAction(deviationIndex, deviationMessageType.Delivery)">
              Varelevering
            </button>

            <button mat-raised-button (click)="updateAction(deviationIndex, deviationMessageType.Cooking)">
              Under tilberedning
            </button>
          </div>
          <div class="form-container" style="margin-top: 2em;">
            <form [formGroup]="getActionForm(deviationIndex)">
              <mat-form-field>
                <textarea rows="10" cols="80" placeholder="Skriv årsak for avvik eller velg et hurtig valg." matInput
                  formControlName="action">
                  </textarea>
              </mat-form-field>
            </form>
          </div>
        </mat-card-actions>
      </mat-card>
    </div>
    <div class="send-button-container">
      <button mat-button class="send-button" (click)="updateTempform()" [disabled]="!tempForm.get('deviation').valid">
        Submit
      </button>
    </div>
  </div>
</div>