import * as React from "react";
import { useState, } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Typography,
} from "@mui/material";
import {
  useHandleChecklistDeviation,
  useHandleDeviation,
} from "../../services/checklistService";
import { useChecklistContext } from "../../ChecklistReactWrapper";
import { useSignatureContext } from "../../../signature-react/SignatureContext";
import { useTranslation } from "react-i18next";
import AddCommentIcon from "@mui/icons-material/AddComment";

type Props = {
  tenantId: string;
  pointId?: string;
  assignedId: string;
  sessionData: any;
  deviation: any;
  onHandle: any;
  variant: "point" | "checklist" | "comment";
};

const HandleChecklistDeviation = ({
  tenantId,
  pointId,
  assignedId,
  sessionData,
  deviation,
  onHandle,
  variant,
}: Props) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [comment, setComment] = useState("");
  const { user } = useChecklistContext();
  const [showButton, setShowButton] = useState<boolean>(!deviation.handled);

  const {
    state: { activeSignature },
  } = useSignatureContext();

  const signatureId = activeSignature?.id?.toString();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setComment(event.target.value);
  };

  const toggleOpen = () => {
    setOpen(!open);
  };

  const siteId = user.siteId;
  const checklistId = deviation.checklistId;

  const handleSubmit = async () => {
    const handler =
      variant === "point" ? useHandleDeviation : useHandleChecklistDeviation;
    const event = {
      ...(variant === "point" ? { pointId } : {}),
      assignedId: assignedId,
      comment: comment,
      siteId,
      checklistId,
      sessionId: sessionData.sessionId,
      tenantId,
      userId: user.adalUser.oid,
      deviationId: deviation.deviationId,
      signatureId: signatureId,
    };

    await handler(event as any);

    setComment("");
    setShowButton(false);
    onHandle && onHandle({ ...event, timestamp: new Date().toISOString() });
    toggleOpen();
  };

  const show = showButton || variant === "comment";

  return (
    <div>
      {show && (
        <>
          {variant === "comment" ? (
            <>
              <Button variant="text" color="primary" onClick={toggleOpen}>
                <AddCommentIcon />
              </Button>
            </>
          ) : (<Button variant="outlined" color="primary" onClick={toggleOpen}>
            {t("checklist.handleDeviationDialog.button")}
          </Button>)}
          <Dialog
            open={open}
            onClose={toggleOpen}
            maxWidth="sm"
            fullWidth
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">
              {t(variant === "comment" ? "checklist.handleDeviationDialog.dialogCommentTitle" : "checklist.handleDeviationDialog.dialogTitle")}
            </DialogTitle>
            <DialogContent>
              {variant === "comment" && (
                <Typography gutterBottom>
                  {t("checklist.handleDeviationDialog.dialogText")}
                </Typography>
              )}
              <TextField
                multiline
                rows={4}
                autoFocus
                margin="dense"
                id="name"
                label={t("checklist.handleDeviationDialog.textFieldLabel")}
                type="text"
                fullWidth
                value={comment}
                onChange={handleChange}
                variant="outlined"
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={toggleOpen} color="primary">
                {t("checklist.handleDeviationDialog.cancel")}
              </Button>
              <Button onClick={handleSubmit} color="primary">
                {t("checklist.handleDeviationDialog.save")}
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )
      }
    </div >
  );
};

export default HandleChecklistDeviation;
