import { Routes } from "@angular/router";
import { AddUserComponent } from "./user-registering/add-user/add-user.component";
import { UserListComponent } from "./user-registering/user-list/user-list.component";
import { RegisterDeviceComponent } from "./devices-registering/register-device/register-device.component";
import { DevicesListComponent } from "./devices-registering/devices-list/devices-list.component";
import { InitialScreenComponent } from "./initial-screen/initial-screen.component";
import { ReportStartScreenComponent } from "./reports/report-start-screen/report-start-screen.component";
import { TemperatureConfirmComponent } from "./reports/temperature-confirm/temperature-confirm.component";
import { ReportHistoryComponent } from "./report-history/report-history.component";
import { AddEntityComponent } from "./entity/add-entity/add-entity.component";
import { TempFormRegisterComponent } from "./temp-inspector/temp-form-register/temp-form-register.component";
import { DeviationProceduresComponent } from "./temp-inspector/deviation-procedures/deviation-procedures.component";
import { DeviationMessageComponent } from "./temp-inspector/deviation-message/deviation-message.component";
import { DeviationexpansionComponent } from "./report-history/deviationexpansion/deviationexpansion.component";
import { SubmitConfirmComponent } from "./temp-inspector/submit-confirm/submit-confirm.component";
import { TemperatureSnapshotComponent } from "./temp-inspector/temperature-snapshot/temperature-snapshot.component";
import { ReportLatestComponent } from "./temp-inspector/report-latest/report-latest.component";
import { EntitiesStatisticsComponent } from "./statistics/entities-statistics/entities-statistics.component";
import { DoorsCleanComponent } from "./doors-clean/doors-clean.component";
import { ManualReportComponent } from "./reports/manual/manual-report.component";
import { DoorsCleanReactWrapperComponent } from "./doors-clean-react/DoorsCleanReactWrapper";
import { RoomsReactComponent } from "./rooms-raect/RoomsReactWrapper";
import { EditEntityFormComponent } from "./edit-entity-form-react/EditEntityFormReactWrapper";
import { NewEntityFormComponent } from "./edit-entity-form-react/NewEntityFormReactWrapper";
import { ReportsComponent } from "./reports-react/ReportsReactWrapper";
import { MoveEntityFormComponent } from "./edit-entity-form-react/MoveEntityFormReactWrapper";
import { SiteSettingsComponent } from "./site-settings-react/SiteSettingsReactWrapper";
import { ChecklistReactComponent } from "./checklist-react/ChecklistReactWrapper";
import { UnauthpageComponent } from "./common/unauthpage/unauthpage.component";
import { DashboardComponent } from "./initial-screen/dashboard/dashboard.component";
import { NotificationSettingsComponent } from "./notifications-settings-react/NotificationSettingsReactWrapper";
import { LoginComponent } from "./login/LoginReactWrapper";
import { EntityTypesComponent } from "./entity-types-react/EntityTypesReactWrapper";
import { EditEntityTypeComponent } from "./entity-types-edit-react/EditEntityTypeReactWrapper";
import { AlarmsComponent } from "./alarms-react/AlarmsReactWrapper";
import { SurveillanceReportsComponent } from "./surveillance-reports-react/SurveillanceReportsReactWrapper";
import { AuthenticationGuard } from "./system/AuthenticationGuard";
import { UserProfileComponent } from "./user-profile-react/UserProfileReactWrapper";
import { QrCode } from "@mui/icons-material";
import QrCodes from "./edit-entity-form-react/components/QrCodes";

export const APP_ROUTES: Routes = [
  {
    path: "fix",
    component: UnauthpageComponent,
  },
  {
    path: "add-user",
    component: AddUserComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: "user-list",
    component: UserListComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: "register-device",
    component: RegisterDeviceComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: "device-list",
    component: DevicesListComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: "report",
    component: ReportStartScreenComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: "temperature-register",
    component: TempFormRegisterComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: "temperature-reg",
    component: TempFormRegisterComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: "deviationProcedure",
    component: DeviationProceduresComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: "surveillance-reports",
    component: SurveillanceReportsComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: "deviationmsg/:id",
    component: DeviationMessageComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: "temperature-confirm",
    component: TemperatureConfirmComponent,
    canActivate: [AuthenticationGuard],
  },
  { path: "login", component: LoginComponent },
  {
    path: "report-history/:id",
    component: ReportHistoryComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: "report-history",
    component: ReportHistoryComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: "report-deviation",
    component: DeviationexpansionComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: "entities",
    component: RoomsReactComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: "add-entity",
    component: AddEntityComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: "deviations-confirm",
    component: SubmitConfirmComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: "temperature-snapshot",
    component: TemperatureSnapshotComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: "report-latest",
    component: ReportLatestComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: "export-reports",
    component: EntitiesStatisticsComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: "alarms",
    component: AlarmsComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: "manual-reports",
    component: ManualReportComponent,
    canActivate: [AuthenticationGuard],
    data: { isOnlyManualReports: true },
  },
  {
    path: "control-reports",
    component: ManualReportComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: "clean-old",
    component: DoorsCleanComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: "clean",
    component: DoorsCleanReactWrapperComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: "rooms",
    component: RoomsReactComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: "new-entity",
    component: NewEntityFormComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: "qr-code",
    component: NewEntityFormComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: "edit-entity/:entityId",
    component: EditEntityFormComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: "edit-entity-type/:entityTypeId",
    component: EditEntityTypeComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: "edit-entity-type",
    component: EditEntityTypeComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: "move-entity/:entityId",
    component: MoveEntityFormComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: "entity/:entityId",
    component: ReportsComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: "site-settings",
    component: SiteSettingsComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: "user-profile",
    component: UserProfileComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: "notification-settings",
    component: NotificationSettingsComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: "entity-types",
    component: EntityTypesComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: "checklist/report/:checklistId",
    component: ChecklistReactComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: "checklist/:checklistId",
    component: ChecklistReactComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: "checklist/dashboard/high",
    component: ChecklistReactComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: "checklist/dashboard/low",
    component: ChecklistReactComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: "checklist/dashboard/overview",
    component: ChecklistReactComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: "checklist/dashboard/reports",
    component: ChecklistReactComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: "checklist/dashboard/deviations",
    component: ChecklistReactComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: "checklist/dashboard/deviations/comments",
    component: ChecklistReactComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: "checklist/dashboard/deviations/temperature",
    component: ChecklistReactComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: "checklist/dashboard/comments",
    component: ChecklistReactComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: "checklist/dashboard/calendar",
    component: ChecklistReactComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: "edit-checklist/:assignedId",
    component: ChecklistReactComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: "edit-checklist",
    component: ChecklistReactComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: "checklist",
    component: ChecklistReactComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: "checklist/overview/:checklistId",
    component: ChecklistReactComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: "checklist/overview/details/:checklistId",
    component: ChecklistReactComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: "dashboard",
    component: DashboardComponent,
    canActivate: [AuthenticationGuard],
  },
  {
    path: "",
    component: InitialScreenComponent,
    canActivate: [AuthenticationGuard],
  },
];
