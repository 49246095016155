import { makeStyles } from "@mui/styles";
import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { forkJoin, of } from "rxjs";
import { catchError, take } from "rxjs/operators";
import { Entity } from "../../entity/entity";
import { Site } from "../../entity/site";
import {
  SignalTag,
  SignalTagFunction,
  SignalTagType,
} from "../../signals/vevent";
import { Guid } from "../../system/guid";
import EditEntityContext from "../EditEntityContext";
import { IValues } from "../interfaces";
import EntityForm from "./EntityForm";
import * as moment from "moment";
import { TermoObject } from "../../entity/termo-object";
import { OpenCloseObject } from "../../entity/open-close-object";
import { ReptileObject } from "../../entity/reptile-object";
import Loading from "../../react-components/Loading";
import { Alert, Snackbar } from "@mui/material";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  loading: {
    marginTop: "4em",
  },
});

const mapSignalObj = (signal: SignalTag) => {
  return {
    lastValue: "",
    sourceId: signal.sourceId,
    timestamp: signal.timestamp,
    type: signal.tagType,
    unit: null,
    value: 0,
  };
};

const EditEntity = () => {
  const classes = useStyles();
  const { entityService, signalsService, route, siteService, user, router } =
    useContext(EditEntityContext);

  const [entity, setEntity] = useState<any>();
  const [oldTags, setOldTags] = useState<any>({});
  const [tenantId, setTenantId] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);

  const [alert, setAlert] = useState<{ open: boolean; success: boolean }>({
    open: false,
    success: false,
  });
  const setAlertOpen = (open: boolean) => {
    setAlert({
      ...alert,
      open,
    });
  };
  const { t } = useTranslation();

  const entityId = route.snapshot.paramMap.get("entityId");
  const entityGuid = new Guid(entityId);

  useEffect(() => {
    siteService
      .getSite(user.siteId)
      .pipe(take(1))
      .subscribe((site: Site) => {
        setTenantId(site.tenantId.toString());
      });

    forkJoin([
      entityService.getEntity(entityGuid),
      signalsService.getTags(entityId).pipe(catchError((err) => of(err))),
    ])
      .pipe(take(1))
      .subscribe(
        ([entity, tagsArray]) => {
          setEntity(entity);

          const currentTags = tagsArray.length > 0 ? tagsArray : [];

          currentTags.map((tag) => {
            switch (tag.tagFunction) {
              case SignalTagFunction.Temp:
                setOldTags((prev) => ({
                  ...prev,
                  temperature: mapSignalObj(tag),
                }));
                break;
              case SignalTagFunction.Door:
                setOldTags((prev) => ({ ...prev, door: mapSignalObj(tag) }));
                break;
              case SignalTagFunction.Humidity:
                setOldTags((prev) => ({
                  ...prev,
                  humidity: mapSignalObj(tag),
                }));
                break;
              case SignalTagFunction.Touch:
                setOldTags((prev) => ({
                  ...prev,
                  touch: mapSignalObj(tag),
                }));
                break;
              case SignalTagFunction.Clean:
                setOldTags((prev) => ({
                  ...prev,
                  touch: mapSignalObj(tag),
                }));
                break;
              case SignalTagFunction.PPM:
                setOldTags((prev) => ({
                  ...prev,
                  ppm: mapSignalObj(tag),
                }));
                break;
              case SignalTagFunction.Pressure:
                setOldTags((prev) => ({
                  ...prev,
                  pressure: mapSignalObj(tag),
                }));
                break;
            }
          });
        },
        () => { },
        () => setLoading(false)
      );
  }, []);

  const handleUpdate = (values: IValues) => {
    let createdOn = moment();

    let obj = {
      id: entity.obj.id,
      entityId,
      tenantId,
      createdOn,
      rules: values.limits,
      make: values.family,
      model: values.species,
    };

    let updatedEntity = Entity.create({
      id: entityId,
      name: values.name,
      siteId: user.siteId,
      tenantId,
      type: values.type,
      createdOn,
      hide: false,
      serviceHours: values.serviceHours,
      objectImages: values.objectImages,
    });

    let objMethod;
    let newObj = {
      ...obj,
      termoObjectTypeId:
        values.temperature.termoObjectTypeId ||
        entity.obj.termoObjectTypeId,
    };
    switch (values.type) {
      case TermoObject.typeName: {
        objMethod = entityService.putThermoObject(entity.obj.id, newObj);
        break;
      }
      case OpenCloseObject.typeName: {
        objMethod = entityService.putOpenCloseObject(entity.obj.id, newObj);
        break;
      }
      case ReptileObject.typeName: {
        objMethod = entityService.putReptileObject(entity.obj.id, newObj);
        break;
      }
      default: {
        throw Error;
      }
    }

    let signalTagsObservers = [];

    postSignalTags(
      oldTags.temperature?.sourceId,
      values.temperature?.sourceId,
      SignalTagFunction.Temp,
      signalTagsObservers
    );

    postSignalTags(
      oldTags.humidity?.sourceId,
      values.humidity?.sourceId,
      SignalTagFunction.Humidity,
      signalTagsObservers
    );

    postSignalTags(
      oldTags.door?.sourceId,
      values.door?.sourceId,
      SignalTagFunction.Door,
      signalTagsObservers
    );

    postSignalTags(
      oldTags.touch?.sourceId,
      values.touch?.sourceId,
      updatedEntity.type === OpenCloseObject.typeName
        ? SignalTagFunction.Clean
        : SignalTagFunction.Touch,
      signalTagsObservers
    );
    postSignalTags(
      oldTags.ppm?.sourceId,
      values.ppm?.sourceId,
      SignalTagFunction.PPM,
      signalTagsObservers
    );
    postSignalTags(
      oldTags.pressure?.sourceId,
      values.pressure?.sourceId,
      SignalTagFunction.Pressure,
      signalTagsObservers
    );
    forkJoin([
      entityService.putEntity(entityId, updatedEntity),
      objMethod,
      ...signalTagsObservers,
    ])
      .pipe(take(1))
      .subscribe(
        () => {
          setAlert({
            success: true,
            open: true,
          });
        },
        () => {
          setAlert({
            success: false,
            open: true,
          });
        },
        () => { }
      );
  };

  const postSignalTags = (
    oldSourceId: string | null,
    newSourceId: string | null,
    signalTagFunction: SignalTagFunction,
    observableArr: Array<any>
  ) => {
    if (oldSourceId !== newSourceId) {
      if (oldSourceId && !oldSourceId.endsWith("/clean")) {
        const oldTag = new SignalTag(
          Guid.newGuid().toString(),
          "SignalTag",
          oldSourceId,
          moment().utc().subtract(1, "second"),
          entityId,
          SignalTagType.Stop,
          signalTagFunction
        );
        observableArr.push(signalsService.postSignalTag(oldTag));
      }

      const newTag = new SignalTag(
        Guid.newGuid().toString(),
        "SignalTag",
        newSourceId,
        moment().utc(),
        entityId,
        SignalTagType.Start,
        signalTagFunction
      );
      console.log("OldSourceID:", oldSourceId);
      console.log("NewSourceId:", newSourceId);

      newSourceId && observableArr.push(signalsService.postSignalTag(newTag));
    }
  };

  if (loading) {
    return (
      <div className={classes.loading}>
        <Loading />
      </div>
    );
  }

  return (
    <>
      <EntityForm entity={entity} tags={oldTags} handleSubmit={handleUpdate} />
      <Snackbar
        open={alert.open}
        autoHideDuration={3000}
        onClose={() => setAlertOpen(false)}
        sx={{ height: "100%" }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => setAlertOpen(false)}
          severity={alert.success ? "success" : "error"}
          sx={{ width: "100%" }}
        >
          <b>{t(`entityForm.${alert.success ? "successMessage" : "errorMessage"}`)}</b>
        </Alert>
      </Snackbar>
    </>
  );
};

export default EditEntity;
