import {
  Menu,
  MenuItem,
  Tooltip,
  Button,
} from "@mui/material";

import SettingsIcon from '@mui/icons-material/Settings';

import React from "react";
import { useTranslation } from "react-i18next";
import TagChecklistPriority, { Props as TagPriorityProps } from "./TagChecklistPriority";

type Props = {
  onEditClick: () => void;
  onEditSiteClick: () => void;
  onAttachMediaClick: () => void;
  onEditTagsClick: () => void;
  onEditDeviationHandlingLevelClick: () => void;
  tagPriorityProps: TagPriorityProps;
}

const ChecklistSettingsMenu = ({ onEditClick, onEditSiteClick, onAttachMediaClick, onEditTagsClick, onEditDeviationHandlingLevelClick, tagPriorityProps }: Props) => {

  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  return (
    <>
      <Tooltip title={t('Settings')}>
        <Button onClick={(e: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(e.currentTarget)}
        >
          <SettingsIcon />
        </Button>
      </Tooltip>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem
          onClick={() => {
            onEditSiteClick();
            setAnchorEl(null);
          }}
        >
          {t("Edit")}
        </MenuItem>
        <MenuItem
          onClick={() => {
            onEditClick();
            setAnchorEl(null);
          }}
        >
          {t("Edit Template")}
        </MenuItem>
        <MenuItem
          onClick={() => {
            onAttachMediaClick();
            setAnchorEl(null);
          }}
        >
          {t("Attach Media")}
        </MenuItem>
        <MenuItem
          onClick={() => {
            onEditTagsClick();
            setAnchorEl(null);
          }}
        >
          {t("Edit Tags")}
        </MenuItem>
        <MenuItem
          onClick={() => {
            onEditDeviationHandlingLevelClick();
            setAnchorEl(null);
          }}
        >
          {t("Deviation Handling")}
        </MenuItem>
        <MenuItem>
          <TagChecklistPriority
            {...tagPriorityProps}
          />
        </MenuItem>
      </Menu>
    </>
  );
};

export default ChecklistSettingsMenu;
