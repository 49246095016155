import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  Checkbox,
  CheckboxProps,
  FormControlLabel,
  Grid,
  IconButton,
  Theme,
  Typography,
} from "@mui/material";
import { makeStyles, createStyles, withStyles } from '@mui/styles';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import clsx from "clsx";
import * as React from "react";
import { useEffect, useState } from "react";
import MediaRender from "../../react-components/MediaRender";
import { useSignatureContext } from "../../signature-react/SignatureContext";
import { useChecklistContext } from "../ChecklistReactWrapper";
import { PointType, useGetLabelValues } from "../services/checklistService";
import { actions, ChecklistState } from "../services/reducer";
import BreakageAmountTextfield from "./BreakageAmountTextfield";
import ManualMeasurementTextfield from "./ManualMeasurementTextfield";
import ReportDeviationInput from "./ReportDeviationInput";
import ReportDeviationMessage from "./ReportDeviationMessage";
import PointExternalLink from "./edit-checklist/edit-point/PointExternalLink";
import MarkdownDisplay from "../../react-components/MarkdownDisplay";
import ReportDeviationHandleInput from "./ReportDeviationHandleInput";
import { useTranslation } from "react-i18next";
import { PointLabel } from "./PointLabel";
import { Guid } from "../../system/guid";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';


const GreenCheckbox = withStyles({
  root: {
    "&$checked": {
      color: "#4caf50",
    },
  },
  checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

const useStyles = makeStyles(({ spacing, palette }: Theme) =>
  createStyles({
    root: {
      marginBottom: spacing(2)
    },
    disabled: {
      "& .MuiFormControlLabel-label.Mui-disabled": {
        color: palette.text.primary,
      },
    },
    green: { background: "rgb(237, 247, 237)" },
    accordianSummary: {
      display: "flex",
    },
    checkbox: {
      "&$checked": {
        color: "#4caf50",
      },
    },
    formControlLabel: {
      flexGrow: 1,
      "&$disabled": {
        color: "#000",
      },
    },
    accordionDetails: {
      display: "flex",
    },
    img: {
      width: "100%",
    },
    imgContainer: {
      flexGrow: 1,
    },
    pointDescription: {
      flexGrow: 2,
    },
    reportButtonContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    tempInput: {
      marginRight: spacing(2),
    },
    subButtonWrapper: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    subButtonOutlined: {
      border: '1px solid',
      borderRadius: '50%',
    },
  })
);

type Props = {
  dispatch: any;
  point: any;
  state: any;
  checklist: any;
  preview?: boolean;
  sessionData: any[];
  onDeviation: (handled: boolean) => any;
  onAddSubPoint?: (pointId: string, subPointId: string) => any;
  onRemoveSubPoint?: (pointId: string, subPointId: string) => any;
  isSubPoint: boolean;
};

const ChecklistPointCard = ({
  dispatch,
  point,
  state,
  checklist,
  preview,
  sessionData,
  onDeviation,
  onAddSubPoint,
  onRemoveSubPoint,
  isSubPoint
}: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { user } = useChecklistContext();
  const [checked, setChecked] = useState(false);

  const {
    state: { activeSignature, ...statee },
  } = useSignatureContext();
  const signatureId = activeSignature?.id?.toString();
  const sessioncomments = sessionData?.filter(
    (e) => ((e.type === "DeviationReported" || e.type == "TemperatureDeviationReported") && e.pointId == point.id)
  );

  const [labelValuesMap, setLabelValuesMap] = useState({});
  const [currentLabels, setCurrentLabels] = useState([]);

  const getUniqueValues = (values: string[]): string[] => {
    const trimmedValues = values.map((value) => value.trim());
    const uniqueValues = [...new Set(trimmedValues)];
    return uniqueValues;
  };



  useEffect(() => {
    const sessionLabelEvents = sessionData?.filter((e) => e.type === "Labeled" && e.pointId === point.id);
    const latestLabelEventsForSession: Record<string, any> = {};

    sessionLabelEvents.forEach((event) => {
      if (!latestLabelEventsForSession[event.labelType] || event.Timestamp > latestLabelEventsForSession[event.labelType].Timestamp) {
        latestLabelEventsForSession[event.labelType] = event;
      }
    });

    const currentLabels = Object.values(latestLabelEventsForSession);
    setCurrentLabels(currentLabels);

    point.labels?.forEach((labelType) => {
      if (!labelValuesMap[labelType]) {
        useGetLabelValues(labelType)
          .then((labelValues) => {
            const uniqueLabelValues = getUniqueValues(labelValues);
            setLabelValuesMap((prevMap) => ({
              ...prevMap,
              [labelType]: uniqueLabelValues
            }));
          });
      }
    });

  }, [sessionData]);

  const [comments, setComments] = useState<any[]>(
    sessioncomments && sessioncomments.length > 0
      ? [sessioncomments[sessioncomments.length - 1]]
      : []
  );
  const measurements =
    state.measurements[point.id] === undefined
      ? null
      : state.measurements[point.id].value;
  const sessionActionsTaken = sessionData?.filter(
    (e) => e.type === "DeviationActionTaken" && e.pointId == point.id
  );

  const [isDeviation, setIsDeviation] = useState(false);
  const [deviationValue, setDeviationValue] = useState(null);
  const [actionDescription, setActionDescription] = useState<string>('');
  const [commonDeviationCauses, setCommonDeviationCauses] = useState<{ label: string, cause: string }[]>([]);
  const [actionTaken, setActionTaken] = useState<string>(sessionActionsTaken?.length ? sessionActionsTaken.slice().reverse()[0].action : '');
  const [commonDeviationHandles, setCommonDeviationHandles] = useState<{ action: string }[]>([]);
  const [needsComment, setNeedsComment] = useState(false);
  const [needsAction, setNeedsAction] = useState(false);
  // const [attachments, setAttachments] = useState<AttachmentFile>();

  // const addComment = (comment) => setComments([...comments, comment]);
  const handelAddComment = (comment) => {
    setComments([comment]);
  };
  // useEffect(() => {
  //   setNeedsComment(comments.length === 0 || comments[0].comment === "");
  // },[comments]);
  useEffect(() => {
    onDeviation(isDeviation && needsComment);
  }, [isDeviation, needsComment]);

  useEffect(() => {
    let mounted = true;
    if (!mounted) return;
    return () => {
      mounted = false;
    };
  }, [signatureId]);

  useEffect(() => {
    setChecked(state.tickedPoints.includes(point.id));
  }, [state.tickedPoints]);

  // var isMovie = point.image && point.image.endsWith(".mp4");
  // useEffect(() => {
  //   console.log("Mounted");
  // }, []);
  let rootClasses = [classes.root];
  if (checked) rootClasses.push(classes.green);
  return (
    <>
      <Accordion className={clsx(rootClasses)}>
        <AccordionSummary
          className={classes.accordianSummary}
          expandIcon={!isSubPoint ? (point?.siteImage || point?.image || point?.description || point?.siteDescription) ? <ExpandMoreIcon /> : null : null}
          onClick={(e) => {
            if (isSubPoint) {
              e.preventDefault();
            }
          }}
          onClickCapture={(e) => {
            if (isSubPoint) {
              e.preventDefault();
            }
          }}
          aria-label="Expand"
        >
          <Grid container spacing={2} direction="column">
            <Grid item style={{ display: "flex" }}>
              <FormControlLabel
                classes={{
                  root: classes.formControlLabel,
                  disabled: classes.disabled,
                }}
                onClick={(event) => {
                  event.stopPropagation();
                }}
                onFocus={(event) => {
                  event.stopPropagation();
                }}
                control={<GreenCheckbox
                  checked={checked}
                  disabled={(preview ?? state.state !== ChecklistState.IN_PROGRESS) ||
                    point.type === PointType.MANUAL_ENTITY ||
                    point.type === PointType.BREAKAGE}
                  onChange={() => {
                    if (state.state === ChecklistState.IN_PROGRESS) {
                      dispatch({
                        type: checked
                          ? actions.UNCHECK_POINT
                          : actions.CHECK_POINT,
                        payload: {
                          checklistId: checklist.checklistId,
                          tenantId: checklist.tenantId,
                          assignedId: checklist.assignedId,
                          pointId: point.id,
                          userId: user.adalUser.oid,
                          siteId: user.siteId,
                          signatureId: signatureId,
                        },
                      });
                    }
                  }} />}
                label={point.siteName || (point.name ?? "Tittel mangler")} />
              {!!point?.entityId && (
                <ManualMeasurementTextfield
                  checked={checked}
                  checklist={checklist}
                  dispatch={dispatch}
                  point={point}
                  state={state}
                  disabled={preview}
                  onChange={(isDeviation: boolean, value: number | string) => {
                    setDeviationValue(value);
                    setIsDeviation(isDeviation);
                    setNeedsComment(isDeviation && comments.length <= 0);
                    setNeedsAction(isDeviation && !actionTaken);
                  }}
                  setActionDescription={setActionDescription}
                  setCommonDeviationCauses={setCommonDeviationCauses}
                  setCommonDeviationHandles={setCommonDeviationHandles} />
              )}
              {!!point?.unit && (
                <BreakageAmountTextfield
                  checked={checked}
                  checklist={checklist}
                  dispatch={dispatch}
                  point={point}
                  state={state}
                  disabled={preview} />
              )}
            </Grid>
            <Grid
              item
              onClick={(event) => {
                event.stopPropagation();
              }}
            >
              {/* {!isDeviation && comments.length > 0 && comments[0].comment} */}
              {<>
                {isDeviation && needsComment && (
                  <ReportDeviationInput
                    dispatch={dispatch}
                    pointId={point.id}
                    point={point}
                    assignedId={checklist.assignedId}
                    checklistId={checklist.checklistId}
                    variant="Point"
                    onCommentsAdded={(comment: any) => {
                      handelAddComment(comment);
                      setNeedsComment(false);
                    }}
                    actionDescription={actionDescription}
                    deviationCauses={commonDeviationCauses}
                    deviationValue={deviationValue}
                    disabled={preview} />
                )}
                {isDeviation && !!commonDeviationHandles?.length && needsAction && !preview && (
                  <ReportDeviationHandleInput
                    pointId={point.id}
                    point={point}
                    assignedId={checklist.assignedId}
                    checklistId={checklist.checklistId}
                    sessionId={sessionData?.[0]?.sessionId ?? ''}
                    onActionsAdded={(action: string) => {
                      setActionTaken(action);
                      setNeedsAction(false);
                    }}
                    actionTaken={actionTaken}
                    deviationHandles={commonDeviationHandles}
                    disabled={preview} />
                )}
                <ReportDeviationMessage
                  prefix={`${t('checklist.deviationHandling.deviationCause')}:`}
                  text={comments.length ? comments[0].comment : ''}
                  onEdit={() => {
                    setNeedsComment(!needsComment);
                  }}
                  disabled={preview} />
                <ReportDeviationMessage
                  prefix={`${t('checklist.deviationHandling.immediateAction')}:`}
                  text={actionTaken}
                  onEdit={() => {
                    setNeedsAction(!needsAction);
                  }}
                  disabled={preview} />
              </>}
            </Grid>
            <Grid item>
              {point.labels.map((label, index) => (
                <PointLabel
                  key={index} disabled={state.state !== ChecklistState.IN_PROGRESS} labelType={label} labelOptions={labelValuesMap[label]} currentValue={currentLabels.find(x => x.labelType === label)?.name ?? ""} onSubmit={(value) => {
                    dispatch({
                      type: actions.LABEL_POINT,
                      payload: {
                        checklistId: checklist.checklistId,
                        tenantId: checklist.tenantId,
                        assignedId: checklist.assignedId,
                        pointId: point.id,
                        userId: user.adalUser.oid,
                        siteId: user.siteId,
                        signatureId: signatureId,
                        sessionId: sessionData?.[0]?.sessionId,
                        labelType: label,
                        name: value
                      },
                    });
                  }} />
              ))}
            </Grid>
          </Grid>
        </AccordionSummary>
        {!isSubPoint && ( // Only render AccordionDetails for main point and not subpoints
          ((point?.siteImage || point?.image) || point?.description || point?.siteDescription) && (
            <AccordionDetails className={classes.accordionDetails}>
              <Grid container spacing={2}>
                {(point?.siteImage || point?.image) && (
                  <Grid item xs={12} md={5}>
                    {(point?.siteImage || point?.image) && <MediaRender src={(point?.siteImage || point?.image)} />}
                  </Grid>
                )}
                <Grid item xs>
                  <MarkdownDisplay>
                    {point.siteDescription || (point.description ?? "Beskrivelse mangler")}
                  </MarkdownDisplay>
                  <br />
                  {point.siteDetails && <MarkdownDisplay>{point.siteDetails}</MarkdownDisplay>}
                  {point?.externalLink && point?.externalLinkEnabled && (
                    <PointExternalLink externalLink={point.externalLink} />
                  )}
                </Grid>
              </Grid>
            </AccordionDetails>
          )
        )}

      </Accordion>
      {state.state === ChecklistState.IN_PROGRESS && (
        isSubPoint ? (
          <>
            <div className={classes.subButtonWrapper}>
              <IconButton color="error" onClick={() => { onRemoveSubPoint(point.parentPointId, point.id); }}>
                <RemoveIcon />
                <Typography>
                  {t("checklist.subPoint.remove")}
                </Typography>
              </IconButton>
              <IconButton color="success" onClick={() => { onAddSubPoint(point.parentPointId, Guid.newGuid().toString()); }}>
                <AddIcon />
                <Typography color="textSecondary">{t("checklist.subPoint.addAnother")}</Typography>
              </IconButton>
            </div>
          </>
        ) :
          point.isMultiPoint ?

            (
              <div className={classes.subButtonWrapper}>
                <IconButton color="success" onClick={() => { onAddSubPoint(point.id, Guid.newGuid().toString()); }}>
                  <AddIcon />
                  <Typography color="textSecondary">{t("checklist.subPoint.add")}</Typography>
                </IconButton>
              </div>
            )
            : (null)
      )}
    </>

  );
};

export default ChecklistPointCard;
