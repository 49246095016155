import { DateTime } from "luxon";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { useDebouncedCallback } from "use-debounce";
import { environment } from "../../../environments/environment";
import { useSignalR } from "../../signalr-event-handler";
import { useChecklistContext } from "../ChecklistReactWrapper";
import {
  mapSchedules,
  useSchedule,
  useGetCompletedChecklists,
  mapSessions,
  sortSchedulesByDate,
  mapAllChecklists,
  useSiteChecklistsWithMedia,
  useAllwaysScheduled,
  mapAllwaysActiveSchedules,
} from "../services/checklistService";
import { useSingleChecklistContext } from "../services/reducer";
import SchedulesBox from "./SchedulesBox";
import AllwaysSchedulesBox from "./AllwaysSchedulesBox";

type Props = {
  tag: string;
  onNumberOfHighPriorityTasksChange?: (n: number) => any;
  onNumberOfLowPriorityTasksChange?: (n: number) => any;
};

const Schedules: React.FC<Props> = ({ tag, onNumberOfHighPriorityTasksChange = () => { }, onNumberOfLowPriorityTasksChange = () => { } }) => {
  const { user } = useChecklistContext();
  const { dispatch } = useSingleChecklistContext();
  const { t } = useTranslation();
  const [
    { data: scheduleData, loading: scheduleLoading, error: scheduleError },
    refetchSchedules,
  ] = useSchedule(user.siteId);
  const [
    { data: checklistData, loading: checklistLoading, error: checklistError },
  ] = useSiteChecklistsWithMedia(user.siteId);
  const [
    { data: completedData, loading: completedLoading, error: completedError },
    refetchCompleted,
  ] = useGetCompletedChecklists(
    user.siteId,
    DateTime.local().toUTC().startOf("day").toString(),
    DateTime.local().toUTC().endOf("day").toString()
  );
  const refetchSingleChecklistState = () => dispatch({ type: "test" });
  const [{data: alwaysChecklist, loading: alwaysChecklistLoading, error: alwaysChecklistError},refetchAllwaysScheduled] = useAllwaysScheduled(user.siteId);
  
  const location = useLocation();

  React.useEffect(() => {
    refetch();
  }, [location.pathname]);

  const refetch = useDebouncedCallback(() => {
    refetchSchedules();
    refetchCompleted();
    refetchSingleChecklistState();
  }, 500);

  useSignalR(
    refetch,
    {
      url: `${environment.dependencies.checklist_api.url}/events`,
      method: "SessionEvent",
      topic: user.siteId.toLowerCase(),
    },
    [user.siteId]
  );

  React.useEffect(() => {
    if (!scheduleData || !checklistData) return;
    const schedulesHigh = mapSchedules(scheduleData, checklistData, ['high']);
    const schedulesLow = mapSchedules(scheduleData, checklistData, ['low']);
    const filter = (list: any[]) => list.filter(s => DateTime.fromISO(s.dueTime).toMillis()
      <= DateTime.utc().endOf('day').toMillis());

    onNumberOfHighPriorityTasksChange(filter(schedulesHigh.activated).length);
    onNumberOfLowPriorityTasksChange(filter(schedulesLow.activated).length);
  }, [scheduleData, checklistData]);

  if (
    !(scheduleData && checklistData && completedData) &&
    (scheduleLoading || checklistLoading || completedLoading)
  ) {
    return <p>{t("Loading")}</p>;
  }

  if (scheduleError || checklistError || completedError) {
    return <p>{t("Error")}</p>;
  }
  const allwaysActiveSchedules = mapAllwaysActiveSchedules(alwaysChecklist, checklistData, [tag])
  const schedules = mapSchedules(scheduleData, checklistData, [tag]);
  const checklists = mapAllChecklists(checklistData, [tag]);
  const completedSessions = mapSessions(completedData).filter(
    (s) =>
      !s.isHidden && !!checklists.find((c) => c.assignedId === s.assignedId)
  );

  return (
    <div>
      <SchedulesBox
        title={t("checklist.inProgressTitle")}
        schedules={sortSchedulesByDate(schedules.inProgress, "dueTime")}
        isInProgress
      />
      <SchedulesBox
        title={t("checklist.readyTitle")}
        schedules={sortSchedulesByDate(schedules.activated, "dueTime")}
      />
      <AllwaysSchedulesBox
        title={t("checklist.allwaysScheduledTitle")}
        schedules={allwaysActiveSchedules}
      />
      <SchedulesBox
        title={t("checklist.comingUpTitle")}
        schedules={sortSchedulesByDate(schedules.visible, "startTime")}
        isExpanded={false}
        timeField="startTime"
        timeLabel={t("checklist.checklistCard.starting")}
      />
      <SchedulesBox
        title={t("checklist.overdueTitle")}
        schedules={sortSchedulesByDate(schedules.overDue, "dueTime")}
        isExpanded={false}
        timeField="dueTime"
        timeLabel={t("checklist.checklistCard.expired")}
      />
      <SchedulesBox
        title={t("checklist.completedTitle")}
        schedules={sortSchedulesByDate(completedSessions, "dueTime")}
        isCompleted
        isExpanded={false}
        refetch={refetch}
      />
    </div>
  );
};

export default Schedules;
