import {
  CircularProgress,
  Divider,
  Grid,
  Theme,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
} from "@mui/material";
import * as React from "react";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router";
import { take } from "rxjs/operators";
import { Site } from "../../entity/site";
import PageHeader from "../../react-components/PageHeader";
import { useSignatureContext } from "../../signature-react/SignatureContext";
import { Guid } from "../../system/guid";
import { useChecklistContext } from "../ChecklistReactWrapper";
import {
  mapChecklist,
  mapSessionEvents,
  PointType,
  useGetAssignedChecklistComposite,
  useGetSubPointEvents,
  useSession,
} from "../services/checklistService";
import {
  actions,
  ChecklistState,
  useSingleChecklistContext,
} from "../services/reducer";
import ChecklistCompletedActions from "./ChecklistCompletedActions";
import ChecklistInProgressActions from "./ChecklistInProgressActions";
import ChecklistPointCard from "./ChecklistPointCard";
import ShowSchedule from "./ShowSchedule";
import { makeStyles, createStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import MarkdownDisplay from "../../react-components/MarkdownDisplay";
import { useQuery } from "../services/utils";
import MediaRender from "../../react-components/MediaRender";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const useStyles = makeStyles(({ spacing }: Theme) =>
  createStyles({
    root: {},
    img: {
      width: "100%",
    },
    divider: {
      marginTop: spacing(2),
      marginBottom: spacing(2),
    },
    loading: {
      padding: spacing(5),
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
  })
);

type Props = {
  checklistId?: string;
  sessionId?: string;
  preview?: boolean;
  showSchedule?: boolean;
  refetch?: () => void;
  stateTime?: DateTime;
};

const ViewChecklist = (props: Props) => {
  let { checklistId }: any = useParams();
  let query = useQuery();
  const { user, siteService } = useChecklistContext();
  const classes = useStyles();
  const sessionId = query.get("sessionId") ?? props.sessionId;
  const backTo = query.get("backTo") ?? "/checklist/dashboard/high";
  // const [state, dispatch] = useChecklistReducer();
  const { state, dispatch } = useSingleChecklistContext();
  const [tenantId, setTenantId] = useState<string>("");
  const [checklist, setChecklist] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [subLoading, setSubLoading] = useState(false);

  const {
    state: { activeSignature },
  } = useSignatureContext();
  const navigate = useNavigate();

  const [time, _] = useState<DateTime>(props.stateTime || DateTime.local());


  const signatureId = activeSignature?.id?.toString();
  const [showImages, setShowImages] = useState(false);
  const { t } = useTranslation();

  const [
    { data: sessionData, error: sessionError, loading: sessionLoading },
    refetchSession,
  ] = useSession(sessionId);

  const [pendingSubPoints, setPendingSubPoints] = useState<string[]>([]);



  const assignedId =
    checklistId ?? props.checklistId ?? Guid.empty().toString();
  const [{ data, error, loading: checklistLoading }, refetchAssigned] =
    useGetAssignedChecklistComposite(assignedId);

  const [changingView, setChangingView] = useState(false);

  const [pointsWithUnhandledDeviations, setPointsWithUnhandledDeviations] =
    useState({});

  useEffect(() => {
    if (state.changeUrl) {
      setChangingView(true);
      setTimeout(() => {
        navigate(state.changeUrl);
      }, 1000);
    }
  }, [state.changeUrl]);


  useEffect(() => {
    let isSubscribed = true;
    const loadData = () => {
      if (!isSubscribed) return;
      siteService
        .getSite(user.siteId)
        .pipe(take(1))
        .subscribe((site: Site) => {
          setTenantId(site.tenantId.toString());
        });

      if (sessionData && checklist) {
        let latestFirstEvents = [...sessionData].reverse();
        const isStarted = Boolean(
          sessionData.find((e) => e.type === "SessionStarted")
        );
        let stoped = null;
        const isCompleted = Boolean(
          (stoped = latestFirstEvents.find(
            (x) => x.type === "SessionStopped" || x.type === "SessionReopened"
          )) &&
          stoped &&
          stoped.type === "SessionStopped"
        );
        const isReopened = Boolean(
          (stoped = latestFirstEvents.find(
            (x) => x.type === "SessionStopped" || x.type === "SessionReopened"
          )) &&
          stoped &&
          stoped.type === "SessionReopened"
        );
        if (!(!isStarted && tenantId && !props.preview)) {
          dispatch({
            type: actions.SELECT_SESSION,
            payload: {
              sessionId,
              ...mapSessionEvents(sessionData, checklist),
            },
          });
          if (isCompleted) {
            dispatch({
              type: actions.SESSION_COMPLETED,
            });
          } else if (isStarted || isReopened) {
            dispatch({
              type: actions.CONTINUE_SESSION,
            });
          }
        }
      }
    };

    if (state.refetch) {
      refetchSession()
        .then(() => {
          if (!isSubscribed) return;
          loadData();
        })
        .catch(() => { });
    } else {
      loadData();
    }

    return () => {
      console.log("unmounting...");
      isSubscribed = false;
    };
  }, [tenantId, checklist, state.refetch, state.subPointFetch]);



  // const handleSubPointEvent = (type: string, pointId: string, subPointId: string) => {
  //   const localSessionEvent = {
  //     type: type,
  //     pointId: pointId,
  //     subPointId: subPointId,
  //     Timestamp: new Date().toISOString(),
  //   }
  //   setSubPointEvents(prevEvents => [...prevEvents, localSessionEvent]);
  // }

  useEffect(() => {
    if (data) {
      let newCheckList = mapChecklist(
        data.filter(
          (e) => DateTime.fromISO(e.Timestamp).toMillis() <= time.toMillis()
        ).concat(useGetSubPointEvents(sessionData)),
        assignedId
      );
      setChecklist(
        newCheckList
      );

    }
  }, [data, assignedId, time]);

  //Hooks only purpose is to reload the checklist if subpoints are added during execution
  useEffect(() => {
    if (pendingSubPoints.length === 0) return; //Don't reload checklist for other changes in sessiondata
    if (data && sessionData) {
      let newCheckList = mapChecklist(
        data.filter(
          (e) => DateTime.fromISO(e.Timestamp).toMillis() <= time.toMillis()
        ).concat(useGetSubPointEvents(sessionData)),
        assignedId
      );
      setChecklist(
        newCheckList
      );

    }
  }, [sessionData]);

  //The purpose of this is to keep track of which points has a pending "subpoint" action on them
  //It will be used to render a loading spinner in the correct place in UI
  //And to prevent the full 'loading screen' that normally appears for a refetch, as this can take a while
  //We limit the loading only to show that something is 'going on'
  const handleSubpointAction = (pointId: string) => {
    setPendingSubPoints(prevPendingSubPoints => [...prevPendingSubPoints, pointId]);
    setTimeout(() => {
      refetchSession().then(() => {
        setPendingSubPoints(prevPendingSubPoints => {
          const updatedPendingSubPoints = prevPendingSubPoints.filter(id => id !== pointId);
          return updatedPendingSubPoints;
        });
      })
    }, 50);
  }

  useEffect(() => {
    if (pendingSubPoints.length > 0) {
      setSubLoading(sessionLoading || checklistLoading);
    }
    else setLoading(sessionLoading || checklistLoading);

  }, [sessionLoading, checklistLoading]);

  if (error) {
    return <p>{t("Error")}</p>;
  }
  if (loading || changingView) {
    return (
      <div className={classes.loading}>
        <CircularProgress />
        <p>{t("Loading")}</p>
      </div>
    );
  }

  return (
    <div>
      {!props.preview && (
        <PageHeader
          title={checklist?.siteName || checklist?.name}
          to={backTo}
        />
      )}
      <Grid container spacing={2}>
        {state.state === ChecklistState.COMPLETED && (
          <ChecklistCompletedActions
            dispatch={dispatch}
            state={state}
            checklist={checklist}
            tenantId={tenantId}
          />
        )}
        <Grid item xs={12} md={12}>
          <MarkdownDisplay>
            {checklist?.siteDescription || checklist?.description}
          </MarkdownDisplay>
        </Grid>

        {checklist?.images?.length > 0 && (
          <Accordion expanded={showImages} onChange={() => setShowImages(!showImages)}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              {t("editChecklist.images")}
            </AccordionSummary>
            <AccordionDetails>
              {checklist.images.map((image, key) => {
                return (
                  <Grid item xs={12} md={6} key={"image" + key}>
                    <MediaRender src={image} />
                  </Grid>
                );
              })}
            </AccordionDetails>
          </Accordion>
        )}
        {props.showSchedule && checklist?.schedule.todoFrom != null && (
          <Grid container spacing={2} marginTop={1}>
            <ShowSchedule schedule={checklist?.schedule} />
          </Grid>
        )}

        <Grid color={"red"} style={{ backgroundColor: 'red !important' }} item xs={12}>
          {checklist?.sitePoints?.length > 0 ? (
            <>
              {checklist?.sitePoints.map((item, key) => {
                // Check if the current item is a subpoint
                if (item.isSubPoint) {
                  return null; // Skip rendering subpoints here
                }

                // Find subpoints for the current parent point
                let subPoints = checklist?.sitePoints.filter(
                  (subPoint) => subPoint.isSubPoint && subPoint.parentPointId === item.id
                );

                const hasSubPoints = subPoints.length > 0;


                return (
                  <React.Fragment key={`parent-point-${key}`}>
                    <div className={classes.divider}>
                      <ChecklistPointCard
                        preview={props.preview}
                        isSubPoint={false}
                        key={`parent-point-${key}`}
                        dispatch={dispatch}
                        onAddSubPoint={(pointId, subPointId) => {
                          dispatch({
                            type: actions.ADD_SUB_POINT,
                            payload: {
                              checklistId: checklistId,
                              assignedId: assignedId,
                              tenantId: tenantId,
                              pointId: pointId,
                              subPointId: subPointId,
                              sessionId: sessionId,
                              userId: user.adalUser.oid,
                              siteId: user.siteId,
                              signatureId: signatureId,
                              point: item
                            },
                          });
                          handleSubpointAction(item.id);
                        }}
                        point={item}
                        state={state}
                        checklist={checklist}
                        sessionData={sessionData}
                        onDeviation={(unhandled) => {
                          if (unhandled) {
                            setPointsWithUnhandledDeviations({
                              ...pointsWithUnhandledDeviations,
                              [item.id]: true,
                            });
                          } else {
                            delete pointsWithUnhandledDeviations[item.id];
                            setPointsWithUnhandledDeviations({
                              ...pointsWithUnhandledDeviations,
                            });
                          }
                        }}
                      />
                      {!hasSubPoints && pendingSubPoints.includes(item.id) && (
                        <CircularProgress />
                      )}
                      {subPoints.map((subItem, subKey) => (
                        <React.Fragment key={subKey}>
                          <Box>
                            <ChecklistPointCard
                              preview={props.preview}
                              isSubPoint={true}
                              key={`sub-point-${subKey}`}
                              dispatch={dispatch}
                              onAddSubPoint={(pointId, subPointId) => {
                                dispatch({
                                  type: actions.ADD_SUB_POINT,
                                  payload: {
                                    checklistId: checklistId,
                                    assignedId: assignedId,
                                    tenantId: tenantId,
                                    pointId: pointId,
                                    subPointId: subPointId,
                                    sessionId: sessionId,
                                    userId: user.adalUser.oid,
                                    siteId: user.siteId,
                                    signatureId: signatureId,
                                    point: item
                                  },
                                });
                                handleSubpointAction(item.id);
                              }}
                              onRemoveSubPoint={(pointId, subPointId) => {
                                dispatch({
                                  type: actions.REMOVE_SUB_POINT,
                                  payload: {
                                    checklistId: checklistId,
                                    assignedId: assignedId,
                                    tenantId: tenantId,
                                    pointId: pointId,
                                    subPointId: subPointId,
                                    sessionId: sessionId,
                                    userId: user.adalUser.oid,
                                    siteId: user.siteId,
                                    signatureId: signatureId,
                                  },
                                });
                                handleSubpointAction(item.id);
                              }}
                              point={subItem}
                              state={state}
                              checklist={checklist}
                              sessionData={sessionData}
                              onDeviation={(unhandled) => {
                                if (unhandled) {
                                  setPointsWithUnhandledDeviations({
                                    ...pointsWithUnhandledDeviations,
                                    [subItem.id]: true,
                                  });
                                } else {
                                  delete pointsWithUnhandledDeviations[subItem.id];
                                  setPointsWithUnhandledDeviations({
                                    ...pointsWithUnhandledDeviations,
                                  });
                                }
                              }}
                            />
                          </Box>

                          {hasSubPoints && pendingSubPoints.includes(item.id) && subKey === subPoints.length - 1 && (
                            <Box>
                              <CircularProgress />
                            </Box>
                          )}
                        </React.Fragment>
                      ))}

                    </div>
                  </React.Fragment>
                );
              })}
            </>
          ) : (
            "Denne sjekklisten har ingen punkter"
          )}
        </Grid>

        {state.state === ChecklistState.IN_PROGRESS && (
          <ChecklistInProgressActions
            dispatch={dispatch}
            state={state}
            checklist={checklist}
            tenantId={tenantId}
            reportedDeviationEvents={sessionData.filter(
              (e) =>
                e.type === "ChecklistDeviationReported" ||
                e.type === "ChecklistComment"
            )}
            canComplete={
              Object.keys(pointsWithUnhandledDeviations).length === 0
            }
          />
        )}
      </Grid>
    </div>
  );
};

export default ViewChecklist;
