import React, { useState, useEffect } from "react";
import MDEditor, {
  fullscreen,
  ICommand,
  TextState,
  TextAreaTextApi,
  commands,
} from "@uiw/react-md-editor";
import { useTranslation } from "react-i18next";
import { RiImageAddFill, RiGhostSmileLine } from "react-icons/ri";
import { AiOutlineEnter } from "react-icons/ai";
import { Dialog, Button } from "@mui/material";
import DialogTitle from "../../../../react-components/DialogTitle";
import MediaRender from "../../../../react-components/MediaRender";
import AttachMediaDialog from "../AttachMediaDialog";
import { SupportedMediaFormatsType } from "../../../../react-components/FileUpload/FileUpload";
import {
  BASE_CHECKLIST_MEDIA_PATH,
  FILEUPLOAD_URL,
  useAddMediaToChecklistMarkdown,
} from "../../../services/checklistService";
import { useChecklistContext } from "../../../ChecklistReactWrapper";

type Props = {
  value: string;
  onChange: (value: string) => void;
  images?: string[];
  markdownImages?: string[];
  checklist?: any;
  label?: string;
};

const MarkdownInput = ({
  value,
  onChange,
  images,
  markdownImages,
  checklist,
  label
}: Props) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const toggleOpen = () => {
    setOpen(!open);
  };
  const [textApi, setTextApi] = useState(null);
  const [attachMediaOpen, setAttachMediaOpen] = useState(false);
  const toggleMedia = () => {
    setAttachMediaOpen(!attachMediaOpen);
  };

  const { user, siteService } = useChecklistContext();

  const [site, setSite] = React.useState<any>(null);

  const [imageLibrary, setImageLibrary] = useState(
    images ? images.concat(markdownImages) : markdownImages
  );

  useEffect(() => {
    siteService.getSite(user.siteId).subscribe((site: any) => {
      setSite(site);
    });
  }, []);

  console.log(markdownImages);

  const previewTitle: ICommand = {
    name: "PreviewTitle",
    keyCommand: "PreviewTitle",
    buttonProps: null,
    icon: (
      <span
        style={{ padding: "0 5px", marginRight: "15vw", fontWeight: "bold" }}
      >
        {t("Preview")}
      </span>
    ),
  };

  //custom command for selecting image

  const selectImage: ICommand = {
    name: "selectImage",
    keyCommand: "selectImage",
    buttonProps: { "aria-label": "Insert title3" },
    icon: <RiImageAddFill />,
    execute: (state: TextState, api: TextAreaTextApi) => {
      setTextApi(api);
      toggleOpen();
    },
  };

  const blankLine: ICommand = {
    name: "blankLine",
    keyCommand: "blankLine",
    buttonProps: { "aria-label": "Insert title3" },
    icon: <AiOutlineEnter />,
    execute: (state: TextState, api: TextAreaTextApi) => {
      api.replaceSelection("<br />");
    },
  };

  return (
    <div className="container">
      <Dialog open={open}>
        <DialogTitle onClose={toggleOpen}>Select Image</DialogTitle>
        <Button onClick={toggleMedia}>Upload</Button>
        <AttachMediaDialog
          open={attachMediaOpen}
          onSubmit={async (
            mediaId: string,
            extension: SupportedMediaFormatsType
          ) => {
            const mediaEvent = await useAddMediaToChecklistMarkdown({
              checklistId: checklist.checklistId,
              tenantId: site.tenantId.toString(),
              imagePath: `${BASE_CHECKLIST_MEDIA_PATH}/${mediaId}${extension}`,
            });
            const image = `${FILEUPLOAD_URL}/${mediaEvent.data.imagePath}`;
            setImageLibrary(imageLibrary.concat(image));
            textApi?.replaceSelection(`<img src="${image}" width="100%"/>`);
            toggleOpen();

            //api stuff here;
          }}
          onClose={() => setAttachMediaOpen(false)}
        />
        {imageLibrary &&
          imageLibrary.map((image, key) => {
            return (
              <Button
                onClick={() => {
                  // textApi?.replaceSelection(`![](${image})`);
                  textApi?.replaceSelection(
                    `<img src="${image}" width="100%"/>`
                  );
                  toggleOpen();
                }}
              >
                <MediaRender src={image} key={key} />
              </Button>
            );
          })}
      </Dialog>
      {label && <label>{label}</label>}
      <MDEditor
        value={value}
        onChange={onChange}
        // commands={[commands.divider, selectImage]}
        commands={[
          commands.bold,
          commands.italic,
          commands.group(
            [
              commands.title1,
              commands.title2,
              commands.title3,
              commands.title4,
              commands.title5,
              commands.title6,
            ],
            {
              name: "title",
              groupName: "title",
              buttonProps: { "aria-label": "Insert title" },
            }
          ),
          commands.divider,
          commands.link,
          selectImage,
          blankLine,
        ]}
        extraCommands={[previewTitle, fullscreen]}
      />
    </div>
  );
};

export default MarkdownInput;
