import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { take } from "rxjs/operators";
import { Entity } from "../../entity/entity";
import EditEntityContext from "../EditEntityContext";
import QRCodeStyling from "qr-code-styling";
import { makeStyles } from '@mui/styles';
import { useLocation } from "react-router-dom";

const useStyles = makeStyles({
  qrCodes: {
    padding: "0 1em",
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gridTemplateRows: "1fr"
  }
});

// Create QR code
interface QrCode {
  name: string;
  data: Blob;
}

const createQrCode = (data: string) =>
  new QRCodeStyling({
    width: 300,
    height: 300,
    data,
    image: '/assets/img/tmesh-logo-transparent.png',
    imageOptions: {
      margin: 5
    },
    dotsOptions: {
      color: '#f79f3c',
      type: 'extra-rounded'
    },
    cornersSquareOptions: {
      color: '#000000',
      type: 'extra-rounded'
    }
  });

const QrCodes = () => {
  const classes = useStyles();
  
  // Fetch entities
  const [entities, setEntities] = useState<Array<Entity>>([]);
  const { entityService, user } = useContext(EditEntityContext);
  
  useEffect(() => {
    const getEntities = () =>
      entityService
        .getEntitiesForSite(user.siteId)
        .pipe(take(1))
        .subscribe(
          {next:(res) => {
            setEntities(res);
          },
          error:(error) => {
            console.log(error);
          }}
      );

    getEntities();
  }, []);

  // Generate QR codes when entities change
  const [loading, setLoading] = useState(true);
  const [qrCodes, setQrCodes] = useState<Array<QrCode>>([]);

  useEffect(() => {
    const basUrl = new URL(window.location.href).origin;
    const fetchQrCodes = async () => {
      const newQrCodes = entities.map(async (entity) => ({
        name: entity.name,
        data: await createQrCode(`${basUrl}/entity/${entity.getId()}`).getRawData(),
      }));
      Promise.all(newQrCodes).then((values) => {
        setQrCodes(values);
        setLoading(false);
      });
    };
    
    if (entities.length > 0) {
      fetchQrCodes();
    }
  }, [entities]);

  useEffect(() => {
    console.log(qrCodes);
  }, [qrCodes]);
  
  return (
    <>
      {loading ? <p>Loading QR codes...</p> :
        <div className={classes.qrCodes}>
          {qrCodes.map((qrCode,i) => (
            <div key={i}>
              <h1>{qrCode.name}</h1>
              <img src={URL.createObjectURL(qrCode.data)} />
            </div>
          ))}
        </div>}
    </>
  );
};

export default QrCodes;
