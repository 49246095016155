import jwt_decode from 'jwt-decode';
import moment from 'moment';

const JWT_TOKEN_KEY = 'jwt-token';
const REFRESH_TOKEN_KEY = 'refresh-token';

export function isAuthenticated() {
	return getToken() && getRefreshToken();
}

export function getUserClaims() {
	const claims = decodeToken();
	if(claims == null) return null;
	return {
		...claims,
		btennant: JSON.parse(claims.btennant),
		sites: JSON.parse(claims.sites),
		roles: JSON.parse(claims.roles)
	};
}

export function setToken(token: string) {
	localStorage.setItem(JWT_TOKEN_KEY, token);
}

export function getToken() {
  return localStorage.getItem(JWT_TOKEN_KEY);
}

export function removeToken() {
	localStorage.removeItem(JWT_TOKEN_KEY);
}

export function getRolesFromToken() {
	return decodeToken()?.roles;
}

export function hasTokenExpired() {
	return decodeToken()?.exp < moment().unix();
}

function decodeToken(): any {
	const token = getToken();

	return !!token ? jwt_decode(token) : null;
}

export function getRefreshToken() {
  return localStorage.getItem(REFRESH_TOKEN_KEY);
}

export function setRefreshToken(refreshToken: string) {
  localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
}

export function removeRefreshToken() {
  localStorage.removeItem(REFRESH_TOKEN_KEY);
}