export const Attachment = (json: any) => {
  if (!json) {
    json = {};
  }
  const name: string | null = json.name;
  const attachmentId: number | null = json.attachmentId;
  const statusCode: string | null = json.statusCode || null;
  const serviceId: number | null = json.serviceId || null;
  const blobName: string | null = json.blobName || null;
  const fileExtension: string | null = json.fileExtension || null;
  const isInvoiceAttachment: boolean = !!json.isInvoiceAttachment;
  return {
    attachmentId,
    name,
    statusCode,
    serviceId,
    blobName,
    fileExtension,
    isInvoiceAttachment,
  };
};
export interface Attachment extends ReturnType<typeof Attachment> {}

export const UploadResult = (json: any) => {
  if (!json) {
    json = {};
  }
  const filename: string | null = json.filename || null;
  const fileExtension: string | null = json.fileExtension || null;
  const blobName: string | null = json.blobName || null;
  return {
    filename,
    fileExtension,
    blobName,
  };
};
export interface UploadResult extends ReturnType<typeof UploadResult> {}

export const AttachmentFile = (json: any) => {
  if (!json) {
    json = {};
  }
  const files: File[] = json.files || [];
  const append = (file: File) => {
    if (files.indexOf(file) >= 0) return;
    files.push(file);
  };
  const data: () => FormData = () => {
    var form = new FormData();
    files.forEach((file) => {
      form.append("file", file, file.name);
    });
    return form;
  };
  const dataFor: (file: File) => FormData = (file) => {
    var form = new FormData();
    form.append("file", file, file.name);
    return form;
  };
  return {
    data,
    dataFor,
    files,
    append,
    hasAttachments: files.length > 0,
  };
};
export interface AttachmentFile extends ReturnType<typeof AttachmentFile> {}
