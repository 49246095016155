import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { Observable } from "rxjs";
import { Site } from "./site";
import { map } from "rxjs/internal/operators/map";
import { Guid } from "../system/guid";
import { observableAxios, ObservableAxios } from "../rxjs-axios";
@Injectable({
  providedIn: "root",
})
export class SiteService {
  private readonly base_url = environment.dependencies.entity_api.url;
  private readonly sites_url = this.base_url + "/api/sites";
  
  private readonly http: ObservableAxios = observableAxios;

  constructor() {}
  public getSites(): Observable<Array<Site>> {
    return this.http.get(`${this.sites_url}`).pipe(
      map((data: Array<any>) => {
        return data.map((data) => {
          return Site.deSerialize(data);
        });
      })
    );
  }

  public getSite(siteId: any): Observable<Site> {
    return this.http.get(`${this.sites_url}/${siteId}`).pipe(
      map((data: any) => {
        return Site.deSerialize(data);
      })
    );
  }

  public putSite(siteId: Guid, values: Site) {
    return this.http.put(`${this.sites_url}/${siteId}`, values);
  }
}
