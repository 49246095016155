import { Injectable } from "@angular/core";

@Injectable()
export class UserSiteService {
    private siteId: string;

	setSiteId(siteId: string){
		this.siteId = siteId
		localStorage.setItem('siteId', JSON.stringify(this.siteId));
	}

	getSiteId(): string {
		if(!this.siteId) {
			try {
				this.siteId = JSON.parse(localStorage.getItem('siteId') ?? "\"\"");				
			} catch (error) {
				this.siteId = "";
			}
		}
		return this.siteId;
    }
}