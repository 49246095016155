import * as React from "react";
import { useState } from "react";
import { Box, Button, Dialog, IconButton, TextField, Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Autocomplete } from "@mui/lab";
import { FormikProps, useFormikContext } from "formik";
import FormikField from "../../react-components/FormikField";
import { IValues, ISensorValues, LimitProps } from "../interfaces";
import ServiceHoursDefinitionDialog from "./ServiceHoursDefinitionDialog";
import { useTranslation } from "react-i18next";
import EditEntityContext from "../EditEntityContext";
import { isBitmeshUser } from "../../auth-utils";
import ObjectTypeDialog from "./ObjectTypeDialog";
import EntityTypeRulesCard from "../../entity-types-react/components/EntityTypeRulesCard";
import MediaRender from "../../react-components/MediaRender";
import ObjectImageDialog from "./ObjectImageDialog";
import { FILEUPLOAD_URL } from "../../checklist-react/services/checklistService";
import CloseIcon from '@mui/icons-material/Close';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: "1rem",
  },
  sensorContainer: {
    marginTop: theme.spacing(3),
  },
  input: {
    margin: theme.spacing(1),
  },
  sensorTitle: {},
  buttonIcon: {
    fontSize: "1.5em",
  },
  addTitle: {
    marginTop: theme.spacing(3),
  },
  imgPreviewContainer: {
    display: 'flex',
    alignItems: "center",
    padding: `${theme.spacing(1)} 0`
  },
  imgPreview: {
    objectFit: "contain",
    maxWidht: "240px",
    maxHeight: "120px"
  },
  closeButton: {
    color: "white",
    position: "absolute",
    right: "0",
    top: "0",
    cursor: "pointer"
  },
  imgInDialog: {
    maxHeight: "90vh"
  },
  clickToPreview: {
    marginBottom: theme.spacing(2),
    fontStyle: "italic",
    color: theme.palette.grey[700],
  }
}));

type Props = {
  label: string;
  signals: any;
  name: string;
  value: ISensorValues;
  limits?: Array<LimitProps>;
  exceptionsValues?: any;
  objectTypeValue?: any;
  termoObjectType?: any;
};

const SensorForm = ({
  signals,
  label,
  name,
  value,
  limits,
  exceptionsValues,
  objectTypeValue,
  termoObjectType,
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { setFieldValue, errors, values }: FormikProps<IValues> = useFormikContext();
  const { user } = React.useContext(EditEntityContext);
  const [objectImageOpen, setObjectImageOpen] = useState<boolean>(false);
  const [exceptionsOpen, setExceptionsOpen] = useState<boolean>(false);
  const [objectTypeDialogOpen, setObjectTypeDialogOpen] =
    useState<boolean>(false);
  const [exceptions, setExceptions] = useState(exceptionsValues || []);
  const [objectType, setObjectType] = useState(objectTypeValue || "");
  const [objectTypeRules, setObjectTypeRules] = useState(termoObjectType?.rules);

  const [imagePreviewDialogOpen, setImagePreviewDialogOpen] = useState(false);

  const handleExceptionsOpen = () => {
    setExceptionsOpen(!exceptionsOpen);
  };

  const handleObjectImageOpen = () => {
    setObjectImageOpen(!objectImageOpen);
  };

  const handleExceptionsSave = () => {
    setFieldValue(`limits.${name}.limits`, exceptions);
    handleExceptionsOpen();
  };

  const handleObjectTypeSave = (objectType) => {
    console.log(objectType)
    setFieldValue(`${name}.termoObjectTypeId`, objectType);
    setObjectTypeDialogOpen(false);
  };

  const hideSensorId = value?.sourceId?.endsWith("/clean");
  const index = values.objectImages.findIndex(
    (v) => v.sourceId === value?.sourceId
  );
  const objectImage = values.objectImages[index];

  return (
    <>
      <Box className={classes.sensorContainer}>
        <Typography variant="h3" className={classes.sensorTitle}>
          {label}
        </Typography>
        {isBitmeshUser(user?.adalUser) && !hideSensorId && index !== -1 && objectImage?.imageUrl &&
          <>
            <div className={classes.imgPreviewContainer}>
              <div style={{ cursor: "pointer" }} onClick={() => setImagePreviewDialogOpen(true)}>
                <MediaRender src={`${FILEUPLOAD_URL}/entity/${objectImage?.imageUrl}`} className={classes.imgPreview} />
              </div>
            </div>
            <div className={classes.clickToPreview}>
              {t('entityForm.objectImage.clickToPreview')}
            </div>
          </>}
        <Dialog
          open={imagePreviewDialogOpen} onClose={() => setImagePreviewDialogOpen(false)}
          sx={{ margin: 0 }}
          PaperProps={{ sx: { margin: 0, maxWidth: "unset", maxHeight: "unset", position: "relative" } }}
        >
          <CloseIcon className={classes.closeButton} style={{ paddingTop: 0 }} onClick={() => setImagePreviewDialogOpen(false)} />
          <MediaRender src={`${FILEUPLOAD_URL}/entity/${objectImage?.imageUrl}`} className={classes.imgInDialog} />
        </Dialog>
        {isBitmeshUser(user?.adalUser) && !hideSensorId && (
          <Autocomplete
            id="sourceId"
            className={classes.input}
            options={signals}
            getOptionLabel={(option) => option.sourceId}
            value={value as ISensorValues}
            onChange={(e, value) => {
              setFieldValue(name, value);
            }}
            isOptionEqualToValue={(option, value) =>
              value.sourceId === option.sourceId
            }
            renderInput={(params) => (
              <TextField
                {...params}
                name={name}
                label="Sensor ID"
                fullWidth
                error={Boolean(errors[name]?.sourceId)}
                helperText={errors[name]?.sourceId}
              />
            )}
          />
        )}
        <Box display="flex" alignItems="center">
          {
            objectTypeRules ? <EntityTypeRulesCard rules={objectTypeRules} showActionDescription={false} />
              :
              <>
                {limits &&
                  limits.map((item, key) => (
                    <FormikField
                      key={key}
                      name={`limits.${name}.${item.name}`}
                      label={item.label}
                      error={errors.limits?.[name]?.[item.name]}
                    />
                  ))}
              </>
          }
          {limits && (
            <Button variant="contained" onClick={handleExceptionsOpen}>
              {t("entityForm.exceptions.buttonLabel")}
            </Button>
          )}
          <Button
            variant="contained"
            sx={{ marginLeft: "1em" }}
            onClick={() => setObjectTypeDialogOpen(true)}
          >
            {t("entityForm.objectType.buttonLabel")}
          </Button>
          <Button
            variant="contained"
            sx={{ marginLeft: "1em" }}
            onClick={() => setObjectImageOpen(true)}
          >
            {t("entityForm.objectImage.buttonLabel")}
          </Button>
        </Box>
      </Box>
      <ServiceHoursDefinitionDialog
        open={exceptionsOpen}
        onClose={handleExceptionsOpen}
        limits={limits}
        values={exceptions}
        setValues={setExceptions}
        handleSave={handleExceptionsSave}
        translationRootKey="entityForm.exceptions"
      />
      <ObjectImageDialog
        sourceId={value?.sourceId}
        open={objectImageOpen}
        onClose={handleObjectImageOpen}
      />
      <ObjectTypeDialog
        open={objectTypeDialogOpen}
        onClose={() => setObjectTypeDialogOpen(false)}
        objectType={objectType}
        setObjectType={setObjectType}
        setObjectTypeRules={setObjectTypeRules}
        handleSave={handleObjectTypeSave}
      />
    </>
  );
};

export default SensorForm;
