import * as moment from "moment";
import { take } from "rxjs/operators";
import { map } from "rxjs/internal/operators/map";
import { Alarm, getLatestRegisterAlarmIfAlarmState } from "../../alarm";
import { Site } from "../../entity/site";
import { SiteEntities } from "../../entity/siteentities";
import { Entity } from "../../entity/entity";
import { SiteService } from "../../entity/site.service";
import { EntityService } from "../../entity/entity.service";
import { AlarmsService } from "../../alarms.service";
import axios from "axios";
import { environment } from "../../../environments/environment";
import { isBitmeshUser } from "../../auth-utils";

const ALARMS_URL = environment.dependencies.entity_api.url + "/api/alarms";

export type EntitySiteMap = {
  // entityId
  [key: string]: {
    site: Site,
    entity: Entity
  }
}

export class SiteAlarmInfo {
  public entityAlarms: EntityAlarmsInfo[] = [];
  public showEntities = false;
  public isAlarmState = false;

  constructor(public siteName: string) {}

  public toggleShowEntities() {
    this.showEntities = !this.showEntities;
  }

  public addEntityAlarms(entityAlarms: EntityAlarmsInfo) {
    if (entityAlarms.isAlarmState) {
      this.isAlarmState = true;
    }
    this.entityAlarms.push(entityAlarms);
  }

  public alarmsCount(): number {
    return this.entityAlarms
      .map((entityAlarm) => {
        return entityAlarm.alarms.length;
      })
      .reduce((prev, current) => prev + current, 0);
  }
}

export class EntityAlarmsInfo {
  public showAlarms = false;
  public alarms: AlarmInfo[];
  public isAlarmState = false;

  constructor(public entityName: string, alarms: Alarm[]) {
    alarms.sort((a, b) => (a.createdOn < b.createdOn ? -1 : 1));

    this.alarms = alarms.map(
      (alarm) => new AlarmInfo(alarm.type, alarm.createdOn)
    );
    this.isAlarmState = getLatestRegisterAlarmIfAlarmState(alarms) !== null;
  }

  public toggleShowAlarms() {
    this.showAlarms = !this.showAlarms;
  }
}

export class AlarmInfo {
  public date: string;

  constructor(public type: string, date: moment.Moment) {
    this.date = moment(date).format("HH:mm DD.MM.YYYY.");
  }
}

export function fetchSiteAlarmInfo(
  entitySiteMap: EntitySiteMap,
  alarmService: AlarmsService,
  onLoadCompleted: (siteAlarms: SiteAlarmInfo[]) => void
) {
  alarmService
    .getAlarmsForEntities(Object.keys(entitySiteMap))
    .pipe(take(1))
    .subscribe(
      (alarmsMap: Map<string, Alarm[]>) => {
        const siteAlarmsMap: { [key: string]: SiteAlarmInfo } = {};
        alarmsMap.forEach((alarms: Alarm[], entityId: string) => {
          const { site, entity } = entitySiteMap[entityId];
          const siteId = site.id.toString();
          if(!siteAlarmsMap[siteId]) {
            siteAlarmsMap[siteId] = new SiteAlarmInfo(site.name);
          }
          const entityAlarms = new EntityAlarmsInfo(
            entity.name,
            alarms
          );

          siteAlarmsMap[siteId].addEntityAlarms(entityAlarms);
        });
        onLoadCompleted(Object.keys(siteAlarmsMap).map((siteId) => siteAlarmsMap[siteId]));
      },
      (error) => {
        console.log(
          "Error getting alarms",
          error
        );
      }
    );
}

export function fetchEntitySiteMap(
  entityService: EntityService,
  siteService: SiteService,
  user: any,
  onLoadCompleted: (entitySiteMap: EntitySiteMap) => void
) {
  let loadCountRemaining = 0;
  const entitySiteMap = {};
  const siteCompletedLoading = () => {
    loadCountRemaining--;

    if (loadCountRemaining > 0) {
      return;
    }

    onLoadCompleted(entitySiteMap);
  }

  if (user == null) return;

  var siteIds = user.adalUser.sites;

  siteService
    .getSites()
    .pipe(
      map((list: Site[]) =>
        list.filter((x: Site) => {
          return isBitmeshUser(user?.adalUser) || siteIds.indexOf(x.id.toString()) >= 0;
        })
      )
    )
    .pipe(take(1))
    .subscribe((sites: Site[]) => {
      loadCountRemaining = sites.length;

      sites.forEach((site) => {
        entityService
          .getSiteEntities(site.id.toString())
          .pipe(take(1))
          .subscribe(
            (siteEntities: SiteEntities) => {
              siteEntities.getEntities().forEach((entity: Entity) => {
                entitySiteMap[entity.id] = {
                  site,
                  entity
                }
              });
              siteCompletedLoading();
            },
            (error) => {
              siteCompletedLoading();
              console.log(
                "Error getting site entities for site " + site.name,
                error
              );
            }
          );
      });
    });
}

export async function getOpenMissingEventsAlarms(): Promise<AlarmAggregate[]> {
  return (await axios.get(`${ALARMS_URL}/open-missing-events`)).data;
}

export async function getAlarmsWithLastEventNotifyAlarm(): Promise<AlarmAggregate[]> {
  return (await axios.get(`${ALARMS_URL}/last-event-notify-alarm`)).data;
}

export interface AlarmAggregate {
  id: string;
  alarms: {
    createdOn: string;
    type: string;
  }
}