import { Observable, Subject } from "rxjs";
import { EntityDeviation } from "./entity-deviation";
import { Entity } from "./entity/entity";
import { AlarmsService } from "./alarms.service";
import { Alarm, getLatestRegisterAlarmIfAlarmState, AlarmType } from "./alarm";
import { TempReport } from "./reports/tempreport";
import { Measurement } from "./measurements/measurement";
import { Injectable } from "@angular/core";
import { TempreportService } from "./reports/tempreport.service";
import * as moment from "moment";

class EntityDeviationsCheckState {
  // public measurements: Measurement[];
  public entityDeviations: EntityDeviation[] = [];
  private entitiesToCheck: number;

  constructor(
    public siteId: any,
    entities: Entity[],
    public measurements: Measurement[],
    private observable: Subject<EntityDeviation[]>
  ) {
    this.entitiesToCheck = entities.length;
    // this.measurements = tempReport.getMeasurements();
  }

  public insertEntityDeviation(
    index: number,
    entityDeviation: EntityDeviation
  ) {
    this.entityDeviations.splice(index, 0, entityDeviation);
  }

  public finishedAlarmCheckForEntity() {
    this.entitiesToCheck--;

    // console.log("Finished alarm check for entity. Remaining entities to check: " + this.entitiesToCheck);

    if (this.entitiesToCheck < 1) {
      this.observable.next(this.entityDeviations);
    }
  }
}

@Injectable({
  providedIn: "root",
})
export class EntityDeviationService {
  constructor(
    private alarmsService: AlarmsService,
    private tempreportService: TempreportService
  ) {}

  public getEntityDeviations(
    siteId: any,
    entities: Entity[],
    measurements: Measurement[]
  ): Observable<EntityDeviation[]> {
    let observable = new Subject<EntityDeviation[]>();

    this.checkAlarms(siteId, entities, measurements, observable);

    return observable;
  }

  private checkAlarms(
    siteId: any,
    entities: Entity[],
    measurements: Measurement[],
    observable: Subject<EntityDeviation[]>
  ) {
    let state = new EntityDeviationsCheckState(
      siteId,
      entities,
      measurements,
      observable
    );

    var entityIds = entities.map((e) => e.id);
    this.alarmsService.getAlarmsForEntities(entityIds).subscribe((alarms:Map<string, Array<Alarm>>) => {
      entities.forEach((entity) => {
        var entityAlarms = alarms.get(entity.id);
        this.gotAlarmsForEntity(entity, entityAlarms??[], state);
      });
    },(err) => {
      if (err.response.status == 404) {
      }
      entities.forEach((entity) => {
        this.gotAlarmsForEntity(entity, [], state);
      });
    });
  }

  private gotAlarmsForEntity(
    entity: Entity,
    alarms: Alarm[],
    state: EntityDeviationsCheckState
  ) {
    this.checkCreateEntityDeviation(entity, alarms, state);
  }

  private checkCreateEntityDeviation(
    entity: Entity,
    alarms: Alarm[],
    state: EntityDeviationsCheckState,
    alarmsCheckSuccess = true
  ) {
    let rules = entity.obj.rules.temperature;
    if (!rules){ 
      state.finishedAlarmCheckForEntity();
      return
    };
    let min = rules.min;
    let max = rules.max;
    let measurement = state.measurements.find((m) => m.entityId === entity.id);

    let temperature = measurement !== undefined ? measurement.value : "?";
    let temperatureOk = temperature >= min && temperature <= max;

    let latestRegisterAlarmIfAlarmState =
      getLatestRegisterAlarmIfAlarmState(alarms);
    let isAlarmState =
      !alarmsCheckSuccess || latestRegisterAlarmIfAlarmState !== null;
    let handledAlarms = isAlarmState
      ? alarms.filter(
          (a) =>
            a.alarmId == latestRegisterAlarmIfAlarmState.alarmId &&
            a.alarmType == AlarmType.HandledAlarm
        )
      : [];
    let isHandled = handledAlarms.length > 0;
    let alarmText = alarmsCheckSuccess
      ? undefined
      : "Feil ved sjekking av alarm";

    if (isAlarmState || !alarmsCheckSuccess) {
      let insertIndex = this.getAddEntityIndex(
        entity.name,
        state.entityDeviations
      );
      let alarmFromTime =
        latestRegisterAlarmIfAlarmState != null
          ? moment(latestRegisterAlarmIfAlarmState.createdOn)
          : null;

      let entityDeviation = new EntityDeviation(
        entity.id,
        entity.name,
        temperature,
        temperatureOk,
        isAlarmState,
        isHandled,
        alarmText,
        alarmFromTime
      );
      this.checkReportDeliveredAndInsertEntityDeviation(
        entityDeviation,
        insertIndex,
        state
      );
    } else {
      state.finishedAlarmCheckForEntity();
    }
  }

  private getAddEntityIndex(
    entityName: string,
    entityDeviations: EntityDeviation[]
  ): number {
    for (var i = 0; i < entityDeviations.length; i++) {
      var tmpName = entityDeviations[i].name;
      if (entityName < tmpName) {
        return i;
      }
    }
    return entityDeviations.length;
  }

  private checkReportDeliveredAndInsertEntityDeviation(
    entityDeviation: EntityDeviation,
    insertIndex: number,
    state: EntityDeviationsCheckState
  ) {
    this.tempreportService
      .getDeliveredForEntity(
        state.siteId,
        entityDeviation.entityId,
        entityDeviation.alarmStartTime
      )
      .subscribe(
        (tempReport) => {
          entityDeviation.isDelivered = true;
          this.finishedProcessing(entityDeviation, insertIndex, state);
        },
        (err) => {
          this.finishedProcessing(entityDeviation, insertIndex, state);
        }
      );
  }

  private finishedProcessing(
    entityDeviation: EntityDeviation,
    insertIndex: number,
    state: EntityDeviationsCheckState
  ) {
    state.insertEntityDeviation(insertIndex, entityDeviation);
    state.finishedAlarmCheckForEntity();
  }
}
