import { Button, Theme, TextField, Typography } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import { DateTime } from "luxon";
import * as React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Guid } from "../../system/guid";
import { useChecklistContext } from "../ChecklistReactWrapper";
import {
  usePostManualRoutine,
} from "../services/checklistService";
import { Site } from "../../entity/site";
import Stack from "@mui/material/Stack";
import moment from "moment";

type Props = {
  checklist: any;
  onSuccess: () => void;
};

const defaultStartDelay = 1; //10 minutes between visible and active
const defaultTimeGap = 60; //60 minutes between events

const useStyles = makeStyles(({ spacing, palette }: Theme) =>
  createStyles({
    top: {
      flex: 1,
    },
    customSchedule: {
      paddingTop: "20px",
    },
  })
);

const ManualScheduleStart = ({ checklist, onSuccess }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [openCustom, setOpenCustom] = useState<boolean>(false);
  const [customDate, setCustomDate] = useState<string>("");
  const [customDueTime, setCustomDueTime] = useState(1);
  const [error, setError] = useState<boolean>(false);

  //checklist info
  const { user, siteService } = useChecklistContext();
  const [tenantId, setTenantId] = useState<string>("");

  useEffect(() => {
    const subscribtion = siteService
      .getSite(user.siteId)
      .subscribe((site: Site) => {
        setTenantId(site.tenantId.toString());
      });
    return () => subscribtion.unsubscribe();
  }, []);

  const handleCustomDate = (newDate) => {
    setCustomDate(newDate);
  };

  const handleCustomDueTime = (newTime) => {
    setCustomDueTime(parseInt(newTime));
  };

  const toggleOpenCustom = () => {
    setOpenCustom(!openCustom);
  };

  const handleStartRoutine = () => {
    const visibleTime = DateTime.now();
    const startTime = visibleTime.plus({ minutes: defaultStartDelay });
    const dueTime = startTime.plus({ minutes: defaultTimeGap });
    const hideTime = dueTime.plus({ minutes: defaultTimeGap });
    const sessionId = Guid.newGuid().toString();

    usePostManualRoutine({
      visibleTime,
      startTime,
      dueTime,
      hideTime,
      tenantId,
      sessionId,
      assignedId: checklist.assignedId,
      type: "ScheduleManual",
      siteId: user.siteId,
      timeStamp: visibleTime.minus({ seconds:1}),
    });
    onSuccess();
  };

  const handleStartCustomRoutine = () => {
    if (customDueTime < 0) {
      setCustomDueTime(1);
    }
    const visibleTime = moment();
    const startTime = moment(customDate);
    const dueTime = moment(startTime).add(customDueTime, "h");
    const hideTime = moment(dueTime).add(defaultTimeGap, "m");
    const sessionId = Guid.newGuid().toString();

    if (moment(customDate).isAfter(moment())) {
      setError(false);
      usePostManualRoutine({
        visibleTime,
        startTime,
        dueTime,
        hideTime,
        tenantId,
        sessionId,
        assignedId: checklist.assignedId,
        type: "ScheduleManual",
        siteId: user.siteId,
      });
      onSuccess();
    } else {
      setError(true);
    }
  };

  return (
    <>
      <div className={classes.top}>
        <Button variant="outlined" onClick={handleStartRoutine}>
          {t("manualSchedule.startNow")}
        </Button>
        {!openCustom && (
          <Button variant="outlined" onClick={toggleOpenCustom}>
            {t("manualSchedule.startLater")}
          </Button>
        )}
      </div>
      {openCustom && (
        <div className={classes.customSchedule}>
          <Stack spacing={3}>
            <Typography variant={"h3"}>
              {t("manualSchedule.customSchedule")}
            </Typography>

            <TextField
              error={error}
              label={t("manualSchedule.when")}
              type="datetime-local"
              defaultValue={customDate}
              onChange={(e) => handleCustomDate(e.target.value)}
              sx={{ width: 250 }}
              InputLabelProps={{
                shrink: true,
              }}
              helperText={t(
                "manualSchedule.selectDateTime"
              )}
            />

            <TextField
              type="number"
              label={t("manualSchedule.hours")}
              helperText={t("manualSchedule.duration")}
              onChange={(e) => handleCustomDueTime(e.target.value)}
            />
            <Button variant="outlined" onClick={handleStartCustomRoutine}>
              {t("manualSchedule.startLater")}
            </Button>
          </Stack>
        </div>
      )}
    </>
  );
};

export default ManualScheduleStart;
