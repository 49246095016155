import { Paper, Theme, Button, Grid, Tooltip, ClickAwayListener } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import React, { useEffect } from "react";
import { useChecklistContext } from "../../../ChecklistReactWrapper";
import { take } from "rxjs/operators";
import AddBreakageToPointDialog from "./AddBreakageToPointDialog";
import ChangeOrderOfPointDialog from "./ChangeOrderOfPointDialog";
import TagEntityToPointDialog from "./TagEntityToPointDialog";
import {
  ChecklistPointEvent,
  ChecklistPointEventHandler,
  ChecklistPointEventType,
  generateReversedPointEventsList,
} from "./ChecklistPointEventHandler";
import { Site } from "../../../../entity/site";
import { useTranslation } from "react-i18next";
import PointSettingsMenu from "./PointSettingsMenu";
import PointCardContent from "./PointCardContent";
import PointCardTitle from "./PointCardTitle";
import AttachMediaDialog from "../AttachMediaDialog";
import {
  BASE_CHECKLIST_MEDIA_PATH,
  FILEUPLOAD_URL,
} from "../../../services/checklistService";
import { SupportedMediaFormatsType } from "../../../../react-components/FileUpload/FileUpload";
import AddExternalLinkToPointDialog from "./AddExternalLinkToPointDialog";
import ArticleIcon from "@mui/icons-material/Article";
import { AddLabelDialog } from "./AddLabelDialog";
import { Guid } from "../../../../system/guid";

interface Entity {
  id: string;
  name: string;
}

type Props = {
  point?: any;
  includedFromChecklistsMap: { [key: string]: any };
  site: Site;
  checklist: any;
  isAdd?: boolean;
  isSiteSpecific?: boolean;
  templatePointsOnly?: boolean;
  availableLabelTypes: string[];
  onSave?: (events: any[]) => void;
  onCancel?: () => void;
};

const useStyles = makeStyles(({ spacing, palette }: Theme) =>
  createStyles({
    root: {
      marginLeft: spacing(1),
      marginRight: spacing(1),
    },
    divider: {
      marginTop: spacing(2),
      marginBottom: spacing(2),
    },
    paper: {
      backgroundColor: palette.grey[300],
      padding: spacing(2),
      minHeight: spacing(2),
      marginBottom: spacing(2),
    },
    pointTitleContainer: {
      display: "flex",
      justifyContent: "space-between",
    },
    pointTitleIconsContainer: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
    },
    templateIcon: {
      color: palette.primary.main,
    },
  })
);

const EditPointCard = ({
  point,
  site,
  checklist,
  isAdd = false,
  isSiteSpecific = false,
  templatePointsOnly = false,
  includedFromChecklistsMap,
  availableLabelTypes,
  onSave = () => { },
  onCancel = () => { },
}: Props) => {
  const classes = useStyles();

  const { t } = useTranslation();

  const { entityService, user } = useChecklistContext();

  const [entities, setEntities] = React.useState<Entity[]>([]);
  const [entitiesLoading, setEntitiesLoading] = React.useState<boolean>(false);

  const [isEdit, setEdit] = React.useState<boolean>(isAdd);


  const [newPointId, setNewPointId] = React.useState<string>(point?.id);

  React.useEffect(() => {
    if (isAdd && !point) {
      setNewPointId(Guid.newGuid().toString());
    }
  }, [isAdd, point])


  const [isSiteEditEnabled, setSiteEditEnabled] =
    React.useState<boolean>(false);

  const [isPointEnabled, setPointEnabled] = React.useState<boolean>(
    point?.enabled ?? false
  );
  const [isPointDeleted, setPointDeleted] = React.useState<boolean>(
    point?.deleted ?? false
  );
  const [isPointEnabledForSite, setPointEnabledForSite] =
    React.useState<boolean>(point?.siteEnabled ?? false);
  const [isPointDeletedForSite, setPointDeletedForSite] = React.useState<boolean>(
    point?.siteDeleted ?? false
  );

  const [isSiteEditDescription, setSiteEditDescription] =
    React.useState<boolean>(false);
  const [isSiteEditDetails, setSiteEditDetails] =
    React.useState<boolean>(false);

  const [isEntityDialogOpen, setEntityDialogOpen] =
    React.useState<boolean>(false);
  const [isBreakageDialogOpen, setBreakageDialogOpen] =
    React.useState<boolean>(false);
  const [isAttachMediaDialogOpen, setAttachMediaDialogOpen] =
    React.useState<boolean>(false);
  const [isOrderDialogOpen, setOrderDialogOpen] =
    React.useState<boolean>(false);
  const [isExternalLinkDialogOpen, setExternalLinkDialogOpen] =
    React.useState<boolean>(false);

  const [isAddLabelDialogOpen, setLabelDialogOpen] =
    React.useState<boolean>(false);

  const [pointName, setPointName] = React.useState<string>(point?.name);
  const [sitePointName, setSitePointName] = React.useState<string>(
    point?.siteName ?? ""
  );
  const [pointDescription, setPointDescription] = React.useState<string>(
    point?.description ?? ""
  );
  const [pointSiteDescription, setPointSiteDescription] =
    React.useState<string>(point?.siteDescription ?? "");
  const [pointSiteDetails, setPointSiteDetails] = React.useState<string>(
    point?.siteDetails ?? ""
  );
  const [associatedEntity, setAssociatedEntity] = React.useState<string>(
    point?.entityId ?? ""
  );
  const [breakageUnit, setBreakageUnit] = React.useState<string>(
    point?.unit ?? ""
  );

  const [labels, setLabels] = React.useState<string[]>(
    point?.labels ?? []
  );

  const [isMultiPoint, setIsMultiPoint] = React.useState<boolean>(point?.isMultiPoint ?? false);

  const [imagePath, setImagePath] = React.useState<string>(
    point?.siteImage ? point?.siteImage ?? "" : point?.image ?? ""
  );
  const [externalLink, setExternalLink] = React.useState<any>(
    point?.externalLink
  );
  const [externalLinkEnabled, setExternalLinkEnabled] = React.useState<boolean>(
    point?.externalLinkEnabled
  );

  const [pointEvents, setPointEvents] = React.useState<ChecklistPointEvent[]>(
    []
  );


  const addPointEvent = (e: ChecklistPointEvent) => {
    setPointEvents(prevEvents => [...prevEvents, e]);
  }


  // hooked on point properties change and on cancel
  useEffect(() => {
    setPointName(point?.name ?? "");
    setSitePointName(point?.siteName ?? "");
    setPointDescription(point?.description ?? "");
    setPointSiteDescription(point?.siteDescription ?? "");
    setPointSiteDetails(point?.siteDetails ?? "");
    setAssociatedEntity(point?.entityId ?? "");
    setBreakageUnit(point?.unit ?? "");
    setImagePath(
      point?.siteImage ? point?.siteImage ?? "" : point?.image ?? ""
    );
    setExternalLink(point?.externalLink);
    setExternalLinkEnabled(point?.externalLinkEnabled);
  }, [
    isEdit,
    point?.name,
    point?.description,
    point?.siteName,
    point?.siteDescription,
    point?.siteDetails,
    point?.entityId,
    point?.unit,
    point?.image,
    point?.siteImage,
    point?.externalLink,
    point?.externalLinkEnabled,
  ]);

  useEffect(() => {
    setEntitiesLoading(true);
    entityService
      .getSiteEntities(user.siteId)
      .pipe(take(1))
      .subscribe(({ siteEntities }: any) => {
        setEntities(siteEntities);
        setEntitiesLoading(false);
      });
  }, []);

  // hooked on point status change
  // if point is deleted, save it
  useEffect(() => {
    if (isAdd) return;
    if (!point.deleted && isPointDeleted) {
      handleClickSave();
    }
    if (!point.siteDeleted && isPointDeletedForSite) {
      handleClickSave();
    }
  }, [isPointDeleted, isPointDeletedForSite]);

  const handleSave = async () => {
    const events = generateReversedPointEventsList({
      isAdd,
      isSiteSpecific,
      newPointId,
      point,
      checklist,
      site,
      pointName,
      sitePointName,
      pointDescription,
      pointSiteDescription,
      isPointEnabled,
      isPointDeleted,
      externalLinkEnabled,
      isPointEnabledForSite,
      isPointDeletedForSite,
      pointSiteDetails,
      existingPointEvents: pointEvents,
    });
    onSave(await ChecklistPointEventHandler.handle(events));
    setPointEvents([]);
  };

  const handleClickPointDisable = () => {
    setPointEnabled(!isPointEnabled);
    setEdit(true);
  };

  const handleClickSitePointDisable = () => {
    setPointEnabledForSite(!isPointEnabledForSite);
    setSiteEditEnabled(true);
  };

  const setAllEditFalse = () => {
    setEdit(false);
    setSiteEditDescription(false);
    setSiteEditDetails(false);
    setSiteEditEnabled(false);
  };

  const handleClickCancel = () => {
    onCancel();
    setAllEditFalse();
    setPointEnabled(point?.enabled ?? true);
    setPointEnabledForSite(point?.siteEnabled ?? true);
    setPointEvents([]);
  };

  const handleClickSave = () => {
    handleSave();
    setAllEditFalse();
  };

  const showButtons =
    isEdit || isSiteEditDescription || isSiteEditDetails || isSiteEditEnabled;
  const isDescriptionOverriden =
    !!point?.siteDescription || isSiteEditDescription;

  const pointDisabled = templatePointsOnly
    ? !isPointEnabled
    : !isSiteSpecific
      ? !isPointEnabledForSite
      : !isPointEnabled;

  return (
    <div className={classes.root}>
      <Paper
        className={classes.paper}
        style={{ opacity: !pointDisabled ? 1 : 0.5 }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className={classes.pointTitleContainer}>
              <PointCardTitle
                isEdit={isEdit || isSiteEditEnabled}
                name={
                  isEdit
                    ? pointName
                    : isSiteEditEnabled
                      ? sitePointName || pointName
                      : sitePointName || pointName
                }
                onNameChange={
                  isSiteEditEnabled ? setSitePointName : setPointName
                }
                includedFromName={
                  point?.isPointIncluded
                    ? includedFromChecklistsMap[point.includedFromChecklistId]
                      ?.name
                    : null
                }
              />
              <div className={classes.pointTitleIconsContainer}>
                {!isSiteSpecific && (
                  <Tooltip
                    title={`${t("editChecklist.editPoint.card.templatePoint")}`}
                  >
                    <ArticleIcon className={classes.templateIcon} />
                  </Tooltip>
                )}
                <PointSettingsMenu
                  isAdd={isAdd}
                  isEdit={isEdit}
                  isSiteSpecific={isSiteSpecific}
                  isPointEnabled={isPointEnabled}
                  isPointEnabledForSite={isPointEnabledForSite}
                  onEditClick={() => {
                    setAllEditFalse();
                    setEdit(true);
                  }}
                  onAssignEntityClick={() => {
                    setEdit(true);
                    setEntityDialogOpen(true);
                  }}
                  onMoveClick={(forSite) => {
                    forSite ? setSiteEditEnabled(true) : setEdit(true);
                    setOrderDialogOpen(true);
                  }}
                  onAddExternalLinkClick={() => {
                    setEdit(true);
                    setExternalLinkDialogOpen(true);
                  }}
                  onSetBreakageClick={() => {
                    setEdit(true);
                    setBreakageDialogOpen(true);
                  }}
                  onAttachMediaClick={(forSite) => {
                    forSite ? setSiteEditEnabled(true) : setEdit(true);
                    setAttachMediaDialogOpen(true);
                  }}
                  onEditSiteClick={() => {
                    setSiteEditEnabled(true);
                    setSiteEditDescription(true);
                    if (!sitePointName) {
                      setSitePointName(pointName);
                    }
                    if (!pointSiteDescription) {
                      setPointSiteDescription(pointDescription);
                    }
                  }}
                  onAddLabelClicked={() => {
                    setEdit(true);
                    setLabelDialogOpen(true);
                  }}
                  onEditSiteDetailsClick={() => setSiteEditDetails(true)}
                  onPointDisabled={handleClickPointDisable}
                  onPointDisabledSite={handleClickSitePointDisable}
                  onPointDeleted={() => {
                    setPointDeleted(true);
                  }}
                  onPointDeletedSite={() => {
                    setPointDeletedForSite(true);
                  }}
                />
              </div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <PointCardContent
              isEdit={isEdit}
              isSiteSpecific={isSiteSpecific}
              assignedId={checklist.assignedId}
              isMultiPoint={isMultiPoint}
              pointId={point?.id}
              onMultiPointEnabled={(ev) => {
                addPointEvent(ev);
                setIsMultiPoint(true);
              }}
              onMultiPointDisabled={(ev) => {
                addPointEvent(ev);
                setIsMultiPoint(false);
              }}
              isSiteEditDescription={isSiteEditDescription}
              isSiteEditDetails={isSiteEditDetails}
              isDescriptionOverriden={isDescriptionOverriden && !isSiteSpecific}
              templateDescription={pointDescription}
              description={
                isDescriptionOverriden ? pointSiteDescription : pointDescription
              }
              onDescriptionChange={
                isDescriptionOverriden
                  ? setPointSiteDescription
                  : setPointDescription
              }
              details={pointSiteDetails}
              onDetailsChange={setPointSiteDetails}
              associatedEntity={
                entitiesLoading
                  ? associatedEntity
                    ? `${t("Loading")}...`
                    : ""
                  : entities.find((e) => e.id === associatedEntity)?.name
              }
              breakageUnit={breakageUnit}
              labels={labels}
              imagePath={imagePath}
              externalLink={externalLink}
              externalLinkEnabled={externalLinkEnabled}
              checklist={checklist}
            />
          </Grid>
          <TagEntityToPointDialog
            point={point}
            assignedId={checklist.assignedId}
            checklistId={checklist.checklistId}
            onSubmit={(e) => {
              addPointEvent(e);
              setAssociatedEntity(e.data.entityId);
            }}
            open={isEntityDialogOpen}
            onClose={() => setEntityDialogOpen(false)}
            entities={entities.map((e) => {
              return {
                value: e.id,
                label: e.name,
              };
            })}
          />
          <AddBreakageToPointDialog
            point={point}
            checklistId={checklist.checklistId}
            onSubmit={(e) => {
              addPointEvent(e);
              setBreakageUnit(e.data.unit);
            }}
            open={isBreakageDialogOpen}
            onClose={() => setBreakageDialogOpen(false)}
          />

          <AddLabelDialog
            open={isAddLabelDialogOpen}
            existingOptions={availableLabelTypes}
            labelsOnPoint={point?.labels ?? []}
            isSiteSpecific={isSiteSpecific}
            assignedId={checklist.assignedId}
            onClose={() => setLabelDialogOpen(false)}
            tenantId={site?.tenantId.toString()}
            point={point ?? { id: newPointId }}
            checklistId={checklist.checklistId}
            onSubmit={(ev) => {
              addPointEvent(ev);
              setLabels((prevLabels) => {
                if (ev.type === ChecklistPointEventType.LabelAdded || ev.type === ChecklistPointEventType.AssignedLabelAdded) {
                  return [...prevLabels, ev.data.labelType];
                } else if (ev.type === ChecklistPointEventType.LabelRemoved || ev.type === ChecklistPointEventType.AssignedLabelRemoved) {
                  return prevLabels.filter((label) => label !== ev.data.labelType);
                }
              })
            }}
          />
          <ChangeOrderOfPointDialog
            point={point}
            checklistId={checklist.checklistId}
            checklist={checklist}
            onSubmit={(data) => {
              addPointEvent({
                type:
                  isSiteSpecific || isSiteEditEnabled
                    ? ChecklistPointEventType.SitePointMovedAfter
                    : ChecklistPointEventType.PointMovedAfter,
                data: {
                  ...data,
                  ...(isSiteSpecific || isSiteEditEnabled
                    ? { assignedId: checklist.assignedId }
                    : {}),
                },
              });
            }}
            open={isOrderDialogOpen}
            onClose={() => setOrderDialogOpen(false)}
          />
          <AddExternalLinkToPointDialog
            externalLink={externalLink}
            externalLinkEnabled={externalLinkEnabled}
            checklistId={checklist.checklistId}
            onSubmit={(e) => {
              addPointEvent(e);
              setExternalLink({ title: e.data.title, url: e.data.url });
            }}
            onEnable={() => {
              setExternalLinkEnabled(true);
            }}
            onDisable={() => {
              setExternalLinkEnabled(false);
            }}
            open={isExternalLinkDialogOpen}
            onClose={() => setExternalLinkDialogOpen(false)}
          />
          <AttachMediaDialog
            open={isAttachMediaDialogOpen}
            onSubmit={(
              mediaId: string,
              extension: SupportedMediaFormatsType
            ) => {
              const newImagePath = `${BASE_CHECKLIST_MEDIA_PATH}/${mediaId}${extension}`;
              addPointEvent({
                type:
                  isSiteSpecific || isSiteEditEnabled
                    ? ChecklistPointEventType.SiteAddMediaPoint
                    : ChecklistPointEventType.PointMediaAdded,
                data: {
                  checklistId: checklist.checklistId,
                  ...(isSiteSpecific || isSiteEditEnabled
                    ? { assignedId: checklist.assignedId }
                    : {}),
                  tenantId: site.tenantId.toString(),
                  imagePath: newImagePath,
                },
              });
              setImagePath(`${FILEUPLOAD_URL}/${newImagePath}`);
            }}
            onClose={() => setAttachMediaDialogOpen(false)}
          />
        </Grid>
      </Paper>
      {showButtons && (
        <Button
          variant="contained"
          color="primary"
          style={{ marginLeft: "1em", marginBottom: "1em" }}
          onClick={handleClickSave}
        >
          {t("Save")}
        </Button>
      )}
      {showButtons && (
        <Button
          variant="contained"
          color="primary"
          style={{ marginLeft: "1em", marginBottom: "1em" }}
          onClick={handleClickCancel}
        >
          {t("Cancel")}
        </Button>
      )}
    </div>
  );
};

export default EditPointCard;
