import { Component, OnInit } from "@angular/core";
import { Guid } from "../system/guid";
import { ServiceBusService } from "../system/service-bus.service";
import { Chanels } from "../chanels";
import { SiteEntities } from "../entity/siteentities";
import { EntityService } from "../entity/entity.service";
import * as moment from "moment";
import { take } from "rxjs/operators";
import { Observable, Subscription, catchError, throwError } from "rxjs";
import { SignalsService } from "../signals/signals.service";
import { SignalEvent } from "../signals/vevent";
import { OpenCloseObject } from "../entity/open-close-object";
import {
  DoorEvent,
  DoorEventValue,
  printDoorEventValue,
  CleanEvent,
} from "../signals/doorevent";
import { map } from "rxjs/internal/operators/map";

@Component({
  selector: "doors",
  templateUrl: "./doors-clean.component.html",
  styleUrls: ["./doors-clean.component.scss"],
})
export class DoorsCleanComponent implements OnInit {
  siteId: Guid;

  public isLoadingEntities = true;
  public entities: OpenCloseEntity[] = [];

  private userSubscription: Subscription;

  constructor(
    private bus: ServiceBusService,
    private entityService: EntityService,
    private signalsService: SignalsService
  ) {}

  ngOnInit() {
    this.userSubscription = this.bus
      .chanelObservable(Chanels.USER_CHANEL)
      .subscribe((user) => {
        let siteId = user.siteId;

        this.entityService
          .getSiteEntities(siteId)
          .pipe(take(1))
          .subscribe(
            (siteEntities: SiteEntities) => {
              let openCloseEntities = siteEntities
                .getEntities()
                .filter((e) => e.isOpenClose());

              this.entities = openCloseEntities.map((e) => {
                let openCloseObject: OpenCloseObject = e.getValue();
                let limit = openCloseObject.rules.door.max;

                return new OpenCloseEntity(
                  e.id,
                  e.getName(),
                  limit,
                  this.signalsService
                );
              });
              this.isLoadingEntities = false;
            },
            (err) => {
              if (err.status != 404) {
                console.log("Error retrieving entities:", err);
              }

              this.isLoadingEntities = false;
            }
          );
      });
  }

  ngOnDestroy() {
    this.userSubscription.unsubscribe();
  }
}

export class OpenCloseEntity {
  public openCloseInfo: OpenCloseInfo;
  public latestDoorInfo: LatestDoorEventInfo;
  public latestCleanInfo: LatestEventInfo;

  private events$: Observable<DoorEvent[]>;
  private latestDoorEvent$: Observable<DoorEvent>;
  private latestCleanEvent$: Observable<CleanEvent>;

  constructor(
    private entityId: string,
    public name: string,
    public openCloseLimit: number,
    private signalsService: SignalsService
  ) {
    this.events$ = this.signalsService.GetDoorEventsSinceLastClean(
      this.entityId
    );
    this.latestDoorEvent$ = this.signalsService.GetLatestDoorEvent(
      this.entityId
    );
    this.latestCleanEvent$ = this.signalsService.GetLatestCleanEvent(
      this.entityId
    );

    this.loadData();
  }

  private loadData() {
    this.getOpenCloseInfo(this.events$)
      .pipe(take(1))
      .subscribe((info) => {
        this.openCloseInfo = info;
      });

    this.latestDoorEvent$.pipe(take(1)).subscribe((latest) => {
      if (!latest) return;
      this.latestDoorInfo = new LatestDoorEventInfo(latest);
    });

    this.latestCleanEvent$.pipe(take(1)).subscribe((latest) => {
      if (!latest) return;
      this.latestCleanInfo = new LatestEventInfo(latest);
    });
  }

  public registerCleanEvent() {
    let sourceId = `${this.entityId}/clean`;
    let cleanEvent = new CleanEvent(
      Guid.newGuid(),
      "CleanEvent",
      sourceId,
      moment().utc()
    );
    this.signalsService
      .postCleanEvent(cleanEvent)
      .pipe(
        catchError((err) => {
          console.log(JSON.stringify(err));
          return throwError(() => err);
        })
      )
      .pipe(take(1))
      .subscribe(() => {
        this.loadData();
      });
  }

  private getOpenCloseInfo(
    events: Observable<SignalEvent[]>
  ): Observable<OpenCloseInfo> {
    return events.pipe(
      map((doorEvents: DoorEvent[]) => {
        return new OpenCloseInfo(this.openCloseLimit, doorEvents);
      })
    );
  }
}

export class LatestEventInfo {
  public isFound: boolean;
  public occuredTime: string;

  constructor(latest?: SignalEvent) {
    this.isFound = !!latest;

    if (this.isFound) {
      this.occuredTime = latest.timestamp.fromNow();
    }
  }
}

export class LatestDoorEventInfo extends LatestEventInfo {
  public state: string;

  constructor(latest?: DoorEvent) {
    super(latest);

    if (this.isFound) {
      this.state = printDoorEventValue(latest.value);
    }
  }
}

export class OpenCloseInfo {
  public countSinceLastCleaning = 0;
  public countPeriodTotal = 0;
  public usagePercentage = 0;
  public usageColorHex: string;
  public usageColorHighlightHex: string;
  public lastSignalDate: moment.Moment;
  public lastState: string;

  private readonly colorStart = new Color(20, 200, 20);
  private readonly colorEnd = new Color(200, 20, 20);

  constructor(
    public openCloseLimit: number,
    public events: Array<SignalEvent>
  ) {
    this.countPeriodTotal = this.countCloseEvents(events);

    let indexLastReset = events.map((e) => e.type).lastIndexOf("CleanEvent");

    let eventsSinceLastReset =
      indexLastReset >= 0 ? events.slice(indexLastReset + 1) : events;

    this.countSinceLastCleaning = this.countCloseEvents(eventsSinceLastReset);
    this.usagePercentage = Math.round(
      (this.countSinceLastCleaning / openCloseLimit) * 100
    );

    let colorUsage = this.colorStart.transform(
      this.colorEnd,
      this.usagePercentage
    );

    this.usageColorHex = colorUsage.toHex();
    this.usageColorHighlightHex = colorUsage.highlight(50).toHex();

    console.log("events: ", events);
    console.log("eventsSinceLastReset: ", eventsSinceLastReset);
    console.log("countPeriodTotal: ", this.countPeriodTotal);
    console.log("countSinceLastReset: ", this.countSinceLastCleaning);
  }

  private countCloseEvents(events: SignalEvent[]) {
    return events.filter(
      (e: any) =>
        e.type === "DoorOpenCloseEvent" && e.value === DoorEventValue.Close
    ).length;
  }
}

export class Color {
  private static readonly highlightColor = new Color(255, 255, 255);

  constructor(public r: number, public g: number, public b: number) {}

  public transform(other: Color, percentage: number): Color {
    if (percentage >= 100) {
      return new Color(other.r, other.g, other.b);
    }

    let progress = percentage / 100;
    let r = this.transformComponent(this.r, other.r, progress);
    let g = this.transformComponent(this.g, other.g, progress);
    let b = this.transformComponent(this.b, other.b, progress);

    return new Color(r, g, b);
  }

  private transformComponent(
    start: number,
    end: number,
    progress: number
  ): number {
    return Math.round(start * (1 - progress) + end * progress);
  }

  public highlight(percentage: number): Color {
    return this.transform(Color.highlightColor, percentage);
  }

  public toHex(): string {
    return (
      "#" +
      this.componentToHex(this.r) +
      this.componentToHex(this.g) +
      this.componentToHex(this.b)
    );
  }

  private componentToHex(c: number): string {
    var hex = c.toString(16);
    return hex.length == 1 ? "0" + hex : hex;
  }
}
