import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Entity } from "../../entity/entity";
import { EntityService } from "../../entity/entity.service";
import { Guid } from "../../system/guid";
import { TempReport } from "../tempreport";
import { TempreportService } from "../tempreport.service";
import { Measurement } from "../../measurements/measurement";
import * as moment from "moment";
import { DataService } from "../data.service";
import { ServiceBusService } from "../../system/service-bus.service";
import { Chanels } from "../../chanels";
import {
  ReportDeviationsType,
  GetReportDeviationsFromQueryParam,
  RouteWithReportType,
} from "../report-deviations-type";
import { SiteEntities } from "../../entity/siteentities";
import { EntityDeviationService } from "../../entity-deviation-service";
import { EntityDeviation } from "../../entity-deviation";
import { Deviation } from "../deviation";

@Component({
  selector: "report-start-screen",
  templateUrl: "./report-start-screen.component.html",
  styleUrls: ["./report-start-screen.component.scss"],
})
export class ReportStartScreenComponent implements OnInit {
  siteEntities: Array<Entity>;
  needsRegister: boolean = false;
  siteId: Guid;
  routerLinkValue: string;
  measurements: any = {};
  tempReportId: string;
  tempReport: TempReport;
  reportDate: string;

  private reportDeviationsType = ReportDeviationsType.HandlePeriodicDeviations;
  isStartReportEnabled = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private entityService: EntityService,
    private tempreportService: TempreportService,
    private entityDeviationService: EntityDeviationService,
    private bus: ServiceBusService,
    private data: DataService
  ) {
    this.route.queryParams.subscribe((params) => {
      let type = params["type"];
      if (type) {
        this.reportDeviationsType = GetReportDeviationsFromQueryParam(type);
      }
      let id = params["id"];
      if (id) {
        this.tempReportId = id;
      }
    });
  }

  ngOnInit() {
    this.getTempReport();
  }

  setReportDate(date: any) {
    moment.locale("nb-NO");
    this.reportDate = moment(date).format("DoMMMYY");
  }

  getTempReport() {
    this.bus.subscribe(Chanels.USER_CHANEL, (user) => {
      if (user == null) return;
      let siteId = user.siteId;
      if (this.tempReportId) {
        this.tempreportService.getPeriodicReportForLastSevenDays(siteId).subscribe(
          (response) => {
            const tempReport = response.find((x) => x.id === this.tempReportId);
            this.gotInprogressTempReport(siteId, tempReport);
            this.setReportDate(tempReport.createdOn);
          },
          (err) => {
            console.log(err);
          }
        );
      } else if (
        this.reportDeviationsType ===
        ReportDeviationsType.HandleCurrentDeviations
      ) {
        // Create a new report
        this.tempreportService.getNewReport(user.siteId).subscribe(
          (response) => {
            this.gotNewTempReport(siteId, response);
            this.setReportDate(response.createdOn);
          },
          (err) => {
            console.log(err);
          }
        );
      } else {
        // Use in progress report
        this.tempreportService.getInprogressReports(user.siteId).subscribe(
          (response) => {
            this.gotInprogressTempReport(siteId, response);
            this.setReportDate(response.createdOn);
          },
          (err) => {
            if (err.response.status == 404) {
              // TODO Expected an inprogress report to exist, handle the case when it is not
            }
            console.log(err);
          }
        );
      }
    });
  }

  private gotNewTempReport(siteId: any, tempReport: TempReport) {
    // console.log("gotNewTempReport:", tempReport);
    this.entityService
      .getSiteEntities(siteId)
      .subscribe((siteEntities: SiteEntities) => {
        let entities = siteEntities.getEntities().filter((e) => !e.disabled);
        let measurements = tempReport.getMeasurements();

        this.entityDeviationService
          .getEntityDeviations(siteId, entities, measurements)
          .subscribe((entityDeviations: EntityDeviation[]) => {
            // console.log("Report entity deviations:", entityDeviations);
            this.updateReportDeviations(
              tempReport,
              measurements,
              entityDeviations
            );

            this.registerTempReport(tempReport);
            this.checkForManuals(tempReport);
          });
      });
  }

  private gotInprogressTempReport(siteId: any, tempReport: TempReport) {
    // console.log("gotInprogressTempReport:", tempReport);
    this.registerTempReport(tempReport);
    this.checkForManuals(tempReport);
  }

  private updateReportDeviations(
    tempReport: TempReport,
    measurements: Measurement[],
    entityDeviations: EntityDeviation[]
  ) {
    let deviations: Deviation[] = [];

    entityDeviations.forEach((entityDeviation: EntityDeviation) => {
      let measurementExistsForEntityDeviation =
        measurements.filter((m) => m.entityId == entityDeviation.entityId)
          .length > 0;
      if (!entityDeviation.isHandled && measurementExistsForEntityDeviation) {
        // Only add entityDevation if a measurement exists for that entity
        let occurredTime = entityDeviation.alarmStartTime.toISOString();
        let deviation = new Deviation(
          Guid.newGuid().toString(),
          entityDeviation.entityId,
          occurredTime,
          "",
          100
        );
        deviations.push(deviation);
      }
    });

    tempReport.setDeviation(deviations);
  }

  checkForManuals(tempReport: TempReport) {
    this.needsRegister =
      tempReport.getMeasurements().filter((x) => !x.value).length > 0;
    this.routerLinkValue = "/temperature-register";
    // if(this.needsRegister){
    //     this.routerLinkValue = "/temperature-register";
    // }else{
    //     this.routerLinkValue = "/deviationProcedure";
    // }
  }

  registerTempReport(tempReport: TempReport) {
    console.log(
      "Register temp report for deviations type " + this.reportDeviationsType,
      tempReport
    );
    this.data.setTempReport(tempReport);

    this.isStartReportEnabled = true;
  }

  startReporting() {
    let route = "/temperature-register";

    RouteWithReportType(route, this.reportDeviationsType, this.router);
  }

  routing(route: string) {
    this.router.navigate([route]);
  }
}
