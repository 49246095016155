import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditEntityFormComponent } from './EditEntityFormReactWrapper';
import { MoveEntityFormComponent } from './MoveEntityFormReactWrapper';
import { NewEntityFormComponent } from './NewEntityFormReactWrapper';



@NgModule({
  declarations: [
    EditEntityFormComponent,
    MoveEntityFormComponent,
    NewEntityFormComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    EditEntityFormComponent,
    MoveEntityFormComponent,
    NewEntityFormComponent
  ]
})
export class EditEntityFormReactModule { }
