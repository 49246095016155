import * as React from "react";
import { useEffect, useState } from "react";
import * as moment from "moment";
import Chart from "react-google-charts";
import { useTranslation } from "react-i18next";

type Props = {
  startDate: any;
  endDate: any;
  timespan: moment.DurationInputArg2;
  signals: any;
  limits?: any;
};

const HumidityChart = ({
  signals,
  startDate,
  endDate,
  timespan,
  limits,
}: Props) => {
  const { t } = useTranslation();

  const [data, setData] = useState([]);
  const maxLimit = limits ? parseInt(limits.max) : null;
  const minLimit = limits ? parseInt(limits.min) : null;

  useEffect(() => {
    let chartData = [];
    signals.map((item) =>
      chartData.push([
        item.timestamp.toDate(),
        item.value,
        limits?.min && minLimit,
        limits?.max && maxLimit - minLimit,
      ])
    );
    setData(chartData);
  }, [signals]);

  return (
    <Chart
      chartType="ComboChart"
      loader={<div>Loading Chart</div>}
      data={[
        [
          { type: "date", label: t("Date") },
          { type: "number", label: t("Humidity") },
          { type: "number", label: "Min" },
          { type: "number", label: "Max" },
        ],
        ...data,
      ]}
      options={{
        isStacked: true,
        seriesType: "line",
        series: {
          1: {
            areaOpacity: 0,
            type: "area",
            color: "#A5D6A7",
            tooltip: false,
          },
          2: {
            type: "area",
            color: "#A5D6A7",
            tooltip: false,
          },
        },
        chartArea: { width: "85%" },
        hAxis: {
          gridlines: { count: 13 },
          format:
            timespan === "days" || timespan === "hours" ? "HH:mm" : "d. MMM",
          minValue: moment().subtract(startDate, timespan).toDate(),
          maxValue: moment().subtract(endDate, timespan).toDate(),
        },
        vAxis: {
          title: `${t("Humidity")} (%)`,
          maxValue: 100,
          minValue: 0,
        },
        legend: { position: "none" },
        height: 400,
      }}
    />
  );
};

export default HumidityChart;
