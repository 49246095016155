import * as React from "react";
import { useContext, useState, useEffect } from "react";
import * as moment from "moment";
import Chart from "react-google-charts";
import { ReportsContext } from "../ReportsReactWrapper";
import { take } from "rxjs/operators";
import {
  DoorEvent,
  DoorEventValue,
  printDoorEventValue,
} from "../../signals/doorevent";
import { renderToString } from "react-dom/server";
import TimelineTooltip from "./TimelineTooltip";
import { SignalEvent } from "../../signals/vevent";
import { useTranslation } from "react-i18next";

type Props = {
  entity: any;
  startDate: any;
  endDate: any;
  timespan: moment.DurationInputArg2;
};

const DoorReport = ({ entity, startDate, endDate, timespan }: Props) => {
  const { signalsService } = useContext(ReportsContext);
  const [data, setData] = useState([]);
  let firstDate = moment().subtract(startDate, timespan);
  let lastDate = moment().subtract(endDate, timespan);

  const { t } = useTranslation();

  useEffect(() => {
    signalsService
      .GetDoorSignalsForEntity(entity.id, firstDate, lastDate)
      .pipe(take(1))
      .subscribe((res: Array<DoorEvent>) => {
        let lastItem = false;
        let signals = res.filter(
          (e: SignalEvent) =>
            e.type === "DoorOpenCloseEvent" &&
            e.timestamp > moment(entity.createdOn) &&
            e.timestamp <= lastDate
        );
        signals.sort((a, b) => a.timestamp.diff(b.timestamp));
        let events = [];
        let hasSignalsBeforeEntityCreation =
          firstDate < moment(entity.createdOn);
        let firstDateValue = hasSignalsBeforeEntityCreation
          ? moment(entity.createdOn).toDate()
          : firstDate.toDate();
        signals.length > 0 &&
          events.push([
            signals[0]?.value === DoorEventValue.Open ? t("Closed") : t("Open"),
            "",
            "",
            "",
            firstDateValue,
            signals[0]?.timestamp.toDate(),
          ]);

        signals.map((event: DoorEvent, index: number) => {
          if (signals[index + 1] === undefined) {
            lastItem = true;
          }

          let eventsItem = [
            printDoorEventValue(event.value),
            "",
            "",
            renderToString(
              <TimelineTooltip
                event={event.timestamp}
                nextEvent={signals[index + 1]?.timestamp}
              />
            ),
            event.timestamp.toDate(),
            lastItem
              ? lastDate.toDate()
              : signals[index + 1].timestamp.toDate(),
          ];
          events.push(eventsItem);
        });

        const door =
          entity.signal && entity.signal.filter((v) => v.unit === "DoorOC");

        door.sort((a, b) => a.timestamp.diff(b.timestamp));

        let hasLastValue = firstDate > moment(door[0].lastValue);

        const openValue = "Åpen";
        const closedValue = "Lukket";
        const doorsignal = [
          [
            printDoorEventValue(door[0].value),
            "",
            hasLastValue ? "" : "opacity: 0",
            hasLastValue
              ? renderToString(
                  <TimelineTooltip event={moment(door[0].lastValue)} />
                )
              : "",
            firstDate.toDate(),
            lastDate.toDate(),
          ],
          [
            door[0].value === DoorEventValue.Open ? closedValue : openValue,
            "",
            "opacity: 0",
            "",
            firstDate.toDate(),
            lastDate.toDate(),
          ],
        ];

        events = events.length > 0 ? events : doorsignal;

        // sorting to make sure close is on top
        if (events[0][0] !== closedValue) {
          var firstClose = events.find((x, i) => x[0] == closedValue);
          var index = events.indexOf(firstClose);
          events[index] = events[0];
          events[0] = firstClose;
        }
        setData(events);
      });
  }, [timespan, startDate, endDate]);

  return (
    <div>
      <Chart
        chartType="Timeline"
        loader={<div>Loading Chart</div>}
        data={[
          [
            { type: "string", id: "Event" },
            { type: "string", id: "Dummylabel" },
            { type: "string", role: "style" },
            { type: "string", role: "tooltip", p: { html: true } },
            { type: "date", id: "Start" },
            { type: "date", id: "End" },
          ],
          ...data,
        ]}
        options={{
          showRowNumber: true,
          isHtml: true,
          hAxis: {
            minValue: firstDate,
            maxValue: lastDate,
            format:
              timespan === "days" || timespan === "hours" ? "HH:mm" : "d. MMM",
          },
          colors: ["gray", "#D97C54"],
        }}
      />
    </div>
  );
};

export default DoorReport;
