import { Box, Button, TextField } from "@mui/material";
import { makeStyles } from '@mui/styles';
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Site } from "../../entity/site";
import Loading from "../../react-components/Loading";
import { useCurrentSite } from "../../react-components/useCurrentSite";
import { EntityTypesContext } from "../EntityTypesReactWrapper";
import { useEntityTypes } from "../services/entityTypesService";
import EntityTypeCard from "./EntityTypeCard";


const useStyles = makeStyles({
  root: {
    padding: "1em",
  },
  actions: {
    display: "flex",
    marginBottom: "2em",
    "& > *": {
      marginRight: "1em",
    },
  },
  searchBar: {
    marginBottom: "2em",
  },
});


type Props = {

};

const EntityTypesList: React.FC<Props> = () => {
  const { siteService, userSiteService } = React.useContext(EntityTypesContext);
  const { site } = useCurrentSite(userSiteService, siteService);

  const { t } = useTranslation();
  const classes = useStyles();

  const [{ data: fetchedEntityTypes, loading, error }, refetch] = useEntityTypes();

  const [entityTypes, setEntityTypes] = React.useState([]);

  React.useEffect(() => {
    site && setEntityTypes(fetchedEntityTypes?.filter(x => x.tenantId === site.tenantId) ?? []);
  }, [fetchedEntityTypes, site]);

  const filterEntityTypes = (arr: Array<any>, query: string) => {
    return arr.filter(
      (el) => !query || el.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  };
  const handleSearch = (e: any) => {
    setEntityTypes(filterEntityTypes(fetchedEntityTypes, e.target.value));
  };

  return (
    <div className={classes.root}>
      <Box className={classes.actions} display="flex">
        <Button variant="contained" color="primary" href="/edit-entity-type">
          {t("entityTypes.addNew")}
        </Button>
      </Box>
      <Box className={classes.searchBar}>
        <TextField
          fullWidth
          label={t("entityTypes.search")}
          variant="outlined"
          onChange={handleSearch}
        />
      </Box>
      {loading ? (
        <Loading />
      ) : (
        entityTypes?.map(entityType => {
          return (
            <EntityTypeCard key={entityType.id} entityType={entityType} />
          );
        })
      )}
    </div>
  );

};

export default EntityTypesList;
