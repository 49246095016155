import { Badge } from "@mui/material";
import React from "react";

type Props = {
  badgeNumber?: number;
}

export const TabLabel: React.FC<Props> = ({ badgeNumber, children }) => {
  return badgeNumber ? (
    <Badge badgeContent={badgeNumber} color="error">
      {children}
    </Badge>
  ) : <>{children}</>;
}
