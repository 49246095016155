import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { HttpClientModule } from "@angular/common/http";
import { MaterialModule } from "../material.module";
import { FormsModule } from "@angular/forms";
import {
  ReactiveFormsModule,
} from "@angular/forms";
import { FlexLayoutModule } from "@angular/flex-layout";

import { MatListModule } from "@angular/material/list";
import { MatIconModule } from "@angular/material/icon";
import { MatNativeDateModule } from "@angular/material/core";
import { TempFormRegisterComponent } from "./temp-form-register/temp-form-register.component";
import { DataService } from "../reports/data.service";
import { EntityService } from "../entity/entity.service";
import { UserSiteService } from "../site/user-site.service";
import {
  DeviationProceduresComponent,
} from "./deviation-procedures/deviation-procedures.component";
import { DeviationMessageComponent } from "./deviation-message/deviation-message.component";
import { RouterModule } from "@angular/router";
import { MatDialogModule } from "@angular/material/dialog";
import { MatBadgeModule } from "@angular/material/badge";
import { ActionDialog } from "../temp-inspector/report-latest/action-dialog/action-dialog";
import { TempChartComponent } from "../reports-react/TempChartReactWrapper";
import { I18NextModule } from "angular-i18next";
import { CurrentTempComponent } from "../rooms-raect/CurrentTempReactWrapper";

@NgModule({
  declarations: [
    TempFormRegisterComponent,
    DeviationProceduresComponent,
    DeviationMessageComponent,
    ActionDialog,
    TempChartComponent,
    CurrentTempComponent
  ],
  imports: [
    HttpClientModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    MaterialModule,
    FlexLayoutModule,
    MatListModule,
    MatIconModule,
    MatNativeDateModule,
    MatDialogModule,
    MatBadgeModule,
    I18NextModule.forRoot(),
  ],
  entryComponents: [ActionDialog],
  providers: [DataService, EntityService, UserSiteService],
  exports: [ActionDialog],
})
export class TempInspectorModule { }
