<mat-card class="entity-item" [ngClass]="{'delivered': isDelivered}">
  <div>
    <form [formGroup]="entityForm" (ngSubmit)="onSubmit(entityForm)">
      <div class="entity-form">
        <div class="items horizontal entity-info">
          <div class="title">
            {{ entity.name }}
          </div>
          <div class="items horizontal">
            <mat-form-field class="temp-field">
              <input matInput placeholder="*Temp" formControlName="temp" [attr.disabled]="isDelivered ? 'disabled' : null"/>
            </mat-form-field>
            <div class="items min-max">
              <div>
                Min: <span class="temperature">{{ entity.obj.rules.temperature.min }}</span>
              </div>
              <div>
                Max: <span class="temperature">{{ entity.obj.rules.temperature.max }}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="quick-buttons">
          <button *ngIf="showQuickButtons && !isActionRequired" (click)="setTempValueOver()" class="over-button" type="button">
            {{ "Greater than" | i18next }} <span class="temperature">{{ entity.obj.rules.temperature.min }}</span>
          </button>
          <button *ngIf="showQuickButtons && !isActionRequired" (click)="setTempValueUnder()" class="under-button" type="button">
            {{ "Less than" | i18next }} <span class="temperature">{{ entity.obj.rules.temperature.min }}</span>
          </button>
        </div>

        <mat-form-field *ngIf="isActionRequired">
          <textarea
            rows="4"
            cols="80"
            placeholder="Skriv årsak for avvik eller velg et hurtig valg."
            matInput
            formControlName="action"
            [attr.disabled]="isDelivered ? 'disabled' : null"
          >
          </textarea>
        </mat-form-field>
      </div>

      <div class="center last-report-info" *ngIf="lastDelivered">
        {{ "Previous report" | i18next }}: <span class="temperature">{{lastDelivered.value}}</span>, {{lastDelivered.timestamp}}
      </div>

      <div class="error-message">{{ errorMessage }}</div>
      <div class="deviationReasons" *ngIf="isActionRequired">
        <button *ngFor="let deviationCause of commonDeviationCauses" mat-raised-button (click)="setAction(deviationCause.cause)">
          {{ deviationCause.label }}
        </button>
      </div>
      <div class="center" *ngIf="!isDelivered">
        <button mat-button class="send-button" type="submit">
          {{ "Save" | i18next }}
        </button>
      </div>

      <div class="center" *ngIf="isDelivered">
        {{ "Report registered" | i18next }}.
      </div>
    </form>
  </div>
  <mat-card-actions>

    </mat-card-actions>
</mat-card>
