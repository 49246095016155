import { Component, OnInit } from "@angular/core";
import { Site } from "../../entity/site";
import { Observable } from "rxjs";
import { SiteService } from "../../entity/site.service";
import { ServiceBusService } from "../../system/service-bus.service";
import { MsalService } from "@azure/msal-angular";
import { Chanels } from "../../chanels";
import { UserSiteService } from "../user-site.service";
import { MatDialogRef } from "@angular/material/dialog";
import { map } from "rxjs/internal/operators/map";
import { catchError } from "rxjs/operators";
import { isBitmeshUser } from "../../auth-utils";

@Component({
  selector: "app-site-select",
  templateUrl: "./site-select.component.html",
  styleUrls: ["./site-select.component.scss"],
})
export class SiteSelectComponent implements OnInit {
  public sites$: Observable<Array<Site>>;
  user$: Observable<any>;
  private user: any;
  private errorCount = 0;
  constructor(
    private siteService: SiteService,
    private msalService: MsalService,
    private userSiteService: UserSiteService,
    private bus: ServiceBusService,
    private dialogRef: MatDialogRef<SiteSelectComponent> // private appInsightService: AppInsightService
  ) { }

  ngOnInit() {
    this.user$ = this.bus.chanelObservable(Chanels.USER_CHANEL);
    this.user$.subscribe((user) => {
      this.user = user;
      var siteIds = user.adalUser.sites;
      var fullAccessTenantIds = user.adalUser.fullAccessTenantIds;
      this.sites$ = this.siteService.getSites().pipe(
        map((list: Site[]) => {
          return list.filter((x: Site) => {
            return (
              x.siteType == "Restaurant" &&
              (isBitmeshUser(user?.adalUser) || siteIds.indexOf(x.id.toString()) >= 0
                || fullAccessTenantIds.indexOf(x.tenantId.toString()) >= 0)
            );
          })
        })
      );
      this.sites$.pipe(
        catchError((err, caught) => {
          this.errorCount++;
          if (this.errorCount > 1) return caught;
          // this.appInsightService.logTrace(
          //   `Error while getting sites. ${JSON.stringify(err)}. profile: (${JSON.stringify(user.adalUser)})`
          //   ,null, AI.SeverityLevel.Error);
          return caught;
        })
      );
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  selectSite(siteId: string) {
    siteId = siteId.toUpperCase();
    this.userSiteService.setSiteId(siteId);
    this.bus.publish(Chanels.USER_CHANEL, {
      adalUser: this.user,
      siteId: siteId,
    });
    location.href = "/";
  }
}
