import * as React from "react";
import { createStyles, makeStyles } from "@mui/styles";
import PageHeader from "../../react-components/PageHeader";

import PhoneAccountsForm from "./PhoneAccountsForm";
import { useTranslation } from "react-i18next";
import { UserProfileContext } from "../UserProfileReactWrapper";
import { take } from "rxjs";
import { Site } from "../../entity/site";
import LanguageSelector from "../../change-language-react/components/LanguageSelector";

const styles = () =>
  createStyles({
    root: {
      padding: "1rem",
    },
  });

const useStyles = makeStyles(styles);

const UserProfilePage = () => {
  const { user, siteService } = React.useContext(UserProfileContext);
  const classes = useStyles();
  const { t } = useTranslation();

  const [site, setSite] = React.useState<Site>();

  React.useEffect(() => {
    siteService
      .getSite(user.siteId)
      .pipe(take(1))
      .subscribe((res: Site) => {
        setSite(res);
      });
  }, []);

  return (
    <div className={classes.root}>
      <PageHeader title={t("userProfile.title")} />
      <LanguageSelector />
      <PhoneAccountsForm />
    </div>
  );
};

export default UserProfilePage;
