import { Typography } from "@mui/material";
import React from "react";
import { useChecklistContext } from "../../ChecklistReactWrapper";
import {
  mapAllChecklists,
  useAssignChecklist,
  useSiteChecklists,
  useTagChecklist,
} from "../../services/checklistService";
import AddChecklistForm from "./AddChecklistForm";
import AssignChecklistForm from "./AssignChecklistForm";
import EditChecklistInfo from "./EditChecklistInfo";
import { useTranslation } from "react-i18next";

const EditChecklistList = () => {
  const { t } = useTranslation();
  const { user } = useChecklistContext();
  const [{ data, loading, error }, refetch] = useSiteChecklists(user.siteId);

  if (loading) {
    return <p>{t("Loading")}</p>;
  }
  if (error) {
    return <p>{t("Error")}</p>;
  }

  const checklists = mapAllChecklists(data);

  return (
    <div>
      <Typography variant="h2">{t("Edit checklists")}</Typography>
      <AddChecklistForm onSubmit={refetch} />
      <AssignChecklistForm
        label={`${t("Assign Checklist to Site")}`}
        onSubmit={async (e) => {
          await useAssignChecklist({
            checklistId: e.checklistId,
            tenantId: e.tenantId,
            siteId: e.siteId,
          });
          await useTagChecklist({
            checklistId: e.checklistId,
            tenantId: e.tenantId,
            tag: e.siteName,
          });
          refetch();
        }}
      />
      {checklists.map((checklist, key) => {
        return <EditChecklistInfo checklist={checklist} key={key} />;
      })}
    </div>
  );
};

export default EditChecklistList;
