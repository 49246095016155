import { Theme, Button, Tabs, Tab } from "@mui/material";
import { makeStyles, createStyles } from '@mui/styles';
import * as React from "react";
import { useParams } from "react-router-dom";
import {
  mapChecklist,
  mapSessions,
  useGetAllPointEvents,
  useGetAssignedChecklistComposite,
  useGetSessions,
} from "../../services/checklistService";
import ChecklistOverviewCard from "./ChecklistOverviewCard";
import PageHeader from "../../../react-components/PageHeader";
import { useTranslation } from "react-i18next";
import { useChecklistDescribedRegistry } from "../deviations/useChecklistDescribedRegistry";
import { Deviation, useChecklistDeviations } from "../deviations/useChecklistDeviations";
import { DateTime } from "luxon";
import { useEffect, useMemo, useState } from "react";
import { useChecklistContext } from "../../ChecklistReactWrapper";
import { ArrowBackIos, ArrowForwardIos, PrintRounded } from "@mui/icons-material";
import { useReactToPrint } from "react-to-print";
import { Site } from "../../../entity/site";
import { take } from "rxjs/operators";
import html2pdf from 'html2pdf.js';

const useStyles = makeStyles(({ spacing }: Theme) =>
  createStyles({
    root: {},
    button: {
      marginBottom: 10,
    },
    dateFilter: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginBottom: "0.5em",
    },
    periodTitle: {
      minWidth: "12em",
      textAlign: "center",
    }
  })
);

const ChecklistOverviewPage = () => {
  let { assignedId }: any = useParams();

  const [contextUpdate, setContextUpdate] = useState(0);
  const [defaultExpanded, setDefaultExpanded] = useState(false);

  const classes = useStyles();
  const { t } = useTranslation(); //translation

  const [{ data: sessionData, loading: sessionLoading, error: sessionError }] =
    useGetSessions(assignedId);

  const { user, siteService } = useChecklistContext();

  const [site, setSite] = useState<Site>();
  useEffect(() => {
    siteService
      .getSite(user.siteId)
      .pipe(take(1))
      .subscribe((res: Site) => {
        setSite(res);
      });
  }, []);
  const [
    { data: checklistData, loading: checklistLoading, error: checklistError },
  ] = useGetAssignedChecklistComposite(assignedId);

  const checklistDescribedRegistryForAssignedId = useChecklistDescribedRegistry([assignedId], checklistData ?? []);

  const [periodType, setPeriodType] = useState<"week" | "month">("week");
  const query = new URLSearchParams(window.location.search);
  const cameFromCalendar = query.get("from") && query.get("to");
  const { from, to } = useMemo(() => {
    if (query.get("from") && query.get("to")) {
      const from = DateTime.fromISO(query.get("from")).startOf("day");
      const to = DateTime.fromISO(query.get("to")).endOf("day");
      return { from, to };
    }
    return { from: null, to: null };
  }, [query, contextUpdate]);

  // one year before
  const [refDateStart] = useState(DateTime.now().minus({ years: 10 }));

  const { allDeviations, deviationLoading } = useChecklistDeviations({
    assignedIds: [assignedId],
    checklistDescribedRegistryForAssignedId,
    refDateStart,
  });

  const deviations = React.useMemo(() => {
    const result: Deviation[] = [];
    allDeviations.forEach((deviation) => {
      Object.values(deviation.deviations).forEach((devs: Deviation[]) => {
        result.push(...devs);
      });
    });
    return result;
  }, [allDeviations]);

  const sessionsWithDeviationsSet = new Set(deviations.map((d) => d.sessionId));
  const sessionsUnfiltered = mapSessions(
    sessionData?.filter((item) => item.type != "ScheduleDue" || sessionsWithDeviationsSet.has(item.sessionId)) ?? [],
    [...sessionsWithDeviationsSet],
  )

  const sessions = sessionsUnfiltered.filter(session => {
    if (from && to) {
      const timestamp = DateTime.fromISO(session.timestamp);
      return timestamp >= from && timestamp <= to;
    }
    return true;
  });

  const [{ data: points, loading: pointsLoading, error: pointsError }] =
    useGetAllPointEvents(user.siteId);

  const pointNameRegistry = {};
  points?.forEach((point) => {
    if (point.name) {
      const sitePoint = points.filter(p => p.pointId === point.pointId).reverse().find(p => p.type === "SitePointDescribed");
      pointNameRegistry[point.pointId] = sitePoint?.name || point.name;
    }
  });

  const componentRef = React.useRef(null);

  const printFn = useReactToPrint({
    contentRef: componentRef,
    documentTitle: `Checklist report for ${checklistData?.siteName || checklistData?.name}`,
  });

  const handleDownloadPdf = (name: string) => {
    const element = componentRef.current;
    const options = {
      filename: `${name.split(" ").map(x => x.toLowerCase()).join("_")}_rapport.pdf`,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2, useCORS: true, logging: true },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
    };

    // Wait until all images are loaded before generating the PDF
    const images = element.querySelectorAll('img');
    const promises = Array.from(images).map((img: any) => {
      return new Promise((resolve, reject) => {
        if (img.complete) {
          resolve(1);
        } else {
          img.onload = resolve;
          img.onerror = reject;
        }
      });
    });

    Promise.all(promises)
      .then(() => {
        html2pdf().from(element).set(options).save();
      })
      .catch((error) => {
        console.error('Error loading images', error);
      });
  };

  if (deviationLoading) {
    return <p>{t("Loading")}</p>;
  }

  if (sessionLoading || checklistLoading) {
    return <p>{t("Loading")}</p>;
  }
  if (sessionError || checklistError) {
    return <p>{t("Error")}</p>;
  }

  const checklist = mapChecklist(checklistData, assignedId);

  const handlePeriodChange = (multiplier: -1 | 1) => {
    const newFrom = from.plus({ [periodType]: multiplier });
    const newTo = to.plus({ [periodType]: multiplier });

    handleSetDates(newFrom, newTo);
  }

  const handleSetDates = (from: DateTime, to: DateTime) => {
    // set from & to in query without page reload
    const url = new URL(window.location.href);
    url.searchParams.set("from", from.toISODate());
    url.searchParams.set("to", to.toISODate());
    history.pushState(null, "", url.toString());
    setContextUpdate(c => c + 1);
  }

  return (
    <>
      <PageHeader title={`Rapport for ${checklist.siteName || checklist.name}`} href={cameFromCalendar ? "/checklist/dashboard/calendar" : "/checklist/dashboard/overview"} />
      <div className={classes.dateFilter}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column"
          }}
        >
          <Button
            onClick={() => {
              setDefaultExpanded(true);
              setTimeout(() => {
                handleDownloadPdf(checklist.siteName || checklist.name || "");
                setTimeout(() => {
                  setDefaultExpanded(false);
                }, 100);
              }, 700);
            }}
            disabled={defaultExpanded}
          >
            <PrintRounded style={{ marginRight: "5px" }} />
            Print
          </Button>

          {from && to && (
            <>
              <div>
                <Tabs value={periodType} onChange={((event, newValue) => {
                  handleSetDates(to.startOf(newValue), to.endOf(newValue));
                  setPeriodType(newValue);
                })}>
                  <Tab label={t('calendar.monthTab')} value="month" />
                  <Tab label={t('calendar.weekTab')} value="week" />
                </Tabs>
              </div>

              <div
                className={classes.dateFilter}
              >
                <Button
                  onClick={() => {
                    handlePeriodChange(-1);
                  }}
                >
                  <ArrowBackIos />
                </Button>

                <div className={classes.periodTitle}>
                  {from.toFormat("dd.MM.yyyy.")} - {to.toFormat("dd.MM.yyyy.")}
                </div>

                <Button
                  onClick={() => {
                    handlePeriodChange(1);
                  }}
                >
                  <ArrowForwardIos />
                </Button>
              </div>
            </>
          )}
        </div>
      </div>
      <div ref={componentRef}>
        <h1 style={{
          width: "100%",
          display: defaultExpanded ? "flex" : "none",
          justifyContent: "center",
        }}
        >
          {site.name}
        </h1>
        <h2
          style={{
            width: "100%",
            display: defaultExpanded ? "flex" : "none",
            justifyContent: "center",
          }}
        >
          {`Rapport for ${checklist.siteName || checklist.name}`}
        </h2>

        {sessions.map((item, key) => (
          <div
            style={{
              pageBreakInside: "avoid",
              pageBreakBefore: "auto",
              pageBreakAfter: "auto",
            }}
          >
            <ChecklistOverviewCard
              key={key}
              checklist={checklist}
              sessionData={item}
              sessionDeviations={deviations.filter((d) => d.sessionId === item.id)}
              pointNameRegistry={pointNameRegistry}
              defaultExpanded={defaultExpanded}
            />
            {/* <div style={{ pageBreakAfter: "always" }}></div> */} {/* page break TODO figure out */}
          </div>
        ))}
        {sessions.length == 0 && <p>{t("checklist.noSessions")}</p>}
      </div>
    </>
  );
};

export default ChecklistOverviewPage;
