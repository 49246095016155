import { Component, OnInit, Input } from '@angular/core';
import { Measurement } from '../../measurements/measurement';
import { Entity } from '../../entity/entity';
import { TempReport } from '../../reports/tempreport';
import { SiteEntities } from '../../entity/siteentities';
import { ChartPeriod } from '../../entity/chartperiod';
import * as moment from 'moment';

@Component({
  selector: 'app-deviationexpansion',
  templateUrl: './deviationexpansion.component.html',
  styleUrls: ['./deviationexpansion.component.scss']
})
export class DeviationexpansionComponent implements OnInit {
  
  @Input() reports: Array<TempReport>;

  @Input() siteEntities:SiteEntities;

  private _period: ChartPeriod;
 
  deviationModel: Array<any>;
  panelOpenState = false;
  constructor() { }

  ngOnInit() {
    // this.buildDeviationModel();
  }

  buildDeviationModel(){
    if(!this.reports) return;
    var deviationMap = this.reports.reduce((acc:any,curr:TempReport)=>{
      var deviations = curr.getDeviations().filter(d => moment(d.occuredTime) >= this._period.startDate && moment(d.occuredTime) < this._period.endDate);
      if(deviations.length > 0){
        deviations.forEach(d=>{
          if(!acc[d.entityId]){
            var entity = this.siteEntities.getEntity(d.entityId);
            acc[d.entityId] = {entity:entity, deviations:[]}
          }
          acc[d.entityId].deviations.push({
            deviation: d,
            measurement: curr.getMeasurement(d.entityId)
          });
        });
      }
      return acc;
    },{});
    this.deviationModel = Object.keys(deviationMap).map(key=>deviationMap[key]);
    console.log(this.deviationModel)
  }

  @Input()
  set period(period: ChartPeriod) {
    this._period = period;
    this.buildDeviationModel();
  }

  public getRulesFromObj(obj: any) {
    const rules = obj?.rules;
    if(!rules) return {};
    const key = ['temperature', 'door'].find(key => !!rules[key]);
    return rules[key] ?? {};
  }
}
