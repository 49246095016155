export const getBoundaries = (rules) => {
  const min = getMin(rules);
  const max = getMax(rules);
  const inverted = isInverted(rules);
  return { min, max, isInverted: inverted };
}

export const getRulePercentages = (rules) => {
  const min = getMin(rules);
  const max = getMax(rules);
  const interval = max - min;
  const ruleInterval = (ruleKey) => 
    (rules[ruleKey].max > max ? max : rules[ruleKey].max) - (rules[ruleKey].min < min ? min : rules[ruleKey].min);
  return {
    greenPercentage: ruleInterval('green') / interval * 100,
    yellowPercentage: ruleInterval('yellow') / interval * 100,
    redPercentage: ruleInterval('red') / interval * 100,
  }
}

export const getBoundaryMarks = (rules) => {
  const { min, max } = getBoundaries(rules);
  return [...new Set([
    rules.green.min,
    rules.green.max,
    rules.yellow.min,
    rules.yellow.max,
    rules.red.min,
    rules.red.max,
  ].map(v => v === Infinity ? max : (v === -Infinity ? min : v)))];
}


const getMin = (rules) => {
  const smallerRuleKey = rules.green.min < rules.red.min ? 'green' : 'red';
  const min = rules[smallerRuleKey].min;
  if(min == '-Infinity') {
    return rules[smallerRuleKey].max - 10;
  }
  return min;
}

const getMax = (rules) => {
  const greaterRuleKey = rules.green.max > rules.red.max ? 'green' : 'red';
  const max = rules[greaterRuleKey].max;
  if(max == 'Infinity') {
    return rules[greaterRuleKey].min + 10;
  }
  return max;
}

const isInverted = (rules) => {
  return rules.green.max > rules.red.max || rules.red.min < rules.green.min;
}