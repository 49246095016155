import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import * as moment from "moment";
import { Router } from "@angular/router";

@Component({
  selector: "temp-widget",
  templateUrl: "./temp-widget.component.html",
  styleUrls: ["./temp-widget.component.scss"]
})
export class TempWidgetComponent implements OnInit {
  
  @Input()
  showMargin: boolean = true;

  @Input()
  tooltipText: string = "";

  @Input()
  showTitle: boolean = true;

  @Input()
  isWeeklyReport: boolean = false;

  @Input()
  isPeriodicReport: boolean = false;

  @Input()
  deviationsCount: number = 0;

  @Input()
  handledDeviationsCount: number = 0;

  @Input()
  timestamp = moment();

  @Output()
  onHandleDeviation = new EventEmitter();

  @Input()
  isLoading = false;
  
  constructor(
    private router: Router
  ) {}

  ngOnInit() {
  }

  clickedHandleDeviations() {
    this.onHandleDeviation.emit();
  }
}