import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import no from "../translation/no";
import en from "../translation/en";
import LanguageDetector from "i18next-browser-languagedetector";

// the translations
export const resources = {
  en: {
    translation: en,
  },
  no: {
    translation: no,
  },
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: "no",
    // keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
