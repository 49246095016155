import * as signalR from "@microsoft/signalr";
import { useEffect } from "react";

interface Options {
  url: string;
  skipNegotiation?: boolean;
  automaticReconnect?: boolean;
}

interface Handler {
  method: string;
  callback: (data: string) => void;
}

export class SignalREventHandler {
  private connectionBuilder: signalR.HubConnectionBuilder;
  private connection: any;
  private handlers: Handler[] = [];

  constructor({ url, skipNegotiation = true }: Options) {
    this.connectionBuilder = new signalR.HubConnectionBuilder()
      .withUrl(url, {
        skipNegotiation,
        transport: signalR.HttpTransportType.WebSockets,
      })
      .configureLogging(signalR.LogLevel.Information);

    this.connect = this.connect.bind(this);
  }

  public isConnected(): boolean {
    return this.connection?.connectionStarted;
  }

  public close(): void {
    console.log("Closing signalR connection.");
    this.connection.stop();
  }

  private async connect() {
    this.connection = this.connectionBuilder.build();
    try {
      if (!this.isConnected()) {
        await this.connection.start();
        this.handlers.forEach((h) => {
          this.connection.on(h.method, h.callback);
        });
        console.log(`SignalR connection success.`);
      }
    } catch (err) {
      const timeout = 5000;
      console.log(err);
      console.log(`Failed to connect. Retrying in ${timeout / 1000} seconds.`);
      setTimeout(this.connect, timeout);
    }

    this.connection.onclose(async () => {
      await this.connect();
    });
  }

  public on(method: string, callback: (data: any) => void = () => {}) {
    const handler = (data: string) => callback(JSON.parse(data));
    this.handlers.push({ method, callback: handler });
    this.isConnected() && this.connection.on(method, handler);
  }

  public async subscribeTo(groupName: string) {
    !this.isConnected() && (await this.connect());
    this.connection.invoke("JoinGroup", `${groupName}`);
  }

  public async unsubscribeFrom(groupName: string) {
    !this.isConnected() && (await this.connect());
    this.connection.invoke("LeaveGroup", `${groupName}`);
  }
}

export function useSignalR(
  callback: (data: any | undefined) => void,
  options: { url: string; method: string; topic: string },
  dependencyList: any[] = []
) {
  useEffect(() => {
    const signalR = new SignalREventHandler({ url: options.url });
    signalR.on(options.method, (data) => {
      console.log("Received event ", data);
      callback(data);
    });
    signalR.subscribeTo(options.topic);
    return () => {
      signalR.close();
    };
  }, dependencyList);
}
