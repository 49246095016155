import { makeStyles } from "@mui/styles";
import * as React from "react";
import ReactMarkdown from "react-markdown";

import { Root, Element, ElementContent } from "hast";
import gfm from "remark-gfm";
import slug from "rehype-slug";
import headings from "rehype-autolink-headings";
import rehypeRaw from "rehype-raw";
import rehypeAttrs from "rehype-attr";
import rehypePrism from "@mapbox/rehype-prism";
import rehypeRewrite from "rehype-rewrite";

const useStyles = makeStyles({
  root: {},
});

type Props = {
  children: any;
};

const octiconLink: Element = {
  type: "element",
  tagName: "svg",
  properties: {
    class: "octicon octicon-link",
    viewBox: "0 0 16 16",
    version: "1.1",
    width: "16",
    height: "16",
    ariaHidden: "true",
  },
  children: [
    {
      type: "element",
      tagName: "path",
      children: [],
      properties: {
        fillRule: "evenodd",
        d: "M7.775 3.275a.75.75 0 001.06 1.06l1.25-1.25a2 2 0 112.83 2.83l-2.5 2.5a2 2 0 01-2.83 0 .75.75 0 00-1.06 1.06 3.5 3.5 0 004.95 0l2.5-2.5a3.5 3.5 0 00-4.95-4.95l-1.25 1.25zm-4.69 9.64a2 2 0 010-2.83l2.5-2.5a2 2 0 012.83 0 .75.75 0 001.06-1.06 3.5 3.5 0 00-4.95 0l-2.5 2.5a3.5 3.5 0 004.95 4.95l1.25-1.25a.75.75 0 00-1.06-1.06l-1.25 1.25a2 2 0 01-2.83 0z",
      },
    },
  ],
};

const rehypeRewriteHandle = (
  node: ElementContent,
  parent: Root | Element | null
) => {
  if (
    node.type === "element" &&
    parent &&
    parent.type === "root" &&
    /h(1|2|3|4|5|6)/.test(node.tagName)
  ) {
    const child = node.children && (node.children[0] as Element);
    if (child && child.properties && child.properties.ariaHidden === "true") {
      child.properties = { class: "anchor", ...child.properties };
      child.children = [octiconLink];
    }
  }
};

const MarkdownDisplay: React.FC<Props> = ({ children }) => {
  return (
    <ReactMarkdown
      linkTarget="_blank"
      children={children}
      remarkPlugins={[gfm]}
      rehypePlugins={[
        [rehypePrism, { ignoreMissing: true }],
        rehypeRaw,
        slug,
        headings,
        [rehypeRewrite, { rewrite: rehypeRewriteHandle }],
        [rehypeAttrs, { properties: "attr" }],
        ...[],
      ]}
    />
  );
};

export default MarkdownDisplay;
