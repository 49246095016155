<div class="_content">
  <div class="content__heading">
    <a class="content__heading__go-back" [routerLink]="['/']">
      <i class="material-icons">home</i>
    </a>
    <h1>Excel {{ "documents" | i18next }}</h1>
  </div>

  <div class="item" *ngIf="!isPeriodsLoaded">
    <mat-spinner></mat-spinner>
  </div>
  <div *ngIf="isPeriodsLoaded">
    <div>
      <a class="report-link" (click)="getExcelReport()">
        {{ "Listing of reports" | i18next }}
      </a>
    </div>

    <div>
      <a class="report-link" (click)="getExcelReport(true)">
        {{ "Listing of deviation reports" | i18next }}
      </a>
    </div>
    
    <h3>{{ "Temperatures per unit" | i18next }}</h3>
    <div *ngIf="periods.length == 0">
      {{ "Found no devices to report for" | i18next }}.
    </div>
    <div *ngIf="periods.length > 0">
      <ul>
        <ng-template ngFor let-period [ngForOf]="periods">
          <li class="period-item">
            <a class="report-link" (click)="getEntitiesExcelReport(period.month() + 1, period.year())">
              {{ period.format("MMMM YYYY") }}
            </a>
          </li>
        </ng-template>
      </ul>
    </div>

    <ng-template [ngIf]="isCleanEnabled">
      <h3>{{ "Doors" | i18next }}</h3>
      <div *ngIf="cleanPeriods.length > 0">
        <ul>
          <ng-template ngFor let-period [ngForOf]="cleanPeriods">
            <li class="period-item">
              <a class="report-link" (click)="getCleanExcelReport(period.month() + 1, period.year())">
                {{ period.format("MMMM YYYY") }}
              </a>
            </li>
          </ng-template>
        </ul>
      </div>
    </ng-template>
    <h3></h3>
  </div>
</div>
