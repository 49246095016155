import {
  Button,
  CardActions,
  CardContent,
  CardHeader,
  Theme,
} from "@mui/material";
import { Card, Typography } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import { DateTime } from "luxon";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import MediaRender from "../../react-components/MediaRender";
import { useSignatureContext } from "../../signature-react/SignatureContext";
import { useChecklistContext } from "../ChecklistReactWrapper";
import { actions, useSingleChecklistContext } from "../services/reducer";
import PreviewDialog from "./PreviewDialog";
import { startRoutineImmediately } from "../services/checklistService";


type Props = {
  checklist: any;
  inProgress?: boolean;
  timeLabel?: string;
  timeField?: string;
};

const useStyles = makeStyles(({ spacing, palette }: Theme) =>
  createStyles({
    root: {
      textDecoration: "none",
    },
    checklistCard: {},
    header: {
      paddingTop: "2px !important",
      paddingBottom: "2px !important",
    },
    card: {
      paddingTop: "2px !important",
      paddingBottom: "2px !important",
      minHeight: "124px",
      display: 'flex',
      justifyContent: 'space-between'
    },

    thumbnailContainer: {
      display: 'flex',
      alignItems: "center"
    },
    thumbnail: {
      objectFit: "contain",
      maxWidht: "240px",
      maxHeight: "120px"
    }
  })
);

const AllwaysChecklistCard = ({
  checklist,
  timeLabel,
  timeField,
}: Props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation();
  timeLabel = timeLabel ?? t("checklist.checklistCard.deadline");
  timeField = timeField ?? "dueTime";
  const { user, tenantId } = useChecklistContext();
  const { state, dispatch } = useSingleChecklistContext();
  const {
    state: { activeSignature },
  } = useSignatureContext();

  const signatureId = activeSignature?.id?.toString();

  const handleChecklistClick = async () => {
    const result = await startRoutineImmediately(checklist);
    var cmd = result.manualScheduleCommand;
    dispatch({
      type: actions.START,
      payload: {
        checklistId: checklist.checklistId,
        tenantId,
        siteId: user.siteId,
        assignedId: checklist.assignedId,
        userId: user.adalUser.oid,
        username: user.adalUser.preferred_username,
        sessionId: cmd.sessionId,
        signatureId: signatureId,
      },
    });
    var sessionUrl = `/checklist/${checklist.assignedId}?sessionId=${cmd.sessionId}&backTo=/checklist/dashboard/high`;
    navigate(sessionUrl);
  };

  return (
    <Card key={checklist.assignedId} className={classes.checklistCard}>
      <CardHeader
        className={classes.header}
        title={checklist.name ?? "Uten navn"}
      />
      <CardContent className={classes.card}>
        <div className={classes.thumbnailContainer}>
          {checklist.image && <MediaRender src={checklist.image} className={classes.thumbnail} />}
        </div>
      </CardContent>
      <CardActions>
        <Button
          sx={{ marginRight: '0.4em' }}
          color="primary"
          variant="contained"
          onClick={handleChecklistClick}
        >
           {t("Start")}
        </Button>
        <PreviewDialog checklist={checklist} />
      </CardActions>
    </Card>
  );
};

export default AllwaysChecklistCard;
