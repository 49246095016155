import { Autocomplete, Box, Button, TextField } from "@mui/material"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next";

type Props = {
    labelType: string;
    labelOptions: string[];
    currentValue?: string;
    onSubmit: (value: string) => void;
    disabled: boolean;
}

export const PointLabel = ({labelType, labelOptions, currentValue, onSubmit, disabled}: Props) => {
    const {t} = useTranslation();
    
    const defaultValueisNullOrEmpty =  currentValue === null || currentValue === undefined || currentValue.trim() === '';

    const [labelValue, setLabelValue] = useState<string>(currentValue ?? "");
    const [savedValue, setSavedValue] = useState<string>(currentValue ?? ""); 
    const [isEdit, setIsEdit] = useState<boolean>((defaultValueisNullOrEmpty));


    const handleSubmit = (value: string) => {
      setSavedValue(value);
      onSubmit(value);
      setIsEdit(false)
    }
    
    useEffect(() => {
      if (!isEdit) {
        if (labelValue !== savedValue) {
          setIsEdit(true);
        }
      }
    },[labelValue])

    useEffect(() => {
      if (!defaultValueisNullOrEmpty) {
        setLabelValue(currentValue);
        setSavedValue(currentValue);
        setIsEdit(false);
      }
    },[currentValue])
    
    return (
        <>
          <Autocomplete
            disabled={disabled}
            freeSolo
            options={labelOptions ?? []}
            value={disabled ? "" : labelValue}
            renderInput={(params) => (
              <TextField {...params} label={labelType} variant="standard" />
            )}
            onInputChange={(_, value) => {
              setLabelValue(value);
            }}
            onChange={(_, value) => {
              setLabelValue(value);
              if (value === null || value === undefined) {
                
              }
              else {
                handleSubmit(value)
              } 
              }}
            onKeyDown={(event) => {
                if (event.key === 'Enter' || event.key === 'Done' || event.key === 'Return') {
                    if (labelValue === null || undefined) {
                      handleSubmit("")
                    }
                    else {
                      handleSubmit(labelValue)
                    } 
                }
              }}
          />
          {isEdit ? (
            <Box mt={1} mb={2}>
            <Button disabled={disabled} variant="contained" onClick={() => {
                if (labelValue === null || undefined) {
                  handleSubmit("")
                }
                handleSubmit(labelValue)
            }}>{t("Save")}</Button>
            </Box>
          ): disabled ? (
            null
          ) : <Button disabled>{t("Saved")}</Button>}
          
        </>
      );
}