import { Entity } from "./entity";

export class SiteEntities {
    getEntities(): Array<Entity> {
        return this.siteEntities;
    }

    constructor(private siteEntities: Array<Entity>) { }

    public static create(o: any) {
        var entities = o?.siteEntities?.map(x => Entity.create(x)) ?? [];
        return new SiteEntities(entities);
    }
    public getEntityName(entityId): string {
        // if(!this.siteEntities) return "Fant ikke navn";
        var entities = this.siteEntities.filter(x => x.id == entityId);
        return entities.length > 0 ? entities[0].getName() : "Not Found";
    }
    public getEntity(entityId): Entity {
        if (!this.siteEntities) return null;
        var entities = this.siteEntities.filter(x => x.id == entityId);
        return entities.length > 0 ? entities[0] : null;

    }
}