import * as React from "react";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Theme
} from "@mui/material";
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "80%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

type Props = {
  entity: any;
};

const StatList: React.FC<Props> = ({ entity }) => {
  const classes = useStyles();
  const [state, setState] = React.useState({
    id: "",
    name: "",
    openCloseInfo: {
      limit: 0,
      countSinceLastCleaning: 0,
      occuredTime: undefined,
      lastSignalDate: "",
      lastState: "",
    },
  });

  React.useEffect(() => {
    setState({ ...state, ...entity });
  }, [entity, setState]);

  return (
    <List dense className={classes.root}>
      <ListItem>
        <ListItemIcon>
          <i className="material-icons">person</i>
        </ListItemIcon>
        <ListItemText>{`${state.openCloseInfo.countSinceLastCleaning} / ${state.openCloseInfo.limit}`}</ListItemText>
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <i className="material-icons">sensor_door</i>
        </ListItemIcon>
        <ListItemText>{state.openCloseInfo.lastState}</ListItemText>
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <i className="material-icons">wc</i>
        </ListItemIcon>
        <ListItemText>{state.openCloseInfo.lastSignalDate}</ListItemText>
      </ListItem>
      <ListItem>
        <ListItemIcon>
          <i className="material-icons">cleaning_services</i>
        </ListItemIcon>
        <ListItemText>
          {entity.lastClean ? entity.lastClean : "Aldri"}
        </ListItemText>
      </ListItem>
    </List>
  );
};

export default StatList;
