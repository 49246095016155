import * as React from "react";
import { makeStyles, createStyles } from '@mui/styles';
import { useTranslation } from "react-i18next";
import * as cron from "cron";
import { DateTime } from "luxon";
import { Paper, Theme } from "@mui/material";

const useStyles = makeStyles(({ spacing, palette }: Theme) =>
  createStyles({
    schedule: {
      backgroundColor: palette.grey[300],
      padding: spacing(2),
      marginBottom: spacing(5),
      marginLeft: spacing(2),
      marginRight: spacing(2),
      width: "100%",
    },
  })
);

type props = {
  schedule: any;
};

const ShowSchedule = ({ schedule }: props) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation(); //translation
  let show = schedule.todoFrom != null;

  //TODO: add code for checking for current language. i18n.language. and use to translate schedule

  let nextRuns = [];
  try {
    const CronJob = cron.CronJob;
    const job = new CronJob(
      schedule.todoFrom.slice(0, -2).replace("?", "*"),
      function () {
        console.log("I am a cron job");
      }
    );
    nextRuns = job.nextDates(3); //number of future runs of cron
  }
  catch {
    show = false;
  }
  

  return (
    <>
      {show && (
        <Paper className={classes.schedule}>
          {/* <p>
            {`${t("checklist.schedule.todoFrom")}`}
            {cronstrue_i18n.toString(schedule.todoFrom, {
              use24HourTimeFormat: true,
              verbose: true,
              locale: "nb",
            })}
          </p>
          <p>
            {`${t("checklist.schedule.dueFrom")}`}
            {cronstrue_i18n.toString(schedule.dueFrom, {
              use24HourTimeFormat: true,
              verbose: true,
              locale: "nb",
            })}
          </p> */}
          <p>
            {`${t("checklist.schedule.nextRuns")}`}
            {nextRuns.map((item, key) => {
              return `${DateTime.fromJSDate(item._d).toLocaleString(
                DateTime.DATETIME_SHORT
              )}, `;
            })}
          </p>
        </Paper>
      )}
    </>
  );
};

export default ShowSchedule;
