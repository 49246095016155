import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChecklistReactComponent } from './ChecklistReactWrapper';



@NgModule({
  declarations: [
    ChecklistReactComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ChecklistReactComponent
  ]
})
export class ChecklistReactModule { }
