import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Theme,
} from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import { Form, Formik } from "formik";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Site } from "../../../entity/site";
import FormikField from "../../../react-components/FormikField";
import { Guid } from "../../../system/guid";
import { useChecklistContext } from "../../ChecklistReactWrapper";
import {
  useAssignChecklist,
  useCreateChecklist,
  useDescribeChecklist,
  useTagChecklist,
} from "../../services/checklistService";
import DialogTitle from "../../../react-components/DialogTitle";

const useStyles = makeStyles(({ spacing, palette }: Theme) =>
  createStyles({
    root: {
      background: palette.grey[200],
      padding: spacing(2),
    },
    title: {
      marginBottom: spacing(2),
    },
    textField: {
      "& > div": {
        background: palette.common.white,
      },
    },
  })
);

type Props = {
  onSubmit: () => void;
};

const AddChecklistForm: FC<Props> = ({ onSubmit }) => {
  const classes = useStyles();
  const { user, siteService } = useChecklistContext();
  const { t } = useTranslation();

  const [tenantId, setTenantId] = useState<string>("");
  const [site, setSite] = useState<Site>(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const subscribtion = siteService
      .getSite(user.siteId)
      .subscribe((site: Site) => {
        setTenantId(site.tenantId.toString());
        setSite(site);
      });
    return () => subscribtion.unsubscribe();
  }, []);

  const toggleOpen = () => {
    setOpen(!open);
  };

  const handleSubmit = async (values, { resetForm }) => {
    const checklistId = Guid.newGuid().toString();
    await Promise.all([
      useCreateChecklist({
        checklistId,
        tenantId,
      }),
      useDescribeChecklist({
        checklistId,
        name: values.name,
        description: values.description,
        tenantId,
        imagePath: "",
      }),
      useAssignChecklist({
        checklistId,
        siteId: user.siteId,
        tenantId,
      }),
      useTagChecklist({
        checklistId,
        tenantId,
        tag: site?.name,
      })
    ]);

    onSubmit();

    toggleOpen();
    resetForm();
  };

  return (
    <>
      <Button variant="contained" color="primary" onClick={toggleOpen}>
        {t("editChecklist.add_new_checklist")}
      </Button>
      <Dialog open={open} onClose={toggleOpen} maxWidth="sm" fullWidth>
        <Formik
          initialValues={{ name: "", description: "" }}
          onSubmit={handleSubmit}
        >
          {() => (
            <Form>
              <DialogTitle onClose={toggleOpen}>{`${t(
                "editChecklist.add_new_checklist"
              )}`}</DialogTitle>
              <DialogContent>
                <FormikField label={`${t("editChecklist.name")}`} name="name" />
                <FormikField
                  label={`${t("editChecklist.description")}`}
                  name="description"
                />
              </DialogContent>
              <DialogActions>
                <Button type="submit" variant="contained" color="primary">
                  {`${t("editChecklist.submit")}`}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};

export default AddChecklistForm;
