<div class="_content">
  <div class="register-device">
    <span class="go-back">
      <a class="content__heading__go-back" routerLink="/" routerLinkActive="active">
        <i class="material-icons">
          home
        </i>
      </a>
    </span>
  
    <h1 class="register-device__title">
      Registrer et Målepunkt
    </h1>
  
    <form action="" class="register-device__form">
      <mat-form-field class="device">
        <input
          matNativeControl
          type="text"
          name="device"
          placeholder="Målepunkt"
        />
      </mat-form-field>
      <div class="temperature-control">
        <mat-form-field>
          <input
            matNativeControl
            type="text"
            name="max"
            placeholder="Max &#8451;"
            class="temperature"
          />
        </mat-form-field>
      </div>
  
      <div class="temperature-control">
        <mat-form-field>
          <input
            matNativeControl
            type="text"
            name="min"
            placeholder="Min &#8451;"
            class="temperature"
          />
        </mat-form-field>
      </div>
  
      <div class="center">
        <button
          mat-button
          class="send-button"
          routerLink="/device-list" routerLinkActive="active"      
        >
          Neste
        </button>
      </div>
    </form>
  </div>
</div>
