import {
  AfterViewInit,
  Component,
  ElementRef,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { ThemeProvider } from "@mui/material";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { Subscription } from "rxjs";
import { Chanels } from "../chanels";
import { EntityService } from "../entity/entity.service";
import { theme } from "../react-theme";
import { Guid } from "../system/guid";
import { ServiceBusService } from "../system/service-bus.service";
import "../react-components/i18n";
import { SiteService } from "../entity/site.service";
import { Dispatch, useReducer } from "react";
import { DateTime, Duration } from "luxon";

const containerElementName = "myReactComponentContainer";

export type Signature = {
  id: Guid;
  name: string;
  pin: string;
  userIds: Array<Guid>;
};
export type SignatureContextType = {
  activeSignature: Signature;
  timeout: number;
  signatures: Array<Signature>;
  iamUpdated: boolean;
  search: string;
  userIds: string;
  tenantId: string;
};

export type SelectedSignatureStore = {
  activeSignature: String;
  timestamp: string;
};
const SIGNATURES = [
  {
    id: new Guid("40D866BF-B378-433F-AC7B-80808CB0E930"),
    userIds: [
      new Guid("72aa5334-c07f-48bd-972b-5c86fda12dd4"),
      new Guid("5015cf98-f5dc-4b5a-801c-8c8efa1f759f"),
    ],
    tenantId: new Guid("CB0501C1-503B-420A-B9C3-D4085796A743"),
    name: "Amalie Qvam",
    pin: "9649",
  },
  {
    id: new Guid("033FE116-45E7-447B-A69A-BABC52207B2E"),
    userIds: [
      new Guid("72aa5334-c07f-48bd-972b-5c86fda12dd4"),
      new Guid("5015cf98-f5dc-4b5a-801c-8c8efa1f759f"),
    ],
    tenantId: new Guid("CB0501C1-503B-420A-B9C3-D4085796A743"),
    name: "Phillip André Aabel",
    pin: "3442",
  },
  {
    id: new Guid("49C75A2B-972E-4525-94D7-C5B172E5F3AF"),
    userIds: [
      new Guid("72aa5334-c07f-48bd-972b-5c86fda12dd4"),
      new Guid("5015cf98-f5dc-4b5a-801c-8c8efa1f759f"),
    ],
    tenantId: new Guid("CB0501C1-503B-420A-B9C3-D4085796A743"),
    name: "Pia Murstad",
    pin: "7856",
  },
  {
    id: new Guid("388F78A2-1DDD-446A-A61C-06DB9886A641"),
    userIds: [
      new Guid("72aa5334-c07f-48bd-972b-5c86fda12dd4"),
      new Guid("5015cf98-f5dc-4b5a-801c-8c8efa1f759f"),
    ],
    tenantId: new Guid("CB0501C1-503B-420A-B9C3-D4085796A743"),
    name: "Anine Lie",
    pin: "2059",
  },
  {
    id: new Guid("76E8129E-D11F-4998-A79A-09EB21DA1634"),
    userIds: [
      new Guid("72aa5334-c07f-48bd-972b-5c86fda12dd4"),
      new Guid("5015cf98-f5dc-4b5a-801c-8c8efa1f759f"),
    ],
    tenantId: new Guid("CB0501C1-503B-420A-B9C3-D4085796A743"),
    name: "Solveig Flaa",
    pin: "8113",
  },
  {
    id: new Guid("B091509B-F614-4E26-8EC5-BC0328688FD9"),
    userIds: [
      new Guid("72aa5334-c07f-48bd-972b-5c86fda12dd4"),
      new Guid("5015cf98-f5dc-4b5a-801c-8c8efa1f759f"),
    ],
    tenantId: new Guid("CB0501C1-503B-420A-B9C3-D4085796A743"),
    name: "Oskar Andersen",
    pin: "5855",
  },
  {
    id: new Guid("EE551CD0-67C0-4370-A58B-4181EA529C70"),
    userIds: [
      new Guid("72aa5334-c07f-48bd-972b-5c86fda12dd4"),
      new Guid("5015cf98-f5dc-4b5a-801c-8c8efa1f759f"),
    ],
    tenantId: new Guid("CB0501C1-503B-420A-B9C3-D4085796A743"),
    name: "Jonas Volden",
    pin: "8490",
  },
  {
    id: new Guid("08258C7F-00C1-4804-8B70-F992FAF50C4E"),
    userIds: [
      new Guid("72aa5334-c07f-48bd-972b-5c86fda12dd4"),
      new Guid("5015cf98-f5dc-4b5a-801c-8c8efa1f759f"),
    ],
    tenantId: new Guid("CB0501C1-503B-420A-B9C3-D4085796A743"),
    name: "Siri Arnesen",
    pin: "2973",
  },
  {
    id: new Guid("6A5DF437-47E1-41F8-A013-BD4BC00167FA"),
    userIds: [
      new Guid("72aa5334-c07f-48bd-972b-5c86fda12dd4"),
      new Guid("5015cf98-f5dc-4b5a-801c-8c8efa1f759f"),
    ],
    tenantId: new Guid("CB0501C1-503B-420A-B9C3-D4085796A743"),
    name: "Kristina Sele Danielsen",
    pin: "4952",
  },
  {
    id: new Guid("9D938E02-BCCB-41C1-8BFF-BF61BDB5E72F"),
    userIds: [
      new Guid("72aa5334-c07f-48bd-972b-5c86fda12dd4"),
      new Guid("5015cf98-f5dc-4b5a-801c-8c8efa1f759f"),
    ],
    tenantId: new Guid("CB0501C1-503B-420A-B9C3-D4085796A743"),
    name: "Hilde Celine Løvland",
    pin: "6865",
  },
  {
    id: new Guid("E118EBA0-1D8D-44B1-83F8-49E402DF3FEB"),
    userIds: [
      new Guid("72aa5334-c07f-48bd-972b-5c86fda12dd4"),
      new Guid("5015cf98-f5dc-4b5a-801c-8c8efa1f759f"),
    ],
    tenantId: new Guid("CB0501C1-503B-420A-B9C3-D4085796A743"),
    name: "Astri Belbo",
    pin: "3037",
  },
  {
    id: new Guid("B5026642-3775-4B9D-A702-5F6C08372F07"),
    userIds: [
      new Guid("72aa5334-c07f-48bd-972b-5c86fda12dd4"),
      new Guid("5015cf98-f5dc-4b5a-801c-8c8efa1f759f"),
    ],
    tenantId: new Guid("CB0501C1-503B-420A-B9C3-D4085796A743"),
    name: "Solveig Haugstad",
    pin: "4648",
  },
  {
    id: new Guid("22E0A1A6-92F8-4740-A1E9-EFC651E80241"),
    userIds: [
      new Guid("72aa5334-c07f-48bd-972b-5c86fda12dd4"),
      new Guid("5015cf98-f5dc-4b5a-801c-8c8efa1f759f"),
    ],
    tenantId: new Guid("CB0501C1-503B-420A-B9C3-D4085796A743"),
    name: "Adrian Asenso",
    pin: "8674",
  },
  {
    id: new Guid("56725FED-2055-4B3E-93BD-03CBDBD1B7AD"),
    userIds: [
      new Guid("72aa5334-c07f-48bd-972b-5c86fda12dd4"),
      new Guid("5015cf98-f5dc-4b5a-801c-8c8efa1f759f"),
    ],
    tenantId: new Guid("CB0501C1-503B-420A-B9C3-D4085796A743"),
    name: "Karianne Røed",
    pin: "9697",
  },
  {
    id: new Guid("6F6D0128-B0BF-45B8-B42D-B173708E68FD"),
    userIds: [
      new Guid("72aa5334-c07f-48bd-972b-5c86fda12dd4"),
      new Guid("5015cf98-f5dc-4b5a-801c-8c8efa1f759f"),
    ],
    tenantId: new Guid("CB0501C1-503B-420A-B9C3-D4085796A743"),
    name: "Selma Astrup Nilsen",
    pin: "4963",
  },
  {
    id: new Guid("DA5707D0-C116-4FA5-8181-7B2B788E6A29"),
    userIds: [
      new Guid("72aa5334-c07f-48bd-972b-5c86fda12dd4"),
      new Guid("5015cf98-f5dc-4b5a-801c-8c8efa1f759f"),
    ],
    tenantId: new Guid("CB0501C1-503B-420A-B9C3-D4085796A743"),
    name: "Magnus Sæbø Møllen",
    pin: "8813",
  },
  {
    id: new Guid("ADECF4AA-469F-4290-857A-A98F6393C981"),
    userIds: [
      new Guid("72aa5334-c07f-48bd-972b-5c86fda12dd4"),
      new Guid("5015cf98-f5dc-4b5a-801c-8c8efa1f759f"),
    ],
    tenantId: new Guid("CB0501C1-503B-420A-B9C3-D4085796A743"),
    name: "Edel-Mari Ricaborda Dahl",
    pin: "3007",
  },
  {
    id: new Guid("87CBED16-076A-4D2F-B458-38E1A94120B4"),
    userIds: [
      new Guid("72aa5334-c07f-48bd-972b-5c86fda12dd4"),
      new Guid("5015cf98-f5dc-4b5a-801c-8c8efa1f759f"),
    ],
    tenantId: new Guid("CB0501C1-503B-420A-B9C3-D4085796A743"),
    name: "Elisabeth Brautaset",
    pin: "7600",
  },
  {
    id: new Guid("BBB830F9-FCE1-4A3F-82FC-51E0151A0C1E"),
    userIds: [
      new Guid("72aa5334-c07f-48bd-972b-5c86fda12dd4"),
      new Guid("5015cf98-f5dc-4b5a-801c-8c8efa1f759f"),
    ],
    tenantId: new Guid("CB0501C1-503B-420A-B9C3-D4085796A743"),
    name: "Sabarin Ahmed",
    pin: "4118",
  },
  {
    id: new Guid("E472CAC0-F8B0-49BD-9091-E37DBF3AF516"),
    userIds: [new Guid("9c016b20-e217-4204-ae88-f318aa716152")],
    tenantId: new Guid("CB0501C1-503B-420A-B9C3-D4085796A743"),
    name: "Maria robertsen",
    pin: "1028",
  },
  {
    id: new Guid("95A95455-D028-44C7-B42E-711BF21D11B4"),
    userIds: [new Guid("9c016b20-e217-4204-ae88-f318aa716152")],
    tenantId: new Guid("CB0501C1-503B-420A-B9C3-D4085796A743"),
    name: "Julie Nordland",
    pin: "1049",
  },
  {
    id: new Guid("CA3A71AC-F16E-4A5F-B410-F5DF7603623B"),
    userIds: [new Guid("9c016b20-e217-4204-ae88-f318aa716152")],
    tenantId: new Guid("CB0501C1-503B-420A-B9C3-D4085796A743"),
    name: "Åshild Bjørgo",
    pin: "2755",
  },
  {
    id: new Guid("9A5D0540-5140-4F9F-9A4E-DE55F46218FB"),
    userIds: [new Guid("9c016b20-e217-4204-ae88-f318aa716152")],
    tenantId: new Guid("CB0501C1-503B-420A-B9C3-D4085796A743"),
    name: "Miriam Frøytland",
    pin: "1158",
  },
  {
    id: new Guid("5FB1B67E-E802-4A93-9362-F3E7910C298C"),
    userIds: [new Guid("9c016b20-e217-4204-ae88-f318aa716152")],
    tenantId: new Guid("CB0501C1-503B-420A-B9C3-D4085796A743"),
    name: "Hedda kjær",
    pin: "6700",
  },
  {
    id: new Guid("3D8D187C-29FD-4FFA-8F1C-A72970D65EB4"),
    userIds: [new Guid("9c016b20-e217-4204-ae88-f318aa716152")],
    tenantId: new Guid("CB0501C1-503B-420A-B9C3-D4085796A743"),
    name: "Kevin vu",
    pin: "8714",
  },
  {
    id: new Guid("13D54F90-3DC3-4500-95C3-E0B58A189183"),
    userIds: [new Guid("9c016b20-e217-4204-ae88-f318aa716152")],
    tenantId: new Guid("CB0501C1-503B-420A-B9C3-D4085796A743"),
    name: "Sigurd gunnarsli",
    pin: "8809",
  },
  {
    id: new Guid("FD590E75-373D-480E-AEB9-DF9C361D10E4"),
    userIds: [new Guid("9c016b20-e217-4204-ae88-f318aa716152")],
    tenantId: new Guid("CB0501C1-503B-420A-B9C3-D4085796A743"),
    name: "Elise Syvertsen",
    pin: "5149",
  },
  {
    id: new Guid("D6842868-FA39-4C75-81AB-3DB2EAD86335"),
    userIds: [new Guid("9c016b20-e217-4204-ae88-f318aa716152")],
    tenantId: new Guid("CB0501C1-503B-420A-B9C3-D4085796A743"),
    name: "Ina Holte",
    pin: "8240",
  },
  {
    id: new Guid("B767A4A7-A53D-4AC9-87C6-D874569FE194"),
    userIds: [new Guid("9c016b20-e217-4204-ae88-f318aa716152")],
    tenantId: new Guid("CB0501C1-503B-420A-B9C3-D4085796A743"),
    name: "Kristine nygård",
    pin: "6388",
  },
  {
    id: new Guid("D68F6ECD-B6F1-4093-A537-D9B2D82328D9"),
    userIds: [new Guid("9c016b20-e217-4204-ae88-f318aa716152")],
    tenantId: new Guid("CB0501C1-503B-420A-B9C3-D4085796A743"),
    name: "Vilde wehus",
    pin: "4322",
  },
  {
    id: new Guid("C82E01FE-DA72-4E18-8422-AAB34E530413"),
    userIds: [new Guid("9c016b20-e217-4204-ae88-f318aa716152")],
    tenantId: new Guid("CB0501C1-503B-420A-B9C3-D4085796A743"),
    name: "Mathea Lund Olsen",
    pin: "2458",
  },
  {
    id: new Guid("12D57D97-43AA-4ECA-8624-CDD17F6E402B"),
    userIds: [new Guid("9c016b20-e217-4204-ae88-f318aa716152")],
    tenantId: new Guid("CB0501C1-503B-420A-B9C3-D4085796A743"),
    name: "Ellinor Wold",
    pin: "1219",
  },
  {
    id: new Guid("84FDA456-6064-4E2E-945A-AA3FF4C470DD"),
    userIds: [new Guid("9c016b20-e217-4204-ae88-f318aa716152")],
    tenantId: new Guid("CB0501C1-503B-420A-B9C3-D4085796A743"),
    name: "Mina mollestad",
    pin: "4350",
  },
  {
    id: new Guid("6CC31611-5F45-411A-9A43-94E3E6A96905"),
    userIds: [new Guid("9c016b20-e217-4204-ae88-f318aa716152")],
    tenantId: new Guid("CB0501C1-503B-420A-B9C3-D4085796A743"),
    name: "Rikke maasbach",
    pin: "3262",
  },
  {
    id: new Guid("1AFE81AD-10F4-4693-A284-AE27AEB2E6CC"),
    userIds: [new Guid("9c016b20-e217-4204-ae88-f318aa716152")],
    tenantId: new Guid("CB0501C1-503B-420A-B9C3-D4085796A743"),
    name: "Jacob kvanvig",
    pin: "3030",
  },
  {
    id: new Guid("A68F7902-4657-4B4D-9FFA-94FC6D9B2F3B"),
    userIds: [new Guid("9c016b20-e217-4204-ae88-f318aa716152")],
    tenantId: new Guid("CB0501C1-503B-420A-B9C3-D4085796A743"),
    name: "Helena Lundin",
    pin: "6117",
  },
  {
    id: new Guid("416B1037-A670-4724-942C-34623909986C"),
    userIds: [new Guid("9c016b20-e217-4204-ae88-f318aa716152")],
    tenantId: new Guid("CB0501C1-503B-420A-B9C3-D4085796A743"),
    name: "Martine Holte",
    pin: "8944",
  },
  {
    id: new Guid("4909B1F4-175A-4A01-8020-0C41E374BF36"),
    userIds: [new Guid("0e2d0ea9-0ee3-46e9-a10a-d8342022ed5c")],
    tenantId: new Guid("CB0501C1-503B-420A-B9C3-D4085796A743"),
    name: "MARIE KJØSTVEDT",
    pin: "1681",
  },
  {
    id: new Guid("F697718E-3651-40EB-A40E-83CF193B4E79"),
    userIds: [new Guid("0e2d0ea9-0ee3-46e9-a10a-d8342022ed5c")],
    tenantId: new Guid("CB0501C1-503B-420A-B9C3-D4085796A743"),
    name: "HANNE HORN TØNNESEN",
    pin: "2234",
  },
  {
    id: new Guid("646B6102-73B3-4AE3-BB8D-8AE28C104BEF"),
    userIds: [new Guid("0e2d0ea9-0ee3-46e9-a10a-d8342022ed5c")],
    tenantId: new Guid("CB0501C1-503B-420A-B9C3-D4085796A743"),
    name: "ANNE VISSCHER",
    pin: "1160",
  },
  {
    id: new Guid("B5C91AB3-AD6F-47F9-BCFE-341613B47F2C"),
    userIds: [new Guid("0e2d0ea9-0ee3-46e9-a10a-d8342022ed5c")],
    tenantId: new Guid("CB0501C1-503B-420A-B9C3-D4085796A743"),
    name: "MALIN STEDAL",
    pin: "2319",
  },
  {
    id: new Guid("581FE696-55AA-4F36-A5FD-D06B337960FB"),
    userIds: [new Guid("0e2d0ea9-0ee3-46e9-a10a-d8342022ed5c")],
    tenantId: new Guid("CB0501C1-503B-420A-B9C3-D4085796A743"),
    name: "INGRID MARIE EKELAND",
    pin: "2523",
  },
  {
    id: new Guid("522B5C5E-9EF5-4206-BA56-7BD8A58936B8"),
    userIds: [new Guid("0e2d0ea9-0ee3-46e9-a10a-d8342022ed5c")],
    tenantId: new Guid("CB0501C1-503B-420A-B9C3-D4085796A743"),
    name: "FROYDIS DRIESEN",
    pin: "9810",
  },
  {
    id: new Guid("8B84DD09-A490-4575-9DC7-D7EF4F4529D2"),
    userIds: [new Guid("0e2d0ea9-0ee3-46e9-a10a-d8342022ed5c")],
    tenantId: new Guid("CB0501C1-503B-420A-B9C3-D4085796A743"),
    name: "SOLVEIG KALRSEN",
    pin: "5573",
  },
  {
    id: new Guid("3DF538FB-60D2-4B5E-A574-4CE2B5F84FC7"),
    userIds: [new Guid("0e2d0ea9-0ee3-46e9-a10a-d8342022ed5c")],
    tenantId: new Guid("CB0501C1-503B-420A-B9C3-D4085796A743"),
    name: "MARTE SOLVANG",
    pin: "6778",
  },
  {
    id: new Guid("5534AA0B-CA27-4617-8CB2-D73726DE4BB2"),
    userIds: [new Guid("0e2d0ea9-0ee3-46e9-a10a-d8342022ed5c")],
    tenantId: new Guid("CB0501C1-503B-420A-B9C3-D4085796A743"),
    name: "THEA MODAL EIDE",
    pin: "5865",
  },
  {
    id: new Guid("45CB449F-9AFE-4E4D-82E8-E70ACF260BC8"),
    userIds: [new Guid("0e2d0ea9-0ee3-46e9-a10a-d8342022ed5c")],
    tenantId: new Guid("CB0501C1-503B-420A-B9C3-D4085796A743"),
    name: "SAGA ISMAEL",
    pin: "1517",
  },
  {
    id: new Guid("BABC7250-5E16-4473-A4F7-899FB4300E63"),
    userIds: [new Guid("0e2d0ea9-0ee3-46e9-a10a-d8342022ed5c")],
    tenantId: new Guid("CB0501C1-503B-420A-B9C3-D4085796A743"),
    name: "KRISTINE STALLEMO",
    pin: "5502",
  },
  {
    id: new Guid("44D2D11D-7AD6-42CF-8BCF-4729EDCAC83D"),
    userIds: [new Guid("0e2d0ea9-0ee3-46e9-a10a-d8342022ed5c")],
    tenantId: new Guid("CB0501C1-503B-420A-B9C3-D4085796A743"),
    name: "INTISAR SAID",
    pin: "5636",
  },
  {
    id: new Guid("9147B28F-2052-41D1-815A-82A7FF8A58F8"),
    userIds: [new Guid("0e2d0ea9-0ee3-46e9-a10a-d8342022ed5c")],
    tenantId: new Guid("CB0501C1-503B-420A-B9C3-D4085796A743"),
    name: "TOBIAS BERG",
    pin: "5404",
  },
  {
    id: new Guid("21E84C86-37B0-418A-B28B-D4A84BBC4064"),
    userIds: [
      new Guid("9c016b20-e217-4204-ae88-f318aa716152"),
      new Guid("72aa5334-c07f-48bd-972b-5c86fda12dd4"),
      new Guid("0e2d0ea9-0ee3-46e9-a10a-d8342022ed5c"),
      new Guid("5015cf98-f5dc-4b5a-801c-8c8efa1f759f"),
    ],
    tenantId: new Guid("CB0501C1-503B-420A-B9C3-D4085796A743"),
    name: "GJEST",
    pin: "2326",
  },
];
const SIGNATURE_TIMEOUT: number = 1000 * 60 * 5;
export const initialSignatureState = (
  userId: Guid = Guid.empty(),
  tenantId: Guid = Guid.empty()
): SignatureContextType => {
  return {
    activeSignature: null,
    timeout: SIGNATURE_TIMEOUT,
    signatures: [],
    iamUpdated: false,
    search: "",
    userIds: userId.toString(),
    tenantId: tenantId.toString(),
  };
};
export const SignatureContext = React.createContext<{
  state: SignatureContextType;
  dispatch: React.Dispatch<any>;
}>({
  state: initialSignatureState(),
  dispatch: () => null,
});

export const useSignatureContext = () => React.useContext(SignatureContext);

export const signatureReducer = (
  state: SignatureContextType,
  action: any
): SignatureContextType => {
  switch (action.type) {
    case "signature-list":
      const selectedSignatureStore1 = getSignatureStore();
      const signatures = action.payload;
      const activeSignature =
        !isExpired(selectedSignatureStore1) &&
        selectedSignatureStore1 &&
        signatures.find(
          (x) => x.id.toString() === selectedSignatureStore1.activeSignature
        );
      return {
        ...state,
        signatures,
        activeSignature,
      };
    case "selected-signature":
      localStorage.setItem(
        "bitmesh.signature",
        JSON.stringify({
          activeSignature: action.activeSignature.id.toString(),
          timestamp: DateTime.local().toISO(),
        })
      );
      const newState = {
        ...state,
        activeSignature: action.activeSignature,
        iamUpdated: true,
      };
      return newState;
    case "active":
      if (!state.activeSignature) return state;
      localStorage.setItem(
        "bitmesh.signature",
        JSON.stringify({
          activeSignature: state.activeSignature.id.toString(),
          timestamp: DateTime.local().toISO(),
        })
      );
      const activeState = {
        ...state,
        iamUpdated: true,
      };
      return activeState;
    case "auto-signout":
      if (!state.activeSignature) return state;
      const selectedSignatureStore = getSignatureStore();
      if (selectedSignatureStore && isExpired(selectedSignatureStore)) {
        localStorage.setItem("bitmesh.signature", "");
        return { ...state, activeSignature: null };
      }
      return state;
    case "unsign":
      if (!state.activeSignature) return state;
      localStorage.setItem("bitmesh.signature", "");
      return { ...state, activeSignature: null };
    case "search-signature":
      localStorage.setItem(
        "bitmesh.signature.search",
        JSON.stringify({
          searchText: action.search,
          timestamp: DateTime.local().toISO(),
        })
      );
      return {
        ...state,
        search: action.search,
        signatures: findSignatures(
          new Guid(state.userIds),
          new Guid(state.tenantId),
          state.signatures,
          action.search
        ),
      };
    default:
      return state;
  }
};
export const useSignatureState = (
  userId: Guid = Guid.empty(),
  tenantId: Guid = Guid.empty()
): [SignatureContextType, Dispatch<any>] => {
  const context = useReducer(
    signatureReducer,
    initialSignatureState(userId, tenantId)
  );

  return context;
};
function findSignatures(
  userId: Guid,
  tenantId: Guid = Guid.empty(),
  signatures: Array<Signature>,
  search: string = ""
) {
  var lowerSearch = search.toLowerCase();
  return [].concat(
    signatures
      .filter(
        (x) =>
          search !== "" &&
          // x.tenantId.toString() === tenantId.toString() &&
          x.name.toLowerCase().indexOf(lowerSearch) >= 0
      )
      .map((item) => ({ ...item, name: `(Gjest) ${item.name}` })),
    signatures.filter(
      (x) =>
        x.userIds &&
        x.userIds.filter((id) => userId && id.valueOf() === userId?.valueOf())
          .length > 0
    )
  );
}

function isExpired(selectedSignatureStore: SelectedSignatureStore) {
  if (!selectedSignatureStore) return true;
  const signedDate = DateTime.fromISO(selectedSignatureStore.timestamp);
  const elapsed = signedDate.diff(DateTime.local(), "seconds");

  const timeoutSeconds = Duration.fromObject({
    seconds: -SIGNATURE_TIMEOUT / 1000.0,
  });
  const isExpired = elapsed <= timeoutSeconds;
  return isExpired;
}

function getSignatureStore() {
  const json = localStorage.getItem("bitmesh.signature");

  return json !== "" ? (JSON.parse(json) as SelectedSignatureStore) : null;
}
function getSearchStore() {
  const json = localStorage.getItem("bitmesh.signature.search");

  return json !== "" ? JSON.parse(json) : null;
}
