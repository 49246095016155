import * as React from "react";
import { useState, useEffect, useContext } from "react";
import { Guid } from "../../system/guid";
import { IValues } from "../interfaces";
import EntityForm from "./EntityForm";
import * as moment from "moment";
import EditEntityContext from "../EditEntityContext";
import { take } from "rxjs/operators";
import { Site } from "../../entity/site";
import { Entity } from "../../entity/entity";
import { TermoObject } from "../../entity/termo-object";
import { OpenCloseObject } from "../../entity/open-close-object";
import { ReptileObject } from "../../entity/reptile-object";
import {
  SignalTag,
  SignalTagFunction,
  SignalTagType,
} from "../../signals/vevent";
import { forkJoin, Observable } from "rxjs";
import { Alert, Snackbar } from "@mui/material";
import { useTranslation } from "react-i18next";
import QrCodes from "./QrCodes";

const NewEntity = () => {
  const [tenantId, setTenantId] = useState("");
  const { siteService, entityService, signalsService, router, user } =
    useContext(EditEntityContext);

  useEffect(() => {
    siteService
      .getSite(user.siteId)
      .pipe(take(1))
      .subscribe((site: Site) => {
        setTenantId(site.tenantId.toString());
      });
  }, []);

  const [alert, setAlert] = useState<{ open: boolean, success: boolean }>({ open: false, success: false });
  const setAlertOpen = (open: boolean) => {
    setAlert({
      ...alert,
      open
    });
  }
  const { t } = useTranslation();

  const handleSave = (values: IValues) => {
    let entityId = Guid.newGuid().toString();
    let createdOn = moment();

    let newObj = {
      id: entityId,
      entityId,
      tenantId,
      createdOn,
      rules: values.limits,
      make: values.family,
      model: values.species,
      termoObjectTypeId: values.temperature.termoObjectTypeId,
    };

    let newEntity = Entity.create({
      id: entityId,
      name: values.name,
      siteId: user.siteId,
      tenantId,
      type: values.type,
      createdOn,
      hide: false,
      serviceHours: values.serviceHours,
      objectImages: values.objectImages,
    });

    let objMethod;
    switch (values.type) { 
      case TermoObject.typeName: {
        objMethod = entityService.postThermoObject(newObj);
        break;
      }
      case OpenCloseObject.typeName: {
        objMethod = entityService.postOpenCloseObject(newObj);
        signalsService
          .registerNewEntityCleanSignal(entityId)
          .subscribe((r) => console.log(`Registered entity clean signal`));
        break;
      }
      case ReptileObject.typeName: {
        objMethod = entityService.postReptileObject(newObj);
        break;
      }
      default: {
        throw Error;
      }
    }

    let tempSignalTag = new SignalTag(
      Guid.newGuid().toString(),
      "SignalTag",
      values.temperature.sourceId,
      moment().utc(),
      entityId,
      SignalTagType.Start,
      SignalTagFunction.Temp
    );

    let doorSignalTag = new SignalTag(
      Guid.newGuid().toString(),
      "SignalTag",
      values.door.sourceId,
      moment().utc(),
      entityId,
      SignalTagType.Start,
      SignalTagFunction.Door
    );

    let humiditySignalTag = new SignalTag(
      Guid.newGuid().toString(),
      "SignalTag",
      values.humidity.sourceId,
      moment().utc(),
      entityId,
      SignalTagType.Start,
      SignalTagFunction.Humidity
    );

    let touchSignalTag = new SignalTag(
      Guid.newGuid().toString(),
      "SignalTag",
      values.touch.sourceId,
      moment().utc(),
      entityId,
      SignalTagType.Start,
      newEntity.type === OpenCloseObject.typeName
        ? SignalTagFunction.Clean
        : SignalTagFunction.Touch
    );

    let ppmSignalTag = new SignalTag(
      Guid.newGuid().toString(),
      "SignalTag",
      values.ppm.sourceId,
      moment().utc(),
      entityId,
      SignalTagType.Start,
      SignalTagFunction.PPM
    );
    let pressureSignalTag = new SignalTag(
      Guid.newGuid().toString(),
      "SignalTag",
      values.pressure.sourceId,
      moment().utc(),
      entityId,
      SignalTagType.Start,
      SignalTagFunction.PPM
    );
    const signalsObservables = [];

    if (values.temperature.sourceId) {
      signalsObservables.push(signalsService.postSignalTag(tempSignalTag));
    }

    if (values.humidity.sourceId) {
      signalsObservables.push(signalsService.postSignalTag(humiditySignalTag));
    }

    if (values.door.sourceId) {
      signalsObservables.push(signalsService.postSignalTag(doorSignalTag));
    }

    if (values.touch.sourceId) {
      signalsObservables.push(signalsService.postSignalTag(touchSignalTag));
    }

    if (values.ppm.sourceId) {
      signalsObservables.push(signalsService.postSignalTag(ppmSignalTag));
    }
    if (values.pressure.sourceId) {
      signalsObservables.push(signalsService.postSignalTag(pressureSignalTag));
    }

    forkJoin([
      entityService.postEntity(newEntity),
      objMethod,
      ...signalsObservables,
    ])
      .pipe(take(1))
      .subscribe(
        () => {
          setAlert({
            success: true,
            open: true
          });
        },
        () => {
          setAlert({
            success: false,
            open: true
          });
        },
        () => router.navigateByUrl(`/entities`)
      );
  };

  return (
    <>
      <EntityForm isNew handleSubmit={handleSave} />;
      <Snackbar
        open={alert.open}
        autoHideDuration={3000}
        onClose={() => setAlertOpen(false)}
        sx={{ height: "100%" }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => setAlertOpen(false)}
          severity={alert.success ? "success" : "error"}
          sx={{ width: "100%" }}
        >
          <b>{t(`entityForm.${alert.success ? "successMessage" : "errorMessage"}`)}</b>
        </Alert>
      </Snackbar>
    </>
  );
};

export default NewEntity;
