import {
  Button,
  CardContent,
  CardHeader,
  Theme,
  Card,
  Typography,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
// import * as React from "react";
import React, { useEffect, useState, useContext, FC } from "react";
import { useTranslation } from "react-i18next";
import { usePutSignature } from "./SignatureService";

type Props = {
  s: any;
  userId?: string;
  onChange: ()=>void;
};

const useStyles = makeStyles(({ spacing, palette }: Theme) =>
  createStyles({
    root: {
      textDecoration: "none",
    },
    signatureCard: {},
    header: {
      paddingTop: 2,
    },
    card: {
      paddingBottom: 2,
    },
    alreadyAdded: {
      fontStyle: 'italic'
    }
  })
);

const SignatureCard: FC<Props> = ({ s, userId, onChange }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [signature, setSignature] = useState(s);

  const addSignature = async () => {
    console.log(`Adding existing signature ${signature.id} to user  ${userId}`);
    signature.userIds.push(userId);
    const userIds = signature.userIds;
    const updatedSignature = {
      id: signature.id,
      name: signature.name,
      pin: signature.pin,
      tenantId: signature.tenantId,
      createdOn: signature.createdOn,
      disabled: signature.disabled,
      userIds: userIds,
    };

    await usePutSignature(updatedSignature, signature.id);
    setSignature(updatedSignature);
    onChange();
  };

  return (
    <Card key={signature.id} className={classes.signatureCard}>
      <CardHeader
        className={classes.header}
        title={signature.name ?? "Uten navn"}
        sx={{paddingBottom: 0}}
      />
      <CardContent className={classes.card} sx={{paddingTop: 0}}>
        {signature.userIds.includes(userId) ? (
          <p className={classes.alreadyAdded}>{t("signature.already_added")}</p>
        ) : (
          <Button onClick={() => addSignature()} variant="contained">
            {t("signature.add")}
          </Button>
        )}
      </CardContent>
    </Card>
  );
};

export default SignatureCard;
