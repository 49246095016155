import React, { useContext, useEffect, useState } from "react";
import { Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import clsx from "clsx";
import { LoginContext } from "../LoginReactWrapper";
import { environment } from "../../../environments/environment";
import { AccountInfo, AuthenticationResult } from "@azure/msal-browser";
import { Observable } from "rxjs";
import { getDefaultRoute, urlToScopeKey } from "../../route-utils";
import { PhoneSignDialog } from "../../security/PhoneSignDialog";
import { getAccess } from "../../security/getAccess";
import { refreshToken } from "../../security/refreshToken";
import { setAxiosInterceptors } from "../../security/axiosInterceptors";
import axios from "axios";
import { isAuthenticated } from "../../security/token";
import Loading from "../../react-components/Loading";
import { clearStorage, deleteAllCookies } from "../../localStorageUtils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",
      flexDirection: "column",
      minHeight: "100vh",
      overflow: "hidden",
    },
    splashBg: {
      backgroundImage: 'url("assets/img/splash-bg.jpg")',
      boxShadow: `inset 0 0 0 2000px rgba(217, 124, 84, 0.9)`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
    },
    img: {
      paddingTop: "5em",
      paddingBottom: "2em",
      width: "50%",
    },
    loginButton: {
      cursor: "pointer",
      height: "3em",
      width: "50%",
      color: "#ffff",
      backgroundColor: theme.palette.primary.dark,
      borderRadius: "15px",
      border: 0,
      fontWeight: 600,
      textTransform: "uppercase",
      fontSize: "0.875 rem",
      marginBottom: "1em",
    },
    textWelcome: {
      color: "#ffff",
      paddingTop: "1em",
    },
    textBrand: {
      color: "#ffff",
      paddingBottom: "1em",
    },
    loading: {
      '& svg': {
        color: "#fff !important"
      }
    }
  })
);

export default function Login() {
  const classes = useStyles();

  const { msalService, router } = useContext(LoginContext);

  const [handledRedirectPromise, setHandledRedirectResponse] = useState(false);
  const [failedToAuthenticate, setFailedToAuthenticate] = useState(false);

  const handleSuccess = () => {
    setAxiosInterceptors(axios);

    // Needs a refresh for the menu to show up
    window.location.pathname = getDefaultRoute() || "/dashboard";
    // router.navigate([getDefaultRoute() || "/dashboard"]);
  };

  const handleAuthObservable = (obs: Observable<AuthenticationResult>) => {
    obs.subscribe(
      (authResult: AuthenticationResult) => {
        if (authResult?.accessToken) {
          getAccess(authResult?.accessToken)
            .then(handleSuccess)
            .catch(() => setFailedToAuthenticate(true));
        }
      },
      (error: any) => {
        console.error(error);
      }
    );
  };

  useEffect(() => {
    // isAuthenticated() && refreshToken(axios).then(handleSuccess).catch(console.log);
    msalService.instance.handleRedirectPromise().then((r) => {
      setHandledRedirectResponse(!r);
      r && tryToGetAccess(r.account);
    });
  }, []);

  const tryToGetAccess = (account?: AccountInfo) => {
    const authObs = msalService.acquireTokenSilent({
      account: account || msalService.instance.getActiveAccount(),
      scopes: environment.protectedResourceMap.get(
        urlToScopeKey(environment.dependencies.security_api.url)
      ),
    });
    handleAuthObservable(authObs);
  };

  const [phoneSignOpen, setPhoneSignOpen] = useState(false);

  return (
    <div className={clsx(classes.root, classes.splashBg)}>
      <img
        className={classes.img}
        src="assets/img/tmesh-logo-transparent.png"
      />
      <Typography variant="h2" className={classes.textWelcome}>
        Welcome to{" "}
      </Typography>
      <Typography variant="h1" className={classes.textBrand}>
        Tmesh.
      </Typography>
      {!handledRedirectPromise && !failedToAuthenticate && <Loading className={classes.loading} />}
      {failedToAuthenticate && (
        <>
          <Typography variant="h2" className={classes.textWelcome} sx={{ marginBottom: '2em' }}>
            Failed to sign in.
          </Typography>
          <button
            className={classes.loginButton}
            onClick={() => {
              setFailedToAuthenticate(false);
              setHandledRedirectResponse(true);
            }}
          >
            Try Again
          </button>
        </>
      )}
      {handledRedirectPromise && (
        <>
          <button
            className={classes.loginButton}
            onClick={() => {
              let url = urlToScopeKey(environment.dependencies.security_api.url);
              let scopes = environment.protectedResourceMap.get(
                url
              );
              clearStorage();
              sessionStorage.clear();
              deleteAllCookies();
              msalService
                .loginRedirect({
                  prompt: "select_account",
                  scopes: scopes,
                })
                .subscribe(() => {});
            }}
          >
            Sign in
          </button>
          <button
            className={classes.loginButton}
            onClick={() => {
              clearStorage();
              setPhoneSignOpen(true);
            }}
          >
            SMS Sign in
          </button>
        </>
      )}

      <PhoneSignDialog
        open={phoneSignOpen}
        onSuccess={() => {
          setPhoneSignOpen(false);
          handleSuccess();
        }}
        onClose={() => setPhoneSignOpen(false)}
      />
    </div>
  );
}
