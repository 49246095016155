import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Paper,
  Theme,
  Typography,
} from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import { DateTime } from "luxon";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { take } from "rxjs/operators";
import { Site } from "../../../entity/site";
import Loading from "../../../react-components/Loading";
import { useChecklistContext } from "../../ChecklistReactWrapper";
import {
  mapTenantChecklists,
  useGetAllPointEvents,
} from "../../services/checklistService";
import { useQuery } from "../../services/utils";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeviationHandlingLevelFilters from "./DeviationHandlingLevelFilters";
import { SessionDeviationGroups } from "./SessionDeviationGroups";
import { useChecklistDescribedRegistry } from "./useChecklistDescribedRegistry";
import { useChecklistDeviations } from "./useChecklistDeviations";

type Props = {
  checklistData: any;
};

type DeviationsDayAccordionProps = {
  initiallyExpanded: boolean;
  summary: React.ReactNode;
};

const DeviationsDayAccordion: FC<DeviationsDayAccordionProps> = ({
  initiallyExpanded,
  summary,
  children,
}) => {
  const [expanded, setExpanded] = useState(initiallyExpanded);
  return (
    <Accordion
      expanded={expanded}
      onChange={() => setExpanded(!expanded)}
      style={{ backgroundColor: "transparent", boxShadow: "none" }}
    >
      <AccordionSummary
        style={{ display: "flex" }}
        expandIcon={<ExpandMoreIcon />}
        aria-label="Expand"
      >
        {summary}
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};

const useStyles = makeStyles(({ spacing, palette, breakpoints }: Theme) =>
  createStyles({
    lane: {
      backgroundColor: palette.grey[200],
      padding: spacing(1),
      minHeight: spacing(5),
      marginBottom: spacing(2),
    },
    laneTitle: {
      textTransform: "uppercase",
      fontWeight: 500,
      opacity: 0.54,
    },
    tagsInputContainer: {
      marginBottom: spacing(2),
      flexGrow: "1",
    },
    infoButton: {
      minWidth: "2em",
    },
    sessionDeviationsContainer: {
      borderRadius: "25px",
      padding: "1.5em",
      border: "0.5px solid rgba(102, 102, 102, 0.25)",
      marginBottom: "2em",
      [breakpoints.down("md")]: {
        padding: "1em",
        marginBottom: "1em",
      },
    },
    sessionDeviationsTitle: {
      marginBottom: "1em",
      fontWeight: "700",
      [breakpoints.down("md")]: {
        marginBottom: "0.1em",
        fontWeight: "650",
      },
    },
  })
);

const DeviationList: FC<Props> = ({ checklistData }) => {
  const { t } = useTranslation();

  const classes = useStyles();

  const query = useQuery();
  const tagsFromQuery = query.getAll("tag");
  const assignedId = query.get("assignedId");
  const from = query.get("from");
  const to = query.get("to");
  const navigate = useNavigate();
  const location = useLocation();
  const isSingleRourtinePage = !!assignedId && !!from && !!to;
  const isCommentPage = location.pathname.endsWith("/comments");
  const isTemperaturePage = location.pathname.endsWith("/temperature");

  const assignedIds: string[] = [
    ...new Set<any>(
      checklistData
        .filter((e) => e.type === "AssignedToSite" && (!assignedId || assignedId === e.assignedId))
        .map((e) => e.assignedId as string)
    ),
  ];

  const [refDateStart, setRefDateStart] = useState(DateTime.local());
  const [tags, setTags] = useState(tagsFromQuery);
  const [filteredChecklistIds, setFilteredChecklistIds] = useState([]);


  const checklistDescribedRegistryForAssignedId = useChecklistDescribedRegistry(assignedIds, checklistData);

  const { allDeviations, deviationData, deviationError, deviationLoading } = useChecklistDeviations({
    assignedIds,
    checklistDescribedRegistryForAssignedId,
    refDateStart,
    from,
    to,
  });


  useEffect(() => {
    setTags(tagsFromQuery);
  }, [location.pathname, location.search]);

  useEffect(() => {
    setFilteredChecklistIds(
      mapTenantChecklists(checklistData, tags).map((c) => c.checklistId)
    );
  }, [tags, checklistData, location.pathname]);

  const [tenantId, setTenantId] = useState("");
  const { user, siteService } = useChecklistContext();

  useEffect(() => {
    siteService
      .getSite(user.siteId)
      .pipe(take(1))
      .subscribe((site: Site) => {
        setTenantId(site.tenantId.toString());
      });
  }, []);

  const [{ data: points, loading: pointsLoading, error: pointsError }] =
    useGetAllPointEvents(user.siteId);

  const pointNameRegistry = {};
  points?.forEach((point) => {
    if (point.name) {
      const sitePoint = points.filter(p => p.pointId === point.pointId).reverse().find(p => p.type === "SitePointDescribed");
      pointNameRegistry[point.pointId] = sitePoint?.name || point.name;
    }
  });


  if ((deviationLoading || pointsLoading) && !deviationData) {
    return <Paper>{t("Loading")}</Paper>;
  }
  if ((deviationError || pointsError) && !deviationData) {
    return <Paper>{t("Error")}</Paper>;
  }

  const handleTagsChange = (tags) => {
    if (tags.length) {
      query.set("tag", tags[0]);
      tags.slice(1).forEach((tag) => query.append("tag", tag));
    } else {
      query.delete("tag");
    }
    setTags(query.getAll("tag"));
    navigate(`${location.pathname}?${query}`);
  };

  const filterDeviationsForPage = (deviations) => {
    return deviations.filter((d) =>
      isTemperaturePage
        ? d.type === "TemperatureDeviationReported"
        : isSingleRourtinePage ? true : d.type !== "TemperatureDeviationReported"
    );
  };

  const filterDeviations = (deviations) => {
    return deviations.filter(
      (d) =>
        (filteredChecklistIds.includes(d.checklistId) &&
          (isTemperaturePage
            ? d.type === "TemperatureDeviationReported"
            : isSingleRourtinePage ? true
              : d.type !== "TemperatureDeviationReported")) ||
        (!isTemperaturePage && d.type === "ChecklistComment")
    );
  };

  return (
    <>
      {!isCommentPage && !isSingleRourtinePage && (
        <div>
          <DeviationHandlingLevelFilters
            value={tags}
            onChange={handleTagsChange}
          />
        </div>
      )}
      {!!allDeviations.length && (
        <Typography variant="h6" sx={{ textAlign: "end" }}>
          {DateTime.fromISO(
            allDeviations[allDeviations.length - 1].timestamp
          ).toLocaleString(DateTime.DATE_MED)}{" "}
          -{" "}
          {DateTime.fromISO(allDeviations[0].timestamp).toLocaleString(
            DateTime.DATE_MED
          )}
        </Typography>
      )}
      {allDeviations.length < 1 && !deviationLoading && (
        <Paper className={classes.lane}>
          <p>{t("No deviations reported")}</p>
        </Paper>
      )}
      {allDeviations.map((item, key) => {
        const sessionDeviationKeys = Object.keys(item.deviations);
        const totalNumberOfDeviatedSessionss = sessionDeviationKeys.filter(
          (sessionId) => {
            const itemDeviations = filterDeviationsForPage(
              item.deviations[sessionId]
            );
            return !!itemDeviations.length;
          }
        ).length;
        return (
          !!totalNumberOfDeviatedSessionss && (
            <Paper key={key + item.timestamp} className={classes.lane} elevation={1}>
              <DeviationsDayAccordion
                initiallyExpanded={key === 0}
                summary={
                  <Typography className={classes.laneTitle} gutterBottom>
                    {item.date}{" "}
                  </Typography>
                }
              >
                <SessionDeviationGroups
                  tenantId={tenantId}
                  filterDeviations={filterDeviations}
                  pointNameRegistry={pointNameRegistry}
                  sessionDeviations={item.deviations}
                />
              </DeviationsDayAccordion>
            </Paper>
          )
        );
      })}
      {deviationData && !deviationLoading && (
        <Button onClick={() => setRefDateStart(refDateStart.minus({ weeks: 1 }))}>
          {t("Load more")}
        </Button>
      )}
      {deviationData && deviationLoading && <Loading />}
    </>
  );
};

export default DeviationList;
