import * as React from "react";
import { Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    marginBottom: "2rem",
  },
  title: {
    flexGrow: 1,
    display: "flex",
    justifyContent: "center",
  },
  homeIcon: {
    color: theme.palette.primary.main,
  },
}));

type Props = {
  title: string;
  href?: string;
  to?: string;
  actions?: React.ReactNode;
};

const PageHeader = ({ title, href = "/", to, actions }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation(); //translation
  return (
    <div className={classes.root}>
      {!!to ? (
        <Link to={to} className={classes.homeIcon}>
          {to !== "/" && (
            <span style={{ display: "flex", justifyContent: "center" }}>
              <i className="material-icons">arrow_back_ios</i>{" "}
              <span style={{ marginTop: "0.3em" }}>{t("Back")}</span>
            </span>
          )}
        </Link>
      ) : (
        <a href={href} className={classes.homeIcon}>
          {href !== "/" && (
            <span style={{ display: "flex", justifyContent: "center" }}>
              <i className="material-icons">arrow_back_ios</i>{" "}
              <span style={{ marginTop: "0.3em" }}>{t("Back")}</span>
            </span>
          )}
        </a>
      )}
      <Typography variant="h1" className={classes.title}>
        {title}
      </Typography>
      {actions}
    </div>
  );
};

export default PageHeader;
