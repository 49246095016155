import { Theme } from "@mui/material";
import { Card, Typography, } from "@mui/material";
import { makeStyles, createStyles } from '@mui/styles';
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Guid } from "../../system/guid";

type Props = {};

const useStyles = makeStyles(({ spacing }: Theme) =>
  createStyles({
    root: {
      textDecoration: "none",
    },
    checklistCard: {
      padding: spacing(3),
    },
    text: {
      opacity: 0.6,
    },
  })
);

const ChecklistCardEmpty = (p: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Card key={Guid.newGuid().toString()} className={classes.checklistCard}>
      <Typography className={classes.text}>{`${t("checklist.checklistCard.empty")}`}</Typography>
    </Card>
  );
};

export default ChecklistCardEmpty;
