import {
  Grid,
  Typography,
  Theme,
  Paper,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
import { makeStyles, createStyles } from '@mui/styles';
import * as React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChecklistCard from "./ChecklistCard";
import ChecklistCardEmpty from "./ChecklistCardEmpty";
import CompletedSessionCard from "./CompletedSessionCard";
import AllwaysChecklistCard from "./AllwaysChecklistCard";

const useStyles = makeStyles(({ spacing, palette }: Theme) =>
  createStyles({
    root: {},
    container: {
      marginBottom: spacing(2),
    },
    lane: {
      padding: spacing(2),
      minHeight: spacing(5),
      marginBottom: spacing(5),
    },
    laneTitle: {
      textTransform: "uppercase",
      fontWeight: 500,
      opacity: 1,
      fontSize: "1.5em",
      color: palette.common.white,
    },
  })
);

type Props = {
  title: string;
  schedules: any[];
  refetch?: () => void;
  isExpanded?: boolean;
  isInProgress?: boolean;
  isCompleted?: boolean;
  timeLabel?: string;
  timeField?: string;
};

const AllwaysSchedulesBox: React.FC<Props> = ({
  title,
  schedules,
  refetch = () => { },
  isExpanded = true,
  isInProgress = false,
  isCompleted = false,
  timeLabel,
  timeField,
}) => {
  const classes = useStyles();

  const [expanded, setExpanded] = React.useState<boolean>(isExpanded);

  return (
    <div className={classes.container}>
      <Paper elevation={0} className={classes.lane} style={{ backgroundColor: '#828282' }}>
        <Accordion
          expanded={expanded}
          onChange={() => setExpanded(!expanded)}
          style={{ backgroundColor: "transparent", boxShadow: "none" }}
        >
          <AccordionSummary
            style={{ display: "flex" }}
            expandIcon={<ExpandMoreIcon style={{ fill: "white" }} />}
            aria-label="Expand"
          >
            <Typography className={classes.laneTitle} gutterBottom>
              {title}
            </Typography>
          </AccordionSummary>
          <AccordionDetails style={{ display: "flex" }}>
            <Grid container spacing={2}>
              {schedules.length == 0 && (
                <Grid item xs={12} key={1}>
                  <ChecklistCardEmpty />{" "}
                </Grid>
              )}
              {schedules.map((item, key) => {
                return (
                  <Grid item xs={12} md={4} key={`${key}${item.sessionId}`}>
                      <AllwaysChecklistCard
                        checklist={item}
                        inProgress={isInProgress}
                        timeLabel={timeLabel}
                        timeField={timeField}
                      />
                  </Grid>
                );
              })}
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Paper>
    </div>
  );
};

export default AllwaysSchedulesBox;
