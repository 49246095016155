import {
  Component,
  ViewEncapsulation,
  OnInit,
} from "@angular/core";
import { ServiceBusService } from "./system/service-bus.service";
import { Chanels } from "./chanels";
import { UserSiteService } from "./site/user-site.service";
import { SwPush } from "@angular/service-worker";
import {
  pushNotificationsEnabled,
  sendSubscription,
} from "./notificationService";
import { Router } from "@angular/router";
import { SiteService } from "./entity/site.service";
import { MsalService } from "@azure/msal-angular";
import { setAxiosInterceptors } from "./security/axiosInterceptors";
import axios from "axios";
import { getUserClaims, isAuthenticated } from "./security/token";
import { initUserProfileService } from "./security/userProfileService";
import { signatureApi } from "./checklist-react/services/signatureService";

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  title = "app";
  shouldShowMenu: boolean = isAuthenticated() !== null && isAuthenticated()?.length > 0;

  readonly VAPID_PUBLIC_KEY =
    "BMRoNhVTCia4hpEgyRHXBw2rohy9_X2jT8479fgSOWv29MDvrZcmiuABWu1pVBoQdSDoEe42uyBICde5yFL3fgg";

  public user: any;

  constructor(
    private router: Router,
    private swPush: SwPush,
    private msalService: MsalService,
    private bus: ServiceBusService,
    private userSiteService: UserSiteService,
    private siteService: SiteService
  ) {
    if(isAuthenticated()) {
      setAxiosInterceptors(axios);
      setAxiosInterceptors(signatureApi);
      const claims = getUserClaims();
      this.user = {
        ...claims,
        oid: claims.id,
      };
      const siteId = this.initSiteId();
      this.bus.publish(Chanels.USER_CHANEL, {
        adalUser: this.user,
        siteId,
      });
      const { getOwnUserProfile } = initUserProfileService();
      getOwnUserProfile()
      .then(profile => {
        this.user = {
          ...profile,
          oid: profile.id,
          sites: profile.siteIds,
          btennant: profile.tenantIds,
        };
        this.bus.publish(Chanels.USER_CHANEL, {
          adalUser: this.user,
          siteId,
        });
        this.initPushNotifications(siteId);
      })
      .catch(() => {
        console.error("Failed to fetch user profile.")
      });
    }
  }

  ngOnInit() {}

  logout() {
    this.msalService.logout();
  }

  private initSiteId(): string {
    const siteId = this.userSiteService.getSiteId();
    this.siteService.getSites().subscribe((fetchedSites) => {
      const fetchedSiteIds = fetchedSites.map((site) => site.id.toString());
      const hasAccessToSite = fetchedSiteIds.length > 0 &&
        fetchedSiteIds
          .map((sid) => sid.toLowerCase())
          .includes(siteId.toLowerCase());
      if(!hasAccessToSite) {
        this.userSiteService.setSiteId(fetchedSiteIds[0]);
        window.location.reload();
      }
    });
    return siteId;
  }

  private initPushNotifications(siteId: string) {
    console.log(pushNotificationsEnabled());
    pushNotificationsEnabled() &&
      this.swPush
        .requestSubscription({
          serverPublicKey: this.VAPID_PUBLIC_KEY,
        })
        .then((sub) => {
          console.log("Subscription object", sub);

          const tenantId = this.user.btennant[0];
          if (!tenantId) {
            console.log(
              `Invalid tenantId ${tenantId} for user object `,
              this.user
            );
            return;
          }
          sendSubscription(
            tenantId,
            siteId.toLocaleLowerCase(),
            this.user.profile.oid,
            JSON.parse(JSON.stringify(sub))
          )
            .then(() => console.log("Subscription successfully sent."))
            .catch((e) => console.log(`Subscription not sent: `, e));
        })
        .catch((err) =>
          console.error("Could not subscribe to notifications", err)
        );

    const actionHandlers = {
      "handle-checklist-deviation": (notification) => {
        this.router.navigate(["/checklist/dashboard/deviations"]);
      },
    };

    pushNotificationsEnabled() &&
      this.swPush.notificationClicks.subscribe(({ action, notification }) => {
        console.log("Received notification", action, notification);
        const actionHandler = actionHandlers[action];
        if (actionHandler) {
          actionHandler(notification);
          console.log("Handled notification", action, notification);
        }
      });
  }
}
