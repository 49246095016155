import axios from "axios";
import { environment } from "../../../environments/environment";

const BASE_NOTIFICATIONS_URL = environment.dependencies.notifications_api.url + "/api";

export type UserSubscribedTopic = {
  topicKey: string;
  id: string;
}

export async function useSubscribedTopicsFor(userId: string): Promise<UserSubscribedTopic[]> {
  return (await axios.get(`${BASE_NOTIFICATIONS_URL}/usersubscriptions/users/${userId}/topics`)).data;
}

export function useSubscribeToTopic(userId: string, topic: UserSubscribedTopic): Promise<any> {
  return axios.post(`${BASE_NOTIFICATIONS_URL}/usersubscriptions/users/${userId}/topics/subscribe`, topic);
}

export function useUnsubscribeToTopic(userId: string, topic: UserSubscribedTopic): Promise<any> {
  return axios.post(`${BASE_NOTIFICATIONS_URL}/usersubscriptions/users/${userId}/topics/unsubscribe`, topic);
}