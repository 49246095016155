import { Injectable } from '@angular/core';
import { TempReport } from './tempreport';
import { Deviation } from './deviation';
import { Measurement } from '../measurements/measurement';
import { Entity } from '../entity/entity';
import { SiteEntities } from '../entity/siteentities';
import { FormBuilder, FormGroup } from '@angular/forms';

@Injectable()
export class DataService {
	private tempRep: TempReport;
	private deviation: Deviation;
	private measurements: Measurement;
	private siteEntities: SiteEntities;
	private tempReportForm: FormGroup;

	setTempReport(tempRep: any){
		this.tempRep = tempRep;
		localStorage.setItem('tempReportKey', JSON.stringify(this.tempRep));
	}

	getTempReport(): TempReport{
		if(!this.tempRep){
			let tempRep = localStorage.getItem('tempReportKey');
			this.tempRep = TempReport.deSerialize(JSON.parse(tempRep));
		}
		return this.tempRep;
	}
	setTempReportForm( form: FormGroup){
		this.tempReportForm= form;
	}
	getTemReportForm():FormGroup{
		
		return this.tempReportForm;
	}

	// setDeviation(deviation: Deviation){
	// 	this.deviation = deviation;
	// 	localStorage.setItem('deviationKey', JSON.stringify(this.deviation));
	// }

	// getDeviation(){
	// 	let storedDeviation = localStorage.getItem('deviationKey');
	// 	return JSON.parse(storedDeviation);
	// }

	// setMeasurements(measurements: Measurement){
	// 	this.measurements = measurements;
	// 	localStorage.setItem('measurementsKey', JSON.stringify(this.measurements));
	// }

	// getMeasurements(){
	// 	let storedMeasurements = localStorage.getItem('measurementsKey');
	// 	return JSON.parse(storedMeasurements);
	// }

	setSiteEntities(siteEntities: SiteEntities){
		this.siteEntities = siteEntities;
		localStorage.setItem('siteEntitiesKey', JSON.stringify(this.siteEntities));
	}

	getSiteEntities(): SiteEntities{
		let storedSiteEntities = localStorage.getItem('siteEntitiesKey');
		var json = JSON.parse(storedSiteEntities);
		return SiteEntities.create(SiteEntities.create(json));
	}

	constructor()  { }
}
