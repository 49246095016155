import { Guid } from "../system/guid";
import { Moment } from "moment";
import { RegisterSignal } from "../signals/registersignal";
import { TermoObject } from "./termo-object";
import { OpenCloseObject } from "./open-close-object";
import { HumidityFormComponent } from "./humidity-form/humidity-form-component";
import { ReptileObject } from "./reptile-object";
import {
  ObjectImage,
  ServiceHoursDefinition,
} from "../edit-entity-form-react/interfaces";

export class Entity {
  // public signal: RegisterSignal[];
  constructor(
    public id: string,
    public name: string,
    public siteId: string,
    public tenantId: string,
    public obj: any,
    public type: string,
    public createdOn: Moment,
    public disabled: boolean,
    public hide: boolean,
    public serviceHours: ServiceHoursDefinition[],
    public signal?: RegisterSignal[],
    public objectImages?: ObjectImage[]
  ) {}
  public static create(o: any): Entity {
    return new Entity(
      o.id,
      o.name,
      o.siteId,
      o.tenantId,
      o.obj,
      o.type,
      o.createdOn,
      o.disabled,
      o.hide,
      o.serviceHours,
      o.signal,
      o.objectImages
    );
  }

  public toString(): string {
    return `Name: ${this.name} ${
      this.signal && this.signal.length > 0 ? this.signal[0].Value : ""
    }`;
  }

  public getValue(): any {
    return this.obj;
  }

  public getName(): string {
    return this.name;
  }

  public getId(): string {
    return this.id.toString();
  }

  public isTermo(): boolean {
    return this.type === TermoObject.typeName;
  }
  public isOpenClose(): boolean {
    return this.type === OpenCloseObject.typeName;
  }
  public isReptil(): boolean {
    return this.type === ReptileObject.typeName;
  }
  paramMap: any = {
    TermoObject: "termo",
    OpenCloseObject: "door",
    ReptileObject: "reptil",
  };
  public getQueryParam(): string {
    var map = this.paramMap[this.type];
    return map || "";
  }

  public printType(): string {
    console.log("print type!");
    if (this.isTermo()) {
      return "Temperatur";
    } else if (this.isOpenClose()) {
      return "Dør";
    } else if (this.isReptil()) {
      return "Reptil";
    }

    return this.type;
  }
  // public isTouch(): boolean {
  //   return this.getEntityType() === EntityType.Touch;
  // }
}
