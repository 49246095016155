import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-submit-confirm',
  templateUrl: './submit-confirm.component.html',
  styleUrls: ['./submit-confirm.component.scss']
})
export class SubmitConfirmComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
