import { Component, OnInit } from "@angular/core";
import { MatBottomSheetRef } from "@angular/material/bottom-sheet";

@Component({
  selector: "app-date-select",
  templateUrl: "./date-select.component.html",
  styleUrls: ["./date-select.component.scss"],
})
export class DateSelectComponent implements OnInit {
  constructor(private bottomSheetRef: MatBottomSheetRef<DateSelectComponent>) {}

  ngOnInit() {}

  confirmDate(event: MouseEvent): void {
    this.bottomSheetRef.dismiss();
  }
}
