import { Component, OnInit, Input } from "@angular/core";
import { TempReport, ReportState, ReportStatus } from "../../tempreport";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { TempreportService } from "../../tempreport.service";
import { Entity } from "../../../entity/entity";
import { take } from "rxjs/operators";
import {
  Measurement,
  UnitTypeTemp,
  SourceIdManual,
} from "../../../measurements/measurement";
import { Guid } from "../../../system/guid";
import { Deviation } from "../../deviation";
import * as moment from "moment";

const validTempNumberPattern = /^-?[0-9]+([,.][0-9])?[0-9]*$/g;

@Component({
  selector: "value-form",
  templateUrl: "./value-form.component.html",
  styleUrls: ["./value-form.component.scss"],
})
export class ValueFormComponent implements OnInit {
  @Input()
  public entity: Entity;
  @Input()
  public siteId: any;

  public errorMessage: string;

  public entityForm: FormGroup;
  public isActionRequired = false;
  public isDelivered = false;
  public showQuickButtons = false;
  public lastDelivered: LastDeliveredInfo;
  public commonDeviationCauses = [
    { label: 'Tomt', cause: 'Det er tomt' },
    { label: 'Vaske & rydde', cause: 'Vi har gjort rengjøring' },
    { label: 'Varelevering', cause: 'Vi har fått inn nye varer' },
    { label: 'Under tilberedning', cause: 'Under tilberedning' },
  ];

  constructor(
    private fb: FormBuilder,
    private tempReportService: TempreportService
  ) {}

  ngOnInit() {
    var min = Number(this.entity.obj.rules.temperature.min);
    var max = Number(this.entity.obj.rules.temperature.max);

    if(this.entity.obj.termoObjectType?.commonDeviationCauses) {
      this.commonDeviationCauses = this.entity.obj.termoObjectType?.commonDeviationCauses;
    }

    this.showQuickButtons = min > 50;

    this.entityForm = this.fb.group({
      id: [this.entity.id],
      temp: [
        "",
        [Validators.required, Validators.pattern(validTempNumberPattern)],
      ],
      action: [""],
    });

    this.entityForm.get("temp").valueChanges.subscribe((val) => {
      var numVal = Number(val);
      this.isActionRequired =
        !!val &&
        val.match(validTempNumberPattern) &&
        (numVal < min || numVal > max);
      this.showQuickButtons = this.showQuickButtons && val === "";
    });

    this.checkLastDelivered();
  }
  public setAction(action: string) {
    this.entityForm.patchValue({ action });
  }

  public setTempValueOver() {
    this.entityForm.patchValue({
      temp: (
        parseFloat(this.entity.obj.rules.temperature.min) + 0.7
      ).toString(),
    });
  }

  public setTempValueUnder() {
    this.entityForm.patchValue({
      temp: (
        parseFloat(this.entity.obj.rules.temperature.min) - 4.3
      ).toString(),
    });
  }

  private checkLastDelivered() {
    this.tempReportService
      .getDeliveredForEntity(this.siteId, this.entity.id, null, true)
      .pipe(take(1))
      .pipe(take(1))
      .subscribe((report) => {
        let measurement = report
          .getMeasurements()
          .find((m) => m.entityId == this.entity.id);
        let value = measurement.value;

        if (!value) {
          return;
        }

        let date = moment(report.createdOn);
        this.lastDelivered = new LastDeliveredInfo(date.fromNow(), value);
      });
  }

  onSubmit(entityForm: FormGroup) {
    this.errorMessage = null;

    var values = entityForm.value;
    var temp = values.temp.replace(",", ".");
    var action = this.isActionRequired ? values.action : undefined;

    var min = Number(this.entity.obj.rules.temperature.min);
    var max = Number(this.entity.obj.rules.temperature.max);

    let validTempInput = temp.match(validTempNumberPattern);
    let numVal = Number(temp);
    var isTempOk = numVal >= min && numVal <= max;
    var isValidAction = isTempOk || (action !== undefined && action.length > 0);
    var deliver = (validTempInput && isValidAction) === true;

    // console.log(`Deliver: ${deliver}. Temp ${temp} is valid: ${isTempOk}. Min: ${min}, max: ${max}, action: ${action}`);

    if (!deliver) {
      // Something is invalid, errors should be displayed by now
      return;
    }

    let entityId = this.entity.getId();

    this.tempReportService
      .getManual(this.siteId, entityId)
      .pipe(take(1))
      .subscribe(
        (report: TempReport) => {
          let occuredTime = report.getOccuredTime();
          report.addMeasurement(
            new Measurement(
              Guid.newGuid().toString(),
              occuredTime,
              entityId,
              SourceIdManual,
              temp,
              UnitTypeTemp
            )
          );
          report.setState(ReportState.Compleeted);

          if (!isTempOk) {
            report.addDeviation(
              new Deviation(
                Guid.newGuid().toString(),
                entityId,
                occuredTime,
                action,
                ReportState.Compleeted
              )
            );
            report.setStatus(ReportStatus.Deviations);
          }

          // console.log("Register new report:", report);

          this.tempReportService
            .registerTempReport(report)
            .pipe(take(1))
            .subscribe(
              (res) => {
                this.isDelivered = true;
                this.checkLastDelivered();
              },
              (err) => {
                console.log("Error registering new report", err);
                this.errorMessage =
                  "Det oppstod en feil (reg1), vennligst prøv igjen.";
              }
            );
        },
        (err) => {
          console.log("Error retrieving manual report", err);
          this.errorMessage =
            "Det oppstod en feil (reg2), vennligst prøv igjen.";
        }
      );
  }
}

class LastDeliveredInfo {
  constructor(public timestamp: string, public value: any) {}
}
