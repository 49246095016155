import * as React from "react";
import ViewChecklist from "./ViewChecklist";

const ChecklistOverview = () => {
  return (
    <div>
      <ViewChecklist showSchedule></ViewChecklist>
    </div>
  );
};

export default ChecklistOverview;
