import * as React from "react";
import { useEffect, useState, useContext } from "react";
import { FieldArray, Form, Formik } from "formik";
import FormikField from "../../react-components/FormikField";
import {
  Box,
  Button,
  IconButton,
  Snackbar,
  Theme,
  Typography,
  Switch,
  Checkbox,
} from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import RemoveIcon from "@mui/icons-material/Delete";
import { AlarmContact, Site } from "../../entity/site";
import { SiteSettingsContext } from "../SiteSettingsReactWrapper";
import { take } from "rxjs/operators";
import { Alert } from "@mui/lab";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import ServiceHoursDefinitionDialog from "../../edit-entity-form-react/components/ServiceHoursDefinitionDialog";
import { ServiceHoursDefinition } from "../../edit-entity-form-react/interfaces";
import Loading from "../../react-components/Loading";
import { useTenantService } from "../../tenantService";
import PageHeader from "../../react-components/PageHeader";

type IValues = {
  name: string;
  showLowPriorityRoutinesTab?: boolean;
  showLowPriorityRoutinesTabTenant?: boolean;
  alarmContacts: AlarmContact[];
};

const styles = ({ spacing }: Theme) =>
  createStyles({
    root: {},
    inputSiteName: {
      maxWidth: "300px",
      width: "100%",
    },
    button: {
      marginBottom: spacing(2),
    },
  });

const useStyles = makeStyles(styles);

const SiteForm = () => {
  const { siteService, user } = useContext(SiteSettingsContext);
  const { t } = useTranslation();
  const classes = useStyles();

  const [site, setSite] = useState<Site>();
  const [open, setOpen] = useState<boolean>(false);
  const [alarmContactsActiveIndex, setAlarmContactsActiveIndex] =
    useState<number>(0);
  const [serviceHoursDialogOpen, setServiceHoursDialogOpen] =
    useState<boolean>(false);

  useEffect(() => {
    siteService
      .getSite(user.siteId)
      .pipe(take(1))
      .subscribe((res: Site) => {
        setSite(res);
      });
  }, []);

  const validation = Yup.object().shape({
    name: Yup.string().required(t("site.smsRecipient.required")),
    alarmContacts: Yup.array()
      .of(
        Yup.object().shape({
          isActive: Yup.boolean().required(t("siteForm.smsRecipient.required")),
          phoneNumber: Yup.string().required(t("siteForm.smsRecipient.required")),
          name: Yup.string().required(t("siteForm.smsRecipient.required")),
        })
      )
      .test("unique", null, function (list) {
        const mapper = (x) => x.phoneNumber;
        const set = [...new Set(list.map(mapper))];
        const isUnique = list.length === set.length;
        if (isUnique) {
          return true;
        }
        const idx = list.findIndex((l, i) => mapper(l) !== set[i]);
        return this.createError({
          path: `alarmContacts.[${idx}].phoneNumber`,
          message: t("siteForm.smsRecipient.unique"),
        });
      }),
  });

  const tenantService = useTenantService();

  const [tenant, setTenant] = useState<any>();

  useEffect(() => {
    if (!site?.tenantId) return;
    tenantService
      .getOne(site.tenantId.toString())
      .then((res) => {
        setTenant(res);
      });
  }, [site?.tenantId]);

  console.log(site, tenant)

  const handleSave = (values: IValues) => {
    siteService
      .putSite(site.id, {
        ...site,
        name: values.name,
        showLowPriorityRoutinesTab: values.showLowPriorityRoutinesTab == null ? null : values.showLowPriorityRoutinesTab,
        alarmContacts: values.alarmContacts,
      })
      .pipe(take(1))
      .subscribe(
        () => setOpen(true),
        (error) => console.log(error)
      );

    if ('showLowPriorityRoutinesTabTenant' in values) {
      tenantService.put(site.tenantId.toString(), {
        ...tenant,
        showLowPriorityRoutinesTab: values.showLowPriorityRoutinesTabTenant == null ? null : values.showLowPriorityRoutinesTabTenant,
      });
    }
  };

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  if (site && tenant) {
    const intitialValues: IValues = {
      name: site.name,
      showLowPriorityRoutinesTab: site.showLowPriorityRoutinesTab,
      showLowPriorityRoutinesTabTenant: tenant.showLowPriorityRoutinesTab,
      alarmContacts: site.alarmContacts || [
        { name: "Default", phoneNumber: "90558192", isActive: true },
      ],
    };

    return (
      <>
        <Formik
          initialValues={intitialValues}
          onSubmit={handleSave}
          validationSchema={validation}
        >
          {({ values, setFieldValue }) => {
            // if showLowPriorityRoutinesTab is null, it means that the site has not set the value - use tenant default
            const useTenantDefault = values.showLowPriorityRoutinesTab == null;
            // showLowPriorityRoutinesTab is true if the site has not set the value (default to true) or if the site has set the value to true
            const siteChecked = values.showLowPriorityRoutinesTab === true || values.showLowPriorityRoutinesTab == null;
            // showLowPriorityRoutinesTabTenant is true if the tenant has not set the value (default to true) or if the tenant has set the value to true
            const tenantChecked = values.showLowPriorityRoutinesTabTenant === true || values.showLowPriorityRoutinesTabTenant == null;
            return (
              <>
                <Form>
                  <FormikField label={t("siteForm.siteName")} name="name" className={classes.inputSiteName} />
                  <FieldArray
                    name="alarmContacts"
                    render={(arrayHelpers) => (
                      <div>
                        <Typography>{t("siteForm.smsRecipients")}</Typography>
                        {values.alarmContacts &&
                          values.alarmContacts.map((number, index) => (
                            <Box key={index} display="flex">
                              <FormikField
                                label={t("siteForm.smsRecipient.name")}
                                disabled={!values.alarmContacts[index].isActive}
                                name={`alarmContacts.${index}.name`}
                              />
                              <FormikField
                                label={t("siteForm.smsRecipient.phoneNumber")}
                                disabled={!values.alarmContacts[index].isActive}
                                name={`alarmContacts.${index}.phoneNumber`}
                              />
                              <div>
                                <Switch
                                  checked={values.alarmContacts[index].isActive}
                                  onChange={() =>
                                    setFieldValue(
                                      `alarmContacts.${index}.isActive`,
                                      !values.alarmContacts[index].isActive
                                    )
                                  }
                                />
                              </div>
                              <div>
                                <IconButton
                                  onClick={() => {
                                    setAlarmContactsActiveIndex(index);
                                    setServiceHoursDialogOpen(true);
                                  }}
                                >
                                  <AccessAlarmIcon />
                                </IconButton>
                              </div>
                              <div>
                                <IconButton
                                  onClick={() => arrayHelpers.remove(index)}
                                >
                                  <RemoveIcon />
                                </IconButton>
                              </div>
                            </Box>
                          ))}
                        <Button
                          onClick={() =>
                            arrayHelpers.push({ name: "", phoneNumber: "", isActive: true })
                          }
                          variant="outlined"
                        >
                          {t("siteForm.smsRecipient.addRecipient")}
                        </Button>
                      </div>
                    )}
                  />
                  <div className={classes.button}
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "flex-end",
                      overflow: "hidden",
                    }}
                  >
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                    >
                      {t("siteForm.save")}
                    </Button>
                  </div>
                </Form>

                <hr style={{ marginBottom: "2rem" }} />
                <PageHeader title={t("SettingsComponent.routinesTitle")} />
                <Form>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexWrap: "wrap"
                    }}
                  >
                    <div>
                      <span>{t("siteForm.showLowPriorityRoutinesTab")}</span>
                      <Switch
                        checked={siteChecked}
                        onChange={(_, checked) =>
                          setFieldValue(
                            `showLowPriorityRoutinesTab`,
                            checked
                          )
                        }
                      />
                    </div>
                    <div>
                      <span> - </span>
                      <Checkbox
                        checked={useTenantDefault}
                        onChange={(_, checked) =>
                          setFieldValue(
                            `showLowPriorityRoutinesTab`,
                            checked ? undefined : true
                          )
                        }
                      />
                      <span> {t('siteForm.useTenantDefault')} </span>
                      <span>(<Switch
                        checked={tenantChecked}
                        onChange={(_, checked) =>
                          setFieldValue(
                            `showLowPriorityRoutinesTabTenant`,
                            checked
                          )
                        }
                      />)</span>
                    </div>
                  </div>

                  <div className={classes.button}
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "flex-end",
                      overflow: "hidden",
                    }}
                  >
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                    >
                      {t("siteForm.save")}
                    </Button>
                  </div>
                </Form>

                <ServiceHoursDefinitionDialog
                  open={serviceHoursDialogOpen}
                  onClose={() => setServiceHoursDialogOpen(false)}
                  limits={[]}
                  values={
                    values.alarmContacts[alarmContactsActiveIndex]
                      ?.sendSmsHours ?? []
                  }
                  setValues={(
                    valuesCallback: (
                      vals: ServiceHoursDefinition[]
                    ) => ServiceHoursDefinition[]
                  ) => {
                    console.log(
                      valuesCallback(
                        values.alarmContacts[alarmContactsActiveIndex]
                          ?.sendSmsHours
                      )
                    );
                    setFieldValue(
                      `alarmContacts.${alarmContactsActiveIndex}.sendSmsHours`,
                      valuesCallback(
                        values.alarmContacts[alarmContactsActiveIndex]
                          ?.sendSmsHours
                      )
                    );
                  }}
                  handleSave={() => {
                    setServiceHoursDialogOpen(false);
                  }}
                  translationRootKey="siteForm.recipientSmsHours"
                />
              </>
            )
          }}
        </Formik>
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          sx={{ height: "100%" }}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert onClose={handleClose} severity="success">
            <b>{t("siteForm.saved")}</b>
          </Alert>
        </Snackbar>
      </>
    );
  }

  return <Loading />;
};

export default SiteForm;
