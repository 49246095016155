<div class="_content">
  <div class="content__heading">
    <a class="content__heading__go-back" [routerLink]="['/']">
      <i class="material-icons">home</i>
    </a>
    <h1>
      {{
        isOnlyManualEntities
          ? ("mainMenu.manualMeasurements" | i18next)
          : ("mainMenu.controlMeasurements" | i18next)
      }}
    </h1>
  </div>
  <div class="loading-spinner" *ngIf="isLoading">
    <mat-spinner></mat-spinner>
  </div>
  <div *ngFor="let entity of entities; let entityIndex = index">
    <value-form [entity]="entity" [siteId]="siteId"></value-form>
  </div>

  <div *ngIf="!isLoading && entities.length == 0">
    {{
      isOnlyManualEntities
        ? ("No manual devices to display." | i18next)
        : ("No devices to display." | i18next)
    }}
  </div>
</div>
