import { Component, OnInit } from "@angular/core";
import { EntityService } from "../../entity/entity.service";
import { SiteEntities } from "../../entity/siteentities";
import { MatDialog } from "@angular/material/dialog";
import { Entity } from "../../entity/entity";
import { ServiceBusService } from "../../system/service-bus.service";
import { Chanels } from "../../chanels";
import * as moment from "moment";
import { take } from "rxjs/operators";
import { RouterOutlet } from "@angular/router";
import { OrderEntitiesByNamePipe } from "../../order-entities-by-name.pipe";
import { TempreportService } from "../tempreport.service";
import { TempReport } from "../tempreport";

@Component({
  selector: "app-manual-report",
  templateUrl: "./manual-report.component.html",
  styleUrls: ["./manual-report.component.scss"],
})
export class ManualReportComponent implements OnInit {
  public entities: Entity[] = [];
  id: any;
  public siteId: any;

  public isOnlyManualEntities: boolean;
  public isLoading = true;
  private entitiesLoadingCount = 0;
  private snapshotReport: TempReport;

  constructor(
    private entityService: EntityService,
    // private signalsService: SignalsService,
    private tempreportService: TempreportService,
    private bus: ServiceBusService,
    private routerOutlet: RouterOutlet,
    public dialog: MatDialog,
    private orderEntitiesByName: OrderEntitiesByNamePipe
  ) {}

  ngOnInit() {
    this.isOnlyManualEntities =
      this.routerOutlet.activatedRouteData.isOnlyManualReports === true;
    this.loadEntities();
  }

  public printDate(dateObj: any) {
    let date = moment(dateObj);
    return `kl. ${date.format("HH:mm DD.MM.YYYY.")}`;
  }

  loadEntities() {
    this.bus.subscribe(Chanels.USER_CHANEL, (user) => {
      if (user == null) return;
      this.siteId = user.siteId;

      this.entityService
        .getSiteEntities(user.siteId)
        .pipe(take(1))
        .subscribe((data: SiteEntities) => {
          this.tempreportService
            .getSnapshot(this.siteId)
            .pipe(take(1))
            .subscribe((tempReport) => {
              this.snapshotReport = tempReport;
              this.checkAddEntities(data);
            });

          // this.checkAddEntities(data);
        });
    });
  }

  private checkAddEntities(siteEntities: SiteEntities) {
    let entities = siteEntities.getEntities();
    this.entitiesLoadingCount = entities.length;

    this.entities = [];

    entities.forEach((entity: Entity) => {
      if (!entity.isTermo() || entity.disabled || entity.hide) {
        this.loadingEntityFinished();
        return;
      }

      if (!this.isOnlyManualEntities) {
        // Show all entities, no need to check if manual.
        this.entities.push(entity);
        this.loadingEntityFinished();
        return;
      }

      let isManual = this.snapshotReport
        .getMeasurements()
        .some((m) => m.entityId === entity.id && !m.value);

      if (isManual) {
        this.entities.push(entity);
      }
      this.loadingEntityFinished();

      // this.signalsService
      //   .getTags(entity.id.toString())
      //   .pipe(take(1))
      //   .subscribe(
      //     (tags: Array<SignalTag>) => {
      //       let isManual = tags.length === 0;

      //       if (isManual) {
      //         this.entities.push(entity);
      //       }
      //       this.loadingEntityFinished();
      //     },
      //     (error) => {
      //       if (error.status === 404) {
      //         // No tag signals, manual
      //         // console.log(entity.name + ": did not get signals, assume manual entity");
      //         this.entities.push(entity);
      //       } else {
      //         console.log("Error getting signals:", error);
      //       }
      //       this.loadingEntityFinished();
      //     }
      //   );
    });
  }

  private loadingEntityFinished() {
    this.entitiesLoadingCount--;
    this.isLoading = this.entitiesLoadingCount > 0;

    if (!this.isLoading) {
      this.entities = this.orderEntitiesByName.transform(
        this.entities,
        undefined
      );
    }
  }
}
