import { Box, Button, ClickAwayListener, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material"
import React, { useEffect } from "react"
import { PointLabelType } from "./PointLabelType";
import { ChecklistPointEvent, ChecklistPointEventType } from "./ChecklistPointEventHandler";
import { useTranslation } from "react-i18next";

type Props = {
    open: boolean;
    point: any;
    checklistId: any;
    tenantId: any;
    existingOptions: string[];
    labelsOnPoint: string[];
    isSiteSpecific: boolean;
    assignedId?: string;
    onClose: () => void;
    onSubmit: (event: ChecklistPointEvent) => void;
}

export const AddLabelDialog = ({open, point, tenantId, checklistId, existingOptions, labelsOnPoint, onClose, onSubmit, isSiteSpecific, assignedId}: Props) => {

    const [selectedLabels, setSelectedLabels] = React.useState<string[]>([]);
    const { t } = useTranslation();

    const HandleLabelsSubmitted = () => {
        const removedLabels = labelsOnPoint.filter((existingLabel) => !selectedLabels.includes(existingLabel));
        const addedLabels = selectedLabels.filter((label) => !labelsOnPoint.includes(label));
      
        const submitLabelEvent = (type, labelType) => {
          onSubmit({
            type,
            data: {
              labelType,
              pointId: point.id,
              tenantId,
              checklistId,
              ...(isSiteSpecific && { assignedId }),
            },
          });
        };
      
        addedLabels.forEach((addedLabel) => {
          const eventTypeToAdd = isSiteSpecific ? ChecklistPointEventType.AssignedLabelAdded : ChecklistPointEventType.LabelAdded;
          submitLabelEvent(eventTypeToAdd, addedLabel);
        });
      
        removedLabels.forEach((removedLabel) => {
          const eventTypeToRemove = isSiteSpecific ? ChecklistPointEventType.AssignedLabelRemoved : ChecklistPointEventType.LabelRemoved;
          submitLabelEvent(eventTypeToRemove, removedLabel);
        });
      
        onClose();
      };
      

    return (
        <Dialog fullWidth maxWidth="xs" open={open} >
                <Box height={280}>
                    <DialogTitle>{`${t("editChecklist.addLabel")}`}</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <PointLabelType
                                onValuesChanged={(values) => setSelectedLabels(values)}
                                labelOptions={existingOptions}
                                existingLabels={labelsOnPoint}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Box>
                <DialogActions>
                    <Button variant="outlined" onClick={() => onClose()}>
                        {`${t("editChecklist.cancel")}`}
                    </Button>
                    <Button type="submit" variant="contained" color="primary" onClick={() => HandleLabelsSubmitted()}>
                        {`${t("editChecklist.submit")}`}
                    </Button>
                </DialogActions>
         </Dialog>
    )
}