import {
  Component,
  OnInit,
  ViewChild,
  EventEmitter,
  Input,
  ViewContainerRef,
  ComponentFactoryResolver,
  ComponentFactory,
  ComponentRef
} from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Observable, catchError, throwError } from "rxjs";
import { Entity } from "../entity";
import { Guid } from "../../system/guid";
import { EntityService } from "../entity.service";
import { ActivatedRoute, Router } from "@angular/router";
import { TermoObject } from "../termo-object";
import * as moment from "moment";
import { ServiceBusService } from "../../system/service-bus.service";
import { Chanels } from "../../chanels";
import { SiteService } from "../site.service";
import { Site } from "../site";
import { SignalsService } from "../../signals/signals.service";
import { RegisterSignal } from "../../signals/registersignal";
import { SignalTag, SignalTagType, SignalTagFunction, printSignalTagFunction } from "../../signals/vevent";
import { IEntitySpecificsFormHandler } from "../site.specifics.form.handler";
import { OpenCloseObject } from "../open-close-object";
import { ThermoFormComponent } from "../thermo-form/thermo-form-component";
import { OpenCloseFormComponent } from "../open-close-form/open-close-form-component";
import { ReptileObject } from "../reptile-object";
import { HumidityFormComponent } from "../humidity-form/humidity-form-component";

@Component({
  selector: "app-edit-entity-form",
  templateUrl: "./edit-entity-form.component.html",
  styleUrls: ["./edit-entity-form.component.scss"]
})
export class EditEntityFormComponent implements OnInit {
  public entityForm: FormGroup;

  private defaultEntityType = TermoObject.typeName; // Default
  public entityType = this.defaultEntityType;

  @Input()
  public entity: Entity = Entity.create({
    id: "",
    name: "Loading",
    siteId: "", //this.siteId,
    tenantId: "",
    obj: this.defaultEntityType,
    type: null,
    createdOn: null,
    hide: false
  });
  public errorMessage: String;

  public entity$: Observable<Entity>;

  private userSub: any;
  private routeSub: any;
  private siteId: any;
  private tenantId: any;

  @Input()
  public entityId: Guid;
  public isNew: boolean;

  @ViewChild('entitySpecificsContainer', { read: ViewContainerRef, static: true }) entry: ViewContainerRef;
  private entitySpecificsFormHandler: IEntitySpecificsFormHandler;

  public entitySpecificsForm: FormGroup;

  public signalFunctions: SignalTagFunctionOption[] = [];
  isSignalSpecified = false;
  selectedSignalFunction: SignalTagFunctionOption = undefined;

  public signals$: EventEmitter<RegisterSignal[]> = new EventEmitter<
    RegisterSignal[]
  >();
  public signals: RegisterSignal[];

  @ViewChild("signalSelect", { static: true }) signalSelect: any;
  constructor(
    private entityService: EntityService,
    private siteService: SiteService,
    private signalService: SignalsService,
    public formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private bus: ServiceBusService,
    private resolver: ComponentFactoryResolver) {
    this.route.queryParams.subscribe(params => {
      let type = params["type"];
      this.entityType = this.GetEntityTypeFromQueryParam(type);
    });
  }

  private GetEntityTypeFromQueryParam(type: any): string {
    if (type === "door") {
      return OpenCloseObject.typeName;
    }
    else if (type === "reptil") {
      return ReptileObject.typeName;
    }
    else if (type ==="termo"){
      return TermoObject.typeName;
    }

    // else if (type === "touch") { // TODO add support for touch object?
    //   return TouchObject.name;
    // }
    return this.defaultEntityType;
  }

  ngOnInit() {
    this.userSub = this.bus.subscribe(Chanels.USER_CHANEL, user => {
      if (user == null) return;
      this.siteId = user.siteId;

      this.routeSub = this.route.params.subscribe(params => {
        this.entityId = params.entityId;

        this.isNew = this.entityId === undefined;
        this.createActionformbuilder();

        if (this.isNew) {
          // Retrieve tenantId
          this.siteService.getSite(this.siteId).subscribe((site: Site) => {
            this.tenantId = site.tenantId;
            this.setupNewEntity();
          });
        } else {
          // Parent will set entity
        }
      });
    });
  }

  ngOnDestroy() {
    if (this.userSub) {
      //TODO userSub should probably never be undefined but it is currently for me. Look into this!
      this.userSub.unsubscribe();
    }
    if (this.routeSub) {
      this.routeSub.unsubscribe();
    }
  }

  onChanges(): void {
    this.entityForm.get("signal").valueChanges.subscribe(val => {
      // Get signals.
      this.isSignalSpecified = !(!val);

      if (!val) return;
      const filter = s => s.sourceId.indexOf(val) >= 0;
      if (!this.signals) {
        this.signalService.getSignals().subscribe(x => {
          this.signals = x;
          this.filterSignals(filter);
        });
      } else {
        this.filterSignals(filter);
      }
      this.signalSelect.open();
    });

    this.entityForm.get("selectedSignal").valueChanges.subscribe(val => {
      // Get signals.
      this.entityForm.get("signal").setValue(val.sourceId);
    });
  }
  private filterSignals(filter: (s: any) => boolean) {
    const filtered = this.signals.filter(filter);
    console.log("Length : " + filtered.length);
    this.signals$.next(filtered);
  }

  private createActionformbuilder() {
    this.entitySpecificsForm = this.formBuilder.group({});

    this.entityForm = this.formBuilder.group({
      name: ["", Validators.required],
      signal: [""],
      selectedSignal: [null],
      entitySpecifics: this.entitySpecificsForm,

      disabled: [""],
      hide: [""]
    });

    this.createEntitySpecificsForm();

    this.onChanges();
  }

  private createEntitySpecificsForm() {
    this.entry.clear();

    const factory = this.getEntitySpecificsHandlerFactory();
    const componentRef = this.entry.createComponent(factory);

    componentRef.instance.rules = this.entitySpecificsForm;

    this.entitySpecificsFormHandler = componentRef.instance;
  }

  private getEntitySpecificsHandlerFactory(): ComponentFactory<IEntitySpecificsFormHandler> {
    console.log("getEntitySpecificsHandler - entityType: " + this.entityType);

    if (this.entityType === TermoObject.typeName) {
      return this.resolver.resolveComponentFactory(ThermoFormComponent);
    }
    if (this.entityType === OpenCloseObject.typeName) {
      return this.resolver.resolveComponentFactory(OpenCloseFormComponent);
    }
    if (this.entityType === ReptileObject.typeName) {
      return this.resolver.resolveComponentFactory(HumidityFormComponent);
    }

    console.log(`Did not find entity handler for ${this.entityType}!!!!`);
    return null;
  }

  setupNewEntity() {
    let entityId = Guid.newGuid().toString();
    let tenantId = this.tenantId;
    let type = this.entitySpecificsFormHandler.getEntityType();
    let name = "";
    let createdOn = moment();

    var object = this.entitySpecificsFormHandler.getEntityObject(
      entityId,
      tenantId,
      createdOn
    );

    this.entity = Entity.create({
      id: entityId,
      name: name,
      siteId: this.siteId,
      tenantId,
      obj: object,
      type,
      createdOn,
      hide: false
    });

    this.setupForEntity();
  }

  public setEntity(entity: Entity) {
    this.entity = entity;
    this.entityType = entity.type;
    this.setupForEntity();
  }

  private setupForEntity() {
      this.entityForm.patchValue({
        name: this.entity.name,
        disabled: this.entity.disabled,
        hide: this.entity.hide
      });
      this.updateEntitySpecificsForm();
      this.signalFunctions = this.entitySpecificsFormHandler.getSignalTagFunctions().map(tagFunc => {
        return new SignalTagFunctionOption(tagFunc);
      });
      this.selectedSignalFunction = this.signalFunctions[0];
  }

  private updateEntitySpecificsForm() {
    let updateSpecificsForm = this.entityType !== this.defaultEntityType;

    if (updateSpecificsForm) {
    /* Clear any existing controls. Needed because conditionally showing entity specifics after
      * entity is loaded might cause form to first be updated with controls for another entity type
      */
      Object.keys(this.entitySpecificsForm.controls).forEach(key => {
        this.entitySpecificsForm.removeControl(key);
      });
    }

    // Tell specifics handler to update for entity
    this.entitySpecificsFormHandler.setupForEntity(this.entity, updateSpecificsForm);
  }

  onSubmit(entityForm: FormGroup) {
    console.log("Submit! Form: ", entityForm);
    this.errorMessage = null;
    var values = entityForm.value;
    var name = values.name;
    var disabled = values.disabled;
    var hide = values.hide;

    if (!entityForm.valid) {
      // Something is invalid, errors should be displayed by now
      return;
    }

    this.entity.name = name;
    this.entity.disabled = disabled;
    this.entity.hide = hide;
    this.entity.type = this.entitySpecificsFormHandler.getEntityType();

    let updateSpecificsResultMessage = this.entitySpecificsFormHandler.updateEntityOrReturnErrorMessageIfInvalid(
      this.entity,
      entityForm.value.entitySpecifics
    );
    if (updateSpecificsResultMessage) {
      this.errorMessage = updateSpecificsResultMessage;
      return;
    }

    console.log(`${this.isNew ? "Create" : "Update"} entity:`, this.entity);

    var method = this.isNew
      ? this.entityService.postEntity(this.entity)
      : this.entityService.putEntity(this.entity.id, this.entity);

    method
      .pipe(
        catchError((err) => {
          console.log(JSON.stringify(err));
          return throwError(() => err);
        })
      )
      .subscribe(() => {
        // Entity updated, update termo object
        var object = this.entity.obj; // as TermoObject;
        var finnished = 0;
        let countToUpdate = this.isNew ? 2 : 1;

        let entitySpecificsMethod = this.isNew
          ? this.entitySpecificsFormHandler.postObject(object)
          : this.entitySpecificsFormHandler.putObject(object);

        entitySpecificsMethod
          .pipe(
            catchError((err) => {
              console.log(JSON.stringify(err));
              return throwError(() => err);
            })
          )
          .subscribe(() => {
            // Termo object updated
            if (++finnished == countToUpdate) {
              this.router.navigateByUrl(`/entities`);
            }
          });

          let signalValue = this.entityForm.get("signal").value;

          if (signalValue !== undefined && signalValue !== "") {
            let tagFunction = this.selectedSignalFunction.value;

          var signalTag = new SignalTag(
            Guid.newGuid().toString(),
            "SignalTag",
            this.entityForm.get("signal").value,
            moment().utc(),
            this.entity.id,
            SignalTagType.Start,
            tagFunction
          );
          this.signalService
            .postSignalTag(signalTag)
            .pipe(
              catchError((err) => {
                console.log(JSON.stringify(err));
                return throwError(() => err);
              })
            )
            .subscribe(() => {
              // Object updated
              if (++finnished == countToUpdate) {
                this.router.navigateByUrl(`/entities`);
              }
            });
        }
      });
  }
  en() {
    throw new Error("Method not implemented.");
  }
}
class SignalTagFunctionOption{
  public readonly name: string;
  constructor(public value: SignalTagFunction){
    this.name = printSignalTagFunction(value);
  }
}
