import * as React from "react";
import * as ReactDOM from "react-dom";
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { ThemeProvider } from "@mui/material";
import { Subscription } from "rxjs";
import { Chanels } from "../chanels";
import { EntityService } from "../entity/entity.service";
import { SiteService } from "../entity/site.service";
import { theme } from "../react-theme";
import { Guid } from "../system/guid";
import { ServiceBusService } from "../system/service-bus.service";
import EntityTypesPage from "./components/EntityTypesPage";
import { setAxiosInterceptors } from "../security/axiosInterceptors";
import axios from "axios";
import { UserSiteService } from "../site/user-site.service";

const containerElementName = "myReactComponentContainer";

@Component({
  selector: "app-my-component",
  template: `<span #${containerElementName}></span>`,
  encapsulation: ViewEncapsulation.None,
})
export class EntityTypesComponent
  implements OnChanges, OnDestroy, AfterViewInit {
  @ViewChild(containerElementName) containerRef: ElementRef;

  siteId: Guid;

  public isLoadingEntities = true;

  private userSubscription: Subscription;

  private user: any;

  constructor(
    private bus: ServiceBusService,
    private entityService: EntityService,
    private siteService: SiteService,
    private userSiteService: UserSiteService,
  ) {
    setAxiosInterceptors(axios);
  }

  ngOnInit() {
    console.log(React); // DO NOT REMOVE - to prevent aot from removing react
    this.userSubscription = this.bus
      .chanelObservable(Chanels.USER_CHANEL)
      .subscribe((user) => {
        this.user = user;
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.render();
  }

  ngAfterViewInit() {
    this.render();
  }

  ngOnDestroy() {
    this.userSubscription.unsubscribe();
    ReactDOM.unmountComponentAtNode(this.containerRef.nativeElement);
  }

  private render() {
    ReactDOM.render(
      <ThemeProvider theme={theme}>
        <EntityTypesContext.Provider
          value={{
            user: this.user,
            entityService: this.entityService,
            siteService: this.siteService,
            userSiteService: this.userSiteService
          }}
        >
          <EntityTypesPage />
        </EntityTypesContext.Provider>
      </ThemeProvider>,
      this.containerRef.nativeElement
    );
  }
}

type EntityTypesContextTypes = {
  user: any;
  entityService: EntityService;
  siteService: SiteService;
  userSiteService: UserSiteService;
};

export const EntityTypesContext = React.createContext<EntityTypesContextTypes>(
  undefined
);
