import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Box,
  Button,
  FormControlLabel,
  Switch,
  Tab,
  Theme,
} from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import { Form, Formik } from "formik";
import * as React from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import FormikField from "../../react-components/FormikField";
import { getBoundaries } from "../../react-components/tempRuleUtils";
import ActionDescriptionRuleForm from "./ActionDescriptionRuleForm";
import ItemListForm from "./ItemListForm";
import RulesForm from "./RulesForm";

const preventSubmitOnEnter = (e) => {
  if ((e.charCode || e.keyCode) === 13 && e.target.localName !== 'textarea') {
    e.preventDefault();
  }
}

const styles = ({ spacing }: Theme) =>
  createStyles({
    root: {},
  });

const useStyles = makeStyles(styles);

type Props = {
  existingEntityType: any;
  handleSubmit: (values: any) => void;
};

const EditEntityTypeForm: React.FC<Props> = ({
  existingEntityType,
  handleSubmit,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [areRulesInverted, setAreRulesInverted] = React.useState(
    existingEntityType
      ? getBoundaries({
          green: existingEntityType.rules.GREEN,
          yellow: existingEntityType.rules.YELLOW,
          red: existingEntityType.rules.RED,
        }).isInverted
      : false
  );

  const [tab, setTab] = React.useState("1");

  const validation = Yup.object().shape({
    name: Yup.string().required(t("entityTypes.edit.errors.required")),
    description: Yup.string().required(t("entityTypes.edit.errors.required")),
    rules: Yup.object().shape({
      GREEN: Yup.object().shape({
        min: Yup.string()
          .required(t("entityTypes.edit.errors.required"))
          .matches(/^-?(\d+(\.\d+)?|Infinity)$/, {
            message: t("entityTypes.edit.errors.number"),
          }),
        max: Yup.string()
          .required(t("entityTypes.edit.errors.required"))
          .matches(/^-?(\d+(\.\d+)?|Infinity)$/, {
            message: t("entityTypes.edit.errors.number"),
          }),
      }),
      YELLOW: Yup.object().shape({
        min: Yup.string()
          .required(t("entityTypes.edit.errors.required"))
          .matches(/^-?(\d+(\.\d+)?|Infinity)$/, {
            message: t("entityTypes.edit.errors.number"),
          }),
        max: Yup.string()
          .required(t("entityTypes.edit.errors.required"))
          .matches(/^-?(\d+(\.\d+)?|Infinity)$/, {
            message: t("entityTypes.edit.errors.number"),
          }),
      }),
      RED: Yup.object().shape({
        min: Yup.string()
          .required(t("entityTypes.edit.errors.required"))
          .matches(/^-?(\d+(\.\d+)?|Infinity)$/, {
            message: t("entityTypes.edit.errors.number"),
          }),
        max: Yup.string()
          .required(t("entityTypes.edit.errors.required"))
          .matches(/^-?(\d+(\.\d+)?|Infinity)$/, {
            message: t("entityTypes.edit.errors.number"),
          }),
      }),
    }),
    commonDeviationCauses: Yup.array().of(
      Yup.object().shape({
        label: Yup.string().required(t("entityTypes.edit.errors.required")),
        cause: Yup.string().required(t("entityTypes.edit.errors.required")),
      })
    ),
    commonDeviationHandles: Yup.array().of(
      Yup.object().shape({
        action: Yup.string().required(t("entityTypes.edit.errors.required")),
      })
    ),
  });

  const initialValues = {
    name: existingEntityType?.name || "",
    description: existingEntityType?.description || "",
    rules: existingEntityType?.rules || {
      GREEN: {
        min: -20,
        max: -10,
        actionDescription: "",
      },
      YELLOW: {
        min: -10,
        max: 10,
        actionDescription: "",
      },
      RED: {
        min: 10,
        max: 20,
        actionDescription: "",
      },
    },
    commonDeviationCauses: existingEntityType?.commonDeviationCauses || [],
    commonDeviationHandles: existingEntityType?.commonDeviationHandles || [],
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validation}
      onSubmit={(values) => {
        handleSubmit(values);
      }}
    >
      {({ values, setFieldValue }) => (
        <Form onKeyDown={preventSubmitOnEnter}>
          <FormikField
            name="name"
            label={t("entityTypes.edit.name")}
            required
          />
          <FormikField
            name="description"
            label={t("entityTypes.edit.description")}
            required
          />

          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={tab}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList onChange={(_, v) => setTab(v)}>
                  <Tab label={t("entityTypes.edit.entityType.title")} value="1" />
                  <Tab label={t("entityTypes.edit.commonDeviationCauses.title")} value="2" />
                  <Tab label={t("entityTypes.edit.commonDeviationHandles.title")} value="3" />
                </TabList>
              </Box>
              <TabPanel value="1">
                <FormControlLabel
                  control={
                    <Switch
                      checked={areRulesInverted}
                      onChange={() => {
                        const redRules = { ...values.rules.RED };
                        const greenRules = { ...values.rules.GREEN };
                        setFieldValue("rules.GREEN", redRules);
                        setFieldValue("rules.RED", greenRules);
                        setAreRulesInverted(!areRulesInverted);
                      }}
                      name="disabled"
                      color="primary"
                    />
                  }
                  label={t("entityTypes.edit.hotTemperatures") as string}
                />
                <RulesForm
                  rules={values.rules}
                  areRulesInverted={areRulesInverted}
                />
                <ActionDescriptionRuleForm
                  ruleKey={areRulesInverted ? "RED" : "GREEN"}
                />
                <ActionDescriptionRuleForm ruleKey="YELLOW" />
                <ActionDescriptionRuleForm
                  ruleKey={areRulesInverted ? "GREEN" : "RED"}
                />
              </TabPanel>
              <TabPanel value="2">
                <ItemListForm
                  rootKey={'commonDeviationCauses'}
                  subKeys={['label', 'cause']}
                  items={values.commonDeviationCauses}
                />
              </TabPanel>
              <TabPanel value="3">
                <ItemListForm
                  rootKey={'commonDeviationHandles'}
                  subKeys={['action']}
                  items={values.commonDeviationHandles}
                />
              </TabPanel>
            </TabContext>
          </Box>

          <Button type="submit" variant="contained" color="primary">
            {t("entityTypes.edit.save")}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default EditEntityTypeForm;
