import React, { FC } from 'react';

import { FormControl, FormControlLabel, FormLabel, Paper, Radio, RadioGroup } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

const styles = () =>
  createStyles({
    root: {
      padding: "1rem",
      maxHeight: "20vh",
      overflowY: 'scroll'
    },
  });

const useStyles = makeStyles(styles);

type Props = {
  options: { label: string; value: string; }[];
  label?: string;
  value: string;
  onChange?: (value: string) => void;
  disabled?: boolean;
}

export const RadioButtonsInput: FC<Props> = ({ options, label, value, onChange,disabled }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <FormControl>
        {!!label ? <FormLabel>{label}</FormLabel> : <></>}
        <RadioGroup
          value={value}
          onChange={e => disabled?()=>{}:onChange(e.target.value)}
        >
          {options.map((opt, i) => 
            <FormControlLabel value={opt.value} key={i+opt.value} control={<Radio />} label={opt.label} />)}
        </RadioGroup>
      </FormControl>
    </Paper>
  );
}
