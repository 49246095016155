import * as moment from "moment";

export class EntityDeviation {
    public isDelivered = false;
  
    constructor(
      public entityId: string,
      public name: string,
      public temperature: any,
      public temperatureOk: boolean,
      public isAlarmState: boolean,
      public isHandled: boolean,
      public alarmText: string,
      public alarmStartTime: moment.Moment
    ) {}
  }