import React, { FC, useEffect, useState } from "react";
import { ColoredSlider } from "./ColoredSlider";

type Props = {
  value: number[];
  onChange: (v: number[]) => any;
  min: number;
  max: number;
  isInverted: boolean;
};

export const TemperatureRangeSlider: FC<Props> = ({ value, onChange, min, max, isInverted }) => {

  const [localValue, setLocalValue] = useState(value);

  useEffect(() => {
    if(value[0] !== localValue[0] || value[1] !== localValue[1])
      setLocalValue(value);
  }, [value]);

  const interval = max - min;
  const greenPercentage = (localValue[0] - min) / interval * 100;
  const yellowPercentage = (localValue[1] - localValue[0]) / interval * 100;
  const redPercentage = (max - localValue[1]) / interval * 100;

  const percentages = {
    green: isInverted ? redPercentage : greenPercentage,
    yellow: yellowPercentage,
    red: isInverted ? greenPercentage : redPercentage,
  };

  const markNumbers = [...new Set([min, ...localValue, max])];

  return (
    <ColoredSlider
      value={localValue}
      onChange={(v: number[]) => {
        const [low, high] = v;
        if(high - low >= 0)
          setLocalValue(v);
      }}
      onChangeCommitted={onChange}
      min={min}
      max={max}
      isInverted={isInverted}
      percentages={percentages}
      marks={markNumbers.map(v => ({
        label: `${v}°C`,
        value: v
      }))}
      valueLabelFormatter={(v) => `${v}°C`}
    />
  );
};
