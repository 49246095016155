import { Guid } from "../system/guid";
import { Moment } from "moment";
import { ServiceHoursDefinition } from "../edit-entity-form-react/interfaces";

export type AlarmContact = {
  name: string;
  phoneNumber: string;
  isActive: boolean;
  sendSmsHours?: ServiceHoursDefinition[];
}

export class Site {
  constructor(
    public id: Guid,
    public tenantId: Guid,
    public name: string,
    public createdOn: Moment,
    public readonly siteType: string,
    public readonly phoneNumber: string,
    public access: string,
    public address: string,
    public prittyUrl: string,
    public reportCron: string,
    public alarmContacts: AlarmContact[],
    public showLowPriorityRoutinesTab?: boolean,
  ) { }

  // access: "PRIVATE"
  // address: null
  // createdOn: "2021-01-18T00:00:00Z"
  // entities: null
  // extraElements: {}
  // id: "c3124c82-c2f1-412f-be0a-4782cdab57f9"
  // name: "Dyreparken - Drivhuset"
  // phoneNumber: "90558192"
  // prittyUrl: "dyreparken-drivhuset"
  // reportCron: "0 0 8 * * ?"
  // siteType: "Restaurant"
  // tenantId: "cb0501c1-503b-420a-b9c3-d4085796a743"
  public static deSerialize(data: any): Site {
    return new Site(
      data.id,
      data.tenantId,
      data.name,
      data.createdOn,
      data.siteType,
      data.phoneNumber,
      data.access,
      data.address,
      data.prittyUrl,
      data.reportCron,
      data.alarmContacts,
      data.showLowPriorityRoutinesTab
    );
  }
  public toString(): string {
    return `Name: ${this.name}`;
  }
}
