<app-header></app-header>
<div class="device-list">
  <span class="go-back">
    <a (click)='routing("register-device")'>
      <i class="material-icons">
keyboard_arrow_left
</i>
    </a>
  </span>

  <mat-card class="device-list__devices">
    <div class="device-list__devices__item">
      <div class="row">
        <span class="device-list__devices__item__name">
          Device 1
        </span>
        <div class="device-list__devices__item__actions">
          <img src="assets/icons/rubbish-bin.png" class="delete" alt="delete" />
          <img src="assets/icons/edit.png" class="edit" alt="edit" />
        </div>
      </div>
      <div class="row">
        <p class="device-list__devices__item__name temperature"> 60/58 &#8451; </p>
      </div>
    </div>
    <div class="device-list__devices__item">
      <div class="row">
        <span class="device-list__devices__item__name">
          Device 2
        </span>
        <div class="device-list__devices__item__actions">
          <img src="assets/icons/rubbish-bin.png" class="delete" alt="delete" />
          <img src="assets/icons/edit.png" class="edit" alt="edit" />
        </div>
      </div>
      <div class="row">
        <p class="device-list__devices__item__name temperature"> 60/58 &#8451; </p>
      </div>
    </div>
    <div class="device-list__devices__item">
      <div class="row">
        <span class="device-list__devices__item__name">
          Device 3
        </span>
        <div class="device-list__devices__item__actions">
          <img src="assets/icons/rubbish-bin.png" class="delete" alt="delete" />
          <img src="assets/icons/edit.png" class="edit" alt="edit" />
        </div>
      </div>
      <div class="row">
        <p class="device-list__devices__item__name temperature"> 60/58 &#8451; </p>
      </div>
    </div>
    <div class="device-list__devices__add"> + </div>
  </mat-card>

  <div class="center">
    <button class="confirm">
      Lagre
    </button>
  </div>
</div>
