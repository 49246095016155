import * as React from "react";
import * as ReactDOM from "react-dom";
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { ThemeProvider } from "@mui/material";
import { theme } from "../react-theme";
import LoginPage from "./components/Login";
import { MsalService } from "@azure/msal-angular";
import { Router } from "@angular/router";
import Fix from "./components/Fix";

const containerElementName = "myReactComponentContainer";

@Component({
  selector: "app-my-component",
  template: `<span #${containerElementName}></span>`,
  encapsulation: ViewEncapsulation.None,
})
export class LoginComponent
  implements OnChanges, OnDestroy, AfterViewInit {
  @ViewChild(containerElementName) containerRef: ElementRef;

  constructor(
    private msalService: MsalService,
    private router: Router,
  ) {
    
  }

  ngOnInit() {
    console.log(React); // DO NOT REMOVE - to prevent aot from removing react
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.render();
  }

  ngAfterViewInit() {
    this.render();
  }

  ngOnDestroy() {
    ReactDOM.unmountComponentAtNode(this.containerRef.nativeElement);
  }

  private render() {
    ReactDOM.render(
      <ThemeProvider theme={theme}>
        <LoginContext.Provider
          value={{
            msalService: this.msalService,
            router: this.router
          }}
        >
          <LoginPage />
          <Fix/>
        </LoginContext.Provider>
      </ThemeProvider>,
      this.containerRef.nativeElement
    );
  }
}

type LoginContextTypes = {
  msalService: MsalService;
  router: Router;
};

export const LoginContext = React.createContext<LoginContextTypes>(
  undefined
);
