import {
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { makeStyles } from '@mui/styles';
import clsx from "clsx";
import * as moment from "moment";
import * as React from "react";
import { FC } from "react";
import { timer, throwError, switchMap, forkJoin, catchError } from "rxjs";
import { take } from "rxjs/operators";
import { LatestDoorEventInfo } from "../../doors-clean/doors-clean.component";
import { CleanEvent, DoorEventValue } from "../../signals/doorevent";
import { SignalsService } from "../../signals/signals.service";
import { SignalEvent } from "../../signals/vevent";
import { useTranslation } from "react-i18next";
import { Guid } from "../../system/guid";
import CleanProgress from "./CleanProgress";
import StatList from "./StatList";

const useStyles = makeStyles({
  card: {
    backgroundColor: "#fafafa",
    marginBottom: "1em",
    paddingBottom: "1em",
  },
  needsCleaning: {
    backgroundColor: "rgba(255, 0, 0, 0.2)",
  },
  cardContent: {
    display: "flex",
  },
  cardItem: {
    display: "flex",
    justifyContent: "center",
  },
  statList: {
    flexShrink: 1,
  },
  button: {
    backgroundColor: "#3f51b5",
    color: "#fff",
    width: 180,
    "&:hover": {
      backgroundColor: "#002984",
    },
  },
  infoContainer: {
    width: "60%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  alert: {
    color: "#F90606",
    marginTop: "0.1em",
  },
});

interface Props {
  entity: any;
  signalsService: SignalsService;
}

const CleanCard: FC<Props> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [entity, setEntity] = React.useState({
    id: props.entity.id,
    name: props.entity.name,
    lastClean: undefined,
    openCloseInfo: {
      limit: props.entity.openCloseInfo.limit,
      countSinceLastCleaning: 0,
      usagePercentage: 0,
      lastSignalDate: "",
      lastState: "",
    },
  });

  const fetchDoorEvents = ([events, lastClean, latestDoorEvent]) => {
    console.log("Fetching");
    console.log(events);
    console.log(lastClean);
    console.log(latestDoorEvent);
    // useGetDoorEventsSinceLastClean(entity.id);
    const signalEvents: SignalEvent[] = events as SignalEvent[];
    const lastCleanEvent: CleanEvent = lastClean as CleanEvent;
    const latestDoorInfo = new LatestDoorEventInfo(latestDoorEvent);

    let countPeriodTotal = countCloseEvents(signalEvents);

    let countSinceLastCleaning = countCloseEvents(signalEvents);

    let usagePercentage = Math.round(
      (countSinceLastCleaning / props.entity.openCloseInfo.limit) * 100
    );
    const openCloseInfo = {
      ...props.entity.openCloseInfo,
      countPeriodTotal,
      countSinceLastCleaning,
      usagePercentage,
      lastSignalDate: latestDoorInfo.occuredTime,
      lastState: latestDoorInfo.state,
    };
    console.log(lastClean);
    setEntity({
      ...props.entity,
      lastClean: lastCleanEvent && lastCleanEvent.timestamp.fromNow(),
      openCloseInfo,
    });
  };

  const doorEventSubscriber = () => {
    return timer(1000, 10000).pipe(
      switchMap(() => forkJoin([
        props.signalsService.GetDoorEventsSinceLastClean(props.entity.id),
        props.signalsService.GetLatestCleanEvent(props.entity.id),
        props.signalsService.GetLatestDoorEvent(props.entity.id)
      ]))
    ).subscribe((res) => fetchDoorEvents(res));
  };

  const countCloseEvents = (events: SignalEvent[]) => {
    return events.filter(
      (e: any) =>
        e.type === "DoorOpenCloseEvent" && e.value === DoorEventValue.Close
    ).length;
  };

  const handleRegisterClean = (entityId: string) => {
    console.log(`Register cleaning entity: ${entityId}`);
    console.log(moment().utc());
    let sourceId = `${entityId}/clean`;
    let cleanEvent = new CleanEvent( //check if this event is actually posted.
      Guid.newGuid(),
      "CleanEvent",
      sourceId,
      moment().utc()
    );

    console.log(cleanEvent);

    props.signalsService
      .postCleanEvent(cleanEvent)
      .pipe(
        catchError((err) => {
          console.log(JSON.stringify(err));
          return throwError(() => err);
        })
      )
      .pipe(take(1))
      .subscribe(() =>
        setEntity({
          ...entity,
          lastClean: moment().utc().fromNow(),
          openCloseInfo: {
            ...entity.openCloseInfo,
            countSinceLastCleaning: 0,
            usagePercentage: 0,
          },
        })
      );
  };

  React.useEffect(() => {
    const subscription = doorEventSubscriber();
    return () => {
      subscription.unsubscribe();
    }
  }, []);

  return (
    <Paper
      className={clsx(
        classes.card,
        entity.openCloseInfo.usagePercentage > 99 && classes.needsCleaning
      )}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} className={classes.cardItem}>
          <Box>
            <CleanProgress value={entity.openCloseInfo.usagePercentage} />
            {entity.openCloseInfo.usagePercentage > 99 && (
              <Typography variant="h5" className={classes.alert}>
                {t("CleanComponent.time_to_clean")}
              </Typography>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} className={classes.cardItem}>
          <Box className={classes.infoContainer}>
            <StatList entity={entity} />
            <Button
              variant="contained"
              className={classes.button}
              onClick={() => handleRegisterClean(entity.id)}
            >
              {t("CleanComponent.register_clean")}
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Divider light />
        </Grid>
        <Grid item xs={4} sm={6} className={classes.cardItem}>
          <i className="material-icons">wc</i>
        </Grid>
        <Grid item xs={8} sm={6} className={classes.cardItem}>
          <Typography variant="h5">{entity.name}</Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default CleanCard;
