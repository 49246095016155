import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "deviation-actions-slider",
  templateUrl: "./deviation-actions-slider.component.html",
  styleUrls: ["./deviation-actions-slider.component.scss"],
})
export class DeviationActionsSliderComponent implements OnInit {
  @Input()
  entityReports;

  @Input()
  entityId;

  @Input()
  entityMax;

  @Input()
  entityMin;

  deviationActionList: Array<any> = [];

  constructor() {}

  config: any = {
    autoplay: 3000, // Autoplay option having value in milliseconds
    initialSlide: 3, // Slide Index Starting from 0
    slidesPerView: 1, // Slides Visible in Single View Default is 1
    pagination: ".swiper-pagination", // Pagination Class defined
    paginationClickable: true, // Making pagination dots clicable
    nextButton: ".swiper-button-next", // Class for next button
    prevButton: ".swiper-button-prev", // Class for prev button
    spaceBetween: 30, // Space between each Item
  };

  ngOnInit() {
    this.getDeviceDeviationsActions();
  }

  getDeviceDeviationsActions() {
    for (let item of this.entityReports) {
      if (Object.entries(item.deviations).length > 0) {
        let keys = Object.keys(item.deviations);
        for (let key of keys) {
          if (
            item.deviations[key].entityId == this.entityId &&
            item.deviations[key].action != ""
          ) {
            let measurementsKeys = Object.keys(item.measurements);
            for (let measurementKey of measurementsKeys) {
              if (item.measurements[measurementKey].entityId == this.entityId) {
                this.deviationActionList.push({
                  entityAction: item.deviations[key].action,
                  entityValue: item.measurements[measurementKey].value,
                });
              }
            }
          }
        }
      }
    }
  }
}
