import { Guid } from "../system/guid";
import { Moment } from "moment";

export class OpenCloseObject {

    public static readonly typeName = "OpenCloseObject";

    constructor(public id: string,public entityId: string,
        public tenantId : string, public rules: any, public createdOn: Moment,public termoObjectTypeId?: string){

    }
}


