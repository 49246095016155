import {
  AfterViewInit,
  Component,
  ElementRef,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ThemeProvider } from "@mui/material";
import axios from "axios";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { Subscription } from "rxjs";
import { Chanels } from "../chanels";
import { EntityService } from "../entity/entity.service";
import { SiteService } from "../entity/site.service";
import { theme } from "../react-theme";
import { setAxiosInterceptors } from "../security/axiosInterceptors";
import { SignalsService } from "../signals/signals.service";
import { UserSiteService } from "../site/user-site.service";
import { Guid } from "../system/guid";
import { ServiceBusService } from "../system/service-bus.service";
import EditEntity from "./components/EditEntity";
import EditEntityContext from "./EditEntityContext";

const containerElementName = "myReactComponentContainer";

@Component({
  selector: "app-my-component",
  template: `<span #${containerElementName}></span>`,
  encapsulation: ViewEncapsulation.None,
})
export class EditEntityFormComponent
  implements OnChanges, OnDestroy, AfterViewInit {
  @ViewChild(containerElementName) containerRef: ElementRef;

  siteId: Guid;

  public isLoadingEntities = true;

  private userSubscription: Subscription;

  private user: any;

  constructor(
    private bus: ServiceBusService,
    private entityService: EntityService,
    private signalsService: SignalsService,
    private siteService: SiteService,
    private userSiteService: UserSiteService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    setAxiosInterceptors(axios);
  }

  ngOnInit() {
    console.log(React); // DO NOT REMOVE - to prevent aot from removing react
    this.userSubscription = this.bus
      .chanelObservable(Chanels.USER_CHANEL)
      .subscribe((user) => {
        this.user = user;
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.render();
  }

  ngAfterViewInit() {
    this.render();
  }

  ngOnDestroy() {
    this.userSubscription.unsubscribe();
    ReactDOM.unmountComponentAtNode(this.containerRef.nativeElement);
  }

  private render() {
    ReactDOM.render(
      <ThemeProvider theme={theme}>
        <EditEntityContext.Provider
          value={{
            user: this.user,
            entityService: this.entityService,
            signalsService: this.signalsService,
            siteService: this.siteService,
            userSiteService: this.userSiteService,
            router: this.router,
            route: this.route,
          }}
        >
          <EditEntity />
          {/* <EditEntityForm /> */}
        </EditEntityContext.Provider>
      </ThemeProvider>,
      this.containerRef.nativeElement
    );
  }
}
