import {
  Theme,
  Paper,
  Button,
  Tooltip,
} from "@mui/material";
import {
  createStyles,
  makeStyles,
} from '@mui/styles'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import React, { FC, useContext } from "react";
import { useTranslation } from "react-i18next";
import { usePutSignature } from "./SignatureService";
import { SiteSettingsContext } from "../site-settings-react/SiteSettingsReactWrapper";

const styles = ({ spacing }: Theme) =>
  createStyles({
    root: {},
    button: {
      marginTop: spacing(2),
    },
    card: {
      maxHeight: "30vh",
      overflowY: 'scroll',
      marginTop: '1em',
    },
    signature: {
      padding: '0.7em',
      paddingTop: '1em',
      marginTop: '0.4em',
      marginBottom: '0.4em',
      display: 'flex',
      justifyContent: 'space-between'
    }
  });

const useStyles = makeStyles(styles);

type Props = {
  signatures: any[];
  onChange: () => void;
}

const SignatureList: FC<Props> = ({ signatures, onChange }) => {
  const { t } = useTranslation();
  const { user } = useContext(SiteSettingsContext);
  const classes = useStyles();

  const userId = user.adalUser.oid;

  const removeSignature = async (signature) => {
    console.log(`Removing signature ${signature.id} from user ${userId}`);
    const index = signature.userIds.indexOf(userId);
    signature.userIds.splice(index, 1);

    const userIds = signature.userIds;

    const updatedSignature = {
      id: signature.id,
      name: signature.name,
      pin: signature.pin,
      tenantId: signature.tenantId,
      createdOn: signature.createdOn,
      disabled: signature.disabled,
      userIds: userIds,
    };

    await usePutSignature(updatedSignature, signature.id);
    onChange();
  };

  return (
    <div className={classes.card}>
      <br />
      <div style={{
        marginTop: '3em',
      }}>
        {t("siteForm.signatures")}
      </div>
      <br />
      {signatures.map(signature => <Paper key={signature.id} className={classes.signature}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {signature.autoCreatedForUserId &&
            <Tooltip title={t('signature.userSignature')}>
              <PersonOutlineIcon />
            </Tooltip>} {signature.name}
        </div>
        <Button onClick={() => { removeSignature(signature) }}>
          <DeleteOutlineIcon />
        </Button>
      </Paper>)}
    </div>
  );
};

export default SignatureList;
