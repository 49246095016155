export const TmeshRoles=  {
  Admin : "Bitmesh.Tmesh.Admin",
  Supervisor : "Bitmesh.Tmesh.Supervisort",
  Tmesh : "Bitmesh.Tempmesh.Permission",
};

type TmeshRoleTypes = typeof TmeshRoles[keyof typeof TmeshRoles];


export function createRoleChecker(roles) {
  return (role:TmeshRoleTypes) => roles && roles?.includes(role);
}

export function hasAdminRole(roles) {
  return roles && roles?.includes(TmeshRoles.Supervisor) || roles?.includes(TmeshRoles.Admin);
}

export function hasSupervisortRole(roles) {
  return roles && roles?.includes(TmeshRoles.Supervisor);
}


export function isBitmeshUser(user) {
  if (!user) return false;
  const tenantIds = user?.btennant;
  return tenantIds?.indexOf("36E67390-6DB0-4F30-B85F-4770C90AD437".toLowerCase()) >= 0;
}
