import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { Observable } from "rxjs";

import { TempReport } from "./tempreport";
import * as moment from "moment";
import { map } from "rxjs/internal/operators/map";
import { observableAxios, ObservableAxios } from "../rxjs-axios";

@Injectable({
  providedIn: "root",
})
export class TempreportService {
  private readonly base_url = environment.dependencies.tempreports_api.url;
  private readonly register_reports_url = this.base_url + "/api/tempreports";
  private readonly check_periodic_reports =
    this.base_url + "/api/tempreports/periodic";
  private readonly check_periodic_week_reports =
    this.base_url + "/api/tempreports/periodic-week";
  private readonly check_inprogress_reports =
    this.base_url + "/api/tempreports/todo";
  private readonly check_delievered_reports =
    this.base_url + "/api/tempreports/delievered";
  private readonly check_delivered_reports =
    this.base_url + "/api/tempreports/delivered";
  private readonly check_delivered_with_value_reports =
    this.base_url + "/api/tempreports/deliveredwithvalue";
  private readonly get_new_reports = this.base_url + "/api/tempreports/new";
  private readonly get_snapshot = this.base_url + "/api/tempreports/snapshot";
  private readonly get_manual = this.base_url + "/api/tempreports/manual";

  private readonly http: ObservableAxios = observableAxios;

  constructor() { }

  public registerTempReport(tempreport: TempReport): Observable<TempReport> {
    return this.http.post<TempReport>(this.register_reports_url, tempreport);
  }

  public updateTempReport(tempreport: TempReport): Observable<TempReport> {
    return this.http.put<TempReport>(
      `${this.register_reports_url}/${tempreport.id}`,
      tempreport
    );
  }

  public getPeriodicReport(siteId: string) {
    return this.http
      .get<TempReport>(`${this.check_periodic_reports}/${siteId}`)
      .pipe(map((x) => TempReport.deSerialize(x)));
  }

  public getPeriodicReportForLastSevenDays(siteId: string) {
    return this.http
      .get<TempReport[]>(`${this.check_periodic_week_reports}/${siteId}`)
      .pipe(map((x) => x.map(tr => TempReport.deSerialize(tr))));
  }

  public getInprogressReports(siteId: string) {
    return this.http
      .get<TempReport>(`${this.check_inprogress_reports}/${siteId}`)
      .pipe(map((x) => TempReport.deSerialize(x)));
  }

  public getDelieveredReports(siteId: string) {
    return this.http
      .get<TempReport>(`${this.check_delievered_reports}/${siteId}`)
      .pipe(map((x) => TempReport.deSerialize(x)));
  }

  public getNewReport(siteId: string) {
    return this.http
      .get<TempReport>(`${this.get_new_reports}/${siteId}`)
      .pipe(map((x) => TempReport.deSerialize(x)));
  }
  public getSnapshot(siteId: string) {
    return this.http
      .get<TempReport>(`${this.get_snapshot}/${siteId}`)
      .pipe(map((x) => TempReport.deSerialize(x)));
  }
  public getManual(siteId: string, entityId: string) {
    return this.http
      .get<TempReport>(`${this.get_manual}/${siteId}/${entityId}`)
      .pipe(map((x) => TempReport.deSerialize(x)));
  }

  // public getTempReports(siteId:string): Observable<Array<TempReport>> {
  //   return this.http.get<Array<TempReport>>(`${this.register_reports_url}?referenceId=${siteId}`)
  //     .map(x=> x.map(o=>TempReport.deSerialize(o)));
  // }
  public getTempReports(
    siteId: string,
    from: moment.Moment,
    to: moment.Moment
  ): Observable<Array<TempReport>> {
    var fromValue = from.toISOString();
    var toValue = to.toISOString();
    return this.http
      .get<Array<TempReport>>(
        `${this.register_reports_url}?referenceId=${siteId}&from=${fromValue}&to=${toValue}`
      )
      .pipe(map((x) => x.map((o) => TempReport.deSerialize(o))));
  }

  public getDeliveredForEntity(
    siteId: string,
    entityId: string,
    fromTime?: moment.Moment,
    requireValue?: boolean
  ): Observable<TempReport> {
    let paramDelimiter = "?";

    let path = `${this.check_delivered_reports}/${siteId}/entity/${entityId}`;
    if (fromTime) {
      path += `${paramDelimiter}fromTime=${fromTime.toISOString()}`;
      paramDelimiter = "&";
    }
    if (requireValue) {
      path += `${paramDelimiter}requireValue=${requireValue}`;
      paramDelimiter = "&";
    }

    // return this.http.get<Array<TempReport>>(`${this.check_delivered_reports}/${siteId}/entity/${entityId}?fromTime=${fromValue}`)
    return this.http
      .get<Array<TempReport>>(path)
      .pipe(map((x) => TempReport.deSerialize(x)));
  }
}
