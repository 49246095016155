<ng-container [formGroup]="rules">
  <!-- <div formGroupName="rules"> -->
    <mat-form-field>
      <input
        matInput
        placeholder="Familie"
        formControlName="make"
        type="text"
      />
    </mat-form-field>
    <mat-form-field>
      <input
        matInput
        placeholder="Art"
        formControlName="model"
        type="text"
      />
    </mat-form-field>

    <mat-form-field>
      <input
        matInput
        placeholder="*Min %"
        formControlName="min"
        type="number"
      />
    </mat-form-field>
    <mat-form-field>
      <input
        matInput
        placeholder="*Maks %"
        formControlName="max"
        type="number"
      />
    </mat-form-field>

    <div fxLayout.xs="column">
      <article
        class="div-left"
        fxFlex="1 1 100%"
        fxFlexOrder
        fxFlexOrder.xs="2"
      >
        <ng-container
          formArrayName="limits"
          *ngFor="let limit of limits.controls; let i=index; "
        >
          <div [formGroupName]="i">
            <ng-template [ngIf]="limit">
              <span
                >Min:{{limit.value.min}} Max:{{limit.value.max}} Fra:
                {{limit.value.from && limit.value.from.hour| number:
                '2.'}}:{{limit.value.from && limit.value.from.minute| number:
                '2.'}} Til: {{limit.value.to && limit.value.to.hour| number:
                '2.'}}:{{limit.value.to && limit.value.to.minute| number: '2.'}}
              </span>
              <!--  Til: {{limit.value.to && limit.to.hour}} - -->
              <button mat-icon-button>
                <mat-icon type="button" (click)="delete(i)">delete</mat-icon>
              </button>
              <!-- </ng-template> -->
            </ng-template>
          </div>
        </ng-container>
      </article>
    </div>

    <button
      mat-raised-button
      color="primary"
      type="button"
      (click)="showNewException = !showNewException"
    >
      {{showNewException? "lukk": "Untak"}}
    </button>
    <div [formGroup]="newLimit" *ngIf="showNewException">
      <label>Fra</label>
      <div formGroupName="from" fxLayout="column" class="from">
        <mat-form-field class="form-content">
          <input
            matInput
            placeholder="time"
            name="bfn"
            formControlName="hour"
          />
        </mat-form-field>
        <mat-form-field class="form-content ">
          <input
            matInput
            placeholder="minutt"
            name="ben"
            formControlName="minute"
          />
        </mat-form-field>
      </div>
      <label>Til</label>
      <div formGroupName="to" class="totime">
        <mat-form-field class="form-content">
          <input
            matInput
            placeholder="time"
            name="bfn"
            formControlName="hour"
          />
        </mat-form-field>
        <mat-form-field class="form-content ">
          <input
            matInput
            placeholder="minutt"
            name="ben"
            formControlName="minute"
          />
        </mat-form-field>
      </div>
      <label>Temperatur</label>
      <div class="limits">
        <mat-form-field class="form-content ">
          <input matInput placeholder="Min" name="ben" formControlName="min" />
        </mat-form-field>
        <mat-form-field class="form-content ">
          <input matInput placeholder="Maks" name="ben" formControlName="max" />
        </mat-form-field>
      </div>
      <button
        mat-raised-button
        color="primary"
        type="button"
        (click)="addLimits()"
      >
        Legg til untak
      </button>
    </div>
  <!-- </div> -->
</ng-container>
