import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Theme,
} from "@mui/material";
import { makeStyles, createStyles } from '@mui/styles';
import { Field, Form, Formik } from "formik";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Site } from "../../../entity/site";
import { useChecklistContext } from "../../ChecklistReactWrapper";
import {
  mapTenantChecklists,
  useGetChecklistTags,
  useTenantChecklists,
} from "../../services/checklistService";
import DialogTitle from "../../../react-components/DialogTitle";
import FormikSelect, {
  FormikSelectItem,
} from "../../../react-components/FormikSelect";
import { TagsField } from "../../../react-components/TagsField";
import { TextField } from "formik-material-ui";

const useStyles = makeStyles(({ spacing, palette }: Theme) =>
  createStyles({
    root: {},
    container: {
      padding: spacing(2),
    },
  })
);

type Props = {
  label: string;
  onSubmit: ({}: {
    checklistId: string;
    checklistName: string;
    tenantId: string;
    siteId: string;
    siteName?: string;
  }) => void;
};

const AssignChecklistForm: FC<Props> = ({ label, onSubmit }) => {
  const classes = useStyles();
  const { user, siteService } = useChecklistContext();
  const { t } = useTranslation();

  const [tenantId, setTenantId] = useState<string>("");
  const [checklistOptions, setChecklistOptions] = useState<FormikSelectItem[]>(
    []
  );
  const [open, setOpen] = useState(false);

  const [tags, setTags] = useState<string[]>([]);
  const [checklistData, setChecklistData] = useState<any[]>([]);
  const [siteName, setSiteName] = useState<string>("");

  const [{ data, loading, error },] = useGetChecklistTags(tenantId);

  useEffect(() => {
    const subscribtion = siteService
      .getSite(user.siteId)
      .subscribe((site: Site) => {
        setTenantId(site.tenantId.toString());
        setSiteName(site.name);
      });
    return () => subscribtion.unsubscribe();
  }, []);

  useEffect(() => {
    tenantId &&
      useTenantChecklists(tenantId).then((resp) => setChecklistData(resp.data));
  }, [tenantId]);

  useEffect(() => {
    setChecklistOptions(
      mapTenantChecklists(checklistData, tags).map((checklist) => {
        return { label: checklist.name, value: checklist.checklistId };
      })
    );
  }, [checklistData, tags]);

  const toggleOpen = () => {
    setOpen(!open);
  };

  const handleSubmit = async (values, { resetForm }) => {
    onSubmit({
      checklistId: values.checklistId,
      checklistName: values.checklistName,
      tenantId,
      siteId: user.siteId,
      siteName: siteName
    });

    toggleOpen();
    resetForm();
  };

  return (
    <>
      <Button
        style={{ marginLeft: "1em" }}
        variant="contained"
        color="primary"
        onClick={toggleOpen}
      >
        {label}
      </Button>
      <Dialog open={open} onClose={toggleOpen} maxWidth="sm" fullWidth>
        <DialogTitle onClose={toggleOpen}>
          {label}
        </DialogTitle>
        <div className={classes.container}>
          <TagsField
            label="Tags Filter"
            values={tags}
            options={error || loading ? [] : data}
            onChange={setTags}
          />
        </div>
        <Formik initialValues={{ checklistId: "",checklistName: "" }} onSubmit={handleSubmit}>
          {(props) => (
            <Form>
              <DialogContent>
                
                <FormikSelect
                  name="checklistId"
                  label={t("Checklist Template")}
                  required
                  items={checklistOptions}
                  onChange={(e)=>{
                    props.handleChange(e);
                    let selectedItem = checklistOptions.find(x=>x.value == e.target.value);
                    // if(!props.touched.checklistName)
                    props.handleChange("checklistName")(selectedItem.label);
                  }}
                />
                <Field
                  name="checklistName"
                  render={(props) => (
                    <TextField
                      label={t("editChecklist.name")}
                      {...props}                      
                      fullWidth
                      variant="standard"
                    />
                  )}
                />
              </DialogContent>
              <DialogActions>
                <Button type="submit" variant="contained" color="primary">
                  {`${t("editChecklist.submit")}`}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};

export default AssignChecklistForm;
