import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SignalsService } from './signals.service';
import { SignalListComponent } from './signal-list/signal-list.component';
import { SignalValuesComponent } from './signal-values/signal-values.component';

@NgModule({
  imports: [
    CommonModule
  ],
  providers:[
    SignalsService
  ],
  declarations: [
    SignalListComponent,
    SignalValuesComponent
  ],
  exports:[
    SignalListComponent,
    SignalValuesComponent
  ]
})
export class SignalsModule { }
