<!-- <div [ngClass]='{header:true, "not-prod": isNotProduction}'>
  <div id="logo-outer">
    <div id="logo-inner">
      <a routerLink="/">
        <div class="site-name" *ngIf="siteName">{{ siteName }}</div>
      </a>
    </div>
  </div>
  
  <div class="menu-element-container">
    <a
      class="menu-item"
      [ngClass]="{ active: isRouteActive('/site-settings') }"
      (click)="routing('/site-settings')"
    >
      <i class="material-icons">settings</i>
    </a>
    <app-profile></app-profile>
  </div>
</div> -->
<div [class]="isNotProduction ? 'prod' : 'prod'">
  <div class="gpt-header">
    <div class="left-cell"></div>
    <div class="title">
      <div id="logo-outer">
        <div id="logo-inner">
          <a routerLink="/">
            <div class="site-name" *ngIf="siteName">{{ siteName }}</div>
          </a>
        </div>
      </div>
    </div>
    <div class="right-cell">
      <a
          class="menu-item"
          [ngClass]="{ active: isRouteActive('/site-settings') }"
          (click)="routing('/site-settings')"
        >
          <i class="material-icons">settings</i>
        </a>
        <app-profile></app-profile>
    </div>
  </div>
</div>
