import * as React from "react";
import { FC } from "react";
import {
  Grid,
  IconButton,
  Paper,
  Theme,
} from "@mui/material";
import { makeStyles, createStyles } from '@mui/styles';
import EditIcon from "@mui/icons-material/Edit";
import { useTranslation } from "react-i18next";

type Props = {
  text: string;
  prefix?: string;
  onEdit: () => void;
  disabled
};

const useStyles = makeStyles(({ spacing, palette }: Theme) =>
  createStyles({
    root: {},
    dialogContent: {
      paddingBottom: "1rem",
    },
    typography: {
      color: "white",
      fontSize: 12,
      position: "absolute",
      top: "20%",
    },
    paper: {
      backgroundColor: palette.grey[300],
      marginBottom: spacing(2),
      marginLeft: spacing(1),
      marginRight: spacing(1),
    },
    mediaContainer: {
      display: "block",
      padding: "2vh",
    },
    image: {},
    text: {
      display: "flex",
      justifyContent: "space-between",
      padding: spacing(2),
      minHeight: spacing(4),
    },
  })
);

const ReportDeviationMessage: FC<Props> = ({ text, prefix = '', onEdit, disabled }) => {
  const classes = useStyles();

  const { t } = useTranslation();

  return text ? (
    <>
      <Paper className={classes.paper}>
        <Grid container>
          <Grid item xs={12}>
            <div className={classes.text}>
              {prefix} {t(text.replace(/\.$/g, ""))}
              <IconButton onClick={() => onEdit()} disabled={disabled}>
                <EditIcon />
              </IconButton>
            </div>
          </Grid>
        </Grid>
      </Paper>
    </>
  ) : <></>;
};

export default ReportDeviationMessage;
