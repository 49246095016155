import React from "react";
import { Theme } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import PageHeader from "../../react-components/PageHeader";
import { SurveillanceReportsContext } from "../SurveillanceReportsReactWrapper";
import { useEntityDeviations } from "../services/entityDeviationServiceUtils";
import { EntityDeviationReport } from "./EntityDeviationReport";
import { TempReport } from "../../reports/tempreport";

const styles = ({ spacing }: Theme) =>
  createStyles({
    root: {},
    topicGroup: {
      paddingLeft: spacing(8),
      paddingRight: spacing(8),
    },
  });

const useStyles = makeStyles(styles);

type Props = {};

const SurveillanceReportsPage: React.FC<Props> = () => {
  const { user } = React.useContext(SurveillanceReportsContext);
  const siteId = user.siteId;

  const { entityDeviations, tempReport } = useEntityDeviations(siteId);

  const { t } = useTranslation();
  const classes = useStyles();

  console.log(entityDeviations, tempReport);

  return (
    <div className={classes.root}>
      <PageHeader title={t('surveillanceDeviationReports.pageTitle')} />
      {entityDeviations?.map((ed) => (
        <EntityDeviationReport
          tempReport={TempReport.deSerialize(tempReport)}
          entityDeviation={ed}
          key={ed.entity.id}
        />
      ))}
    </div>
  );
};

export default SurveillanceReportsPage;
