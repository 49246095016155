import * as React from "react";
import * as ReactDOM from "react-dom";
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { ThemeProvider } from "@mui/material";
import { Subscription } from "rxjs";
import { Chanels } from "../chanels";
import { theme } from "../react-theme";
import { Guid } from "../system/guid";
import { ServiceBusService } from "../system/service-bus.service";
import { MsalService } from "@azure/msal-angular";
import { Maintenance } from "./Maintenance";

const containerElementName = "myReactComponentContainer";

@Component({
  selector: "app-maintenance-component",
  template: `<span #${containerElementName}></span>`,
  encapsulation: ViewEncapsulation.None,
})
export class MaintenanceComponent implements OnChanges, OnDestroy, AfterViewInit {
  @ViewChild(containerElementName) containerRef: ElementRef;

  siteId: Guid;

  public isLoadingEntities = true;

  private userSubscription: Subscription;

  private user: any;

  constructor(
    private bus: ServiceBusService,
    private msalService: MsalService
  ) {}

  ngOnInit() {
    console.log(React); // DO NOT REMOVE - to prevent aot from removing react
    this.userSubscription = this.bus
      .chanelObservable(Chanels.USER_CHANEL)
      .subscribe((user) => {
        this.user = user;
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.render();
  }

  ngAfterViewInit() {
    this.render();
  }

  ngOnDestroy() {
    this.userSubscription.unsubscribe();
    ReactDOM.unmountComponentAtNode(this.containerRef.nativeElement);
  }

  private render() {
    // TODO: MSAL
    const userId = this.user?.adalUser?.oid ?? '';
    ReactDOM.render(
      <ThemeProvider theme={theme}>
        <Maintenance
          userId={userId}
          msalService={this.msalService}
          theme={theme}
        />
      </ThemeProvider>,
      this.containerRef.nativeElement
    );
  }
}

