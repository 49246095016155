import { Component, OnInit, HostListener, ElementRef } from "@angular/core";

import { FiltersComponent } from "./filters/filters.component";
import { DateSelectComponent } from "./date-select/date-select.component";
import { DeviceGraphicComponent } from "./device-graphic/device-graphic.component";
import { DeviationActionsSliderComponent } from "./deviation-actions-slider/deviation-actions-slider.component";
import { TabsComponent } from "./tabs/tabs.component";
import { ChartPeriod } from "../entity/chartperiod";
import { DeviationsModalComponent } from "./deviations-modal/deviations-modal.component";
import { Entity } from "../entity/entity";
import { Guid } from "../system/guid";
import { TempReport } from "../reports/tempreport";

import { EntityService } from "../entity/entity.service";
import { TempreportService } from "../reports/tempreport.service";
import { Chart } from "chart.js";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";

import * as moment from "moment";
import { SiteEntities } from "../entity/siteentities";
import { ServiceBusService } from "../system/service-bus.service";
import { Chanels } from "../chanels";
import { VEvent } from "../signals/vevent";
import { SignalsService } from "../signals/signals.service";
import { RegisterSignal } from "../signals/registersignal";
import { Observable } from "rxjs";
import "chartjs-plugin-zoom";
import "./custom-chart";
import { map } from "rxjs/internal/operators/map";
import { forkJoin } from "rxjs/internal/observable/forkJoin";
import i18n from "../react-components/i18n";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

function ascii(character) {
  return character.charCodeAt(0);
}

@Component({
  selector: "app-report-history",
  templateUrl: "./report-history.component.html",
  styleUrls: ["./report-history.component.scss"],
})
export class ReportHistoryComponent implements OnInit {
  siteEntities: SiteEntities;
  entityList: Array<Entity>;
  entityList$: Observable<Array<Entity>>;
  reports: Array<TempReport>;

  siteId: any;
  entityId: any;
  entityReports: Array<any> = [];
  period: ChartPeriod = ChartPeriod.create("Uke");
  weekChart: Chart = null;
  deviationsCount: number = 0;
  entityMax: number;
  entityMin: number;
  tickColor: any = "#000";
  showreportView: boolean;
  chartDataToShow: number = 0;
  // showSignalsButtonText: string;
  showExtraGraphs: boolean = false;
  chartContext: string = "#canvas";
  disabledForClick: boolean = false;
  user: any = null;
  constructor(
    private entityService: EntityService,
    private tempReportService: TempreportService,
    private signalsService: SignalsService,
    private bus: ServiceBusService,
    private dialog: MatDialog,
    private elementRef: ElementRef
  ) {}

  ngOnInit() {
    this.bus.subscribe(Chanels.USER_CHANEL, (user) => {
      if (user == null) return;
      this.user = user;
      this.siteId = user.siteId;
      this.showreportView = false;

      this.getSiteEntities(user.siteId);
    });
  }

  @HostListener("window:keyup", ["$event"])
  keyEvent(event: KeyboardEvent) {
    if (event.ctrlKey && event.shiftKey && event.keyCode === ascii("H")) {
      this.showExtraGraphs = !this.showExtraGraphs;
    }
  }
  onclickToggleDeviations() {
    this.showreportView = !this.showreportView;
  }
  onclickHideDeviations() {
    this.showreportView = false;
  }
  onclickToggleSignals(newChartDataToShow: number) {
    this.disabledForClick = true;
    this.chartDataToShow = newChartDataToShow;
    this.buildGraphic();
  }

  getSiteEntities(siteId: string) {
    var siteEntities$ = this.entityService.getSiteEntities(siteId);
    this.entityList$ = siteEntities$.pipe(
      map((x) =>
        x
          .getEntities()
          .filter((e) => !e.hide && (e.isTermo() || e.isReptil()))
          .sort((a, b) => (a.name > b.name ? 1 : -1))
      )
    );
    forkJoin([siteEntities$, this.entityList$]).subscribe(
      ([data, x]) => {
        this.siteEntities = data;
        this.entityList = x;
        if (this.entityList.length > 0) {
          this.selectEntity(this.entityList[0].id);
        } else {
          this.refreshUi();
        }
      },
      (error) => {
        this.refreshUi();
      }
    );
  }

  private refreshUi() {
    this.selectPeriod(this.period);
  }

  selectEntity(entityId: any) {
    this.disabledForClick = true;
    if (this.entityReports != null) {
      this.entityReports.length = 0;
    }
    this.entityId = entityId;
    this.setEntityReports();
    // this.entityReports =
    //   this.reports !== undefined ? this.reports.filter((x: TempReport) => x.getMeasurement(this.entityId)) : [];

    // this.countDeviations();
    // this.buildGraphic();
    this.refreshUi();

    this.setMaxMin(entityId);
  }
  setEntityReports() {
    this.entityReports =
      this.reports !== undefined
        ? this.reports.filter((x: TempReport) =>
            x.getMeasurement(this.entityId)
          )
        : [];
  }

  selectPeriod(period: ChartPeriod) {
    this.period = period;
    if (!this.user) return;
    var from = moment(this.period.startDate).subtract(1, "days");
    var to = moment(this.period.endDate).add(1, "days");
    this.tempReportService.getTempReports(this.user.siteId, from, to).subscribe(
      (response: Array<TempReport>) => {
        this.reports = response;
        this.setEntityReports();
        this.buildGraphic();
        this.countDeviations();
      },
      (err) => {
        console.log(err);
      }
    );
  }

  buildGraphic() {
    // Clear chart, if previously created

    if (this.siteEntities == null) {
      return;
    }

    var entity = this.siteEntities.getEntity(this.entityId);

    if (entity == null) {
      return;
    }

    /*
      chartDataToShow:
      0 - only reports
      1 - only signals
      2 - both
    */

    var showReports = this.chartDataToShow == 0; // || this.chartDataToShow == 2;
    var showSignals = this.chartDataToShow > 0;
    var chartDataSets = [];

    if (showReports) {
      chartDataSets.push(this.getChartDatasetForReports());
      this.updateChart(chartDataSets, entity.obj.rules.temperature);
    }
    if (showSignals) {
      this.drawChartForSignals(entity, chartDataSets);
    }
  }

  getChartDatasetForReports(): any {
    if (this.entityReports == undefined) {
      return [];
    }

    this.entityReports = this.entityReports.sort(function (a, b) {
      const bTime = b.getMeasurements()
        ? b.getMeasurements()[0].occuredTime
        : b.createdOn;
      const aTime = a.getMeasurements()
        ? a.getMeasurements()[0].occuredTime
        : a.createdOn;

      return moment(bTime).isBefore(moment(aTime)) ? 1 : -1;
    });

    let chartData = [];

    var reachedPeriod = false;
    var lastOfPreviousPeriod: any;

    for (let item of this.entityReports) {
      let reportDate = moment(item.occuredTime);

      if (reportDate.isBetween(this.period.startDate, this.period.endDate)) {
        reachedPeriod = true;
        if (lastOfPreviousPeriod != null) {
          // Add last of item of previous period for chart continuity
          chartData.push(lastOfPreviousPeriod);
          lastOfPreviousPeriod = null;
        }
        chartData.push(item);
      } else if (reachedPeriod) {
        // Outside current period, add for chart continuity and abort
        chartData.push(item);
        break;
      } else {
        // Not yet reached period, set item as currently last of previous period
        lastOfPreviousPeriod = item;
      }
    }

    let data: any = chartData.reduce(
      (acc, curr) => {
        var measurement = curr.getMeasurement(this.entityId);
        if (measurement) {
          acc.measurements.push({
            x: moment(measurement.occuredTime),
            y: measurement.value,
          });
        }
        var deviation = curr.getDeviation(this.entityId);
        if (deviation) acc.deviations.push(deviation);
        return acc;
      },
      { measurements: [], deviations: [] }
    );

    //TODO: Fix translation here!

    return {
      label: i18next.t("Reports"),
      data: data.measurements,
      fill: false,
      borderColor: "#87BCBF",
      lineTension: 0.1,
      pointHoverRadius: 5,
    };
  }

  getChartDatasetForSignals(events: VEvent[]): Array<any> {
    // Sort values
    events = events.sort(function (a: VEvent, b: VEvent) {
      return moment(b.timestamp).isBefore(moment(a.timestamp)) ? 1 : -1;
    });

    // Generate chart values
    var charts = [];
    let chartValues = events.map((event) => ({
      x: moment(event.timestamp),
      y: event.value,
    }));
    charts.push({
      label: i18next.t("Measurements"),
      data: chartValues,
      fill: false,
      borderColor: "#B82",
      lineTension: 0.1,
      borderWidth: 1,
      pointRadius: 0,
      pointHoverRadius: 5,
    });
    if (this.showExtraGraphs) {
      let shortAverage = this.showExtraGraphs
        ? this.getMovingAverageGraph(chartValues, events, 60)
        : [];
      let longAvgValues = this.showExtraGraphs
        ? this.getMovingAverageGraph(chartValues, events, 3 * 60)
        : [];
      let trend = this.showExtraGraphs
        ? this.getTrend(chartValues, events)
        : [];
      charts = charts.concat([
        {
          label: "Gjenomsnitt(1t)",
          data: shortAverage,
          fill: false,
          borderColor: "#44f",
          lineTension: 0.1,
          borderWidth: 1,
          pointRadius: 0,
          pointHoverRadius: 5,
        },
        {
          label: "Gjenomsnitt(3t)",
          data: longAvgValues,
          fill: false,
          borderColor: "#000",
          lineTension: 0.1,
          borderWidth: 1,
          pointRadius: 0,
          pointHoverRadius: 5,
        },
      ]);
    }

    return charts;
  }

  private getMovingAverageGraph(
    chartValues: { x: moment.Moment; y: string }[],
    events: VEvent[],
    minutes: number
  ) {
    if (chartValues.length <= 0) return [];
    var averageEvents = [];
    return events.reduce((acc, event) => {
      averageEvents.push(event);
      if (averageEvents.length == 1) return acc;
      const firstInAverage = moment(averageEvents[0].timestamp).add(
        minutes,
        "minutes"
      );
      const last = moment(averageEvents[averageEvents.length - 1].timestamp);
      if (firstInAverage.isAfter(last)) return acc;
      const sum = averageEvents.reduce((sum, e) => sum + e.value, 0);
      acc.push({
        x: moment(event.timestamp),
        y: Math.round((sum / averageEvents.length) * 100) / 100,
      });
      averageEvents.shift(); //dequeue
      return acc;
    }, []);
  }

  private getTrend(
    chartValues: { x: moment.Moment; y: string }[],
    events: VEvent[]
  ) {
    if (chartValues.length <= 0) return [];
    var averageEvents = [];
    return events.reduce((acc, event) => {
      averageEvents.push(event);
      if (averageEvents.length == 1) return acc;

      const first = moment(averageEvents[0].timestamp);
      const last = moment(averageEvents[averageEvents.length - 1].timestamp);
      const timediff = moment.duration(last.diff(first));
      const diff = averageEvents[1].value - averageEvents[0].value;
      const dydx = 4 * (diff / timediff.asMinutes());

      acc.push({ x: moment(last), y: Math.round(dydx * 100) / 100 });
      averageEvents.shift(); //dequeue
      return acc;
    }, []);
  }

  drawChartForSignals(entity: Entity, chartDatasets: any[]): any {
    var from = this.period.startDate;
    var to = this.period.endDate;
    this.signalsService
      .GetSignalsForEntity(entity.id, from, to)
      .subscribe((signals: Array<VEvent>) => {
        var chart = this.getChartDatasetForSignals(signals);
        this.updateChart(chart, entity.obj.rules.temperature);
      });
  }

  updateChart(chartData: any[], rules: any) {
    const { min, max, limits } = this.getLimitsObject(rules);
    var sugestedMax = this.entityMax || 0;
    var sugestedMin = this.entityMin || 0;
    var ranges = [
      {
        begin: this.entityMax,
        end: this.entityMin,
        color: "rgba(0, 255, 0, 0.3)",
      },
    ];
    if (limits) {
      var exceptionRanges =
        limits &&
        limits.map((x) => ({
          begin: parseInt(x.max),
          end: parseInt(x.min),
          color: "rgba(255, 255, 0, 0.3)",
        }));
      exceptionRanges.forEach((x) => {
        sugestedMax = Math.max(x.begin, sugestedMax);
        sugestedMin = Math.min(x.end, sugestedMin);
      });
      ranges = ranges.concat(exceptionRanges);
    }

    // let safeColor = 'rgba(20, 150, 40, 0.2)';
    if (this.weekChart) {
      // this.weekChart.clear();
      this.weekChart.destroy();
      this.weekChart = null;
      // const cntx = this.elementRef.nativeElement.querySelector(this.chartContext);

      //this.weekChart = new Chart(cntx , {type: 'LineWithLine',data:{datasets:[],ranges:[]}});
      console.log("Clear chart");
    }
    // setTimeout(()=>{
    const cntx = this.elementRef.nativeElement.querySelector(this.chartContext);
    this.weekChart = new Chart(cntx, {
      type: "LineWithLine",
      data: {
        datasets: chartData,
        // @ts-ignore
        yHighlightRange: ranges,
      },
      options: {
        animation: {
          duration: 0,
        },
        tooltips: {
          intersect: false,
        },
        scales: {
          yAxes: [
            {
              display: true,
              ticks: {
                suggestedMax: sugestedMax + 2,
                suggestedMin: sugestedMin - 2,
                // beginAtZero: true
              },
            },
          ],
          xAxes: [
            {
              type: "time",
              time: {
                unit: "day",
                min: this.period.startDate.toLocaleString(),
                max: this.period.endDate.toLocaleString(),
              },
            },
          ],
        },
        plugins: {
          zoom: {
            pan: {
              enabled: true,
              mode: "xy",
            },
            zoom: {
              enabled: true,
              mode: "y",
            },
          },
        },
      },
    });
    this.disabledForClick = false;
    console.log("New chart");
    // });
  }

  showDeviationsModal() {
    this.dialog.open(DeviationsModalComponent, {
      width: "500px",
      data: {
        entityReports: this.entityReports,
        entityId: this.entityId,
        entityMin: this.entityMin,
        entityMax: this.entityMax,
      },
    });
  }

  countDeviations() {
    if (this.reports == null) {
      this.deviationsCount = 0;
      return;
    }
    this.deviationsCount = this.reports.reduce(
      (acc, curr) =>
        acc +
        curr
          .getDeviations()
          .filter(
            (d) =>
              moment(d.occuredTime) >= this.period.startDate &&
              moment(d.occuredTime) < this.period.endDate
          ).length,
      0
    );
  }

  setMaxMin(entityId: any) {
    var item = this.siteEntities.getEntity(entityId);
    let newLocal = this.getLimitsObject(item.obj.rules.temperature);
    if(item.obj.termoObjectType){
      newLocal = this.getLimitsObject(item.obj.termoObjectType.rules.GREEN);
    }
    this.entityMax = Number(newLocal?.max) || 0;
    this.entityMin = Number(newLocal?.min) || 0;
  }

  private getLimitsObject(rules: any) {
    return this.limitIsDefined(rules)
      ? rules
      : this.limitIsDefined(rules?.temperature)
      ? rules.temperature
      : this.limitIsDefined(rules?.humidity)
      ? rules.humidity
      : { max: 0, min: 0 };
  }

  private limitIsDefined(limit: any) {
    return (
      limit?.max != undefined &&
      limit?.min != undefined &&
      !(limit?.max == 0 && limit?.min == 0)
    );
  }
}
