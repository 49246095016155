import {
  Box,
  Theme,
  Typography,
} from "@mui/material";
import { makeStyles, createStyles } from '@mui/styles';
import * as React from "react";
import { useTranslation } from "react-i18next";
import { take } from "rxjs/operators";
import { EntityService } from "../../entity/entity.service";
import Loading from "../../react-components/Loading";
import { SignalsService } from "../../signals/signals.service";
import CleanCard from "./CleanCard";

type Props = {
  user: any;
  entityService: EntityService;
  signalsService: SignalsService;
};

type EntityType = {
  id: string;
  name: string;
  openCloseInfo: OpenCloseInfo;
};

type OpenCloseInfo = {
  limit?: number;
  countSinceLastCleaning?: number;
  countPeriodTotal?: number;
  usagePercentage?: number;
  usageColorHex?: string;
  usageColorHighlightHex?: string;
  lastSignalDate?: Date;
  lastState?: string;
};

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: "1rem",
    },
    header: {
      display: "flex",
      justifyContent: "center",
      marginBottom: "2rem",
    },
    pageTitle: {
      textAlign: "center",
      flexGrow: 1,
    },
    homeIcon: {
      color: theme.palette.primary.main,
    },
    loadingContainer: {
      marginTop: "6em",
    },
  });

const useStyles = makeStyles(styles);

const CleanEntities: React.FC<Props> = ({
  user,
  entityService,
  signalsService,
}) => {
  const classes = useStyles();
  const [entities, setEntities] = React.useState<EntityType[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const { t } = useTranslation();
  // get all clean entities in user site
  const entitiesSubscriber = () => {
    setLoading(true);
    entityService
      .getSiteEntities(user.siteId)
      .pipe(take(1))
      .subscribe((siteEntities: any) => {
        let openCloseEntities = siteEntities
          .getEntities()
          .filter((e) => e.isOpenClose());
        setEntities(
          openCloseEntities.map((e) => {
            let rules = e.obj.rules.door || e.obj.rules;
            console.log(e)
            return {
              id: e.id,
              name: e.name,
              hide: e.hide,
              disabled: e.disabled,
              openCloseInfo: {
                limit: rules.max,
                countSinceLastCleaning: 0,
                countPeriodTotal: 0,
              },
            };
          })
        );
        setLoading(false);
      });
  };

  React.useEffect(() => {
    entitiesSubscriber();
  }, []);

  return (
    <div className={classes.root}>
      <Box className={classes.header}>
        <a className={classes.homeIcon} href="/">
          <i className="material-icons">home</i>
        </a>
        <Typography variant="h1" className={classes.pageTitle}>
          {t("CleanComponent.title")}
        </Typography>
      </Box>
      {loading ? (
        <Box className={classes.loadingContainer}>
          <Loading />
        </Box>
      ) : (
        entities.map((item: any, index: number) => (
          (item.hide || item.disabled) ? <></> : 
          <CleanCard
            key={index}
            entity={item}
            signalsService={signalsService}
          />
        ))
      )}
    </div>
  );
};

export default CleanEntities;
