import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { EntityService } from "../../entity/entity.service";
import { Guid } from "../../system/guid";
import * as moment from "moment";
import { ServiceBusService } from "../../system/service-bus.service";
import { Chanels } from "../../chanels";
import { SiteEntities } from "../../entity/siteentities";
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import * as FileSaver from 'file-saver';
import { Entity } from "../../entity/entity";
import { ObservableAxios, observableAxios } from "../../rxjs-axios";

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';  
const EXCEL_EXTENSION = '.xlsx';

@Component({
  selector: "entities-statistics",
  templateUrl: "./entities-statistics.component.html",
  styleUrls: ["./entities-statistics.component.scss"]
})
export class EntitiesStatisticsComponent implements OnInit {
  siteId: Guid;

  private statisticsStartDate: moment.Moment = undefined;

  public periods = new Array<moment.Moment>();
  public cleanPeriods = new Array<moment.Moment>();
  public isPeriodsLoaded = false;
  public isCleanEnabled = false;

  private readonly http: ObservableAxios = observableAxios;

  constructor(
    private router: Router,
    private entityService: EntityService,
    private bus: ServiceBusService,
  ) {
  }

  ngOnInit() {
    this.bus.subscribe(Chanels.USER_CHANEL, user => {
      if (user == null) return;
      this.siteId = user.siteId;
      this.getSiteEntities(user.siteId);
    });
  }

  private getSiteEntities(siteId: any) {
    this.entityService.getSiteEntities(siteId).subscribe((entities: SiteEntities) => {
      entities.getEntities().forEach(entity => {
        if (!this.statisticsStartDate || entity.createdOn < this.statisticsStartDate) {
          this.statisticsStartDate = moment(entity.createdOn);
        }
      });

      this.setupCleanPeriods(entities.getEntities())

      this.createPeriods();
    }, err => {
      if (err.response.status == 404) {
        // No entities found for site
      } else {
        console.log("Error retrieving entities", err);
      }
      this.isPeriodsLoaded = true;
    });
  }

  private createPeriods() {
    console.log("Clean periods:", this.cleanPeriods);

    if (!this.statisticsStartDate) {
      return;
    }

    var period = moment(this.statisticsStartDate).utc().startOf('month');
    var currentMonth = moment().utc().startOf('month');

    while (period <= currentMonth) {
      this.periods = [moment(period), ...this.periods];

      period.add(1, 'month');
    }

    this.isPeriodsLoaded = true;
  }

  private setupCleanPeriods(entities: Entity[]) {
    this.isCleanEnabled = entities.some(e => e.isOpenClose());
    console.log("Clean enabled: " + this.isCleanEnabled + ". Entities:", entities);

    if (this.isCleanEnabled) {
      var currentPeriod = moment().startOf('month');

      this.cleanPeriods.push(moment(currentPeriod));
      this.cleanPeriods.push(moment(currentPeriod).add(-1, "M"));
      this.cleanPeriods.push(moment(currentPeriod).add(-2, "M"));
      this.cleanPeriods.push(moment(currentPeriod).add(-3, "M"));
      this.cleanPeriods.push(moment(currentPeriod).add(-4, "M"));
      this.cleanPeriods.push(moment(currentPeriod).add(-5, "M"));
    }
  }

  getEntitiesExcelReport(month: Number, year: Number) {
    let tempreportUrl = environment.dependencies.tempreports_api.url + "/api/tempreports";
    let url = `${tempreportUrl}/GetEntitiesExcelReports/${this.siteId}/${month}/${year}`;
    this.goTo(url);
  }

  getCleanExcelReport(month: Number, year: Number) {
    let tempreportUrl = environment.dependencies.tempreports_api.url + "/api/tempreports";
    let url = `${tempreportUrl}/CleanExcelReport/${this.siteId}/${month}/${year}`;
    this.goTo(url);
  }

  getExcelReport(deviations: boolean = false) {
    let tempreportUrl = environment.dependencies.tempreports_api.url + "/api/tempreports";
    let url = `${tempreportUrl}/GetExcelReports/${this.siteId}?deviationsOnly=${deviations}`;
    this.goTo(url);
  }

  private goTo(url: string) {
    const httpOption: Object = {
      observe: 'response',
      responseType: 'arraybuffer'
    };

    this.http.get(url,httpOption).subscribe((result: any)=>{
      let fileName = "Excel";
      const data: Blob = new Blob([result], {type: EXCEL_TYPE});  
      FileSaver.saveAs(data, fileName + '_export_' + new  Date().getTime() + EXCEL_EXTENSION);
    });
  }

  routing(route: string) {
    this.router.navigate([route]);
  }
}
