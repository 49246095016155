<div class="_content">
  <div class="content__heading">
    <a class="content__heading__go-back" [routerLink]="['/entities']" routerLinkActive="router-link-active">
      <i class="material-icons">keyboard_arrow_left</i>
    </a>
    <h1>Opprett ny enhet</h1>
  </div>

  <app-edit-entity-form ></app-edit-entity-form>
</div>
