<div *ngIf="shouldShowMenu">
  <app-header></app-header>
  <a class="menu-link" (click)="sidenav.toggle()">
    <i class="material-icons"> menu </i>
  </a>
</div>

<mat-sidenav-container autosize>
  <mat-sidenav #sidenav>
    <app-menu #appMenu [sidenav]="sidenav"></app-menu>
  </mat-sidenav>
  <mat-sidenav-content>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
<app-maintenance-component></app-maintenance-component>
