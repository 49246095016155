import { Guid } from "../system/guid";
import { Moment } from "moment";
import * as moment from "moment";

export class SignalEvent{
  constructor(public id:Guid, public type: string, public sourceId:string, public timestamp:Moment){

  }
}
export class VEvent extends SignalEvent {
  constructor(id: Guid, type: string, sourceId: string, timestamp: Moment, public value:string){
    super(id, type, sourceId, timestamp);
  }
  public toString():string{
      return `Value: ${this.value}` ;
  }
  public static deSerialize(e:any):VEvent{
    return new VEvent(e.id,e.type,e.sourceId,moment(e.timestamp),e.value);
  }
}

export enum SignalTagType
{
  Start = 0,
  Stop = 1
}

export enum SignalTagFunction
{
  Temp = 0,
  Door = 1,
  Clean = 2,
  Touch = 3,
  Humidity = 4,
  PPM = 5,
  Pressure = 6
}
export function printSignalTagFunction(tagFunction: SignalTagFunction): string {
  switch (tagFunction) {
    case SignalTagFunction.Temp:
      return "Temperatur";
    case SignalTagFunction.Door:
      return "Dør";
    case SignalTagFunction.Clean:
      return "Vask";
    case SignalTagFunction.Touch:
      return "Trykknapp";
    case SignalTagFunction.Humidity:
      return "Fuktighet"
    default:
      return `${tagFunction}`;
  }
}
export class SignalTag{
  constructor(
    private readonly id:string,
    private readonly type: string,
    public readonly sourceId:string,
    public readonly timestamp:Moment,
    public readonly entityId: string,
    public readonly tagType:SignalTagType,
    public readonly tagFunction: SignalTagFunction ){

  }
  public toString():string{
      return `Value: ${this.sourceId} ${this.tagType}` ;
  }

  public static deSerialize(e:any):SignalTag {
    return new SignalTag(
      e.id,
      e.type,
      e.sourceId,
      moment(e.timestamp),
      e.entityId,
      e.tagType,
      e.tagFunction
    );
  }
}
