import * as React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Theme,
} from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import { FormikProps, useFormikContext } from "formik";
import FormikField from "../../react-components/FormikField";
import { useTranslation } from "react-i18next";
import { IValues } from "../interfaces";
import DialogTitle from "../../react-components/DialogTitle";
import FileUpload, {
  extractExtension,
} from "../../react-components/FileUpload/FileUpload";
import { AttachmentFile } from "../../react-components/FileUpload/attachment";
import { Guid } from "../../system/guid";
import { useImageUpload } from "../../checklist-react/services/checklistService";
import LinearProgressWithLabel from "../../dashboard-react/ChecklistComponent/components/LinearProgressWithLabel";
import { ProgressBar } from "../../react-components/ProgressBar";

type Props = {
  open: boolean;
  onClose: () => void;
  sourceId: string;
};

const styles = ({ spacing }: Theme) =>
  createStyles({
    row: {
      display: "flex",
    },
    button: {
      marginBottom: spacing(2),
      marginTop: spacing(2),
    },
    error: {
      color: 'red',
      marginLeft: '1em'
    }
  });

const useStyles = makeStyles(styles);

const ObjectImageDialog = ({ open, onClose, sourceId }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { submitForm, values, setFieldValue }: FormikProps<IValues> = useFormikContext();

  const [hasSubmitted, setHasSubmitted] = React.useState(false);
  const [attachments, setAttachments] = React.useState<AttachmentFile>();
  const [progress, setProgress] = React.useState<number>();

  const searchIndex = values.objectImages.findIndex(
    (v) => v.sourceId === sourceId
  );
  const searchIndexEmpty = values.objectImages.findIndex(
    (v) => v.sourceId === ''
  );
  const emptyIndex = searchIndexEmpty === -1 ? values.objectImages.length : searchIndexEmpty;
  const index = searchIndex === -1 ? emptyIndex : searchIndex;
  const objectImage = values.objectImages[index];

  const handleMediaUpload = async (onSuccess: (images: string[]) => void) => {
    const img = AttachmentFile(attachments);

    if (img.hasAttachments) {
      const images = await Promise.all(
        img.files.map(async (file) => {
          const imgId = Guid.newGuid().toString();
          const extension = extractExtension(file.name);
          await useImageUpload(img.dataFor(file), imgId, extension, 'entity', setProgress);
          return `${imgId}${extension}`;
        })
      );
      onSuccess(images);
    } else {
      onSuccess([]);
    }
  };

  React.useEffect(() => {
    setFieldValue(`objectImages.${index}.sourceId`, sourceId);
  }, [sourceId, index]);

  React.useEffect(() => {
    const warningText =
      "The image is still being uploaded, are you sure you wish to leave this page?";
    const handleWindowClose = (e: BeforeUnloadEvent) => {
      if (progress === undefined) return;
      e.preventDefault();
      return (e.returnValue = warningText);
    };
    window.addEventListener("beforeunload", handleWindowClose, {
      capture: true,
    });
    return () => {
      window.removeEventListener("beforeunload", handleWindowClose, {
        capture: true,
      });
    };
  }, [progress]);

  const handleSubmit = () => {
    if (objectImage?.name && attachments) {
      handleMediaUpload(images => {
        setFieldValue(`objectImages.${index}.imageUrl`, images[0]);
        submitForm();
        onClose();

        setHasSubmitted(false);
        setAttachments(undefined);
        setProgress(undefined);
      });
    }
  };

  const hasNameError = !values.objectImages[index]?.name;
  const hasImageError = !attachments;

  return (
    <Dialog open={open} onClose={() => {
      if (progress === undefined) {
        onClose()
      }
    }}>
      <DialogTitle onClose={() => {
        if (progress === undefined) {
          onClose()
        }
      }}>{t(`entityForm.objectImage.title`)}</DialogTitle>
      <DialogContent>
        <FormikField name={`objectImages.${index}.name`} label={t(`entityForm.objectImage.name`)} />
        {hasSubmitted && hasNameError && <p className={classes.error}>{t("entityForm.objectImage.nameError")}</p>}
        <FileUpload
          attachments={AttachmentFile(attachments)}
          onAttachmentsChange={(value) => setAttachments(value)}
        />
        {hasSubmitted && hasImageError && <p className={classes.error}>{t("entityForm.objectImage.imageError")}</p>}
        {progress != null && <ProgressBar value={progress} />}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={() => {
          if (!hasSubmitted) {
            setHasSubmitted(true);
            handleSubmit();
          }
        }}>
          {t(`entityForm.objectImage.done`)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ObjectImageDialog;
