import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { catchError, Observable } from "rxjs";
import { Entity } from "./entity";
import { SignalsService } from "../signals/signals.service";
import { Guid } from "../system/guid";
import { RegisterSignal } from "../signals/registersignal";
import { SiteEntities } from "./siteentities";
import { TermoObject } from "./termo-object";
import { OpenCloseObject } from "./open-close-object";
import { ReptileObject } from "./reptile-object";
import { map } from "rxjs/internal/operators/map";
import { mergeMap } from "rxjs/internal/operators/mergeMap";

import { of } from "rxjs/internal/observable/of";
import { take } from "rxjs-compat/operator/take";
import { observableAxios, ObservableAxios } from "../rxjs-axios";
@Injectable({
  providedIn: "root",
})
export class EntityService {
  private readonly base_url = environment.dependencies.entity_api.url;
  private readonly entities_url = this.base_url + "/api/entities";
  private readonly termoobject_url =
    environment.dependencies.termoobject_api.url + "/api/termoobjects";
  private readonly reptileobject_url =
    environment.dependencies.termoobject_api.url + "/api/reptileobjects";
  private readonly opencloseobject_url =
    environment.dependencies.termoobject_api.url + "/api/opencloseobjects";

  private readonly http: ObservableAxios = observableAxios;

  constructor(private signalsService: SignalsService) { }
  public getEntities(): Observable<Array<Entity>> {
    return this.http.get(`${this.entities_url}`).pipe(
      mergeMap((data: Array<any>) => {
        return this.mapSignalsToEntities(data);
      })
    );
  }

  public getEntity(entityId: Guid): Observable<Entity> {
    //this fails if there is no signals
    return this.http
      .get(`${this.entities_url}/${entityId}`)
      .pipe(
        mergeMap((data: any) => {
          return this.mapSignalsToEntities([data]);
        })
      )
      .pipe(map((x) => (x.length > 0 ? x[0] : null)));
  }

  public getEntityWithoutSignals(entityId: Guid): Observable<Entity> {
    return this.http.get(`${this.entities_url}/${entityId}`).pipe(
      map((data: any) => {
        return Entity.create(data);
      })
    );
  }
  public getEntityWithSignals(entityId: string): Observable<Entity> {
    return this.http
      .get(`${this.entities_url}/${entityId}`)
      .pipe(
        mergeMap((data: any) => {
          return this.mapAllSignalsToEntities([data]);
        })
      )
      .pipe(map((x) => (x.length > 0 ? x[0] : null)));
  }

  public getEntitiesForSite(siteId: string): Observable<Array<Entity>> {
    return this.http.get(`${this.entities_url}/site/${siteId}`).pipe(
      mergeMap((data: Array<any>) => {
        return this.mapSignalsToEntities(data);
      })
    );
  }
  public getEntitiesForSiteNoSignals(
    siteId: string
  ): Observable<Array<Entity>> {
    return this.http.get(`${this.entities_url}/site/${siteId}`).pipe(
      mergeMap((data: Array<any>) => {
        return of(data.map(Entity.create));
      })
    );
  }
  public getSiteEntities(siteId: string): Observable<SiteEntities> {
    return this.getEntitiesForSiteNoSignals(siteId).pipe(
      map((x) => {
        return new SiteEntities(x);
      })
    );
  }

  public getEntitiesForSiteWithAllSignals(
    siteId: string
  ): Observable<Array<Entity>> {
    return this.http.get(`${this.entities_url}/site/${siteId}`).pipe(
      mergeMap((data: Array<any>) => {
        return this.mapAllSignalsToEntities(data)
      })
    );
  }

  public putEntity(entityId: string, entity: Entity): any {
    return this.http.put(`${this.entities_url}/${entityId}`, entity);
  }

  public putThermoObject(id: string, termoObject: TermoObject) {
    return this.http.put(`${this.termoobject_url}/${id}`, termoObject);
  }

  public putReptileObject(id: string, reptileObject: ReptileObject) {
    return this.http.put(`${this.reptileobject_url}/${id}`, reptileObject);
  }
  public putOpenCloseObject(id: string, openCloseObject: OpenCloseObject) {
    return this.http.put(`${this.opencloseobject_url}/${id}`, openCloseObject);
  }

  public postEntity(entity: Entity): any {
    return this.http.post(`${this.entities_url}`, entity);
  }

  public postThermoObject(termoObject: TermoObject) {
    return this.http.post(`${this.termoobject_url}`, termoObject);
  }
  public postReptileObject(reptileObject: ReptileObject) {
    return this.http.post(`${this.reptileobject_url}`, reptileObject);
  }
  public postOpenCloseObject(openCloseObject: OpenCloseObject) {
    return this.http.post(`${this.opencloseobject_url}`, openCloseObject);
  }

  private mapSignalsToEntities(data: Array<any>): Observable<Array<Entity>> {
    var res = data.map((d) => {
      console.log(d);
      return Entity.create(d);
    });

    return this.getSignals(res);
  }

  private mapAllSignalsToEntities(data: Array<any>): Observable<Array<Entity>> {
    return this.getAllSignals(data);
  }

  private getSignals(x: Array<Entity>): Observable<Array<Entity>> {
    var ids = x.map((e) => e.id.toString());
    console.log("getSignals", ids);
    var signals = this.signalsService.GetSignalsForEntities(ids);
    console.log("Signals: ", signals);
    return signals.pipe(
      map((data) => {
        console.log(data);
        x.map((m) => {
          m.signal = [data.get(m.id.toString()) as RegisterSignal];
        });
        return x;
      })
    );
  }

  private getAllSignals(x: Array<Entity>): Observable<Array<Entity>> {
    var ids = x.map((e) => e.id.toString());
    var signals = this.signalsService.GetAllSignalsForEntities(ids);
    return signals.pipe(
      map((data) => {
        x.map((m) => {
          m.signal = data.get(m.id.toString());
        });
        return x;
      })
    ).pipe(
      catchError(err => {
        if (err.response.status === 404) {
          return of(x);
        } else {
          return of(x);
        }
      })
    );;
  }
}
