import { Paper, Theme } from "@mui/material";
import { makeStyles, createStyles } from '@mui/styles';
import { Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

type Props = {
  checklist: any;
};

const useStyles = makeStyles(({ spacing }: Theme) =>
  createStyles({
    root: {
      padding: spacing(1),
    },
    item: {
      paddingTop: spacing(0.5),
      paddingBottom: spacing(0.5),
      marginTop: spacing(1.5),
    }
  })
);

const EditChecklistInfo = ({ checklist }: Props) => {
  const classes = useStyles();
  return (
    <Link to={`/edit-checklist/${checklist.assignedId}`}>
      <Paper className={classes.item}>
        <Typography className={classes.root} variant="h3">{checklist.name}</Typography>
      </Paper>
    </Link>
  );
};

export default EditChecklistInfo;
