import { Pipe, PipeTransform } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Entity } from "./entity/entity";

@Pipe({
  name: "orderEntitiesByNamePipe",
  pure: false,
})
export class OrderEntitiesByNamePipe implements PipeTransform {
  transform(entities: Array<Entity>, args: any): Array<Entity> {
    if (!entities) {
      return entities;
    }

    let sorted = entities.sort((a: Entity, b: Entity) => {
      return a.name < b.name ? -1 : 1;
    });

    return sorted;
  }
}
