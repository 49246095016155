import React from 'react';

const CancelledScheduleIcon = () => (
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="#9E9E9E">
  {/* Clock Shape */}
  <path d="M12,2C6.48,2,2,6.48,2,12s4.48,10,10,10s10-4.48,10-10S17.52,2,12,2z M12,20c-4.41,0-8-3.59-8-8s3.59-8,8-8s8,3.59,8,8S16.41,20,12,20z"/>
  {/* Clock Lines */}
  <line x1="12" y1="6" x2="12" y2="12" stroke="#9E9E9E" strokeWidth="2"/>
  <line x1="12" y1="12" x2="18" y2="18" stroke="#9E9E9E" strokeWidth="2"/>
  {/* Cancelled Diagonal Line */}
  <line x1="2" y1="22" x2="22" y2="2" stroke="black" strokeWidth="2"/>
</svg>
);

export default CancelledScheduleIcon;
