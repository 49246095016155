import { NgModule } from '@angular/core';
import {CommonModule} from "@angular/common";
import { OrderEntitiesByNamePipe } from './order-entities-by-name.pipe';

@NgModule({
  declarations:[OrderEntitiesByNamePipe],
  imports:[CommonModule],
  exports:[OrderEntitiesByNamePipe],
  providers: [OrderEntitiesByNamePipe]
})

export class MainPipe{}