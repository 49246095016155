import {
  Button,
  Grid,
  Theme,
} from "@mui/material";
import { makeStyles, createStyles } from '@mui/styles';
import * as React from "react";
import { useSignatureContext } from "../../signature-react/SignatureContext";
import { useChecklistContext } from "../ChecklistReactWrapper";
import { actions } from "../services/reducer";
import ReportDeviationDialog from "./ReportDeviationDialog";
import { useTranslation } from 'react-i18next'

type Props = {
  state: any;
  dispatch: any;
  checklist: any;
  tenantId: string;
  reportedDeviationEvents: any[];
  canComplete?: boolean;
};

const useStyles = makeStyles(({ spacing }: Theme) =>
  createStyles({
    root: {},
    sessionSelectContainer: {
      display: "flex",
      alignItems: "baseline",
    },
    button: {
      marginRight: spacing(2),
      marginLeft: spacing(2),
    },
    cantCompleteReason: {
      fontSize: '0.9em',
      fontStyle: 'italic',
      marginTop: '0.5em',
      color: 'red'
    }
  })
);

const ChecklistInProgressActions = ({
  state,
  dispatch,
  checklist,
  tenantId,
  reportedDeviationEvents,
  canComplete = true,
}: Props) => {
  const classes = useStyles();
  const { user } = useChecklistContext();
  const {
    state: { activeSignature },
  } = useSignatureContext();

  const { t } = useTranslation();

  const signatureId = activeSignature?.id?.toString();
  return (
    <>
     <Grid container justifyContent="center">
      <Grid item xs={12} sm={6} textAlign="center">
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '1em' }}>
          <Button
            className={classes.button}
            onClick={() => {
              dispatch({
                type: actions.STOP,
                payload: {
                  checklistId: checklist.checklistId,
                  tenantId,
                  assignedId: checklist?.assignedId,
                  userId: user.adalUser.oid,
                  siteId: user.siteId,
                  signatureId: signatureId,
                },
              });
            }}
            variant="contained"
            color="primary"
            disabled={!canComplete}
          >
            {t('checklist.progressActions.completeChecklist')}
          </Button>
          <ReportDeviationDialog
            dispatch={dispatch}
            assignedId={checklist?.assignedId}
            checklistId={checklist?.checklistId}
            variant="Checklist"
            reportedDeviationEvents={reportedDeviationEvents}
          />
          {!canComplete ? <> 
            <br/>
            <p className={classes.cantCompleteReason}>{t('checklist.progressActions.handleDeviationsInfo')}</p>
            </> 
            :
            <></>
          }
        </div>
      </Grid>
    </Grid>
    </>
  );
};

export default ChecklistInProgressActions;
