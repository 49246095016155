import { Component, OnInit, ViewChild, EventEmitter, ViewChildren } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Observable } from "rxjs";
import { Entity } from "../entity";
import { Guid } from "../../system/guid";
import { EntityService } from "../entity.service";
import { ActivatedRoute, Router } from "@angular/router";
import { TermoObject } from "../termo-object";
import * as moment from "moment";
import { ServiceBusService } from "../../system/service-bus.service";
import { Chanels } from "../../chanels";
import { SiteService } from "../site.service";
import { Site } from "../site";
import { SignalsService } from "../../signals/signals.service";
import { RegisterSignal } from "../../signals/registersignal";
import { environment } from "../../../environments/environment.prod";
import { SignalTag, SignalTagType } from "../../signals/vevent";
import { IEntitySpecificsFormHandler } from "../site.specifics.form.handler";
import { ThermoFormComponent } from "../thermo-form/thermo-form-component";


@Component({
  selector: "app-add-entity",
  templateUrl: "./add-entity.component.html",
  styleUrls: ["./add-entity.component.scss"]
})
export class AddEntityComponent implements OnInit {

  @ViewChild('signalSelect') signalSelect: any;
  constructor(
    public formBuilder: FormBuilder
  ) {
  }

  ngOnInit() {
  }
}

enum EntityType {
  thermoObject,
  openCloseObject,
  touchObject,
  reptileObject,
}
