import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class ReportHistoryService {
  chart = []; // This will hold our chart info


  constructor(private _http: HttpClient) { }

  getReportHistory() {
    
  }

}
