import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  Theme,
} from "@mui/material";
import {
  createStyles,
  makeStyles,
} from '@mui/styles'
import { Form, Formik } from "formik";
import React, { useEffect, useState, useContext, FC } from "react";
import { useTranslation } from "react-i18next";
import { Site } from "../entity/site";
import FormikField from "../react-components/FormikField";
import { Guid } from "../system/guid";
import DialogTitle from "../react-components/DialogTitle";
import PinInput from "react-pin-input";
import { take } from "rxjs/operators";
import { SiteSettingsContext } from "../site-settings-react/SiteSettingsReactWrapper";
import { usePostSignatures } from "./SignatureService";
import FindSignature from "./FindSignature";
import { useDebouncedCallback } from "use-debounce";

const styles = ({ spacing }: Theme) =>
  createStyles({
    root: {},
    button: {
      marginTop: spacing(2),
    },
    card: {
      display: 'block',
    },
  });

const useStyles = makeStyles(styles);

type Props = {
  onChange: () => void;
  signatures: any[];
}

const AddSignature: FC<Props> = ({ onChange, signatures }) => {
  const [open, setOpen] = useState(false);
  const [pin, setPin] = useState("");
  const [name, setName] = useState("");
  const { siteService, user } = useContext(SiteSettingsContext);
  const { t } = useTranslation();
  const classes = useStyles();

  const [site, setSite] = useState<Site>();

  useEffect(() => {
    siteService
      .getSite(user.siteId)
      .pipe(take(1))
      .subscribe((res: Site) => {
        setSite(res);
      });
  }, []);

  const toggleOpen = () => {
    setOpen(!open);
    resetPin();
  };

  const handleSubmit = async (values, { resetForm }) => {
    if (values.name != "" && pin != "") {
      console.log("Add signature");
      const id = Guid.newGuid().toString();

      const signature = {
        id: id,
        name: values.name,
        pin: pin,
        tenantId: site.tenantId,
        UserIds: [user.adalUser.oid],
      };
      toggleOpen();
      resetForm();
      await usePostSignatures(signature);
      onChange();
    } else {
      console.log("Missing name or PIN");
    }
  };

  const debounced = useDebouncedCallback((value) => {
    console.log(value);
    setName(value);
  }, 500);

  const resetPin = () => {
    setPin("");
  };

  const onPinComplete = (values) => {
    setPin(values);
  };

  return (
    <>
      <Button
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
          toggleOpen();
        }}
        className={classes.button}
        variant="outlined"
      >
        {t("signature.add")}
      </Button>
      <Dialog open={open} onClose={toggleOpen}>
        <Formik initialValues={{ name: "" }} onSubmit={handleSubmit}>
          {() => (
            <Form
              onChange={(value) => debounced((value.currentTarget.name as any).value)}
            >
              <DialogTitle onClose={toggleOpen}>
                {t("signature.add")}
              </DialogTitle>
              <DialogContent className={classes.card}>
                <FormikField label={"Name"} name="name" />
                <Typography>PIN</Typography>
                <PinInput
                  length={4}
                  initialValue=""
                  onChange={resetPin}
                  type="numeric"
                  inputMode="number"
                  style={{ padding: "10px" }}
                  inputStyle={{ borderColor: "black" }}
                  inputFocusStyle={{
                    borderColor: "black",
                  }}
                  onComplete={onPinComplete}
                  autoSelect={true}
                />
              </DialogContent>
              <DialogActions>
                <Button type="submit" variant="contained" color="primary"
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "flex-end",
                    overflow: "hidden",
                  }}
                >
                  {`${t("editChecklist.submit")}`}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
        <FindSignature
          userId={user.adalUser.oid}
          name={name}
          onChange={onChange}
          signatures={signatures}
        />
      </Dialog>
    </>
  );
};

export default AddSignature;
