<mat-accordion>



  <mat-expansion-panel (opened)="panelOpenState = true"
                       (closed)="panelOpenState = false"
                       *ngFor="let item of deviationModel">

    <mat-expansion-panel-header>
      <mat-panel-title>
        {{item.entity.name}}
      </mat-panel-title>
      <mat-panel-description>
        <span *ngIf="getRulesFromObj(item.entity.obj)?.min">Min: <span class="temperature">{{getRulesFromObj(item.entity.obj)?.min}}</span>,</span>
        <span *ngIf="getRulesFromObj(item.entity.obj)?.max">Max: <span class="temperature">{{getRulesFromObj(item.entity.obj)?.max}}</span>,</span>
        antall avvik: {{item.deviations.length}}
      </mat-panel-description>
    </mat-expansion-panel-header>
    <ul>
      <li *ngFor="let d of item.deviations">
        {{d.deviation.action}} <strong>Dato:</strong> {{d.deviation.occuredTime |  date:'MM/dd/yyyy HH:mm'}} 
        <span *ngIf="d.measurement && (d.measurement.value != null) && (getRulesFromObj(item.entity.obj)?.min || getRulesFromObj(item.entity.obj)?.max)"><strong>MåltTemp: </strong> <span class="temperature">{{d.measurement.value}}</span></span>
      </li>
    </ul>
  </mat-expansion-panel>







</mat-accordion>

