import * as React from "react";
import { useState, useContext, useEffect } from "react";
import {
  Box,
  Button,
  FormControlLabel,
  Switch,
  TextField,
  Tabs,
  Tab,
} from "@mui/material";
import { makeStyles, withStyles } from '@mui/styles';
import RoomCard from "./RoomCard";
import { RoomsContext } from "../RoomsReactWrapper";
import { take } from "rxjs/operators";
import PageHeader from "../../react-components/PageHeader";
import { useTranslation } from "react-i18next";
import Loading from "../../react-components/Loading";
import { Entity } from "../../entity/entity";
import { Route, Navigate, Routes } from "react-router";
import QrCodes from "../../edit-entity-form-react/components/QrCodes";

const useStyles = makeStyles({
  root: {
    padding: "1em",
  },
  actions: {
    display: "flex",
    marginBottom: "2em",
    "& > *": {
      marginRight: "1em",
    },
  },
  searchBar: {
    marginBottom: "2em",
  },
  tabBar: {
    display: "flex",
    backgroundColor: "#87BCBF",
    width: "100%",
  },
  tabBarButton: {
    color: "white !important",
    minWidth: "40px !important",
  },
  pushRight: {
    marginLeft: "auto !important",
  }
});

const Rooms = () => {
  const classes = useStyles();
  const { entityService, user, signalsService } = useContext(RoomsContext);
  const { t } = useTranslation();

  const [entities, setEntities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filtered, setFiltered] = useState([]);
  const [currentTab, setCurrentTab] = useState("all");

  const filterByTab = (currentTab: string, entities: Array<Entity>): Array<Entity> => {
    if (currentTab == "all") {
      return entities;
    } else if (currentTab == "manuel") {
      return entities.filter((entity) => entity.signal === undefined);
    } else if (currentTab == "automatic") {
      return entities.filter((entity) => entity.signal !== undefined);      
    } else if (currentTab == "sensors") {
      return entities.filter((entity) => entity.signal !== undefined&& entity.signal.some((signal) => signal.isDoorClickSensor()));
    } else {
      return [];
    }
  };

  // Update list of entities when currentTab changes
  useEffect(() => {
    let filteredEntities = filterByTab(currentTab, entities);
    setFiltered(filteredEntities);
  }, [currentTab]);

  const changeTab = (event: React.SyntheticEvent, newTab: string) => {
    setCurrentTab(newTab);
  };

  const [filter, setFilter] = useState({
    hidden: false,
    disabled: true,
  });
  
  const getEntities = () =>
    entityService
      .getEntitiesForSiteWithAllSignals(user.siteId)
      .pipe(take(1))
      .subscribe(
        {next:(res) => {
          const sortedEntities = res.sort((a, b) => (a.name > b.name ? 1 : -1));
          setEntities(sortedEntities);
          setFiltered(sortedEntities);
        },
        error:(error) => {
          console.log(error);
        },
        complete: () => {
          setLoading(false);
        }}
      );

  useEffect(() => {
    getEntities();
  }, [filter]);

  const filterEntities = (arr: Array<any>, query: string) => {
    return arr.filter(
      (el) => el.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  };
  const handleSearch = (e: any) => {
    setFiltered(filterEntities(entities, e.target.value));
  };
  
  return (
    <div className={classes.root}>
      <PageHeader title={t("Entities")} />
      <Box className={classes.actions} display="flex">
        <Button variant="contained" color="primary" href="/new-entity">
          {t("New Entity")}
        </Button>

        <FormControlLabel
          control={
            <Switch
              checked={filter.disabled}
              onChange={() =>
                setFilter({ ...filter, disabled: !filter.disabled })
              }
              name="hide"
              color="primary"
            />
          }
          label={t("Disabled") as string}
        />
        <FormControlLabel
          control={
            <Switch
              checked={filter.hidden}
              onChange={() => setFilter({ ...filter, hidden: !filter.hidden })}
              name="hide"
              color="primary"
            />
          }
          label={t("Hidden") as string}
        />
        <Button variant="contained" color="primary" href="/qr-code" className={classes.pushRight}>
          {t("Vis QR-koder")}
        </Button>
      </Box>
      <Box>
        <TextField
          fullWidth
          label={t("Search")}
          className={classes.searchBar}
          variant="outlined"
          onChange={handleSearch}
        />
      </Box>
      <Tabs className={classes.tabBar} value={currentTab} onChange={changeTab} variant="scrollable" allowScrollButtonsMobile>
        <Tab className={classes.tabBarButton} value="all" label="Vis alle" />
        <Tab className={classes.tabBarButton} value="manuel" label="Manuelle" />
        <Tab className={classes.tabBarButton} value="automatic" label="Automatisk" />
        <Tab className={classes.tabBarButton} value="sensors" label="Dør & klikk sensor" />
      </Tabs>
      {loading ? (
        <Loading />
      ) : (
        filtered.map((item, index) => {
          return (
            <RoomCard
              entity={item}
              key={index}
              hide={
                (!filter.hidden && item.hide) ||
                (!filter.disabled && item.disabled)
              }
            />
          );
        })
      )}
    </div>
  );
};

export default Rooms;
