import { FormControlLabel, Switch } from "@mui/material";
import * as React from "react";
import { useDebouncedCallback } from "use-debounce";
import { useSubscribeToTopic, useUnsubscribeToTopic } from "../services/userSubscriptionsService";


type Props = {
  label: string;
  topicKey: string;
  topicId: string;
  subscribed: boolean;
  userId: string;
};

const SubscriptionTopicToggle: React.FC<Props> = ({ subscribed, label, topicKey, topicId, userId }) => {

  const [isSubscribed, setSubscribed] = React.useState(subscribed);

  const handleChange = () => {
    setSubscribed(!isSubscribed);
    sendEvent();
  }

  const sendEvent = useDebouncedCallback(() => {
    const handler = isSubscribed ? useSubscribeToTopic : useUnsubscribeToTopic;
    handler(userId, { topicKey, id: topicId });
  }, 1000);

  return (
    <>
      <FormControlLabel
        control={
          <Switch
            checked={isSubscribed}
            onChange={handleChange}
            name="disabled"
            color="primary"
          />
        }
        label={label}
      />
    </>
  );

};

export default SubscriptionTopicToggle;
