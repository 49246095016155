import {
  ApplicationRef,
  Component,
  OnInit,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TempreportService } from "../../reports/tempreport.service";
import { ServiceBusService } from "../../system/service-bus.service";
import { Chanels } from "../../chanels";
import { TempReport } from "../../reports/tempreport";
import { Entity } from "../../entity/entity";
import { EntityService } from "../../entity/entity.service";
import { SiteEntities } from "../../entity/siteentities";
import { environment } from "../../../environments/environment";
import * as moment from "moment";
import { Measurement } from "../../measurements/measurement";
import {
  ReportDeviationsType,
  RouteWithReportId,
  RouteWithReportType,
} from "../../reports/report-deviations-type";
import { EntityDeviation } from "../../entity-deviation";
import { EntityDeviationService } from "../../entity-deviation-service";
import Chart from "chart.js";
import { ChecklistPeriodStatus, getChecklistCalendar } from "../../checklist-react/services/checklistService";
import { DateTime } from "luxon";
import i18next from "i18next";
import tippy from 'tippy.js';

@Component({
  selector: "dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit {
  // The period report must be handled if there are deviations in the automatic measurements or if there are manual measurements
  public isLoadingPeriodicReport = true;
  public isLoadingPeriodicReports = true;
  public isPeriodicReportActionRequired = false;
  public periodicReportDeviationsCount = 0;
  public handledPeriodicReportDeviationsCount = 0;
  public periodicReportTimestamp: moment.Moment;

  public periodicReportForLastSevenDays: {
    id: string;
    isActionRequired: boolean;
    deviationsCount: number;
    handledDeviationsCount: number;
    timestamp: moment.Moment;
  }[] = [];

  public showWeeklyReports = false;

  public siteId: any;
  public entityCount: string = undefined;
  public entityDeviations: EntityDeviation[] = [];
  public handledDeviationsCount = 0;

  private entities: Entity[];
  private measurements: Measurement[];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private bus: ServiceBusService,
    private entityService: EntityService,
    private tempreportService: TempreportService,
    private entityDeviationsService: EntityDeviationService,
    private changeDetectorRef: ApplicationRef
  ) { }

  ngOnInit() {
    this.bus.subscribe(Chanels.USER_CHANEL, (user) => {
      if (user == null) return;

      this.siteId = user.siteId;

      // Clear variables
      this.isLoadingPeriodicReport = true;
      this.isPeriodicReportActionRequired = false;
      this.periodicReportDeviationsCount = 0;
      this.handledPeriodicReportDeviationsCount = 0;
      this.periodicReportTimestamp = undefined;

      this.entityCount = undefined;
      this.entityDeviations = [];
      this.handledDeviationsCount = 0;

      this.entities = [];
      this.measurements = [];

      // Get entities and data
      this.getTempReportAndEntities(this.siteId);
      this.getPeriodicReport(this.siteId);
      this.getPeriodicReportForLastSevenDays(this.siteId);
      setTimeout(() => {
        this.changeDetectorRef.tick();
      }, 5000);
    });

    this.route.queryParams.subscribe((params) => {
      if (params.showWeeklyReports === "true") {
        this.showWeeklyReports = true;
      } else {
        this.showWeeklyReports = false;
      }
    });
  }

  chartsLoading = {};

  ngAfterViewInit() {
    tippy('#tippy-target', {
      content: `<div style="background-color: rgba(33, 29, 29, 0.9); color: white; padding: 12px; border-radius: 10px;">
      <div>${i18next.t('dashboard.tooltips.routineStatus')}</div>
      <div style="display: flex; gap: 4px; align-items: center; flex-wrap: wrap;">
        <div style="flex-shrink: 0; width: 10px; height: 10px; border-radius: 100%; background-color: #18BD0A;"></div>
        <div style="font-weight: bold;">${i18next.t('dashboard.chartLabels.completed')}:</div>
        <div>${i18next.t('dashboard.chartLabelDescriptions.completed')}</div>
      </div>
      <div style="display: flex; gap: 4px; align-items: center; flex-wrap: wrap;">
        <div style="flex-shrink: 0; width: 10px; height: 10px; border-radius: 100%; background-color: #F1A106;"></div>
        <div style="font-weight: bold;">${i18next.t('dashboard.chartLabels.handled')}:</div>
        <div>${i18next.t('dashboard.chartLabelDescriptions.handled')}</div>
      </div>
      <div style="display: flex; gap: 4px; align-items: center; flex-wrap: wrap;">
        <div style="flex-shrink: 0; width: 10px; height: 10px; border-radius: 100%; background-color: #F02804;"></div>
        <div style="font-weight: bold;">${i18next.t('dashboard.chartLabels.unhandled')}:</div>
        <div>${i18next.t('dashboard.chartLabelDescriptions.unhandled')}</div>
      </div>
    </div>`,
      allowHTML: true,
    }
    )
    this.getChartData('weekly-deviations-chart', DateTime.now().minus({ weeks: 1 }));
    this.getChartData('monthly-deviations-chart', DateTime.now().minus({ months: 1 }));
  }

  getWeekNumber() {
    return moment().isoWeek();
  }

  getMonthName() {
    return moment().format('MMMM');
  }

  getChartData(chartId: string, from: DateTime, to: DateTime = DateTime.now()) {
    this.chartsLoading[chartId] = true;
    getChecklistCalendar(this.siteId, from, to).then((result) => {
      const chartNumbers = result.data.reduce((acc, item) => {
        if (item.periodStatus === ChecklistPeriodStatus.Completed) {
          acc.total++;
          acc.completed++;
        }
        if (item.periodStatus === ChecklistPeriodStatus.UnhandledDeviations) {
          acc.total++;
          acc.unhandledDeviations++;
        } else if (item.periodStatus === ChecklistPeriodStatus.HandledDeviations) {
          acc.total++;
          acc.handledDeviations++;
        }
        return acc;
      }, {
        total: 0,
        completed: 0,
        unhandledDeviations: 0,
        handledDeviations: 0
      });

      this.createChart(chartId, chartNumbers);
      this.chartsLoading[chartId] = false;
    });
  }

  createChart(id: string, chartNumbers: {
    total: number,
    completed: number,
    unhandledDeviations: number,
    handledDeviations: number
  }) {
    const completedPercentage = Math.round(chartNumbers.completed / chartNumbers.total * 100);
    const unhandledDeviationsPercentage = Math.round(chartNumbers.unhandledDeviations / chartNumbers.total * 100);
    const handledDeviationspercentage = 100 - (completedPercentage + unhandledDeviationsPercentage);

    // handle charts
    const data = {
      labels: [
        i18next.t('dashboard.chartLabels.completed') + ` (${completedPercentage}%)`,
        i18next.t('dashboard.chartLabels.unhandled') + ` (${unhandledDeviationsPercentage}%)`,
        i18next.t('dashboard.chartLabels.handled') + ` (${handledDeviationspercentage}%)`,
      ],
      datasets: [{
        data: [chartNumbers.completed, chartNumbers.unhandledDeviations, chartNumbers.handledDeviations],
        backgroundColor: [
          '#18bd09',
          '#f02805',
          '#f0a105',
        ],
        hoverOffset: 4,
      }]
    };

    const canvas = document.getElementById(id) as HTMLCanvasElement;
    if (chartNumbers.total === 0) {
      const ctx = canvas.getContext("2d");
      ctx.font = "15px Arial";
      ctx.fillText('No data available.', 10, 20);
      return;
    }
    new Chart(
      canvas,
      {
        type: 'doughnut',
        data: data,
        options: {
          responsive: true,
          maintainAspectRatio: false,
          cutoutPercentage: 70
        }
      }
    );
  }

  onHandleWeeklyReports(show = true) {
    this.router.navigate([], {
      queryParams: {
        showWeeklyReports: show,
      },
    })
  }

  onHandlePeriodicReport(id?: string) {
    if (id) {
      RouteWithReportId(
        "/report",
        id,
        this.router
      );
    } else {
      RouteWithReportType(
        "/report",
        ReportDeviationsType.HandlePeriodicDeviations,
        this.router
      );
    }
  }

  onHandleCurrentDevations() {
    this.router.navigate(['/surveillance-reports'])
  }

  onShowAllEntities() {
    this.router.navigate(["entities"]);
  }

  private getTempReportAndEntities(siteId: any) {
    this.tempreportService.getSnapshot(siteId).subscribe(
      // Get snapshot reports (actual temp)
      (tempReport) => {
        this.measurements = tempReport.getMeasurements();
        this.getEntitiesAndCheckAlarms(siteId);
      },
      (err) => {
        console.log(err);
        this.getEntitiesAndCheckAlarms(siteId);
      }
    );
  }

  private getEntitiesAndCheckAlarms(siteId: any) {
    this.entityService.getSiteEntities(siteId).subscribe(
      (siteEntities: SiteEntities) => {
        this.entities = siteEntities.getEntities().filter((e) => !e.disabled);
        this.entityCount = `${this.entities.length}`;
      },
      (err) => {
        this.isPeriodicReportActionRequired = false;
        console.log(err);

        if (err.response.status == 404) {
          // Assume no entities
          this.entityCount = "0";
          this.isLoadingPeriodicReport = false;
        }
      }
    );
  }

  private getPeriodicReport(siteId: any) {
    this.tempreportService.getPeriodicReport(siteId).subscribe(
      (report) => {
        this.periodicReportTimestamp = moment(report.createdOn);
        const {
          isActionRequired,
          reportDeviationsCount,
          handledDeviationsCount,
          timestamp,
        } = this.checkIsPeriodReportDeviations(report);

        this.isPeriodicReportActionRequired = isActionRequired;
        this.periodicReportDeviationsCount = reportDeviationsCount;
        this.handledPeriodicReportDeviationsCount = handledDeviationsCount;
        this.periodicReportTimestamp = timestamp;

        this.isLoadingPeriodicReport = false;
      },
      (err) => {
        if (err.response.status == 404) {
          // This happens if no in progress report is found
        } else {
          console.log("Error getting in progress report", err);
        }
        this.isLoadingPeriodicReport = false;
      }
    );
  }

  getWeeklyReportDeviationsCount() {
    return this.periodicReportForLastSevenDays.reduce(
      (acc, report) => acc + report.deviationsCount,
      0
    );
  }

  getWeeklyReportHandledDeviationsCount() {
    return this.periodicReportForLastSevenDays.reduce(
      (acc, report) => acc + report.handledDeviationsCount,
      0
    );
  }

  private getPeriodicReportForLastSevenDays(siteId: any) {
    this.tempreportService.getPeriodicReportForLastSevenDays(siteId).subscribe(
      (periodicReports) => {
        this.periodicReportForLastSevenDays = [];

        periodicReports
          // exclude the most recent one
          .slice(1, periodicReports.length)
          .forEach((report) => {
            const {
              isActionRequired,
              reportDeviationsCount,
              handledDeviationsCount,
              timestamp,
            } = this.checkIsPeriodReportDeviations(report, true); // is in past

            this.periodicReportForLastSevenDays.push({
              id: report.id,
              isActionRequired,
              deviationsCount: reportDeviationsCount,
              handledDeviationsCount,
              timestamp,
            });
          });

        this.isLoadingPeriodicReports = false;
      },
      (err) => {
        if (err?.response?.status == 404) {
          // This happens if no in progress report is found
        } else {
          console.log("Error getting in progress report for a week", err);
        }
        this.isLoadingPeriodicReports = false;
      }
    );
  }

  private checkIsPeriodReportDeviations(report: TempReport, isInPast = false) {
    let isActionRequired = false;
    let reportDeviationsCount = 0;
    let handledDeviationsCount = 0;
    let timestamp = moment(report.createdOn);
    if (report && report.isHandled(isInPast)) {
      reportDeviationsCount = report.getDeviations().length;
      handledDeviationsCount =
        report.getDeviations().length;
      return {
        isActionRequired,
        reportDeviationsCount,
        handledDeviationsCount,
        timestamp,
      };
    }
    if (
      !report ||
      (!report.hasDeviations() && !this.hasManualEntities(report))
    ) {
      isActionRequired = false;
      return {
        isActionRequired,
        reportDeviationsCount,
        handledDeviationsCount,
        timestamp,
      };
    }

    isActionRequired = true;
    reportDeviationsCount = this.countAutomaticValuesDeviations(report);

    return {
      isActionRequired,
      reportDeviationsCount,
      handledDeviationsCount,
      timestamp,
    };

  }

  hasManualEntities(report: TempReport): boolean {
    report.getMeasurements().forEach((measurement) => {
      if (measurement.value == null) {
        // Has manual measurement
        return true;
      }
    });

    // Everything fine
    return true;
  }
  onRefresh() {
    location.reload();
  }
  countAutomaticValuesDeviations(report: TempReport): number {
    return report.getDeviations().length;
  }

  getExcelReport() {
    let tempreportUrl =
      environment.dependencies.tempreports_api.url + "/api/tempreports";
    let url = `${tempreportUrl}/GetExcelReports/${this.siteId}`;
    window.location.href = url; //Use router instead? https://medium.com/@adrianfaciu/using-the-angular-router-to-navigate-to-external-links-15cc585b7b88
  }
}
