export default {
  Yes: "Ja",
  No: "Nei",
  Hour: "Time",
  Day: "Dag",
  Week: "Uke",
  Month: "Måned",
  Previous: "Forrige",
  Next: "Neste",
  to: "til",
  "Edit entity": "Rediger enhet",
  Disabled: "Deaktivert",
  Hidden: "Skjult",
  "The change has been saved": "Endringen din har blitt lagret",
  "Something went wrong! Try again later": "Noe gikk galt! Prøv igjen senere",
  Humidity: "Fuktighet",
  Temperature: "Temperatur",
  Door: "Dør",
  Doors: "Dører",
  "CO2": "CO2",
  Date: "Dato",
  Breakage: "Brekkasje",
  Entities: "Enheter",
  "New Entity": "Ny enhet",
  "Show QR-coder": "Vis QR-koder",
  "New checklist": "Ny sjekkliste",
  "Edit checklists": "Rediger sjekklister",
  Open: "Åpen",
  Close: "Lukk",
  Closed: "Lukket",
  "Greater than": "Mer en",
  "Less than": "Mindre en",
  "Entity count": "Antall enheter",
  "Show all entities": "Vis alle enheter",
  "Deviation handled": "Avvik behandlet",
  "Handle deviations": "Behandle avvik",
  "Handle Deviation": "Behandle avvik / Hindre gjentagende avvik",
  "Routine report": "Rutinerapport",
  "Show Deviations": "Vis avvik",
  "Show Reports": "Vis rapporter",
  "Show Measurements": "Vis målinger",
  "No deviations to report": "Ingen avvik å rapportere",
  "No deviations to handle": "Ingen avvik å behandle",
  Reports: "Rapporter",
  Measurements: "Målinger",
  Deviations: "Avvik",
  handled: "behandlet",
  deviationOrComment: "Legg til avvik eller kommentar",

  "Choose entity": "Velg enhet",
  Min: "Min",
  Max: "Maks",
  Between: "Mellom",
  "Add new sensor": "Legg til ny sensor",

  "Log Out": "Logg ut",
  "Log In": "Logg in",
  "Add telephone number": "Legg til telefonnummer",

  "Dagens avvik": "Dagens avvik",
  "Ukens avvik": "Ukens avvik",
  "Show all": "Vis alle",

  Entity: {
    Manual: "Manuell",
  },

  Search: "Søk",
  Settings: "Innstillinger",
  Type: "Type",
  Name: "Navn",
  Save: "Lagre",
  Saved: "Lagret",
  Back: "Tilbake",
  Exceptions: "Unntak",
  Done: "Fullført",
  Add: "Legg til",
  "All Exceptions": "Alle unntak",
  "From has to be before To": '"Fra" må være før "Til"',
  "Select at least one day": "Velg minst en dag",
  Required: "Må fylles ut",
  "Alarm SMS recipients": "Alarm SMS mottakere",
  "Has to be integer": "Må være heltall ",
  "Has to be less than": "Må være mindre enn",
  "Has to be more than": "Må være mer enn",
  newOrder: {
    fileDropAriaTitle: "Last opp",
  },
  SettingsComponent: {
    title: "Instillinger for sted",
    signaturesTitle: "Signaturer",
    routinesTitle: "Rutineinnstillinger",
  },
  CleanComponent: {
    title: "Rengjøring",
    register_clean: "Registrer vask",
    time_to_clean: "På tide med rengjøring",
  },
  Labeling: {
    label: "Merkelapp",
    labels: "Merkelapper",
    type: "Type",
    name: "Navn"
  },
  editChecklist: {
    pageTitle: "Rediger Rutine",
    edit: "Rediger",
    add_new_checklist: "Ny",
    add_checklist_to_site: "Sjekkliste fra mal",
    name: "Navn",
    description: "Beskrivelse",
    deviationHandlingLevel: "Avvikshåndterings nivå",
    submit: "Legg til",
    cancel: "Avbryt",
    add_entity_title: "Legg til enhet til punkt",
    add_entity: "Legg til enhet",
    add_entity_id: "Legg til ID på enhet",
    addLabel: "Legg til merkelapp",
    change_order: "Bytt rekkefølge",
    change_order_title: "Legg dette punktet etter:",
    add_breakage: "Legg til brekkasje",
    breakage_unit: "Skriv inn ønsket brekkasje-måleenhet",
    addDeviationButton: "Raporter avvik",
    removeImage: "Fjern bilde",
    imageLibrary: "Bildebibliotek",
    images: "Sjekkliste bilder",
    externalLink: {
      title: "Legg til ekstern lenke",
      titleLabel: "Tittel",
      urlLabel: "URL",
      titleLabelError: "Tittel er påkrevd",
      urlLabelError: "URL er ugyldig",
      submit: "Sett",
      disable: "Deaktiver",
      enable: "Muliggjøre",
    },
    // TODO: Translate
    sections: {
      checklistInfo: "Rutineinformasjon",
      schedule: "Rutinetidsplan",
      points: "Sjekklistepunkter"
    },
    editPoint: {
      addNew: "+ Malpunkt",
      addNewToSite: "+ Sjekklistepunkt",
      includePoints: "Ta med sjekklistepunkter",
      show: {
        checklistAll: "Vis alle sjekkliste punkter",
        templateAll: "Vis alle malpunkter",
        checklist: "Vis sjekkliste punkter",
        template: "Vis malpunkter",
      },
      card: {
        inputFieldLabel: "Punkt tittel",
        inputDescriptionLabel: "Punkt beskrivelse",
        includedFrom: "Inkludert fra",
        templatePoint: "Mal punkt",
      },
      settings: {
        title: "Innstillinger",
        edit: "Rediger",
        editDetails: "Legg til mer detalje",
        assignEntity: "Legg til enhet",
        addLabel: "Legg til merkelapp",
        move: "Flytt",
        setExternalLink: "Sett ekstern link",
        setBreakage: "Sett brekkasje-måleenhet",
        attachMedia: "Legg til media",
        enabled: "Aktiv",
        deleteLabel: "Slett",
        deleteConfirmation: "Er du sikker på at du vil slette dette punktet?",
        deleteSiteConfirmation: "Er du sikker på at du vil slette dette punktet fra stedet?",
      },
      multipoint: {
        enable: "Tillat flere oppføringer"
      }
    },
  },
  calendar: {
    unhandledDeviations: "Ubehandlede avvik",
    handledDeviations: "Behandlede avvik",
    allHandled: "Alle avvik har blitt behandlet",
    notCompleted: "Ble ikke utført",
    pending: "Venter på utførelse",
    completed: "Fullført",
    weekTab: "SE UKE",
    monthTab: "SE MÅNED"
  },
  checklist: {
    checklistsTitle: "Rutiner",
    inProgressTitle: "Pågående oppgaver",
    readyTitle: "Oppgaver som skal utføres",
    allwaysScheduledTitle: "Oppgaver som startes manuelt",
    overdueTitle: "Forfalte oppgaver",
    comingUpTitle: "Oppgaver som skal gjøres senere",
    completedTitle: "Fullført oppgaver",
    highPriorityRoutinesTab: "Dagens oppgaver",
    lowPriorityRoutinesTab: "Grovvask",
    reportsTab: "Rapport",
    deviationsTab: "Rutine avviksrapport",
    calendarTab: "Kalender",
    temperatureDeviationsTab: "Temperatur avviksrapport",
    commentsTab: "Kommentarer",
    overviewTab: "Rutiner",
    noSessions: "Ingen utførte rutiner",
    tagFilters: {
      labelWithFilters: "Filtre",
      labelWithoutFilters: "Velg Avvik Type Her",
    },
    deviationHandlingLevelInfo: {
      title: "Deviation handling level info",
      rules: {
        GREEN: "Påminnelse av mindre viktige oppgaver.",
        YELLOW: "Viktige avvik som lagres automatisk.",
        RED: "Kritiske avvik som lagres og sender ut varsel automatisk.",
      },
    },
    subPoint: {
      add: "Legg til ny oppføring",
      remove: "Fjern denne oppføringen",
      addAnother: "Legg til flere"
    },
    green: "Grønn",
    yellow: "Gul",
    red: "Rød",
    takenAction: "Strakstiltak",
    deviationCard: {
      auto: "Auto",
      point: "Oppgave",
      reportedBy: "Rapportert av",
      comment: "Avviksbeskrivelse",
      actionTakenBy: "Opprettet av",
      takenAction: "Strakstiltak",
      handledBy: "Behandlet av",
      handledComment: "Avviksbehandling/ Hindre gjentagende avvik",
      additionalCommentLabel: "Kommentar",
      commentsHistory: "Kommentarhistorikk",
      wholeRoutine: "Dette avviket gjelder hele rutinen",
      reportedAt: "rapportert kl",
    },
    handleDeviationDialog: {
      button: "Behandle",
      dialogTitle: "Behandle avvik / Hindre gjentagende avvik",
      dialogCommentTitle: "Leg til ny kommentar",
      dialogText: "Her kan du legge til en ny kommentar. Denne kommentaren vil være lagret i rapporten, som vil være synlig for alle som har tilgang til rapporten.",
      textFieldLabel: "Kommentar",
      cancel: "Avbryt",
      save: "Lagre",
    },
    numberOfDeviationsInCard: "viser {{deviationNumber}}",
    status: {
      status: "Status",
      IN_PROGRESS: "Påbegynt",
      FINISHED: "Ferdig",
      OVER_DUE: "Ikke påbegynt",
      OVER_DUE_STARTED: "Ikke fullført",
    },
    completed: "Fullført",
    deviation: "Avvik",
    deviationHandling: {
      deviationCause: "Årsak til avvik",
      immediateAction: "Strakstiltak",
    },
    checklistCard: {
      deadline: "Fullføres før kl.",
      expired: "Utløpt",
      starting: "Skal utføres fra kl.",
      started: "Startet",
      readySince: "Klar siden",
      ready: "Klar",
      lastChanged: "Sist endret",
      empty: "Ingen oppgaver",
      addDeviationButton: "Legg til avvik",
      actionDescriptionPrefix: "Håndtering",
    },
    comments: {
      wholeRoutine: "Denne kommentaren gjelder hele rutinen",
      comment: "Kommentar",
      noComments: "Ingen kommentarer",
    },
    chooseSignature: "Velg signatur",
    checklistOverview: "Oversikt",
    schedule: {
      todoFrom: "Denne rutinen er klar ",
      dueFrom: "Denne rutinen skal være ferdig til ",
      nextRuns: "Kommende tider: ",
    },
    progressActions: {
      completeChecklist: "Fullfør rutine",
      handleDeviationsInfo: "Håndter alle avvik",
    },
    actions: {
      startChecklist: "Start",
      reports: "Gå til rapporter",
      edit: "Redigere",
      delete: "Slett",
    },
    deleteDialog: {
      title: "Slett rutine",
    }
  },
  entityForm: {
    successMessage: "Enhet lagret.",
    errorMessage: "Lagring av enhet mislyktes.",
    objectImage: {
      buttonLabel: "Legg til bilde",
      title: "Bilde av enheten",
      name: "Navn",
      done: "Ferdig",
      nameError: "Gi bildet et navn!",
      imageError: "Last opp et bilde!",
      clickToPreview: "Klikk for å se hele bildet",
    },
    serviceHours: {
      buttonLabel: "Definer tjenestetider",
      title: "Tjenestetider",
      from: "Fra",
      to: "Til",
      add: "Legg til",
      all: "Alle tjenestetider",
      noItems: "Ingen tjenestetid definert",
      done: "Ferdig",
      everyDay: "Hver dag",
    },
    exceptions: {
      buttonLabel: "Unntak",
      title: "Unntak",
      from: "Fra",
      to: "Til",
      add: "Legg til",
      all: "Alle Unntak",
      noItems: "Ingen unntak definert",
      done: "Ferdig",
      everyDay: "Hver dag",
    },
    objectType: {
      buttonLabel: "Type",
      title: "Type",
      select: "Select",
      selectAndSave: "Select and Save",
    },
  },
  dashboard: {
    routineDeviationsTitle: "Avviksrapport fra automatiske sensor",
    routineStatusTitle: "Oversikt over rutineavvik",
    routineChartsTitle: "Prosentandel gjennomførte rutiner",
    tooltips: {
      dailyDeviations: 'Denne delen viser deg en generert rapport over avvik i automatiske sensorer fra dagen før. Ved å trykke på "behandle", så behandler du avviket.',
      weeklyDeviations: 'Denne delen viser en generert rapport over ukens avvik fra automatiske sensorer. Ved å klikke på en bestemt dag, kan du se og behandle ulike avvik som er registrert.',
      routineStatus: 'Denne delen viser deg prosentandelen gjennomførte rutiner i løpet av uken og måneden.'
    },
    chartLabels: {
      completed: "Gjennomførte uten avvik",
      handled: "Rutiner med behandlet avvik",
      unhandled: "Rutiner med ubehandlet avvik",
    },
    chartLabelDescriptions: {
      completed: "Rutiner som er gjennomført uten noen mangel/avvik.",
      handled: "Andelen rutiner hvor det har vært mangel/avvik, men som har blitt behandlet.",
      unhandled: "Andelen rutiner hvor det har vært mangel/avvik som fortsatt er ubehandlet."
    },
    calendarLinkTitle: 'Kalenderoversikt',
  },
  mainMenu: {
    dashboard: "Dashbord",
    entityManagement: "Enhetsbehandling",
    entityTypes: "Enhetstyper",
    reportsHistory: "Rapporthistorikk",
    manualMeasurements: "Manuelle målinger",
    controlMeasurements: "Kontrollmåling",
    excelReports: "Excelrapporter",
    clean: "Rengjør",
    routines: "Rutiner",
    selectSite: "Velg sted",
    settings: "Innstillinger",
    selectLanguage: "Velg språk",
  },
  and: "og",
  on: "på",
  editSchedule: {
    add: "Legg til timeplan",
    the_checklist: "Rutinen",
    starts_on: "starter",
    should_become: " ",
    it_becomes: "den",
    before: "før",
    after: "etter",
    visible: "synlig",
    available: "blir tilgjengelig",
    for_doing: " ",
    overdue: "forfaller",
    hidden: "skjules",
    upcoming_times: "Kommende tider",
    upcoming_times_invalid: "Ugyldig tidsintervall",
    day: "dag",
    days: "dager",
    hour: "time",
    hours: "timer",
    minute: "minutt",
    minutes: "minutter",
    starting_at: "starter på",
    starting_now: "starter nå",
    between: "mellom",
    selectedHalfHours: "hver halvtime",
    selectedHours: "på valgte klokkeslett",
    starting_at_simple: "på",
    every: "hver",
    every_day_of_the_week: "hver dag i uken",
    advanced: "Avansert",
    schedulingEnabled: "Timeplan aktivert",
    save: "Lagre timeplan",
    saved: "Lagret",
    createAllwaysActive: "Start ved behov",
  },
  daysInWeek: {
    Monday: "Mandag",
    Tuesday: "Tirsdag",
    Wednesday: "Onsdag",
    Thursday: "Torsdag",
    Friday: "Fredag",
    Saturday: "Lørdag",
    Sunday: "Søndag",
  },
  Preview: "Forhåndsvisning",
  Loading: "Laster inn",
  Error: "Feil",
  "Add new": "Legg til nytt punkt",
  "Name of point": "Navn på punkt",
  Description: "Beskrivelse",
  "Associated entity": "Tilknyttet enhet",
  Cancel: "Avbryt",
  "Markdown Editor": "Markdown teksteditor",
  "Associated object": "Tilknyttet objekt",
  Edit: "Rediger",
  Move: "Flytt",
  "Set External Link": "Angi ekstern kobling",
  "Assign Entity": "Tilordne enhet",
  Enabled: "Aktivert",
  "Set Breakage": "Sett brekkasje-måleenhet",
  "Template description": "Malbeskrivelse",
  "Site specific description": "Stedsspesifikk beskrivelse",
  "Site specific details": "Stedsspesifikke detaljer",
  "Breakage unit": "Brekkasje-måleenhet",
  "Site Specific": "Stedsspesifikt",
  "Edit Description": "Rediger beskrivelse",
  "Add More Details": "Legg til flere detaljer",
  "Enabled for Site": "Aktivert for sted",
  "is overridden": "er overstyrt",
  "The template description": "Malbeskrivelsen",
  "Attach Media": "Legg til medie",
  "Edit for Site": "Rediger for Sted",
  "Edit Template": "Rediger mal",
  "Edit Tags": "Rediger tagger",
  "Deviation Handling": "Avvikshåndtering",
  "High Priority": "Høy prioritet",
  "Add new to Site": "Legg til nytt punkt for stedet",
  "The format of the uploaded file is not supported":
    "Formatet til den opplastede filen støttes ikke",
  "The file is too large, maximum allowed size is 50MB":
    "Filen er for stor, maksimal tillatt størrelse er 50 MB",
  "Assign Checklist to Site": "Tilordne sjekkliste til sted",
  "Checklist Template": "Sjekklistemal",
  Comment: "Kommentar",
  Comments: "Kommentarer",
  Point: "Punkt",
  "Reopen routine": "Gjennåpne rutine",
  "This deviation applies to the entire routine":
    "Dette avviket gjelder hele rutinen",
  Handle: "Behandle",
  Continue: "Fortsett",
  Start: "Start",
  "Automatic deviation due to the routine not being completed in time":
    "Automatisk avvik på grunn av at rutinen ikke ble gjennomført i tide.",
  "Missing manual measurement for point": "Mangler manuelle målinger.",
  signature: {
    select: "Signer",
    av: "Signatur av",
    search: "Søk",
    add: "Legg til signatur",
    add_existing: "Legg til en eksisterende signatur",
    already_added: "Allerede lagt til",
    remove: "Fjern",
    userSignature: "Automatisk lagt til signatur for bruker",
  },
  "No deviations reported": "Ingen avvik rapportert",
  "Load more": "Last inn mer",
  "Handled Comment": "Kommentar på behandling",
  "Notification Settings": "Varslingsinnstillinger",
  "Global Site Notifications": "Stedsvarslinger",
  "Checklist Alarms Notifications": "Sjekkliste Alarmvarsler",
  Version: "Versjon",
  "Update app": "Oppdater app",
  "No manual devices to display.": "Ingen manuelle enheter å vise.",
  "No devices to display.": "Ingen enheter å vise.",
  "Previous report": "Forrige rapport",
  Empty: "Tomt",
  "Wash & clean": "Vaske & rydde",
  "Goods delivery": "Varelevering",
  "During cooking": "Under tilberedning",
  "Report registered": "Rapport registrert",
  documents: "dokumenter",
  "Listing of reports": "Utlisting av rapporter",
  "Listing of deviation reports": "Utlisting av avviksmeldinger",
  "Temperatures per unit": "Temperaturer per enhet",
  "Found no devices to report for": "Fant ingen enheter å rapportere for",
  "Latest report": "Siste rapport",
  "Save Current Page as Default": "Lagre gjeldende side som standard",
  "Push Notification Subscriptions": "Administrer varslinger",
  entityTypes: {
    pageTitle: "Enhetstype",
    addNew: "Legg til ny enhetstype",
    search: "Søk etter enhetstype",
    edit: {
      pageTitle: "Endre Enhetstype",
      name: "Navn",
      description: "Beskrivelse",
      hotTemperatures: "Varme temperaturer",
      min: "Min",
      max: "Maks",
      actionDescription: "Håndteringsmåte",
      GREEN: "Grønne Regler",
      YELLOW: "Gule Regler",
      RED: "Røde Regler",
      save: "Lagre",
      errors: {
        number: "Må være et tall eller Infinity(uendelig)",
        required: "Påkrevd",
      },
      tabs: {
        entityType: "Enhetstype",
        deviationCauses: "Årsaker til avvik",
        deviationHandling: "Avvikshåndtering",
      },
      entityType: {
        title: "Enhetstype",
      },
      commonDeviationCauses: {
        cause: "Avviksårsakstekst som blir lagret i avviks behandling",
        label: "Tittel på knapp",
        add: "Legg til årsak",
        title: "Vanlige avviksårsaker",
        noItems: "Ingen vanlige årsaker til avvik er definert.",
      },
      commonDeviationHandles: {
        action:
          "Beskrivelse av vanlige strakstiltak. Dette blir lagret i avvikshåntering.",
        title: "Vanlig strakstiltak",
        noItems: "Ingen vanlige avvikshåndteringer er definert.",
        add: "Legg til håndtering",
      },
    },
    cardDisplay: {
      rules: {
        GREEN: "Trygt mellom {{min}} og {{max}}. ",
        YELLOW: "Advarsel mellom {{min}} og {{max}}. ",
        RED: "Kritisk mellom {{min}} og {{max}}. ",
      },
      actionDescription: "Håndtering: {{actionDescription}}",
    },
  },
  alarms: {
    pageTitle: "Alarmer",
    entities: "Enheter",
    alarms: "Alarmer",
    siteAlarms: "Alle alarmer på dine steder",
    missingEventAlarmsTitle: "Manglende event alarm",
    notifyAlarmsTitle: "Notifikasjons alarmer",
  },
  siteForm: {
    recipientSmsHours: {
      buttonLabel: "Definer når mottaker skal mota SMS ",
      title: "SMS-mottakers tider",
      from: "Fra",
      to: "Til",
      add: "Legg til",
      all: "Alle SMS-mottakeres tider",
      done: "Ferdig",
      noItems: "Ingen SMS-tider",
      everyDay: "Hver dag",
    },
    showLowPriorityRoutinesTab: "Vis Grovvask Rutiner",
    useTenantDefault: "bruk Tennant standard",
    signatures: "Signaturer for stedet",
    siteName: "Stedsnavn",
    smsRecipients: "Alarm SMS mottakere",
    smsRecipient: {
      name: "Navn",
      phoneNumber: "Telefonnummer",
      required: "Må fylles ut",
      addRecipient: "Legg til mottaker",
      unique: "Må være unik",
    },
    save: "Lagre",
    saved: "Lagret",
  },
  maintenance: {
    dontShowLabel: "Ikke vis igjen",
  },
  manualSchedule: {
    startNow: "Start rutine nå",
    startLater: "Start rutine senere",
    startManually: "Start rutinen",
    customSchedule: "Tilpass timeplan",
    hours: "Time(r)",
    when: "Når skal rutinen utføres?",
    selectDateTime: "Velg dato og tid for fremtidig rutine",
    duration: "Hvor lenge skal rutinen være aktiv?",
    successMessage: "Utføring av rutine lagt til timeplan.",
    tooltips: {
      startChecklist: "Start",
      reports: "Gå til rapporter",
      edit: "Redigere",
    },
  },
  surveillanceDeviationReports: {
    pageTitle: "Alarmavvik",
    latestValue: "Siste verdi",
    submit: "Sende inn",
    handled: "Avvik håndtert.",
    actionPlaceholder: "Skriv årsak for avvik eller velg et hurtig valg.",
  },
  alarmStatus: {
    red: "Kritisk! Alarm utsendt.",
    yellow: "Avvik oppdaget. Ikke kritisk.",
    redSince: "Kritisk avvik startet for ",
    yellowSince: "Avvik startet for ",
    reportPageAlarmMessage: "Avvik startet",
  },
  userProfile: {
    title: "Brukerprofil",
    phoneAccounts: {
      add: "Legg til telefonnummer for innlogging",
      addNew: "+ Legg til telefonnummer",
      phoneNumber: "Telefonnummer",
      name: "Navn",
      save: "Lagre",
      saved: "Innloggingsnummer lagret"
    },
  },
  deviationProcedures: {
    deviationAndPerios: "Avvik ble oppdaget",
    lastMeasuredTemperature: "Siste målt temp",
    seeMoreInfo: "Her kan du se mer info",
    handleDeviationHere: "Behandle avvik her",
    limitValue: "Grense verdi"
  },
};
