import * as React from "react";
import { useState, useContext, useEffect } from "react";
import { Box, Button, IconButton, Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import PageHeader from "../../react-components/PageHeader";
import { Formik, Form } from "formik";
import { Icon } from "@iconify/react";
import thermometerIcon from "@iconify/icons-mdi/thermometer";
import humidityIcon from "@iconify/icons-mdi/water-percent";
import moleculeCo2Icon  from "@iconify/icons-mdi/molecule-co2";
import pressureIcon from "@iconify/icons-mdi/molecule-co2";
import clsx from "clsx";
import * as Yup from "yup";
import { take } from "rxjs/operators";
import FormikField from "../../react-components/FormikField";
import FormikSelect from "../../react-components/FormikSelect";
import { TermoObject } from "../../entity/termo-object";
import { OpenCloseObject } from "../../entity/open-close-object";
import { ReptileObject } from "../../entity/reptile-object";
import EditEntityContext from "../EditEntityContext";
import SensorForm from "./SensorForm";
import { IValues, ISensorValues } from "../interfaces";
import { useTranslation } from "react-i18next";
import ServiceHoursDefinitionDialog from "./ServiceHoursDefinitionDialog";
import { environment } from "../../../environments/environment";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: "1rem",
  },
  sensorContainer: {
    marginTop: theme.spacing(3),
  },
  input: {
    margin: theme.spacing(1),
  },
  sensorTitle: {},
  buttonIcon: {
    fontSize: "1.5em",
  },
  addTitle: {
    marginTop: theme.spacing(3),
  },
  button: {
    marginRight: theme.spacing(1),
  },
  actions: {
    marginTop: theme.spacing(2),
  },
}));

type Props = {
  isNew?: boolean;
  isMove?: boolean;
  entity?: any;
  tags?: any;
  handleSubmit: (values: IValues) => void;
};

const initialSensorValues: ISensorValues = {
  lastValue: "",
  sourceId: "",
  timestamp: "",
  type: "",
  unit: null,
  value: 0,
  termoObjectTypeId: null,
};

const EntityForm = ({ entity, isNew, tags, handleSubmit, isMove }: Props) => {
  console.log("EntityForm 1");
  const classes = useStyles();
  const { signalsService } = useContext(EditEntityContext);
  const { t } = useTranslation();

  const [showTemp, setShowTemp] = useState<boolean>(
    tags && Boolean(tags.temperature)
    || entity?.obj?.rules?.temperature 
  );
  const [showHumidity, setShowHumidity] = useState<boolean>(
    tags && Boolean(tags.humidity)
    || entity?.obj?.rules?.humidity 
  );
  const [showDoor, setShowDoor] = useState<boolean>(
    tags && Boolean(tags.door)
    || entity?.obj?.rules?.door
  );
  const [showTouch, setShowTouch] = useState<boolean>(
    tags && Boolean(tags.touch)
    || entity?.obj?.rules?.touch
  );
  const [showPPM, setShowPPM] = useState<boolean>(
    tags && Boolean(tags.ppm)
    || entity?.obj?.rules?.ppm
  );
  const [showPressure, setShowPressure] = useState<boolean>(
    tags && Boolean(tags.pressure)
    || entity?.obj?.rules?.pressure
  );
  const [serviceHoursDialogOpen, setServiceHoursDialogOpen] =
    useState<boolean>(false);
  const [serviceHours, setServiceHours] = useState<any[]>(
    entity?.serviceHours || []
  );

  const validation = Yup.object().shape({
    name: Yup.string().required(t("Required")),
   
    limits: Yup.object().shape({
      temperature:
        showTemp &&
        Yup.object().shape({
          min: Yup.number()
            .typeError(t("Has to be integer"))
            .integer(t("Has to be integer"))
            .lessThan(Yup.ref("max"), (e) =>
              e.less ? `Må være mindre enn ${e.less}` : "Må være mindre en max"
            ),
          max: Yup.number()
            .typeError(t("Has to be integer"))
            .integer(t("Has to be integer"))
            .moreThan(Yup.ref("min"), (e) =>
              e.more ? `Må være større enn ${e.more}` : "Må være større en min"
            ),
        }),
      humidity:
        showHumidity &&
        Yup.object().shape({
          min: Yup.number()
            .typeError(t("Has to be integer"))
            .integer(t("Has to be integer"))
            .lessThan(Yup.ref("max"), (e) =>
              e.less
                ? `${t("Has to be less than")} ${e.less}`
                : t("Has to be less than")
            ),
          max: Yup.number()
            .typeError(t("Has to be integer"))
            .integer(t("Has to be integer"))
            .moreThan(Yup.ref("min"), (e) =>
              e.more
                ? `${t("Has to be more than")} ${e.more}`
                : t("Has to be more than")
            ),
        }),
      door:
        showDoor &&
        Yup.object().shape({
          max: Yup.number()
            .typeError(t("Has to be integer"))
            .integer(t("Has to be integer")),
          timer: Yup.number()
            .typeError(t("Has to be integer"))
            .integer(t("Has to be integer")),
        }),
      ppm:
        showPPM &&
        Yup.object().shape({
          min: Yup.number()
            .typeError(t("Has to be integer"))
            .integer(t("Has to be integer"))
            .lessThan(Yup.ref("max"), (e) =>
              e.less ? `Må være mindre enn ${e.less}` : "Må være mindre en max"
            ),
          max: Yup.number()
            .typeError(t("Has to be integer"))
            .integer(t("Has to be integer"))
            .moreThan(Yup.ref("min"), (e) =>
              e.more ? `Må være større enn ${e.more}` : "Må være større en min"
            ),
        }),
      pressure:
        showPressure &&
        Yup.object().shape({
          min: Yup.number()
            .typeError(t("Has to be integer"))
            .integer(t("Has to be integer"))
            .lessThan(Yup.ref("max"), (e) =>
              e.less ? `Må være mindre enn ${e.less}` : "Må være mindre en max"
            ),
          max: Yup.number()
            .typeError(t("Has to be integer"))
            .integer(t("Has to be integer"))
            .moreThan(Yup.ref("min"), (e) =>
              e.more ? `Må være større enn ${e.more}` : "Må være større en min"
            ),
        }),
    }),
  });

  console.log(entity, tags);

  let initialValues: IValues = {
    type: entity?.type || TermoObject.typeName,
    name: entity?.name || "",
    family: entity?.obj.make || "",
    species: entity?.obj.model || "",
    temperature: tags?.temperature || initialSensorValues,
    humidity: tags?.humidity || initialSensorValues,
    door: tags?.door || initialSensorValues,
    touch: tags?.touch || initialSensorValues,
    ppm: tags?.ppm || initialSensorValues,
    pressure: tags?.pressure || initialSensorValues,
    limits: entity?.obj.rules || {},
    serviceHours: entity?.serviceHours || [],
    objectImages: entity?.objectImages || [],
  };

  const [signals, setSignals] = useState([]);
  const [tempSignals, setTempSignals] = useState([]);
  const [humiditySignals, setHumiditySignals] = useState([]);
  const [doorSignals, setDoorSignals] = useState([]);
  const [PPMSignals, setPPMSignals] = useState([]);
  const [pressureSignals, setPressureSignals] = useState([]);
  const recentDate = new Date(Date.now() - 86400000); //last day in milliseconds
  // const recentDate = new Date(Date.now() - 3600000); //last hour in milliseconds

  useEffect(() => {
    signalsService
      .getSignals()
      .pipe(take(1))
      .subscribe((res: any) => {
        let filteredArray = res.filter(
          (s) =>
            (s.sourceId.indexOf("dt/") !== -1 || s.sourceId.indexOf("ef/") !== -1) &&
            new Date(s.lastValue._i) > recentDate
        );
        let newArray = filteredArray.map(
          ({ direction, hubId, id, links, ...keepAtr }) => keepAtr
        ); 
        setSignals(newArray);
        setTempSignals(newArray.filter((s) => s.unitCode === "TempC"));
        setHumiditySignals(newArray.filter((s) => s.unitCode === "Humidity%"));
        setDoorSignals(newArray.filter((s) => s.unitCode === "DoorOC"));
        setPressureSignals(newArray.filter((s) => s.unitCode === "PressurePascal"));
        setPPMSignals(newArray.filter((s) => s.unitCode === "ConcPPM"));
      });
  }, []);
  return (
    <div className={classes.root}>
      <PageHeader
        href="/entities"
        title={
          isNew
            ? t("New Entity")
            : isMove
              ? entity && `${t("Move")} "${entity.name}"`
              : entity && `${t("Edit")} "${entity.name}"`
        }
      />
      <Formik
        initialValues={initialValues}
        validationSchema={validation}
        onSubmit={(values) => handleSubmit(values)}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <FormikSelect
              name="type"
              label={t("Type")}
              required
              items={[
                { label: "Termo", value: TermoObject.typeName },
                { label: "Clean", value: OpenCloseObject.typeName },
                { label: "Habitat", value: ReptileObject.typeName },
              ]}
            />
            <FormikField name="name" label={t("Name")} required />
            {/* <FormikField name="family" label="Familie" />
            <FormikField name="species" label="Art" /> */}
            <Button
              variant="contained"
              onClick={() => setServiceHoursDialogOpen(true)}
            >
              {t("entityForm.serviceHours.buttonLabel")}
            </Button>
            <ServiceHoursDefinitionDialog
              open={serviceHoursDialogOpen}
              onClose={() => setServiceHoursDialogOpen(false)}
              limits={[]}
              values={serviceHours}
              setValues={setServiceHours}
              handleSave={() => {
                setFieldValue(`serviceHours`, serviceHours);
                setServiceHoursDialogOpen(false);
              }}
              translationRootKey="entityForm.serviceHours"
            />
            <Typography variant="h2" className={classes.addTitle}>
              {t("Add new sensor")}
            </Typography>
            <Box display="flex">
              <IconButton
                color="primary"
                onClick={() => {
                  setShowTemp((prev) => !prev);
                  if (showTemp) {
                    setFieldValue("temperature", undefined);
                    setFieldValue("limits.temperature", undefined);
                  } else {
                    setFieldValue("temperature", initialSensorValues);
                    setFieldValue("limits.temperature", { min: "", max: "" });
                  }
                }}
              >
                <Icon
                  icon={thermometerIcon}
                  className={classes.buttonIcon}
                  fr={undefined}
                />
              </IconButton>
              <IconButton
                color="primary"
                onClick={() => {
                  setShowHumidity((prev) => !prev);
                  if (showHumidity) {
                    setFieldValue("humidity", undefined);
                    setFieldValue("limits.humidity", undefined);
                  } else {
                    setFieldValue("humidity", initialSensorValues);
                    setFieldValue("limits.humidity", { min: "", max: "" });
                  }
                }}
              >
                <Icon
                  icon={humidityIcon}
                  className={classes.buttonIcon}
                  fr={undefined}
                />
              </IconButton>
              <IconButton
                color="primary"
                onClick={() => {
                  setShowDoor((prev) => !prev);
                  if (showDoor) {
                    setFieldValue("door", undefined);
                    setFieldValue("limits.door", undefined);
                  } else {
                    setFieldValue("door", initialSensorValues);
                    setFieldValue("limits.door", { timer: "", max: "" });
                  }
                }}
              >
                <i className={clsx("material-icons", classes.buttonIcon)}>
                  sensor_door
                </i>
              </IconButton>
              <IconButton
                color="primary"
                onClick={() => {
                  setShowTouch((prev) => !prev);
                  if (showTouch) {
                    setFieldValue("touch", undefined);
                    setFieldValue("limits.touch", undefined);
                  } else {
                    setFieldValue("touch", initialSensorValues);
                    setFieldValue("limits.touch", { max: 0 });
                  }
                }}
              >
                <i className={clsx("material-icons", classes.buttonIcon)}>
                  touch_app
                </i>
              </IconButton>
              {environment.featureFlag.co2 &&<IconButton
                color="primary"
                onClick={() => {
                  setShowPressure((prev) => !prev);
                  if (showTemp) {
                    setFieldValue("pressure", undefined);
                    setFieldValue("limits.pressure", undefined);
                  } else {
                    setFieldValue("pressure", initialSensorValues);
                    setFieldValue("limits.pressure", { min: "", max: "" });
                  }
                }}
              >
                <Icon
                  icon={pressureIcon}
                  className={classes.buttonIcon}
                  fr={undefined}
                />
              </IconButton>}
              {environment.featureFlag.co2 && <IconButton
                color="primary"
                onClick={() => {
                    setShowPPM((prev) => !prev);
                  if (showTemp) {
                    setFieldValue("ppm", undefined);
                    setFieldValue("limits.ppm", undefined);
                  } else {
                    setFieldValue("ppm", initialSensorValues);
                    setFieldValue("limits.ppm", { min: "", max: "" });
                  }
                }}
              >
                <Icon
                  icon={moleculeCo2Icon}
                  className={classes.buttonIcon}
                  fr={undefined}
                />
              </IconButton>}
            </Box>
            {showTemp && (
              <SensorForm
                label={t("Temperature")}
                signals={tempSignals}
                value={values.temperature}
                name="temperature"
                limits={[
                  { name: "min", label: "Min" },
                  { name: "max", label: "Max" },
                ]}
                exceptionsValues={entity?.obj.rules["temperature"]?.limits}
                objectTypeValue={entity?.obj?.termoObjectTypeId}
                termoObjectType={entity?.obj?.termoObjectType}
              />
            )}

            {showHumidity && (
              <SensorForm
                label={t("Humidity")}
                signals={humiditySignals}
                value={values.humidity}
                name="humidity"
                limits={[
                  { name: "min", label: "Min" },
                  { name: "max", label: "Max" },
                ]}
              />
            )}

            {showDoor && (
              <SensorForm
                label={t("Door")}
                signals={doorSignals}
                value={values.door}
                name="door"
                limits={[
                  { name: "timer", label: "Timer" },
                  { name: "max", label: "Max" },
                ]}
              />
            )}

            {showTouch && (
              <SensorForm
                label={t("Touch")}
                signals={signals}
                value={values.touch}
                name="touch"
              />
            )}
            {showPPM && (
              <SensorForm
                label={t("CO2")}
                signals={PPMSignals}
                value={values.ppm}
                name="ppm"
                limits={[
                  { name: "min", label: "Min" },
                  { name: "max", label: "Max" },
                ]}
              />
            )}
            {showPressure && (
              <SensorForm
                label={t("Pressure")}
                signals={pressureSignals}
                value={values.pressure}
                name="pressure"
                limits={[
                  { name: "min", label: "Min" },
                  { name: "max", label: "Max" },
                ]}
              />
            )}

            <Box className={classes.actions}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.button}
              >
                {t("Save")}
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default EntityForm;
