import {
  Button,
  Dialog,
  DialogContent,
  Theme
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Site } from "../../../../entity/site";
import DialogTitle from "../../../../react-components/DialogTitle";
import FormikField from "../../../../react-components/FormikField";
import { useChecklistContext } from "../../../ChecklistReactWrapper";
import { ChecklistPointEvent, ChecklistPointEventType } from "./ChecklistPointEventHandler";

const useStyles = makeStyles(({ spacing, palette }: Theme) =>
  createStyles({
    root: {},
    textField: {
      "& > div": {
        background: palette.common.white,
      },
    },
    buttonsContainer: {
      display: 'flex',
      justifyContent: 'space-between'
    }
  })
);

type Props = {
  externalLink: any;
  externalLinkEnabled: boolean;
  checklistId: string;
  onSubmit: (event: ChecklistPointEvent) => void;
  onEnable: () => void;
  onDisable: () => void;
  open: boolean;
  onClose: () => void;
};

const AddExternalLinkToPointDialog = ({ externalLink, externalLinkEnabled, checklistId, open, onClose, onSubmit, onEnable, onDisable }: Props) => {

  const classes = useStyles();
  const { t } = useTranslation(); //translation

  const { user, siteService } = useChecklistContext();

  const [tenantId, setTenantId] = useState<string>("");

  useEffect(() => {
    siteService.getSite(user.siteId).subscribe((site: Site) => {
      setTenantId(site.tenantId.toString());
    });
  }, []);

  const handleEnable = () => {
    onEnable();

    onClose();
  }

  const handleDisable = () => {
    onDisable();

    onClose();
  }

  const handleSubmit = (values, { resetForm }) => {
    onSubmit({
      type: ChecklistPointEventType.PointExternalLinkAdded,
      data: {
        title: values.title,
        url: values.url,
        // Support for the possibility of adding icons
        iconUrl: null,
        tenantId,
        checklistId,
      }
    });

    onClose();
  };

  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose}>
      <DialogTitle onClose={onClose}>
        {`${t("editChecklist.externalLink.title")}`}
      </DialogTitle>
      <DialogContent>
        <Formik
          initialValues={{ title: externalLink?.title ?? "", url: externalLink?.url ?? "" }}
          enableReinitialize
          validate={(values) => {
            const errors = {} as any;
            if (!values.title) {
              errors.title = t('editChecklist.externalLink.titleLabelError');
            }
            if (!values.url || !/^(http(s)?:\/\/)[^ :]+\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/g.test(values.url)) {
              errors.url = t('editChecklist.externalLink.urlLabelError');
            }
            return errors;
          }}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <FormikField
                className={classes.textField}
                label={`${t("editChecklist.externalLink.titleLabel")}`}
                name="title"
                variant="filled"
                required
              />
              <FormikField
                className={classes.textField}
                label={`${t("editChecklist.externalLink.urlLabel")}`}
                name="url"
                variant="filled"
                required
              />
              <div className={classes.buttonsContainer}>
                <Button type="submit" variant="contained" color="primary">
                  {`${t("editChecklist.externalLink.submit")}`}
                </Button>
                <Button type="button" variant="contained" color="primary" onClick={externalLinkEnabled ? handleDisable : handleEnable}>
                  {externalLinkEnabled ? t("editChecklist.externalLink.disable") : t("editChecklist.externalLink.enable")}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default AddExternalLinkToPointDialog;
