import { Component, OnInit, Input } from "@angular/core";
import {
  FormGroup,
  Validators,
  FormBuilder
} from "@angular/forms";
import { IEntitySpecificsFormHandler } from "../site.specifics.form.handler";
import { Entity } from "../entity";
import { Guid } from "../../system/guid";
import { Moment } from "moment";
import { EntityService } from "../entity.service";
import { Observable, mergeMap } from "rxjs";
import { OpenCloseObject } from "../open-close-object";
import { SignalTagFunction } from "../../signals/vevent";
import { SignalsService } from "../../signals/signals.service";

@Component({
  selector: "app-open-close-form",
  templateUrl: "./open-close-form-component.html",
  styleUrls: ["./open-close-form-component.scss"]
})
export class OpenCloseFormComponent implements OnInit, IEntitySpecificsFormHandler {
  public rules: FormGroup;

  constructor(
    public formBuilder: FormBuilder,
    private entityService: EntityService,
    private signalsService: SignalsService
  ) {
  }

  ngOnInit() {
    this.updateForm();
  }

  public setupForEntity(entity: Entity, updateForm: boolean) {
    if (updateForm) {
      this.updateForm();  
    }
    this.patchForm(entity);
  }

  private updateForm() {
    var numberValidators = [
      Validators.required,
      Validators.max(1000000)
    ];

    this.rules.addControl(
      "max",
      this.formBuilder.control("", numberValidators)
    );
  }

  private patchForm(entity: Entity) {
    this.rules.patchValue({
      max: entity.obj.rules.max
    });

    console.log("Patched openClose form with entity: ", entity);
    console.log("Rules: ", this.rules);
  }

  getEntityObject(
    entityId: string,
    tenantId: any,
    createdOn: Moment
  ): any {
    var rules: any = {
      max: null
    };

    return new OpenCloseObject(
      Guid.newGuid().toString(),
      entityId,
      tenantId,
      rules,
      createdOn
    );
  }

  updateEntityOrReturnErrorMessageIfInvalid(
    entity: Entity,
    formGroup: any
  ): string | null {
    console.log("open-close-form - updateEntityOrReturnErrorMessageIfInvalid");
    var max = formGroup.max;

    entity.obj.rules.max = max;

    return null;
   }

  getEntityType() : string {
    return OpenCloseObject.typeName;
  }

  getSignalTagFunctions(): SignalTagFunction[] {
    return [SignalTagFunction.Door, SignalTagFunction.Clean];
  }

  postObject(openCloseObject: any): Observable<object> {
    let entityId = openCloseObject.entityId;
    return this.entityService
      .postOpenCloseObject(openCloseObject)
      .pipe(
        mergeMap(_ => {
          return this.signalsService.registerNewEntityCleanSignal(entityId);
        })
      )
  }

  putObject(openCloseObject: any): Observable<object> {
    return this.entityService.putOpenCloseObject(openCloseObject.id, openCloseObject);
  }
}
