import {
  Button,
  Dialog,
  DialogContent,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Site } from "../../../../entity/site";
import DialogTitle from "../../../../react-components/DialogTitle";
import FormikSelect, { FormikSelectItem } from "../../../../react-components/FormikSelect";
import { useChecklistContext } from "../../../ChecklistReactWrapper";
import { ChecklistPointEvent, ChecklistPointEventType } from "./ChecklistPointEventHandler";

type Props = {
  point: any;
  checklistId: string;
  assignedId: string;
  entities: FormikSelectItem[];
  open: boolean;
  onClose: () => void;
  onSubmit: (event: ChecklistPointEvent) => void;
};


const TagEntityToPointDialog = ({ point, checklistId, assignedId, entities, open, onClose, onSubmit }: Props) => {

  const { t } = useTranslation(); //translation
  
  const { user, siteService } = useChecklistContext();
  const [tenantId, setTenantId] = useState<string>("");

  useEffect(() => {
    siteService.getSite(user.siteId).subscribe((site: Site) => {
      setTenantId(site.tenantId.toString());
    });
  }, []);

  const handleSubmit = (values, { resetForm }) => {
    onSubmit({
      type: ChecklistPointEventType.PointManualEntityTagged,
      data: {
        pointId: point?.id,
        entityId: values.entityId,
        tenantId,
        checklistId,
        assignedId,
      }
    });

    resetForm();
    onClose();
  };

  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose}>
      <DialogTitle onClose={onClose}>
        {`${t("editChecklist.add_entity_title")}`}
      </DialogTitle>
      <DialogContent>
        <Formik
          initialValues={{ entityId: point?.entityId ?? "" }}
          enableReinitialize
          validate={(values) => {
            const errors = {};
          }}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <FormikSelect
                name="entityId"
                label={t("entitet")}
                required
                items={entities}
              />
              <Button type="submit" variant="contained" color="primary">
                {`${t("editChecklist.submit")}`}
              </Button>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default TagEntityToPointDialog;
