import * as React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  Theme,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { makeStyles, createStyles } from '@mui/styles';
import { Formik, Form, Field, FormikProps, useFormikContext } from "formik";
import FormikField from "../../react-components/FormikField";
import { useTranslation } from "react-i18next";
import DeleteIcon from "@mui/icons-material/Delete";
import { ExceptionsValues, IValues } from "../interfaces";
import { Info, DateTime } from "luxon";
import * as Yup from "yup";
import { Alert } from "@mui/lab";
import DialogTitle from "../../react-components/DialogTitle"

type Props = {
  open: boolean;
  onClose: () => void;
  limits: any;
  values: any;
  setValues: any;
  handleSave: () => void;
  translationRootKey: string;
};

const styles = ({ spacing }: Theme) =>
  createStyles({
    row: {
      display: "flex",
    },
    button: {
      marginBottom: spacing(2),
      marginTop: spacing(2),
    },
  });

const useStyles = makeStyles(styles);

const splitTime = (time: string) => {
  const timeArray = time.split(":");

  return {
    hour: timeArray[0],
    minute: timeArray[1],
  };
};

const ServiceHoursDefinitionDialog = ({
  open,
  onClose,
  limits,
  values,
  setValues,
  handleSave,
  translationRootKey
}: Props) => {
  const { t, i18n } = useTranslation();
  const classes = useStyles();

  const { submitForm }: FormikProps<IValues> = useFormikContext();

  const limitsValidations = {};

  limits?.map((item) => {
    limitsValidations[item.name] = Yup.string().required(t("Required"));
  });

  let ExceptionSchema = Yup.object().shape({
    customValidation: Yup.boolean().test(
      "my test",
      t("From has to be before To"),
      function (val) {
        const { from, to } = this.parent;
        if (!from || !to) {
          return null;
        }
        return (
          DateTime.fromFormat(from, "HH:mm") <= DateTime.fromFormat(to, "HH:mm")
        );
      }
    ),
    from: Yup.string().required(t("Required")),
    to: Yup.string().required(t("Required")),
    days: Yup.array().min(1, t("Select at least one day")),
    ...limitsValidations,
  });

  const initialsFields = {
    from: "",
    to: "",
    days: [],
    customValidation: false,
  };

  limits?.forEach((element) => {
    initialsFields[element.name] = "";
  });


  const handleSubmit = (values: ExceptionsValues, { resetForm }) => {
    delete values.customValidation;

    const limits = {
      ...values,
      from: splitTime(values.from),
      to: splitTime(values.to),
    };

    setValues((prev) => prev ? [...prev, limits] : [limits]);
    resetForm();
  };

  const handleDelete = (item: any) => {
    setValues((prev) => prev.filter((e) => e !== item));
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle onClose={onClose}>{t(`${translationRootKey}.title`)}</DialogTitle>
      <DialogContent>
        <Formik
          initialValues={initialsFields}
          onSubmit={handleSubmit}
          validationSchema={ExceptionSchema}
        >
          {({ errors }) => (
            <Form>
              <Box>
                {limits?.map((item, key) => (
                  <FormikField
                    key={key}
                    name={item.name}
                    type="text"
                    label={item.label}
                  />
                ))}
              </Box>
              <Box className={classes.row}>
                <FormikField name="from" label={t(`${translationRootKey}.from`)} type="time" shrink />
                <FormikField name="to" label={t(`${translationRootKey}.to`)} type="time" shrink />
                {errors.customValidation && !errors.from && !errors.to ? (
                  <Alert severity="error">{errors.customValidation}</Alert>
                ) : null}
              </Box>

              <Box className={classes.row}>
                <Field name="days">
                  {({ field }) => {
                    return Info.weekdays()?.map((item, key) => {
                      return (
                        <FormControlLabel
                          key={key}
                          {...field}
                          labelPlacement="bottom"
                          label={
                            Info.weekdays("short", { locale: i18n.language })[
                            key
                            ]
                          }
                          control={
                            <Field
                              type="checkbox"
                              {...field}
                              as={Checkbox}
                              color="primary"
                              value={key}
                              checked={field.value.includes(key.toString())}
                            />
                          }
                        />
                      );
                    });
                  }}
                </Field>
              </Box>

              {errors.days ? (
                <Alert severity="error">{errors.days}</Alert>
              ) : null}

              <Button
                className={classes.button}
                type="submit"
                variant="contained"
                color="primary"
              >
                {t(`${translationRootKey}.add`)}
              </Button>
            </Form>
          )}
        </Formik>

        <Typography variant="h5" component="h3">
          {values?.length ? t(`${translationRootKey}.all`) : t(`${translationRootKey}.noItems`)}
        </Typography>
        <List>
          {values?.map((item, key) => {
            const sortedDays = item.days ? item.days.sort() : [];
            const days = sortedDays.length > 0 ? sortedDays.map((day) => Info.weekdays("short")[day]).join(",") : t(`${translationRootKey}.everyDay`, "Every day");


            const formatTime = (t) => `${t.hour}:${t.minute}`;

            let timeText = `${days}, ${formatTime(item.from)}-${formatTime(item.to)}`;

            let limitText = limits.map(
              (limit) => `${limit.label}: ${item[limit.name]}`
            ).join(',');

            console.log(values, limits)

            let itemText = limits.length ? `${limitText} | ${timeText}` : timeText;

            return (
              <ListItem key={key}>
                <ListItemText primary={itemText} />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => handleDelete(item)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </List>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={() => {
          handleSave();
          submitForm();
        }}
        >
          {t(`${translationRootKey}.done`)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ServiceHoursDefinitionDialog;
