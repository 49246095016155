import { APP_INITIALIZER, LOCALE_ID } from "@angular/core";
import { I18NEXT_SERVICE, ITranslationService } from "angular-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { resources } from "./react-components/i18n";

export function appInit(i18next: ITranslationService) {
  return () =>
    i18next.use(LanguageDetector).init({
      supportedLngs: ["no", "en"],
      debug: false,
      returnEmptyString: false,
      resources: resources,
    });
}

export function localeIdFactory(i18next: ITranslationService) {
  return i18next.language;
}

export const I18N_PROVIDERS = [
  {
    provide: APP_INITIALIZER,
    useFactory: appInit,
    deps: [I18NEXT_SERVICE],
    multi: true,
  },
  {
    provide: LOCALE_ID,
    deps: [I18NEXT_SERVICE],
    useFactory: localeIdFactory,
  },
];
