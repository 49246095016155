import React from 'react';

const GreenCheckboxWithWarning = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="darkgreen">
    <path d="M19,3H5C3.89,3,3,3.89,3,5v14c0,1.1,0.89,2,2,2h14c1.11,0,2-0.9,2-2V5C21,3.89,20.11,3,19,3z M9,16l-4-4l1.41-1.41L9,13.17l6.59-6.59L17,7L9,16z"/>
    <circle cx="17.5" cy="6.5" r="6.2" fill="#FFA500" />
    <text x="15.7" y="10" font-size="12" fill="#FFFFFF">!</text>
  </svg>
  
);

export default GreenCheckboxWithWarning;
