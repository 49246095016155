import { useEffect } from "react";

export function useChecklistDescribedRegistry(assignedIds: string[], checklistData: any[]) {
  const checklistDescribedRegistryForAssignedId = {};
  useEffect(() => {
    assignedIds.forEach((assignedId: string) => {
      const reversedChecklistData = checklistData
        .slice()
        .reverse();
      const checklistDescribed = reversedChecklistData
        .find(
          (e) =>
            e.type === "Described" &&
            e.checklistId ===
              checklistData.find(
                (a) => a.type === "AssignedToSite" && a.assignedId == assignedId
              ).checklistId
        );
      const checklistSiteDescribed = reversedChecklistData
        .find(
          (e) =>
            e.type === "SiteDescribed" &&
            e.checklistId ===
              checklistData.find(
                (a) => a.type === "AssignedToSite" && a.assignedId == assignedId
              ).checklistId
        );
      checklistDescribedRegistryForAssignedId[assignedId] = checklistSiteDescribed ?? checklistDescribed;
    });
  }, [assignedIds]);
  return checklistDescribedRegistryForAssignedId;
}
