import useAxios from "axios-hooks";
import axios from "axios";
import { environment } from "../../environments/environment";

const BASE_SIGNATURE_URL =
  environment.dependencies.signature_api.url + "/api/signatures";

export const useGetSignatures = (userId: string) => {
  return useAxios(`${BASE_SIGNATURE_URL}?userId=${userId}`);
};

export const useGetSignaturesByTenant = (tenantId: string) => {
  return useAxios(`${BASE_SIGNATURE_URL}/tenant/${tenantId}`);
};

export const usePostSignatures = (data: any) => {
  return axios.post(`${BASE_SIGNATURE_URL}/postOne`, data);
};

export const usePutSignature = (data: any, id: string) => {
  return axios.put(`${BASE_SIGNATURE_URL}/${id}`, data);
};
