import { Button, CircularProgress, Grid, IconButton, Theme, Tooltip, Typography } from "@mui/material";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import DisabledByDefaultIcon from "@mui/icons-material/DisabledByDefault";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {
  ChecklistPeriodState,
  ChecklistPeriodStatus,
  PeriodDeviationStatus,
  getChecklistCalendar,
} from "../../services/checklistService";
import { createStyles, makeStyles } from "@mui/styles";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import clsx from "clsx";
import { Link } from "react-router-dom";
import EventNoteIcon from '@mui/icons-material/EventNote';
import { useTranslation } from "react-i18next";
import GreenCheckboxWithWarning from "../GreenCheckboxWithWarning";
import ScheduleIcon from '@mui/icons-material/Schedule';
import CancelledScheduleIcon from "../CancelledScheduleIcon";

const useStyles = makeStyles(({ spacing }: Theme) =>
  createStyles({
    header: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginBottom: "0.5em",
    },
    periodTitle: {
      minWidth: "10em",
      textAlign: "center",
    },
    wrap: {
      overflowX: "auto",
    },
    calendarTable: {
      borderCollapse: "collapse",
      marginBottom: "1em",
      "& th": {
        border: "1px solid #aaa",
        minWidth: "2em",
        padding: "0.5em",
        fontWeight: "normal",
      },
      "& td": {
        border: "1px solid #aaa",
        padding: "0.5em",
      },
    },
    cellRoutineTitle: {
      fontWeight: "bold !important",
    },
    cellLabel: {
      textAlign: "left",
    },
    cellValue: {
      textAlign: "center",
    },
    ok: {
      color: "darkgreen",
    },
    warning: {
      color: "orange",
    },
    error: {
      color: "darkred",
    },
  })
);

type Props = {
  siteId: string;
};

type PeriodFromTo = {
  from: DateTime;
  to: DateTime;
};

export const DeviationCalendar: React.FC<Props> = ({ siteId }) => {
  const [date, setDate] = useState(DateTime.now());
  const [fromTo, setFromTo] = useState<PeriodFromTo>();
  const [periodType, setPeriodType] = useState<"week" | "month">("week");
  const handleTabChange = (event, newValue) => {
    setPeriodType(newValue);
  };

  const [canNavigateNext, setCanNavigateNext] = useState(true);

  const [isCalendarDataLoading, setIsCalendarDataLoading] = useState(true);
  const [calendarData, setCalendarData] = useState<ChecklistPeriodState[]>([]);
  const classes = useStyles();

  const getDateMovedByPeriod = (input: DateTime, moveValue: 1 | -1): DateTime =>
    input.plus(
      periodType === "month" ? { months: moveValue } : { weeks: moveValue }
    );

  const updatePeriodNavigationEnabled = () => {
    const nextDate = getDateMovedByPeriod(date, 1);
    setCanNavigateNext(nextDate < DateTime.now());
  };
  const { t } = useTranslation();
  useEffect(() => {
    updatePeriodNavigationEnabled();
  }, [fromTo, periodType]);

  useEffect(() => {
    // Update from/to when period or current date changes
    const from = date.startOf(periodType);
    const to = getDateMovedByPeriod(from, 1);

    setFromTo({ from, to });
  }, [date, periodType]);

  useEffect(() => {
    // Retrieve calendar data when from/to changes
    if (!fromTo) {
      return;
    }
    let isMounted = true;
    const { from, to } = fromTo;
    const now = DateTime.now();
    const retrieveTo = to < now ? to : now;

    setIsCalendarDataLoading(true);
    getChecklistCalendar(siteId, from, retrieveTo).then((result) => {
      if (isMounted) {
        setCalendarData(result.data);
        setIsCalendarDataLoading(false);
      }
    });

    return () => {
      isMounted = false;
    };
  }, [fromTo]);


  const groupAndOrderByChecklistAndAssignedId = (calendarData: ChecklistPeriodState[]): Record<string, ChecklistPeriodState[]> => {
    const scheduledItems = calendarData.filter(item => item.isScheduled);
    const unscheduledItems = calendarData.filter(item => !item.isScheduled);

    scheduledItems.sort((a, b) => (a.isScheduled && !b.isScheduled ? -1 : 1));
    unscheduledItems.sort((a, b) => (a.isScheduled && !b.isScheduled ? -1 : 1));

    const sortedAndGroupedItems = scheduledItems.concat(unscheduledItems);

    return sortedAndGroupedItems.reduce((result, item) => {
      const key = `${item.checklistId}-${item.assignedId}`;
      (result[key] = result[key] || []).push(item);
      return result;
    }, {});
  };




  const [periodItems, setPeriodItems] = useState<DateTime[]>();

  useEffect(() => {
    // Update period items (days in month/week) when from/to changes
    let newPeriodItems = [];

    if (fromTo) {
      const { from, to } = fromTo;
      let tmpDate = from;

      while (tmpDate < to) {
        newPeriodItems = [...newPeriodItems, tmpDate];
        tmpDate = tmpDate.plus({ days: 1 });
      }
    }

    setPeriodItems(newPeriodItems);
  }, [fromTo]);

  const renderPeriod = (period?: ChecklistPeriodState) => {
    if (!period) {
      return <></>;
    }

    const handledDeviations = period.calendarDeviations.filter(
      (deviation) => deviation.deviationStatus === PeriodDeviationStatus.Handled
    );
    const unhandledDeviations = period.calendarDeviations.filter(
      (deviation) => deviation.deviationStatus === PeriodDeviationStatus.Unhandled
    );

    return (
      <>
        {period.periodStatus === ChecklistPeriodStatus.UnhandledDeviations && (
          <div title={`${t('calendar.unhandledDeviations')} (${unhandledDeviations.length})${handledDeviations.length > 0 ? ` - Behandlede avvik (${handledDeviations.length})` : ''}`}>
            <Link to={`/checklist/dashboard/deviations?assignedId=${period.assignedId}&from=${period.periodFrom}&to=${period.periodTo}`}>
              <DisabledByDefaultIcon className={classes.error} />
            </Link>
          </div>
        )}

        {period.periodStatus === ChecklistPeriodStatus.HandledDeviations && (
          <div title={`${t('calendar.handledDeviations')} (${handledDeviations.length})`} style={{ position: 'relative' }}>
            <Link to={`/checklist/dashboard/deviations?assignedId=${period.assignedId}&from=${period.periodFrom}&to=${period.periodTo}`}>
              <div style={{ position: 'relative' }}>
                <GreenCheckboxWithWarning />
              </div>
            </Link>
          </div>
        )}
        {
          period.periodStatus === ChecklistPeriodStatus.Pending && (
            <div title={`${t('calendar.pending')}`}>
              <ScheduleIcon />
            </div>
          )
        }
        {
          period.periodStatus === ChecklistPeriodStatus.NotCompleted && (
            <div title={`${t('calendar.notCompleted')}`}>
              <CancelledScheduleIcon />
            </div>
          )
        }
        {period.periodStatus === ChecklistPeriodStatus.Completed && (
          <div title={`${t('calendar.completed')}`}>
            <CheckBoxIcon className={classes.ok} />
          </div>
        )}
      </>
    );
  };


  const onPrevPeriod = () => {
    setDate(getDateMovedByPeriod(date, -1));
  };
  const onNextPeriod = () => {
    setDate(getDateMovedByPeriod(date, 1));
  };

  return (
    <div>
      <div>
        <Grid container spacing={0} alignItems="left" justifyContent="left">
          <Grid item xs={4}>
            <CheckBoxIcon className={classes.ok} />
            <Typography>{t('calendar.completed')}</Typography>
            <GreenCheckboxWithWarning />
            <Typography>{t('calendar.handledDeviations')}</Typography>
          </Grid>
          <Grid item xs={4}>
            <ScheduleIcon />
            <Typography>{t('calendar.pending')}</Typography>
            <DisabledByDefaultIcon className={classes.error} />
            <Typography>{t('calendar.unhandledDeviations')}</Typography>
          </Grid>
          <Grid item xs={4}>
            <CancelledScheduleIcon />
            <Typography>{t('calendar.notCompleted')}</Typography>
          </Grid>
        </Grid>
      </div>
      <div>
        <Tabs value={periodType} onChange={handleTabChange}>
          <Tab label={t('calendar.monthTab')} value="month" />
          <Tab label={t('calendar.weekTab')} value="week" />
        </Tabs>
      </div>

      <div className={classes.header}>
        <Button onClick={onPrevPeriod}>
          <ArrowBackIos />
        </Button>

        <div className={classes.periodTitle}>
          {periodType === "month"
            ? date.toFormat("MMMM yyyy")
            : `Uke ${date.weekNumber} ${date.year}`}
        </div>

        <Button onClick={onNextPeriod} disabled={!canNavigateNext}>
          <ArrowForwardIos />
        </Button>
      </div>
      {isCalendarDataLoading && <CircularProgress />}
      {!isCalendarDataLoading && (
        <div className={classes.wrap}>
          <table className={classes.calendarTable}>
            <tr>
              <th className={clsx(classes.cellLabel, classes.cellRoutineTitle)} colSpan={2}>
                Rutiner
              </th>
              {periodItems &&
                periodItems.map((p) => (
                  <th className={classes.cellValue}>
                    {p.toLocaleString(
                      periodType === "month"
                        ? { day: "2-digit" }
                        : { weekday: "long", day: "2-digit", month: "short" }
                    )}
                  </th>
                ))}
            </tr>
            {!isCalendarDataLoading &&
              calendarData &&
              // Group calendarData by ChecklistId
              Object.values(groupAndOrderByChecklistAndAssignedId(calendarData)).map((checklistGroup) => (
                <tr key={checklistGroup[0].checklistId}>
                  <td style={{ borderRight: "none" }}>
                    <Link to={`/checklist/overview/${checklistGroup[0].assignedId}?from=${fromTo?.from?.toISODate()}&to=${fromTo?.to?.minus({ day: 1 })?.toISODate()}`}>
                      <Tooltip title={t('checklist.actions.reports')}>
                        <IconButton>
                          <EventNoteIcon sx={{ color: 'green' }} />
                        </IconButton>
                      </Tooltip>
                    </Link>
                  </td>
                  <td style={{ borderLeft: "none" }} className={classes.cellLabel}>
                    {checklistGroup[0].name}
                  </td>
                  {periodItems.map((periodStartTime) => {
                    const periodStartUtc = periodStartTime.toUTC();
                    const period = checklistGroup.find((c) => {
                      const deviationPeriodStart = DateTime.fromISO(c.periodFrom.toString()).toUTC();

                      // Because of time zone issues, match period with the closest day
                      const diff = periodStartUtc.diff(deviationPeriodStart);
                      const diffHours = diff.as("hours");
                      const isMatch = Math.abs(diffHours) < 12;

                      return isMatch;
                    });

                    // Render the cell content based on the presence of the period
                    return (
                      <td className={classes.cellValue}>
                        {period ? renderPeriod(period) : ""}
                      </td>
                    );
                  })}
                </tr>
              ))}
          </table>
        </div>
      )}
    </div>
  );
};
