<div class="_content">
  <div class="add-user-modal">
      <span class="go-back">
        <a class="content__heading__go-back" routerLink="/" routerLinkActive="active">
          <i class="material-icons">
            home
  </i>
        </a>
      </span>
  
      <h1 class="add-user-modal__title">
        Registrer en bruker
      </h1>
  
      <form class="add-user-modal__form" >
        <mat-form-field>
            <input
              matNativeControl
              type="text"
              name="Navn"
              placeholder="Navn"
              class="full-width-field"
            />
        </mat-form-field>
  
        <mat-form-field>
          <input
            matInput
            type="text"
            name="Mobil"
            placeholder="Mobil"
            class="full-width-field"
          />
        </mat-form-field>
  
        <mat-form-field>
          <input
            matInput
            type="text"
            name="Epost"
            placeholder="Epost"
            class="full-width-field"
          />
        </mat-form-field>
  
        <mat-form-field>
          <mat-select placeholder="Velg brukerrolle">
            <mat-option value="Admin">
              Admin
            </mat-option>
            <mat-option value="Temperature">
              Temperature registerer
            </mat-option>
          </mat-select>
        </mat-form-field>
  
        <div class="center">
          <button
            mat-button
            class="send-button"
            routerLink="/user-list" routerLinkActive="active"
          >
            Neste
          </button>
        </div>
      </form>
  </div>
</div>
