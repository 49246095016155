import * as React from "react";
import {
  IconButton,
  Menu,
  MenuItem,
  Theme,
} from "@mui/material";
import { makeStyles, createStyles } from '@mui/styles';
import { useTranslation } from "react-i18next";

type Props = {
  entityType: any;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chip: {
      marginLeft: theme.spacing(1),
      backgroundColor: "#ba000d",
      color: "#fff",
    },
  })
);

const Settings = ({ entityType }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleSettingsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton onClick={handleSettingsClick}>
        <span className="material-icons">settings</span>
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem
          onClick={() => (window.location.href = `edit-entity-type/${entityType.id}`)}
        >
          {t("Edit")}
        </MenuItem>
      </Menu>
    </>
  );
};

export default Settings;
