import { Typography, Tooltip, Theme, Checkbox, Box } from "@mui/material";

import InfoIcon from "@mui/icons-material/Info";

import React from "react";
import { useTranslation } from "react-i18next";
import MediaRender from "../../../../react-components/MediaRender";
import DescriptionEditor from "./DescriptionEditor";
import MarkdownDisplay from "../../../../react-components/MarkdownDisplay";
import PointExternalLink from "./PointExternalLink";
import { createStyles, makeStyles } from "@mui/styles";
import { ChecklistPointEvent, ChecklistPointEventType } from "./ChecklistPointEventHandler";

const useStyles = makeStyles(({ spacing, palette }: Theme) =>
  createStyles({
    root: {},
    margins: {
      marginTop: "1em",
      marginBottom: "1em",
    },
    descriptionContainer: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    templateIcon: {
      color: palette.primary.main,
    },
  })
);

type Props = {
  isEdit: boolean;
  isSiteEditDescription: boolean;
  isSiteEditDetails: boolean;
  tenantId?: string;
  pointId: string;
  isSiteSpecific: boolean;
  assignedId?: string;

  isDescriptionOverriden: boolean;
  templateDescription: string;

  description: string;
  onDescriptionChange: (description: string) => void;
  details: string;
  onDetailsChange: (details: string) => void;

  associatedEntity: string;
  breakageUnit: string;
  labels: string[];

  imagePath: string;
  externalLink: any;
  externalLinkEnabled: boolean;

  checklist?: any;

  isMultiPoint: boolean;
  onMultiPointEnabled: (event: ChecklistPointEvent) => void;
  onMultiPointDisabled: (event: ChecklistPointEvent) => void;
};

const PointCardContent = ({
  isSiteSpecific,
  assignedId,
  isEdit,
  isSiteEditDescription,
  isSiteEditDetails,
  isDescriptionOverriden,
  templateDescription,
  description,
  onDescriptionChange,
  details,
  onDetailsChange,
  associatedEntity,
  breakageUnit,
  imagePath,
  externalLink,
  externalLinkEnabled,
  checklist,
  labels,
  isMultiPoint,
  onMultiPointEnabled,
  onMultiPointDisabled,
  tenantId,
  pointId
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const markdownImages = checklist.images.concat(checklist.markdownImages);

  const handleMultiPointChange = (enabled: boolean) => {
    const eventType = isSiteSpecific ? (
      enabled ? ChecklistPointEventType.AssignedMultiPointEnabled : ChecklistPointEventType.AssignedMultiPointDisabled
    ) : (
      enabled ? ChecklistPointEventType.MultiPointEnabled : ChecklistPointEventType.MultiPointDisabled
    );
  
    const eventData = {
      pointId: pointId,
      tenantId: tenantId,
      checklistId: checklist.checklistId,
      ...(isSiteSpecific && { assignedId }),
    };
  
    const eventAction = enabled ? onMultiPointEnabled : onMultiPointDisabled;
  
    eventAction({ type: eventType, data: eventData });
  };
  

  return (
    <>
      {isEdit || isSiteEditDescription ? (
        <div className={classes.margins}>
          <DescriptionEditor
            value={description}
            onChange={onDescriptionChange}
            markdownImages={markdownImages}
            checklist={checklist}
          />
        </div>
      ) : (
        <div className={classes.descriptionContainer}>
          {isDescriptionOverriden && (
            <Tooltip
              title={`${t(
                "The template description"
              )}: " ${templateDescription} " ${t("is overridden")}.`}
            >
              <InfoIcon className={classes.templateIcon} />
            </Tooltip>
          )}
          <MarkdownDisplay>{description}</MarkdownDisplay>
        </div>
      )}
      {isSiteEditDetails ? (
        <div className={classes.margins}>
          <DescriptionEditor
            value={details}
            markdownImages={markdownImages}
            onChange={onDetailsChange}
          />
        </div>
      ) : (
        details && <MarkdownDisplay>{details}</MarkdownDisplay>
      )}
     {labels.length > 0 && (
        <Typography variant="body1">
          <b>{t("Labeling.labels")}: </b>
          {labels.map((label, index) => (
            <React.Fragment key={index}>
              {label}
              {index !== labels.length - 1 && ', '}
            </React.Fragment>
          ))}
        </Typography>
      )}
      {associatedEntity && (
        <Typography variant="body1">
          <b>{t("Associated object")}:</b> {associatedEntity}
        </Typography>
      )}
      {breakageUnit && (
        <Typography variant="body1">
          <b>{t("Breakage unit")}:</b> {breakageUnit}
        </Typography>
      )}
      {imagePath && <MediaRender src={imagePath} />}
      {externalLink && externalLinkEnabled && (
        <PointExternalLink externalLink={externalLink} />
      )}
      <Box mt={2}>
        <Typography><b>{t("editChecklist.editPoint.multipoint.enable")}:</b></Typography>
        <Checkbox color="default" disabled={!isEdit} checked={isMultiPoint} onChange={(_, v) => handleMultiPointChange(v)} />
      </Box>
    
    </>
  );
};

export default PointCardContent;
