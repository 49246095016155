import { Site } from "../../../../entity/site";
import { Guid } from "../../../../system/guid";
import { useAddBreakageToPoint, useAddChecklistPoint, useAddChecklistPointToSite, useAddMediaToPoint, useAddPointDescription, useAddPointExternalLink, useAddSitePointDescription, useAddSitePointDetails, useAssignedLabelAddedToPoint, useAssignedLabelRemovedFromPoint, useAssignedMultiPointDisabled, useAssignedMultiPointEnabled, useDisablePoint, useDisablePointExternalLink, useEnablePoint, useEnablePointExternalLink, useLabelAddedToPoint, useLabelRemovedFromPoint, useMovePointAfter, useMultiPointDisabled, useMultiPointEnabled, useNamePointSite, useSiteAddMediaToPoint, useSiteDisablePoint, useSiteEnablePoint, useSiteMovePointAfter, useTagPointWithEntity } from "../../../services/checklistService"

export enum ChecklistPointEventType {
  PointAdded,
  PointMediaAdded,
  PointDescribed,
  PointExternalLinkAdded,
  PointExternalLinkEnabled,
  PointExternalLinkDisabled,
  PointDisabled,
  PointEnabled,
  PointMovedAfter,
  PointManualEntityTagged,
  PointBreakage,
  SitePointAdded,
  SitePointNamed,
  SitePointDescribed,
  SitePointDetails,
  SitePointEnabled,
  SitePointDisabled,
  SitePointMovedAfter,
  SiteAddMediaPoint,
  LabelAdded,
  LabelRemoved,
  AssignedLabelAdded,
  AssignedLabelRemoved,
  MultiPointEnabled,
  MultiPointDisabled,
  AssignedMultiPointEnabled,
  AssignedMultiPointDisabled,
}

export type ChecklistPointEvent = {
  type: ChecklistPointEventType;
  data: any;
}

function removeDuplicateEvents(events: ChecklistPointEvent[]) {
  const seen = {};
  return events.filter(e => {
    const type = e.type;
    if (type === ChecklistPointEventType.LabelAdded || type === ChecklistPointEventType.LabelRemoved || type === ChecklistPointEventType.AssignedLabelAdded || type === ChecklistPointEventType.AssignedLabelRemoved) {
      return true; //Allow duplication of labeling events
    }
    return seen.hasOwnProperty(type) ? false : (seen[type] = true);
  })
}


export class ChecklistPointEventHandler {

  private static eventHandlersMap: { [key in ChecklistPointEventType]: (data: any) => Promise<any> } = {
    [ChecklistPointEventType.PointAdded]: useAddChecklistPoint,
    [ChecklistPointEventType.PointMediaAdded]: useAddMediaToPoint,
    [ChecklistPointEventType.PointDescribed]: useAddPointDescription,
    [ChecklistPointEventType.PointExternalLinkAdded]: useAddPointExternalLink,
    [ChecklistPointEventType.PointExternalLinkEnabled]: useEnablePointExternalLink,
    [ChecklistPointEventType.PointExternalLinkDisabled]: useDisablePointExternalLink,
    [ChecklistPointEventType.PointDisabled]: useDisablePoint,
    [ChecklistPointEventType.PointEnabled]: useEnablePoint,
    [ChecklistPointEventType.PointMovedAfter]: useMovePointAfter,
    [ChecklistPointEventType.PointBreakage]: useAddBreakageToPoint,
    [ChecklistPointEventType.PointManualEntityTagged]: useTagPointWithEntity,
    [ChecklistPointEventType.SitePointAdded]: useAddChecklistPointToSite,
    [ChecklistPointEventType.SitePointNamed]: useNamePointSite,
    [ChecklistPointEventType.SitePointDescribed]: useAddSitePointDescription,
    [ChecklistPointEventType.SitePointDetails]: useAddSitePointDetails,
    [ChecklistPointEventType.SitePointEnabled]: useSiteEnablePoint,
    [ChecklistPointEventType.SitePointDisabled]: useSiteDisablePoint,
    [ChecklistPointEventType.SitePointMovedAfter]: useSiteMovePointAfter,
    [ChecklistPointEventType.SiteAddMediaPoint]: useSiteAddMediaToPoint,
    [ChecklistPointEventType.LabelAdded]: useLabelAddedToPoint,
    [ChecklistPointEventType.LabelRemoved]: useLabelRemovedFromPoint,
    [ChecklistPointEventType.AssignedLabelAdded]: useAssignedLabelAddedToPoint,
    [ChecklistPointEventType.AssignedLabelRemoved]: useAssignedLabelRemovedFromPoint,
    [ChecklistPointEventType.MultiPointEnabled]: useMultiPointEnabled,
    [ChecklistPointEventType.MultiPointDisabled]: useMultiPointDisabled,
    [ChecklistPointEventType.AssignedMultiPointEnabled]: useAssignedMultiPointEnabled,
    [ChecklistPointEventType.AssignedMultiPointDisabled]: useAssignedMultiPointDisabled
  }

  public static async handle(events: ChecklistPointEvent[]): Promise<any[]> {
    return await Promise.all(removeDuplicateEvents(events).map(e => this.eventHandlersMap[e.type](e.data)));
  }

}

const addIf = (condition: boolean, el: any) => condition ? [el] : [];

type Params = {
  isAdd: boolean;
  isSiteSpecific: boolean;
  newPointId?: string;
  point: any;
  checklist: any;
  site: Site;

  pointName: string;
  sitePointName: string;
  pointDescription: string;
  pointSiteDescription: string;
  isPointEnabled: boolean;
  isPointDeleted: boolean;
  isPointEnabledForSite: boolean;
  isPointDeletedForSite: boolean;
  pointSiteDetails: string;
  externalLinkEnabled: boolean;

  existingPointEvents: ChecklistPointEvent[];
}

export function generateReversedPointEventsList({
  isAdd,
  isSiteSpecific,
  newPointId,
  point,
  checklist,
  site,

  pointName,
  sitePointName,
  pointDescription,
  pointSiteDescription,
  isPointEnabled,
  isPointDeleted,
  isPointEnabledForSite,
  isPointDeletedForSite,
  pointSiteDetails,
  externalLinkEnabled,

  existingPointEvents,
}: Params): ChecklistPointEvent[] {

  const pointId = point?.id ?? newPointId;

  const pointNameChanged = point?.name !== pointName;
  const sitePointNameChanged = point?.siteName ? point?.siteName !== sitePointName : (!isAdd && sitePointName !== pointName);
  const pointInfoChanged = isAdd || pointNameChanged || point?.description !== pointDescription;
  const pointStatusChanged = !isAdd && point?.enabled !== isPointEnabled;
  const externalLinkStatusChanged = point?.externalLinkEnabled !== externalLinkEnabled;

  const sitePointInfoChanged = point?.siteDescription ? point?.siteDescription !== pointSiteDescription : pointSiteDescription !== pointDescription;
  const sitePointDetailsChanged = pointSiteDetails && point?.details !== pointSiteDetails;
  const sitePointStatusChanged = !isAdd && point?.siteEnabled !== isPointEnabledForSite;

  const pointHasBeenDeleted = !isAdd && isPointDeleted && !point?.deleted;
  const pointHasBeenDeletedForSite = !isAdd && isPointDeletedForSite && !point?.siteDeleted;

  return [
    ...addIf(isAdd, {
      type: isSiteSpecific ? ChecklistPointEventType.SitePointAdded : ChecklistPointEventType.PointAdded,
      data: {
        checklistId: checklist.checklistId,
        tenantId: site.tenantId.toString(),
        assignedId: isSiteSpecific ? checklist.assignedId : undefined
      }
    }),
    ...addIf(sitePointNameChanged, {
      type: ChecklistPointEventType.SitePointNamed,
      data: {
        checklistId: checklist.checklistId,
        tenantId: site.tenantId.toString(),
        name: sitePointName,
        assignedId: checklist.assignedId
      }
    }),
    ...addIf(pointInfoChanged, {
      type: ChecklistPointEventType.PointDescribed,
      data: {
        checklistId: checklist.checklistId,
        tenantId: site.tenantId.toString(),
        name: pointName,
        description: pointDescription,
      }
    }),
    ...addIf(pointStatusChanged && !pointHasBeenDeleted, {
      type: isPointEnabled ? ChecklistPointEventType.PointEnabled : ChecklistPointEventType.PointDisabled,
      data: {
        checklistId: checklist.checklistId,
        tenantId: site.tenantId.toString(),
      }
    }),
    ...addIf(pointHasBeenDeleted, {
      type: ChecklistPointEventType.PointDisabled,
      data: {
        checklistId: checklist.checklistId,
        tenantId: site.tenantId.toString(),
        deleted: true,
      }
    }),
    ...addIf(externalLinkStatusChanged, {
      type: externalLinkEnabled ? ChecklistPointEventType.PointExternalLinkEnabled : ChecklistPointEventType.PointExternalLinkDisabled,
      data: {
        checklistId: checklist.checklistId,
        tenantId: site.tenantId.toString(),
      }
    }),
    ...addIf(sitePointInfoChanged, {
      type: ChecklistPointEventType.SitePointDescribed,
      data: {
        assignedId: checklist.assignedId,
        checklistId: checklist.checklistId,
        tenantId: site.tenantId.toString(),
        description: pointSiteDescription,
      }
    }),
    ...addIf(sitePointDetailsChanged, {
      type: ChecklistPointEventType.SitePointDetails,
      data: {
        assignedId: checklist.assignedId,
        checklistId: checklist.checklistId,
        tenantId: site.tenantId.toString(),
        details: pointSiteDetails,
      }
    }),
    ...addIf(sitePointStatusChanged && !pointHasBeenDeletedForSite, {
      type: isPointEnabledForSite ? ChecklistPointEventType.SitePointEnabled : ChecklistPointEventType.SitePointDisabled,
      data: {
        assignedId: checklist.assignedId,
        checklistId: checklist.checklistId,
        tenantId: site.tenantId.toString(),
      }
    }),
    ...addIf(pointHasBeenDeletedForSite, {
      type: ChecklistPointEventType.SitePointDisabled,
      data: {
        assignedId: checklist.assignedId,
        checklistId: checklist.checklistId,
        tenantId: site.tenantId.toString(),
        deleted: true,
      }
    }),

    ...existingPointEvents
  ]
    .reverse()
    // set the pointId
    .map(e => {
      return {
        ...e, data: {
          ...e.data,
          pointId
        }
      }
    });
}