import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'devices-list',
  templateUrl: './devices-list.component.html',
  styleUrls: ['./devices-list.component.scss']
})
export class DevicesListComponent implements OnInit {

  constructor(private router: Router) { }

  routing(route: string){
    this.router.navigate([route]);
  }

  ngOnInit() {
  }

}
