import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Theme,
  Typography,
} from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { AlarmsContext } from "../AlarmsReactWrapper";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { EntityAlarmsInfo } from "../services/siteAlarmInfoService";

const styles = ({ spacing }: Theme) =>
  createStyles({
    root: {
      paddingLeft: spacing(4),
      paddingRight: spacing(4),
    },
  });

const useStyles = makeStyles(styles);

type Props = {
  entityAlarms: EntityAlarmsInfo[];
};

const EntityAlarmsList: React.FC<Props> = ({ entityAlarms }) => {
  const { t } = useTranslation();


  return (
    <>
      {entityAlarms.map((entityAlarm, i) => (
        <Accordion key={entityAlarm.entityName + i}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-label="Expand">
            <Typography variant="h4">
              {entityAlarm.entityName} ({entityAlarm.alarms.length} {t("alarms.alarms")})
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {entityAlarm.alarms.map((alarm, i) => (
              <div key={alarm.date + i}>
                {alarm.date} - <strong>{alarm.type}</strong>
              </div>
            ))}
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
};

export default EntityAlarmsList;
