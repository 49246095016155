<div *ngIf="!isNew && (!entity || entity.id === '')">
  Laster data...
</div>
<div [ngClass]="{'entity-loading': !isNew && (!entity || entity.id === '')}">
  <form [formGroup]="entityForm" (ngSubmit)="onSubmit(entityForm)">
    <div class="action-form">

      <mat-form-field>
        <input matInput placeholder="*Navn" formControlName="name" />
      </mat-form-field>

        <template #entitySpecificsContainer>
        </template>

      <mat-form-field>
        <input matInput placeholder="*Signal" formControlName="signal" />
      </mat-form-field>

      <ng-template [ngIf]="signalFunctions.length > 1 && isSignalSpecified">
        <mat-form-field>
          <mat-label>Sensor funksjon</mat-label>
          <mat-select [(value)]="selectedSignalFunction">
            <mat-option *ngFor="let signalFunction of signalFunctions" [value]="signalFunction">
              {{ signalFunction.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </ng-template>

      <mat-select
        #signalSelect
        formControlName="selectedSignal"
        placeholder="Bank"
        #singleSelect
      >
        <mat-option *ngFor="let signal of signals$ | async" [value]="signal">
          <b>{{ signal.sourceId }}</b> : {{ signal.value }}
        </mat-option>
      </mat-select>

      <mat-checkbox id="dissable" type="checkbox" formControlName="disabled">
        Deaktiver
      </mat-checkbox>
      <mat-checkbox id="hide" type="checkbox" formControlName="hide">
        Skjul
      </mat-checkbox>
    </div>

    <div class="error-message center">{{ errorMessage }}</div>
    <div class="center">
      <button mat-button class="send-button" type="submit">
        Lagre
      </button>
    </div>
  </form>
</div>
