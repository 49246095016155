import { TextField, FormControlLabel, Switch } from "@mui/material";

import React from "react";
import { useTranslation } from "react-i18next";

import MarkdownInput from "./MarkdownInput";

type Props = {
  value: string;
  onChange: (v: string) => void;
  images?: string[];
  markdownImages?: string[];
  checklist?: any;
};

const DescriptionEditor: React.FC<Props> = ({
  value,
  onChange,
  images,
  markdownImages,
  checklist,
}) => {
  const { t } = useTranslation();

  const [isMarkdown, setMarkdown] = React.useState<boolean>(false);

  return (
    <>
      <FormControlLabel
        control={
          <Switch
            checked={isMarkdown}
            onChange={() => setMarkdown(!isMarkdown)}
            name="disabled"
            color="primary"
          />
        }
        label={t("Markdown Editor") as string}
      />
      <br />
      {isMarkdown ? (
        <MarkdownInput
          value={value}
          onChange={onChange}
          images={images}
          markdownImages={markdownImages}
          checklist={checklist}
          label={t("editChecklist.editPoint.card.inputDescriptionLabel")}
        />
      ) : (
        <TextField
          style={{ width: "100%" }}
          value={value}
          multiline
          maxRows={8}
          onChange={e => onChange(e.target.value)}
          label={t("editChecklist.editPoint.card.inputDescriptionLabel")}
        />
      )}
    </>
  );
};

export default DescriptionEditor;
