<swiper [config]="config">
  <div class="swiper-wrapper">
    <div *ngFor="let item of deviationActionList" class="swiper-slide">
      <div class="deviation-box">
        <div class="deviation-box__temperatures-row">
          <span class="deviation-box__temperatures-row__min-max">Max: {{entityMax}}&deg; </span>
          <span class="deviation-box__temperatures-row__min-max">Min: {{entityMin}}&deg; </span>
          <span class="deviation-box__temperatures-row__registered"> {{ item.entityValue }} &deg;C</span>
        </div>

        <div class="deviation-box__description">
           {{ item.entityAction }}
        </div>

        <div class="deviation-box__user">
          <img src="assets/icons/icn_profile_active.png" alt="deviation-user-icon" />
          <span> Signert av Andre</span>
        </div>
      </div>
    </div>
  </div>
  <!-- Add Arrows -->
  <div class="swiper-button-next"></div>
  <div class="swiper-button-prev"></div>
</swiper>