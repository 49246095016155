import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#D97C54",
    },
  },
  typography: {
    h1: {
      fontWeight: "bold",
      fontSize: "2em",
    },
    h2: {
      fontSize: "2em",
    },
    h3: {
      fontSize: "1.5em",
    },
    h4: {
      fontSize: "1.5em",
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: "#fafafa",
        },
      }
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          '&.Mui-focused, &.Mui-focusVisible': {
            backgroundColor: 'transparent',
          },
        },
      },
    }
  }
});
