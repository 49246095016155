import { Button, Paper, TextField, Typography, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import moment from "moment";
import React, { FC, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReportType, TempReport } from "../../reports/tempreport";
import { Guid } from "../../system/guid";
import { EntityAlarmDeviation } from "../services/entityDeviationServiceUtils";
import { SurveillanceReportsContext } from "../SurveillanceReportsReactWrapper";

const styles = ({ spacing }: Theme) =>
  createStyles({
    root: {
      padding: spacing(4),
      margin: spacing(2),
    },
    flexContainer: {
      display: "flex",
      justifyContent: "space-between",
      flexWrap: "wrap",
      gap: spacing(2)
    },
    submitButtonContainer: {
      display: "flex",
      justifyContent: "flex-end",
    },
    buttonsContainer: {
      paddingLeft: spacing(2),
      paddingRight: spacing(2),
      marginTop: spacing(2),
    },
    commentInputContainer: {
      marginTop: spacing(2),
      marginBottom: spacing(2),
    },
    mutedText: {
      color: "gray",
      fontStyle: "italic",
    },
    temperatureText: {
      color: "red",
    },
  });

const useStyles = makeStyles(styles);

type Props = {
  entityDeviation: EntityAlarmDeviation;
  tempReport: TempReport;
  deviationCauses?: { label: string; cause: string }[];
};

const defaultDeviationCauses = [
  { label: "Tomt", cause: "Det er tomt" },
  { label: "Vaske & rydde", cause: "Vi har gjort rengjøring" },
  { label: "Varelevering", cause: "Vi har fått inn nye varer" },
  { label: "Under tilberedning", cause: "Under tilberedning" },
];

export const EntityDeviationReport: FC<Props> = ({
  entityDeviation,
  deviationCauses,
  tempReport,
}) => {
  if (!deviationCauses?.length) deviationCauses = defaultDeviationCauses;
  const { entity, measurement, timestamp, currentValue, isHandled } = entityDeviation;
  const latestValueTimestamp = tempReport.getOccuredTime();

  const { tempReportService } = useContext(SurveillanceReportsContext);

  const classes = useStyles();
  const { t } = useTranslation();

  const [comment, setComment] = useState("");
  const [handled, setHandled] = useState(isHandled);

  const handleSubmit = () => {
    tempReport.setState(400);
    tempReport.reportType = ReportType.Manual;
    const id = Guid.newGuid().toString();
    const newTempReport = {
      ...tempReport,
      id,
      measurements: {
        [entity.id]: measurement,
      },
      deviations: {
        [entity.id]: {
          entityId: entity.id,
          action: comment,
          occuredTime: timestamp,
          state: 400,
        },
      },
    };
    tempReportService
      .registerTempReport(newTempReport as any)
      .subscribe(() => setHandled(true));
  };

  return (
    <Paper className={classes.root}>
      <div className={classes.flexContainer}>
        <Typography variant="h3">{entity.name}</Typography>
        <Typography variant="h3" className={classes.temperatureText}>
          {measurement?.value ? `${measurement?.value}°C` : ''}
        </Typography>
      </div>
      <div className={classes.flexContainer}>
        <span>
          <b>{currentValue}°C </b>
          <span className={classes.mutedText}>
            {moment(latestValueTimestamp).format("HH:mm DD.MM.YYYY")}
          </span>
        </span>
        <span className={classes.mutedText}>
          {moment(timestamp).format("HH:mm DD.MM.YYYY")}
        </span>
      </div>
      {!handled && (
        <>
          <div
            className={classes.buttonsContainer + " " + classes.flexContainer}
          >
            {deviationCauses.map(({ label, cause }, i) => (
              <Button
                key={`${label}${cause}${i}`}
                variant="contained"
                onClick={() => {
                  setComment(cause);
                }}
              >
                {label}
              </Button>
            ))}
          </div>
          <div className={classes.commentInputContainer}>
            <TextField
              rows="5"
              multiline
              fullWidth
              variant="standard"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              placeholder={t('surveillanceDeviationReports.actionPlaceholder')}
            ></TextField>
          </div>
          <div className={classes.submitButtonContainer}>
            <Button variant="contained" onClick={handleSubmit}>
              {t("surveillanceDeviationReports.submit")}
            </Button>
          </div>
        </>
      )}
      {handled && (
        <>
          {t("surveillanceDeviationReports.handled")}
        </>
      )}
    </Paper>
  );
};
