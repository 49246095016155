<button class="person-link" mat-button [matMenuTriggerFor]="menu">
  <i class="material-icons personColor">
    person
  </i>
</button>
<mat-menu #menu="matMenu" class="user">
  <button mat-menu-item *ngIf="user$ | async">
    {{ (user$ | async).adalUser.name }}
  </button>
  <button mat-menu-item (click)="goToUserProfile()">
    <span class="personColor">{{"User Profile" | i18next }}</span>
  </button>
  <button mat-menu-item (click)="setDefaultPage()">
    <span class="personColor">{{"Save Current Page as Default" | i18next }}</span>
  </button>

  <button *ngIf="pushNotificationsEnabled" mat-menu-item (click)="goToNotificationSubscriptionSettings()">
    <span class="personColor">{{"Push Notification Subscriptions" | i18next }}</span>
  </button>
  
  <button mat-menu-item *ngIf="canInstall" (click)="installApp()">
    <span class="personColor">{{"Install on Device" | i18next }}</span>
  </button>
  <button mat-menu-item (click)="updateCache()">
    <span class="personColor">{{"Version" | i18next}}: {{version}} - {{"Update app" | i18next }}</span>
  </button>
  <button mat-menu-item (click)="logout()">
    <span class="personColor">{{"Log Out" | i18next }}</span>
  </button>
</mat-menu>
