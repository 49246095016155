<app-header></app-header>
<div class="user-list">
  <span class="go-back">
    <a routerLink="/add-user" routerLinkActive="active">
      <i class="material-icons">
keyboard_arrow_left
</i>
    </a>
  </span>

  <mat-card class="user-list__users">
    <div class="user-list__users__item">
      <div class="row">
        <span class="user-list__users__item__name">
          Olav Jensen
        </span>
        <div class="user-list__users__item__actions">
          <img src="assets/icons/rubbish-bin.png" class="delete" alt="delete" />
          <img src="assets/icons/edit.png" class="edit" alt="edit" />
        </div>
      </div>
      <div class="row">
        <p class="phone user-info"> 90558195 </p>
        <p class="email user-info"> aolav@gmail.com </p>
      </div>
    </div>
    <div class="user-list__users__item">
      <div class="row">
        <span class="user-list__users__item__name">
          Olav Jacko Jackobsen
        </span>
        <div class="user-list__users__item__actions">
          <img src="assets/icons/rubbish-bin.png" class="delete" alt="delete" />
          <img src="assets/icons/edit.png" class="edit" alt="edit" />
        </div>
      </div>
      <div class="row">
        <p class="phone user-info"> 90448194 </p>
        <p class="email user-info"> jola@gmail.com </p>
      </div>
    </div>
    <div class="user-list__users__add"> + </div>
  </mat-card>

  <div class="center">
    <button class="exit">
      Avslutt
    </button>
  </div>
</div>
