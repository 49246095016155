import {
  Button,
  Dialog,
  DialogContent,
  Theme
} from "@mui/material";
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from "react";
import DialogTitle from "../../../react-components/DialogTitle";
import { useTranslation } from "react-i18next";
import { TagsField } from "../../../react-components/TagsField";
import { areTagsInvalid, RESERVED_TAGS, useGetChecklistTags, useTagChecklist, useUntagChecklist } from "../../services/checklistService";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: theme.spacing(1),
  },
  errorText: {
    color: 'red',
    paddingTop: '1em'
  }
}));

type Props = {
  checklist: any;
  tenantId: string;
  open: boolean;
  existingTags: string[];
  onSuccess: (tagEvents: any[]) => void;
  onClose: () => void;
};

const EditChecklistTagsDialog = ({ checklist, tenantId, open, onClose, existingTags, onSuccess }: Props) => {
  const classes = useStyles();

  const { t } = useTranslation(); //translation

  const [{ data, loading, error },] = useGetChecklistTags(tenantId);

  const [tags, setTags] = useState<string[]>(existingTags);
  const [tagsInvalid, setTagsInvalid] = useState<boolean>(false);

  useEffect(() => {
    setTagsInvalid(areTagsInvalid(tags));
  }, [tags]);

  const handleSubmit = async () => {
    const untagValues = existingTags.filter(t => !tags.includes(t));
    const tagValues = tags.filter(t => !existingTags.includes(t));
    onSuccess(await Promise.all([
      ...untagValues.map(tag => useUntagChecklist({
        checklistId: checklist.checklistId,
        tenantId,
        tag
      })),
      ...tagValues.map(tag => useTagChecklist({
        checklistId: checklist.checklistId,
        tenantId,
        tag
      })),
    ]));
  };

  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose}>
      <DialogTitle onClose={onClose}>
        {`${t("Tag Checklist")}`}
      </DialogTitle>
      <DialogContent>
        <TagsField
          options={error || loading ? [] : data.filter(t => !RESERVED_TAGS.includes(t))}
          values={tags}
          onChange={setTags}
        />
        {tagsInvalid && <p className={classes.errorText}>{t(`Tags ${RESERVED_TAGS.map(t => `'${t}'`).join(', ')} are reserved.`)}</p>}
        <Button style={{marginTop: '1em'}} disabled={tagsInvalid} onClick={handleSubmit} variant="contained" color="primary">
          {`${t("editChecklist.submit")}`}
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default EditChecklistTagsDialog;
