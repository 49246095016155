import React, { FC, useEffect, useState } from "react";
import {
  InputAdornment,
  TextField,
  Theme,
} from "@mui/material";
import { makeStyles, createStyles } from '@mui/styles';
import { actions, ChecklistState } from "../services/reducer";
import { useDebouncedCallback } from "use-debounce";
import { useChecklistContext } from "../ChecklistReactWrapper";
import { useSignatureContext } from "../../signature-react/SignatureContext";

const useStyles = makeStyles(({ spacing, palette }: Theme) =>
  createStyles({
    tempInput: {
      marginRight: spacing(2),
    },
  })
);

type Props = {
  dispatch: any;
  checklist: any;
  point: any;
  checked: boolean;
  state: any;
  disabled?: boolean;
};

const BreakageAmountTextfield: FC<Props> = ({
  dispatch,
  checklist,
  point,
  checked,
  state,
  disabled,
}) => {
  const classes = useStyles();
  const { user } = useChecklistContext();
  const [amount, setAmount] = useState("");

  const {
    state: { activeSignature },
  } = useSignatureContext();
  const signatureId = activeSignature?.id?.toString();

  const debounced = useDebouncedCallback((value) => {
    setAmount(value);
    dispatch({
      type: actions.TYPE_BREAKAGE,
      payload: {
        checklistId: checklist.checklistId,
        tenantId: checklist.tenantId,
        assignedId: checklist.assignedId,
        pointId: point.id,
        userId: user.adalUser.oid,
        siteId: user.siteId,
        signatureId: signatureId,
        amount: value,
        unit: point.unit,
      },
    });

    if (value === "" && checked) {
      dispatch({
        type: actions.UNCHECK_POINT,
        payload: {
          checklistId: checklist.checklistId,
          tenantId: checklist.tenantId,
          assignedId: checklist.assignedId,
          pointId: point.id,
          userId: user.adalUser.oid,
          siteId: user.siteId,
          signatureId: signatureId,
        },
      });
    } else if (value !== "" && !checked) {
      dispatch({
        type: actions.CHECK_POINT,
        payload: {
          checklistId: checklist.checklistId,
          tenantId: checklist.tenantId,
          assignedId: checklist.assignedId,
          pointId: point.id,
          userId: user.adalUser.oid,
          siteId: user.siteId,
          signatureId: signatureId,
        },
      });
    }
  }, 1000);

  useEffect(() => {
    let mounted = true;
    if (!mounted) return;
    return () => {
      mounted = false;
    };
  }, [signatureId]);

  useEffect(() => {
    dispatch({
      type: actions.TYPE_BREAKAGE,
      payload: {
        amount: amount,
        pointId: point.id,
        fetch: true,
        siteId: user.siteId
      },
    });
  }, []);

  useEffect(() => {
    state.breakages[point.id] === undefined
      ? setAmount("")
      : setAmount(state.breakages[point.id].value);
  }, [state.breakages[point.id]]);

  return (
    <span
      onClick={(event) => {
        event.stopPropagation();
      }}
      onFocus={(event) => {
        event.stopPropagation();
      }}
    >
      <TextField
        className={classes.tempInput}
        id="outlined-required"
        label="Brekkasje"
        variant="outlined"
        type="number"
        value={amount || 0}
        disabled={state.state !== ChecklistState.IN_PROGRESS || disabled}
        onChange={(e) => {
          let stringAmount: string = e.target.value;
          if (!stringAmount.includes('.') && !stringAmount.includes(','))
            stringAmount = stringAmount.replace(/^0/g, '');
          setAmount(stringAmount);
          debounced(stringAmount);
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">{point.unit}</InputAdornment>
          ),
        }}
      />
    </span>
  );
};

export default BreakageAmountTextfield;
