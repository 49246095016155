<div class="report-history">
  <div class="deviation-list__heading">
    <div class="content__heading__go-back" routerLink="/">
      <i class="material-icons"> home </i>
    </div>

    <div class="deviation-list__heading__deviations-count">
      <button
        mat-raised-button
        matBadge="{{ deviationsCount }}"
        matBadgePosition="before"
        matBadgeColor="accent"
        [ngClass]="{ highlight: showreportView }"
        (click)="onclickToggleDeviations()"
      >
        {{ "Show Deviations" | i18next }}
      </button>
      <button
        mat-raised-button
        (click)="onclickToggleSignals(0)"
        [ngClass]="{ highlight: chartDataToShow === 0 }"
      >
        {{ "Show Reports" | i18next }}
      </button>
      <button
        mat-raised-button
        (click)="onclickToggleSignals(1)"
        [ngClass]="{ highlight: chartDataToShow === 1 }"
      >
        {{ "Show Measurements" | i18next }}
      </button>
    </div>
  </div>
  <mat-card *ngIf="showreportView" class="report-history__item">
    <mat-card-header class="close-button">
      <mat-card-actions>
        <a mat-raised-button (click)="onclickHideDeviations()">
          <i class="material-icons"> close </i>
        </a>
      </mat-card-actions>
    </mat-card-header>

    <mat-card-content>
      <app-deviationexpansion
        [reports]="reports"
        [siteEntities]="siteEntities"
        [period]="period"
      ></app-deviationexpansion>
    </mat-card-content>
  </mat-card>

  <!-- <mat-card *ngIf="!showreportView" class="report-history__item"> -->
  <mat-card class="report-history__item">
    <div class="report-history__item__dropdown">
      <mat-form-field class="report-history-labels">
        <mat-select
          [ngModel]="entityId"
          placeholder="{{ 'Choose entity' | i18next }}"
          (selectionChange)="selectEntity($event.value)"
          class="report-history__item__dropdown__device"
        >
          <mat-option
            *ngFor="let entity of entityList$ | async"
            name="entity"
            [value]="entity.id"
            ngDefaultControl
          >
            {{ entity.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <!-- <p class="deviations-count">
        <a (click)="showDeviationsModal()">
            Antall avvik: {{ deviationsCount }}
        </a>
      </p> -->
    </div>
    <tabs (changedPeriod)="selectPeriod($event)"></tabs>
    <div>
      <canvas id="canvas"></canvas>
      <div class="label-min-max">
        <p>{{ "Min" | i18next }}: {{ entityMin }}</p>
        <p>{{ "Max" | i18next }}: {{ entityMax }}</p>
      </div>
    </div>
    <!-- <deviation-actions-slider></deviation-actions-slider> -->
  </mat-card>
</div>
