<div class="options-tabs">
  <mat-tab-group (selectedTabChange)="selectTab($event)">
    <mat-tab label="{{'Week' | i18next}}">
    </mat-tab>
    <mat-tab label="{{'Month' | i18next}}">

    </mat-tab>
  </mat-tab-group>
  <!-- <button mat-button (click)="openDateModal()">
    <img class="calendar" src="/assets/icons/calendar-icon.png" alt="calendar">
  </button> -->
</div>

<!-- TODO Refactor to avoid duplication; content of both tabs are almost identical? -->
<div class="tab-content" *ngIf="showTab == i18next.t('Week')">
  <p class="tab-content__title" style="position: relative;"><!--TODO Update style and move to .scss-->
    <button (click)="onClickNavigate_before()" style="position: absolute; top: -0.5em; left: 0;"><!--TODO Update style and move to .scss-->
      <i class="material-icons">
        navigate_before
      </i>

    </button>
    {{'Week' | i18next}} ({{ startOfWeek }} - {{ endOfWeek }})
    <button *ngIf="isNavigateNextEnabled" (click)="onClickNavigate_next()" style="position: absolute; top: -0.5em; right: 0;"><!--TODO Update style and move to .scss-->
      <i class="material-icons">
        navigate_next
      </i>
    </button>
  </p>

</div>

<div class="tab-content" *ngIf="showTab == i18next.t('Month')">
  <p class="tab-content__title" style="position: relative;"><!--TODO Update style and move to .scss-->
    <!-- Februar -->
    <button (click)="onClickNavigate_before()" style="position: absolute; top: -0.5em; left: 0;"><!--TODO Update style and move to .scss-->
      <i class="material-icons">
        navigate_before
      </i>
    </button>
    {{ month }}
    <button *ngIf="isNavigateNextEnabled" (click)="onClickNavigate_next()" style="position: absolute; top: -0.5em; right: 0;"><!--TODO Update style and move to .scss-->
      <i class="material-icons">
        navigate_next
      </i>
    </button>
  </p>
</div>
