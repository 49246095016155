import { Component, OnInit, HostListener, Input } from "@angular/core";
import { ServiceBusService } from "../../system/service-bus.service";
import { Chanels } from "../../chanels";
import { Router, NavigationStart, RouterEvent } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { MatSidenav } from "@angular/material/sidenav";
import { SiteSelectComponent } from "../../site/site-select/site-select.component";
import { Subscription } from "rxjs";
import { EntityService } from "../../entity/entity.service";
import { take } from "rxjs/operators";
import { Entity } from "../../entity/entity";

@Component({
  selector: "app-menu",
  templateUrl: "./menu.component.html",
  styleUrls: ["./menu.component.scss"],
})
export class MenuComponent implements OnInit {
  public siteId: any;
  public hasMultipleSites: boolean = false;
  public hasOpenCloseEntity = false;

  public widthLimitOverlay = 992;
  public isOverlayMenu = false;

  private routerSubscription: Subscription;
  private busSubscription: Subscription;

  @Input()
  public sidenav: MatSidenav;

  constructor(
    private router: Router,
    private bus: ServiceBusService,
    private entityService: EntityService,
    private dialog: MatDialog
  ) {
    this.routerSubscription = router.events.subscribe((event: RouterEvent) => {
      if (event instanceof NavigationStart) {
        this.closeSidenavIfOverlay();
      }
    });
  }

  ngOnInit() {
    this.bus.subscribe(Chanels.USER_CHANEL, (user) => {
      if (user == null) return;
      this.siteId = user.siteId;

      // Check user sites
      var siteIds = user.adalUser.sites;
      this.hasMultipleSites = siteIds.length > 1;

      this.updateEntitySpecificMenuItems();

      this.updateOverlayMenu(window.innerWidth);
    });
  }

  ngOnDestroy() {
    this.routerSubscription.unsubscribe();
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.updateOverlayMenu(event.target.innerWidth);
  }

  private updateEntitySpecificMenuItems() {
    this.entityService
      .getEntitiesForSiteNoSignals(this.siteId)
      .pipe(take(1))
      .subscribe((entities: Entity[]) => {
        this.hasOpenCloseEntity = entities.some((e) => e.isOpenClose());
      });
  }

  private updateOverlayMenu(width: number) {
    this.isOverlayMenu = width <= this.widthLimitOverlay;
    this.sidenav.mode = this.isOverlayMenu ? "push" : "side";

    this.closeSidenavIfOverlay();
  }

  private closeSidenavIfOverlay() {
    if (this.isOverlayMenu) {
      this.sidenav.close();
    }
  }

  routing(route: string) {
    this.router.navigate([route]);
  }

  isRouteActive(route: string) {
    return this.router.url.startsWith(route);
  }

  clickSelectSite() {
    this.closeSidenavIfOverlay();
    this.dialog.open(SiteSelectComponent, { panelClass: ["full-screen"] });
  }
}
