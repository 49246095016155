import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { Alarm, AlarmType } from "../../alarm";
import { Entity } from "../../entity/entity";
import { SiteEntities } from "../../entity/siteentities";
import { SurveillanceReportsContext } from "../SurveillanceReportsReactWrapper";

export type EntityAlarmDeviation = {
  entity: Entity;
  timestamp: moment.Moment;
  measurement: any;
  currentValue: number;
  isHandled: boolean;
}

function getEntityAlarmDeviation(alarmService, entity, tempReport) {
  return new Promise((res, rej) => {
    if(entity.type !== "TermoObject") {
      res(null);
      return;
    }
    alarmService.getAlarms(entity.id, null).subscribe(
      (alarms: Alarm[]) => {
        const regAlarm = alarms.find(a => a.type === AlarmType.RegisterAlarm);
        // TODO: think about closed unhandled alarms
        const closeAlarm = alarms.find(a => a.type === AlarmType.CloseAlarm);
        const missingEventsAlarm = alarms.find(a => 
          a.type === AlarmType.MissingEventsAlarm && a.createdOn === regAlarm?.createdOn);
        const isAlarmState = regAlarm !== null && missingEventsAlarm !== null;
        const handledAlarms = isAlarmState
          ? alarms.filter(
              (a) =>
                a.alarmId == regAlarm.alarmId &&
                a.alarmType == AlarmType.HandledAlarm
            )
          : [];
        const isHandled = handledAlarms.length > 0;

        if (isAlarmState && !isHandled) {
          res({
            entity: entity,
            timestamp: regAlarm.createdOn,
            measurement: regAlarm.measurement,
            currentValue: tempReport.measurements[entity.id]?.value,
            isHandled,
          });
        } else {
          res(null);
        }
      },
      (err) => {res(null)}
    );
  });
}

export function useEntityDeviations(siteId: string) {
  const { tempReportService, entityService, alarmService } = useContext(SurveillanceReportsContext);
  
  const [entityDeviations, setEntityDeviations] = useState<EntityAlarmDeviation[]>([]);
  const [tempReport, setTempReport] = useState(null);

  useEffect(() => {
    if(!!tempReport) return;
    tempReportService.getSnapshot(siteId).subscribe(
      setTempReport,
      (err) => console.log(err)
    );
  }, []);

  const measurements = tempReport?.getMeasurements();

  useEffect(() => {
    if(!measurements?.length || !!entityDeviations?.length || !tempReport) return;
    entityService.getSiteEntities(siteId).subscribe(
      async (siteEntities: SiteEntities) => {
        const entities = siteEntities.getEntities().filter((e) => !e.disabled);
        
        const entityDevs = [];
        for (const entity of entities) {
          const alarmDev = await getEntityAlarmDeviation(alarmService, entity, tempReport);
          if(!alarmDev) continue;
          entityDevs.push(alarmDev);
        }
        setEntityDeviations(entityDevs);
      },
      (err) => { console.log(err); }
    );
  }, [measurements, tempReport]);

  return { tempReport, entityDeviations };
}