import React, { FC } from "react";
import { makeStyles } from "@mui/styles";
import { Slider, Theme } from "@mui/material";

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    width: 300,
  },
  mark: {
    height: "20px !important",
    width: "2px !important",
    color: "black",
  },
  rail: {
    height: "9px !important",
    opacity: "1 !important",
  },
}));

type Props = {
  value: number|number[];
  onChange: (v: number|number[]) => any;
  onChangeCommitted: (v: number|number[]) => any;
  min: number;
  max: number;
  isInverted: boolean;
  percentages: {
    green: number;
    yellow: number;
    red: number;
  };
  marks?: { label: string; value: number }[];
  step?: number;
  valueLabelFormatter?: (v: number) => string;
  disabled?: boolean;
};

export const ColoredSlider: FC<Props> = ({
  value,
  onChange,
  onChangeCommitted,
  min,
  max,
  isInverted,
  percentages,
  marks = [],
  step = 1,
  valueLabelFormatter = (v) => `${v}`,
  disabled,
}) => {
  const colors = isInverted
    ? ["red", "yellow", "green"]
    : ["green", "yellow", "red"];
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Slider
        classes={{
          rail: classes.rail,
          mark: classes.mark,
        }}
        track={false}
        value={value}
        valueLabelDisplay="auto"
        valueLabelFormat={valueLabelFormatter}
        step={step}
        sx={{
          "& .MuiSlider-rail": {
            background: `linear-gradient(to right, ${colors[0]} ${
              percentages[colors[0]]
            }%, 0%, ${colors[1]} ${
              percentages[colors[0]] + percentages[colors[1]]
            }%, 0%, ${colors[2]});`,
          },
        }}
        marks={marks}
        min={min}
        max={max}
        onChange={(_, v: number|number[]) => onChange(v)}
        onChangeCommitted={(_, v: number|number[]) => onChangeCommitted(v)}
        disabled={disabled}
      />
    </div>
  );
};
