import axios from "axios";
import useAxios from "axios-hooks";
import { environment } from "../../../environments/environment";

const ENTITY_API_URL = environment.dependencies.entity_api.url;

export function useEntityTypes() {
  return useAxios(`${ENTITY_API_URL}/api/termoobjecttypes`);
}

export function fetchEntityType(id: string) {
  return axios.get(`${ENTITY_API_URL}/api/termoobjecttypes/${id}`);
}

export function createEntityType(entityType: any) {
  return axios.post(`${ENTITY_API_URL}/api/termoobjecttypes`, entityType);
}

export function updateEntityType(entityTypeId: string, entityType: any) {
  console.log(entityType);
  return axios.put(
    `${ENTITY_API_URL}/api/termoobjecttypes/${entityTypeId}`,
    entityType
  );
}
