import { Component, OnInit, NgZone } from "@angular/core";
import { ServiceBusService } from "../../system/service-bus.service";
import { Observable } from "rxjs";
import { Chanels } from "../../chanels";
import { MsalService } from "@azure/msal-angular";
import { SiteSelectComponent } from "../../site/site-select/site-select.component";
import { MatDialog } from "@angular/material/dialog";
import { UserSiteService } from "../../site/user-site.service";
import { SiteService } from "../../entity/site.service";
import { environment } from "../../../environments/environment";

@Component({
  selector: "app-unauth",
  templateUrl: "./unauthpage.component.html",
  styleUrls: ["./unauthpage.component.scss"],
})
export class UnauthpageComponent implements OnInit {
  user$: Observable<any>;
  // hasMultipleSites: boolean = false;
  public siteName: String;
  public version = environment.version;

  constructor(
    private bus: ServiceBusService,
    private msalService: MsalService,
    private sitesService: SiteService
  ) {}

  ngOnInit() {
    this.user$ = this.bus.chanelObservable(Chanels.USER_CHANEL);
    this.user$.subscribe((user) => {
      console.log("User: ", user);

      // Get site name
      this.sitesService.getSite(user.siteId).subscribe((site) => {
        console.log("Site: ", site);
        this.siteName = site.name;
      });
    });
  }
  logout() {
    this.msalService.logout();
  }
  login() {
    this.msalService.loginPopup();
  }
  updateCache() {
    console.log("Update version");
    window.location.reload();
  }
  clear() {
    localStorage.clear();
    window.location.href = "/";
  }
}
