<div class="dashboard" *ngIf="siteId">
  <div style="border: solid rgba(180, 180, 180, 0.7) 1px; border-radius: 20px; padding: 20px 20px; margin-bottom: 40px;">
    <h2 style="margin-bottom: 1rem;">{{ "dashboard.routineDeviationsTitle" | i18next }}</h2>
    <div style="border: solid rgb(180, 180, 180, 0.5) 1px; border-radius: 10px; padding: 10px;">
      <div *ngIf="entityCount" style="color: #999; margin-bottom: 10px;">
        <div *ngIf="!showWeeklyReports">
          {{ "Entity count" | i18next }}: {{ entityCount }}
          <a class="button" style="margin-left: 10px;" (click)="onShowAllEntities()">
            {{ "Show all" | i18next }}
          </a>
        </div>
        <div *ngIf="showWeeklyReports">
          <a class="button" style="margin-left: 10px; display: flex; align-items: center; width: 70px;" (click)="onHandleWeeklyReports(false)">
            <i class="material-icons"> arrow_back </i> {{ "Back" | i18next }}
          </a>
        </div>
      </div>
      
      <temp-widget
        *ngIf="!showWeeklyReports"
        [isPeriodicReport]="true"
        [deviationsCount]="periodicReportDeviationsCount"
        [handledDeviationsCount]="handledPeriodicReportDeviationsCount"
        [timestamp]="periodicReportTimestamp"
        (onHandleDeviation)="onHandlePeriodicReport()"
        [isLoading]="isLoadingPeriodicReport"
        [tooltipText]="'dashboard.tooltips.dailyDeviations' | i18next"
      ></temp-widget>

      <div style="margin-bottom: 20px;"></div>

      <!-- Aggregated Weekly deviations  -->
      <temp-widget
        *ngIf="!showWeeklyReports"
        [isPeriodicReport]="true"
        [isWeeklyReport]="true"
        [deviationsCount]="getWeeklyReportDeviationsCount()"
        [handledDeviationsCount]="getWeeklyReportHandledDeviationsCount()"
        [timestamp]="periodicReportTimestamp"
        (onHandleDeviation)="onHandleWeeklyReports()"
        [isLoading]="isLoadingPeriodicReports"
        [showMargin]="false"
        [tooltipText]="'dashboard.tooltips.weeklyDeviations' | i18next"
      ></temp-widget>

      <!-- Weekly deviations  -->
      <div *ngIf="showWeeklyReports">
        <temp-widget
          *ngFor="let report of periodicReportForLastSevenDays"
          [isPeriodicReport]="true"
          [isWeeklyReport]="false"
          [showTitle]="false"
          [deviationsCount]="report.deviationsCount"
          [handledDeviationsCount]="report.handledDeviationsCount"
          [timestamp]="report.timestamp"
          (onHandleDeviation)="onHandlePeriodicReport(report.id)"
          [isLoading]="isLoadingPeriodicReports"
        ></temp-widget>
      </div>
    </div>
  </div>

  <div [hidden]="showWeeklyReports" style="border: solid rgba(180, 180, 180, 0.7) 1px; border-radius: 20px; padding: 20px 20px;">
    <div style="width: 100%; display: flex; justify-content: space-between; align-items: center; margin-bottom: 1rem;">
      <h2 style="margin-bottom: 1rem;">{{ "dashboard.routineStatusTitle" | i18next }}</h2>

      <div style="color: #D97C54;">
        <i id="tippy-target" class="material-icons"> info </i>
      </div>
    </div>

    <div style="display: flex; justify-content: space-between; overflow: hidden; flex-wrap: wrap;">
      <div style="display: flex; justify-content: center; align-items: center; width: 50%; min-width: 250px;">
        <div style="width: 450px; height: 640px;">
          <h3 style="font-weight: 500; text-wrap: nowrap;">{{ "dashboard.routineChartsTitle" | i18next }} ({{ "Week" | i18next }}; {{getWeekNumber()}})</h3>
          <mat-spinner *ngIf="chartsLoading['weekly-deviations-chart']"></mat-spinner>
          <canvas id="weekly-deviations-chart"></canvas>
        </div>
      </div>
      <div style="display: flex; justify-content: center; align-items: center; width: 50%; min-width: 250px;">
        <div style="width: 450px; height: 640px;">
          <h3 style="font-weight: 500; text-wrap: nowrap;">{{ "dashboard.routineChartsTitle" | i18next }} ({{ "Month" | i18next }}; {{getMonthName()}})</h3>
          <mat-spinner *ngIf="chartsLoading['monthly-deviations-chart']"></mat-spinner>
          <canvas id="monthly-deviations-chart"></canvas>
        </div>
      </div>
    </div>

    <div style="width: 100%; display: flex; justify-content: end; align-items: center; margin-top: 1rem;">
      <a class="button" style="margin-left: 10px;" href="/checklist/dashboard/calendar">
        {{ "dashboard.calendarLinkTitle" | i18next }}
      </a>
    </div>
  </div>
</div>