import React, { FC, useEffect, useState } from "react";
import SelectOption from "./SelectOption";
import { Paper } from "@mui/material";
import { DateTime } from "luxon";
import { useFrequencyItems, useRelativeTimeItems } from "./hooks";


type Props = {
  prefix?: any;
  suffix?: any;
  value?: string;
  onChange?: (cronStatement: string) => void;
}

const DefaultDiffValues = {
  min: '15',
  hour: '1',
  day: '1',
}

const RelativeCronDefinitionField: FC<Props> = ({ prefix, suffix, value, onChange }) => {
  
  const [diffValue, setDiffValue] = useState(DefaultDiffValues.min);
  const [unit, setUnit] = useState('min');
  
  const { minuteItems, hourItems, dayItems } = useRelativeTimeItems();
  const { frequencyUnitItems } = useFrequencyItems(diffValue === '1', true);

  useEffect(() => {
    // String starts with one or two digits followed by a dot, eg. 1. or 12.
    const includesDay = value.match(/^[0-9]{1,2}\..*/g);
    const zeroFilledValues = !includesDay ? 
      value.split(':').map(v => v.length < 2 ? `0${v}` : v).join(':') : value;
    const time = DateTime.fromFormat(zeroFilledValues, includesDay ? 'd.H:mm:ss' : 'H:mm:ss');

    const { day, hour, minute } = time;

    if(includesDay) {
      setUnit('day');
      setDiffValue(day.toString());
    } else if(hour) {
      setUnit('hour');
      setDiffValue(hour.toString());
    } else if(minute) {
      setUnit('min');
      setDiffValue(minute.toString());
    }
  }, []);

  // Emit change
  useEffect(() => {
    switch (unit) {
      case 'min': onChange(`00:${diffValue}:00`); break;
      case 'hour': onChange(`${diffValue}:00:00`); break;
      case 'day': onChange(`${diffValue}.00:00:00`); break;
      default:
        break;
    }
  }, [diffValue, unit]);

  return (
    <Paper style={{padding: '1em', margin: '0.5em'}}>
      {prefix}
      <SelectOption
        value={diffValue}
        onChange={setDiffValue}
        items={unit === 'min' ? minuteItems : (unit === 'hour' ? hourItems : dayItems)}
      />
      <span> </span>
      <SelectOption
        value={unit}
        onChange={(v: string) => { 
          setUnit(v);
          // Edge case for invalid select items switch
          setDiffValue(DefaultDiffValues[v]);
        } }
        items={frequencyUnitItems}
      />
      <span> </span>
      {suffix}
    </Paper>
  )
}

export default RelativeCronDefinitionField;
