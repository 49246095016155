import React, { FC, useState } from "react";
import { getBoundaries, getRulePercentages, getBoundaryMarks } from "./tempRuleUtils";
import { ColoredSlider } from "./ColoredSlider";

type Props = {
  value: number;
  onChange: (v: number) => any;
  rules: any;
  disabled?: boolean;
};

export const TemperatureSlider: FC<Props> = ({ value, onChange, rules,disabled }) => {

  const [localValue, setLocalValue] = useState(value);

  const { min, max, isInverted } = getBoundaries(rules);
  const { greenPercentage, yellowPercentage, redPercentage } =
    getRulePercentages(rules);
  const colors = isInverted
    ? ["red", "yellow", "green"]
    : ["green", "yellow", "red"];
  const percentages = {
    green: greenPercentage,
    yellow: yellowPercentage,
    red: redPercentage,
  };

  const boundaryMarks = getBoundaryMarks(rules);

  return (
    <ColoredSlider
      value={localValue}
      onChange={(v: number) => setLocalValue(v)}
      onChangeCommitted={onChange}
      min={min}
      max={max}
      isInverted={isInverted}
      percentages={percentages}
      marks={boundaryMarks.map(v => ({
        label: `${v}°C`,
        value: v
      }))}
      valueLabelFormatter={(v) => `${v}°C`}
      disabled={disabled}
    />
  );
};
