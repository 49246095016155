<div class="date-filter">
  <mat-form-field class="filter-list__date__from report-history-labels">
    <input placeholder="FROM" matInput [matDatepicker]="myDatepickerFrom">
    <mat-datepicker-toggle matSuffix [for]="myDatepickerFrom"></mat-datepicker-toggle>
    <mat-datepicker #myDatepickerFrom></mat-datepicker>
  </mat-form-field>
  <mat-form-field class="filter-list__date__to report-history-labels">
    <input placeholder="TO" matInput [matDatepicker]="myDatepickerTo">
    <mat-datepicker-toggle matSuffix [for]="myDatepickerTo"></mat-datepicker-toggle>
    <mat-datepicker #myDatepickerTo></mat-datepicker>
  </mat-form-field>

  <button mat-button class="confirm-date" (click)="confirmDate($event)">
    Bekrefte
  </button>
</div>
