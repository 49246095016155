<div class="_content">
  <span class="go-back">
    <a class="content__heading__go-back" [routerLink]="['/']" routerLinkActive="router-link-active">
      <i class="material-icons">home</i>
    </a>
  </span>
</div>
<div class="sense">
  <img class="logo" src="assets/img/tmesh-logo.png" alt="logo" />
  <div class="sense__title">
    <img src="assets/icons/icn_activity.png" alt="activity-icon" />
    <h1>{{ "Latest report" | i18next }}</h1>
    <h2>{{ reportDate }}</h2>
    <div class="center" *ngIf="!isStartReportEnabled">
      <mat-spinner *ngIf="!isStartReportEnabled"></mat-spinner>
    </div>
  </div>
</div>
<div>
  <!-- <a class="start" routerLink="{{ routerLinkValue }}"> -->
  <a class="start" (click)="startReporting()" *ngIf="isStartReportEnabled">
    <div class="start__name">Start</div>
    <div class="start__go">
      <img src="assets/icons/icn_arrow-white.png" alt="right-arrow" />
    </div>
  </a>
</div>
