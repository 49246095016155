import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { TempReport } from "../tempreport";
import { TempreportService } from "../tempreport.service";
import { Guid } from "../../system/guid";
import * as moment from "moment";
import { DataService } from "../data.service";
import { Entity } from "../../entity/entity";

@Component({
  selector: "temperature-confirm",
  templateUrl: "./temperature-confirm.component.html",
  styleUrls: ["./temperature-confirm.component.scss"],
})
export class TemperatureConfirmComponent implements OnInit {
  measurements: any;
  siteEntities: Array<Entity>;
  entityValues: Array<any> = [];
  tempReport: TempReport;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private newTempReport: TempreportService,
    private data: DataService
  ) {
    this.tempReport = this.data.getTempReport();
  }

  routing(route: string) {
    this.router.navigate([route]);
  }

  ngOnInit() {
    this.buildEntityWithValue();
  }

  buildEntityWithValue() {
    this.entityValues = this.siteEntities.map((x: any): any => {
      var m = this.tempReport.getMeasurement(x.id);
      const rules = x.obj.rules.temperature;
      return {
        entityName: x.name,
        entityValue: m.value,
        isManual: m.value == undefined,
        isDeviation:
          x.obj && rules && (rules.min > m.value || rules.max < m.value),
      };
    });
  }

  registerTempReport() {
    this.newTempReport.updateTempReport(this.tempReport).subscribe(
      (response) => {
        this.router.navigate(["deviations"]);
        this.data.setTempReport({});
      },
      (err) => {
        console.log("Det oppsto en feil ved registrering av rapporten");
      }
    );
  }
}
