import * as React from "react";
import {
  SingleChecklistContext,
  useChecklistReducer,
} from "../services/reducer";
import ChecklistRoutes from "./ChecklistRoutes";

const ChecklistWrapper = () => {
  const singleChecklistContext = useChecklistReducer();

  return (
    <SingleChecklistContext.Provider
      value={{
        state: singleChecklistContext[0],
        dispatch: singleChecklistContext[1],
      }}
    >
      <ChecklistRoutes />
    </SingleChecklistContext.Provider>
  );
};

export default ChecklistWrapper;
