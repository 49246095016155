import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormControl,
  FormBuilder,
  FormArray,
  Validators,
} from "@angular/forms";
import { TempReport } from "../../reports/tempreport";
import { RegisterSignal } from "../../signals/registersignal";
import { Entity } from "../../entity/entity";
import { EntityService } from "../../entity/entity.service";
import { DataService } from "../../reports/data.service";
import { Guid } from "../../system/guid";
import { SiteEntities } from "../../entity/siteentities";
import { Router, ActivatedRoute } from "@angular/router";
import { Measurement } from "../../measurements/measurement";
import { ServiceBusService } from "../../system/service-bus.service";
import { Chanels } from "../../chanels";
import {
  ReportDeviationsType,
  GetReportDeviationsFromQueryParam,
  RouteWithReportType,
} from "../../reports/report-deviations-type";
import { Deviation } from "../../reports/deviation";
import { AlarmsService } from "../../alarms.service";
import { Alarm, isActiveAlarm } from "../../alarm";

@Component({
  selector: "app-temp-form-register",
  templateUrl: "./temp-form-register.component.html",
  styleUrls: ["./temp-form-register.component.scss"],
})
export class TempFormRegisterComponent implements OnInit {
  tempreportForm: FormGroup;
  tempReport: TempReport;
  public signal: RegisterSignal;
  // siteEntities: Array<Entity>;
  siteEntities: SiteEntities;
  private reportType = ReportDeviationsType.HandlePeriodicDeviations;

  siteId: Guid;
  showLoader: boolean;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private entities: EntityService,
    private bus: ServiceBusService,
    private data: DataService,
    private alarmsService: AlarmsService
  ) {
    this.route.queryParams.subscribe((params) => {
      this.reportType = GetReportDeviationsFromQueryParam(params["type"]);
      console.log(
        "--- temp-form-register.component - report type: " +
          this.reportType +
          " ---"
      );
    });

    this.showLoader = true;
    this.tempReport = this.getTempReport();
    this.createFormBuilder(this.tempReport);
    this.getSiteEntities();
  }

  ngOnInit() {}

  private createFormBuilder(tempreport: TempReport) {
    console
      .log
      // "--- temp-form-register.component - createFormBuilder - report type: " +
      //   this.reportType + ", temp report:", tempreport
      ();

    if (this.reportType == ReportDeviationsType.HandleCurrentDeviations) {
      // Check alarm state and only report on measurements with active alarm
      let allMeasurements = tempreport.getMeasurements();
      let allDeviations = tempreport.getDeviations();
      // console.log("Find alarms for measurements: ", allMeasurements);

      // Find entities to check alarm state for
      var entityIds: string[] = [];
      allMeasurements.forEach((measurement) => {
        if (entityIds.indexOf(measurement.entityId) < 0) {
          entityIds.push(measurement.entityId);
        }
      });

      // Check alarm state for entities
      var measurements: Measurement[] = [];
      var deviations: Deviation[] = [];

      this.alarmsService.getAlarmsForEntities(entityIds).subscribe(
        (entitiesAlarmsMap: Map<string, Array<Alarm>>) => {
          // console.log("Alarms found, count: " + entitiesAlarmsMap.size + ". entitiesAlarmsMap:", entitiesAlarmsMap);

          entitiesAlarmsMap.forEach((alarms, entityId, map) => {
            let isAlarm = isActiveAlarm(alarms);
            // console.log("Check if active alarm for entity: " + entityId + " - active: " + isAlarm + ". Alarms: ", alarms);
            if (!isAlarm) {
              return;
            }

            // Add measurement and deviation for entity
            let entityMeasurements = allMeasurements.filter(
              (m) => m.entityId == entityId
            );
            let entityDeviations = allDeviations.filter(
              (d) => d.entityId == entityId
            );

            console.log(
              "Add measurements/deviations for alarm entity with id: " +
                entityId +
                ". Found measurements: ",
              entityMeasurements
            );

            measurements = [...measurements, ...entityMeasurements];
            deviations = [...deviations, ...entityDeviations];
          });
          console.log("Build form for measurements: ", measurements);
          this.buildForm(tempreport, measurements, deviations);
        },
        (err) => {
          if (err.response.status == 404) {
            // No alarms found for entity
            console.log("No alarms found for entities!", err);
            //TODO
          } else {
            console.log("Error gettings alarms for entities!", err);
            //TODO
          }
        }
      );
    } else {
      this.buildForm(
        tempreport,
        tempreport.getMeasurements(),
        tempreport.getDeviations()
      );
    }
  }
  private buildForm(
    tempreport: TempReport,
    measurements: Measurement[],
    deviations: Deviation[]
  ) {
    this.tempreportForm = this.fb.group({
      id: tempreport.id,
      occuredTime: tempreport.getOccuredTime(),
      tenantId: tempreport.getTenantId(),
      createdOn: tempreport.getCreatedOn(),
      createdBy: tempreport.getCreatedBy(),
      reportType: this.reportType,
      measurements: this.fb.array(
        measurements.map((x: Measurement) =>
          this.fb.group({
            ...x,
            value: [x.value, Validators.required],
            isManual: x.value == null,
          })
        )
      ),
      deviation: this.fb.array(
        deviations.map((x) =>
          this.fb.group({
            id: x.id,
            entityId: x.entityId,
            occuredTime: x.occuredTime,
            state: x.state,
            action: [x.action, Validators.required],
          })
        )
      ),
      referenceId: tempreport.getReferenceId(),
      status: tempreport.getStatus(),
      state: tempreport.getState(),
      modifiedBy: tempreport.getModifiedBy(),
      modifiedOn: tempreport.getModifiedOn(),
    });

    this.data.setTempReportForm(this.tempreportForm);
    RouteWithReportType("/deviationProcedure", this.reportType, this.router);
  }

  getEntityName(entityid: string) {
    if (!this.siteEntities) return "";
    return this.siteEntities.getEntityName(entityid);
  }
  onSubmit(form: FormGroup) {
    var measurements = form.get("measurements") as FormArray;
    Object.keys(measurements.controls).forEach((key) => {
      var element = measurements.controls[key];

      element.controls["occuredTime"].patchValue(new Date().toISOString(), {
        onlySelf: true,
        emitEvent: false,
      });
    });

    this.data.setTempReportForm(form);
    RouteWithReportType("/deviationProcedure", this.reportType, this.router);
  }
  private getTempReport(): TempReport {
    return this.data.getTempReport();
  }
  get measurements(): FormArray {
    return this.tempreportForm.get("measurements") as FormArray;
  }
  getSiteEntities() {
    this.bus.subscribe(Chanels.USER_CHANEL, (user) => {
      if (user == null) return;
      this.entities
        .getSiteEntities(user.siteId)
        .subscribe((data: SiteEntities) => {
          this.siteEntities = data;
          this.showLoader = false;
          this.data.setSiteEntities(this.siteEntities);
        });
    });
  }
}
