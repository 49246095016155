import * as React from "react";
import { Box, Theme, Typography } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import { Icon } from "@iconify/react";
import thermometerIcon from "@iconify/icons-mdi/thermometer";
import { useTranslation } from "react-i18next";

import i18n from "../../react-components/i18n";
import moment from "moment";
import "moment/locale/nb";
import { CurrentTempContext } from "../CurrentTempReactWrapper";
import { take } from "rxjs";

//set locale. Find better way for this
if (i18n.language === "no") {
  moment.locale("nb");
} else {
  moment.locale("en");
}

type Props = {
  entityId: string
};

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    icon: {
      fontSize: 48,
      marginRight: theme.spacing(1),
      color: theme.palette.primary.main,
    },
    box: {
      flexBasis: 0,
      flexGrow: 1,
      "&:not(:last-child)": {
        borderRight: `1px solid ${theme.palette.divider}`,
        marginRight: theme.spacing(2),
      },
    },
    timestamp: {
      marginLeft: theme.spacing(4),
    },
  });

const useStyles = makeStyles(styles);

const CurrentTemp = ({ entityId }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [entity, setEntity] = React.useState<any>();

  const { entityService, user } = React.useContext(CurrentTempContext);

  React.useEffect(() => {
    if (!user) return;
    entityService
      .getEntitiesForSiteWithAllSignals(user.siteId)
      .pipe(take(1))
      .subscribe(
        {
          next: (res) => {
            setEntity(res.find(x => x.id === entityId));
          },
          error: (error) => {
            console.log(error);
          },
          complete: () => {
          }
        }
      )
  }, [user]);

  if (!entity) {
    return <></>
  }

  const temp = entity.signal && entity.signal.filter((v) => v.unit === "TempC");
  const rules = entity.obj?.rules;

  return (
    <Box display="flex" justifyContent="space-between" className={classes.root}>
      {rules.temperature && (
        <Box className={classes.box}>
          <Box display="flex" alignItems="center">
            <Icon
              icon={thermometerIcon}
              className={classes.icon}
              fr={undefined}
            />
            {temp && temp.length > 0 ? (
              <Typography variant="h3">{temp[0].value} &deg;C</Typography>
            ) : (
              <Typography variant="h3">{t("Entity.Manual")}</Typography>
            )}

          </Box>
        </Box>
      )}
    </Box>
  );
};

export default CurrentTemp;
