import {
  Menu,
  MenuItem,
  Tooltip,
  Button,
  FormControlLabel,
  Switch,
  Divider,
  Dialog,
  DialogContent,
} from "@mui/material";

import SettingsIcon from '@mui/icons-material/Settings';

import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import DialogTitle from "../../../../react-components/DialogTitle";

type Props = {
  isAdd: boolean;
  isEdit: boolean;
  isSiteSpecific: boolean;
  isPointEnabled: boolean;
  isPointEnabledForSite: boolean;
  onEditClick: () => void;
  onAssignEntityClick: () => void;
  onMoveClick: (forSite: boolean) => void;
  onAddExternalLinkClick: () => void;
  onSetBreakageClick: () => void;
  onAddLabelClicked: () => void;
  onAttachMediaClick: (forSite: boolean) => void;
  onEditSiteClick: () => void;
  onEditSiteDetailsClick: () => void;
  onPointDisabled: () => void;
  onPointDisabledSite: () => void;
  onPointDeleted: () => void;
  onPointDeletedSite: () => void;
};


const PointSettingsMenu: FC<Props> = ({
  isAdd,
  isEdit,
  isSiteSpecific,
  isPointEnabled,
  isPointEnabledForSite,
  onEditClick,
  onAssignEntityClick,
  onMoveClick,
  onAddExternalLinkClick,
  onSetBreakageClick,
  onAttachMediaClick,
  onEditSiteClick,
  onEditSiteDetailsClick,
  onPointDisabled,
  onPointDisabledSite,
  onAddLabelClicked,
  onPointDeleted,
  onPointDeletedSite,
}) => {

  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [subAnchorEl, setSubAnchorEl] = React.useState<null | HTMLElement>(null);

  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = React.useState(false);
  const [isDeleteSiteConfirmationOpen, setIsDeleteSiteConfirmationOpen] = React.useState(false);

  const Template = () => <>
    <MenuItem
      onClick={() => {
        onEditClick();
        setAnchorEl(null);
      }}
    >
      {/* Template */}
      {t("editChecklist.editPoint.settings.edit")}
    </MenuItem>
    {!isAdd && <MenuItem onClick={() => {
      onMoveClick(false);
      setAnchorEl(null);
    }}>
      {/* Template */}
      {t("editChecklist.editPoint.settings.move")}
    </MenuItem>}
    {!isAdd && <MenuItem onClick={() => {
      onAddExternalLinkClick();
      setAnchorEl(null);
    }}>
      {/* Template */}
      {t("editChecklist.editPoint.settings.setExternalLink")}
    </MenuItem>}
    <MenuItem onClick={() => {
      onSetBreakageClick();
      setAnchorEl(null);
    }}>
      {/* Template */}
      {t("editChecklist.editPoint.settings.setBreakage")}
    </MenuItem>

    <MenuItem onClick={() => {
      onAddLabelClicked();
      setAnchorEl(null);
    }}>
      {/* Template */}
      {t("editChecklist.editPoint.settings.addLabel")}
    </MenuItem>
    <MenuItem onClick={() => {
      onAttachMediaClick(false);
      setAnchorEl(null);
    }}>
      {/* Template */}
      {t("editChecklist.editPoint.settings.attachMedia")}
    </MenuItem>
    {!isAdd && <MenuItem onClick={() => {
      onPointDisabled();
      setAnchorEl(null);
    }}>
      {/* Template */}
      <FormControlLabel
        control={
          <Switch
            checked={isPointEnabled}
            onChange={() => { }}
            name="disabled"
            color="primary"
          />
        }
        label={t("editChecklist.editPoint.settings.enabled") as string}
      />
    </MenuItem>}
    {!isAdd && (
      <MenuItem onClick={() => {
        setIsDeleteConfirmationOpen(true);
        setAnchorEl(null);
      }}>
        {/* Template */}
        {t("editChecklist.editPoint.settings.deleteLabel")}
      </MenuItem>
    )}
  </>;

  return (
    <>
      <Tooltip title={t("editChecklist.editPoint.settings.title")}>
        <Button onClick={(e: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(e.currentTarget)}
        >
          <SettingsIcon />
        </Button>
      </Tooltip>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {!isAdd && !isEdit && !isSiteSpecific && <MenuItem onClick={() => {
          onEditSiteClick();
          setAnchorEl(null);
          setSubAnchorEl(null);
        }}>
          {t("editChecklist.editPoint.settings.edit")}
        </MenuItem>}
        {!isSiteSpecific && <MenuItem onClick={() => {
          onAttachMediaClick(true);
          setAnchorEl(null);
        }}>
          {/* Site */}
          {t("editChecklist.editPoint.settings.attachMedia")}
        </MenuItem>}
        {!isSiteSpecific && <MenuItem onClick={() => {
          onMoveClick(true);
          setAnchorEl(null);
        }}>
          {/* Site */}
          {t("editChecklist.editPoint.settings.move")}
        </MenuItem>}
        {isSiteSpecific && <Template />}
        <MenuItem onClick={() => {
          onEditSiteDetailsClick();
          setAnchorEl(null);
          setSubAnchorEl(null);
        }}>
          {t("editChecklist.editPoint.settings.editDetails")}
        </MenuItem>
        <MenuItem onClick={() => {
          onAssignEntityClick();
          setAnchorEl(null);
        }}>
          {/* Site */}
          {t("editChecklist.editPoint.settings.assignEntity")}
        </MenuItem>
        {!isAdd && !isSiteSpecific && <MenuItem onClick={() => {
          onPointDisabledSite();
          setAnchorEl(null);
        }}>
          <FormControlLabel
            control={
              <Switch
                checked={isPointEnabledForSite}
                onChange={() => { }}
                name="disabled"
                color="primary"
              />
            }
            label={t("Enabled") as string}
          />
        </MenuItem>}
        {!isAdd && !isSiteSpecific && (
          <MenuItem onClick={() => {
            setIsDeleteSiteConfirmationOpen(true);
            setAnchorEl(null);
          }}>
            {/* Template */}
            {t("editChecklist.editPoint.settings.deleteLabel")}
          </MenuItem>
        )}
        {!isAdd && !isSiteSpecific && <Divider />}
        {!isSiteSpecific && <MenuItem
          onClick={e => setSubAnchorEl(e.currentTarget)}
        >
          {t("Edit Template")}
        </MenuItem>}
        {!isSiteSpecific && <MenuItem
          onClick={e => {
            onAddLabelClicked();
          }}
        >
          Add labels
        </MenuItem>}
        <Menu
          id="simple-sub-menu"
          anchorEl={subAnchorEl}
          keepMounted
          open={Boolean(subAnchorEl)}
          onClose={() => setSubAnchorEl(null)}
        >
          <Template />
        </Menu>
        {!isAdd && !isSiteSpecific && <Divider />}
      </Menu>

      {/* TEMPLATE */}
      <Dialog open={isDeleteConfirmationOpen} onClose={() => {
        setIsDeleteConfirmationOpen(false);
      }}>
        <DialogTitle onClose={() => {
          setIsDeleteConfirmationOpen(false);
        }}>
          {t("editChecklist.editPoint.settings.deleteConfirmation")}
        </DialogTitle>
        <DialogContent>
          <Button onClick={() => {
            onPointDeleted();
            setIsDeleteConfirmationOpen(false);
          }}>
            {t("Yes")}
          </Button>
          <Button onClick={() => {
            setIsDeleteConfirmationOpen(false);
          }}>
            {t("No")}
          </Button>
        </DialogContent>
      </Dialog>

      {/* SITE */}
      <Dialog open={isDeleteSiteConfirmationOpen} onClose={() => {
        setIsDeleteSiteConfirmationOpen(false);
      }}>
        <DialogTitle onClose={() => {
          setIsDeleteSiteConfirmationOpen(false);
        }}>
          {isSiteSpecific ? t("editChecklist.editPoint.settings.deleteConfirmation") : t("editChecklist.editPoint.settings.deleteSiteConfirmation")}
        </DialogTitle>
        <DialogContent>
          <Button onClick={() => {
            onPointDeletedSite();
            setIsDeleteSiteConfirmationOpen(false);
          }}>
            {t("Yes")}
          </Button>
          <Button onClick={() => {
            setIsDeleteSiteConfirmationOpen(false);
          }}>
            {t("No")}
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default PointSettingsMenu;
