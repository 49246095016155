import { Theme, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { DateTime } from "luxon";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import ChecklistDeviationAccordion from "./ChecklistDeviationAccordion";
import DeviationAccordion from "./DeviationAccordion";
import PreviewDialog from "../PreviewDialog";
import { Deviation } from "./useChecklistDeviations";

const useStyles = makeStyles(({ breakpoints }: Theme) =>
  createStyles({
    sessionDeviationsContainer: {
      borderRadius: "25px",
      padding: "1.5em",
      border: "0.5px solid rgba(102, 102, 102, 0.25)",
      marginBottom: "2em",
      [breakpoints.down("md")]: {
        padding: "1em",
        marginBottom: "1em",
      },
    },
    sessionDeviationsTitle: {
      marginBottom: "1em",
      fontWeight: "700",
      [breakpoints.down("md")]: {
        marginBottom: "0.1em",
        fontWeight: "650",
      },
    },
    checklistHeader: {
      flexDirection: "row",
      display: "flex",
      justifyContent: "space-between",
    },
  })
);

type Props = {
  tenantId: string;
  sessionDeviations: { [key: string]: Deviation[] };
  filterDeviations: (deviations: Deviation[]) => Deviation[];
  pointNameRegistry: { [key: string]: string };
};

export const SessionDeviationGroups: FC<Props> = ({
  tenantId,
  sessionDeviations,
  filterDeviations,
  pointNameRegistry,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      {Object.keys(sessionDeviations).map((sessionId, i) => {
        const itemDeviations = filterDeviations(sessionDeviations[sessionId]);
        return !!itemDeviations.length ? (
          <div key={sessionId + i} className={classes.sessionDeviationsContainer}>
            <div className={classes.checklistHeader}>
              <div className={classes.sessionDeviationsTitle}>
                {itemDeviations[0].checklistName} <Typography fontStyle='italic' fontWeight="light" display="inline">{t("checklist.deviationCard.reportedAt") + " "}
                  {DateTime.fromISO(
                    itemDeviations[0].timestamp as any
                  ).toLocaleString(DateTime.TIME_SIMPLE)}</Typography >

              </div>
              <PreviewDialog checklist={itemDeviations[0]} />
            </div>
            {itemDeviations.map((deviationItem, key) => {
              if (
                deviationItem.type === "TemperatureDeviationReported" ||
                deviationItem.type === "DeviationReported"
              )
                return (
                  <DeviationAccordion
                    tenantId={tenantId}
                    item={deviationItem}
                    key={
                      key + deviationItem.sessionId + deviationItem.checklistId
                    }
                    isAutoGenerated={!deviationItem.signatureId}
                    pointNameRegistry={pointNameRegistry}
                  />
                );
              else {
                return (
                  <ChecklistDeviationAccordion
                    tenantId={tenantId}
                    isAutoGenerated={
                      deviationItem.type === "ChecklistDeviationDetected"
                    }
                    item={deviationItem}
                    key={
                      key + deviationItem.sessionId + deviationItem.checklistId
                    }
                    comment={deviationItem.type === "ChecklistComment"}
                  />
                );
              }
            })}
          </div>
        ) : (
          <></>
        );
      })}
    </>
  );
};
