
<div class="deviation-confirm">
  <div class="deviation-confirm__heading">
    <a class="deviation-confirm__heading__go-back" routerLink="/deviationProcedure" routerLinkActive="active">
      <i class="material-icons">
keyboard_arrow_left
</i>
    </a>
  </div>

  <mat-card>
    <div class="deviation-confirm__body">
      <img src="assets/icons/icn_activity.png" alt="icn-activity" class="deviation-confirm__body__icon" />
      <div class="deviation-confirm__body__content">
        <!-- <p class="deviation-confirm__body__content__date">
          18.feb.2019
        </p> -->
        <p class="deviation-confirm__body__content__text">
          Temperaturkontrollen er signert og arkivert
        </p>
      </div>
    </div>
    <div class="center">
      <button
        mat-button
        class="send-button"
        routerLink="/"
        routerLinkActive="active"
      >
        Avslutt
      </button>
    </div>
  </mat-card>
</div>
