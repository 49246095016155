import * as React from "react";
import { useState, useContext, useEffect } from "react";
import {
  Box,
  Button,
  FormControlLabel,
  Switch,
  TextField,
  Tabs,
  Tab,
} from "@mui/material";
import { makeStyles, withStyles } from '@mui/styles';
import RoomCard from "../rooms-raect/components/RoomCard";
import { RoomsContext } from "../rooms-raect/RoomsReactWrapper";
import { take } from "rxjs/operators";
import PageHeader from "../react-components/PageHeader";
import { useTranslation } from "react-i18next";
import Loading from "../react-components/Loading";
import { Entity } from "../entity/entity";
import { Route, Navigate, Routes } from "react-router";
import QrCodes from "./components/QrCodes";
import Rooms from "../rooms-raect/components/Rooms";
import { BrowserRouter } from "react-router-dom";
import NewEntity from "./components/NewEntity";

const useStyles = makeStyles({
  
});

const EntityRoute = () => {
  return (
    <BrowserRouter>
      <Routes>
          <Route path="/new-entity" element={<NewEntity />}/>
          <Route path="/qr-code" element={<QrCodes />}/>
      </Routes>
    </BrowserRouter>
  );
};

export default EntityRoute;
