import * as React from "react";
import { useContext, useEffect, useState } from "react";
import {
  Button,
  Select,
  MenuItem,
  Box,
  Typography,
  Theme,
} from "@mui/material";
import { makeStyles } from '@mui/styles';
import { take } from "rxjs/operators";
import TempReport from "./TempChart";
import * as moment from "moment";
import { forkJoin } from "rxjs";
import { useTranslation } from "react-i18next";
import { TempChartContext } from "../TempChartReactWrapper";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: "1em 2em",
    [theme.breakpoints.down("sm")]: {
      padding: "0",
    },
  },
  actions: {
    marginBottom: "2em",
  },
  select: {},
}));

const TempChartPage: React.FC<{
  entityId: string,
  refDate: string,
  deviationValue: number
}> = ({ entityId, refDate, deviationValue }) => {


  const classes = useStyles();
  const { t } = useTranslation();

  const { entityService, signalsService } = useContext(TempChartContext);
  const [entity, setEntity] = useState<any>();
  const [temp, setTemp] = useState([]);

  const [tempSignals, setTempSignals] = useState([]);

  const [step, setStep] = useState(12);
  const [timespan, setTimespan] = useState<moment.DurationInputArg2>("hours");
  const [endDate, setEndDate] = useState(-step);
  const [startDate, setStartDate] = useState(step);

  useEffect(() => {
    forkJoin([
      entityService.getEntityWithSignals(entityId),
      signalsService.GetSignalsForEntity(
        entityId,
        moment(refDate).subtract(startDate, timespan),
        moment(refDate).subtract(endDate, timespan)
      ),
    ])
      .pipe(take(1))
      .subscribe(([entityData, signalsData]) => {
        setEntity(entityData);

        const entitySignals: any = entityData.signal;

        const filterSignalType = (unitType: string) =>
          entitySignals.filter((v) => v.unit === unitType);

        const tempSignal = filterSignalType("TempC");

        setTemp(tempSignal);

        const filterSignals = (sourceId: string) =>
          signalsData.filter((v) => v.sourceId === sourceId);

        tempSignal.length > 0 &&
          setTempSignals(filterSignals(tempSignal[0].sourceId));
      });
  }, [timespan, startDate, endDate]);

  const handleTimespanChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setTimespan(event.target.value as any);
    if (event.target.value === "hours") {
      setStep(12);
      setEndDate(-12);
      setStartDate(12);
    } else {
      setStep(1);
      setEndDate(-1);
      setStartDate(1);
    }
  };

  const handleNext = () => {
    setEndDate(endDate - step);
    setStartDate(startDate - step);
  };
  const handlePrev = () => {
    setEndDate(endDate + step);
    setStartDate(startDate + step);
  };

  const temperatureLimits = entity?.obj?.termoObjectType?.rules?.GREEN ?? entity?.obj?.rules?.temperature;
  return (
    <div className={classes.root}>
      <Box className={classes.actions}>
        <Select
          value={timespan}
          onChange={v => handleTimespanChange(v as any)}
          className={classes.select}
        >
          <MenuItem value="hours">{t("Hour")}</MenuItem>
          <MenuItem value="days">{t("Day")}</MenuItem>
          <MenuItem value="weeks">{t("Week")}</MenuItem>
          <MenuItem value="months">{t("Mont")}</MenuItem>
        </Select>
        <Button onClick={handlePrev}>{t("Previous")}</Button>
        {endDate >= 0 && <Button onClick={handleNext}>{t("Next")}</Button>}
      </Box>
      <Typography variant="h3">
        {`${moment(refDate)
          .subtract(startDate, timespan)
          .format("HH:mm - DD.MM.YYYY")} ${t("to")} 
        ${moment(refDate).subtract(endDate, timespan).format("HH:mm - DD.MM.YYYY")}`}
      </Typography>
      {temp.length > 0 && (
        <TempReport
          signals={tempSignals}
          startDate={startDate}
          endDate={endDate}
          timespan={timespan}
          limits={temperatureLimits}
          refDate={refDate}
          deviationValue={deviationValue}
        />
      )}
    </div>
  );
};

export default TempChartPage;
