import * as React from "react";
import { useState } from "react";
import { Button, Dialog, DialogContent } from "@mui/material";
import DialogTitle from "../../react-components/DialogTitle";
import ViewChecklist from "./ViewChecklist";
import { useTranslation } from "react-i18next";

type Props = {
  checklist: any;
};

const PreviewDialog = ({ checklist }: Props) => {
  const [open, setOpen] = useState(false);

  const { t } = useTranslation();

  const toggleOpen = () => {
    setOpen(!open);
  };

  return (
    <>
      <Button
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
          toggleOpen();
        }}
        variant="outlined"
      >
        {t("Preview")}
      </Button>
      <Dialog open={open} onClose={toggleOpen}>
        <DialogTitle onClose={toggleOpen}>{t("Preview")}</DialogTitle>
        <DialogContent>
          <ViewChecklist checklistId={checklist.assignedId} sessionId={checklist.sessionId} preview />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default PreviewDialog;
