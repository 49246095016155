import { Theme } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useChecklistContext } from "../ChecklistReactWrapper";
import {
  mapAllChecklists,
  useAssignChecklist,
  useSiteChecklists,
  useSiteDescribeChecklist,
  useTagChecklist,
} from "../services/checklistService";
import AddChecklistForm from "./edit-checklist/AddChecklistForm";
import AssignChecklistForm from "./edit-checklist/AssignChecklistForm";
import ChecklistOverviewCard from "./ChecklistOverviewCard";
import { TmeshRoles } from "../../auth-utils";

const useStyles = makeStyles(({ spacing }: Theme) =>
  createStyles({
    root: {},
    card: {
      padding: spacing(2),
      marginBottom: spacing(2),
    },
  })
);

const ChecklistOverviewList = () => {
  const classes = useStyles();
  const { user } = useChecklistContext();
  const [{ data, loading, error }, refetch] = useSiteChecklists(user.siteId);

  const { t } = useTranslation();
  
  if (loading) {
    return <p>{t("Loading")}</p>;
  }

  if (error) {
    return <p>{t("Error")}</p>;
  }

  const checklists = mapAllChecklists(data);

  return (
    <div>
      <AddChecklistForm onSubmit={refetch} />
      <AssignChecklistForm
        label={`${t("editChecklist.add_checklist_to_site")}`}
        onSubmit={async (e) => {
          var assignment = await useAssignChecklist({
            checklistId: e.checklistId,
            tenantId: e.tenantId,
            siteId: e.siteId,
          });
          await useTagChecklist({
            checklistId: e.checklistId,
            tenantId: e.tenantId,
            tag: e.siteName,
          });
          const event = await useSiteDescribeChecklist({
            checklistId: assignment.data.checklistId,
            name: e.checklistName,
            tenantId: assignment.data.tenantId,
            assignedId: assignment.data.assignedId,
          });

          refetch();
        }}
      />
      
      {checklists.sort((a, b) => (a.name < b.name) ? -1 : (a.name > b.name) ? 1 : 0).map((item, key) => (<>
          <ChecklistOverviewCard checklist={item} key={key + item.checklistId} onChange={refetch} />
        </>
      ))}
    </div>
  );
};

export default ChecklistOverviewList;
