import * as React from "react";
import { useContext, useState, useEffect } from "react";
import {
  Chip,
  Divider,
  FormControlLabel,
  IconButton,
  Menu,
  MenuItem,
  Snackbar,
  Switch,
  Theme,
} from "@mui/material";
import { makeStyles, createStyles } from '@mui/styles';
import { RoomsContext } from "../RoomsReactWrapper";
import { take } from "rxjs/operators";
import { useTranslation } from "react-i18next";
import { Alert } from "@mui/lab";

type Props = {
  entity: any;
  context?: any;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chip: {
      marginLeft: theme.spacing(1),
      backgroundColor: "#ba000d",
      color: "#fff",
    },
  })
);

const SimpleMenu = ({ entity, context }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const { entityService } = useContext(context || RoomsContext);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [hide, setHide] = useState<boolean>(false);

  const [success, setSucces] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    setDisabled(() => (entity ? entity.disabled : false));
    setHide(() => (entity ? entity.hide : false));
  }, [entity]);

  const handleSettingsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDisabled = () => {
    entityService
      .putEntity(entity.id, { ...entity, disabled: !disabled })
      .pipe(take(1))
      .subscribe(
        () => {},
        () => setError(true),
        () => {
          setSucces(true), setDisabled(!disabled);
        }
      );
  };

  const handleHide = () => {
    entityService
      .putEntity(entity.id, { ...entity, hide: !hide })
      .pipe(take(1))
      .subscribe(
        () => {},
        () => setError(true),
        () => {
          setSucces(true), setHide(!hide);
        }
      );
  };

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    setError(false);
    setSucces(false);
  };

  return (
    <>
      {hide && (
        <Chip label={t("Hidden")} className={classes.chip} size="small" />
      )}
      {disabled && (
        <Chip label={t("Disabled")} className={classes.chip} size="small" />
      )}
      <IconButton onClick={handleSettingsClick}>
        <span className="material-icons">settings</span>
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem
          onClick={() => (window.location.href = `edit-entity/${entity.id}`)}
        >
          {t("Edit")}
        </MenuItem>
        <MenuItem
          onClick={() => (window.location.href = `move-entity/${entity.id}`)}
        >
          {t("Move")}
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleDisabled}>
          <FormControlLabel
            control={
              <Switch
                checked={disabled}
                onChange={handleDisabled}
                name="disabled"
                color="primary"
              />
            }
            label={t("Disabled") as string}
          />
        </MenuItem>
        <MenuItem onClick={handleHide}>
          <FormControlLabel
            control={
              <Switch
                checked={hide}
                onChange={handleHide}
                name="hide"
                color="primary"
              />
            }
            label={t("Hidden") as string}
          />
        </MenuItem>
      </Menu>
      <Snackbar sx={{ height: "100%" }} open={success} autoHideDuration={6000} onClose={handleClose}>
        <Alert severity="success"><b>{t("The change has been saved")}</b></Alert>
      </Snackbar>
      <Snackbar sx={{ height: "100%" }} open={error} autoHideDuration={6000} onClose={handleClose}>
        <Alert severity="error">
          <b>{t("Something went wrong! Try again later")}</b>
        </Alert>
      </Snackbar>
    </>
  );
};

export default SimpleMenu;
