import * as React from "react";
import { useState } from "react";
import {
  Paper,
  Theme,
  Typography,
  Dialog,
  DialogContent,
  Box,
} from "@mui/material";
import { makeStyles, createStyles } from '@mui/styles';
import { DateTime } from "luxon";
import {
  mapChecklist,
  mapSession,
  useGetAssignedChecklistComposite,
  useSession,
} from "../services/checklistService";
import CheckIcon from "@mui/icons-material/CheckCircle";
import ViewChecklist from "./ViewChecklist";
import { useTranslation } from "react-i18next";
import DialogTitle from "../../react-components/DialogTitle"

type Props = {
  sessionObj: any;
  checklistId: string;
  refetch: () => void;
};

const useStyles = makeStyles(({ spacing, palette }: Theme) =>
  createStyles({
    root: {
      padding: spacing(3),
      display: "flex",
      cursor: "pointer",
      alignItems: "center",
    },
    checkIcon: {
      color: palette.success.main,
      marginRight: spacing(2),
    },
    title: {
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
    },
    textContainer: {
      width: "90%",
    },
  })
);

const CompletedSessionCard = ({ sessionObj, checklistId, refetch }: Props) => {
  const classes = useStyles();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [{ data: sessionData, loading: sessionLoading, error: sessionError }] =
    useSession(sessionObj.id);
  const [
    { data: checklistData, loading: checklistLoading, error: checklistError },
  ] = useGetAssignedChecklistComposite(checklistId);
  
  const convertDateTime = (timestamp) =>
    DateTime.fromISO(timestamp).toLocaleString(DateTime.DATETIME_SHORT);
  const { t } = useTranslation();
  if (sessionLoading || checklistLoading) {
    return <p>{t("Loading")}</p>;
  }

  if (checklistError || sessionError) {
    return <p>{t("Error")}</p>;
  }
  const checklist = mapChecklist(checklistData, checklistId);
  // const session = mapSession(sessionData, checklist);

  // const progress = (session) => {
  //   let checked = 0;
  //   session.map((item) => {
  //     item.checked == true && checked++;
  //   });
  //   return `${checked}/${session.length}`;
  // };

  // const deviation = (session) => {
  //   let deviations = 0;
  //   session.map((item) => {
  //     deviations += item.deviations.length;
  //   });
  //   return deviations;
  // };

  const toggleDialogOpen = () => {
    dialogOpen && refetch();
    setDialogOpen(!dialogOpen);
  };
  const lastChange = sessionData[sessionData.length - 1].Timestamp;
  const timestamp = sessionData.find(
    (x) => x.type === "ScheduleVisible"
  )?.startTime;
  const lastStoppedEvent = sessionData.slice().reverse().find(e => e.type === "SessionStopped")

  return (
    <>
      <Paper className={classes.root} onClick={() => toggleDialogOpen()}>
        <div>
          <CheckIcon className={classes.checkIcon} />
        </div>
        <Box className={classes.textContainer}>
          <Typography variant="h3" className={classes.title}>
            {checklist.name}
          </Typography>
          <Typography variant="subtitle1">
            {`${convertDateTime(timestamp)}`}
          </Typography>
          <Typography variant="caption">
            {`${t("checklist.checklistCard.lastChanged")}
            ${convertDateTime(lastChange)}`}
          </Typography>
        </Box>
      </Paper>
      <Dialog open={dialogOpen} onClose={toggleDialogOpen}>
        <DialogTitle onClose={toggleDialogOpen}>{checklist.name}</DialogTitle>
        <DialogContent>
          <ViewChecklist
            checklistId={checklistId}
            sessionId={sessionObj.id}
            preview
            refetch={refetch}
            stateTime={lastStoppedEvent?.Timestamp ? DateTime.fromISO(lastStoppedEvent?.Timestamp) : null}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CompletedSessionCard;
