import axios from "axios";
import * as React from "react";
import { useEffect, useState } from "react";
import { useChecklistContext } from "../ChecklistReactWrapper";
import { DeviationCalendar } from "./deviations/DeviationCalendar";

const ChecklistCalendar = () => {
  const [output, setOutput] = useState(null);
  const { user, siteService } = useChecklistContext();

  // Get tasks
  // useEffect(() => {
  //   const fetchTasks = async () => {
  //     const result = await axios.get(
  //       `https://api.bitme.sh/checklist/api/checklist/site/${user.siteId}/deviations-calendar?startTime=2023-03-01T00%3A00%3A00.000%2001%3A00&endTime=2023-04-01T00%3A00%3A00.000%2002%3A00&fbclid=IwAR1GXAtl7YcDsplmQ7HTjjqt3SypJhQfgXcaLs076dpVzRVzVSOogU--2UE`
  //     );
  //     setOutput(result.data);
  //   };

  //   fetchTasks();
  // }, []);

  useEffect(() => {
    console.log(output);
  }, [output]);

  return (
    <div>
      <h1>Calendar</h1>
      {user && <DeviationCalendar siteId={user.siteId} />}
    </div>
  );
};

export default ChecklistCalendar;
