import { Guid } from "./system/guid";
import { Moment } from "moment";

export class Alarm {
  public readonly alarmType: AlarmType;

  constructor(
    private id: Guid,
    public alarmId: Guid,
    public type: string,
    private sourceId: string,
    public createdOn: Moment,
    public measurement: any
  ) {
    this.alarmType = AlarmType[type];
  }
}

export function isActiveAlarm(alarms: Alarm[]): boolean {
  var alarm = getLatestRegisterAlarmIfAlarmState(alarms);
  return alarm !== null;
  // for (var i = alarms.length - 1; i >= 0; i--) {
  //   var alarm = alarms[i];

  //   if (alarm.alarmType === AlarmType.RegisterAlarm) {
  //     return true;
  //   } else if (alarm.alarmType === AlarmType.CloseAlarm) {
  //     return false;
  //   }
  // }
  // return false;
}

export function getLatestRegisterAlarmIfAlarmState(alarms: Alarm[]): Alarm {
  for (var i = alarms.length - 1; i >= 0; i--) {
    var alarm = alarms[i];

    if (alarm.alarmType === AlarmType.RegisterAlarm) {
      return alarm;
    } else if (alarm.alarmType === AlarmType.CloseAlarm) {
      return null;
    }
  }
  return null;
}

export enum AlarmType {
  RegisterAlarm = "RegisterAlarm",
  CloseAlarm = "CloseAlarm",
  NotifyAlarm = "NotifyAlarm",
  HandledAlarm = "HandledAlarm",
  MissingEventsAlarm = "MissingEventsAlarm",
  RegisterWarning = "RegisterWarning",
  CloseWarning = "CloseWarning",
}
