import {
  AfterViewInit,
  Component,
  ElementRef,
  NgZone,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { ThemeProvider } from "@mui/material";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { Subscription } from "rxjs";
import { Chanels } from "../chanels";
import { EntityService } from "../entity/entity.service";
import { theme } from "../react-theme";
import { Guid } from "../system/guid";
import { ServiceBusService } from "../system/service-bus.service";
import "../react-components/i18n";
import { SiteService } from "../entity/site.service";
import { Site } from "../entity/site";
import ChecklistWrapper from "./components/ChecklistWrapper";
import { setAxiosInterceptors } from "../security/axiosInterceptors";
import axios from "axios";

const containerElementName = "myReactComponentContainer";

@Component({
  selector: "app-my-component",
  template: `<span #${containerElementName}></span>`,
  encapsulation: ViewEncapsulation.None,
})
export class ChecklistReactComponent
  implements OnChanges, OnDestroy, AfterViewInit {
  @ViewChild(containerElementName, { static: true }) containerRef: ElementRef;

  siteId: Guid;

  public isLoadingEntities = true;

  private userSubscription: Subscription;

  private user: any;

  private tenantId: Guid;

  constructor(
    private bus: ServiceBusService,
    private entityService: EntityService,
    private siteService: SiteService,
    private zone: NgZone
  ) {
    setAxiosInterceptors(axios);
  }

  ngOnInit() {
    this.userSubscription = this.bus
      .chanelObservable(Chanels.USER_CHANEL)
      .subscribe((user) => {
        this.user = user;
        this.render();
        this.siteService.getSite(user.siteId).subscribe((site: Site) => {
          this.tenantId = new Guid(site.tenantId.toString());
          this.render();
          this.zone.run(() => { });
        });
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.render();
  }

  ngAfterViewInit() {
    this.render();
  }

  ngOnDestroy() {
    this.userSubscription.unsubscribe();
    ReactDOM.unmountComponentAtNode(this.containerRef.nativeElement);
  }

  private render() {
    ReactDOM.render(
      <ThemeProvider theme={theme}>
        <ChecklistContext.Provider
          value={{
            user: this.user,
            tenantId: this.tenantId,
            entityService: this.entityService,
            siteService: this.siteService,
          }}
        >
          <ChecklistWrapper />
        </ChecklistContext.Provider>
      </ThemeProvider>,
      this.containerRef.nativeElement
    );
  }
}

type CheklistContextTypes = {
  user: any;
  tenantId: Guid;
  entityService: EntityService;
  siteService: SiteService;
};

const ChecklistContext = React.createContext<CheklistContextTypes>(undefined);

export const useChecklistContext = () => React.useContext(ChecklistContext);
