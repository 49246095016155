import Axios from "axios";
import { removeRefreshToken, removeToken, setRefreshToken, setToken } from "./token";
import { environment } from "../../environments/environment";

const SECURITY_API_URL = environment.dependencies.security_api.url;

const axiosInstance = Axios.create();
axiosInstance.defaults.baseURL = SECURITY_API_URL;

export async function getAccess(authToken: string) {
  const resp = await axiosInstance?.get(`/api/authentication/token`, {
    headers: {
      Authorization: `Bearer ${authToken}`
    }
  })
  const { jwtToken, refreshTokenId } = resp.data;

  setToken(jwtToken);
  setRefreshToken(refreshTokenId);
}

export async function getAccessByCode(externalUserId: string, code: string) {
  const axiosInstance = Axios.create();
  axiosInstance.defaults.baseURL = SECURITY_API_URL;

  const resp = await axiosInstance?.post(`/api/authentication/code`, {
    externalUserId,
    code
  });
  const { jwtToken, refreshTokenId } = resp.data;

  setToken(jwtToken);
  setRefreshToken(refreshTokenId);
}

export async function requestCodeGeneration(phoneNumber: string) {
  const axiosInstance = Axios.create();
  axiosInstance.defaults.baseURL = SECURITY_API_URL;

  await axiosInstance?.post(`/api/authcode/generate`, {
    externalUserId: phoneNumber,
    sendTo: phoneNumber
  });
}

export function logout() {
  removeToken();
  removeRefreshToken();
}