import { Component, OnDestroy, OnInit, } from "@angular/core";
import { EntityService } from "../../entity/entity.service";
import { DataService } from "../../reports/data.service";
import { TempReport, ReportType } from "../../reports/tempreport";
import {
  FormGroup,
  FormArray,
  FormBuilder,
} from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { SiteEntities } from "../../entity/siteentities";
import { Measurement } from "../../measurements/measurement";
import { Deviation } from "../../reports/deviation";
import { Guid } from "../../system/guid";
import { TempreportService } from "../../reports/tempreport.service";
import {
  MatDialog,
} from "@angular/material/dialog";
import { ServiceBusService } from "../../system/service-bus.service";
import { Chanels } from "../../chanels";
import {
  ReportDeviationsType,
  RouteWithReportType,
} from "../../reports/report-deviations-type";
import * as moment from "moment";
import { AlarmsService } from "../../alarms.service";
import { Subscription, take } from "rxjs";

@Component({
  selector: "app-deviation-procedures",
  templateUrl: "./deviation-procedures.component.html",
  styleUrls: ["./deviation-procedures.component.scss"],
})
export class DeviationProceduresComponent implements OnInit, OnDestroy {
  public tempForm: FormGroup;
  public deviationList = Array<Deviation>();
  public measurments = Array<Measurement>();
  public siteEntities: SiteEntities;
  showLoader: boolean;
  id: any;

  private userSubscription: Subscription;

  private user: any;

  private entitiesWithSignals: Array<any>;

  get deviationMessageType() {
    return DeviationMessageType;
  }

  private deviationMessages = {
    [DeviationMessageType.Empty]: "Det er tomt",
    [DeviationMessageType.Cooking]: "Under tilberedning",
    [DeviationMessageType.Clean]: "Vi har gjort rengjøring",
    [DeviationMessageType.Delivery]: "Vi har fått inn nye varer",
    [DeviationMessageType.Other]: "",
  };

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    // private entities: EntityService,
    private data: DataService,
    private fb: FormBuilder,
    private entities: EntityService,
    private tempReportService: TempreportService,
    private alarmsService: AlarmsService,
    private bus: ServiceBusService,
    public dialog: MatDialog
  ) {
    this.tempForm = this.data.getTemReportForm();
    console.log(this.tempForm);

    if (this.tempForm == null) {
      this.router.navigate(["/report"]);
      return;
    }

    this.getSiteEntities();
    this.siteEntities = this.data.getSiteEntities();
  }

  getActionForm(index: number): FormGroup {
    let entityId = this.getEntityId(index);
    const deviations: any = this.tempForm.get("deviation");

    if (entityId == null || this.data == null) return null;
    var form = deviations.controls.filter((x) => x.value.entityId == entityId);
    return form.length > 0 ? form[0] : null;
  }

  private getEntityId(index: number): string {
    const deviations: any = this.tempForm.get("deviation");
    if (deviations.controls.length - 1 < index) return null;
    return deviations.controls[index].value.entityId;
  }

  public getLastMeasuredTime(entityId: string): string {
    const entity = this.entitiesWithSignals?.find(x => x.id === entityId);
    if (!entity) return "";
    const temp = entity.signal && entity.signal.filter((v) => v.unit === "TempC");
    return temp[0].lastValue;
  }

  public getEntityMinMax(entityId: string) {
    const entity = this.entitiesWithSignals?.find(x => x.id === entityId);
    if (!entity) return { min: "NaN", max: "NaN" };
    return entity?.obj?.termoObjectType?.rules?.GREEN ?? entity?.obj?.rules?.temperature
  }

  public updateAction(index: number, messageType: DeviationMessageType) {
    let message = this.deviationMessages[messageType];
    this.getActionForm(index).patchValue({ action: message });
  }

  ngOnInit() {
    this.buildEntityWithValue();
    this.deviationList = this.tempForm.value.deviation;
    this.measurments = this.tempForm.value.measurements;
    this.userSubscription = this.bus
      .chanelObservable(Chanels.USER_CHANEL)
      .subscribe((user) => {
        this.entities
          .getEntitiesForSiteWithAllSignals(user.siteId)
          .pipe(take(1))
          .subscribe(
            {
              next: (res) => {
                this.entitiesWithSignals = res;
              },
              error: (error) => {
                console.log(error);
              },
              complete: () => {
              }
            }
          )
      });
  }

  ngOnDestroy(): void {
    this.userSubscription?.unsubscribe();
  }

  goBack() {
    var hasManualMeasurements =
      this.tempForm != null &&
      this.tempForm.get("measurements").value.filter((x) => x.value.isManual)
        .length > 0;

    let route = hasManualMeasurements ? "/temperature-register" : "/report";
    var reportType = ReportDeviationsType.HandlePeriodicDeviations;
    var reportTypeField = this.tempForm.get("reportType");

    if (reportTypeField) {
      reportType = reportTypeField.value;
    }
    RouteWithReportType(route, reportType, this.router);
  }

  get deviations(): FormArray {
    return this.tempForm.get("deviation") as FormArray;
  }
  hasDeviation(mId: string): Deviation {
    if (!this.deviationList) return null;
    var devisList = this.deviationList.filter((x) => x.entityId == mId);
    return devisList.length > 0 ? devisList[0] : null;
  }
  buildEntityWithValue() {
    var deviations = this.tempForm.get("deviation").value as Array<Deviation>;

    var ms = this.tempForm.get("measurements").value as Array<Measurement>;
    ms.forEach((x: any) => {
      if (x.isManual) {
        var entity = this.siteEntities.getEntity(x.entityId);
        let rules =
          entity.obj && entity.obj.rules && entity.obj.rules.temperature;
        var isDeviation = rules && (rules.min > x.value || rules.max < x.value);

        if (isDeviation) {
          var deviationExists =
            deviations.find((d) => d.entityId == x.entityId) != null;

          if (!deviationExists) {
            // push to formArray
            var d = new Deviation(
              Guid.newGuid().toString(),
              x.entityId,
              x.occuredTime,
              "",
              100
            );
            var deviArray = this.tempForm.get("deviation") as FormArray;
            deviArray.push(this.fb.group(d));
          }
        }
      }
    });
  }

  public getEntityName(entityid: string) {
    if (!this.siteEntities) return "";
    return this.siteEntities.getEntityName(entityid);
  }
  public getValue(entityId: string) {
    let measurement = this.getMeasurement(entityId);
    return measurement.value;
  }
  public printDate(dateObj: any) {
    let date = moment(dateObj);
    return `kl. ${date.format("HH:mm DD.MM.YYYY.")}`;
  }
  private getMeasurement(entityId: String): Measurement {
    let measurements = this.tempForm.get("measurements")
      .value as Array<Measurement>;
    return measurements.find((m) => m.entityId == entityId);
  }

  getSiteEntities() {
    this.bus.subscribe(Chanels.USER_CHANEL, (user) => {
      if (user == null) return;
      this.entities
        .getSiteEntities(user.siteId)
        .subscribe((data: SiteEntities) => {
          this.siteEntities = data;
          this.showLoader = false;
          this.data.setSiteEntities(this.siteEntities);
        });
    });
  }
  // selectDeviation(item: Measurement){
  //   this.data.setTempReportForm(this.tempForm);
  //   this.router.navigate(['/deviationmsg', item.entityId ]);
  // }
  updateTempform() {
    var report = TempReport.fromForm(this.tempForm.getRawValue());

    // Set report state and deviations state as completed
    report.setState(400);
    report.getDeviations().forEach((deviation) => {
      deviation.state = 400;
    });

    // Set report type to manual
    report.reportType = ReportType.Manual;

    // Update report
    this.tempReportService.updateTempReport(report).subscribe(
      (res) => {
        this.router.navigate(["/deviations-confirm"]);
      },
      (err) => {
        console.log("Det oppstod en feil under registreringen av handlingen");
      }
    );
  }
}

export enum DeviationMessageType {
  Empty,
  Clean,
  Delivery,
  Cooking,
  Other,
}
