import { Component, OnInit } from "@angular/core";
import { ServiceBusService } from "../../system/service-bus.service";
import { Observable } from "rxjs";
import { Chanels } from "../../chanels";
import { MsalService } from "@azure/msal-angular";
import { SiteService } from "../../entity/site.service";
import { environment } from "../../../environments/environment";
import { setDefaultRoute } from "../../route-utils";
import { Router } from "@angular/router";
import pwaInstallHandler from "pwa-install-handler";
import { pushNotificationsEnabled } from "../../notificationService";
import { logoutClearStorageAndRedirect } from "../../security/axiosInterceptors";

@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.scss"],
})
export class ProfileComponent implements OnInit {
  user$: Observable<any>;
  // hasMultipleSites: boolean = false;
  public siteName: String;
  public version = environment.version;
  public canInstall = true;
  public pushNotificationsEnabled = false;
  constructor(
    private router: Router,
    private bus: ServiceBusService,
    private msalService: MsalService,
    private sitesService: SiteService
  ) {}

  ngOnInit() {
    this.user$ = this.bus.chanelObservable(Chanels.USER_CHANEL);
    this.user$.subscribe((user) => {
      console.log("User: ", user);
      if(!user) return;

      // Get site name
      this.sitesService.getSite(user.siteId).subscribe((site) => {
        console.log("Site: ", site);
        this.siteName = site.name;
      });
    });

    pwaInstallHandler.addListener((canInstall) => {
      this.canInstall = canInstall;
    });
    this.pushNotificationsEnabled = pushNotificationsEnabled();
    console.log(`pushNotificationsEnabled: ${this.pushNotificationsEnabled}`)
  }


  logout() {
    logoutClearStorageAndRedirect();
  }

  updateCache() {
    console.log("Update version");
    (caches?.keys() ?? new Promise((r) => r([]))).then(function (
      names: string[]
    ) {
      for (let name of names) caches.delete(name);
    });
    window.location.reload();
  }

  setDefaultPage() {
    setDefaultRoute(this.router.url);
  }

  goToNotificationSubscriptionSettings() {
    this.router.navigate(['/notification-settings'])
  }

  goToUserProfile() {
    this.router.navigate(['/user-profile'])
  }

  installApp() {
    pwaInstallHandler.install();
  }
}
