import { Moment } from "moment";
import { Entity } from "../entity/entity";

export class Deviation{
	 constructor(
		 public id: string,
		 public entityId: string,
		 public occuredTime: string,
		 public action: string,
		 public state: Number
	 ){
		 this.id = id;
		 this.entityId = entityId;
		 this.occuredTime = occuredTime;
		 this.action = action;
		 this.state = state;
	 }
	 public static create(o:any){
		 return new Deviation(o.id,o.entityId,o.occuredTime,o.action,o.state);
	 }
	 public getId(): string{
		 return this.id;
	 }

	 public getEntityId(): string{
		 return this.entityId;
	 }

	 public getOccuredTime(): string{
		 return this.occuredTime;
	 }

	 public getAction(): string{
		 return this.action;
	 }

	 public getState(): Number{
		 return this.state;
	 }
}
