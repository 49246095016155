import { Component, OnInit, HostListener } from "@angular/core";
import { TempreportService } from "../../reports/tempreport.service";
import { ServiceBusService } from "../../system/service-bus.service";
import { Chanels } from "../../chanels";
import { TempReport } from "../../reports/tempreport";
import { EntityService } from "../../entity/entity.service";
import { Entity } from "../../entity/entity";
import { Measurement } from "../../measurements/measurement";
import * as moment from "moment";
import { SignalsService } from "../../signals/signals.service";
import { VEvent } from "../../signals/vevent";
import { Observable } from "rxjs";
import { take } from "rxjs/operators";
import { map } from "rxjs/internal/operators/map";

function ascii(character) {
  return character.charCodeAt(0);
}

@Component({
  selector: "app-temperature-snapshot",
  templateUrl: "./temperature-snapshot.component.html",
  styleUrls: ["./temperature-snapshot.component.scss"],
})
export class TemperatureSnapshotComponent implements OnInit {
  private tempReport: TempReport;
  private entities: Entity[];
  public showSignalIds: boolean = false;
  public measurements: any[];
  public isLoading = true;

  constructor(
    private tempreportService: TempreportService,
    private entityService: EntityService,
    private signalsService: SignalsService,
    private bus: ServiceBusService
  ) {}

  ngOnInit() {
    this.getTempReportAndEntities();
  }
  @HostListener("window:keyup", ["$event"])
  keyEvent(event: KeyboardEvent) {
    if (event.ctrlKey && event.shiftKey && event.keyCode === ascii("H")) {
      this.showSignalIds = !this.showSignalIds;
    }
  }
  getTempReportAndEntities() {
    this.bus.subscribe(Chanels.USER_CHANEL, (user) => {
      if (user == null) return;
      this.tempreportService.getSnapshot(user.siteId).subscribe(
        (tempReport) => {
          this.tempReport = tempReport;
          this.getEntities(user.siteId);
        },
        (err) => {
          this.isLoading = false;
          console.log(err);
        }
      );
    });
  }

  getEntities(siteId: any) {
    this.entityService
      .getSiteEntities(siteId)
      .pipe(take(1))
      .subscribe(
        (entities) => {
          this.entities = entities.getEntities();
          this.buildMeasurements();
          this.isLoading = false;
        },
        (err) => {
          this.isLoading = false;
        }
      );
  }

  buildMeasurements() {
    var result = [];
    var i = 0;

    this.tempReport.getMeasurements().forEach((measurement) => {
      var entity = this.entities.find(
        (e) => e.id.toString() == measurement.entityId
      );
      var entityName = entity != null ? entity.name : "<Ukjent>";
      var entityType = entity != null ? entity.type : "";
      var value = measurement.value;

      var averageValue = this.getAverageValue(
        entity.id,
        measurement.OccuredTime
      );
      var signalTypes = {
        TermoObject: "temperatur",
        ReptileObject: "fuktighet",
      };
      var cssClasses = {
        TermoObject: "temperature",
        ReptileObject: "humidity",
      };
      var signalType = signalTypes[entityType]
        ? signalTypes[entityType]
        : "ukjent";
      var cssClass = cssClasses[entityType] ? cssClasses[entityType] : "ukjent";
      const rules =
        entity.obj && entity.obj.rules && entity.obj.rules.temperature;
      var isDeviation =
        entity && rules && (rules.min > value || rules.max < value);
      var isManual = value == null;

      result[i++] = {
        id: entity.id,
        entityName,
        entityType,
        value: value,
        averageValue: averageValue,
        isDeviation: isDeviation,
        isManual: isManual,
        sourceId: measurement.sourceId,
        signalType,
        cssClass,
        occuredTime: measurement.OccuredTime.fromNow(),
      };
    });

    this.measurements = result.sort((a, b) =>
      a.entityName > b.entityName ? 1 : -1
    );
  }

  private getAverageValue(
    entityId: string,
    to: moment.Moment
  ): Observable<string> {
    var from = moment(to).add(-1, "hours");

    return this.signalsService.GetSignalsForEntity(entityId, from, to).pipe(
      map((signals: Array<VEvent>) => {
        let avg = this.getMovingAverageGraph(signals);
        return avg;
      })
    );
  }

  private getMovingAverageGraph(events: VEvent[]): string {
    if (events.length <= 0) {
      return "";
    }

    var sum = events.reduce(
      (sum, current) => sum + parseFloat(current.value),
      0
    );
    var average = sum / events.length;
    return average.toFixed(2);
  }
}
