import { Theme, useMediaQuery, useTheme } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import * as React from "react";
import MobileInfo from "./MobileInfo";
import Info from "./Info";
import { AlarmsService } from "../../alarms.service";
import { take } from "rxjs/operators";
import { Alarm, AlarmType } from "../../alarm";

type Props = {
  entity: any;
};

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    icon: {},
    tabletBox: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      flexBasis: 0,
      flexGrow: 1,
    },
  });

const useStyles = makeStyles(styles);

const RoomData = ({ entity }: Props) => {
  const classes = useStyles();
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));

  const alarmService = new AlarmsService();
  const [alarms, setAlarms] = React.useState<Array<Alarm>>([]);
  const [alarm, setAlarm] = React.useState(false);
  const [warning, setWarning] = React.useState(false);
  const [alarmEvent, setAlarmEvent] = React.useState<Alarm>();
  const [warningEvent, setWarningEvent] = React.useState<Alarm>();

  const evaluateState = (alarms: Array<Alarm>) => {
    setAlarms(alarms);
    if (alarms.length > 0) {
      const lastAlarmEvent = [
        ...alarms.filter((e) => {
          return (
            e.type == AlarmType.RegisterAlarm || e.type == AlarmType.CloseAlarm
          );
        }),
      ].pop();
      const lastWarningEvent = [
        ...alarms.filter((e) => {
          return (
            e.type == AlarmType.RegisterWarning ||
            e.type == AlarmType.CloseWarning
          );
        }),
      ].pop();
      setAlarm(
        lastAlarmEvent && lastAlarmEvent.type == AlarmType.RegisterAlarm
      );
      setWarning(
        //cannot be warning when alarm
        !(lastAlarmEvent && lastAlarmEvent.type == AlarmType.RegisterAlarm) &&
          lastWarningEvent &&
          lastWarningEvent.type == AlarmType.RegisterWarning
      );
      setAlarmEvent(lastAlarmEvent);
      setWarningEvent(lastWarningEvent);
    }
  };

  const door =
    entity.signal && entity.signal.filter((v) => v.unit === "DoorOC");
  const temp = entity.signal && entity.signal.filter((v) => v.unit === "TempC");
  const humidity =
    entity.signal && entity.signal.filter((v) => v.unit === "Humidity%");
  const ppm =
    entity.signal && entity.signal.filter((v) => v.unit === "ConcPPM");
  const pressure =
    entity.signal && entity.signal.filter((v) => v.unit === "PressurePascal");

  React.useEffect(() => {
    alarmService
      .getAlarms(entity.id, "")
      .pipe(take(1))
      .subscribe(evaluateState);
  }, []);

  if (smUp) {
    return (
      <>
        {entity.obj?.rules && (
          <Info
            rules={entity.obj?.rules}
            door={door}
            temp={temp}
            humidity={humidity}
            ppm={ppm}
            pressure={pressure}
            entityTypeName={entity.obj?.termoObjectType?.name}
            warning={warning}
            alarm={alarm}
            alarmEvent={alarmEvent}
            warningEvent={warningEvent}
            entity={entity}
          />
        )}
      </>
  )}
      
  return (
    <>
      {entity.obj?.rules && (
        <MobileInfo
          rules={entity.obj?.rules}
          door={door}
          temp={temp}
          humidity={humidity}
          entityTypeName={entity.obj?.termoObjectType?.name}
          warning={warning}
          alarm={alarm}
        />
      )}
    </>
  );
};

export default RoomData;
