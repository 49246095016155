<div class="site-select-modal">
  <div class="close-wrap">
    <button
      mat-raised-button
      color="primary"
      type="button"
      (click)="closeDialog()"
    >
      {{ "Close" | i18next }}
    </button>
  </div>

  <h3>{{ "mainMenu.selectSite" | i18next }}</h3>
  <ng-template ngFor let-site [ngForOf]="sites$ | async">
    <div class="select-site-wrap">
      <button
        mat-button
        mat-dialog-close
        class="select-button"
        (click)="selectSite(site.id)"
      >
        {{ site.name }}
      </button>
    </div>
  </ng-template>
</div>
