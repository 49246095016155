import { Button } from '@mui/material';
import { ICommand } from '@uiw/react-md-editor';
import React from 'react';
import { useTranslation } from 'react-i18next';
import OfflineShareIcon from '@mui/icons-material/OfflineShare';

type Props = {
  externalLink: any;
};

const PointExternalLink = ({
  externalLink,
}: Props) => {

  const { t } = useTranslation();

  return (
    <Button variant="contained" href={externalLink.url} target="_blank">
      <OfflineShareIcon />
      {externalLink.title}
    </Button>
  );
};

export default PointExternalLink;
