import * as React from "react";
import {
  Theme,
} from "@mui/material";
import { TimelineDot, } from "@mui/lab";
import { makeStyles, createStyles } from '@mui/styles';
import { useTranslation } from "react-i18next";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: "0.5em",
    },
    ruleTitle: {
      marginLeft: theme.spacing(1),
      fontWeight: 500
    },
    ruleTitleContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
    }
  });

const useStyles = makeStyles(styles);

type Props = {
  rules: any;
  showActionDescription?: boolean;
};

const EntityTypeRulesCard = ({ rules, showActionDescription=true }: Props) => {
  
  const { t } = useTranslation();
  const classes = useStyles();

  const keyColorRegistry = {
    GREEN: "success",
    YELLOW: "warning",
    RED: "error",
  }

  return (
    <div className={classes.root}>
      {Object.keys(rules).map(ruleKey => {
        const { min, max, actionDescription } = rules[ruleKey];
        return (
          <div className={classes.ruleTitleContainer}>
            <TimelineDot sx={{ display: 'inline-block', width: '1px', verticalAlign: 'middle' }} color={keyColorRegistry[ruleKey]} />
            <p className={classes.ruleTitle}>{t(`entityTypes.cardDisplay.rules.${ruleKey}`, { min, max })}
              {(actionDescription && showActionDescription) ? t(`entityTypes.cardDisplay.actionDescription`, { actionDescription }) : <></>}
            </p>
          </div>
        )
      }
      )}
    </div>
  );
};

export default EntityTypeRulesCard;
