import { Theme, Typography } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import SignatureCard from "./SignatureCard";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles(({ spacing }: Theme) =>
  createStyles({
    root: {},
    wrapper: {
      overflowY: 'scroll'
    },
  })
)

type Props = {
  userId?: string;
  name?: string;
  onChange: ()=>void;
  signatures: any[];
};

const FindSignature = ({ userId, name, onChange, signatures }: Props) => {
  const classes = useStyles();

  const [expanded, setExpanded] = useState(true);

  const amountSignatures = 5;
  const { t } = useTranslation();

  const filteredSignatures = signatures
    .filter((x) => x.name.toLowerCase().includes(name.toLowerCase()))
    .splice(0, amountSignatures);

  return (
    <>
      {filteredSignatures.length > 0 && (
        <Typography>{t("signature.add_existing")}</Typography>
      )}
      <div className={classes.wrapper}>
        {expanded &&
          filteredSignatures.length > 0 &&
          filteredSignatures.map((item) => {
            return <SignatureCard s={item} userId={userId} key={item.id} onChange={onChange} />;
          })}
      </div>
    </>
  );
};

export default FindSignature;
