<ng-container [formGroup]="rules">
  <mat-form-field>
    <input
      matInput
      placeholder="*Maks åpne/lukke"
      formControlName="max"
      type="number"
    />
  </mat-form-field>
</ng-container>
