import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, FormControl, Validators } from '@angular/forms';
import { Deviation } from '../../reports/deviation';
import { Measurement } from '../../measurements/measurement';
import { SiteEntities } from '../../entity/siteentities';
import { Router, ActivatedRoute } from '@angular/router';
import { DataService } from '../../reports/data.service';
import { EntityService } from '../../entity/entity.service';
import { Guid } from '../../system/guid';
import { Entity } from '../../entity/entity';
import { TempreportService } from '../../reports/tempreport.service';
import { ServiceBusService } from '../../system/service-bus.service';
import { Chanels } from '../../chanels';


@Component({
  selector: 'app-deviation-message',
  templateUrl: './deviation-message.component.html',
  styleUrls: ['./deviation-message.component.scss']
})
export class DeviationMessageComponent implements OnInit {
  private tempForm: FormGroup;
  public actionForm: FormGroup;
  private deviationList = Array<Deviation>();
  private measurments = Array<Measurement>();
  private siteEntities: SiteEntities;
  private selctedEntityId: string ;
  public selectedMeasurment : Measurement;
  public entity: Entity;
  private fb: FormBuilder;

  showLoader: boolean;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private data: DataService,
    private entities: EntityService,
    private bus: ServiceBusService,
    private tempReportService: TempreportService,
   
 ) {
   this.tempForm = this.data.getTemReportForm(); 
   this.siteEntities = this.data.getSiteEntities(); 
   this.createActionformbuilder();
 
   
 }
private createActionformbuilder(){
  this.actionForm= new FormGroup({
    mvalue :new FormControl(null, Validators.required)
     
  });
}
 ngOnInit() {
    //this.buildEntityWithValue();
    this.selctedEntityId= this.route.snapshot.params['id'];
    this.measurments= this.tempForm.value.measurements;
    this.deviationList= this.tempForm.value.deviation;
    this.selectedMeasurment= this.getMeasurment(this.selctedEntityId);
    this.entity= this.siteEntities.getEntity(this.selctedEntityId);
 }

 get measurmentFormGroup(): FormArray {
  return this.tempForm.get('measurements') as FormArray;
};

onSubmit (actionForm: FormGroup){
  var ms = this.tempForm.get("deviation").value as Array<Deviation>;

  ms.forEach((x:any) =>{
    if(x.entityId== this.selctedEntityId){
      x.action= actionForm.controls.mvalue;
  
    }
    
  });
let tempReport = this.tempForm.getRawValue();

//tahani
this.tempReportService.updateTempReport(tempReport)
  .subscribe(
    (err) => { console.log('Det oppstod en feil under registreringen av handlingen') }
  );
 
}
 public getEntityName(entityid: string){
  if (!this.siteEntities) return "";
  return this.siteEntities.getEntityName(entityid);
}
getSiteEntities(){
  this.bus.subscribe(Chanels.USER_CHANEL,(user)=>{
    if(user == null) return;
    this.entities.getSiteEntities(user.siteId)
    .subscribe(
      (data:SiteEntities )=>{
        this.siteEntities= data;
        this.showLoader=false;
        this.data.setSiteEntities(this.siteEntities);
      });
  });
  
}
public getMeasurment(mId): Measurement {
  var measermentsList = this.measurments.filter(x => x.entityId == mId);
  return measermentsList.length > 0 ? measermentsList[0] : null;
}
 

}
