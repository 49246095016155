import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfigurableMessageBus } from './configurable-message-bus';
import { RemoteEventService } from './event/remote-event.service';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [],
  providers: [ConfigurableMessageBus, RemoteEventService],
  exports:[]
})
export class InfrastructureModule { }
