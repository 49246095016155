// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

interface EnvironmentConfig {
  CHECKLIST_API_URL: string;
  SIGNATURE_API_URL: string;
  ENTITY_API_URL: string;
  NOTIFICATIONS_API_URL: string;
  TERMOOBJECT_API_URL: string;
  SIGNAL_API_URL: string;
  SECURITY_API_URL: string;
  FILE_API_URL: string;
  TEMPREPORTS_API_URL: string;

  REDIRECT_URL: string;
  APP_ID: string;
  ENVIRONMENT: string;
  PUSH_NOTIFICATIONS_ENABLED: string;
  DOMAIN: string;
}

const w: { _env_: EnvironmentConfig; location: { hostname: string } } =
  window as any;

export const REDIRECT_URL = w._env_.REDIRECT_URL;
export const ENVIRONMENT = w._env_.ENVIRONMENT;
export const PUSH_NOTIFICATIONS_ENABLED = w._env_.PUSH_NOTIFICATIONS_ENABLED;
export const DOMAIN = w._env_.DOMAIN;
export const APP_ID = w._env_.APP_ID;

export const CHECKLIST_API_URL = w._env_.CHECKLIST_API_URL;
export const SIGNATURE_API_URL = w._env_.SIGNATURE_API_URL;
export const ENTITY_API_URL = w._env_.ENTITY_API_URL;
export const NOTIFICATIONS_API_URL = w._env_.NOTIFICATIONS_API_URL;
export const TERMOOBJECT_API_URL = w._env_.TERMOOBJECT_API_URL;
export const SIGNAL_API_URL = w._env_.SIGNAL_API_URL;
export const SECURITY_API_URL = w._env_.SECURITY_API_URL;
export const FILE_API_URL = w._env_.FILE_API_URL;
export const TEMPREPORTS_API_URL = w._env_.TEMPREPORTS_API_URL;

export const environment = {
  production: ENVIRONMENT === 'production',
  pushNotificationsEnabled: PUSH_NOTIFICATIONS_ENABLED === 'true',
  version: "1.0.0",
  dependencies: {
    domain: DOMAIN,
    checklist_api: { url: CHECKLIST_API_URL, domain: new URL(CHECKLIST_API_URL).origin },
    signature_api: { url: SIGNATURE_API_URL, domain: new URL(SIGNATURE_API_URL).origin },
    entity_api: { url: ENTITY_API_URL, domain: new URL(ENTITY_API_URL).origin },
    notifications_api: { url: NOTIFICATIONS_API_URL, domain: new URL(NOTIFICATIONS_API_URL).origin },
    termoobject_api: { url: TERMOOBJECT_API_URL, domain: new URL(TERMOOBJECT_API_URL).origin },
    signal_api: { url: SIGNAL_API_URL, domain: new URL(SIGNAL_API_URL).origin },
    security_api: { url: SECURITY_API_URL, domain: new URL(SECURITY_API_URL).origin },
    fileupload_api: { url: FILE_API_URL, domain: new URL(FILE_API_URL).origin },
    tempreports_api: { url: TEMPREPORTS_API_URL, domain: new URL(TEMPREPORTS_API_URL).origin },
  },
  clientId: APP_ID,
  redirectUrl: REDIRECT_URL,
  protectedResourceMap: new Map<string, Array<string>>([
    ["https://graph.microsoft.com", ["User.Read"]],
    [CHECKLIST_API_URL, ["api://c0570187-e61a-46da-b197-1007ee995a7d/Admin"]],
    [SIGNATURE_API_URL, ["api://c0570187-e61a-46da-b197-1007ee995a7d/Admin"]],
    [ENTITY_API_URL, ["api://c0570187-e61a-46da-b197-1007ee995a7d/Admin"]],
    [NOTIFICATIONS_API_URL, ["api://c0570187-e61a-46da-b197-1007ee995a7d/Admin"]],
    [TERMOOBJECT_API_URL, ["api://c0570187-e61a-46da-b197-1007ee995a7d/Admin"]],
    [SIGNAL_API_URL, ["api://c0570187-e61a-46da-b197-1007ee995a7d/Admin"]],
    [SECURITY_API_URL, ["api://c0570187-e61a-46da-b197-1007ee995a7d/Admin"]],
    [FILE_API_URL, ["api://c0570187-e61a-46da-b197-1007ee995a7d/Admin"]],
    [TEMPREPORTS_API_URL, ["api://c0570187-e61a-46da-b197-1007ee995a7d/Admin"]],
  ]),
};
