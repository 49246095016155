import { Button, Theme, } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import * as React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useChecklistContext } from "../ChecklistReactWrapper";
import {
  useRemoveAssignChecklist,
} from "../services/checklistService";
import { Site } from "../../entity/site";

type Props = {
  checklist: any;
  onDeleted: () => void;
  onCanceled: () => void;
};


const useStyles = makeStyles(({ }: Theme) =>
  createStyles({
    top: {
      flex: 1,
    },
    customSchedule: {
      paddingTop: "20px",
    },
  })
);

const DeleteChecklist = ({ checklist, onDeleted, onCanceled }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();


  //checklist info
  const { user, siteService } = useChecklistContext();
  const [tenantId, setTenantId] = useState<string>("");
  const [siteId, setSiteId] = useState<string>("");

  useEffect(() => {
    const subscribtion = siteService
      .getSite(user.siteId)
      .subscribe((site: Site) => {
        setTenantId(site.tenantId.toString());
        setSiteId(site.id.toString());
      });
    return () => subscribtion.unsubscribe();
  }, []);

  const handleDelete = () => {
    useRemoveAssignChecklist({
      checklistId: checklist.checklistId,
      assignedId: checklist.assignedId,
      siteId: siteId,
      tenantId: tenantId,
      type: "RemovedAssignedToSite",

    });
    onDeleted();
  };
  const handleCancel = () => {
    onCanceled();
  }
  return (
    <>
      <div className={classes.top}>
        <Button variant="outlined" onClick={handleDelete}>
          Slett
        </Button>
        <Button variant="outlined" onClick={handleCancel}>
          Avbryt
        </Button>
      </div>
    </>
  );
};

export default DeleteChecklist;
