import React, { FC, useEffect, useState } from "react";
import {
  InputAdornment,
  TextField,
  Theme,
} from "@mui/material";
import { makeStyles, createStyles } from '@mui/styles';
import { actions, ChecklistState } from "../services/reducer";
import { useDebouncedCallback } from "use-debounce";
import { useChecklistContext } from "../ChecklistReactWrapper";
import { useSignatureContext } from "../../signature-react/SignatureContext";
import { take } from "rxjs/operators";
import { useTranslation } from "react-i18next";
import { TemperatureSlider } from "../../react-components/TemperatureSlider";

const useStyles = makeStyles(({ spacing, palette }: Theme) =>
  createStyles({
    inputArea: {
      minWidth: "10rem",
    },
    tempInput: {
      //marginRight: spacing(2),
    },
    greenLimitButton: {
      backgroundColor: "#009605",
      fontWeight: "bolder",
      color: palette.primary.contrastText,
      "&:hover, &:focus": {
        color: palette.primary.main,
        fontWeight: "bolder",
      },
    },
    yellowLimitButton: {
      backgroundColor: "#ffbf00",
      fontWeight: "bolder",
      color: palette.primary.contrastText,
      "&:hover, &:focus": {
        color: palette.primary.main,
        fontWeight: "bolder",
      },
    },
    redLimitButton: {
      backgroundColor: "#a80000",
      fontWeight: "bolder",
      color: palette.primary.contrastText,
      "&:hover, &:focus": {
        color: palette.primary.main,
        fontWeight: "bolder",
      },
    },
    quickButtons: {
      display: "flex",
      justifyContent: "space-between",
    },
  })
);

const validTempNumberPattern = /^-?[0-9]+([,.][0-9])?[0-9]*$/g;

type Props = {
  dispatch: any;
  checklist: any;
  point: any;
  checked: boolean;
  state: any;
  disabled?: boolean;
  onChange: (isDeviation: boolean, value: number | string) => void;
  setActionDescription: (actionDescription: string) => void;
  setCommonDeviationCauses: (commonDeviationCauses: any[]) => void;
  setCommonDeviationHandles: (commonDeviationHandles: any[]) => void;
};

const ManualMeasurementTextfield: FC<Props> = ({
  dispatch,
  checklist,
  point,
  checked,
  state,
  onChange,
  setActionDescription,
  setCommonDeviationCauses,
  setCommonDeviationHandles,
  disabled
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { user, entityService } = useChecklistContext();
  const [measurement, setMeasurement] = useState("");
  const [isValid, setValid] = useState(true);
  const [rules, setRules] = useState<any>();

  const {
    state: { activeSignature },
  } = useSignatureContext();
  const signatureId = activeSignature?.id?.toString();

  const dispatchCommand = (value) => {
    dispatch({
      type: actions.TYPE_MEASUREMENT,
      payload: {
        checklistId: checklist.checklistId,
        tenantId: checklist.tenantId,
        assignedId: checklist.assignedId,
        pointId: point.id,
        userId: user.adalUser.oid,
        signatureId: signatureId,
        measurement: value,
        entityId: point.entityId,
        rules,
        siteId: user.siteId,
        fetch: false,
      },
    });

    if (value === "" && checked) {
      dispatch({
        type: actions.UNCHECK_POINT,
        payload: {
          checklistId: checklist.checklistId,
          tenantId: checklist.tenantId,
          assignedId: checklist.assignedId,
          pointId: point.id,
          userId: user.adalUser.oid,
          siteId: user.siteId,
          signatureId: signatureId,
        },
      });
    } else if (value !== "" && !checked) {
      dispatch({
        type: actions.CHECK_POINT,
        payload: {
          checklistId: checklist.checklistId,
          tenantId: checklist.tenantId,
          assignedId: checklist.assignedId,
          pointId: point.id,
          userId: user.adalUser.oid,
          siteId: user.siteId,
          signatureId: signatureId,
        },
      });
    }
  };

  useEffect(() => {
    let isSubscribed = true;
    entityService
      .getEntityWithoutSignals(point.entityId)
      .pipe(take(1))
      .subscribe((entityObject: any) => {
        if (!isSubscribed) return;
        if (entityObject.obj.termoObjectType?.commonDeviationCauses) {
          setCommonDeviationCauses(entityObject.obj.termoObjectType?.commonDeviationCauses);
        }
        if (entityObject.obj.termoObjectType?.commonDeviationHandles) {
          setCommonDeviationHandles(entityObject.obj.termoObjectType?.commonDeviationHandles);
        }
        if (
          entityObject.obj.termoObjectType &&
          entityObject.obj.termoObjectType.rules.GREEN
        ) {
          setRules({
            ...entityObject.obj.termoObjectType.rules.GREEN,
            green: entityObject.obj.termoObjectType.rules.GREEN,
            yellow: entityObject.obj.termoObjectType.rules.YELLOW,
            red: entityObject.obj.termoObjectType.rules.RED,
          });
        } else {
          setRules(entityObject.obj.rules.temperature);
        }
      });
    return () => {
      isSubscribed = false;
    };
  }, []);

  useEffect(() => {
    if (rules) {
      dispatch({
        type: actions.TYPE_MEASUREMENT,
        payload: {
          checklistId: checklist.checklistId,
          tenantId: checklist.tenantId,
          assignedId: checklist.assignedId,
          pointId: point.id,
          userId: user.adalUser.oid,
          signatureId: signatureId,
          measurement,
          entityId: point.entityId,
          rules,
          siteId: user.siteId,
          fetch: true,
        },
      });
    }
  }, [rules]);

  useEffect(() => {
    let mounted = true;
    if (!mounted) return;
    return () => {
      mounted = false;
    };
  }, [signatureId]);
  const minLimit = rules ? parseInt(rules.min) : null;
  const maxLimit = rules ? parseInt(rules.max) : null;

  const showQuickButtons =
    state.state === ChecklistState.IN_PROGRESS && rules;
  useEffect(() => {
    state.measurements[point.id] === undefined
      ? setMeasurement("")
      : setMeasurement(state.measurements[point.id].value || "");
  }, [state.measurements[point.id]]);

  const inRangeOfRule = (ruleKey: string, valueText: string) => {
    if ((rules[ruleKey]?.min != null) || (rules[ruleKey]?.max != null)) {
      return isInRange(rules[ruleKey]?.min, rules[ruleKey]?.max, parseFloat(valueText));
    }
  }

  const isOutOfRange = (min: number, max: number, value: number) => {
    if (min && (max == null)) return value < min;
    if ((min == null) && max) return value > max;
    return value < min || value > max;
  }

  const isInRange = (min: number, max: number, value: number) => {
    if (min && (max == null)) return value > min;
    if ((min == null) && max) return value < max;
    return value > min && value < max;
  }

  const evaluateDeviation = (valueText) => {
    if (inRangeOfRule('green', valueText)) {
      setActionDescription(rules.green?.actionDescription);
    } else if (inRangeOfRule('yellow', valueText)) {
      setActionDescription(rules.yellow?.actionDescription);
      return true;
    } else if (inRangeOfRule('red', valueText)) {
      setActionDescription(rules.red?.actionDescription);
      return true;
    } else if ((minLimit == null) && (maxLimit == null)) {
      return false
    } else {
      return isOutOfRange(minLimit, maxLimit, parseFloat(valueText));
    }
  };

  useEffect(() => {
    setValid(
      measurement === "" ||
      !!(
        !!measurement && measurement?.toString().match(validTempNumberPattern)
      )
    );
  }, [measurement]);

  // This is used for checklist deviation validation
  // The user can't complete checklist if a deviation is not handled
  // This coveres the case in which the user refreshes the page
  useEffect(() => {
    measurement && rules && onChange(evaluateDeviation(measurement), measurement);
  }, [rules])

  const handleMesurment = (newValue) => {
    setMeasurement(newValue);
    handleMeasurementDeviation(newValue);
  };
  const handleMeasurementChange = (e) => {
    const newValue = e.target.value;
    handleMesurment(newValue);
  };

  const handleMeasurementDeviation = useDebouncedCallback((e) => {
    if (isValid) {
      dispatchCommand(e);
      onChange(evaluateDeviation(e), e);
    }
  }, 1000);

  const hasEntityTypeRules = rules?.green && rules?.yellow && rules?.red;
  return (
    <span
      onClick={(event) => {
        event.stopPropagation();
      }}
      onFocus={(event) => {
        event.stopPropagation();
      }}
      className={classes.inputArea}
    >
      <TextField
        className={classes.tempInput}
        id="outlined-required"
        label="Temperatur"
        variant="outlined"
        type="text"
        value={measurement || ""}
        disabled={state.state !== ChecklistState.IN_PROGRESS || disabled}
        onChange={handleMeasurementChange}
        InputProps={{
          endAdornment: <InputAdornment position="end">&deg;C</InputAdornment>,
        }}
        error={!isValid}
        helperText={
          isValid
            ? `Min: ${rules?.min}, Max: ${rules?.max}`
            : "Please enter a valid number."
        }
        
      />
      <div className={classes.quickButtons}>
        {showQuickButtons && hasEntityTypeRules && (
          <TemperatureSlider
            value={measurement === "" ? rules?.min : Math.round(parseFloat(measurement))}
            onChange={v => handleMesurment(v.toString())}
            rules={rules}
            disabled={disabled}
          />
        )}
      </div>
    </span>
  );
};

export default ManualMeasurementTextfield;
