import { Component, OnInit, Input } from "@angular/core";
import { FormGroup, Validators, FormBuilder, FormArray } from "@angular/forms";
import { IEntitySpecificsFormHandler } from "../site.specifics.form.handler";
import { Entity } from "../entity";
import { TermoObject } from "../termo-object";
import { Guid } from "../../system/guid";
import { Moment } from "moment";
import { EntityService } from "../entity.service";
import { Observable } from "rxjs";
import { CouldFoodStorage } from "../CouldFoodStorage";
import { SignalTagFunction } from "../../signals/vevent";

@Component({
  selector: "app-thermo-form",
  templateUrl: "./thermo-form-component.html",
  styleUrls: ["./thermo-form-component.scss"]
})
export class ThermoFormComponent implements OnInit, IEntitySpecificsFormHandler {
  public rules: FormGroup;

  public newLimit: FormGroup;
  public showNewException: boolean = false;
  
  constructor(
    public formBuilder: FormBuilder,
    private entityService: EntityService
  ) {}

  ngOnInit() {
    this.updateForm();
  }

  public setupForEntity(entity: Entity, updateForm: boolean) {
    if (updateForm) {
      this.updateForm();
    }
    this.patchForm(entity);
  }

  private updateForm() {
    // Rules
    var numberValidators = [
      Validators.required,
      Validators.min(-100),
      Validators.max(100)
    ];

    this.rules.addControl(
      "min",
      this.formBuilder.control("", numberValidators)
    );
    this.rules.addControl(
      "max",
      this.formBuilder.control("", numberValidators)
    );
    this.rules.addControl("limits", this.formBuilder.array([])); //TODO Will this work??

    this.newLimit = this.formBuilder.group({
      min: ["", numberValidators],
      max: ["", numberValidators],
      from: this.formBuilder.group({
        hour: ["", Validators.pattern("([0-1][0-9])|(2[0-4])")],
        minute: ["", Validators.pattern("([0-5][0-9])")]
      }),
      to: this.formBuilder.group({
        hour: ["", Validators.pattern("([0-1][0-9])|(2[0-4])")],
        minute: ["", Validators.pattern("([0-5][0-9])")]
      })
    });

    // Custom fields
    this.rules.addControl(
      "make",
      this.formBuilder.control("")
    );
    this.rules.addControl(
      "model",
      this.formBuilder.control("")
    );
  }

  private patchForm(entity: Entity) {
    let storage = new CouldFoodStorage(entity);
    var limits = storage.getLimits();
    limits.forEach(l => {
      this.limits.push(this.formBuilder.group({ ...l }));
    });

    this.rules.patchValue({
      min: entity.obj.rules.min,
      max: entity.obj.rules.max,
      make: entity.obj.make,
      model: entity.obj.model
    });
  }

  getEntityObject(entityId: string, tenantId: any, createdOn: Moment): any {
    var rules: any = {
      min: null,
      max: null
    };
    let make = "";
    let model ="";

    return new TermoObject(
      Guid.newGuid().toString(),
      entityId,
      make,
      model,
      tenantId,
      rules,
      createdOn
    );
  }

  updateEntityOrReturnErrorMessageIfInvalid(
    entity: Entity,
    formGroup: any
  ): string | null {
    // Rules
    var min = formGroup.min;
    var max = formGroup.max;

    if (min > max) {
      return "Min kan ikke være større enn maks.";
    }

    // entity.obj.rules = formGroup;
    entity.obj.rules = {
      min: min,
      max: max,
      limits: formGroup.limits
    };

    var limits = [{ min, max }, ...formGroup.limits];
    for (let limit of limits) {
      var lmin = parseFloat(limit.min);
      var lmax = parseFloat(limit.max);
      if (lmin > lmax) {
        return "Min kan ikke være større enn maks.";
      }
    }

    // Custom fields
    entity.obj.make = formGroup.make;
    entity.obj.model = formGroup.model;

    // Everything ok
    return null;
  }

  getEntityType(): string {
    return TermoObject.typeName;
  }

  getSignalTagFunctions(): SignalTagFunction[] {
    return [SignalTagFunction.Temp];
  }

  putObject(thermoObject: any): Observable<object> {
    return this.entityService.putThermoObject(thermoObject.id, thermoObject);
  }

  postObject(thermoObject: any): Observable<object> {
    return this.entityService.postThermoObject(thermoObject);
  }

  get limits(): FormArray {
    let limits = this.rules.get("limits") as FormArray;
    return limits;
  }

  addLimits() {
    this.limits.push(
      this.formBuilder.group({
        ...this.newLimit.value
      })
    );
    this.newLimit.reset();
  }
  delete(index) {
    var adressList = this.limits.removeAt(index);
    this.limits.markAsDirty();
  }
}
