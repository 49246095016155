export function setDefaultRoute(route: string) {
  localStorage.setItem('defaultRoute', route);
}

export function getDefaultRoute() {
  const defaultRoute = localStorage.getItem('defaultRoute');
  if(!defaultRoute || !defaultRoute.startsWith('/'))
    return null;
  return defaultRoute;
}

export const urlToScopeKey = (urlString: string) => {
  const url = new URL(urlString);
  const host = url.origin;
  const rootPath = url.pathname.split('/')[1] || '';
  return `${host}/${rootPath}`
}