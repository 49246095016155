import * as React from "react";
import { useState } from "react";
import { Dialog } from "@mui/material";
import { makeStyles, createStyles } from '@mui/styles';

type Props = {
  src: string;
};

const useStyles = makeStyles(() =>
  createStyles({
    thumbnail: {
      cursor: "pointer",
    },
  })
);

const ImageModal = ({ src }: Props) => {
  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(false);

  const handleOpen = () => {
    setOpen(!open);
  };

  return (
    <>
      <img
        src={src}
        width="200"
        onClick={handleOpen}
        className={classes.thumbnail}
      />
      <Dialog open={open} onClose={handleOpen}>
        <img src={src} />
      </Dialog>
    </>
  );
};

export default ImageModal;
