import { Button, Grid, IconButton, Theme, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { Site } from "../../../entity/site";
import { SupportedMediaFormatsType } from "../../../react-components/FileUpload/FileUpload";
import PageHeader from "../../../react-components/PageHeader";
import { useChecklistContext } from "../../ChecklistReactWrapper";
import {
  BASE_CHECKLIST_MEDIA_PATH,
  fetchChecklists,
  getLabelTypes,
  mapAllChecklists,
  mapChecklist,
  RESERVED_TAGS,
  useAddMediaToChecklist,
  useAssociateChecklistPoints,
  useGetAssignedChecklistComposite,
  useSiteChecklists,
} from "../../services/checklistService";
import SelectOption from "../cron-definition/SelectOption";
import AssignChecklistForm from "./AssignChecklistForm";
import AttachMediaDialog from "./AttachMediaDialog";
import ChecklistSettingsMenu from "./ChecklistSettingsMenu";
import EditPointCard from "./edit-point/EditPointCard";
import EditChecklistDescription from "./EditChecklistDescription";
import EditChecklistDeviationLevel, {
  getDeviationLevelHandlingTag,
} from "./EditChecklistDeviationLevel";
import EditChecklistTagsDialog from "./EditChecklistTagsDialog";
import EditSchedule from "./EditSchedule";
import EditIcon from "@mui/icons-material/Edit";
import { createStyles, makeStyles } from "@mui/styles";
import Loading from "../../../react-components/Loading";
import SectionContainer from "./SectionContainer";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import DescriptionIcon from "@mui/icons-material/Description";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";

const useStyles = makeStyles(({ spacing, palette }: Theme) =>
  createStyles({
    root: {},
    editIcon: {
      color: palette.primary.main,
    },
  })
);

const EditChecklist = () => {
  const { t } = useTranslation(); //translation
  const classes = useStyles();

  let usePar = useParams();
  let { assignedId }: any = usePar;

  const { user, siteService } = useChecklistContext();

  const [
    { data: checklistData, error: checklistError, loading: checklistLoading },
    refetchCompositeChecklist,
  ] = useGetAssignedChecklistComposite(assignedId);

  const [addToggled, setAddToggled] = React.useState<boolean>(false);
  const [addSiteSpecific, setAddSiteSpecific] = React.useState<boolean>(false);

  const [labelTypes, setLabelTypes] = React.useState<string[]>([]);

  const [isAttachMediaDialogOpen, setAttachMediaDialogOpen] =
    React.useState<boolean>(false);
  const [isEditChecklistTagsDialogOpen, setEditChecklistTagsDialogOpen] =
    React.useState<boolean>(false);
  const [
    isEditChecklistDeviationHandlingLevelOpen,
    setEditChecklistDeviationHandlingLevelOpen,
  ] = React.useState<boolean>(false);

  const [checklistEvents, setChecklistEvents] = React.useState<any[]>(null);

  const [showPointsMode, setShowPointsMode] =
    React.useState<string>("sitePoints");

  const [checklist, setChecklist] = React.useState(null);

  const [includedFromChecklistsMap, setIncludedFromChecklistsMap] =
    React.useState<{ [key: string]: any }>({});

  useEffect(() => {
    checklistEvents && setChecklist(mapChecklist(checklistEvents, assignedId));
  }, [checklistEvents]);

  useEffect(() => {
    checklistData && setChecklistEvents(checklistData);
  }, [checklistData]);

  const [site, setSite] = React.useState<Site>(null);

  useEffect(() => {
    checklistData && refetchCompositeChecklist();
    getLabelTypes().then((labelTypes) => {
      const labelTypesWithValue = labelTypes.filter((labelType) => labelType !== null && labelType !== undefined);
      setLabelTypes(labelTypesWithValue);
    });
    
    siteService.getSite(user.siteId).subscribe((site: Site) => {
      setSite(site);
    });
  }, []);

  useEffect(() => {
    if (!checklist?.points) return;
    const includedFromChecklistIds = [
      ...new Set<string>(
        checklist.points
          .filter((p) => p.isPointIncluded)
          .map((p) => p.includedFromChecklistId)
      ),
    ];
    includedFromChecklistIds.length &&
      fetchChecklists(includedFromChecklistIds).then((checklistData) => {
        const mappedChecklistData = mapAllChecklists(checklistData);
        const checklistMap = {};
        includedFromChecklistIds.forEach((id) => {
          checklistMap[id] = mappedChecklistData.find(
            (e) => e.checklistId === id
          );
        });
        setIncludedFromChecklistsMap(checklistMap);
      });
  }, [checklist]);

  const [isEdit, setEdit] = React.useState<boolean>(false);
  const [isSiteEdit, setSiteEdit] = React.useState<boolean>(false);

  if ((!checklistData && checklistLoading) || !checklist) {
    return <Loading />;
  }
  if (checklistError || !checklist) {
    return <p>{t("Error")}</p>;
  }

  const updateChecklistEvents = (events: any[]) => {
    setChecklistEvents([...checklistEvents, ...events]);
  }
   
  return (
    <div>
      <PageHeader
        title={t("editChecklist.pageTitle")}
        to={"/checklist/dashboard/overview"}
      />
      <SectionContainer
        title={t("editChecklist.sections.checklistInfo")}
        Icon={<DescriptionIcon />}
      >
        <Grid container spacing={2} sx={{ marginTop: "0.6em" }}>
          <Grid item xs={11}>
            <EditChecklistDescription
              isEdit={isEdit}
              isSiteEdit={isSiteEdit}
              assignedId={assignedId}
              tenantId={site?.tenantId?.toString()}
              checklist={checklist}
              onSuccess={(describedEvents) => {
                updateChecklistEvents(
                  describedEvents.map((describedEvent) => describedEvent.data)
                );
                setEdit(false);
                setSiteEdit(false);
              }}
              onCancel={() => {
                setEdit(false);
                setSiteEdit(false);
              }}
            />
          </Grid>
          <Grid item xs={1}>
            <Grid
              style={{
                marginBottom: "1em",
                marginRight: "1em",
                justifyContent: "flex-end",
              }}
              container
              component={"div"}
            >
              <ChecklistSettingsMenu
                onEditClick={() => setEdit(true)}
                onEditSiteClick={() => setSiteEdit(true)}
                onAttachMediaClick={() => setAttachMediaDialogOpen(true)}
                onEditTagsClick={() => setEditChecklistTagsDialogOpen(true)}
                onEditDeviationHandlingLevelClick={() =>
                  setEditChecklistDeviationHandlingLevelOpen(true)
                }
                tagPriorityProps={{
                  tag: checklist?.tags?.find(
                    (t) => t === "high" || t === "low"
                  ),
                  checklist: checklist,
                  tenantId: site?.tenantId?.toString(),
                  onSuccess: (tagEvents) => {
                    updateChecklistEvents(
                      tagEvents.map((tagEvent) => tagEvent.data)
                    );
                  },
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Typography variant="h4">
          {t("editChecklist.deviationHandlingLevel")}:{" "}
          {/* {getDeviationLevelHandlingTag(checklist?.tags ?? [])} */}
          {`${t(
            `checklist.${getDeviationLevelHandlingTag(checklist?.tags ?? [])}`
          )}`}
          <IconButton
            edge="end"
            onClick={() => setEditChecklistDeviationHandlingLevelOpen(true)}
          >
            <EditIcon className={classes.editIcon} />
          </IconButton>
        </Typography>
      </SectionContainer>

      <AttachMediaDialog
        open={isAttachMediaDialogOpen}
        onSubmit={async (
          mediaId: string,
          extension: SupportedMediaFormatsType
        ) => {
          const mediaEvent = await useAddMediaToChecklist({
            checklistId: checklist.checklistId,
            tenantId: site.tenantId.toString(),
            imagePath: `${BASE_CHECKLIST_MEDIA_PATH}/${mediaId}${extension}`,
          });
          updateChecklistEvents([mediaEvent].map((e) => e.data));
        }}
        onClose={() => setAttachMediaDialogOpen(false)}
      />
      <EditChecklistTagsDialog
        checklist={checklist}
        tenantId={site?.tenantId?.toString()}
        open={isEditChecklistTagsDialogOpen}
        existingTags={checklist?.tags?.filter(
          (t) => !RESERVED_TAGS.includes(t)
        )}
        onSuccess={(tagEvents) => {
          updateChecklistEvents(tagEvents.map((tagEvent) => tagEvent.data));
        }}
        onClose={() => setEditChecklistTagsDialogOpen(false)}
      />
      <EditChecklistDeviationLevel
        checklist={checklist}
        tenantId={site?.tenantId?.toString()}
        open={isEditChecklistDeviationHandlingLevelOpen}
        existingTags={checklist?.tags}
        onSuccess={(tagEvents) => {
          updateChecklistEvents(tagEvents.map((tagEvent) => tagEvent.data));
        }}
        onClose={() => setEditChecklistDeviationHandlingLevelOpen(false)}
      />
      <SectionContainer
        title={t("editChecklist.sections.schedule")}
        Icon={<AccessTimeIcon />}
      >
        <EditSchedule checklist={checklist} onSubmit={updateChecklistEvents} />
      </SectionContainer>

      <SectionContainer
        title={t("editChecklist.sections.points")}
        Icon={<FormatListBulletedIcon />}
      >
        <Grid
          style={{
            marginBottom: "1em",
            marginRight: "1em",
            justifyContent: "flex-end",
          }}
          container
          component={"div"}
        >
          <SelectOption
            value={showPointsMode}
            onChange={setShowPointsMode}
            items={[
              {
                label: t("editChecklist.editPoint.show.checklist"),
                value: "sitePoints",
              },
              {
                label: t("editChecklist.editPoint.show.checklistAll"),
                value: "checklistPoints",
              },
              {
                label: t("editChecklist.editPoint.show.template"),
                value: "points",
              },
              {
                label: t("editChecklist.editPoint.show.templateAll"),
                value: "templatePoints",
              },
            ]}
          />
        </Grid>
        {checklist[showPointsMode]?.map((point, index) => (
          <EditPointCard
            availableLabelTypes={labelTypes}
            point={point}
            includedFromChecklistsMap={includedFromChecklistsMap}
            templatePointsOnly={showPointsMode === "templatePoints"}
            site={site}
            key={point.id}
            isSiteSpecific={point.isSiteSpecific}
            checklist={checklist}
            onSave={updateChecklistEvents}
          />
        ))}
        {addToggled ? (
          <EditPointCard
            availableLabelTypes={labelTypes}
            site={site}
            includedFromChecklistsMap={includedFromChecklistsMap}
            isAdd={true}
            isSiteSpecific={addSiteSpecific}
            onSave={(events: any[]) => {
              setAddToggled(false);
              updateChecklistEvents(events);
            }}
            onCancel={() => setAddToggled(false)}
            checklist={checklist}
          />
        ) : (
          <>
            <Button
              style={{ marginLeft: "0.6em" }}
              variant="contained"
              onClick={() => {
                setAddToggled(true);
                setAddSiteSpecific(false);
              }}
            >
              {t("editChecklist.editPoint.addNew")}
            </Button>
            <Button
              style={{ marginLeft: "0.6em" }}
              variant="contained"
              onClick={() => {
                setAddToggled(true);
                setAddSiteSpecific(true);
              }}
            >
              {t("editChecklist.editPoint.addNewToSite")}
            </Button>
          </>
        )}
        <AssignChecklistForm
          label={t("editChecklist.editPoint.includePoints")}
          onSubmit={async (e) => {
            await useAssociateChecklistPoints({
              checklistId: checklist.checklistId,
              referenceChecklistId: e.checklistId,
              assignedId,
              tenantId: site.tenantId.toString(),
            });
            refetchCompositeChecklist();
          }}
        />
      </SectionContainer>
    </div>
  );
};

export default EditChecklist;
