<div>
    <button mat-menu-item *ngIf="user$ | async">
      {{ (user$ | async).adalUser.name }}
    </button>
    <button mat-menu-item (click)="updateCache()">
      <span class="personColor">{{"Version" | i18next}}: {{version}} - {{"Update app" | i18next }}</span>
    </button>
    <button mat-menu-item (click)="logout()">
      <span class="personColor">{{"Log Out" | i18next }}</span>
    </button>
    <button mat-menu-item (click)="login()">
      <span class="personColor">{{"Log In" | i18next }}</span>
    </button>
    <button mat-menu-item (click)="clear()">
      <span class="personColor">{{"Clear site data" | i18next }}</span>
    </button>

</div>
