import axios from "axios";
import { environment } from "../environments/environment";

const BASE_NOTIFICATIONS_URL = environment.dependencies.notifications_api.url + "/api";

export function pushNotificationsEnabled() {
  if(!didUserConfigurePushNotifications())
    return environment.pushNotificationsEnabled;
  return didUserEnablePushNotifications();
}

export function didUserConfigurePushNotifications() {
  return localStorage.getItem('pushNotificationsEnabled') !== null;
}

export function didUserEnablePushNotifications() {
  return localStorage.getItem('pushNotificationsEnabled') === 'true';
}

export function sendSubscription(tenantId: string, siteId: string, userId: string, subscriptionObject: any): Promise<any> {
  return axios.post(`${BASE_NOTIFICATIONS_URL}/notifications/subscriptions/tenants/${tenantId}/sites/${siteId}`, {
    userId,
    pushEndpoint: subscriptionObject.endpoint,
    p256dh: subscriptionObject.keys.p256dh,
    auth: subscriptionObject.keys.auth
  })
}