<div class="deviation-list">
  <div class="deviation-list__heading">
    <a class="content__heading__go-back" [routerLink]="['/']">
      <i class="material-icons">home</i>
    </a>
    <h1>{{ "Latest report" | i18next }}</h1>
  </div>
  <mat-card class="deviation-list__deviations">
    <div  class="deviation-list__deviations__item" >
      <div *ngFor="let item of measurements">
        <div class="row">
          <span class="deviation-list__deviations__item__name">
            <i *ngIf="item.isManual" class="material-icons icon-color">
              person
            </i>
            <i *ngIf="!item.isManual" class="material-icons icon-color">
              android
            </i>
            {{item.entityName}}
          </span>
          
          <div class="deviation-list__deviations__item__device-info">
            <span *ngIf="item.action" (click)="showAction(item)" class="show-action material-icons icon-color">
              info
            </span>
            <span class="temperature danger">
              {{ item.value }}
            </span>
            <i  *ngIf="item.isDeviation" class="material-icons icon-color">
              warning
            </i>   
            <i *ngIf="!item.isDeviation" class="material-icons icon-color">
              done
            </i>
          </div>
        </div>
      </div>
    </div>
  </mat-card>
</div>
    