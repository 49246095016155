import {
  FormControlLabel,
  Switch,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useTagChecklist, useUntagChecklist } from "../../services/checklistService";

export type Props = {
  tag: string;
  checklist: any;
  tenantId: string;
  onSuccess: (tagEvents: any[]) => void;
};

const TagChecklistPriority = ({ tag, checklist, tenantId, onSuccess }: Props) => {

  const { t } = useTranslation(); //translation

  const [disabled, setDisabled] = React.useState(false);

  return (
    <FormControlLabel
      control={
        <Switch
          checked={tag === 'high'}
          onChange={async (_, value) => {
            setDisabled(true);
            setDisabled(false);
            onSuccess(await Promise.all([
              useUntagChecklist({
                checklistId: checklist.checklistId,
                tenantId,
                tag: value ? 'low' : 'high'
              }),
              useTagChecklist({
                checklistId: checklist.checklistId,
                tenantId,
                tag: value ? 'high' : 'low'
              })
            ]));
          }}
          name="priority"
          color="primary"
          disabled={disabled}
        />
      }
      label={t("High Priority") as string}
    />
  );
};

export default TagChecklistPriority;
