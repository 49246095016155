import { Box, Tab, Tabs, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Route, useLocation } from "react-router";
import { Link, Routes } from "react-router-dom";
import { createRoleChecker, TmeshRoles } from "../../auth-utils";
import PageHeader from "../../react-components/PageHeader";
import { useChecklistContext } from "../ChecklistReactWrapper";
import { useSiteChecklists } from "../services/checklistService";
import ChecklistCalendar from "./ChecklistCalendar";
import ChecklistOverviewList from "./ChecklistOverviewList";
import DeviationList from "./deviations/DeviationList";
import Schedules from "./Schedules";
import { TabLabel } from "./TabsLabel";
import { Site } from "../../entity/site";
import { useTenantService } from "../../tenantService";
import { take } from "rxjs/operators";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  checklistMsg: {
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    marginTop: theme.spacing(4),
  },
}));

const ChecklistPage = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [value, setValue] = useState(0);
  const { user, siteService, tenantId } = useChecklistContext();
  const location = useLocation();

  const [site, setSite] = useState<Site>();
  const [tenant, setTenant] = useState<any>();

  React.useEffect(() => {
    siteService
      .getSite(user.siteId)
      .pipe(take(1))
      .subscribe((res: Site) => {
        setSite(res);
      });
  }, []);

  const tenantService = useTenantService();

  React.useEffect(() => {
    if (!tenantId) return;
    tenantService
      .getOne(tenantId.toString())
      .then((res) => {
        setTenant(res);
      });
  }, [tenantId]);

  const showLowPriorityRoutinesTabConfig = (site?.showLowPriorityRoutinesTab ?? tenant?.showLowPriorityRoutinesTab);
  const showLowPriorityRoutinesTab = showLowPriorityRoutinesTabConfig == null ? true : showLowPriorityRoutinesTabConfig;

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const [{ data, error, loading }] = useSiteChecklists(user.siteId);

  const [numberOfHighPriority, setNumberOfHighPriority] = useState(0);
  const [numberOfLowPriority, setNumberOfLowPriority] = useState(0);

  if (loading) {
    return <p>{t("Loading")}</p>;
  }

  if (error) {
    return <p>{t("Error")}</p>;
  }
  const hasRole = createRoleChecker(user?.adalUser?.roles);
  const isSupervisorUser = hasRole(TmeshRoles.Supervisor) || hasRole(TmeshRoles.Admin);


  return (
    <>
      <PageHeader title={t("checklist.checklistsTitle")} />
      <Tabs
        value={location.pathname}
        textColor="primary"
        indicatorColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        allowScrollButtonsMobile
      >
        <Tab
          label={
            <TabLabel badgeNumber={numberOfHighPriority}>
              {t("checklist.highPriorityRoutinesTab")}
            </TabLabel>
          }
          component={Link}
          to={`/checklist/dashboard/high`}
          value={`/checklist/dashboard/high`}
        />
        {showLowPriorityRoutinesTab && <Tab
          label={
            <TabLabel badgeNumber={numberOfLowPriority}>
              {t("checklist.lowPriorityRoutinesTab")}
            </TabLabel>
          }
          component={Link}
          to={`/checklist/dashboard/low`}
          value={`/checklist/dashboard/low`}
        />}
        {/* <Tab
          label={<TabLabel>{t("checklist.reportsTab")}</TabLabel>}
          component={Link}
          to={`/checklist/dashboard/reports`}
          value={`/checklist/dashboard/reports`}
        />*/}
        {isSupervisorUser && (
          <Tab
            label={
              <TabLabel>{t("checklist.temperatureDeviationsTab")}</TabLabel>
            }
            component={Link}
            to={`/checklist/dashboard/deviations/temperature?tag=red&tag=yellow`}
            value={`/checklist/dashboard/deviations/temperature`}
          />
        )}
        {isSupervisorUser && (
          <Tab
            label={<TabLabel>{t("checklist.deviationsTab")}</TabLabel>}
            component={Link}
            to={`/checklist/dashboard/deviations?tag=red&tag=yellow`}
            value={`/checklist/dashboard/deviations`}
          />
        )}
        {/* {isSupervisorUser && (
          <Tab
            label={<TabLabel>{t("checklist.commentsTab")}</TabLabel>}
            component={Link}
            to={"/checklist/dashboard/comments"}
            value={"/checklist/dashboard/comments"}
          />
        )} */}
        {isSupervisorUser && (
          <Tab
            label={<TabLabel>{t("checklist.overviewTab")}</TabLabel>}
            component={Link}
            to={`/checklist/dashboard/overview`}
            value={`/checklist/dashboard/overview`}
          />)}
        {isSupervisorUser && (
          <Tab
            label={<TabLabel>{t("checklist.calendarTab")}</TabLabel>}
            component={Link}
            to={`/checklist/dashboard/calendar`}
            value={`/checklist/dashboard/calendar`}
          />
        )}
      </Tabs>
      <Box p={3}>
        <Routes>
          <Route
            path={`high`}
            element={
              <Schedules
                tag="high"
                onNumberOfHighPriorityTasksChange={setNumberOfHighPriority}
                onNumberOfLowPriorityTasksChange={setNumberOfLowPriority}
              />
            }
          />
          <Route
            path={`low`}
            element={
              <Schedules
                tag="low"
                onNumberOfHighPriorityTasksChange={setNumberOfHighPriority}
                onNumberOfLowPriorityTasksChange={setNumberOfLowPriority}
              />
            }
          />
          {/* <Route path={`reports`} element={<ChecklistReportList />} /> */}
          {isSupervisorUser && (
            <Route
              path={`deviations/*`}
              element={<DeviationList checklistData={data} />}
            />
          )}
          {isSupervisorUser && (
            <Route path={`overview`} element={<ChecklistOverviewList />} />
          )}
          {isSupervisorUser && (
            <Route path={`calendar`} element={<ChecklistCalendar />} />
          )}
          {/* {isSupervisorUser && (
            <Route
              path={`comments`}
              element={<CommentList checklistData={data} />}
            />
          )} */}
        </Routes>
      </Box>
    </>
  );
};

export default ChecklistPage;
