import * as React from "react";
import { FormControl, Select, MenuItem, Button, Typography, Divider, Grid, Box } from "@mui/material";
import { makeStyles } from '@mui/styles';
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  header: {
    marginBottom: 16,
  },
  divider: {
    marginBottom: 16,
  },
  formControl: {
    minWidth: 200,
  },
  saveButton: {
    marginTop: 16,
    alignSelf: "flex-start",
  },
});

const LanguageSelector = () => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = React.useState(i18n.language);

  const handleLanguageChange = (language: string) => {
    setSelectedLanguage(language);
  };

  const handleSave = () => {
    i18n.changeLanguage(selectedLanguage);
    window.location.reload();
  };

  return (
    <div className={classes.root}>
      <Typography className={classes.header}>
        Velg språk / Select language
      </Typography>
      <Grid container spacing={2} mt={2}>
        <Grid item xs={12} sm={8}>
          <FormControl fullWidth className={classes.formControl}>
            <Select
              value={selectedLanguage}
              onChange={(e) => handleLanguageChange(e.target.value as string)}
              renderValue={(value) => {
                return value === "no" ? Norwegian : English;
              }}
            >
              <MenuItem value="no">
                {Norwegian}
              </MenuItem>
              <MenuItem value="en">
                {English}
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Box mt={2} mb={2}
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "flex-end",
          overflow: "hidden",
        }}
      >
        <Button
          onClick={handleSave}
          variant="contained"
          color="primary"
          className={classes.saveButton}
        >
          {t("Save")}
        </Button>
      </Box>
      <Divider className={classes.divider} />
    </div>
  );
};

export default LanguageSelector;

const Norwegian = <div
  style={{
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    overflow: "hidden",
  }}
>

  <span>Norsk / Norwegian</span>
  <span style={{
    height: "30px",
    width: "30px",
  }}>
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 30 16" width="30" height="30">
      <title>Flag of Norway</title>
      <rect width="30" height="16" fill="#ba0c2f" />
      <path d="M0,8h30M8,0v16" stroke="#fff" strokeWidth="4" />
      <path d="M0,8h30M8,0v16" stroke="#00205b" strokeWidth="2" />
    </svg>
  </span>
</div>;

const English = <div
  style={{
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    overflow: "hidden",
  }}
>
  <span>English / Engelsk</span>
  <span style={{
    height: "30px",
    width: "30px",
  }}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 30" width="30" height="32">
      <clipPath id="s">
        <path d="M0,0 v30 h60 v-30 z" />
      </clipPath>
      <clipPath id="t">
        <path d="M30,15 h30 v15 z v15 h-30 z h-30 v-15 z v-15 h30 z" />
      </clipPath>
      <g clipPath="url(#s)">
        <path d="M0,0 v30 h60 v-30 z" fill="#012169" />
        <path d="M0,0 L60,30 M60,0 L0,30" stroke="#fff" strokeWidth="6" />
        <path d="M0,0 L60,30 M60,0 L0,30" clipPath="url(#t)" stroke="#C8102E" strokeWidth="4" />
        <path d="M30,0 v30 M0,15 h60" stroke="#fff" strokeWidth="10" />
        <path d="M30,0 v30 M0,15 h60" stroke="#C8102E" strokeWidth="6" />
      </g>
    </svg>
  </span>
</div>