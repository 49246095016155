import { Guid } from "../system/guid";
import { Moment } from "moment";
import * as moment from "moment";
import { SignalEvent } from "./vevent";
import i18next from "i18next";

export class DoorEvent extends SignalEvent {
  constructor(
    id: Guid,
    type: string,
    sourceId: string,
    timestamp: Moment,
    public value: DoorEventValue
  ) {
    super(id, type, sourceId, timestamp);
  }

  public toString(): string {
    return `Value: ${this.value}`;
  }
  public static deSerialize(e: any): DoorEvent {
    return new DoorEvent(
      e.id,
      e.type,
      e.sourceId,
      moment(e.timestamp),
      e.value
    );
  }
}

export class CleanEvent extends SignalEvent {
  constructor(id: Guid, type: string, sourceId: string, timestamp: Moment) {
    super(id, type, sourceId, timestamp);
  }

  public static deSerialize(e: any): CleanEvent {
    return new CleanEvent(e.id, e.type, e.sourceId, moment(e.timestamp));
  }

  public restObject(): any {
    //TODO Because of problems with serializing Guid. Find a better way?
    return {
      id: this.id.toString(),
      type: this.type,
      sourceId: this.sourceId,
      timestamp: this.timestamp,
    };
  }
}

export enum DoorEventValue {
  Close = 0,
  Open = 1,
}

export function printDoorEventValue(value: DoorEventValue): string {
  switch (value) {
    case DoorEventValue.Close:
      return i18next.t("Closed");
    case DoorEventValue.Open:
      return i18next.t("Open");
    default:
      return `Unknown: ${value}`;
  }
}
