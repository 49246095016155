import { Box, Button, IconButton, TextField, Theme } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import { Field, Form, Formik } from "formik";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useChecklistContext } from "../checklist-react/ChecklistReactWrapper";
import { useSiteChecklists } from "../checklist-react/services/checklistService";
import { useSignatureContext } from "./SignatureContext";
import { Guid } from "../system/guid";
import { useEffect, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import clsx from "clsx";
import {
  Autocomplete,
  AutocompleteRenderInputParams,
} from "formik-material-ui";
import KeyboardIcon from "@mui/icons-material/Keyboard";
import KeyboardHideIcon from '@mui/icons-material/KeyboardHide';

type Props = { className?: string; classes?: any, dark?: boolean };
const useStyles = makeStyles(({ spacing }: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "row",
      alignContent: "space-between",
      justifyContent: "space-between",
    },
    stretch: {
      flex: 1,
    },
  })
);

const SignatureSelect = (props: Props) => {
  const { className, classes: externClasses, dark = false } = props;
  const color = dark ? '#000' : '#fff';
  const classes = useStyles();
  const { user } = useChecklistContext();
  const { t } = useTranslation();

  const {
    state: { activeSignature, signatures, timeout },
    dispatch,
  } = useSignatureContext();

  const initialValues = {
    signatureId: activeSignature ? activeSignature.id.toString() : "",
  };
  const [{ loading, error }, refetch] = useSiteChecklists(user.siteId);
  useEffect(() => {
    let mounted = true;
    if (!mounted) return;
    return () => {
      mounted = false;
    };
  }, []);
  const handleOnIdle = (event) => {
    dispatch({ type: "auto-signout" });
  };

  const handleOnActive = (event) => {
    dispatch({ type: "active" });
  };

  const handleOnAction = (event) => {
    dispatch({ type: "active" });
  };
  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: timeout + 2000,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 500,
  });
  const [searchText, setSearchText] = useState("");
  const [open, setOpen] = useState(false);
  const handleSubmit = (values) => {
    //onSignatureSelected(signatures.find((x) => x.id === values.signatureId));
    const signatureIdGuid = new Guid(values.signatureId);
    const signature = signatures.find(
      (x) => x.id.valueOf() === signatureIdGuid.valueOf()
    );
    if (signature) {
      dispatch({ type: "selected-signature", activeSignature: signature });
      setSearchText("");
    }
  };

  const ref = React.useRef();
  const [mobileKeyboardEnabled, setMobileKeyboardEnabled] = useState(false);

  useEffect(() => {
    const element = ref.current as any;
    if (mobileKeyboardEnabled) {
      element?.blur();
      element?.focus();
    } else {
      element?.blur();
    }
  }, [mobileKeyboardEnabled]);

  const preventMobileKeyboardPopup = () => {
    // Prevents mobile keyboard from automatically popping up
    !mobileKeyboardEnabled && (document.activeElement as any)?.blur();
  };

  const toggleKeyboard = () => {
    setMobileKeyboardEnabled(!mobileKeyboardEnabled);
  };

  if (loading) {
    return <p>{t("Loading")}</p>;
  }

  if (error) {
    return <p>{t("Error")}</p>;
  }
  if (signatures.length <= 0) {
    return null;
  }
  const handleUnsign = () => {
    dispatch({ type: "unsign" });
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => handleSubmit(values)}
      validate={(values) => handleSubmit(values)}
      enableReinitialize
    >
      {({ values, errors, touched }) => {
        return (
          <Form className={clsx(classes.root, className)}>
            <Field
              name="signatureId"
              label={t("signature.select")}
              required
              className={classes.stretch}
              component={Autocomplete}
              style={{ color: "white", borderColor: "white" }}
              getOptionLabel={(option) =>
                signatures.find((v) => v.id.toString() === option)?.name || ""
              }
              disableClearable={true}
              options={signatures.map((x) => x.id.toString())}
              noOptionsText="Ukjent"
              onOpen={() => setOpen(true)}
              onClose={() => setOpen(false)}
              openOnFocus={true}
              open={open || searchText != ""}
              onInputChange={(e) => {
                if (searchText != "") {
                  e.stopPropagation();
                  e.preventDefault();
                  e.bubbles = false;
                }
              }}
              renderInput={(params: AutocompleteRenderInputParams) => {
                return (
                  <div
                    style={{ display: "flex", justifyContent: "flex-start" }}
                  >
                    <TextField
                      onChange={(e) => {
                        setSearchText(e.currentTarget.value);
                      }}
                      className={externClasses?.select}
                      {...params}
                      error={
                        touched["autocomplete"] && !!errors["autocomplete"]
                      }
                      helperText={
                        touched["autocomplete"] && errors["autocomplete"]
                      }
                      label={t("signature.select")}
                      variant="standard"
                      onFocus={() => preventMobileKeyboardPopup()}
                      inputRef={ref}
                    />
                    <IconButton
                      sx={{ margin: "0.7em 1.5em 0 0" }}
                      onClick={() => {
                        toggleKeyboard();
                      }}
                    >
                      {mobileKeyboardEnabled ? <KeyboardHideIcon sx={{ color }} /> : <KeyboardIcon sx={{ color }} />}
                    </IconButton>
                  </div>
                );
              }}
            />
            {activeSignature && (
              <Button onClick={handleUnsign}>{t("signature.av")}</Button>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

export default SignatureSelect;
