import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { SiteAlarmInfo } from "../services/siteAlarmInfoService";
import EntityAlarmsList from "./EntityAlarmsList";

type Props = {
  siteAlarms: SiteAlarmInfo[];
};

export const SiteAlarmsList: React.FC<Props> = ({ siteAlarms }) => {
  const { t } = useTranslation();

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-label="Expand">
        <Typography variant="h3">{t("alarms.siteAlarms")}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {siteAlarms.map((siteAlarm, i) => (
          <Accordion key={siteAlarm.siteName + i}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-label="Expand"
            >
              <Typography variant="h3">
                {siteAlarm.siteName}({siteAlarm.entityAlarms.length}{" "}
                {t("alarms.entities")}, {siteAlarm.alarmsCount()}{" "}
                {t("alarms.alarms")})
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <EntityAlarmsList entityAlarms={siteAlarm.entityAlarms} />
            </AccordionDetails>
          </Accordion>
        ))}
      </AccordionDetails>
    </Accordion>
  );
};
