<div class="_content">
  <div class="content__heading">
    <a class="content__heading__go-back" [routerLink]="['/']">
      <i class="material-icons">home</i>
    </a>
    <h1>Siste temperaturer</h1>
  </div>

  <mat-card class="measurements">
    <mat-card-content>
      <mat-progress-bar
        mode="indeterminate"
        *ngIf="isLoading"
        class="progess"
      ></mat-progress-bar>
      <div *ngIf="!isLoading && (!measurements || measurements.length === 0)">
        Fant ingen målinger.
      </div>

      <mat-card class="measurement" *ngFor="let item of measurements">
        <mat-card-content>
          <div class="items horizontal wrap">
            <div class="item">
              <div class="title">
                {{ item.entityName }}
              </div>
            </div>
            <div class="item temp">
              <div class="items horizontal">
                <div class="item temperatures">
                  <div class="now"><span [ngClass]="item.cssClass">{{ item.value }}</span><span class="desc">(siste måling)</span></div>
                  <div class="average"><span [ngClass]="item.cssClass">{{ item.averageValue | async }}</span><span class="desc">(times gj.snitt)</span></div>
                </div>
                <div class="item time">
                  <div>Sist målte {{item.signalType}}</div>
                  <div>{{ item.occuredTime }}</div>
                </div>
              </div>
            </div>
            <div class="item icon">
              <i *ngIf="item.isDeviation" class="material-icons icon-color">
                warning
              </i>
              <i *ngIf="!item.isDeviation" class="material-icons icon-color">
                done
              </i>
            </div>
          </div>
          <ng-template [ngIf]="showSignalIds">
            {{ item.sourceId }}
          </ng-template>
        </mat-card-content>
      </mat-card>
    </mat-card-content>
  </mat-card>

  <mat-card class="info" *ngIf="measurements && measurements.length == 0">
    <mat-card-content>
      Ingen målinger funnet.
    </mat-card-content>
  </mat-card>

  <mat-card class="info">
    <mat-card-content>
      Temperaturen måles 4 ganger i timen.
    </mat-card-content>
  </mat-card>
</div>
 