import * as React from "react";
import { useEffect, useState } from "react";
import * as moment from "moment";
import Chart from "react-google-charts";
import { useTranslation } from "react-i18next";
import { useMediaQuery, useTheme } from "@mui/material";

type Props = {
  startDate: any;
  endDate: any;
  timespan: moment.DurationInputArg2;
  signals: any;
  limits?: any;
  refDate?: string
  deviationValue?: number;
};

const TempChart = ({
  signals,
  startDate,
  endDate,
  timespan,
  limits,
  refDate,
  deviationValue
}: Props) => {
  const { t } = useTranslation();

  const [data, setData] = useState([]);
  const maxLimit = limits ? parseInt(limits.max) : null;
  const minLimit = limits ? parseInt(limits.min) : null;

  const [columns] = useState([
    { type: "date", label: t("Date") },
    { type: "number", label: t("Temperature") },
    { type: "number", label: "Min" },
    { type: "number", label: "Max" },
    { type: "number", label: t("Deviation") },
  ]);

  const minDateValue = moment(refDate).subtract(startDate, timespan).toDate();
  const maxDateValue = moment(refDate).subtract(endDate, timespan).toDate();

  useEffect(() => {
    const chartData = [];
    signals.map((item) =>
      chartData.push([
        item.timestamp.toDate(),
        item.value,
        limits?.min && minLimit,
        limits?.max && maxLimit - minLimit,
        null
      ])
    );
    if (deviationValue && refDate && moment(refDate).isBetween(moment(minDateValue), moment(maxDateValue))) {
      setData(
        [
          ...chartData,
          [
            moment(refDate).toDate(),
            deviationValue,
            limits?.min && minLimit,
            limits?.max && maxLimit - minLimit,
            deviationValue
          ]
        ].sort((a, b) => a[0].getTime() - b[0].getTime()))
    } else {
      setData(chartData);
    }
  }, [signals, columns]);

  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Chart
      chartType="ComboChart"
      loader={<div>Loading Chart</div>}
      data={[columns, ...data]}
      options={{
        isStacked: true,
        seriesType: "line",
        series: {
          1: {
            areaOpacity: 0,
            type: "area",
            color: "#A5D6A7",
            tooltip: false,
          },
          2: {
            type: "area",
            color: "#A5D6A7",
            tooltip: false,
          },
          3: {
            type: "line",
            color: "#e8201a",
            pointSize: 10,
          }
        },
        chart: {
          title: t("Temperature"),
        },
        chartArea: { width: "85%" },
        hAxis: {
          gridlines: { count: 13 },
          format:
            timespan === "days" || timespan === "hours" ? "HH:mm" : "d. MMM",
          minValue: minDateValue,
          maxValue: maxDateValue,
        },
        vAxis: smDown ? {} : { title: `${t("Temperature")} (\u00B0C)` },
        legend: { position: "none" },
        height: 400,
      }}
    />
  );
};

export default TempChart;
