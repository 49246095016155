<div class="menu-items">
  <a
    class="menu-item"
    [ngClass]="{ active: isRouteActive('/dashboard') }"
    (click)="routing('/dashboard')"
  >
    <i class="material-icons"> space_dashboard </i>
    <span class="name">{{ "mainMenu.dashboard" | i18next }}</span>
  </a>
  <a
    class="menu-item"
    [ngClass]="{ active: isRouteActive('/entities') }"
    (click)="routing('/entities')"
  >
    <i class="material-icons"> view_agenda </i>
    <span class="name">{{ "mainMenu.entityManagement" | i18next }}</span>
  </a>
  <a
    class="menu-item"
    [ngClass]="{ active: isRouteActive('/entity-types') }"
    (click)="routing('/entity-types')"
  >
    <i class="material-icons"> api </i>
    <span class="name">{{ "mainMenu.entityTypes" | i18next }}</span>
  </a>
  <a
    class="menu-item"
    [ngClass]="{ active: isRouteActive('/report-history') }"
    (click)="routing('/report-history')"
  >
    <i class="material-icons"> show_chart </i>
    <span class="name">{{ "mainMenu.reportsHistory" | i18next }}</span>
  </a>
  <!-- <a
    class="menu-item"
    [ngClass]="{ active: isRouteActive('/manual-reports') }"
    (click)="routing('/manual-reports')"
  >
    <i class="material-icons"> person </i>
    <span class="name">{{ "mainMenu.manualMeasurements" | i18next }}</span>
  </a>
  <a
    class="menu-item"
    [ngClass]="{ active: isRouteActive('/control-reports') }"
    (click)="routing('/control-reports')"
  >
    <i class="material-icons"> list </i>
    <span class="name">{{ "mainMenu.controlMeasurements" | i18next }}</span>
  </a> -->
  <a
    class="menu-item"
    [ngClass]="{ active: isRouteActive('/export-reports') }"
    (click)="routing('/export-reports')"
  >
    <i class="material-icons"> archive </i>
    <span class="name">{{ "mainMenu.excelReports" | i18next }}</span>
  </a>
  <a
    class="menu-item"
    [ngClass]="{ active: isRouteActive('/clean') }"
    (click)="routing('/clean')"
    *ngIf="hasOpenCloseEntity"
  >
    <i class="material-icons"> cleaning_services </i>
    <span class="name">{{ "mainMenu.clean" | i18next }}</span>
  </a>
  <a
    class="menu-item"
    [ngClass]="{ active: isRouteActive('/checklist') }"
    (click)="routing('/checklist')"
  >
    <i class="material-icons"> check </i>
    <span class="name">{{ "mainMenu.routines" | i18next }}</span>
  </a>
  <a *ngIf="hasMultipleSites" class="menu-item img" (click)="clickSelectSite()">
    <img
      class="admin-color-filter-black"
      src="/assets/icons/select-site-icon.png"
      alt="{{ 'mainMenu.selectSite' | i18next }}"
    />
    <span class="name">{{ "mainMenu.selectSite" | i18next }}</span>
  </a>
</div>
