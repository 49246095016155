import * as React from "react";
import { Theme, } from "@mui/material";
import { makeStyles, createStyles } from '@mui/styles';
import ImageModal from "../../../react-components/ImageModal";

type Props = {
  item: any;
  assignedId: string;
  sessionData: any;
  pointId: string;
};

const useStyles = makeStyles(({ spacing }: Theme) =>
  createStyles({
    root: { marginLeft: spacing(10) },
  })
);

const ChecklistDeviation = ({
  item,
  assignedId,
  sessionData,
  pointId,
}: Props) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <p>{item.comment}</p>
      <div>
        {item.images && item.images.length > 0 && (
          <ImageModal src={item.images[0]} />
        )}
      </div>
    </div>
  );
};

export default ChecklistDeviation;
