import { Router } from "@angular/router";

export enum ReportDeviationsType {
  HandlePeriodicDeviations,
  HandleCurrentDeviations
}

let QueryParamCurrentDeviations = "deviations";

export function GetReportDeviationsFromQueryParam(type: String): ReportDeviationsType {
  if (type === QueryParamCurrentDeviations) {
    return ReportDeviationsType.HandleCurrentDeviations;
  }
  return ReportDeviationsType.HandlePeriodicDeviations;
}

export function RouteWithReportType(route: String, reportType: ReportDeviationsType, router: Router) {
  if (reportType === ReportDeviationsType.HandleCurrentDeviations) {
    router.navigate([route], { queryParams: { type: QueryParamCurrentDeviations } });
    return;
  }
  router.navigate([route]);
}

export function RouteWithReportId(route: String, reportId: string, router: Router) {
  if (reportId) {
    router.navigate([route], { queryParams: { id: reportId } });
    return;
  }
  router.navigate([route]);
}
