import {
  Button,
  Dialog,
  DialogContent,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Site } from "../../../../entity/site";
import { useChecklistContext } from "../../../ChecklistReactWrapper";
import DialogTitle from "../../../../react-components/DialogTitle";
import { Form, Formik } from "formik";
import FormikSelect from "../../../../react-components/FormikSelect";
import { useTranslation } from "react-i18next";

type Props = {
  point: any;
  checklistId: string;
  checklist: any;
  onSubmit: (data: any) => void;
  open: boolean;
  onClose: () => void;
};

const ChangeOrderOfPointDialog = ({ point, checklistId, checklist, open, onClose, onSubmit }: Props) => {

  const { t } = useTranslation(); //translation

  const { user, siteService } = useChecklistContext();

  const [tenantId, setTenantId] = useState<string>("");
  const [points, setPoints] = useState<any>([]);

  useEffect(() => {
    siteService.getSite(user.siteId).subscribe((site: Site) => {
      setTenantId(site.tenantId.toString());
    });
  }, []);

  useEffect(() => {
    setPoints(
      checklist.checklistPoints
        .filter((p) => (p.id != point?.id) && p.siteEnabled)
        .map((p) => {
          return { value: p.id, label: p.siteName || p.name };
        })
    );
  }, [checklist.checklistPoints]);

  const handleSubmit = (values, { resetForm }) => {
    onSubmit({
      referencePoint: point?.id,
      setAfter: values.moveAfterPointId,
      tenantId,
      checklistId,
    });

    resetForm();
    onClose();
  };

  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose}>
      <DialogTitle onClose={onClose}>
        {`${t("editChecklist.change_order_title")}`}
      </DialogTitle>
      <DialogContent>
        <Formik
          initialValues={{ moveAfterPointId: "" }}
          enableReinitialize
          validate={(values) => {
            const errors = {};
          }}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <FormikSelect
                name="moveAfterPointId"
                label={t("punkt")}
                required
                items={points}
              />
              <Button type="submit" variant="contained" color="primary">
                {`${t("editChecklist.submit")}`}
              </Button>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default ChangeOrderOfPointDialog;
