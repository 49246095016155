import {
  Grid,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useFormikContext } from "formik";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useDebouncedCallback } from "use-debounce";
import { TemperatureRangeSlider } from "../../react-components/TemperatureRangeSlider";
import { getBoundaries } from "../../react-components/tempRuleUtils";


const Layout: React.FC = ({ children }) => {
  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <Grid container>
      {mdUp && (
        <Grid item md={3} xs={0} display="flex" justifyContent="flex-end">
          {children[0]}
        </Grid>
      )}
      <Grid item md={6} xs={12} display="flex" justifyContent="center">
        {children[1]}
      </Grid>
      {!mdUp && (
        <Grid item md={0} xs={6} display="flex" justifyContent="center">
          {children[0]}
        </Grid>
      )}
      <Grid item md={3} xs={6} display="flex" justifyContent={mdUp ? "flex-start" : "center"}>
        {children[2]}
      </Grid>
    </Grid>
  );
};

type Props = {
  rules: any;
  areRulesInverted: boolean;
};

const RulesForm: React.FC<Props> = ({ rules, areRulesInverted }) => {
  const { setFieldValue } = useFormikContext();

  const { t } = useTranslation();

  const bounds = getBoundaries({
    green: rules.GREEN,
    yellow: rules.YELLOW,
    red: rules.RED,
  });

  const [inverted, setInverted] = React.useState(areRulesInverted);
  const [min, setMin] = React.useState(bounds.min);
  const [max, setMax] = React.useState(bounds.max);
  const [tmpMin, setTmpMin] = React.useState(min);
  const [tmpMax, setTmpMax] = React.useState(max);
  const [midInterval, setMidInterval] = React.useState([
    rules.YELLOW.min,
    rules.YELLOW.max,
  ]);

  React.useEffect(() => {
    if(inverted !== areRulesInverted && areRulesInverted) {
      setTmpMax(75);
      setMax(75);
      setTmpMin(30);
      setMin(30);
      setMidInterval([50, 65]);
    } else if(inverted !== areRulesInverted && !areRulesInverted) {
      setTmpMax(6);
      setMax(6);
      setTmpMin(-2);
      setMin(-2);
      setMidInterval([0, 4]);
    }
    setInverted(areRulesInverted);
  }, [areRulesInverted]);

  React.useEffect(() => {
    if(midInterval[0] < min && midInterval[1] < min) setMidInterval([min, min]);
    else if (midInterval[0] < min) setMidInterval([min, midInterval[1]]);
    else if (midInterval[1] < min) setMidInterval([midInterval[0], min]);
  }, [min]);

  React.useEffect(() => {
    if(midInterval[0] > max && midInterval[1] > max) setMidInterval([max, max]);
    else if (midInterval[1] > max) setMidInterval([midInterval[0], max]);
    else if (midInterval[0] > max) setMidInterval([max, midInterval[1]]);
  }, [max]);

  React.useEffect(() => {
    setFieldValue(areRulesInverted ? "rules.RED" : "rules.GREEN", {
      ...(areRulesInverted ? rules.RED : rules.GREEN),
      min,
      max: midInterval[0],
    });
    setFieldValue("rules.YELLOW", {
      ...rules.YELLOW,
      min: midInterval[0],
      max: midInterval[1],
    });
    setFieldValue(!areRulesInverted ? "rules.RED" : "rules.GREEN", {
      ...(!areRulesInverted ? rules.RED : rules.GREEN),
      min: midInterval[1],
      max,
    });
  }, [min, max, midInterval[0], midInterval[1]]);

  const handleMinChange = (e) => {
    const newValue = e.target.value;
    if(isNaN(newValue) && newValue !== '-' && newValue !== '') return;
    const newMin = parseInt(newValue);
    setTmpMin(newValue);
    handleRealMinChange(newMin);
  }

  const handleMaxChange = (e) => {
    const newValue = e.target.value;
    if(isNaN(newValue) && newValue !== '-' && newValue !== '') return;
    const newMax = parseInt(newValue);
    setTmpMax(newValue);
    handleRealMaxChange(newMax);
  }

  const handleRealMinChange = useDebouncedCallback((newMin: number) => {
    if(!isNaN(newMin) && newMin < max) setMin(newMin);
    else setTmpMin(min);
  }, 1000);

  const handleRealMaxChange = useDebouncedCallback((newMax: number) => {
    if(!isNaN(newMax) && newMax > min) setMax(newMax);
    else setTmpMax(max);
  }, 1000);

  return (
    <Layout>
      <TextField
        value={tmpMin}
        label={t("entityTypes.edit.min")}
        variant="standard"
        sx={{maxWidth: '5em'}}
        onChange={handleMinChange}
      />
      <TemperatureRangeSlider
        value={midInterval}
        onChange={setMidInterval}
        min={min}
        max={max}
        isInverted={areRulesInverted}
      />
      <TextField
        value={tmpMax}
        label={t("entityTypes.edit.max")}
        variant="standard"
        sx={{maxWidth: '5em'}}
        onChange={handleMaxChange}
      />
    </Layout>
  );
};

export default RulesForm;
