<h1 mat-dialog-title class="center">{{data.title}}</h1>
<div mat-dialog-content>
  <b>Temperatur:</b>
  <p><span class="temperature">{{data.value}}</span></p>
  <b>Min / maks:</b>
  <p><span class="temperature">{{data.min}}</span> / <span class="temperature">{{data.max}}</span></p>
  <b>Avviksbeskrivelse:</b>
  <p>{{data.action}}</p>
</div>
<div mat-dialog-actions class="center">
  <button mat-button (click)="cancel()">Lukk</button>
</div>