import { Component, OnInit, NgZone } from "@angular/core";
import { ServiceBusService } from "../../system/service-bus.service";
import { Observable } from "rxjs";
import { Chanels } from "../../chanels";
import { MsalService } from "@azure/msal-angular";
import { SiteSelectComponent } from "../../site/site-select/site-select.component";
import { MatDialog } from "@angular/material/dialog";
import { UserSiteService } from "../../site/user-site.service";
import { SiteService } from "../../entity/site.service";
import { Router, NavigationStart, RouterEvent } from "@angular/router";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  user$: Observable<any>;
  hasMultipleSites: boolean = false;
  public siteName: String;
  private location: String;
  constructor(
    private bus: ServiceBusService,
    private msalService: MsalService,
    private sitesService: SiteService,
    private dialog: MatDialog,
    private router: Router
  ) {}

  ngOnInit() {
    this.user$ = this.bus.chanelObservable(Chanels.USER_CHANEL);
    this.user$.subscribe((user) => {
      console.log("User: ", user);
      if(!user) return;

      // Check user sites
      var siteIds = user.adalUser.sites;
      this.hasMultipleSites = siteIds.length > 1;

      // Get site name
      this.sitesService.getSite(user.siteId).subscribe((site) => {
        console.log("Site: ", site);
        this.siteName = site.name;
      });
      this.location = window.location.hostname;
    });

  }

  isRouteActive(route: string) {
    return this.router.url.startsWith(route);
  }

  routing(route: string) {
    this.router.navigate([route]);
  }

  logout() {
    this.msalService.logout();
  }
  get isNotProduction(){
    return !this.location.includes('tmesh.app');
  }
  clickSelectSite() {
    this.dialog.open(SiteSelectComponent);
  }
}
