import * as React from "react";
import { Paper, Theme, Typography, Box } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import RoomData from "./RoomData";
import Settings from "./Settings";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: "1em",
      marginBottom: "1em",
    },
    link: { textDecoration: "none!important" },
    icon: {
      fontSize: 32,
    },
    alertIcon: {
      fontSize: 70,
      color: theme.palette.primary.main,
    },
    alertContainer: {
      marginRight: "2em",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    title: {
      flexGrow: 1,
      cursor: "pointer",
    },
    data: {
      cursor: "pointer",
    },
  });

const useStyles = makeStyles(styles);

type Props = {
  entity: any;
  hide?: boolean;
};

const RoomCard = ({ entity, hide }: Props) => {
  const classes = useStyles();

  const link = () => (window.location.href = `entity/${entity.id}`);
  return (
    !hide && (
      <Paper className={classes.root}>
        <Box display="flex" alignItems="center">
          <Typography variant="h2" className={classes.title} onClick={link}>
            {entity.name}
          </Typography>
          <Settings entity={entity} />
        </Box>
        <Box onClick={link} className={classes.data}>
          <RoomData entity={entity} />
        </Box>
      </Paper>
    )
  );
};

export default RoomCard;
