import * as React from "react";
import { FC } from "react";
import {
  Button,
  Theme,
} from "@mui/material";
import { makeStyles, createStyles } from '@mui/styles';
import { Field, Form, Formik } from "formik";
import { actions } from "../services/reducer";
import { useChecklistContext } from "../ChecklistReactWrapper";
import { useSignatureContext } from "../../signature-react/SignatureContext";
import { useTranslation } from "react-i18next";
import { TextField } from "formik-material-ui";

type Props = {
  dispatch: any;
  pointId?: any;
  point: any;
  assignedId: string;
  checklistId: string;
  variant: "Point" | "Checklist";
  onCommentsAdded: (comment: any) => void;
  actionDescription: string;
  deviationCauses?: { label: string, cause: string }[];
  deviationValue: number | string;
  disabled?: boolean;
};

const useStyles = makeStyles(({ spacing, palette, breakpoints }: Theme) =>
  createStyles({
    root: {},
    dialogContent: {
      paddingBottom: "1rem",
    },
    typography: {
      color: "white",
      fontSize: 12,
      position: "absolute",
      top: "20%",
    },
    paper: {
      backgroundColor: palette.grey[300],
      marginBottom: spacing(2),
      marginLeft: spacing(1),
      marginRight: spacing(1),
    },
    mediaContainer: {
      display: "block",
      padding: "2vh",
    },
    image: {},
    comment: {
      padding: spacing(2),
      minHeight: spacing(4),
    },
    commentButtons: {
      width: "100%",
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-around",
      padding: spacing(2),
      minHeight: spacing(4),
    },
    commentButton: {
      [breakpoints.down("sm")]: {
        width: "100%",
        marginBottom: spacing(1),
        padding: spacing(2),
      },
    },
    commentInput: {
      [breakpoints.down("sm")]: {
        minHeight: "5rem",
      },
    },
  })
);

const defaultDeviationCauses = [
  { label: 'Tomt', cause: 'Det er tomt' },
  { label: 'Vaske & rydde', cause: 'Vi har gjort rengjøring' },
  { label: 'Varelevering', cause: 'Vi har fått inn nye varer' },
  { label: 'Under tilberedning', cause: 'Under tilberedning' },
];

const ReportDeviationInput: FC<Props> = ({
  dispatch,
  pointId,
  point,
  assignedId,
  checklistId,
  variant,
  onCommentsAdded,
  actionDescription,
  deviationCauses,
  deviationValue,
  disabled,
}) => {
  console.log("LUKA", deviationValue)
  if (!deviationCauses?.length) deviationCauses = defaultDeviationCauses;

  const { t } = useTranslation();
  const classes = useStyles();
  const { user } = useChecklistContext();
  const {
    state: { activeSignature },
  } = useSignatureContext();

  const signatureId = activeSignature?.id?.toString();
  const addComment = (comment) => onCommentsAdded(comment);
  const dispachtValueCommand = (comment: string) => {
    dispatch({
      type:
        variant === "Point"
          ? (deviationValue != null ? actions.REPORT_TEMPERATURE_DEVIATION : actions.REPORT_DEVIATION)
          : actions.CHECKLIST_REPORT_DEVIATION,
      payload: {
        deviation: {
          ...(variant === "Point" ? { pointId } : {}),
          comment: comment,
          assignedId,
          checklistId,
          images: null,
          signatureId: signatureId,
          siteId: user.siteId,
          tenantId: point.tenantId,
          ...(deviationValue ? { value: deviationValue } : {})
        },
        userId: user.adalUser.oid,
      },
    });
  };
  const handleNewComment = (commentText: string) => {
    addComment({ comment: commentText });
    dispachtValueCommand(commentText);
  };
  return (
    <>
      {
        <Formik
          initialValues={{ comment: "" }}
          onSubmit={(values, formikHelpers) => {
            // handleMediaUpload((images) => {
            if (!values.comment) return;
            handleNewComment(values.comment);
            // });
            // setAttachments(null);
            // formikHelpers.resetForm();
          }}
        >
          {({ submitForm }) => (
            <Form>
              <p>{t("checklist.checklistCard.actionDescriptionPrefix")}: {t(actionDescription)}</p>
              <div>
                <Field
                  name="comment"
                  render={(props) => (
                    <TextField
                      {...props}
                      className={classes.commentInput}
                      label="Skriv årsak for avvik eller velg et hurtig valg."
                      multiline
                      fullWidth
                      variant="standard"
                      onBlur={submitForm}
                      disabled={disabled}
                    />
                  )}
                />
              </div>
            </Form>
          )}
        </Formik>
      }
      <div className={classes.commentButtons}>
        {deviationCauses.map(({ label, cause }, i) =>
          <Button
            key={`${label}${cause}${i}`}
            variant="contained"
            className={classes.commentButton}
            onClick={() => {
              handleNewComment(cause);
            }}
            disabled={disabled}
          >
            {label}
          </Button>
        )}
      </div>
    </>
  );
};

export default ReportDeviationInput;
