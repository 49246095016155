const timeouts = {};
const debounce = (callback, wait, id='') => {
  if(!timeouts[id]) {
    timeouts[id] = {
      id: null,
      callbacks: []
    }
    callback.apply(null, []);
    return () => {};
  }
  return (...args) => {
    window.clearTimeout(timeouts[id].id);
    timeouts[id].callbacks.push(() => {
      callback.apply(null, args);
    });
    timeouts[id].id = window.setTimeout(() => {
      timeouts[id].callbacks.forEach(cb => cb());
    }, wait);
  };
}
export const debouncedPromise = (cb: () => any, timeout: number, id: string) => {
  return new Promise(r => {
    const debounced = debounce(async () => {
      r(await cb());
    }, timeout, id);
    debounced();
  });
}