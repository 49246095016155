import * as React from "react";
import { createStyles, makeStyles } from "@mui/styles";
import PageHeader from "../../react-components/PageHeader";

import SiteForm from "./SiteForm";
import { useTranslation } from "react-i18next";
import AddSignature from "../../signature-react/AddSignature";
import SignatureList from "../../signature-react/SignatureList";
import { useGetSignatures, useGetSignaturesByTenant } from "../../signature-react/SignatureService";
import { SiteSettingsContext } from "../SiteSettingsReactWrapper";
import { useDebouncedCallback } from "use-debounce";
import { take } from "rxjs";
import { Site } from "../../entity/site";

const styles = () =>
  createStyles({
    root: {
      padding: "1rem",
    },
  });

const useStyles = makeStyles(styles);

const SiteSettingsPage = () => {
  const { user, siteService } = React.useContext(SiteSettingsContext);
  const classes = useStyles();
  const { t } = useTranslation();

  const [site, setSite] = React.useState<Site>();

  React.useEffect(() => {
    siteService
      .getSite(user.siteId)
      .pipe(take(1))
      .subscribe((res: Site) => {
        setSite(res);
      });
  }, []);

  const [{ data: signatures, error: errorUserSignatures, loading: loadingUserSignatures }, refetch] = useGetSignatures(user.adalUser.oid);
  const [{ data: tenantSignatures, error: errorTenantSignatures, loading: loadingTenantSignatures }, refetchTenant] = useGetSignaturesByTenant(site?.tenantId?.toString());

  const refetchDebounced = useDebouncedCallback(() => {
    refetch();
    refetchTenant();
  });

  React.useEffect(() => {
    errorTenantSignatures && refetchTenant();
  }, [errorTenantSignatures, site?.tenantId]);

  if (loadingUserSignatures && !signatures) return <>{t("Loading")}</>;
  if (loadingTenantSignatures && !signatures) return <>{t("Loading")}</>;
  if ((errorTenantSignatures && errorTenantSignatures?.response?.status != 200 && errorTenantSignatures?.response?.status != 404)
    || (errorUserSignatures && errorUserSignatures?.response?.status != 200 && errorUserSignatures?.response?.status != 404)) {
    return <>{t("Error")}</>;
  }

  const signaturesList = (!!signatures && errorUserSignatures?.response?.status !== 404) ? signatures : [];
  const tenantSignaturesList = (!!tenantSignatures && errorTenantSignatures?.response?.status !== 404) ? tenantSignatures : [];
  return (
    <div className={classes.root}>
      <PageHeader title={t("SettingsComponent.title")} />
      <SiteForm />
      <hr style={{ marginBottom: "2rem" }} />
      <PageHeader title={t("SettingsComponent.signaturesTitle")} />
      <SignatureList signatures={signaturesList} onChange={refetchDebounced} />
      <AddSignature signatures={tenantSignaturesList} onChange={refetchDebounced} />
    </div>
  );
};

export default SiteSettingsPage;
