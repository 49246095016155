import {
  Checkbox,
  FormGroup,
  Grid,
  Paper,
  Theme,
} from "@mui/material";
import React, { useEffect } from "react";
import { TimelineDot, } from "@mui/lab";
import { useTranslation } from "react-i18next";
import { createStyles, makeStyles } from "@mui/styles";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: "1em",
      marginBottom: "1em",
    },
    paper: {
      padding: '0.25em',
      marginBottom: '1em',
      marginRight: '0.5em',
      marginLeft: '0.5em',
      cursor: 'pointer',
      [theme.breakpoints.down('md')]: {
        marginRight: '0',
        marginLeft: '0',
        padding: '0.1em',
      }
    },
    title: {
      flexGrow: 1,
    },
    ruleTitle: {
      marginLeft: theme.spacing(1),
      fontWeight: 500
    },
    ruleTitleContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
    }
  });

const useStyles = makeStyles(styles);

const keyColorRegistry = {
  GREEN: "success",
  YELLOW: "warning",
  RED: "error",
}

type Props = {
  value: string[];
  onChange: (value: string[]) => any;
};

const DeviationHandlingLevelFilters = ({ value, onChange }: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [state, setState] = React.useState({
    red: value.includes('red'),
    yellow: value.includes('yellow'),
    green: value.includes('green'),
  });

  const handleChange = (name: string) => {
    setState({
      ...state,
      [name]: !state[name],
    });
  };

  useEffect(() => {
    onChange(Object.keys(state).filter(k => state[k]));
  }, [state]);

  return (
    <FormGroup>
      <Grid container>
      {Object.keys(keyColorRegistry).map((ruleKey: string, i: number) => 
        (<Grid item md={4} sx={{width: '100%'}} key={ruleKey+i}>
          <div className={classes.paper} >
            <div className={classes.ruleTitleContainer} onClick={() => handleChange(ruleKey.toLowerCase())}>
              <Checkbox checked={state[ruleKey.toLowerCase()]} onChange={e => handleChange(e.target.name)} name={ruleKey.toLowerCase()} />
              <TimelineDot sx={{ display: 'inline-block', width: '1px', marginTop: '1.5vh' }} color={keyColorRegistry[ruleKey]} />
              <p className={classes.ruleTitle}>{t(`checklist.deviationHandlingLevelInfo.rules.${ruleKey}`)}</p>
            </div>
        </div>
        </Grid>)
      )}
      </Grid>
    </FormGroup>
  );
};

export default DeviationHandlingLevelFilters;
