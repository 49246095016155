import React, { FC, useEffect, useState } from 'react';

import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';

type Props = {
  options: string[];
  label?: string;
  values: string[];
  onChange?: (values: string[]) => void;
}

export const TagsField: FC<Props> = ({ label, options, values, onChange }) => {

  const [defaultValues, setDefaultValues] = useState(values || []);
  
  useEffect(() => {
    defaultValues !== values && setDefaultValues(values);
  }, [values]);

  const { t } = useTranslation();

  const mapOptionLabel = {
    high: t('High Priority'),
    low: t('Low Priority')
  }

  const getOptionLabel = (option) => mapOptionLabel[option] || option;

  return (
    <Autocomplete
      multiple
      id="tags-component-autocomplete"
      options={options}
      value={defaultValues}
      defaultValue={defaultValues}
      freeSolo
      getOptionLabel={getOptionLabel}
      renderTags={(value: readonly string[], getTagProps) =>
        value.map((option: string, index: number) => (
          <Chip variant="outlined" label={getOptionLabel(option)} {...getTagProps({ index })} />
        ))
      }
      
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          label={label}
          placeholder={label}
        />
      )}
      onChange={(_, values) => {
        setDefaultValues(values);
        onChange(values);
      }}
    />
  );
}
