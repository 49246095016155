import { Button, Grid } from "@mui/material";
import * as React from "react";
import { useSignatureContext } from "../../signature-react/SignatureContext";
import { useChecklistContext } from "../ChecklistReactWrapper";
import { actions } from "../services/reducer";
import { useTranslation } from "react-i18next";

type Props = {
  state: any;
  dispatch: any;
  checklist: any;
  tenantId: string;
};

const ChecklistCompletedActions = ({
  state,
  dispatch,
  checklist,
  tenantId,
}: Props) => {
  const { user } = useChecklistContext();
  const {
    state: { activeSignature },
  } = useSignatureContext();

  const { t } = useTranslation();

  const signatureId = activeSignature?.id?.toString();
  return (
    <>
      <Grid item xs={12}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            dispatch({
              type: actions.REOPEN_SESSION,
              payload: {
                checklistId: checklist.checklistId,
                tenantId,
                assignedId: checklist.assignedId,
                userId: user.adalUser.oid,
                siteId: user.siteId,
                signatureId: signatureId,
              },
            });
          }}
        >
          {t("Gjennåpne rutine")}
        </Button>
      </Grid>
    </>
  );
};

export default ChecklistCompletedActions;
