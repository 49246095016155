import * as React from "react";
import { useEffect, useState, useContext } from "react";
import { FieldArray, Form, Formik } from "formik";
import FormikField from "../../react-components/FormikField";
import {
  Box,
  Button,
  IconButton,
  Snackbar,
  Theme,
  Typography,
  Switch,
  TextField,
} from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import RemoveIcon from "@mui/icons-material/Delete";
import { AlarmContact, Site } from "../../entity/site";
import { UserProfileContext } from "../UserProfileReactWrapper";
import { take } from "rxjs/operators";
import { Alert } from "@mui/lab";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import ServiceHoursDefinitionDialog from "../../edit-entity-form-react/components/ServiceHoursDefinitionDialog";
import { ServiceHoursDefinition } from "../../edit-entity-form-react/interfaces";
import Loading from "../../react-components/Loading";
import {
  AddPhoneUserAccount,
  initPhoneAccountService,
} from "../initPhoneAccountService";

type IValues = {
  name: string;
  alarmContacts: AlarmContact[];
};

const styles = ({ spacing }: Theme) =>
  createStyles({
    root: {},
    button: {
      marginTop: spacing(2),
    },
  });

const useStyles = makeStyles(styles);

const PhoneAccountsForm = () => {
  const { user } = useContext(UserProfileContext);
  const { t } = useTranslation();
  const classes = useStyles();

  const [phoneAccountsService] = useState(initPhoneAccountService());

  const [open, setOpen] = useState<boolean>(false);
  const [saved, setSaved] = useState<boolean>(true);
  const [changed, setChanged] = useState<number[]>([]);
  const [deleted, setDeleted] = useState<string[]>([]);
  const [phoneAccounts, setPhoneAccounts] = useState<AddPhoneUserAccount[]>([]);

  useEffect(() => {
    if (!user) return;
    phoneAccountsService
      .getForProfile(user.adalUser.id)
      .then((r) => setPhoneAccounts(r))
      .catch((err) => console.log(err));
  }, []);

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleSave = async () => {
    await Promise.all(
      changed.map((i) => phoneAccountsService.addOrUpdate(phoneAccounts[i]))
    );
    setChanged([]);
    await Promise.all(
      deleted.map((id) => phoneAccountsService.del(id))
    );
    setDeleted([]);

    setSaved(true);
    setOpen(true);
  };

  return (
    <>
      <Box mt={5} mb={2}>
        <Typography>
          {t("userProfile.phoneAccounts.add")}
        </Typography>
      </Box>
      {phoneAccounts.map((acc, i) => (
        <div key={i}>
          <TextField
            variant="standard"
            label={t("userProfile.phoneAccounts.phoneNumber")}
            value={acc.phoneNumber}
            onChange={(e) => {
              phoneAccounts[i].phoneNumber = e.target.value;
              phoneAccounts[i].externalUserId = e.target.value;
              setSaved(false);
              setChanged((prev) => [...prev.filter((x) => x !== i), i]);
              setPhoneAccounts([...phoneAccounts]);
            }}
          />
          <TextField
            variant="standard"
            label={t("userProfile.phoneAccounts.name")}
            value={acc.name}
            onChange={(e) => {
              phoneAccounts[i].name = e.target.value;
              setSaved(false);
              setChanged((prev) => [...prev.filter((x) => x !== i), i]);
              setPhoneAccounts([...phoneAccounts]);
            }}
          />
          <IconButton
            onClick={() => {
              setPhoneAccounts((prev) => prev.filter((_, j) => j !== i));
              setDeleted((prev) => [...prev, (acc as any).id]);
              setSaved(false);
            }}
          >
            <RemoveIcon />
          </IconButton>
        </div>
      ))}
      <div className={classes.button}>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          onClick={() => {
            setPhoneAccounts((prev) => [
              ...prev,
              {
                externalUserId: "",
                userProfileId: user.adalUser.id,
                phoneNumber: "",
                name: ""
              },
            ]);
          }}
        >
          {t("userProfile.phoneAccounts.addNew")}
        </Button>
      </div>
      <div className={classes.button}
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "flex-end",
          overflow: "hidden",
        }}
      >
        {!saved && (
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={handleSave}
          >
            {t("userProfile.phoneAccounts.save")}
          </Button>
        )}
      </div>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        sx={{ height: "100%" }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleClose} severity="success">
          <b>{t("userProfile.phoneAccounts.saved")}</b>
        </Alert>
      </Snackbar>
    </>
  );
};

export default PhoneAccountsForm;
