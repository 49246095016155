export default {
  Yes: "Yes",
  No: "No",
  Hour: "Hour",
  Day: "Day",
  Week: "Week",
  Month: "Month",
  Previous: "Previous",
  Next: "Next",
  to: "to",
  "Edit entity": "Edit entity",
  Disabled: "Disabled",
  Hidden: "Hidden",
  "The change has been saved": "The change has been saved",
  "Something went wrong! Try again later":
    "Something went wrong! Try again later",
  Humidity: "Humidity",
  Temperature: "Temperature",
  Door: "Door",
  Doors: "Doors",
  "CO2": "CO2",
  Date: "Date",
  Breakage: "Breakage",
  Entities: "Entities",
  "New Entity": "New Entity",
  "Show QR-coder": "Show QR-coder",
  "New Checklist": "New Checklist",
  "Edit checklists": "Edit checklists",
  Open: "Open",
  Close: "Close",
  Closed: "Closed",
  "Greater than": "Greater than",
  "Less than": "Less than",
  "Entity count": "Entity count",
  "Show all entities": "Show all entities",
  "Deviation handled": "Deviation Handled",
  "Handle deviations": "Handle deviations",
  "Handle Deviation": "Handle Deviation",
  "Routine report": "Routine report",
  "Show Deviations": "Show Deviations",
  "Show Reports": "Show Reports",
  "Show Measurements": "Show Measurements",
  "No deviations to report": "No deviations to report",
  "No deviations to handle": "No deviations to handle",
  Reports: "Reports",
  Measurements: "Measurements",
  Deviations: "Deviations",
  handled: "handled",
  deviationOrComment: "Add deviation or comment",

  "Choose entity": "Choose entity",
  Min: "Min",
  Max: "Max",
  Between: "Between",
  "Add new sensor": "Add new sensor",

  "Log Out": "Log Out",
  "Log In": "Log In",
  "Add telephone number": "Add telephone number",

  "Dagens avvik": "Today's deviations",
  "Ukens avvik": "Weekly deviations",
  "Show all": "Show all",

  Entity: {
    Manual: "Manual",
  },

  Search: "Search",
  Settings: "Settings",
  Type: "Type",
  Name: "Name",
  Save: "Save",
  Saved: "Saved",
  Back: "Back",
  Exceptions: "Exceptions",
  Done: "Done",
  Add: "Add",
  "All Exceptions": "All Exceptions",
  "From has to be before To": "From has to be before To",
  "Select at least one day": "Select at least one day",
  Required: "Must be filled in",
  "Alarm SMS recipients": "Alarm SMS recipients",
  "Has to be integer": "Has to be integer ",
  newOrder: {
    fileDropAriaTitle: "Upload",
  },
  SettingsComponent: {
    title: "Site settings",
    signaturesTitle: "Signature settings",
    routinesTitle: "Routine settings",
  },
  CleanComponent: {
    title: "Clean",
    register_clean: "Register clean",
    time_to_clean: "Time for cleaning",
  },
  Labeling: {
    label: "Label",
    labels: "Labels",
    type: "Type",
    name: "Name"
  },
  editChecklist: {
    pageTitle: "Edit Checklist",
    edit: "Edit",
    add_new_checklist: "New",
    add_checklist_to_site: "Checklist from template",
    name: "Name",
    description: "Description",
    deviationHandlingLevel: "Deviation handling level",
    submit: "Submit",
    cancel: "Cancel",
    add_entity_title: "Add entity to point",
    add_entity: "Add entity",
    add_entity_id: "Add ID of entity",
    addLabel: "Add label",
    change_order: "Change order",
    change_order_title: "Add this point after:",
    add_breakage: "Add breakage",
    breakage_unit: "Enter the desired breakage device",
    addDeviationButton: "Report deviation",
    removeImage: "Remove Image",
    imageLibrary: "Image library",
    images: "Checklist images",
    externalLink: {
      title: "Add External Link",
      titleLabel: "Title",
      urlLabel: "URL",
      titleLabelError: "Title is required",
      urlLabelError: "URL is invalid",
      submit: "Set",
      disable: "Disable",
      enable: "Enable",
    },
    sections: {
      checklistInfo: "Checklist Info",
      schedule: "Checklist Schedule",
      points: "Checklist Points",
    },
    editPoint: {
      addNew: "+ Template point",
      addNewToSite: "+ Checklist point",
      includePoints: "Include checklist points",
      show: {
        checklistAll: "Show All Checklist Points",
        templateAll: "Show All Template Points",
        checklist: "Show Checklist Points",
        template: "Show Template Points",
      },
      card: {
        inputFieldLabel: "Point title",
        inputDescriptionLabel: "Point description",
        includedFrom: "Included from",
        templatePoint: "Template Point",
      },
      settings: {
        title: "Settings",
        edit: "Edit",
        editDetails: "Add More Details",
        assignEntity: "Assign Entity",
        addLabel: "Add label",
        move: "Move",
        setExternalLink: "Set External Link",
        setBreakage: "Set Waste Unit",
        attachMedia: "Attach Media",
        enabled: "Enabled",
        deleteLabel: "Delete",
        deleteConfirmation: "Are you sure you want to delete this point?",
        deleteSiteConfirmation: "Are you sure you want to delete this point in the site?",
      },
      multipoint: {
        enable: "Allow multiple entries"
      }
    },
  },
  calendar: {
    unhandledDeviations: "Unhandled deviations",
    handledDeviations: "All deviations handled",
    allHandled: "All deviations handled",
    notCompleted: "Not performed",
    pending: "Pending",
    completed: "Completed",
    weekTab: "SEE WEEK",
    monthTab: "SEE MONTH"
  },
  checklist: {
    checklistsTitle: "Routines",
    inProgressTitle: "Ongoing Routines",
    readyTitle: "Tasks must be performed",
    allwaysScheduledTitle: "Tasks for manual start",
    overdueTitle: "Deadline expired",
    comingUpTitle: "Coming later",
    completedTitle: "Finished",
    highPriorityRoutinesTab: "High priority",
    lowPriorityRoutinesTab: "Low priority",
    reportsTab: "Reports",
    deviationsTab: "Routine deviation report/ comments",
    calendarTab: "Calendar",
    temperatureDeviationsTab: "Temperature deviation report",
    commentsTab: "Comments",
    overviewTab: "Routines",
    noSessions: "No performed routines",
    tagFilters: {
      labelWithFilters: "Filters",
      labelWithoutFilters: "Select Deviation Type Here",
    },
    deviationHandlingLevelInfo: {
      title: "Deviation handling level info",
      rules: {
        GREEN: "No automatic deviations.",
        YELLOW: "Automatic deviations that don't emit notifications.",
        RED: "Automatic deviations that emit notifications.",
      },
    },
    subPoint: {
      add: "Add new entry",
      remove: "Remove this entry",
      addAnother: "Add another entry"
    },
    green: "Green",
    yellow: "Yellow",
    red: "Red",
    deviationCard: {
      auto: "Auto",
      point: "Point",
      reportedBy: "Reported by",
      comment: "Deviation description",
      actionTakenBy: "Action taken by",
      takenAction: "Taken action",
      handledBy: "Handled by",
      handledComment: "Deviation treatment / Prevent occuring deviations",
      additionalCommentLabel: "Comment",
      commentsHistory: "Comments history",
      wholeRoutine: "This deviation applies to the entire routine",
      reportedAt: "reported at",
    },
    handleDeviationDialog: {
      button: "Handle",
      dialogTitle: "Handle deviation / Prevent recurring deviations",
      dialogCommentTitle: "Add new comment",
      dialogText: "Here you can add a new comment. This comment will be saved in the report, which will be visible to everyone who has access to the report.",
      textFieldLabel: "Comment",
      cancel: "Cancel",
      save: "Save",
    },
    numberOfDeviationsInCard: "showing {{deviationNumber}}",
    status: {
      status: "Status",
      IN_PROGRESS: "Started",
      FINISHED: "Finished",
      OVER_DUE: "Not started",
      OVER_DUE_STARTED: "Not finished",
    },
    completed: "Completed",
    deviation: "Deviation",
    deviationHandling: {
      deviationCause: "Cause of deviation",
      immediateAction: "Immediate action",
    },
    checklistCard: {
      deadline: "Completed before",
      expired: "Expired at",
      starting: "Starting",
      started: "Started",
      readySince: "Ready since",
      ready: "Ready",
      lastChanged: "Last changed",
      empty: "No tasks",
      addDeviationButton: "Add deviation",
      actionDescriptionPrefix: "You should",
    },
    comments: {
      wholeRoutine: "This comment applies to the entire routine",
      comment: "Comment",
      noComments: "No comments",
    },
    chooseSignature: "Choose signature",
    checklistOverview: "Overview",
    schedule: {
      todoFrom: "This routine is ready ",
      dueFrom: "This routine should be completed by ",
      nextRuns: "Coming times: ",
    },
    progressActions: {
      completeChecklist: "Complete routine",
      handleDeviationsInfo: "Please handle all deviations",
    },
    actions: {
      startChecklist: "Start",
      reports: "Go to Reports",
      edit: "Edit",
      delete: "Delete",
    },
    deleteDialog: {
      title: "Delete routine",
    }
  },

  entityForm: {
    successMessage: "Entity successfully saved.",
    errorMessage: "Failed to save entity.",
    objectImage: {
      buttonLabel: "Set Object Image",
      title: "Object Image",
      name: "Name",
      done: "Done",
      nameError: "Please enter a name.",
      imageError: "Please upload an image.",
      clickToPreview: "Click to see the full image"
    },
    serviceHours: {
      buttonLabel: "Define Service Hours",
      title: "Service Hours",
      from: "From",
      to: "To",
      add: "Add",
      all: "All Service Hours",
      noItems: "No Service Hours Defined",
      done: "Done",
      everyDay: "Every day",
    },
    exceptions: {
      buttonLabel: "Exceptions",
      title: "Exceptions",
      from: "From",
      to: "To",
      add: "Add",
      all: "All Exceptions",
      noItems: "No Exceptions Defined",
      done: "Done",
      everyDay: "Every day",
    },
    objectType: {
      buttonLabel: "Type",
      title: "Type",
      select: "Select",
      selectAndSave: "Select and Save",
    },
  },
  dashboard: {
    routineDeviationsTitle: "Deviation report from automatic sensor",
    routineStatusTitle: "Overview of Routine Deviations",
    routineChartsTitle: "Percentage of completed routines",
    tooltips: {
      dailyDeviations: 'This section shows you a generated report of deviations in automatic sensors from the previous day. By pressing "process", you process the deviation.',
      weeklyDeviations: "This section displays a generated report of the week's deviations from automatic sensors. By clicking on a specific day, you can see and process various deviations that have been registered.",
      routineStatus: 'This section shows you the percentage of completed routines during the week and month.'
    },
    chartLabels: {
      completed: "Completed without deviations",
      handled: "Routines with handled deviations",
      unhandled: "Routines with unhandled deviations",
    },
    chartLabelDescriptions: {
      completed: "Routines that have been completed without any deficiencies/deviations.",
      handled: "The proportion of routines where there has been a deficiency/deviation, but which has been processed.",
      unhandled: "The proportion of routines where there has been a deficiency/deviation which is still unprocessed."
    },
    calendarLinkTitle: 'Calendar overview',
  },
  mainMenu: {
    dashboard: "Dashboard",
    entityManagement: "Entity Management",
    entityTypes: "Entity Types",
    reportsHistory: "Reports History",
    manualMeasurements: "Manual Measurements",
    controlMeasurements: "Control Measurements",
    excelReports: "Excel Reports",
    clean: "Clean",
    routines: "Routines",
    selectSite: "Select Site",
    settings: "Settings",
    selectLanguage: "Select Language",
  },
  and: "and",
  on: "on",
  editSchedule: {
    add: "Add schedule",
    the_checklist: "The routine",
    starts_on: "starts on",
    should_become: "should become",
    it_becomes: "it becomes",
    before: "before",
    after: "after",
    visible: "visible",
    available: "available",
    for_doing: "for doing",
    overdue: "overdue",
    hidden: "hidden",
    upcoming_times: "Upcoming times",
    upcoming_times_invalid: "Invalid time interval",
    day: "day",
    days: "days",
    hour: "hour",
    hours: "hours",
    minute: "minute",
    minutes: "minutes",
    starting_at: "starting at",
    starting_now: "starting now",
    between: "between",
    selectedHalfHours: "every half hour",
    selectedHours: "selected hours",
    starting_at_simple: "at",
    every: "every",
    every_day_of_the_week: "every day of the week",
    advanced: "Advanced",
    schedulingEnabled: "Scheduling Enabled",
    save: "Save schedule",
    saved: "Saved",
    createAllwaysActive: "Start at will",
  },
  daysInWeek: {
    Monday: "Monday",
    Tuesday: "Tuesday",
    Wednesday: "Wednesday",
    Thursday: "Thursday",
    Friday: "Friday",
    Saturday: "Saturday",
    Sunday: "Sunday",
  },
  Preview: "Preview",
  Loading: "Loading",
  Error: "Error",
  "Add new": "Add new",
  "Name of point": "Name of point",
  Description: "Description",
  "Associated entity": "Associated entity",
  Cancel: "Cancel",
  "Show Active Points": "Show Active Points",
  "Show All Points": "Show All Points",
  "Markdown Editor": "Markdown Editor",
  "Associated object": "Associated object",
  Edit: "Edit",
  Move: "Move",
  "Set External Link": "Set External Link",
  "Assign Entity": "Assign Entity",
  Enabled: "Enabled",
  "Set Breakage": "Set Waste Unit",
  "Template description": "Template description",
  "Site specific description": "Site specific description",
  "Site specific details": "Site specific details",
  "Breakage unit": "Breakage unit",
  "Show Site Active Points": "Show Site Active Points",
  "Site Specific": "Site Specific",
  "Edit Description": "Edit Description",
  "Add More Details": "Add More Details",
  "Enabled for Site": "Enabled for Site",
  "is overridden": "is overridden",
  "The template description": "The template description",
  "Attach Media": "Attach Media",
  "Edit for Site": "Edit for Site",
  "Edit Template": "Edit Template",
  "Edit Tags": "Edit Tags",
  "Deviation Handling": "Deviation Handling",
  "High Priority": "High Priority",
  "Add new to Site": "Add new to Site",
  "The format of the uploaded file is not supported":
    "The format of the uploaded file is not supported",
  "The file is too large, maximum allowed size is 50MB":
    "The file is too large, maximum allowed size is 50MB",
  "Assign Checklist to Site": "Assign Checklist to Site",
  "Checklist Template": "Checklist Template",
  Comment: "Comment",
  Comments: "Comments",
  Point: "Point",
  "Reopen routine": "Reopen routine",
  "This deviation applies to the entire routine":
    "This deviation applies to the entire routine",
  Handle: "Handle",
  Continue: "Continue",
  Start: "Start",
  "Automatic deviation due to the routine not being completed in time":
    "Automatic deviation due to the routine not being completed in time.",
  "Include checklist points": "Include checklist points",
  "Mangler manuelle målinger": "Missing manual measurement for point.",
  signature: {
    select: "Select",
    av: "Signature of",
    search: "Search",
    add: "Add new signature",
    add_existing: "Add an existing signature",
    already_added: "Already added",
    remove: "Remove",
    userSignature: "Automatically added signature for User",
  },
  "No deviations reported": "No deviations reported",
  "Load more": "Load more",
  "Handled Comment": "Handled Comment",
  "Notification Settings": "Notification Settings",
  "Global Site Notifications": "Global Site Notifications",
  "Checklist Alarms Notifications": "Checklist Alarms Notifications",
  Version: "Version",
  "Update app": "Update app",
  "No manual devices to display.": "No manual devices to display.",
  "No devices to display.": "No devices to display.",
  "Previous report": "Previous report",
  Empty: "Empty",
  "Wash & clean": "Wash & clean",
  "Goods delivery": "Goods delivery",
  "During cooking": "During cooking",
  "Report registered": "Report registered",
  documents: "documents",
  "Listing of reports": "Listing of reports",
  "Listing of deviation reports": "Listing of deviation reports",
  "Temperatures per unit": "Temperatures per unit",
  "Found no devices to report for": "Found no devices to report for",
  "Latest report": "Latest report",
  "Save Current Page as Default": "Save Current Page as Default",
  "Push Notification Subscriptions": "Push Notification Subscriptions",
  entityTypes: {
    pageTitle: "Entity Types",
    addNew: "Add New Entity Type",
    search: "Search Entity Types",
    edit: {
      pageTitle: "Edit Entity Type",
      name: "Name",
      description: "Description",
      hotTemperatures: "Hot Temperatures",
      min: "Min",
      max: "Max",
      actionDescription: "Action Description",
      GREEN: "Green Rules",
      YELLOW: "Yellow Rules",
      RED: "Red Rules",
      save: "Save",
      errors: {
        number: "Has to be a number or Infinity.",
        required: "Required",
      },
      tabs: {
        entityType: "Entity Type",
        deviationCauses: "Deviation Causes",
        deviationHandling: "Deviation Handling",
      },
      entityType: {
        title: "Entity Type",
      },
      commonDeviationCauses: {
        cause: "Common deviation cause",
        label: "Button label",
        add: "Add Cause",
        title: "Common devation causes",
        noItems: "No common deviation causes defined.",
      },
      commonDeviationHandles: {
        action: "Common deviation handle action",
        add: "Add Deviation Handle",
        title: "Common devation handles",
        noItems: "No common deviation handles defined.",
      },
      addHandle: "Add Handeling",
      commonHandling: "Common Deviation Handling",
      handleLabel: "Handling",
    },
    cardDisplay: {
      rules: {
        GREEN: "Safe when value is between {{min}} and {{max}}.",
        YELLOW: "Warning when value is between {{min}} and {{max}}.",
        RED: "Critical when value is between {{min}} and {{max}}.",
      },
      actionDescription: "You should: {{actionDescription}}",
    },
  },
  alarms: {
    pageTitle: "Alarms",
    entities: "Entities",
    alarms: "Alarms",
    siteAlarms: "All Alarms in Sites",
    missingEventAlarmsTitle: "Missing Event Alarms",
    notifyAlarmsTitle: "Notify Alarms",
  },
  siteForm: {
    recipientSmsHours: {
      buttonLabel: "Define Recipient SMS Hours",
      title: "Recipient SMS Hours",
      from: "From",
      to: "To",
      add: "Add",
      all: "All Recipient SMS Hours",
      done: "Done",
      noItems: "No SMS Hours",
      everyDay: "Every day",
    },
    showLowPriorityRoutinesTab: "Show Low Priority Routines Tab",
    useTenantDefault: "use Tennant default",
    signatures: "Signatures for site",
    siteName: "Site Name",
    smsRecipients: "Alarm SMS Recipients",
    smsRecipient: {
      name: "Name",
      phoneNumber: "Phone Number",
      required: "Required",
      addRecipient: "Add recipient",
      unique: "Has to be unique",
    },
    save: "Save",
    saved: "Saved",
  },
  maintenance: {
    dontShowLabel: "Don't show again",
  },
  manualSchedule: {
    startNow: "Start Routine Now",
    startLater: "Start Routine Later",
    startManually: "Start Routine Manually",
    customSchedule: "Select Custom Schedule",
    hours: "Hour(s)",
    when: "When?",
    selectDateTime: "Select date and time for the fuutre schedule",
    duration: "How long should the routine be active?",
    successMessage: "Scheduled routine successfully.",
    tooltips: {
      startChecklist: "Start",
      reports: "Go to Reports",
      edit: "Edit",
    },
  },
  surveillanceDeviationReports: {
    pageTitle: "Alarm Deviations",
    latestValue: "Latest value",
    submit: "Submit",
    handled: "Deviation handled.",
    actionPlaceholder:
      "Write the reason for the deviation or choose a quick action.",
  },
  alarmStatus: {
    red: "Critical! Alarm generated.",
    yellow: "Warning detected. Not critical.",
    redSince: "Critical deviation started ",
    yellowSince: "Deviation started ",
    reportPageAlarmMessage: "Deviation started",
  },
  userProfile: {
    title: "User Profile",
    phoneAccounts: {
      add: "Add Phone for Sign In",
      addNew: "+ Add phone number",
      phoneNumber: "Sign In Phone Number",
      name: "Name",
      save: "Save",
      saved: "Sign in Phone Numbers Saved"
    },
  },
  deviationProcedures: {
    deviationAndPerios: "Deviations were detected",
    lastMeasuredTemperature: "Last measured temp",
    seeMoreInfo: "Here you can see more info",
    handleDeviationHere: "Handle deviation here",
    limitValue: "Limit value"
  }
};
