import { Guid } from "../system/guid";
import { Moment } from "moment";
import { RegisterSignal } from "../signals/registersignal";
import { Entity } from "./entity";

export class CouldFoodStorage {
  public signal: RegisterSignal;
  constructor(private entity: Entity) {}

  public toString(): string {
    return this.entity.toString();
  }

  public get value(): any {
    return this.entity.getValue();
  }

  public get name(): string {
    return this.entity.getName();
  }

  public get id(): string {
    return this.entity.getId();
  }

  public get disabled(): boolean {
    return this.entity.disabled;
  }
  public get hide(): boolean {
    return this.entity.hide;
  }
  public get nigthLimits(): any {
    return this.getLimit(0);
  }
  public get rules(): any {
    const rules = this.entity.obj.rules.temperature || this.entity.obj.rules;
    if (!rules.max && !rules.min) return { min: "", max: "", limits: [] };
    return rules;
  }
  public get min(): number {
    return this.rules.min;
  }
  public get max(): number {
    return this.rules.max;
  }

  public hasExceptionLimits(): any {
    return this.rules.limits && this.rules.limits.length > 0;
  }
  public getLimit(index: number): any {
    if (!this.rules.limits || this.rules.limits.length <= 0)
      return {
        min: "",
        max: "",
        from: { hour: "", minute: "" },
        to: { hour: "", minute: "" },
      };
    return this.rules.limits[0];
  }
  public getLimits(): any {
    if (!this.rules.limits || this.rules.limits.length <= 0) return [];
    return this.rules.limits;
  }
  public getRules(): any {
    return this.rules;
  }
}
