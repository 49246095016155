import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SignalsModule } from '../signals/signals.module';
import { SiteComponent } from './site/site.component';
import { EntitiesComponent } from './entities/entities.component';
import { EntityComponent } from './entity/entity.component';
import { AddEntityComponent } from './add-entity/add-entity.component';
// import { AddOpenCloseEntityComponent } from './add-entity/add-entity.component';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { FormBuilder, FormGroup, FormControl, Validators,  ReactiveFormsModule} from '@angular/forms';
import { MaterialModule } from '../material.module';
// import { ThermoFormComponent } from "./thermo-form-cva/thermo-form-component";
import { EditEntityFormComponent } from "./edit-entity-form/edit-entity-form.component";
import { ThermoFormComponent } from "./thermo-form/thermo-form-component";
import { OpenCloseFormComponent } from "./open-close-form/open-close-form-component";
import { MainPipe } from '../main-pipe.module.ts';
import { HumidityFormComponent } from './humidity-form/humidity-form-component';

@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    SignalsModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    MainPipe
  ],
  declarations: [
    EntityComponent,
    SiteComponent,
    EntitiesComponent,
    AddEntityComponent,
    EditEntityFormComponent,
    ThermoFormComponent,
    HumidityFormComponent,
    OpenCloseFormComponent,
  ],
  exports:[
    // EntityComponent
  ]
})
export class EntityModule { }
