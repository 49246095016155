import React, { useContext, useEffect } from 'react';
import {  Theme } from "@mui/material";
import Button from '@mui/material/Button';
import clsx from 'clsx';
import { LoginContext } from '../LoginReactWrapper';
import { environment } from '../../../environments/environment';
import { AuthenticationResult } from '@azure/msal-browser';
import { Observable } from 'rxjs';
import { styled, alpha } from '@mui/material/styles';
import { createStyles, makeStyles } from "@mui/styles";
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
import Divider from '@mui/material/Divider';
import ArchiveIcon from '@mui/icons-material/Archive';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { clearStorage, deleteAllCookies } from '../../localStorageUtils';


const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
    },
  })
);

export default function Fix() {
  const classes = useStyles();
  var siteId = localStorage.getItem("siteId") as string;
  var version = environment.version;
  const { msalService, router } = useContext(LoginContext);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = () => {
    msalService.logout();
    handleClose();
  };
  const handleLogin = ()=> {
    msalService.loginPopup();
  };
  const handleUpdateCache = () => {
    console.log("Update version");
    window.location.reload();
    handleClose();
  };

  
  const handleClearAppData = () => {
    clearStorage();
    sessionStorage.clear();
    deleteAllCookies();
    window.location.href = "/";
    handleClose();
  };
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const setSiteId = (event: React.MouseEvent<HTMLElement>) => {
    localStorage.setItem("siteId",'"c3124c82-c2f1-412f-be0a-4782cdab57f9"');
    setAnchorEl(event.currentTarget);
  };
  

  return (
    <div className={clsx(classes.root)}>
      <Button
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        Options
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >

        <MenuItem onClick={setSiteId} disableRipple>
          SiteID : {siteId}
        </MenuItem>
        <MenuItem onClick={handleLogout} disableRipple>
          Logg ut
        </MenuItem>
        <MenuItem onClick={handleClearAppData} disableRipple>
          
          Fjern app data
        </MenuItem>
        <MenuItem onClick={handleUpdateCache} disableRipple>
          Versjon - {version} - Trykk for å oppdatere
        </MenuItem>
      </StyledMenu>
      
    </div>
  );
}
