import { TimelineDot, } from "@mui/lab";
import { Grid, Paper, Theme } from "@mui/material";
import { makeStyles, createStyles } from '@mui/styles';
import * as React from "react";
import { useTranslation } from "react-i18next";
import FormikField from "../../react-components/FormikField";

const styles = ({ spacing }: Theme) =>
  createStyles({
    root: {
      marginBottom: spacing(2),
      padding: spacing(2)
    },
    ruleTitle: {
      marginLeft: spacing(1),
      fontWeight: 550
    },
    ruleTitleContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
    }
  });

const useStyles = makeStyles(styles);


type Props = {
  ruleKey: string;
};

const ActionDescriptionRuleForm: React.FC<Props> = ({ ruleKey }) => {

  const { t } = useTranslation();
  const classes = useStyles();

  const keyColorRegistry = {
    GREEN: "success",
    YELLOW: "warning",
    RED: "error",
  }

  return (
    <Paper className={classes.root} elevation={2}>
      <div className={classes.ruleTitleContainer}>
        <TimelineDot sx={{ display: 'inline-block', width: '1px', verticalAlign: 'middle' }} color={keyColorRegistry[ruleKey]} />
        <p className={classes.ruleTitle}>{t(`entityTypes.edit.${ruleKey}`)}</p>
      </div>
      <Grid container>
        <Grid item md={6} xs={12}>
          <FormikField multiline rows={3} label={t("entityTypes.edit.actionDescription")} name={`rules.${ruleKey}.actionDescription`} />
        </Grid>
        <Grid item md={3} xs={6} sx={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-end'}}>
          <FormikField
            label={t("entityTypes.edit.min")} name={`rules.${ruleKey}.min`} required disabled />
        </Grid>
        <Grid item md={3} xs={6} sx={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-end'}}>
          <FormikField label={t("entityTypes.edit.max")} name={`rules.${ruleKey}.max`} required disabled />
        </Grid>
      </Grid>
    </Paper>
  );

};

export default ActionDescriptionRuleForm;
