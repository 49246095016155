import {
  Typography,
  TextField,
  Theme,
  Autocomplete,
  Select
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

import React from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(({ spacing, palette }: Theme) =>
  createStyles({
    root: { },
    includedFromText: {
      fontSize: '0.9em'
    }
  })
);

type Props = {
  isEdit: boolean;
  name: string;
  onNameChange: (name: string) => void;
  includedFromName: string;
};


const PointCardTitle = ({
  isEdit,
  name,
  onNameChange,
  includedFromName
}: Props) => {

  const classes = useStyles();

  const { t } = useTranslation();

  return isEdit ?
    <TextField value={name} onChange={e => onNameChange(e.target.value)} label={t("editChecklist.editPoint.card.inputFieldLabel")} />
    :
    <>
      <Typography variant="h3" gutterBottom>
        {name}
      </Typography>
      {includedFromName ? <i className={classes.includedFromText}>{t("editChecklist.editPoint.card.includedFrom")} {includedFromName}</i> : <></>}
    </>;
};

export default PointCardTitle;
