import * as React from "react";
import * as moment from "moment";
import { makeStyles } from '@mui/styles';
import { useTranslation } from "react-i18next";

type Props = {
  event: any;
  nextEvent?: any;
};

const useStyles = makeStyles({
  root: {
    fontSize: "1em",
    margin: "0.3em",
  },
});

const TimelineTooltip = ({ event, nextEvent = moment() }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const duration = moment.duration(nextEvent.diff(event));

  return (
    <div className={classes.root}>
      <p>{`${event.format("DD.MM HH:mm:ss")} ${t("to")} ${nextEvent.format(
        "DD.MM HH:mm:ss"
      )}`}</p>
      <p>{duration.humanize()}</p>
    </div>
  );
};

export default TimelineTooltip;
