import { Injectable } from "@angular/core";
import { environment } from "../environments/environment";
import { Observable } from "rxjs";
import { Alarm } from "./alarm";
import { map } from "rxjs/internal/operators/map";
import { ObservableAxios, observableAxios } from "./rxjs-axios";

@Injectable({
  providedIn: "root",
})
export class AlarmsService {
  private readonly base_entity_url = environment.dependencies.entity_api.url;
  private readonly alarms_url = this.base_entity_url + "/api/alarms";

  private readonly http: ObservableAxios = observableAxios;

  constructor() {}

  public getAlarms(
    entityId: string,
    sourceId: string
  ): Observable<Array<Alarm>> {
    return this.http
      .get(`${this.alarms_url}?referenceId=${entityId}&sourceId=${sourceId}`)
      .pipe(
        map((data: Array<any>) => {
          return data.map((data) => {
            return new Alarm(
              data.id,
              data.alarmId,
              data.type,
              data.sourceId,
              data.createdOn,
              data.measurement
            );
          });
        })
      );
  }

  public getAlarmsForEntities(
    entityIds: string[]
  ): Observable<Map<string, Array<Alarm>>> {
    var sep = "?";
    var queryParam = "";
    entityIds.forEach((id) => {
      queryParam += `${sep}referenceIds=${id}`;
      sep = "&";
    });

    return this.http.get(`${this.alarms_url}/references${queryParam}`).pipe(
      map((data: Map<string, Array<any>>) => {
        // console.log("Got data (count: " + data.size + ") for multiple alarms: ", data);

        var result = new Map<string, Array<Alarm>>();
        Object.entries(data).forEach((e) => {
          let referenceId = e[0];
          let alarmsData = e[1];
          // console.log("ReferenceId: " + referenceId + ", alarms data:", alarmsData);
          let alarms = alarmsData.map((data) => {
            return new Alarm(
              data.id,
              data.alarmId,
              data.type,
              data.sourceId,
              data.createdOn,
              data.measurement
            );
          });

          result.set(referenceId, alarms);
        });
        return result;
      })
    );
  }
}
