import * as React from "react";
import { FC } from "react";
import {
  Theme,
} from "@mui/material";
import { makeStyles, createStyles } from '@mui/styles';
import { useChecklistContext } from "../ChecklistReactWrapper";
import { useSignatureContext } from "../../signature-react/SignatureContext";
import { useTranslation } from "react-i18next";
import { RadioButtonsInput } from "../../react-components/RadioButtonsInput";
import { useTakeActionForDeviation } from "../services/checklistService";

type Props = {
  pointId?: any;
  point: any;
  assignedId: string;
  checklistId: string;
  sessionId: string;
  actionTaken: string;
  onActionsAdded: (action: any) => void;
  deviationHandles?: { action: string }[];
  disabled?: boolean;
};

const useStyles = makeStyles(({ spacing }: Theme) =>
  createStyles({
    root: {
      marginBottom: spacing(2)
    },
  })
);

const ReportDeviationHandleInput: FC<Props> = ({
  pointId,
  point,
  assignedId,
  checklistId,
  sessionId,
  actionTaken,
  onActionsAdded,
  deviationHandles,
  disabled,
}) => {

  const { t } = useTranslation();
  const { user } = useChecklistContext();
  const {
    state: { activeSignature },
  } = useSignatureContext();

  const signatureId = activeSignature?.id?.toString();
  const classes = useStyles();

  const handleNewAction = async (action: string) => {
    await useTakeActionForDeviation({
      pointId,
      action,
      assignedId,
      checklistId,
      signatureId: signatureId,
      sessionId,
      siteId: user.siteId,
      tenantId: point.tenantId,
      userId: user.adalUser.oid
    });
    onActionsAdded(action);
  };

  return (
    <div className={classes.root}>
      <RadioButtonsInput
        label={t("checklist.takenAction")}
        value={actionTaken}
        options={deviationHandles.map(h => ({
          label: h.action,
          value: h.action
        }))}
        onChange={handleNewAction}
        disabled={disabled}
      />
    </div>
  );
};

export default ReportDeviationHandleInput;
