import { Theme } from "@mui/material";
import { makeStyles, createStyles } from '@mui/styles';
import * as React from "react";
import { useTranslation } from "react-i18next";
import PageHeader from "../../react-components/PageHeader";
import { EntityTypesContext } from "../EntityTypesReactWrapper";
import EntityTypesList from "./EntityTypesList"


const styles = ({ spacing }: Theme) =>
  createStyles({
    root: {},
    topicGroup: {
      paddingLeft: spacing(8),
      paddingRight: spacing(8),
    },
  });

const useStyles = makeStyles(styles);


type Props = {

};

const EntityTypesPage: React.FC<Props> = () => {
  const { siteService, user } = React.useContext(EntityTypesContext);

  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <>
      <PageHeader title={t("entityTypes.pageTitle")} />
      <EntityTypesList />
    </>
  );

};

export default EntityTypesPage;
