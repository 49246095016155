import { Guid } from "../system/guid";
import { Moment } from "moment";

export class TermoObject {

    public static readonly typeName = "TermoObject";

    constructor(
        public id: string,
        public entityId: string,
        public make: string,
        public model: string,
        public tenantId: string,
        public rules: any,
        public createdOn: Moment,
        public termoObjectTypeId?: string){

    }
}