import { Theme } from "@mui/material";
import { makeStyles, createStyles } from '@mui/styles';
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Site } from "../../entity/site";
import PageHeader from "../../react-components/PageHeader";
import { NotificationSettingsContext } from "../NotificationSettingsReactWrapper";
import { UserSubscribedTopic, useSubscribedTopicsFor } from "../services/userSubscriptionsService";
import SubscriptionTopicToggle from "./SubscriptionTopicToggle";


const styles = ({ spacing }: Theme) =>
  createStyles({
    root: {},
    topicGroup: {
      paddingLeft: spacing(8),
      paddingRight: spacing(8),
    },
  });

const useStyles = makeStyles(styles);


type Props = {

};

const NotificationsPage: React.FC<Props> = () => {
  const { siteService, user } = React.useContext(NotificationSettingsContext);

  const { t } = useTranslation();
  const classes = useStyles();

  const tenantIds: string[] = user.adalUser.btennant;

  const [sites, setSites] = React.useState<Site[]>([]);
  const [userSubscribedTopics, setUserSubscribedTopics] = React.useState<UserSubscribedTopic[]>([]);

  React.useEffect(() => {
    siteService
      .getSites()
      .subscribe((res: Site[]) => {
        setSites(res);
      });
    useSubscribedTopicsFor(user.adalUser.oid)
      .then((res: UserSubscribedTopic[]) => setUserSubscribedTopics(res));
  }, []);

  const isSubscribedTo = (topicKey: string, id: string) => userSubscribedTopics.findIndex(ust => ust.topicKey === topicKey && ust.id === id) !== -1;

  return (
    <>
      <PageHeader title={t("Notification Settings")} />
      <div className={classes.topicGroup}>
        <h3>{t("Global Tenant Notifications")}</h3>
        {tenantIds.map(t => 
          <SubscriptionTopicToggle
            key={t + isSubscribedTo('tenant', t)}
            label={t}
            subscribed={isSubscribedTo('tenant', t)}
            topicKey={'tenant'}
            topicId={t}
            userId={user.adalUser.oid}
          />
        )}
      </div>
      <div className={classes.topicGroup}>
        <h3>{t("Global Site Notifications")}</h3>
        {sites.map(s => 
          <SubscriptionTopicToggle
            key={s.id.toString() + isSubscribedTo('site', s.id.toString())}
            label={s.name}
            subscribed={isSubscribedTo('site', s.id.toString())}
            topicKey={'site'}
            topicId={s.id.toString()}
            userId={user.adalUser.oid}
          />
        )}
      </div>
      <div className={classes.topicGroup}>
        <h3>{t("Checklist Alarms Notifications")}</h3>
        {sites.map(s => 
          <SubscriptionTopicToggle
            key={s.id.toString() + isSubscribedTo('site-checklist-alarms', s.id.toString())}
            label={s.name}
            subscribed={isSubscribedTo('site-checklist-alarms', s.id.toString())}
            topicKey={'site-checklist-alarms'}
            topicId={s.id.toString()}
            userId={user.adalUser.oid}
          />
        )}
      </div>
    </>
  );

};

export default NotificationsPage;
