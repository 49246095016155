import * as moment from 'moment';

export class ChartPeriod {
    
    private constructor(
      public readonly type: string,
      public readonly startDate: moment.Moment,
      public readonly endDate: moment.Moment,
      public readonly lastWeekStartDate: moment.Moment) { }

    static create(type: string): ChartPeriod {
      let now = moment();
      let startDate = this.getStartDate(now, 0, type);
      let endDate = this.getEndDate(startDate, type);
      return new ChartPeriod(type, startDate, endDate, null);
    }

    prevPeriod(): ChartPeriod {
      return this.updatePeriod(-1)
    }

    nextPeriod(): ChartPeriod {
      return this.updatePeriod(1)
    }

    setType(type: string): ChartPeriod {
      if (type == this.type) {
        return this;
      }

      let startDate = ChartPeriod.getStartDateForNewType(type, this);
      let endDate = ChartPeriod.getEndDate(startDate, type);
      let lastWeekStartDate = ChartPeriod.getLastWeekStartDateForNewType(type, this);

      var newPeriod = new ChartPeriod(type, startDate, endDate, lastWeekStartDate);

      return newPeriod;
    }

    isWeek() {
      return ChartPeriod.isWeek(this.type);
    }

    static isWeek(type: string) {
      return type == "Uke";
    }

    private updatePeriod(diff: number): ChartPeriod {
      var newStartDate = ChartPeriod.getStartDate(this.startDate, diff, this.type);
      var newEndDate = ChartPeriod.getEndDate(this.startDate, this.type);

      var newPeriod = new ChartPeriod(this.type, newStartDate, newEndDate, this.lastWeekStartDate);

      return newPeriod;
    }

    private static getStartDateForNewType(type: string, period: ChartPeriod): moment.Moment {
      if (ChartPeriod.isWeek(type)) {
        if (period.lastWeekStartDate != null && period.lastWeekStartDate.isSameOrAfter(period.startDate) && period.lastWeekStartDate.isBefore(period.endDate)) {
          // Previous week start date is still within current month, continue to use it
          return moment(period.lastWeekStartDate);
        } else {
          // Use last week of month, or current week if last week is in the future
          let now = moment()
          let date = period.endDate.isBefore(now) ? period.endDate : now;
          return moment(date).startOf('isoWeek');
        }
      } else {
        // Month
        return moment(period.startDate).startOf('month');
      }
    }

    private static getLastWeekStartDateForNewType(type: string, period: ChartPeriod): moment.Moment {
        if (ChartPeriod.isWeek(type)) {
          if (period.lastWeekStartDate != null && period.lastWeekStartDate.isSameOrAfter(period.startDate) && period.lastWeekStartDate.isBefore(period.endDate)) {
            return period.lastWeekStartDate;
          } else {
            return null;
          }
        } else {
          // Month
          return period.startDate;
        }
      }

    private static getStartDate(startDate: moment.Moment, diff: number, type: string): moment.Moment {
      if (ChartPeriod.isWeek(type)) {
        return startDate.startOf('isoWeek').add(diff, 'weeks');
      } else {
        // Month
        return startDate.startOf('month').add(diff, 'months');
      }
    }

    private static getEndDate(startDate: moment.Moment, type: string): moment.Moment {
      if (ChartPeriod.isWeek(type)) {
        return moment(startDate).endOf('isoWeek');
      } else {
        return moment(startDate).endOf('month');
      }
    }
  }