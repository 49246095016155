import * as React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Theme,
} from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import DialogTitle from "../../react-components/DialogTitle";
import { useEntityTypes } from "../../entity-types-react/services/entityTypesService";
import SelectOption from "../../checklist-react/components/cron-definition/SelectOption";
import { FormikProps, useFormikContext } from "formik";
import { IValues } from "../interfaces";
import Loading from "../../react-components/Loading";
import EditEntityContext from "../EditEntityContext";
import { useCurrentSite } from "../../react-components/useCurrentSite";

type Props = {
  open: boolean;
  onClose: () => void;
  objectType: any;
  setObjectType: (v: any) => void;
  setObjectTypeRules: (v: any) => void;
  handleSave: (v: any) => void;
};

const styles = ({ spacing }: Theme) =>
  createStyles({
    row: {
      display: "flex",
    },
    label: {
      marginRight: spacing(2),
    },
  });

const useStyles = makeStyles(styles);

const ObjectTypeDialog = ({
  open,
  onClose,
  objectType,
  setObjectType,
  setObjectTypeRules,
  handleSave,
}: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { siteService, userSiteService } = React.useContext(EditEntityContext);
  const { site } = useCurrentSite(userSiteService, siteService);

  const [{ data: fetchedEntityTypes, loading, error }] = useEntityTypes();

  const [entityTypes, setEntityTypes] = React.useState([]);
  React.useEffect(() => {
    site && setEntityTypes(fetchedEntityTypes?.filter(x => x.tenantId === site.tenantId) ?? []);
  }, [fetchedEntityTypes, site]);

  const { submitForm }: FormikProps<IValues> = useFormikContext();

  const [objectTypeLocal, setObjectTypeLocal] = React.useState(objectType);
  const [objectTypeRulesLocal, setObjectTypeRulesLocal] = React.useState();

  const selectObjectType = () => {
    setObjectType(objectTypeLocal);
    setObjectTypeRules(objectTypeRulesLocal);
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle onClose={onClose}>
        {t("entityForm.objectType.title")}
      </DialogTitle>
      <DialogContent>
        <label className={classes.label}>
          {t("entityForm.objectType.title")}
        </label>
        <SelectOption
          onChange={(v) => {
            setObjectTypeLocal(v);
            setObjectTypeRulesLocal(
              entityTypes?.find((entityType) => entityType.id === v)?.rules
            );
          }}
          value={objectTypeLocal}
          items={entityTypes?.map((entityType) => ({
            label: entityType.name,
            value: entityType.id,
          }))}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          onClick={() => {
            selectObjectType();
            handleSave(objectTypeLocal);
            onClose();
          }}
        >
          {t("entityForm.objectType.select")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          onClick={() => {
            selectObjectType();
            handleSave(objectTypeLocal);
            submitForm();
            onClose();
          }}
        >
          {t("entityForm.objectType.selectAndSave")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ObjectTypeDialog;
