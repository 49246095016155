import useAxios from "axios-hooks";
import { environment } from "../../../environments/environment";
import { setup } from 'axios-cache-adapter';
import { debouncedPromise } from "../../debouncedPromise";
import { Guid } from "../../system/guid";

const BASE_SIGNATURE_URL =
  environment.dependencies.signature_api.url + "/api/signatures";

export const useGetSignatures = (pointId) => {
  return useAxios(`${BASE_SIGNATURE_URL}?userId=${pointId}`);
};

export const signatureApi = setup({
  baseURL: BASE_SIGNATURE_URL,
  cache: {
    maxAge: 60 * 1000 // one hour
  }
});

export const fetchSignature = async (signatureId: string) => {
  return debouncedPromise(async () => {
    const resp = await signatureApi.get(`/${signatureId}`);
    return resp.data[0];
  }, 500, signatureId);
}

export const fetchManySignatures = async (signatureId: (string | Guid)[]) => {
  const signatures = [];
  await Promise.all(signatureId.map(async (id) => {
    if(!id) return;
    const signature = await fetchSignature(id.toString());
    signatures.push(signature);
  }));
  return signatures;
};