<mat-card class="ok" style="margin-bottom: 0;"
  [ngStyle]="{'margin-bottom': showMargin ? '20px' : '0'}"
  [ngClass]="{ ok: deviationsCount == 0, deviations: deviationsCount > 0 }">
  <mat-card-content>
    <div class="items horizontal" style="position: relative;">
      <div style="display: flex;">
        <div class="item">
          <h2 style="margin-bottom: 10px;" [ngStyle]="{ 'visibility': showTitle ? '' : 'hidden' }">
            {{
            isWeeklyReport
            ? ("Ukens avvik" | i18next)
            : ("Dagens avvik" | i18next)
            }}
          </h2>
          <div class="icon">
            <img *ngIf="isPeriodicReport" class="icon-img" src="/assets/icons/report-icon.svg" alt="Rapporter" />
            <i *ngIf="!isPeriodicReport" class="material-icons">
              report_problem
            </i>
          </div>
        </div>
        <div class="item" *ngIf="!isLoading"
          style="display: flex; flex-direction: column; justify-content: end; margin-left: 20px; height: 115px;">
          <h3 *ngIf="timestamp && !isWeeklyReport">
            {{ timestamp.format("DD.MM.YYYY") }} kl. {{ timestamp.format("HH.mm") }}
          </h3>
          <h3 *ngIf="timestamp && isWeeklyReport">
            {{ timestamp.clone().subtract(6, "days").format("DD.MM.YYYY") }} - {{ timestamp.clone().subtract(1,
            "days").format("DD.MM.YYYY") }}
          </h3>
        </div>
      </div>
      <div class="item" *ngIf="isLoading">
        <mat-spinner></mat-spinner>
      </div>
      <div class="item" *ngIf="deviationsCount == 0 && !isLoading">
        <div class="no-deviations">
          <span>{{ "No deviations to report" | i18next }}</span>
          <i class="material-icons"> done </i>
        </div>
      </div>
      <div class="item" *ngIf="deviationsCount > 0 && !isLoading">
        <ng-template [ngIf]="handledDeviationsCount == 0">
          <!-- None of the deviations are handled, just show deviations count -->
          <div class="deviations-count">
            {{ deviationsCount }}
          </div>
          <div class="deviations-text">
            {{
            isPeriodicReport
            ? ("Routine report" | i18next)
            : ("Deviations" | i18next)
            }}
          </div>
        </ng-template>
        <ng-template [ngIf]="handledDeviationsCount > 0">
          <!-- Some of the deviations are handled, show both handled and total deviations count -->
          <div class="deviations-count">
            {{ handledDeviationsCount }}/{{ deviationsCount }}
          </div>
          <div class="deviations-text">
            {{
            isPeriodicReport
            ? ("Routine report" | i18next)
            : ("Deviations" | i18next)
            }}
            {{ "handled" | i18next }}
          </div>
        </ng-template>
      </div>
    </div>

    <div class="items horizontal" *ngIf="!isPeriodicReport || deviationsCount > 0">
      <div class="item"></div>
      <div class="item">
        <a class="button" (click)="clickedHandleDeviations()" *ngIf="deviationsCount > handledDeviationsCount">{{
          "Handle deviations" | i18next }}</a>
        <button *ngIf="deviationsCount <= handledDeviationsCount" disabled>
          {{ "No deviations to handle" | i18next }}
        </button>
      </div>
    </div>

    <div *ngIf="tooltipText" style="position: absolute; top: 10px; right: 10px;">
      <i class="material-icons" [matTooltip]="tooltipText"> info </i>
    </div>
  </mat-card-content>
</mat-card>